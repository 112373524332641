import styled from "styled-components";
import { ReactComponent as BillIconSVG } from "../../../assets/billSide.svg";

export const BillIcon = styled(BillIconSVG)`
  fill: var(--cian);
  width: 16px;
`;

export const Container = styled.div`
  height: 100vh;
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
`;

export const ContentWrapper = styled.div`
  padding: 32px;
`;

export const Text = styled.div`
  max-width: 592px;

  > span {
    font-size: 18px;
    font-weight: 600;
    font-family: var(--regular);
    color: var(--gray);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 32px 0 0 0;

  @media (min-width: 720px) {
    width: 242px;
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 37px;
  flex: 1;
  border: 1px solid var(--gray-light-order);
  border-radius: 8px;
  padding: 14px 16px;

  > input {
    background: none;
    border-style: none;
    width: 100%;
    outline: 0;
  }
`;

export const Table = styled.div`
  flex: 1;
  margin-top: 32px;
`;

export const TableWrapper = styled.div`
  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px) {
    width: 100%;
  }

  @media (min-width: 1280px) {
    width: 100%;
  }

  > table {
    width: 100%;

    > tbody {
      border-radius: 8px;
      border: none;
      border-collapse: collapse;

      > tr {
        position: relative;

        > td {
          height: 62px;
          font-family: var(--regular);
          color: var(--gray-light-text);
          border-bottom: 1px solid var(--gray-light-line);
          padding: 0 20px;

          &:last-child {
            text-align: end;
          }
        }
      }
    }

    > thead {
      border-radius: 8px;
      border: none;
      border-collapse: collapse;

      > tr {
        position: relative;

        > td {
          padding: 6px 16px;
          font-family: var(--regular);
          font-size: 14px;
          font-weight: 600;
          color: #222;
          cursor: pointer;
          border-bottom: 1px solid var(--gray-light-line);

          @media (min-width: 720px) {
            font-size: 16px;
            padding: 12px 32px;
          }

          &:last-child {
            text-align: end;
          }
        }
      }
    }
  }
`;
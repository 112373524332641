import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StepHeader } from "../../../components/stepHeader";
import { CardActionOption } from "../../../components/cardActionOption";
import { Skeleton } from "../../../components/skeleton";

import {
  BillIcon,
  ButtonIcon,
  Container,
  Content,
  ContentWrapper,
  PrintButtonIcon,
  RecentBilletsTitle,
  WrapperHeader,
} from "./styles";

import { service } from "../../../services/ajio";
import { Table, TableWrapper } from "../../receipt/styles";
import { Menu, Transition } from "@headlessui/react";
import { PrinterIcon } from "@heroicons/react/outline";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface Payer {
  id: string;
  name: string;
  document: string;
}

interface Address {
  id: string;
  street: string;
  number: string;
  extra: string;
  neighborhood: string;
  city: string;
  state: string;
  postal_code: string;
}

interface BilletProps {
  id: string;
  status: string;
  type: string;
  reference_id: string;
  charge_type: string;
  created_at: string;
  updated_at: string;
  due_date: string;
  limit_date: string;
  barcode: string;
  digitable_line: string;
  amount: number;
  instructions: string;
  payer: Payer;
  address: Address;
  user_id: string | null;
  addressId: string;
  payerId: string;
  document: string;
}

export const Billets: React.FC = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const { status } = useParams();

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");

  const [items, setItems] = useState<BilletProps[]>([]);

  useEffect(() => {
    setLoading(true);
    const params = { status };
    const tl = status === "waiting" ? "Boletos em aberto" : "Boletos pagos";
    if (tl) setTitle(tl);
    service
      .get(`bank-slips/all-slips`, {
        params,
      })
      .then(({ data }) => {
        setItems(data.transactions.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [status]);


  const handleGeneratePDF = async (data: BilletProps) => {
    const response = await service.get(`/bank-slips/${data.id}/all`);

    const bankslips = {
      ...response.data.bankslips.data,
      user: response.data.bankslips.user,
    };


    let pdf = new jsPDF("p", "mm", "a4");

    // Definindo as margens do PDF (por padrão, as margens são 10mm)
    const marginLeft = 10;
    const marginTop = 10;
    const marginRight = 10;
    const marginBottom = 10;

    // Obtendo o tamanho útil da página descontando as margens
    const usableWidth =
      pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
    const usableHeight =
      pdf.internal.pageSize.getHeight() - marginTop - marginBottom;

    // Configurando o texto para centralizar horizontalmente
    pdf.setFont("helvetica", "bold"); // Definindo a fonte como negrito
    pdf.setFontSize(22);
    pdf.setTextColor(0, 0, 0); // Definindo a cor do texto como preto

    // Adicione a imagem no início do PDF
    const imgWidth = 100; // Largura da imagem
    const imgHeight = 20; // Altura da imagem
    const imgY = marginTop;
    const imgX = (usableWidth - imgWidth) / 2 + marginLeft;

    // pdf.addImage(logoImage, "PNG", imgX, imgY, imgWidth, imgHeight);

    const formattedDate = dateFormat(data.created_at || data.created_at || "");
    const formattedAmount = currencyFormat(data.amount);

    pdf.setFontSize(12);
    pdf.text(`Data: ${formattedDate}`, 10, 50);
    pdf.text("Valor:", 10, 60);
    pdf.text(`${formattedAmount}`, 10, 70);

    const tipoPagamentoTextWidth =
      pdf.getStringUnitWidth("Tipo de pagamento:") * 12;
    const pageWidthTypePayment = pdf.internal.pageSize.getWidth();
    const tipoPagamentoX = pageWidthTypePayment - tipoPagamentoTextWidth - 10;
    pdf.text("Tipo de pagamento:", tipoPagamentoX, 60);
    pdf.text(`PIX`, tipoPagamentoX, 70);

    pdf.setFontSize(12);
    pdf.text(`CÓDIGO DE AUTENTICAÇÃO`, 80, 180);
    pdf.text(bankslips.id, 70, 190);

    // ... adicione mais dados da tabela conforme necessário

    pdf.save("Extrato.pdf");
  };

  const dateFormat = (date: string | undefined): string => {
    // Formata a data para o formato desejado
    const formattedDate = format(
      new Date(date || ""),
      "EEEE, dd 'de' MMMM yyyy HH:mm",
      { locale: ptBR }
    );
    return formattedDate;
  };

  const currencyFormat = (amount: number | string) => {
    // Formata o valor para o formato de moeda brasileira (R$)
    const formattedAmount = amount.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formattedAmount;
  };


  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
            <StepHeader handleGoBackFunction={handleGoBack} title="Boletos" />
          </WrapperHeader>

          <RecentBilletsTitle>{title}</RecentBilletsTitle>

          <Table>
            <div className="flex justify-between items-center mb-5">
              <div className="flex flex-end">
                <Menu as="div" className="relative inline-block text-left">
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => <span>PDF</span>}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <TableWrapper>
              {!loading && items.length <= 0 && (
                <div className="p-6">
                  <div className="text-center">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Nenhum lançamento para o período selecionado
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Selecione outro período ou gere um Boleto e comece a usar
                      agora mesmo
                    </p>
                  </div>
                </div>
              )}

              <Skeleton space={4} isActive={loading} repeat={5} />

              <table>
                <tbody>
                  {!loading && items.length > 0 && (
                    <tr>
                      <th>Data</th>
                      <th>Pagador</th>
                      <th>Valor</th>
                      <th style={{ paddingLeft: "15px" }}>Imprimir</th>
                    </tr>
                  )}

                  {!loading &&
                    items.map((item) => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>{dateFormat(item.created_at)}</td>

                          <td>{item.payer.name}</td>
                          <td>
                            <span
                              style={{
                                color: "var(--green)",
                              }}
                            >
                              {currencyFormat(Number(item.amount))}
                            </span>

                            <span
                              style={{
                                marginLeft: "25px",
                                display: "inline",
                              }}
                            ></span>
                          </td>
                          <td>
                            <PrintButtonIcon>
                              <PrinterIcon
                                onClick={() => handleGeneratePDF(item)}
                                color="var(--boxText)"
                                className="h-4 w-4 inline"
                              />
                            </PrintButtonIcon>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

import React, { FormEvent, Fragment, useEffect, useState, FC } from "react";
import { Skeleton } from "../../../components/skeleton";
import { api } from "../../../services/api";
import { Transition } from "@headlessui/react";
import { Container, Content, QrContainer, Table, TableWrapper, LeftDiv, RightDiv } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { service } from "../../../services/ajio";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { classNames } from "./base-limits-layout";
import IntlCurrencyInput from "react-intl-currency-input";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import bgLogoLeft from "../../../assets/clients/vilapaybank/bg-div-left.png";
import successLogoLimits from "../../../assets/success_up_limits.png";
import analisysLogoLimits from "../../../assets/analisys_status_limits.png";
import upLogoLimits from "../../../assets/arrow-up-right.svg";
import { GraphicAccount } from "../graphic-account.interface";

type AccountProps = {
  id: string;
  name: string;
  balance: number;
  document: string;
  email: string;
  monthly_invoicing: number;
  phone?: {
    ddd: string;
    number: string;
  };
  address?: {
    state: string; //only the UF code
    publicPlace: string;
    number: string;
    zipCode: string; //only numbers
    neighborhood: string;
    complement?: string;
    isConfirmed: boolean;
    type: string;
    cityId?: number;
    cityName?: string;
  };
};

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export interface Transaction {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  // type: string;
  credit: boolean;
  // amount: number;
  //

  createdAt: string;
  category: string;
  amount: string;
  type: string;
  transactionData: {
    clientNamePayer: string;
    clientName: string;
    keyTarget: string;
  };
}

interface IItems {
  [x: string]: any;
  id: string;
  transactionId: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  // type: string;
  credit: boolean;
  // amount: number;
  //

  category: string;
  amount: string;
  type: string;
  createdAt: string;
  transactionData: any;
}

export interface ExtractItem {
  id: string;
  direction: "in" | "out";
  description: string;
  amount: number;
  created_at: string;
  operation: string;
  title: string;
}

type AddressProps = {
  city: string;
  complement: string;
  date: string;
  id: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
};

type DocumentsProps = {
  id: string;
  originalName: string;
  name: string;
  status: string;
  type: string;
  date: string;
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const IncreasingLimits = () => {
  // const token = localStorage.getItem('@stricv2:token');
  const navigate = useNavigate();
  //const { setOpenNotification } = useNotification();
  const [key, setKey] = useState<KeysProps>({} as KeysProps);
  const [keys, setKeys] = useState<KeysProps[]>([]);
  const [amount, setAmount] = useState<number>(0.0);
  const [qrImage, setQrImage] = useState<string>("");
  const [isEmvData, setEmvData] = useState<string>("");

  const [maskedValue, setMaskedValue] = useState("");
  const [value, setValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [keyOptions, setKeyOptions] = useState<string[]>([]);
  const { signIn, user } = useAuth();

  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [, setAccount] = useState<AccountProps>({} as AccountProps);
  const [, setAddress] = useState<AddressProps>({} as AddressProps);
  const [, setDocuments] = useState<DocumentsProps[]>([] as DocumentsProps[]);
  const [graphicAccount, setGraphicAccount] = useState<GraphicAccount>();

  const [feeBillet, setFeeBillet] = useState(0);
  const [feeP2pIn, setFeeP2pIn] = useState(0);
  const [feeP2pOut, setFeeP2pOut] = useState(0);
  const [feePixIn, setFeePixIn] = useState(0);
  const [feePixOut, setFeePixOut] = useState(0);
  const [feeTedOut, setFeeTedOut] = useState(0);
  const [limitDay, setLimitDay] = useState(0);
  const [limitNightly, setLimitNightly] = useState(0);
  const [limitRequestStatus, setLimitRequestStatus] = useState("");

  const [limitMonth, setLimitMonth] = useState(0);

  const [show, setShow] = useState(false);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  const token = window.localStorage.getItem("@stricv2:token");

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .get(`/graphic/fee-limits`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setGraphicAccount(data);
        if (data && data.feeLimits && data.feeLimits.length > 0) {
          setFeeBillet(data.feeLimits[0].feeBillet);
          setFeeP2pIn(data.feeLimits[0].feeP2pIn);
          setFeeP2pOut(data.feeLimits[0].feeP2pOut);
          setFeePixIn(data.feeLimits[0].feePixIn);
          setFeePixOut(data.feeLimits[0].feePixOut);
          setFeeTedOut(data.feeLimits[0].feeTedOut);
          setLimitDay(data.feeLimits[0].limitDay);
          setLimitNightly(data.feeLimits[0].limitNightly);
          setLimitMonth(data.feeLimits[0].limitMonth);
          setLimitRequestStatus(data?.feeLimitChangeRequests[0]?.status ?? "");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id, token]);

  async function handleForm(e: FormEvent) {
    e.preventDefault();
    setLoading(true);

    try {
      await api.patch(`/backoffice/accounts/${id}`, {
        id: id,
        feePixIn,
        feePixOut,
        feeTedOut,
        feeP2pOut,
        feeBillet,
        limitDay,
        limitNightly,
        limitMonth,
      });

      setLoading(false);
      setShow(true);
    } catch {
      setLoading(false);
      //
    }
  }
  console.log("RESPONSE DO 04:");

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  const handleAmountChange = (newValue: number) => {
    if (!isNaN(newValue)) {
      setAmount(newValue);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post(
        "/graphic/fee-limits/change/request",
        {
          feePixIn,
          feePixOut,
          feeTedOut,
          feeP2pOut,
          feeBillet,
          limitDay,
          limitNightly,
          limitMonth,
          status: "waiting",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      handleOpenDownloadModal();
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    }
  };

  async function getKeys() {
    service
      .get("/pix/keys")
      .then(({ data }) => {
        setKeys(data.keys);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.value);
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const userId = user.id;
  console.log(userId, "eu sou o id:");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  function parseStatus(status: string): string {
    if (status === "WAITING") {
      return "Aguardando";
    }

    if (status === "CREATED") {
      return "Aguardando";
    }

    if (status === "PROCESSED") {
      return "Em processamento";
    }

    if (status === "REGISTERED") {
      return "Registrada";
    }

    if (status === "PRE-CANCELED") {
      return "Em cancelamento";
    }

    if (status === "CANCELED") {
      return "Cancelada";
    }

    return status;
  }

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOpenDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const handleCloseDownloadModal = () => {
    setIsDownloadModalOpen(false);
    navigate("/myLimits");
  };

  const inputWidth = `${Math.max(100, inputValue.length * 8)}px`; // Adjust the multiplier as needed
  const width = inputValue === "" ? "300px" : inputWidth;

  let teste = "a";

  return (
    <Container>
      <Content>
        <Table>
          <TableWrapper>
            <LeftDiv>
              <img src={bgLogoLeft} alt={"bank"} />
            </LeftDiv>

            <RightDiv>
              <QrContainer>
                <div className={classNames("space-y-6 sm:px-6 lg:px-0 w-ful", "lg:col-span-10")}>
                  <section aria-labelledby="payment-details-heading">
                    <>
                      <form onSubmit={handleForm}>
                        <div className="sm:overflow-hidden mt-10">
                          <div className="bg-gray py-6 px-4 sm:p-6">
                            <Skeleton isActive={loading} repeat={3} />
                            {!loading && (
                              <>
                                <div>
                                  <h2
                                    id="payment-details-heading"
                                    className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                                  >
                                    Meus limites:
                                  </h2>
                                  <p className="mt-1 text-sm text-gray-500"></p>
                                </div>

                                <div className="mt-6 grid grid-cols-6 gap-6">
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      Boleto
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={feeBillet}
                                      onChange={(_event: FormEvent, value: number) => setFeeBillet(value)}
                                    />
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      TED
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={feeTedOut}
                                      onChange={(_event: FormEvent, value: number) => setFeeTedOut(value)}
                                    />
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      P2P Saída (Entre contas)
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={feeP2pOut}
                                      onChange={(_event: FormEvent, value: number) => setFeeP2pOut(value)}
                                    />
                                  </div>
                                </div>

                                <div className="mt-6 grid grid-cols-6 gap-6">
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      P2P Entrada (Entre contas)
                                    </label>
                                    <input
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      type="text"
                                      value="Ilimitado"
                                      disabled
                                    />
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      Pix (Saída)
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={feePixOut}
                                      onChange={(_event: FormEvent, value: number) => setFeePixOut(value)}
                                    />
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      Pix (Entrada)
                                    </label>
                                    <input
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      type="text"
                                      value="Ilimitado"
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="mt-5">
                                  <h2
                                    id="payment-details-heading"
                                    className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                                  >
                                    Meus limites operacionais:
                                  </h2>
                                </div>

                                <div className="mt-6 grid grid-cols-6 gap-6">
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      Limite diário
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={limitDay}
                                      onChange={(_event: FormEvent, value: number) => setLimitDay(value)}
                                    />
                                  </div>

                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      Limite notuno
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={limitNightly}
                                      onChange={(_event: FormEvent, value: number) => setLimitNightly(value)}
                                    />
                                  </div>
                                  <div className="col-span-2 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                      Limite mensal
                                    </label>
                                    <IntlCurrencyInput
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      currency="BRL"
                                      config={currencyConfig}
                                      value={limitMonth}
                                      onChange={(_event: FormEvent, value: number) => setLimitMonth(value)}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </form>

                      <div
                        aria-live="assertive"
                        className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
                      >
                        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                          <Transition
                            show={show}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="p-4">
                                <div className="flex items-start">
                                  <div className="flex-shrink-0">
                                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                  </div>
                                  <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">Configurações salvar</p>
                                    <p className="mt-1 text-sm text-gray-500">
                                      As configurações de taxas foram alteradas.
                                    </p>
                                  </div>
                                  <div className="ml-4 flex-shrink-0 flex">
                                    <button
                                      type="button"
                                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      onClick={() => {
                                        setShow(false);
                                      }}
                                    >
                                      <span className="sr-only">Close</span>
                                      <XIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </div>
                    </>
                  </section>

                  <div className={classNames("space-y-6 sm:px-6 lg:px-0 w-ful", "lg:col-span-10")}>
                    <div className="shadow sm:rounded-3xl sm:overflow-hidden mt-8">
                      {limitRequestStatus === "approved" && (
                        <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70">
                          <img src={upLogoLimits} alt={"bank"} className="mr-4 w-16 h-16" />

                          <div>
                            <p className="mt-1 text-sm text-gray-500">
                              Parabéns! Seu pedido de aumento de limite foi aprovado sucesso para análise. <br />{" "}
                              Estamos ansiosos para oferecer a você limites ainda maiores para aproveitar ao máximo os
                              nossos serviços!
                              <br />
                              Obrigado por confiar em nós.
                            </p>
                          </div>
                        </div>
                      )}

                      {limitRequestStatus === "rejected" && (
                        <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70">
                          <img src={upLogoLimits} alt={"bank"} className="mr-4 w-16 h-16" />
                          <div>
                            <p className="mt-1 text-sm text-gray-500">
                              Lamentamos informar que, após analisar sua solicitação, não podemos conceder o aumento de
                              limite neste momento.
                              <br /> Nossas políticas e procedimentos atuais não nos permitem atender sua solicitação.
                              <br /> Agradecemos sua compreensão e permanecemos à disposição para qualquer outra questão
                              ou solicitação que possa ter.
                            </p>
                          </div>
                        </div>
                      )}

                      {limitRequestStatus === "" && (
                        <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70">
                          <img src={upLogoLimits} alt={"bank"} className="mr-4 w-16 h-16" />

                          <div>
                            <p className="mt-1 text-sm text-gray-500">
                              Está pronto para dar um passo adiante? Solicite o aumento do seu limite agora mesmo!{" "}
                              <br /> Preencha conforme desejado, escolha os limites e clique em enviar para iniciar o
                              processo.
                            </p>
                          </div>
                        </div>
                      )}

                      {limitRequestStatus === "waiting" && (
                        <div className="shadow sm:rounded-3xl sm:overflow-hidden mt-8">
                          <div className="bg-white flex py-2 px-4 sm:p-6 mx-auto w-70 space-x-4">
                            <div>
                              <p className="mt-1 text-sm text-gray-500">
                                <strong>Seu pedido foi enviado para análise.</strong>
                                <br />
                                Aguarde, em até 48 horas você terá a<br />
                                Resposta sobre o aumento do seu limite!
                              </p>
                            </div>
                            <img src={analisysLogoLimits} alt={"bank"} />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="bg-gray flex flex-col items-center py-2 px-4 sm:p-6 mx-auto w-96">
                      {!loading && (
                        <div className="flex justify-center">
                          {/*<button
        type="submit"
        className="bg-black text-white border border-transparent rounded-md shadow-sm py-3 px-8 inline-flex justify-center text-sm font-medium hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
      >
        Cancelar
  </button>*/}
                          {limitRequestStatus !== "waiting" && (
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="bg-amber-700 border border-transparent rounded-md shadow-sm py-3 px-8 inline-flex justify-center text-sm font-medium text-white hover:bg-amber-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ml-4"
                            >
                              Solicitar aumento
                            </button>
                          )}
                          {isDownloadModalOpen && (
                            <div
                              className="modal"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <div
                                className="modal-content"
                                id="download-modal"
                                style={{
                                  backgroundColor: "#fefefe",
                                  padding: "20px",
                                  borderRadius: "10px",
                                  maxWidth: "400px",
                                  maxHeight: "100%",
                                }}
                              >
                                <img src={successLogoLimits} alt={"bank"} />

                                <p
                                  className="text-center mb-4 mx-4"
                                  style={{
                                    color: "#5D5D5D",
                                    height: "61.42px",
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: "16.18px",
                                  }}
                                >
                                  Seu pedido foi enviado para análise. Aguarde, em até 48 horas você terá a resposta
                                  sobre o aumento do seu limite!{" "}
                                </p>

                                <button
                                  onClick={handleCloseDownloadModal}
                                  style={{
                                    background: "#274683",
                                    borderRadius: "20px",
                                  }}
                                  className="w-full px-10 py-4 mb-4  text-white"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </QrContainer>
            </RightDiv>
          </TableWrapper>
        </Table>
      </Content>
    </Container>
  );
};

const PropDetail: FC<{
  title: string;
  value: string | number;
  cols?: number;
}> = ({ title, value, cols = 4 }) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <input
        type="text"
        name="name"
        id="name"
        disabled
        value={value}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
      />
    </div>
  );
};

import "./styles.css";
import Select from "react-select";
import { api } from "src/services/api";
import Modal from "src/components/Modal";
import DatePicker from "react-datepicker";
import { StringifyOptions } from "querystring";
import { Button } from "src/components/button";
import { Machine, PosDetail } from "./interface";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { DiscAlbumIcon, Download } from "lucide-react";
import React, { useState, useEffect, FC } from "react";
import { useNotification } from "src/hooks/notification";
import { Container, Content, ContentWrapper, Table, TableWrapper, Text } from "../Tax/styles";

export function ForwardInvoicePagbank() {
  const [selectedMachines, setSelectedMachines] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [amount, setAmount] = useState<string>("");
  const [data, setData] = useState<PosDetail[]>();
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [observations, setObservations] = useState<string>("");
  const [isRepassModalOpen, setIsRepassModalOpen] = useState<boolean>(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const handleOpenRepassModal = () => {
    setIsRepassModalOpen(true);
  };

  const handleCloseRepassModal = () => {
    setIsRepassModalOpen(false);
  };

  const handleConfirmRepass = () => {
    console.log("Repasse confirmado com as seguintes observações:", observations);
    setIsRepassModalOpen(false);
  };

  const handleCancelRepass = () => {
    setIsRepassModalOpen(false);
  };

  const handleOpenDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const handleCloseDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const handleConfirmDownload = () => {
    console.log("Relatório baixado com sucesso!");
    setIsDownloadModalOpen(false);
  };

  const handleCancelDownload = () => {
    setIsDownloadModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const repassModal = document.getElementById("repass-modal");
      const downloadModal = document.getElementById("download-modal");

      if (
        repassModal &&
        event.target instanceof Node &&
        !repassModal.contains(event.target) &&
        downloadModal &&
        event.target instanceof Node &&
        !downloadModal.contains(event.target)
      ) {
        setIsRepassModalOpen(false);
        setIsDownloadModalOpen(false);
      }
    };
    if (isRepassModalOpen || isDownloadModalOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isRepassModalOpen, isDownloadModalOpen]);

  useEffect(() => {
    fetchPosDetails();
  }, []);

  const token = localStorage.getItem("@backoffice:token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchPosDetails = async () => {
    try {
      const url = "/pagbank/wallet-machines";
      const response = await api.get(url, config);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscountAmountChange = (machineId: string, value: string) => {
    console.log(`Valor do desconto para a máquina ${machineId}: ${value}`);
  };

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Relatório das maquininhas</span>
          </Text>
          <div>
            <span className="mt-2">Selecione um período para filtrar</span>
            <div className="flex items-center">
              <DatePicker
                className="bg-gray-300 rounded px-2 py-1 mr-2"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                locale="pt"
                dateFormat="dd/MM/yyyy"
              />
              <span className="mx-2">até</span>
              <DatePicker
                className="bg-gray-300 rounded px-2 py-1 mr-2"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                locale="pt"
                dateFormat="dd/MM/yyyy"
              />
              <button
                className="ml-2 bg-yellow-600 text-white px-4 py-2 rounded-md text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => fetchPosDetails()}
              >
                Filtrar
              </button>
            </div>
          </div>
          <table className="flex mt-4 bg-white rounded-lg shadow-lg p-4 w-full flex-col gap-2">
            <thead className="flex w-full flex-col">
              <tr className="font-semibold grid grid-cols-5 items-center h-20">
                <th style={{ color: "#274683", padding: "10px" }}>Nome da conta</th>
                <th style={{ color: "#274683" }}>Maquininhas</th>
                <th style={{ color: "#274683" }}>Total a repassar</th>
                <th style={{ color: "#274683" }}>
                  Valor do desconto <br />
                  (opcional)
                </th>
                <th style={{ color: "#274683" }}>Fazer repasse</th>
              </tr>
            </thead>
            <tbody className="flex flex-col gap-4">
              {data?.map((graphic, index) => (
                <GraphicDetail
                  key={graphic.id}
                  data={graphic}
                  endDate={endDate}
                  startDate={startDate}
                  onPay={fetchPosDetails}
                />
              ))}
            </tbody>
          </table>
        </ContentWrapper>
      </Content>
      {/* Modais */}
      {isRepassModalOpen && (
        <div
          className="modal"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            className="modal-content"
            id="repass-modal"
            style={{
              backgroundColor: "#fefefe",
              padding: "20px",
              borderRadius: "10px",
              maxWidth: "400px",
            }}
          >
            <span className="close" onClick={handleCloseRepassModal} style={{ float: "right", cursor: "pointer" }}>
              &times;
            </span>
            <p
              className="text-center mb-4"
              style={{
                color: "#5D5D5D",
                height: "31.42px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "26.18px",
              }}
            >
              Inserir observações de Repasse
            </p>
            <textarea
              rows={4}
              cols={50}
              value={observations}
              placeholder="Ex: Solicitado pelo gerente"
              onChange={(e) => setObservations(e.target.value)}
              style={{
                borderRadius: "5px",
                padding: "5px",
                marginBottom: "10px",
                width: "100%",
                border: "1px solid #e5e7eb",
              }}
            />

            <button
              onClick={handleConfirmRepass}
              style={{ background: "#274683", borderRadius: "20px" }}
              className="w-full px-10 py-4 mb-4 rounded text-white"
            >
              Confirmar repasse
            </button>
            <button
              style={{ borderRadius: "20px" }}
              onClick={handleCancelRepass}
              className="w-full px-10 py-4 bg-red-500 rounded text-white"
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
      {isDownloadModalOpen && (
        <div
          className="modal"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            className="modal-content"
            id="download-modal"
            style={{
              backgroundColor: "#fefefe",
              padding: "20px",
              borderRadius: "10px",
              maxWidth: "400px",
            }}
          >
            <span className="close" onClick={handleCloseDownloadModal} style={{ float: "right", cursor: "pointer" }}>
              &times;
            </span>
            <p
              className="text-center mb-4 mx-4"
              style={{
                color: "#5D5D5D",
                height: "31.42px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "26.18px",
              }}
            >
              Deseja baixar o relatório?
            </p>

            <button
              onClick={handleConfirmDownload}
              style={{ background: "#274683", borderRadius: "20px" }}
              className="w-full px-10 py-4 mb-4  text-white"
            >
              Sim
            </button>

            <button
              onClick={handleCancelDownload}
              style={{ borderRadius: "20px" }}
              className="w-full px-10 py-4 bg-red-500 rounded text-white"
            >
              Não
            </button>
          </div>
        </div>
      )}
    </Container>
  );
}

// fazer um componente pra cada conta grafica

const GraphicDetail: FC<{ data: PosDetail; onPay: () => void; startDate: Date; endDate: Date }> = ({
  data,
  onPay,
  endDate,
  startDate,
}) => {
  const [selectedMachines, setSelectedMachines] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState("");
  const [description, setDescription] = useState("");

  const fullSelectedMachines = data.pagBankMachines.filter((m) => {
    const fullMachine = selectedMachines.find((sm) => sm.value == m.id);
    if (!fullMachine) return false;

    if (m.machineTransactions && m.machineTransactions.length > 0) {
      m.machineTransactions = m.machineTransactions.filter((transaction) => {
        console.log(transaction.status);
        const transactionDate = new Date(transaction.created_at);

        if (transaction.status !== 4) return false;
        if (startDate && transactionDate < startDate) return false;
        if (endDate && transactionDate > endDate) return false;

        return true;
      });
    }

    return true;
  });

  console.log(fullSelectedMachines);

  const totalToPay = sumTotalMachines(fullSelectedMachines);
  console.log(totalToPay);
  const token = localStorage.getItem("@backoffice:token");

  const { setOpenNotification } = useNotification();

  const floatAmount = parseFloat(discount);

  const handlePayMachines = async () => {
    try {
      const machineIds = selectedMachines.map((m) => m.value);
      if (machineIds.length > 0) {
        setLoading(true);

        const response = await api.post(
          "/pagbank/aproove-payments",
          {
            machineIds,
            description,
            discount: floatAmount,
            endDate,
            startDate,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response);
        const data: { payedMachines: number; payedTransactions: number } = response.data;

        setSelectedMachines([]);
        setLoading(false);
        setDiscount("");
        setDescription("");
        console.log(response);
        setModalDescriptionIsOpen(false);
        setOpenNotification({
          title: "Pago!",
          notification: `${data.payedTransactions} transações processadas em ${data.payedMachines} maquininhas.`,
          type: "success",
        });
        onPay();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [modalDescriptionIsOpen, setModalDescriptionIsOpen] = useState(false);

  return (
    <>
      <Modal open={modalDescriptionIsOpen} onClickClose={() => setModalDescriptionIsOpen(false)}>
        <div className="flex flex-col items-center p-8 w-[400px] gap-4">
          <span className="text-lg font-bold">Repasse com desconto</span>
          <div className="flex flex-col w-full">
            <span className="">Escreva uma descrição</span>
            <input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              placeholder="descrição"
              className="w-full h-12 rounded-lg shadow-md px-2 text-center bg-white border border-zinc-100"
            />
          </div>
          <button
            className="w-full items-center justify-center h-12 bg-black rounded-md"
            onClick={() => {
              if (!description) {
                return setOpenNotification({
                  title: "Ops...",
                  notification: `Digite uma descrição`,
                  type: "error",
                });
              }

              handlePayMachines();
            }}
            disabled={loading}
            color="#000"
          >
            <span className="text-white text-sm">{loading ? "Repassando..." : "Fazer repasse com desconto"}</span>
          </button>
        </div>
      </Modal>
      <tr
        className="grid grid-cols-5 items-center gap-4"
        key={data.id}
        // onClick={() => handleMachineSelect(data.machine)}
        style={{
          borderRadius: "10px",
        }}
      >
        <td className="text-sm font-semibold text-zinc-600">{data.name}</td>
        <td style={{ color: "#8A8A8A", textAlign: "center" }}>
          <Select
            isMulti
            options={data.pagBankMachines
              .filter((item) => item.total > 0)
              .map((option) => ({
                value: option.id,
                label: option.description,
              }))}
            value={selectedMachines}
            onChange={(newOptions, selected) => {
              console.log(selected);
              const action = selected.action;

              if (action == "clear") {
                setSelectedMachines([]);
              }

              if (action == "remove-value") {
                const removedValue = selected.removedValue;

                console.log(selectedMachines.filter((o) => o.value != removedValue.value));

                return setSelectedMachines((old) => old.filter((o) => o.value !== removedValue.value));
              }

              const option = selected.option;

              if (action == "select-option" && option) {
                const existsOption = selectedMachines.find((m) => m.value === option.value);

                console.log(existsOption);

                if (!existsOption) {
                  setSelectedMachines((old) => [...old, option]);
                }
              }
            }}
          />
        </td>
        <td style={{ color: "#54E751", textAlign: "center" }}>R$ {toLocaleAmount(totalToPay)}</td>
        <td style={{ color: "#8A8A8A", textAlign: "center" }}>
          <div
            className="flex items-center overflow-hidden gap-1"
            style={{
              height: "24px",
              width: "100%",
              borderRadius: "6px",
              padding: "20px",
              boxSizing: "border-box",
              border: "1px solid #E3E3E3",
              textAlign: "center",
            }}
          >
            <span>R$</span>
            <input className="flex" type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} />
          </div>
        </td>
        <td className="text-center rounded-lg">
          <button
            disabled={loading}
            className="p-2 w-full rounded-lg text-white"
            onClick={() => {
              if (discount && Number(discount) > Number(totalToPay)) {
                setOpenNotification({
                  title: "Atenção!",
                  notification: `O valor do desconto não pode ser superior ao valor do repasse`,
                  type: "error",
                });
                return;
              }
              if (discount) {
                setModalDescriptionIsOpen(true);
              } else {
                handlePayMachines();
              }
            }}
            style={{ background: "#274683" }}
          >
            {loading ? "Repassando..." : "Repassar"}
          </button>
        </td>
      </tr>
    </>
  );
};

export default ForwardInvoicePagbank;

const sumTotalMachines = (machines: Machine[]): number => {
  let total = 0;

  for (const machine of machines) {
    for (const transaction of machine.machineTransactions) {
      total += transaction.netAmount;
    }
  }

  return total;
};

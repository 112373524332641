/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from "react";
import { Container, Content, ContentWrapper } from "../Tax/styles";
import { api } from "src/services/api";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import { Dot } from "lucide-react";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TransactionDataPos } from "src/pages/receiptPos";
import { PagBankPaymentStatus } from "src/components/cardsTrade/types";

const formatValue = (value: number) => `R$ ${toLocaleAmount(value)}`;

const TransactionDetail = ({
  label,
  value,
  textColor,
}: {
  label: string;
  value: any;
  textColor?: string;
}) => (
  <p className="text-gray-500 mb-2">
    <span className="mr-2">{label}:</span>
    <span className={`font-bold ${textColor ? textColor : "text-gray-500"}`}>
      {typeof value === "number" ? formatValue(value) : value}
    </span>
  </p>
);

export function TransactionsPagbankDetails() {
  const token = localStorage.getItem("@backoffice:token");
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionData } = location.state as {
    transactionData: TransactionDataPos;
  };
  return (
    <Container>
      <Content>
        <ContentWrapper>
          <section aria-labelledby="payment-details-heading">
            <>
              <form action="#" method="POST">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="bg-white py-6 px-4 sm:p-6">
                    <>
                      <div>
                        <h2
                          id="payment-details-heading"
                          className="flex justify-between text-lg leading-6 font-bold text-gray-500 mb-8"
                        >
                          Detalhes da transação
                        </h2>
                        <TransactionDetail
                          label="Status"
                          value={
                            transactionData.status ===
                              PagBankPaymentStatus["COMPLETE"] ||
                            transactionData.status ===
                              PagBankPaymentStatus["DONE"]
                              ? "Aprovada"
                              : transactionData.status ===
                                PagBankPaymentStatus["ANALYSIS"]
                              ? "Em analise"
                              : transactionData.status ===
                                PagBankPaymentStatus["REFOUND"]
                              ? "Devolvida"
                              : "Cancelada"
                          }
                        />
                        <TransactionDetail
                          label="Código da transação"
                          value={transactionData.transactionId}
                        />

                        <TransactionDetail
                          label="Valor Bruto"
                          value={transactionData.grossAmount}
                        />
                        <TransactionDetail
                          label="Taxas"
                          value={transactionData.taxTotal}
                          textColor="text-red-500"
                        />
                        <TransactionDetail
                          label="Total (líquido)"
                          value={transactionData.netAmount}
                        />
                        <TransactionDetail
                          label="Meio de pagamento"
                          value={
                            transactionData.type == "1"
                              ? `Crédito ${transactionData.installments}x`
                              : `Débito`
                          }
                        />

                        <TransactionDetail
                          label="Número de série da maquininha"
                          value={transactionData.machineSerial}
                        />
                      </div>
                    </>
                  </div>
                </div>
              </form>
            </>
          </section>
        </ContentWrapper>
      </Content>
    </Container>
  );
}

import React, { useState } from 'react';
import onImage from './../../../assets/on.png';
import offImage from './../../../assets/off.png';
import { ToogleDiv } from './styles';
import axios from 'axios';
import { useNotification } from 'src/hooks/notification';

interface ToggleSwitchFavoriteProps {
  userId: string; // ID da conta
  statusWalletActived: boolean; // Status inicial da carteira
  accountStatus: 'active' | 'under_review'; // Status da conta
}

const ToggleActiveWallet: React.FC<ToggleSwitchFavoriteProps> = ({ userId, statusWalletActived, accountStatus }) => {
  const [isOn, setIsOn] = useState(statusWalletActived);
  const token = localStorage.getItem('@stricv2:account');
  const { setOpenNotification } = useNotification();

  const handleToggle = async () => {
    if (accountStatus === 'active') {
      // Se a conta estiver ativa, não faz nada
      return;
    }

    const newActivedStatus = !isOn;
    setIsOn(newActivedStatus);

    try {
      console.log('Início da função handleToggle');

      // Determina o valor do payload baseado no status da conta
      const payload = accountStatus === 'under_review' ? { activedWallet: 'active' } : { activedWallet: newActivedStatus };

      // Fazer chamada para API para atualizar o status no servidor
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/update-wallet-active-status/${userId}`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('Resposta da requisição PATCH:', response);

      if (response.status === 200) {
        console.log('Atualização bem-sucedida no servidor');
        setOpenNotification({
          type: 'success',
          title: 'Atualização Concluída:',
          notification: newActivedStatus ? 'Wallet Ativada com Sucesso!' : 'Wallet Ativada com Sucesso!',
        });
      }
    } catch (error) {
      console.error('Erro ao atualizar status no servidor:', error);

      // Reverter o estado em caso de erro
      setIsOn(!isOn);

      setOpenNotification({
        type: 'error',
        title: 'Erro na tentativa de ativação da wallet!',
        notification: 'Houve um erro ao tentar ativar a wallet, tente novamente mais tarde.',
      });
    } finally {
      console.log('Final da função handleToggle');
    }
  };

  return (
    <ToogleDiv
      onClick={accountStatus === 'under_review' ? handleToggle : undefined}
      style={{ cursor: accountStatus === 'active' ? 'not-allowed' : 'pointer' }}
    >
      <img
        src={accountStatus === 'under_review' ? offImage : isOn ? onImage : offImage}
        alt={accountStatus === 'under_review' ? 'Off' : isOn ? 'On' : 'Off'}
        style={{ width: '100px', height: '40px' }}
      />
    </ToogleDiv>
  );
};

export default ToggleActiveWallet;

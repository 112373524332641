import React, { useRef, useState } from "react";
import {
  BackLogo,
  KeyboardContainer,
  KeyboardKey,
  KeyboardRow,
  ShiftIcon,
} from "./styles";
import Logo from "./../../assets/back.png";

interface VirtualKeyboardProps {
  onKeyPress: (key: string) => void;
  setVirtualKeyboardDocument?: React.Dispatch<React.SetStateAction<string>>;
  keyWidth?: string; // Largura padrão da tecla
  keyHeight?: string; // Altura padrão da tecla
  keyMargin?: string; // Margem entre as teclas
  isKeyboardVisible: boolean;
  closeKeyboard: () => void;
}

const shiftChars: Record<string, string> = {
  "1": "!",
  "2": "@",
  "3": "#",
  "4": "$",
  "5": "%",
  "6": "¨",
  "7": "&",
  "8": "*",
  "9": "(",
  "0": ")",
  "-": "_",
  "=": "+",
  // Adicione outros caracteres conforme necessário
};

const VirtualKeyboard: React.FC<VirtualKeyboardProps> = ({
  onKeyPress,
  keyWidth = "57.5px",
  keyHeight = "50px",
  keyMargin = "5px",
  isKeyboardVisible,
  closeKeyboard,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [virtualKeyboardDocument, setVirtualKeyboardDocument] =
    useState<string>("");
  const [isShiftClicked, setShiftClicked] = useState(false);
  const [lastPressedKey, setLastPressedKey] = useState<string>("");

  const keyboardRows = [
    [
      "`",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "-",
      "=",
      "Backspace",
    ],
    ["Tab", "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "[", "]", "\\"],
    ["Caps", "a", "s", "d", "f", "g", "h", "j", "k", "l", ",", "'", "Enter"],
    ["Shift", "z", "x", "c", "v", "b", "n", "m", ",", ".", "/", "\u2191", "  "],
    ["Ctrl", "Alt", "Space", "Alt", "Ctrl", "\u2190", "\u2193", "\u2192"],
  ];

  const handleKeyboardClick = () => {
    if (isKeyboardVisible) {
      closeKeyboard();
    }
  };

  const handleCaps = () => {
    // Inverte o estado de Caps Lock
    setIsUpperCase(!isUpperCase);
  };

  const handleKeyPress = (key: string) => {
    console.log("Tecla pressionada:", key);
    // Atualize o estado da última tecla pressionada
    setLastPressedKey(key || ""); // Garanta que key não seja nulo

    const handleShiftControl = (key: string) => {
      if (isShiftClicked && key in shiftChars) {
        return shiftChars[key];
      }
      return key;
    };

    if (["\u2190", "\u2191", "\u2192", "\u2193"].includes(key)) {
      handleArrowClick(key);
      return;
    }

    if (key === "Shift") {
      handleShiftClick();
      return;
    }
    key = handleShiftControl(key);

    if (key in shiftChars && (isUpperCase || isShiftClicked)) {
      key = shiftChars[key];
    }

    if (
      [
        "Enter",
        "Shift",
        "Backspace",
        "Space",
        "Alt",
        "Ctrl",
        "Caps",
        "Tab",
      ].includes(key)
    ) {
      if (key === "Backspace") {
        handleBackspace();
        return;
      }

      if (key === "Caps") {
        handleCaps();

        if (isUpperCase) {
          setIsUpperCase(false);
        }
        return;
      }

      if (key === "Space") {
        console.log("Chamando handleSpace");
        handleSpace();
      } else if (key === "ArrowRight") {
        console.log("Chamando handleArrowRight");
        handleArrowRight();
      } else if (key === "ArrowLeft") {
        console.log("Chamando handleArrowLeft");
        handleArrowLeft();
      } else if (key === "Tab") {
        console.log("Chamando handleTab");
        //  handleTab();
      } else {
        onKeyPress(key);
        // Desativa automaticamente o Caps Lock após pressionar qualquer letra quando o Caps Lock está ativado
        if (isUpperCase && !isShiftClicked) {
          setIsUpperCase(false);
        }
      }
    } else {
      const processedKey = isUpperCase ? key.toUpperCase() : key;
      onKeyPress(processedKey);

      // Escrever no input usando a referência
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(
        inputRef.current?.value.length || 0,
        inputRef.current?.value.length || 0
      );
      document.execCommand("insertText", false, processedKey);

      setVirtualKeyboardDocument((prev: string) => prev + processedKey);
    }
  };

  const handleShiftClick = () => {
    // Atualize o estado quando o Shift for clicado
    setShiftClicked(!isShiftClicked);

    // Ajuste o estado de maiúsculas/minúsculas apenas se a última tecla não for 'Space'
    if (lastPressedKey !== "Space") {
      setIsUpperCase(!isUpperCase);
    }

    // Atualize o estado de maiúsculas/minúsculas, exceto para teclas específicas
    if (
      !["Space", "Caps", "Tab", "Alt", "Backspace", "Ctrl", "Enter"].includes(
        lastPressedKey
      )
    ) {
      setIsUpperCase(!isUpperCase);
    }
  };

  const handleSpace = () => {
    const processedKey = " ";

    // Adicione o espaço diretamente ao input
    inputRef.current?.focus();
    document.execCommand("insertText", false, processedKey);

    setVirtualKeyboardDocument((prev) => prev + processedKey);
  };

  // ...
  const handleBackspace = () => {
    const processedKey = String.fromCharCode(8); // Código ASCII para backspace

    // Adiciona o backspace diretamente ao input
    inputRef.current?.focus();
    document.execCommand("delete", false, processedKey);

    // Atualiza o estado, se necessário
    setVirtualKeyboardDocument((prev) => prev.slice(0, -1));
  };

  const handleTab = () => {
    // Aqui você pode adicionar a lógica para pular para o próximo campo de entrada
    // Por exemplo, você pode usar a referência do inputRef para encontrar o próximo campo e focar nele.
    // inputRef.current?.nextElementSibling?.focus();
  };

  const handleArrowRight = () => {
    const processedKey = "ArrowRight";

    // Adicione a seta para a direita diretamente ao input
    inputRef.current?.focus();
    document.execCommand("insertText", false, processedKey);

    setVirtualKeyboardDocument((prev) => prev + processedKey);
  };

  const handleArrowLeft = () => {
    const processedKey = "ArrowLeft";

    // Adicione a seta para a esquerda diretamente ao input
    inputRef.current?.focus();
    document.execCommand("insertText", false, processedKey);

    setVirtualKeyboardDocument((prev) => prev + processedKey);
  };

  // ...

  const handleArrowClick = (arrow: string) => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const cursorPos = inputElement.selectionStart;

      if (cursorPos !== null) {
        switch (arrow) {
          case "\u2190":
            // Lógica para mover para a esquerda
            if (cursorPos > 0) {
              inputElement.setSelectionRange(cursorPos - 1, cursorPos - 1);
            }
            break;
          case "\u2192":
            // Lógica para mover para a direita
            if (cursorPos < inputElement.value.length) {
              inputElement.setSelectionRange(cursorPos + 1, cursorPos + 1);
            }
            break;
          default:
            // Não fazer nada para outras setas (cima e baixo)
            break;
        }
      }
    }
  };

  // ...

  return (
    <KeyboardContainer>
      {keyboardRows.map((row, rowIndex) => (
        <KeyboardRow key={rowIndex}>
          {row.map((key, keyIndex) => (
            <KeyboardKey
              key={`${rowIndex}-${keyIndex}`}
              onClick={() => handleKeyPress(key)}
              onKeyDown={(event) => {
                const arrowKeys = [
                  "ArrowLeft",
                  "ArrowUp",
                  "ArrowRight",
                  "ArrowDown",
                ];
                const arrowUnicode: { [key: string]: string } = {
                  ArrowLeft: "\u2190",
                  ArrowUp: "\u2191",
                  ArrowRight: "\u2192",
                  ArrowDown: "\u2193",
                };

                if (arrowKeys.includes(event.key)) {
                  // Se a tecla pressionada for uma seta, trate-a utilizando a função handleArrowClick
                  event.preventDefault(); // Evita o comportamento padrão das setas nos inputs
                  handleArrowClick(arrowUnicode[event.key]);
                  console.log("Arrow key pressed:", event.key);
                }
              }}
              style={{
                width:
                  key === "Shift"
                    ? "173px"
                    : key === "Enter"
                    ? "99px"
                    : key === "Backspace"
                    ? "107px"
                    : key === "Space"
                    ? "325px"
                    : key === "Caps"
                    ? "130px"
                    : key === "Tab"
                    ? "105px"
                    : key === "Ctrl"
                    ? "105px"
                    : key === "Alt"
                    ? "102.5px"
                    : keyWidth,
                height: key === "Shift" ? "50px" : keyHeight,
                margin: keyMargin,
                color: isShiftClicked
                  ? [
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "0",
                      "-",
                      "=",
                    ].includes(key)
                    ? "white"
                    : "white"
                  : "white",
              }}
            >
              {key === "Shift" ? (
                <ShiftIcon
                  onClick={handleShiftClick}
                  isClicked={isShiftClicked}
                />
              ) : // Renderize a tecla com base no estado de maiúsculas/minúsculas
              isUpperCase &&
                ![
                  "Space",
                  "Caps",
                  "Tab",
                  "Alt",
                  "Backspace",
                  "Ctrl",
                  "Enter",
                ].includes(key) ? (
                key.toUpperCase()
              ) : (
                key.toLowerCase()
              )}

              {key === "  " ? (
                <BackLogo src={Logo} onClick={handleKeyboardClick} />
              ) : (
                ""
              )}

              {
                isShiftClicked ? shiftChars[key] : "" // Substituir o '' por uma logica de estado que isHidden setIshidden, pra fazer os numeros nao aparecerem nas teclas quando isShiftClicked for true
              }
            </KeyboardKey>
          ))}
        </KeyboardRow>
      ))}
    </KeyboardContainer>
  );
};

export default VirtualKeyboard;

import styled from 'styled-components';

export const Container = styled.div`

height: 100%;
width: 100%;
border: 1px solid var(--line);
border-radius: 4px;
background-color: transparent;

& + div {
    margin-left: 4px;
}

> input {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-style: none;
    text-align: center;
    color: var(--white);
    font-family: var(--regular);
    font-size: 16px;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }


`;

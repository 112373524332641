import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import {
  Container,
  Content,
  Line,
  Reference,
  Separator,
  Title,
  WrapperBackground,
  WrapperContent,
  WrapperLogo,
  Error,
  ImageLogo,
  NotHaveAnAccountMobile,
  ButtonAcess,
  WrapperForm,
  DivInputMobile,
  DivInputPasswordMobile,
  ButtonVisibility,
  ForgetPassword,
  ButtonSignInMobile,
  ForgetPasswordMobile,
  TitleMobile,
  ButtonAcessSignup,
} from "./styles";
import { parseError } from "../../services/api";
import { useClient } from "../../hooks/client";
import Logo from "../../assets/Group.svg";
import Trancar from "../../assets/trancar.png";
import Aberto from "../../assets/aberto.png";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { ExclamationIcon } from "@heroicons/react/solid";

import User from "../../assets/user.png";
import PasswordKeyPress from "src/components/keyPressVirtualLogin/passwordKeyPress";
import { InputBoardContainer, ModalContainer, ModalOverlay } from "src/components/keyPressVirtualLogin/styles";
import { SignInForm } from "src/components/keyPressVirtualLogin/input-form";

import loginLogo from "src/assets/login-logo.png";
import android from "src/assets/android.png";
import ios from "src/assets/ios.png";
import background from "src/assets/login-background.png";

export const SignIn: React.FC = () => {
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigation = useNavigate();
  const { signIn, account, user } = useAuth();

  const [document, setDocument] = useState("");
  const [password, setPassword] = useState("");
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [virtualKeyboardDocument, setVirtualKeyboardDocument] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //useEffect(() => {
  // Limpar o localStorage imediatamente quando o componente é montado
  // localStorage.clear();
  //}, []); // O array vazio assegura que o efeito só é executado uma vez, ao montar o componente

  const handleDocumentClick = () => {
    setKeyboardVisible(true);
  };

  // const handleDocumentBlur = () => {
  //   setKeyboardVisible(false);
  // };

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((state) => !state);
  };

  const handleKeyboardClose = () => {
    setKeyboardVisible(false);
  };

  const handleKeyboardKeyPress = (key: string) => {
    setDocument(document + key);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Verifica se o clique foi fora do ModalContainer
    if (event.target === event.currentTarget) {
      // Fecha o modal
      handleKeyboardClose();
    }
  };

  const handleOutsideClickClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Verifica se o clique foi fora do ModalContainer
    if (event.target === event.currentTarget) {
      // Fecha o modal
      handleKeyboardClose();
    }
  };

  useEffect(() => {
    if (account) {
      navigation("/");
    }

    if (user?.type === "GRAPHIC") {
      window.location.reload();
    }
  }, [account, user, navigation]);

  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();

    if (!document || !password) {
      return;
    }

    try {
      setLoading(true);
      setError("");
      await signIn({
        document: document.replace(/[/,.,-\s]/g, ""),
        password,
      });

      setLoading(false);
      setError("");
      navigation("/");
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError("");

      console.log(err);

      setError("Usuário e/ou senha inválido.");
    }
  };

  return (
    <Container image={background} bank={client.name}>
      <div className="md:flex flex-col justify-between h-screen py-20 hidden">
        <img src={loginLogo} className="object-cover lg:w-[280px] md:w-[250px] w-[200px]" />

        <div className="flex gap-8">
          <button>
            <img src={android} className="object-cover w-[100px] md:w-[150px]" />
          </button>
          <button>
            <img src={ios} className="object-cover w-[100px] md:w-[150px]" />
          </button>
        </div>
      </div>
      <WrapperContent>
        <Content>
          <ImageLogo>
            <img src={Logo} alt="logo" />
          </ImageLogo>
          <Title
            className="hide-on-small-screens"
            style={{ display: isKeyboardVisible ? "none" : "block" }}
          >
            <b>
              {" "}
              <h1 style={{ fontSize: 50, color: "#4D4D4D" }}>
                Globalize-se
              </h1>{" "}
            </b>
            {/* <Separator
              className="hide-on-small-screens"
              style={{ display: isKeyboardVisible ? "none" : "block" }}
            /> */}
            {/* <b>banking da {client.name}.</b> */}
            <span
              style={{
                display: isKeyboardVisible ? "none" : "block",
                color: "#4D4D4D",
              }}
            >
              A Pixwave tem o plano ideal <br /> para você, garantimos que sua <br />
              experiência financeira chegue a <br /> nível global.
              
            </span>
          </Title>
          <TitleMobile>
            <b>
              {" "}
              <h1 style={{ fontSize: 50, color: "#00cbda" }}>PIXWAVE </h1>{" "}
            </b>
            {/* <b>banking da {client.name}.</b> */}
            <h1
              style={{
                color: "#fff",
              }}
            >
              Globalize-se
            </h1>
          </TitleMobile>
          <Reference>
            <span style={{ color: "#fff" }}> </span>
          </Reference>
          <WrapperForm onSubmit={handleSignIn}>
            <DivInputMobile>
              <img src={User} alt="user" />
              <CpfCnpj
                type="text"
                onChange={(input: any) => setDocument(input.target.value)}
                value={document}
              />
            </DivInputMobile>

            <DivInputPasswordMobile>
              {/*<img src={Trancar} alt="cadeado" />*/}
              <ButtonVisibility
                onClick={handlePasswordVisibility}
                type="button"
              >
                {isPasswordVisible ? (
                  <img src={Aberto} alt="aberto" />
                ) : (
                  <img src={Trancar} alt="trancado" />
                )}
              </ButtonVisibility>
              <input
                color={"black"}
                onChange={(input) => setPassword(input.target.value)}
                value={password}
                type={isPasswordVisible ? "text" : "password"}
              />
            </DivInputPasswordMobile>
            {!!error && (
              <Error>
                <div className="bg-red-50 border-l-4 border-red-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">{error}</p>
                    </div>
                  </div>
                </div>
              </Error>
            )}
            <ForgetPasswordMobile href="/forgot">
              Esqueci minha senha
            </ForgetPasswordMobile>
            <ButtonSignInMobile>
              <Loading
                isLoading={loading}
                style={{ WrapperBackground: "black", marginLeft: "-20px" }}
              />
              Entrar
            </ButtonSignInMobile>
          </WrapperForm>
          <ButtonAcess
            className="hide-on-small-screens"
            onClick={handleDocumentClick}
            style={{
              display: isKeyboardVisible ? "none" : "block",
              //backgroundImage: `url(${ButtonSignInColor})`,
              backgroundColor: "#274683",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "12px",
            }}
          >
            <Loading isLoading={loading} />
            Entrar
          </ButtonAcess>
          <ForgetPassword
            href="/forgot"
            style={{ display: isKeyboardVisible ? "none" : "block" }}
          >
          Esqueci minha senha
          </ForgetPassword>
          <Line style={{ display: isKeyboardVisible ? "none" : "block" }} />

          <ButtonAcessSignup
            to="/create-account-wallet"
            className="hide-on-small-screens"
            onClick={handleDocumentClick}
            style={{
              display: isKeyboardVisible ? "none" : "block",
              borderRadius: "12px",
              border: "2px solid #274683",
              //backgroundImage: `url(${ButtonSignInColor})`,
              //backgroundColor: "#FFF",
              //backgroundRepeat: "no-repeat",
              //backgroundSize: "cover",
            }}
          >
            <Loading isLoading={loading} />
            Abrir conta
          </ButtonAcessSignup>
          {isKeyboardVisible && (
            <>
              <ModalOverlay onClick={handleOutsideClick}>
                <ModalContainer>
                  <SignInForm />

                  <InputBoardContainer />
                  <PasswordKeyPress
                    onKeyPress={(key) => console.log(key)}
                    isKeyboardVisible={isKeyboardVisible}
                    closeKeyboard={handleKeyboardClose}
                  />
                </ModalContainer>
              </ModalOverlay>
            </>
          )}

          {/*         <NotHaveAnAccount  className="hide-on-small-screens" to="/type-create-account" style={{ display: isKeyboardVisible ? 'none' : 'block' }}>
</NotHaveAnAccount>*/}
          {/*} <NotHaveAnAccount
            className="hide-on-small-screens"
            to="/create-account-wallet"
            style={{ display: isKeyboardVisible ? "none" : "block" }}
          >
            <span style={{ color: "#fff" }}>
              Não tem uma conta? Abrir conta
            </span>{" "}
            {client.name}
            </NotHaveAnAccount>*/}
          {/*<NotHaveAnAccountMobile to="/type-create-account">*/}
          <NotHaveAnAccountMobile to="/create-account-wallet">
            <p>Não possui cadastro? </p>
            <p>Cadastre-se aqui</p>
          </NotHaveAnAccountMobile>
        </Content>
      </WrapperContent>
    </Container>
  );
};

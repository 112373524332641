import { FC } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useNavigate } from "react-router-dom";

import "./styles.css";

export const WhyCreateWallet: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center gap-10 items-center background">
      <PhoneFrame onPress={() => navigate("/signin")} />

      <div>
        <svg style={{ position: "relative" }} width="610" height="550">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="600"
            height="530"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>
        <div className="absolute top-[24vh] ml-20 max-2xl:top-[14vh]">
          <h1 className="font-medium text-white text-4xl">
            Por que abrir uma <br />
            conta <strong className="text-[#274683] text-4xl">Wallet?</strong>
          </h1>

          <p className="font-extralight text-white text-lg mt-10 mb-[10vh]">
            A PIXWAVE BANK é uma conta digital criada por <br />
            empreendedores e para empreendedores, <br />
            projetada para reduzir burocracias, eliminar taxas <br />
            abusivas e descomplicar a administração das <br />
            finanças de quem empreende
          </p>

          <h3 className="font-extralight text-white text-xl mb-3">
            Comece agora, é <strong className="font-bold text-xl">rápido e fácil!</strong>
          </h3>

          <GradientButton
            title="Abrir Wallet"
            tailwindStyles="bg-[#274683] w-[30rem] h-16 text-white text-lg"
            onClick={() => navigate("/create-account-wallet/choose-account-type")}
          />
        </div>
      </div>
    </div>
  );
};

import styled from 'styled-components';

import {ReactComponent as EyeEnabledIconSVG} from '../../assets/eyeEnabled.svg'
import {ReactComponent as EyeDisabledIconSVG} from '../../assets/eyeDisabled.svg'

export const EyeEnabledIcon = styled(EyeEnabledIconSVG)``
export const EyeDisabledIcon = styled(EyeDisabledIconSVG)``

interface props {
    borderRadius: string;
    borderColor: string;
    height: string;
}

export const Container = styled.div<props>`
  
    height: ${props => props.height};
    width: 100%;
    border: 1px solid ${props => props.borderColor};
    border-radius: ${props => props.borderRadius};
    display: flex;
    align-items: center;
    padding: 16px 14px;

    > input {
        outline: 0;
        background: none;
        border-style: none;
        width: 100%;

        &::placeholder {
            font-size: 14px;
            font-family: var(--semiBold);
        }
    }

    > button {
        background: none;
        border-style: none;
        cursor: pointer;
    }

    & + div {
        margin-top: 16px;
    }

`;

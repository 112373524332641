import { FC, useEffect, useRef, useState } from "react";
import { useAuth } from "src/hooks/auth";
import logoBlack from "src/assets/logo-black.png";
import moment from "moment";
import { SendHorizonal } from "lucide-react";
import { api } from "src/services/api";
import socket from "src/services/socket-io";
import { TicketStatus } from "../backoffice/Support";
import { Loading } from "src/components/loading";
import { color } from "html2canvas/dist/types/css/types/color";

export const ChatModal: FC<{ selectedId?: string }> = ({ selectedId }) => {
  const { user } = useAuth();

  const [text, setText] = useState("");

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();

  const [loadingTickets, setLoadingTickets] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  const [tab, setTab] = useState<"CHATS" | "CLOSEDS">("CHATS");

  const closedTickets = tickets.filter((t) => t.status === "CLOSE");
  const restTickets = tickets.filter((t) => t.status != "CLOSE");

  const token = localStorage.getItem("@stricv2:token");

  const inputRef = useRef<HTMLInputElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const listTickets = async () => {
    try {
      setLoadingTickets(true);
      const response = await api.get("/tickets", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setTickets(response.data);

      if (selectedId) setSelectedTicket(response.data.find((t: any) => t?.id == selectedId));
    } catch (error) {
      console.log(error);
    }
    setLoadingTickets(false);
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const height = messagesContainerRef.current?.offsetHeight / 2;
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - height;
    }
  };

  const sendMessage = async () => {
    const content = text;
    try {
      if (sendingMessage) return;
      setSendingMessage(true);
      if (!selectedTicket) return;
      if (!content) return;

      setText("");

      await api.post(
        `/tickets/${selectedTicket.number}/message`,
        {
          content,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      setTimeout(() => {
        inputRef.current?.focus();
      }, 20);
    } catch (error) {
      console.log(error);
      setText(content);
      setSendingMessage(false);
    }
    setSendingMessage(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedTicket]);

  useEffect(() => {
    listTickets();
    scrollToBottom();
    socket.on(`updateTicket`, (ticket: Ticket) => {
      setTickets((tickets) => tickets.map((t) => (ticket?.id == t.id ? ticket : t)));
      if (selectedTicket?.id == ticket.id) {
        setSelectedTicket(ticket);
      }
    });

    socket.on("ticket-new-message", (data) => {
      if (data) {
        setTickets((tickets) =>
          tickets.map((ticket) =>
            ticket.id === data.ticketId ? { ...ticket, messages: [...ticket.messages, data] } : ticket
          )
        );

        setSelectedTicket((t) => {
          if (t && t.id === data.ticketId) {
            return { ...t, messages: [...t.messages, data] };
          }
          return t;
        });
      }
    });

    return () => {
      socket.off("updateTicket");
      socket.off("ticket-new-message");
      socket.off("ticketCreated");
    };
  }, []);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex flex-col rounded-[12px] 2xl:w-[1600px] 2xl:h-[800px] w-[1000px] h-[700px] overflow-hidden"
    >
      <div className="w-full h-14 bg-[#274683]">
        <div className="flex w-full h-full">
          <div className="flex flex-1 items-center justify-center gap-2">
            <span
              onClick={() => setTab("CHATS")}
              className={`text-white cursor-pointer hover:scale-[1.03] text-sm transition-all font-semibold rounded-full px-2 pb-[2px] ${
                tab == "CHATS" && "bg-white text-[#274683]"
              }`}
            >
              Conversas abertas
            </span>
            <span
              onClick={() => setTab("CLOSEDS")}
              className={`text-white cursor-pointer hover:scale-[1.03] text-sm transition-all font-semibold rounded-full px-2 pb-[2px] ${
                tab == "CLOSEDS" && "bg-white text-[#274683]"
              }`}
            >
              Conversas fechadas
            </span>
          </div>
          <div className="flex flex-[2] items-center justify-center">
            <span className="text-white font-semibold">Chat PIXWAVE Bank</span>
          </div>
        </div>
      </div>
      <div className=" relative grid grid-cols-3 bg-zinc-100 h-full max-h-[calc(100%-56px)] w-full">
        <div className="col-span-1 overflow-y-auto hideScrollbar bg-white">
          {tickets.length == 0 && (
            <div className="flex w-full items-center h-10 justify-center">
              <span>Não há conversas</span>
            </div>
          )}

          {tab == "CHATS"
            ? restTickets.map((item) => {
                if (item.status === "OPEN" && item.assigned != user?.id) return null;
                return (
                  <TicketDetails
                    key={item.id}
                    isSelected={selectedTicket?.id == item.id}
                    onSelectTicket={(ticket) => {
                      setSelectedTicket(ticket);
                    }}
                    ticket={item}
                  />
                );
              })
            : closedTickets.map((item) => {
                if (item.status === "OPEN" && item.assigned != user?.id) return null;
                return (
                  <TicketDetails
                    key={item.id}
                    isSelected={selectedTicket?.id == item.id}
                    onSelectTicket={(ticket) => {
                      setSelectedTicket(ticket);
                    }}
                    ticket={item}
                  />
                );
              })}
        </div>
        <div ref={messagesContainerRef} className="col-span-2 relative max-h-[calc(100%-64px)] overflow-y-auto">
          <div className="flex flex-col flex-1 bg-zinc-100 justify-end p-8 gap-4">
            {loadingTickets && (
              <div className="flex w-full items-center h-10 justify-center">
                <span>Carregando...</span>
              </div>
            )}

            {!selectedTicket && tickets.length != 0 && (
              <div className="flex w-full items-center h-10 justify-center">
                <span>Selecione uma conversa</span>
              </div>
            )}

            {selectedTicket?.messages.length == 0 && (
              <div className="flex w-full items-center h-10 justify-center">
                <span>Nenhuma mensagem nessa conversa</span>
              </div>
            )}

            {!loadingTickets &&
              selectedTicket?.messages.map((msg, index) => {
                const isClientMessage = msg.author === user.id;

                const lastMessage = selectedTicket.messages[index - 1];
                const showRabinhoDaMensagem = lastMessage ? msg.author != lastMessage.author : true;

                return (
                  <div
                    key={msg.id}
                    className={`flex flex-col relative min-w-[100px] p-2 max-w-[80%] ${
                      isClientMessage
                        ? showRabinhoDaMensagem
                          ? "self-end  bg-[#274683] text-white text-base shadow-md rounded-b-xl rounded-tl-xl"
                          : "self-end  bg-[#274683] text-white text-base shadow-md rounded-xl"
                        : showRabinhoDaMensagem
                        ? "self-start bg-white text-zinc-800 text-base font-semibold shadow-md rounded-b-xl rounded-tr-xl "
                        : "self-start bg-white text-zinc-800 text-base font-semibold shadow-md rounded-xl"
                    }`}
                  >
                    {msg.content}
                    <span className={`text-xs font-normal text-right`}>
                      {moment(msg.created_at).format("DD/MM/YY - HH:mm")}
                    </span>
                    {showRabinhoDaMensagem && (
                      <RabinhoDaMensagem
                        isClientMessage={isClientMessage}
                        color={isClientMessage ? "#274683" : "#fff"}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="absolute flex bottom-0 right-0 w-[66.666%] h-16 ">
          <div className="reltaive flex flex-1">
            <input
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  sendMessage();
                }
              }}
              autoFocus
              ref={inputRef}
              disabled={selectedTicket?.status == "CLOSE" || sendingMessage}
              value={text}
              onChange={(e) => setText(e.target.value)}
              type="text"
              placeholder={selectedTicket?.status == "CLOSE" ? "Essa conversa foi encerrada." : "Escreva sua mensagem"}
              className="flex shadow-inner bg-zinc-50 h-full w-full px-4"
            />
            {selectedTicket?.status != "CLOSE" && (
              <button
                disabled={sendingMessage}
                onClick={sendMessage}
                className="flex  active:opacity-75 bg-[#274683] items-center justify-center h-full aspect-square"
              >
                {sendingMessage ? (
                  <Loading
                    isLoading
                    style={{
                      color: "white",
                      height: 26,
                      width: 26,
                    }}
                  />
                ) : (
                  <SendHorizonal size={25} color="#fff" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export type Ticket = {
  id: string;
  number: number;
  title: string;
  description: string;
  status: "WAITING" | "OPEN" | "CLOSE";
  origem: string;
  type: string;
  category: string;
  applicant: string;
  assigned: string;
  created_at: string;
  updated_at: string;
  messages: Message[];
};

export type Message = {
  id: string;
  ticketId: string;
  author: string;
  read: boolean;
  content: string;
  created_at: string;
};

const TicketDetails: FC<{
  onSelectTicket: (ticket: Ticket) => void;
  ticket: Ticket;
  isSelected: boolean;
}> = ({ isSelected, onSelectTicket, ticket }) => {
  return (
    <div
      onClick={() => {
        onSelectTicket(ticket);
      }}
      className={`flex items-center hover:bg-zinc-200 ${
        isSelected ? "bg-zinc-100" : "bg-white"
      } gap-2 p-2 h-16 w-full cursor-pointer`}
    >
      <div className="w-9 h-9 flex items-center justify-center overflow-hidden rounded-full bg-white">
        <img className="object-contain" src={logoBlack} />
      </div>
      <div className="flex relative gap-1 flex-1 flex-col justify-center">
        <span className="text-sm font-bold">{ticket.title}</span>
        <div className="flex  justify-between items-center">
          <span className="text-sm">{moment(ticket.created_at).format("DD/MM/YYYY")}</span>
          <TicketStatus status={ticket.status} />
        </div>
      </div>
    </div>
  );
};

export const RabinhoDaMensagem: FC<{
  color: string;
  isClientMessage: boolean;
}> = ({ color, isClientMessage }) => {
  const right = isClientMessage ? -12 : undefined;
  const left = isClientMessage ? undefined : -12;

  const clip = isClientMessage ? "polygon(100% 0, 0 0, 0 100%)" : "polygon(100% 0, 0 0, 100% 100%)";

  return (
    <div
      className="w-3 h-4 absolute top-0 shadow-lg"
      style={{
        clipPath: clip,
        backgroundColor: color,
        right,
        left,
      }}
    ></div>
  );
};
// commit update
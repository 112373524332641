import styled from 'styled-components';
import {ReactComponent as ArrowPageLeftIconSVG} from "../../assets/arrowPageLeft.svg"

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`

fill: var(--cian);
width: 16px;

`

export const Container = styled.div`

height: 100vh;
padding: 32px;
background-color: var(--w);


`


export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;

`


export const HeaderForm = styled.div`

width: 100%;
display: flex;
align-items: center;


`

export const GoBackButton = styled.button`

border-style: none;
margin-right: 24px;
height: 40px;
width: 40px;
background: var(--white);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
cursor: pointer;
flex-shrink: 0;


`

export const FormTitle = styled.h2`


font-size: 24px;
font-family: var(--bold);
color: var(--gray-dark);

`

export const BoxInfoWrapper = styled.div`
width: 480px;
background-color: var(--confirmBackground);
padding: 32px;
display: flex;
justify-content: space-between;
`
export const FormSubtitle = styled.span`


font-size: 16px;
font-family: var(--regular);
color: var(--gray-dark);

`

export const WrapperForm = styled.div``
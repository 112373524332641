import { Fragment, useEffect, useRef, useState } from "react";
import {
  Container,
  Content,
  AccountStats,
  CardAccountInfo,
  Transactions,
  Items,
  ItemTitle,
  Action,
  BillIcon,
  TransferIcon,
} from "./styles";
import "./style.css";
import { DateTime } from "luxon";

import { Orders } from "../../components/orders";
import { Projection } from "../../components/projection";
import { Contacts } from "../../components/contacts";
import { Card } from "../../components/card";
import { useAuth } from "../../hooks/auth";
import { documentFormatted } from "../../utils/documentFormatted";
import { currencyFormat } from "../../utils/currencyFormat";

import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import TextInput from "../../components/TextInput";
import { api } from "../../services/api";
import { useNotification } from "../../hooks/notification";
import { useNavigate } from "react-router-dom";
import { service } from "src/services/ajio";
import { PixIcon } from "../deposit/styles";
import { CircleDashed } from "lucide-react";
import moment from "moment";

export function Dashboard() {
  const navigation = useNavigate();
  const { user, account, refreshAccount } = useAuth();
  const { setOpenNotification } = useNotification();

  const [balanceValueTransaction, setBalanceValueTransaction] = useState(0);
  const token = localStorage.getItem("@stricv2:token");

  const [totalToPay, setTotalToPay] = useState(0);

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [isBalanceVisible, setIsBalanceVisible] = useState(() => {
    const visible = localStorage.getItem("visible");

    if (visible && visible === "true") {
      return true;
    } else {
      return false;
    }
  });

  const fetchPosDetails = async () => {
    try {
      const response = await api.get("/pagbank/graphic-pos-details", config);

      console.log(response.data);

      setTotalToPay(response.data.totalToPayMachines || 0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const internal = setInterval(() => {
      const visible = localStorage.getItem("visible");

      if (visible && visible === "true") {
        setIsBalanceVisible(true);
      } else {
        setIsBalanceVisible(false);
      }
    }, 3000);

    fetchPosDetails();

    return () => {
      clearInterval(internal);
    };
  }, []);

  const [pin, setPin] = useState("");

  const { signIn } = useAuth();

  const [switchingAccount, setSwitchingAccount] = useState(false);

  const handleWalletLogin = async () => {
    setSwitchingAccount(true);
    try {
      if (!token) {
        return console.log("error: token is missing. login wallet");
      }

      await signIn({ user_master_token: token });
    } catch (error) {
      console.log("erro ao logar wallet", error);
    }
    setTimeout(() => {
      setSwitchingAccount(false);
    }, 1000);
  };

  const handleIndividualLoginByWallet = async () => {
    setSwitchingAccount(true);
    try {
      if (!token) {
        return console.log("error: token is missing. login wallet");
      }

      await signIn({ individuals_token: token });
    } catch (error) {
      console.log("erro ao logar wallet", error);
    }
    setTimeout(() => {
      setSwitchingAccount(false);
      window.location.reload();
    }, 1000);
  };

  async function getAccounts() {
    if (user.role != "MEMBER") return;
    try {
      const { data } = await service.get("/graphic");
      let value = 0;
      const accounts = data.accounts;

      for (let i = 0; i < accounts.length; i++) {
        const { data } = await service.get(
          `/graphic/${accounts[i].id}/transactions`,
          {
            params: {
              status: "waiting",
            },
          }
        );

        const transactions = data.transactions;

        if (transactions.length > 0) {
          for (let j = 0; j < transactions.length; j++) {
            value = value + transactions[j].amount;
          }
        }
      }
      setBalanceValueTransaction(value);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    refreshAccount();
    getAccounts();
  }, []);

  async function handlePin() {
    try {
      await api.post("accounts/pin", {
        pin,
      });
      setOpen(false);
      setOpenNotification({
        type: "success",
        title: "Cadastro realizado",
        notification: "Senha de 4 dígitos cadastrada com sucesso",
      });
    } catch (err) {
      setOpenNotification({
        type: "error",
        title: "Erro!",
        notification: "Apenas números",
      });
    }
  }

  return (
    <>
      {switchingAccount && (
        <div className="flex fadeIn items-center gap-4 bg-black bg-opacity-80 justify-center w-screen h-screen fixed top-0 left-0 z-40">
          <span className="text-xl text-white">Trocando de conta</span>
          <CircleDashed className="animate-spin" color="white" />
        </div>
      )}
      <Container>
        <Content>
          <AccountStats>
            <Card
              title="Saldo Total na Conta"
              value={
                isBalanceVisible
                  ? currencyFormat(
                      typeof account?.graphic_balance === "number"
                        ? account.balance + account.graphic_balance
                        : account.balance
                    )
                  : "***"
              }
              color={"#274683"}
            />
            <Card
              title={
                account.account_number && account.account_digit
                  ? "Saldo Retido"
                  : "Saldo Total"
              }
              value={
                user.role == "WALLET"
                  ? currencyFormat(account.balance + totalToPay)
                  : account.account_number && account.account_digit
                  ? isBalanceVisible
                    ? currencyFormat(account?.graphic_balance || 0)
                    : "***"
                  : isBalanceVisible
                  ? currencyFormat(
                      account.balance + account.graphic_transactions
                    )
                  : "***"
              }
              color={"#1286B8"}
            />
            <Card
              title={
                user.role == "WALLET"
                  ? "Aguardando Repasse (POS)"
                  : "C-A - Pendente de autorização"
              }
              value={
                user.role == "WALLET"
                  ? currencyFormat(totalToPay)
                  : account.account_number && account.account_digit
                  ? isBalanceVisible
                    ? currencyFormat(account.graphic_transactions)
                    : "***"
                  : currencyFormat(
                      account.graphic_transactions - account.graphic_balance
                    )
              }
              color={"#274683"}
              textColor={"#fff"}
            ></Card>
            <CardAccountInfo>
              <div>
                <p className="name">{user?.name}</p>
                <p className="id">
                  {documentFormatted(user?.document as string)}
                </p>
                <p className="bankCode">
                  Banco: <strong>0435</strong>
                </p>
                <p className="agency">
                  {account.account_number && account.account_digit ? (
                    <span>
                      {" "}
                      Agência: <strong>0001</strong>
                    </span>
                  ) : (
                    <span>
                      Agência: <strong>1002</strong>
                    </span>
                  )}
                </p>

                {account.status === "ACTIVE" || "active" ? (
                  <>
                    <p className="account">
                      {account.account_number && account.account_digit
                        ? "Conta: "
                        : "Nº Identificador: "}
                      {account.account_number && account.account_digit ? (
                        <strong>
                          {account.account_number}-{account.account_digit}
                        </strong>
                      ) : (
                        <strong>{account.number_identifier}</strong>
                      )}
                    </p>
                  </>
                ) : (
                  <p className="bankCode">Conta em análise</p>
                )}
              </div>
            </CardAccountInfo>
          </AccountStats>

          <Transactions>
            <Orders />
            <Projection />
            <Contacts />
            <Items>
              {/* <Action to="/charge">
              <ItemTitle>Cobrar</ItemTitle>
              <ChargeIcon />
            </Action> */}
              <Action to="/paybills">
                <ItemTitle>Pagar contas</ItemTitle>
                <BillIcon className="fill-white w-14 h-14" />
              </Action>
              <Action to="/pix">
                <ItemTitle>Transferir</ItemTitle>
                <PixIcon className="fill-white w-14 h-14" />
              </Action>
              {user.role == "MEMBER" ? (
                <Action onClick={handleWalletLogin} to={"/"}>
                  <ItemTitle>
                    {user.role == "MEMBER" ? "Wallet" : "Retornar a conta"}
                  </ItemTitle>
                  <TransferIcon className="fill-white w-14 h-14" />
                </Action>
              ) : user.role == "WALLET" &&
                user.status_master_user &&
                account.userStatus == "ACTIVE" ? (
                <Action onClick={handleIndividualLoginByWallet} to={"/"}>
                  <ItemTitle>Retornar a conta</ItemTitle>
                  <TransferIcon className="fill-white w-14 h-14" />
                </Action>
              ) : (
                <Action to={"/adds-founds-wallet"}>
                  <ItemTitle>Recarga</ItemTitle>
                  <TransferIcon className="fill-white w-14 h-14" />
                </Action>
              )}
            </Items>
          </Transactions>
        </Content>
      </Container>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                      <ExclamationIcon
                        className="h-6 w-6 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Senha de 4 dígitos
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md text-gray-500">
                          Você ainda não cadastrou uma senha de 4 dígitos
                        </p>
                        <p className="text-md text-gray-500">
                          Para continuar cadastre uma senha de 4 dígitos
                        </p>
                        <p className="mt-5 flex justify-center">
                          <div className="w-48 ">
                            <TextInput
                              placeholder="Senha de 4 dígitos"
                              maxLength={4}
                              value={pin}
                              type="password"
                              onChange={(input) => setPin(input.target.value)}
                            />
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                      onClick={handlePin}
                    >
                      Cadastrar
                    </button>
                    {/* <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(true)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import { documentFormatted } from "./documentFormatted";
import { ExtractItem } from "./extractDescription";

export default function getTransactionDocuments(
  transaction: ExtractItem,
  formated?: boolean
) {
  const { response, data, direction } = transaction;

  let beneficiary = "";
  let payer = "";

  if (transaction.type.toLowerCase().includes("p2p")) {
    payer = response?.proof?.payer?.holder?.document;
    beneficiary = response?.proof?.beneficiary?.holder?.document;
  }

  if (transaction.type.includes("PAGBANK_PAYMENT")) {
    payer = data?.payer?.document;
    beneficiary = data?.beneficiary?.document;
  }

  if (transaction.type.toLowerCase().includes("pix")) {
    payer =
      response?.proof?.payer?.holder?.document ||
      response?.payer?.holder?.document;
    beneficiary =
      response?.proof?.beneficiary?.holder?.document ||
      response?.beneficiary?.holder?.document;
  }

  if (transaction.type == "INTERNAL_TAX") {
    payer = response?.payer?.document;
    beneficiary = response?.beneficiary?.document;
  }

  if (transaction.type == "TED") {
    payer = response?.proof?.payer?.holder?.document;
    beneficiary = response?.proof?.beneficiary?.holder?.document;
  }

  if (transaction.type == "payment") {
    payer = "";
    beneficiary = response?.beneficiary?.document;
  }

  beneficiary = beneficiary
    ? formated
      ? documentFormatted(beneficiary)
      : beneficiary
    : "********";
  payer = payer ? (formated ? documentFormatted(payer) : payer) : "********";

  return { beneficiary, payer };
}

import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth";
import { sub } from "date-fns";
import {
  ArrowIcon,
  Container,
  Header,
  HeaderWrapper,
  Content,
  FutureProfits,
  FuturePayments,
  Info,
  FutureTransactions,
  ExpectedBalance,
  FuturePayment,
  IconWrapper,
  FutureReceive,
} from "./styles";
import { dateFormatUS } from "src/utils/dateFormat";
import { service } from "src/services/ajio";
import { currencyFormat } from "src/utils/currencyFormat";
import { InputSelect, SelectObject } from "../select";
import { extractEntriesExitPDF } from "src/utils/extract-entries-exit-pdf";

export type CustomExtract = {
  in: number;
  out: number;
};

export const Projection = () => {
  const { account, user } = useAuth();

  const [transactionsIn, setTransactionsIn] = useState<any[]>([]);
  const [transactionsOut, setTransactionsOut] = useState<any[]>([]);

  const [days, setDays] = useState(7);
  const [selectedDays, setSelectedDays] = useState<SelectObject>({
    name: "7 dias",
    id: 7,
  } as any);
  const [customExtract, setCustomExtract] = useState<CustomExtract>({
    in: 0,
    out: 0,
  });

  const findDashboard = async (day: number) => {
    const date = new Date();
    const start_date = new Date(sub(date, { days: day }));
    const end_date = date;

    try {
      const { data } = await service.get("/dashboard/custom-extract", {
        params: {
          end_date,
          start_date,
        },
      });
      setCustomExtract(data);

      const response = await service.get(`/transactions`, {
        params: {
          end: end_date,
          start: start_date,
        },
      });

      const transactions = response.data.transactions.data;

      const filterTransactionsIn = [];
      const filterTransactionsOut = [];

      for (let i = 0; i < transactions.length; i++) {
        if (
          new Date(transactions[i].created_at) < end_date &&
          transactions[i].status === "done"
        ) {
          if (transactions[i].direction === "in") {
            filterTransactionsIn.push(transactions[i]);
          } else {
            filterTransactionsOut.push(transactions[i]);
          }
        }
      }
      setTransactionsOut(filterTransactionsOut);
      setTransactionsIn(filterTransactionsIn);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    findDashboard(days).catch((err) => console.log(err));
  }, [days]);

  return (
    <Container>
      <Header>
        <HeaderWrapper>
          <span>Extrato Personalizado</span>
          <div>
            <InputSelect
              label=""
              selected={selectedDays}
              options={
                [
                  { name: "7 dias", id: 7 },
                  { name: "30 dias", id: 30 },
                  { name: "90 dias", id: 90 },
                ] as any
              }
              onChange={(ctx) => {
                const day = Number(ctx.id);
                setDays(day);
                setSelectedDays(ctx);
                findDashboard(day);
              }}
            />
          </div>
        </HeaderWrapper>
      </Header>
      <Content>
        <FutureTransactions>
          <FutureProfits>
            <Info>
              <span>Entradas</span>
              <p>{currencyFormat(customExtract.in)}</p>
            </Info>
            <IconWrapper
              tabIndex={0} // Torna o IconWrapper focável
              onClick={() =>
                extractEntriesExitPDF(transactionsIn, days, "Entrada", user)
              }
            >
              <FutureReceive />
            </IconWrapper>
          </FutureProfits>
          <FuturePayments>
            <Info>
              <span>Saídas</span>
              <p>{currencyFormat(customExtract.out)}</p>
            </Info>
            <IconWrapper
              tabIndex={0} // Torna o IconWrapper focável
              onClick={() =>
                extractEntriesExitPDF(transactionsOut, days, "Saída", user)
              }
            >
              <FuturePayment />
            </IconWrapper>
          </FuturePayments>
        </FutureTransactions>
      </Content>
    </Container>
  );
};

import { FC, useState } from "react";
import { Eye, EyeClosed } from "phosphor-react";

import IntlCurrencyInput from "react-intl-currency-input";
import InputMask from "react-input-mask";

export const FormInput: FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    value?: string;
    placeholder?: string;
    mask?: string;
    errorMessage?: string;
    isPassword?: boolean;
    onChangeText?: (text: string) => void;
  }
> = ({ mask, placeholder, value, isPassword = false, onChangeText, errorMessage ,...props }) => {
  const [showPassword, setShowPassword] = useState(isPassword ? true : false);

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return (
    <div>
      {mask ? (
        <>
          {mask !== "currency" ? (
            <InputMask
              {...props}
              mask={mask}
              placeholder={placeholder}
              onChange={(e) => onChangeText!(e.target.value)}
              value={value}
              type={!showPassword ? "text" : "password"}
              className={`bg-white w-full rounded-lg px-5 h-12 mb-2 max-2xl:h-8`}
            />
          ) : (
            <IntlCurrencyInput
              currency="BRL"
              config={currencyConfig}
              className={`bg-white w-full rounded-lg px-5 h-12 mb-2 max-2xl:h-8`}
              onChange={(e: any) => onChangeText!(e.target.value)}
              value={value}
              {...props}
            />
          )}
        </>
      ) : (
        <input
          {...props}
          placeholder={placeholder}
          onChange={(e) => onChangeText!(e.target.value)}
          value={value}
          type={!showPassword ? "text" : "password"}
          className={`bg-white w-full rounded-lg px-5 h-12 mb-2 max-2xl:h-8`}
        />
      )}

      {isPassword && (
        <button onClick={() => setShowPassword(!showPassword)} className="absolute mt-3 ml-[-2.3rem] max-2xl:mt-1">
          {!showPassword ? <Eye color="#404040" size={24} /> : <EyeClosed color="#404040" size={24} />}
        </button>
      )}
      {errorMessage && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};

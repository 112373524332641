import { format } from "date-fns";
import React, { Fragment, FormEvent, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";

import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { service, serviceError } from "../../services/ajio";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormat } from "../../utils/dateFormat";
import { BoxText } from "../payBillsPayInvoice/styles";

import {
  BoxInfoWrapper,
  ButtonsWrapper,
  Container,
  ExpiresDate,
  InvoiceCode,
  Label,
  PaymentsDate,
  Title,
  ValidityWrapper,
  WrapperHeader,
} from "./styles";
import { useAuth } from "../../hooks/auth";
import KeyboardPressPasswordEletronic from "src/components/keyPressPasswordeletronic";
import { useNotification } from "src/hooks/notification";


interface StateProps {
  beneficiary: any;
  type: string;
  isValid: boolean;
  wasPaid: boolean;
  hasEnoughBalance: boolean;
  isPayable: boolean;
  isOverdue: boolean;
  isInTime: boolean;
  barCode: string;
  checkedAt: string;
  minTime: string;
  maxTime: string;
  dueDate: string;
  limitDate: string;
  nextValidDate: string;
  amount: number;
  discount: number;
  fine: number;
  interest: number;
  recipientName: string;
  recipientDocument: any;
  payerName: any;
  payerDocument: any;
  amountRule: string;
  amountRuleDescription: string;
  hasMinimum: boolean;
  minimumAmount: number;
  hasMaximum: boolean;
  maximumAmount: number;
}

interface PaymentProps {
  id: string;
}

export const PayBillsInfoInvoice: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { account, refresh } = useAuth();

  const state = location.state as StateProps;

  const [value, setValue] = useState(state.amount);
  const [maskedValue, setMaskedValue] = useState("");
  const [dueAt, setDueAt] = useState(format(new Date(), "dd/MM/yyyy"));
  const [pinOpen, setPinOpen] = useState(false);
  const [pin, setPin] = useState("");
  const [hasPin, setHasPin] = useState(false);
  const [open, setOpen] = useState(false);
  const [paymentData, setPaymentData] = useState<PaymentProps>(
    {} as PaymentProps
  );
  const [valueEletronic, setValueEletronic] = useState("");


  const formRef = useRef<HTMLFormElement | null>(null);

  
  const [clickedValues, setClickedValues] = useState<string[]>([]);




  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate("/paybills");
  };

  const handleConfirmButton = async () => {
    setLoading(true);
    setError("");
    try {
      const { data } = await service.post("/payments", {
        barCode: state.barCode,
        amount: value,
      });

      setPaymentData(data.payment);
      setLoading(false);
      setOpen(true);
      refresh();
    } catch (err) {
      setLoading(false);
      const error = serviceError(err);
      setError(error.friend);
    }
    // navigate("/paybills/confirm-invoice");
  };

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }






  const handleKeyPress = (prevValue: string) => {
    // ...
    setClickedValues((prevValue: any) => [...prevValue]);
    // ...
  };

  const handlePasswordVerification = (clickedValues: string[]) => {
    const securityCode = account.security_eletronic;
    console.log("sou securityCode", securityCode);
    console.log(
      "sou clickedValues de dentro do handlePasswordVerification ",
      clickedValues
    );

    // Converte o array de valores para uma string
    const clickedValuesString = clickedValues.join("").trim();
    console.log(
      "sou clickedValuesString de dentro do handlePasswordVerification ",
      clickedValuesString
    );
    const handleConfirm = (passwords: string[]) => {
      // Lógica para lidar com a confirmação da senha
      setPinOpen(false);
      formRef.current?.requestSubmit();
    };
    // Verifique cada par de dígitos em clickedValuesString com os dígitos em securityCode
    const isPasswordCorrect = clickedValues.every((_, index) => {
      const start = index * 2;
      const end = start + 2;
      const clickedPair = clickedValuesString.substring(start, end);
      const securityPair = securityCode.substr(start, 2);

      console.log(
        `Comparando: clickedPair = ${clickedPair}, securityPair = ${securityPair}`
      );

      const isPairCorrect = clickedPair === securityPair;

      console.log(
        `Par ${index + 1}: ${isPairCorrect ? "Correto" : "Incorreto"}`
      );

      return isPairCorrect;
    });
    console.log(
      "sou isPasswordCorrect de dentro do handlePasswordVerification ",
      isPasswordCorrect
    );

    if (isPasswordCorrect) {
      // Senha correta, faça o que for necessário
      console.log("Senha correta!");
      console.log("sou boy 3");
    } else {
      // Senha incorreta, faça o que for necessário
      console.log("Senha incorreta!222222");
      console.log("sou boy 2");
    }
  };

  const updateValueEletronic = (newValue: any) => {
    setValueEletronic(newValue);
  };

  const handleUpdateClickedValues = (values: string[]) => {
    setClickedValues(values);
  };

  const { setOpenNotification } = useNotification();

  // Função para atualizar o estado com o valor recebido do componente filho











  return (
    <>
      <Container>
        <WrapperHeader>
          <StepHeader
            handleGoBackFunction={handleGoBack}
            title="Realizar Pagamento de Boleto "
          />
        </WrapperHeader>
        <BoxInfoWrapper>
          <Title>Código de Barras</Title>
          <InvoiceCode>{state.barCode}</InvoiceCode>
          <ValidityWrapper>
            <ExpiresDate>
              <Label>Vencimento</Label>
              <TextInput
                borderColor="#E3E3E3"
                placeholder="02/03/2022"
                type="text"
                value={dateFormat(state.dueDate)}
                disabled
              />
            </ExpiresDate>
            <PaymentsDate>
              <Label>Pagamento</Label>
              <TextInput
                borderColor="#E3E3E3"
                placeholder="02/03/2022"
                type="text"
                value={dueAt}
                disabled
              />
            </PaymentsDate>
          </ValidityWrapper>
          <ExpiresDate>
            <Label>Valor</Label>
            <TextInput
              borderColor="#E3E3E3"
              placeholder="02/03/2022"
              type="text"
              value={currencyFormat(state.amount)}
              disabled
            />
          </ExpiresDate>
          <ExpiresDate>
            <Label>Beneficiário</Label>
            <TextInput
              borderColor="#E3E3E3"
              placeholder="02/03/2022"
              type="text"
              value={state?.beneficiary?.name}
              disabled
            />
          </ExpiresDate>
        </BoxInfoWrapper>
        <ButtonsWrapper>
          <Button
            handleFunction={handleCancel}
            color="#e3e3e3"
            title="Cancelar"
          />

          {!hasPin ? (
            <Button
              type="button"
              isLoading={loading}
              title="Continuar para senha"
              handleFunction={() => setPinOpen(true)}
            />
          ) : (
            <Button
              handleFunction={handleConfirmButton}
              title="Realizar pagamento"
              isLoading={loading}
            />
          )}
        </ButtonsWrapper>
        <BoxText>
          {error && <div className="block mt-3 text-red-500">{error}</div>}
        </BoxText>
      </Container>

      <Transition.Root show={pinOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative bg-darkgoldenrod rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                      <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-white"
                          >
                            Senha
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="mt-5 flex justify-center">
                              <div className="w-48 ">
                                <TextInput
                                  placeholder="Senha Eletrônica"
                                  maxLength={8}
                                  value={valueEletronic}
                                  style={{ color: "black" }} // Adicione esta linha
                                  type="password"
                                  onChange={(input) =>
                                    setValueEletronic(input.target.value)
                                  }
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <span>bolinha quadradinha</span>*/}
                      <div className="mt-5 sm:mt-6">
                        {/*  <span>bolinha redondinha</span> */}
                        <KeyboardPressPasswordEletronic
                          updateParentInput={updateValueEletronic}
                          updateClickedValues={setClickedValues}
                          onKeyPress={handleKeyPress}
                          onConfirm={handlePasswordVerification}
                        />

                        <Button
                          type="submit"
                          isLoading={loading}
                          title="Transferir"
                          handleFunction={() => {
                            const handlePasswordVerification = (
                              clickedValues: string[]
                            ) => {
                              // Verificar se clickedValues está vazio
                              if (clickedValues.length === 0) {
                                console.error(
                                  "Erro: Nenhum valor fornecido. Digite a senha eletrônica."
                                );
                                return;
                              }

                              const securityCode = account.security_eletronic;
                              console.log("Sou securityCode", securityCode);
                              console.log(
                                "em handleFunction no pix confirm Sou clickedValues pra referencia fui resultado dos clicks do usuario no front end: ",
                                clickedValues
                              );

                              // Verifique cada posição de clickedValuesArray com os dígitos em securityCode
                              let isPasswordCorrect = true;

                              clickedValues.some((position, index) => {
                                // Remove 'ou' e espaços e divide a posição em um array
                                const digitsArray: string[] = position
                                  .split(" ou ")
                                  .map((digit) => digit.trim());

                                console.log(
                                  `Posição ${
                                    index + 1
                                  } - Original: ${position}, digitsArray:`,
                                  digitsArray
                                );

                                // Verifica se temos exatamente dois dígitos na posição (exceto na última posição)
                                if (digitsArray.length !== 2) {
                                  if (index === 7) {
                                    // Se for a última posição, verifica se tem apenas um dígito
                                    if (digitsArray.length !== 2) {
                                      console.error(
                                        `Erro: posição ${
                                          index + 1
                                        } não possui um dígito válido.`
                                      );
                                      isPasswordCorrect = false;
                                      return true; // Sai do loop
                                    }
                                  } else {
                                    console.error(
                                      `Erro: posição ${
                                        index + 1
                                      } não possui dois dígitos válidos.`
                                    );
                                    isPasswordCorrect = false;
                                    return true; // Sai do loop
                                  }
                                }

                                // Continua com a lógica original de comparação
                                const digitOptionsString = digitsArray.join("");
                                const securityDigit = securityCode[index];
                                console.log(
                                  `Posição ${
                                    index + 1
                                  } - Comparando: digitOptions = ${digitOptionsString}, securityDigit = ${securityDigit}`
                                );

                                // Ajuste na lógica de comparação
                                const isDigitCorrect = digitsArray.some(
                                  (digit) => digit === securityDigit
                                );
                                console.log(
                                  `Posição ${index + 1}: ${
                                    isDigitCorrect ? "Correta" : "Incorreta"
                                  }`
                                );

                                if (!isDigitCorrect) {
                                  isPasswordCorrect = false;
                                  console.error(
                                    "Senha incorreta! Algum dígito está errado."
                                  );
                                  setOpenNotification({
                                    notification:
                                      "Verifique sua senha e tente novamente.",
                                    title: "Senha incorreta",
                                    type: "error",
                                  });
                                  return true; // Sai do loop
                                }

                                return false; // Continua para a próxima posição
                              });

                              if (isPasswordCorrect) {
                                // Senha correta, faça o que for necessário
                                console.log("Senha correta!");
                                console.log("Sou boy 3");
                                setHasPin(true);
                                setPinOpen(false);
                                formRef.current?.requestSubmit();
                              } else {
                                // Senha incorreta, faça o que for necessário
                                console.error(
                                  "Senha incorreta! Toda a senha está incorreta."
                                );
                                console.log("Sou boy 23");
                              }
                            };

                            handlePasswordVerification(clickedValues);
                          }}
                        />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Pagamento realizado com sucesso
                        <br />
                        {maskedValue}
                      </Dialog.Title>
                      <div className="mt-2 text-left">
                        <p className="text-sm text-gray-500">
                          <h2 className="mb-5 mt-5 text-lg">
                            Dados do favorecido
                          </h2>
                          {/* <div>
                          <h2>Nome</h2>
                            <h3>{account.name}</h3>
                         </div> */}

                          {/* <div className="mt-5"> */}
                          {/* <h2>Documento</h2>
                            <h3>{documentFormatted(account.document)}</h3>
                         </div> */}

                          <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>1111</h3>
                            </div>

                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>
                                {account.account_number}-{account.account_digit}
                              </h3>
                            </div>
                          </div>

                          <h2 className="mb-5 mt-5 text-lg">
                            Dados da conta debitada
                          </h2>

                          <div>
                            <h2>Nome</h2>
                            <h3>{state.recipientName}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Documento</h2>
                            <h3>{state.recipientName}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Código de Barras</h2>
                            <h3>{state.barCode}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>ID da transação</h2>
                            <h3>{paymentData.id}</h3>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => onCloseModal()}
                    >
                      Ir para o extrato
                    </button>

                    <button
                      type="button"
                      className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                      // onClick={() => onCloseModal()}
                    >
                      Comprovante disponível no extrato
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

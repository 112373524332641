/* eslint-disable react-hooks/exhaustive-deps */
import { KeyReturn } from "phosphor-react";
import { FC, useEffect, useState } from "react";
import {
  Container,
  Content,
  ContentWrapper,
  Table,
  TableWrapper,
  Text,
} from "../Tax/styles";
import { api } from "src/services/api";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import { Clipboard, Dot, Plus, PlusCircle, Save, Table2 } from "lucide-react";
import toLocaleAmount from "src/utils/toLocaleAmount";

type Plan = {
  id: string;
  name: string;
  description: string;
  price: number;
  subscribersIds: string[];
  avaliableToRoles: string[];
};

export function Plans() {
  const token = localStorage.getItem("@backoffice:token");

  const [data, setData] = useState<Plan[]>([]);

  const [isNew, setIsNew] = useState(false);

  const fetchPlans = async () => {
    try {
      let url = `/backoffice/plans`;
      const { data } = await api.get(url, {
        headers: { Authorization: "Bearer " + token },
      });

      setData(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Configuração de planos</span>
          </Text>
          <Table>
            <div
              onClick={() => setIsNew(true)}
              className="flex flex-col w-full mb-2 cursor-pointer"
            >
              <div className="flex items-center justify-center gap-1 self-end bg-blue-500 px-2 py-1 rounded shadow">
                <span className="text-white font-medium text-sm">
                  Novo plano
                </span>
                <Plus color="#fff" size={18} />
              </div>
            </div>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <td>Plano</td>
                    <td>Descrição</td>
                    <td>Preço</td>
                    <td>Total assinantes</td>
                    <td>Disponível para contas</td>
                  </tr>
                </thead>
                <tbody>
                  {!data && <tr className="text-zinc-500">carregando</tr>}

                  {data.map((plan) => (
                    <PlanDetails key={plan.id} data={plan} />
                  ))}

                  {isNew && (
                    <PlanDetails
                      data={
                        {
                          description: "",
                          id: "",
                          name: "",
                          price: 0,
                          subscribersIds: [],
                          avaliableToRoles: [],
                        } as Plan
                      }
                      isNew
                      onCreatePlan={(plan) => {
                        setData([...data, plan]);
                        setIsNew(false);
                      }}
                      onCancel={() => setIsNew(false)}
                    />
                  )}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
}

const PlanDetails: FC<{
  data: Plan;
  isNew?: boolean;
  onCancel?: () => void;
  onCreatePlan?: (plan: Plan) => void;
}> = ({ data, isNew, onCancel, onCreatePlan }) => {
  const [plan, setPlan] = useState<Plan>(data);

  const [editing, setEditing] = useState(isNew || false);

  const token = window.localStorage.getItem("@backoffice:token");

  const save = async () => {
    setEditing(false);
    updatePlans();
  };

  const updatePlans = async () => {
    try {
      let url = `/backoffice/plans`;
      if (isNew) {
        const response = await api.post(url, plan, {
          headers: { Authorization: "Bearer " + token },
        });

        const createdPlan = response.data;

        if (onCreatePlan) onCreatePlan(createdPlan);
      } else {
        const response = await api.put(url, plan, {
          headers: { Authorization: "Bearer " + token },
        });
      }
    } catch (error) {
      console.log("erro ao atualizar plano", error);
    }
  };

  const onKeyDown = (event: any) => {
    const pressedKey = event.key;

    if (pressedKey == "Enter") {
      save();
    }

    if (pressedKey == "Escape") {
      if (onCancel) onCancel();
      setPlan(data);
      setEditing(false);
    }
  };

  const rolesOptions = ["MEMBERPJ", "MEMBERPF", "WALLET", "GRAPHIC"];
  const rolesMap: any = {
    MEMBERPJ: "Conta PJ",
    MEMBERPF: "Conta PF",
    WALLET: " Wallet",
    GRAPHIC: "Conta C-A",
  };

  return (
    <tr
      className="relative"
      onClick={() => setEditing(true)}
      onKeyDown={onKeyDown}
    >
      {!editing && (
        <>
          <td className="text-sm">{plan.name}</td>
          <td className="text-sm">{plan.description}</td>
          <td className="text-sm">R$ {toLocaleAmount(plan.price)}</td>
          <td className="text-center text-sm">{plan.subscribersIds.length}</td>
          <td className="flex items-center justify-end gap-2  w-[480px] absolute right-0">
            {plan.avaliableToRoles.map((role) => (
              <div className="flex gap-1 items-center">
                <span className="text-xs">{rolesMap[role as any]}</span>
              </div>
            ))}
          </td>
        </>
      )}

      {editing && (
        <>
          <td className="relative">
            <Dot className="absolute left-[-4px] top-4" size={30} />
            <input
              placeholder="Nome"
              autoFocus
              className="text-base text-zinc-700 bg-transparent h-full"
              value={plan.name}
              onChange={(e) =>
                setPlan((old) => ({ ...old, name: e.target.value }))
              }
            />
          </td>
          <td>
            <input
              placeholder="Descrição"
              autoFocus
              className="text-base text-zinc-700 bg-transparent h-full"
              value={plan.description}
              onChange={(e) =>
                setPlan((old) => ({ ...old, description: e.target.value }))
              }
            />
          </td>
          <td>
            <input
              autoFocus
              className="text-base text-zinc-700 bg-transparent h-full"
              value={plan.price}
              type="number"
              onChange={(e) =>
                setPlan((old) => ({
                  ...old,
                  price: Number.isNaN(parseFloat(e.target.value))
                    ? 0
                    : parseFloat(e.target.value),
                }))
              }
            />
          </td>
          <td className="flex items-center justify-end gap-2  w-[480px] absolute right-0">
            {rolesOptions.map((role, i) => (
              <div className="flex gap-1 items-center">
                <input
                  checked={plan.avaliableToRoles.includes(role)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPlan((old) => ({
                        ...plan,
                        avaliableToRoles: [...plan.avaliableToRoles, role],
                      }));
                    } else {
                      setPlan((old) => ({
                        ...plan,
                        avaliableToRoles: plan.avaliableToRoles.filter(
                          (ar) => ar != role
                        ),
                      }));
                    }
                  }}
                  type="checkbox"
                  value={role}
                />
                <span className="text-xs">{rolesMap[role as any]}</span>
              </div>
            ))}
          </td>
        </>
      )}
    </tr>
  );
};

import styled from "styled-components";
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as ScheduleIconSVG} from '../../assets/schedule.svg'

export const BillIcon = styled(BillIconSVG)`
fill: var(--cian);
width: 16px;
`
export const Container = styled.div`
  height: 100vh;
`;

export const RecentBilletsTitle = styled.h2`
  margin-top: 24px;
  font-size: 24px;
  font-family: var(--bold);
  color: var(--gray-dark);
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
`;

export const ContentWrapper = styled.div`
  padding: 32px;
`;

export const WrapperHeader = styled.div`

margin-bottom: 44px;

`

export const ButtonIcon = styled.button`
  display: flex; /* Adiciona o display flex para alinhar os elementos lado a lado */
  align-items: center; /* Alinha verticalmente os elementos ao centro */
  background-color: #ff0013;
  border: 1px solid var(--boxText); /* cor padrão da borda */
  padding: 6px 12px; /* Ajuste o valor do padding para reduzir a altura */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  width: 120px; 
    color: #fff;
  &:hover {
    background-color: red; /* cor vermelha quando o mouse passa por cima */
    transform: scale(1.05); /* destaque um pouco */
  }

  & > svg {
    margin-right: 18px; /* Espaçamento entre o ícone e o texto */
  }
`;

export const PrintButtonIcon = styled.button`
  align-items: center; /* Alinha verticalmente os elementos ao centro */
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  width: 50px;
    color: #fff;
  &:hover {
    background-color: transparent; /* cor vermelha quando o mouse passa por cima */
    transform: scale(1.05); /* destaque um pouco */
  }

  & > svg {
    margin-right: 18px; /* Espaçamento entre o ícone e o texto */
    width: 30px;
     height: 30px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 20px; /* Espaçamento entre os cards (opcional) */
  /* Se você quiser que os cards ocupem a largura igualmente, adicione a seguinte linha: */
  /* justify-content: center; */
  margin-left: 30px;
`;
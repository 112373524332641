import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  HeaderWrapper,
  ContactsWrapper,
  Contact,
  ContactInfo,
  TransferMoney,
} from "./styles";
import { api } from "src/services/api";
import { Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { service } from "../../services/ajio";

interface Contact {
  id: string;
  contactName: string;
  keyPix: string;
  keyType: string;
}

export function Contacts() {
  const token = localStorage.getItem("@stricv2:token");
  const [contacts, setContacts] = useState<Contact[]>([]);
  const navigation = useNavigate();

  const fetchContacts = async () => {
    try {
      let url = `/pix/favorites`;
      const { data } = await api.get<Contact[]>(url, {
        headers: { Authorization: "Bearer " + token },
      });

      setContacts(data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleDeleteContact = async (id: string) => {
    try {
      let url = `/pix/favorites`;
      await api.delete(`${url}/${id}`, {
        headers: { Authorization: "Bearer " + token },
      });

      // Após a exclusão, buscar novamente os contatos
      fetchContacts();
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
  };

  const handlePix = async (contato: Contact) => {
    let keySelected;

    try {
      const { data } = await service.post("/pix/validate", {
        key: contato.keyPix,
      });
      navigation("/pix/transfer/confirm", {
        state: { ...data },
      });
    } catch (err) {}
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <Container>
      <Header>
        <HeaderWrapper>
          <span>Contatos Frequentes</span>
        </HeaderWrapper>
      </Header>
      <ContactsWrapper>
        {contacts.length === 0 ? (
          <Contact>
            <ContactInfo>
              <span>Não há nenhum contato cadastrado ainda</span>
            </ContactInfo>
          </Contact>
        ) : (
          contacts.map((contact) => (
            <Contact key={contact.id} className="max-h-64 overflow-y-auto">
              <ContactInfo onClick={() => handlePix(contact)}>
                <p className="cursor-pointer">
                  <span className="font-bold text-black">
                    {contact.contactName}
                  </span>{" "}
                  - <span className="text-blue-500">{contact.keyType}</span>:{" "}
                  <span className="text-red-500">{contact.keyPix}</span>
                </p>
              </ContactInfo>
              <hr className="mt-2" />
              <Trash2
                className="text-red-500 cursor-pointer"
                onClick={() => handleDeleteContact(contact.id)}
              />
            </Contact>
          ))
        )}
      </ContactsWrapper>
    </Container>
  );
}

import { FC } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useNavigate } from "react-router-dom";

import "./styles.css";

export const ChooseAccountType: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame onPress={() => navigate("/create-account-wallet")} />

      <div>
        <svg style={{ position: "relative" }} width="700" height="560">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="650"
            height="550"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[24vh] ml-20">
          <h1 className="font-medium text-white text-5xl">
            É hora de escolher o <br />
            tipo da <strong className="text-[#274683] text-5xl">sua Wallet!</strong>
          </h1>

          <p className="font-extralight text-white text-xl mt-5 mb-[12vh]">
            Decida se deseja uma cona Wallet PIXWAVE <br />
            comercial ou pessoal.
          </p>

          <div className="flex flex-col gap-4">
            <GradientButton
              title="Conta Física"
              tailwindStyles="bg-[#274683] w-[30rem] h-16 text-white text-lg"
              onClick={() => navigate("/create-account-wallet/insert-data", { state: { accountType: "PF" } })}
            />

            <GradientButton
              title="Conta Business"
              tailwindStyles="bg-[#171717] w-[30rem] h-16 text-white text-lg"
              onClick={() => navigate("/create-account-wallet/insert-data-pj", { state: { accountType: "PJ" } })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

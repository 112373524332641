export function reduceAccountsBalance(accounts: { balance: number }[]): number {
  if (accounts.length == 0) return 0;

  const filter = accounts.filter((item: any) => {
    return item.user.role !== "MASTER";
  });

  const totalBalance = filter
    .map((acc: any) => acc.balance)
    .reduce((total: number, current: number) => {
      return total + current;
    });
  return totalBalance;
}

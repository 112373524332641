import React, { ButtonHTMLAttributes } from "react";
// import { Icon } from "../sidebar/styles";
import { ContainerButton } from "./styles";
import { Loading } from "src/components/loading";

interface IconTypeProps {
    width?: number;
    height?: number;
    color?: string;
  }

type IconType = (props: IconTypeProps) => JSX.Element;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    Icon?: IconType;
    handleFunction?: () => void;
    color?: string;
    isLoading?: boolean;
  }

export const Button: React.FC<Props> = ({
    Icon,
    type= 'button',
    title,
    color,
    handleFunction,
    isLoading
}) => {

    return (
        <ContainerButton type={type} onClick={handleFunction} color={color} disabled={isLoading} style={{
                    border: "2px solid var(--cian)",
                }}>
            {
                Icon && <Icon/>
            }
            {isLoading ? <Loading isLoading={isLoading} /> : null}
            <p>{title}</p>
        </ContainerButton>
    )
}

// Dentro do componente ConfigTax

import React, { FC, useEffect, useRef, useState } from "react";
import {
  ContentWrapper,
  Container,
  Content,
  Table,
  TableWrapper,
  Text,
} from "./styles";
import { api } from "src/services/api";
import { ChevronLeft, ChevronRight, Plus, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/button";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { useAuth } from "src/hooks/auth";
import { useNotification } from "src/hooks/notification";

export type Plan = {
  id: string;
  name: string;
  taxes: {
    id: string;
    name: string;
    installments: number;
    pagPlansId: string;
    tax: number;
  }[];
};

export const ConfigTax: React.FC = () => {
  const token = localStorage.getItem("@backoffice:token");

  const [sidebarMobileIsActive, setSidebarMobileIsActive] = useState(false);
  const [data, setData] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  const navigate = useNavigate();

  const fetchTaxs = async () => {
    try {
      const { data } = await api.get<Plan[]>("/backoffice/pag-plans", {
        headers: { Authorization: "Bearer " + token },
      });
      console.log(data);

      setData(data);
    } catch (error) {
      console.error("Erro ao buscar planos:", error);
    }
  };

  const handleDelete = async (planId: string) => {
    try {
      await api.delete(`/backoffice/pag-plans/${planId}`, {
        headers: { Authorization: "Bearer " + token },
      });
      // Fechar o modal e recarregar a lista
      setSelectedPlan(undefined);
      fetchTaxs();
    } catch (error) {
      console.error("Erro ao deletar plano:", error);
    }
  };

  useEffect(() => {
    fetchTaxs();
  }, []);

  return (
    <Container>
      <Content>
        <ContentWrapper className="justify-center">
          <Table>
            <div className="flex flex-col gap-2 mb-2">
              <Text>
                <span>Configuração de tarifas</span>
              </Text>
              <div className="flex gap-2">
                {selectedPlan && (
                  <div
                    className="flex cursor-pointer"
                    onClick={() => {
                      setSelectedPlan(undefined);
                    }}
                  >
                    <div className="flex items-center justify-center gap-1 bg-black px-4 py-1 rounded shadow">
                      <ChevronLeft color="#fff" size={18} />
                    </div>
                  </div>
                )}
                <div
                  className="flex cursor-pointer"
                  onClick={() => {
                    navigate("/backoffice/config/tax/form");
                  }}
                >
                  <div className="flex items-center justify-center gap-1 bg-blue-500 px-2 py-1 rounded shadow">
                    <span className="text-white font-medium text-sm">
                      Novo plano
                    </span>
                    <Plus color="#fff" size={18} />
                  </div>
                </div>
              </div>
            </div>
          </Table>

          <div className="flex flex-col overflow-hidden items-center w-full bg-white rounded-lg border border-zinc-200 shadow-lg">
            {selectedPlan ? (
              <PlanDetail plan={selectedPlan} onDelete={handleDelete} />
            ) : (
              data.map((item) => (
                <div
                  onClick={() => setSelectedPlan(item)}
                  key={item.id}
                  className="grid grid-cols-2 cursor-pointer hover:bg-gray-100 duration-150 w-full p-4"
                >
                  <span>{item.name}</span>
                  <div className="flex justify-end">
                    <div>
                      <ChevronRight size={20} color="#222" />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

interface PlanDetailProps {
  plan: Plan;
  onDelete: (planId: string) => void;
}

export const PlanDetail: FC<PlanDetailProps> = ({ plan, onDelete }) => {
  interface PlanDetailProps {
    plan: Plan;
    onDelete: (planId: string) => void;
  }

  const [focusedTaxId, setFocusedTaxId] = useState("");
  const token = localStorage.getItem("@backoffice:token");

  const orderedTax = plan.taxes.sort(
    (t1, t2) => t1.installments - t2.installments
  );

  const handleDelete = async () => {
    try {
      const { data } = await api.delete<Plan[]>(
        `/backoffice/pag-plans/${plan.id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
    } catch (error) {
      console.error("Erro ao deletar plano:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex items-center">
          <span className="font-bold h-12 p-4 flex-grow">
            Plano: {plan.name}
          </span>
          <button onClick={handleDelete} className="flex items-center">
            <button onClick={() => onDelete(plan.id)}>
              <Trash2 className="mr-2" color="#ff0000" />
            </button>
          </button>{" "}
        </div>

        <div className="grid grid-cols-2">
          <div className="flex items-center text-zinc-500 font-bold h-12 p-4">
            Nome da taxa
          </div>
          <div className="flex items-center text-zinc-500 font-bold justify-end h-12 p-4">
            Valor da taxa
          </div>
        </div>
        {orderedTax.map((data, index) => (
          <TaxDetail
            index={index}
            focused={focusedTaxId == data.id}
            onFocus={() => setFocusedTaxId(data.id)}
            data={data}
            key={data.id}
          />
        ))}
      </div>
    </>
  );
};

const TaxDetail: FC<{
  index: number;
  data: {
    id: string;
    name: string;
    pagPlansId: string;
    tax: number;
    installments: number;
  };
  focused: boolean;
  onFocus: () => void;
}> = ({ data, focused, onFocus, index }) => {
  const [name, setName] = useState<string>(data.name);
  const [tax, setTax] = useState<string>(data.tax.toString());

  const [editing, setEditing] = useState<boolean>(focused);
  const [loading, setLoading] = useState<boolean>(false);

  const token = localStorage.getItem("@backoffice:token");

  const { setOpenNotification } = useNotification();

  const inputNameRef = useRef<HTMLInputElement>(null);
  const inputTaxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEditing(focused);
  }, [focused]);

  const handleEdit = async () => {
    try {
      setLoading(true);
      const taxFloat = parseFloat(tax);

      if (Number.isNaN(taxFloat)) {
        setLoading(false);

        return console.log(taxFloat);
      }

      const response = await api.put(
        `/backoffice/pag-plans/tax`,
        {
          ...data,
          name,
          tax: taxFloat,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpenNotification({
        notification: "",
        title: "Editado",
        type: "success",
      });
      setLoading(false);
      setEditing(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  return (
    <div
      onKeyDown={(e) => {
        const key = e.key;

        if (key == "Enter") {
          handleEdit();
        }

        if (key == "Escape") {
          setName(data.name);
          setTax(data.tax.toFixed(2));
          setEditing(false);
        }
      }}
    >
      {editing ? (
        <div className="grid grid-cols-2">
          <input
            ref={inputNameRef}
            placeholder={
              data.installments == 0 ? "Débito" : `${data.installments}x`
            }
            style={{
              fontStyle: loading ? "italic" : "normal",
            }}
            disabled={loading}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="flex h-12 cursor-text hover:bg-gray-100 duration-150 p-4 "
          />
          <input
            ref={inputTaxRef}
            style={{
              fontStyle: loading ? "italic" : "normal",
            }}
            disabled={loading}
            type="number"
            value={tax}
            onChange={(e) => {
              setTax(e.target.value);
            }}
            className="flex justify-center text-end h-12 cursor-text hover:bg-gray-100 duration-150 p-4"
          />
        </div>
      ) : (
        <div className="grid grid-cols-2">
          <div
            onClick={() => {
              setEditing(true);
              onFocus();
              setTimeout(() => {
                inputNameRef.current?.focus();
              }, 100);
            }}
            className={`flex ${
              index % 2 == 0 ? "bg-zinc-50" : "bg-white"
            } h-12 cursor-text items-center hover:bg-gray-100 hover:shadow-inner duration-150 p-4`}
          >
            {name}
          </div>
          <div
            onClick={() => {
              setEditing(true);
              onFocus();
              setTimeout(() => {
                inputTaxRef.current?.focus();
              }, 100);
            }}
            className={`flex ${
              index % 2 == 0 ? "bg-zinc-50" : "bg-white"
            } justify-end items-center h-12 cursor-text hover:bg-gray-100 hover:shadow-inner duration-150 p-4`}
          >
            {tax}%
          </div>
        </div>
      )}
    </div>
  );
};

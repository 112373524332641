import React, { useEffect, useState, Fragment, FormEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";

import { Dialog, Transition } from "@headlessui/react";
import { PrinterIcon, XIcon } from "@heroicons/react/outline";

import {
  Action,
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  RedirectWrapperButton,
  Search,
  Table,
  TableWrapper,
  ArrowRightDepositIcon,
  ButtonsTableWrapper,
} from "./styles";

import { ButtonSvgColor as ButtonForm } from "./components/create-control-account.styles";

import { service, serviceError } from "../../services/ajio";
import { documentFormatted } from "../../utils/documentFormatted";
import { currencyFormat } from "../../utils/currencyFormat";
import { FormInput } from "../../components/LPFormButton2ControlAccount";
import { Loading } from "../../components/loading";
import { Skeleton } from "../../components/skeleton";
import { Money } from "phosphor-react";
import { format } from "date-fns";
import { da, de } from "date-fns/locale";
import TextInput from "../../components/TextInput";
import { useNotification } from "../../hooks/notification";
import { PrintButtonIcon } from "../receipt/styles";
import { GerenciarContasHandlePDF } from "./components/handleGeneratePDF";
import ToggleSwitchFavorite from "./components/toogleFavorite";
import { passwordEletronicFormatted } from "src/utils/passwordFormated";
import { useAuth } from "src/hooks/auth";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { api } from "src/services/api";
import Modal from "src/components/Modal";
import { HelpCircle } from "lucide-react";
import ButtonSignInColor from "../../../src/assets/button-color.svg";
import { FormInputSelect } from "./components/formInputCreateCotrolAccount";

export interface Account {
  id: string;
  balance: number;
  name: string;
  status: string;
  email: string;
  document: string;
  number_identifier: string; // Ou `number` se for um número
  status_pin_eletronic: boolean;
}

export interface ExtractType {
  id: string;
  description: string;
  amount: number;
  created_at: string;
  direction: "in" | "out";
  status: string;
  graphic_account_id?: string;
}

type Plan = {
  id: string;
  name: string;
  description: string;
  price: number;
};

export const GerenciarContas: React.FC = () => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<Account[]>([]);

  const [currentItem, setCurrentItem] = useState<Account>({} as Account);

  const { account, refresh, user } = useAuth();

  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordEletronic, setPasswordEletronic] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(false);
  const [approveAll, setApproveAll] = useState(false);
  const [valueEletronic, setValueEletronic] = useState("");

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const password2InputRef = useRef<HTMLInputElement>(null);

  const { setOpenNotification } = useNotification();

  const [openModalExtract, setOpenModalExtract] = useState(false);
  const [extract, setExtract] = useState<ExtractType[]>([]);
  const [loadExtract, setLoadExtract] = useState(false);

  const [balanceTotal, setBalanceTotal] = useState(0);

  const [maskDocument, setMaskDocument] = useState("99.999.999/9999-99");

  const [open, setOpen] = useState(false);
  const [openBalance, setOpenBalance] = useState(false);

  const [operation, setOperation] = useState("");

  const [amount, setAmount] = useState("");

  const [error, setError] = useState("");
  const [pin, setPin] = useState("");

  const cancelButtonRef = useRef(null);

  function handleDocument(doc: string) {
    setDocument(doc);
    if (doc.length >= 15) {
      setMaskDocument("99.999.999/9999-99");
    } else {
      setMaskDocument("999.999.999-999");
    }
  }

  // Função para validar o tamanho do CPF
  function isValidCPF(cpf: string): boolean {
    // Remover caracteres especiais e espaços
    const cleanedCPF = cpf.replace(/[/.,-\s]/g, "");

    // Verificar se o CPF tem 11 dígitos
    return cleanedCPF.length === 11;
  }

  // Função para validar o tamanho do CNPJ
  function isValidCNPJ(cnpj: string): boolean {
    // Remover caracteres especiais e espaços
    const cleanedCNPJ = cnpj.replace(/[/.,-\s]/g, "");

    // Verificar se o CNPJ tem 14 dígitos
    return cleanedCNPJ.length === 14;
  }

  // Função para validar o documento (CPF ou CNPJ)
  function isValidDocument(document: string): boolean {
    // Remover caracteres especiais e espaços
    const cleanedDocument = document.replace(/[/.,-\s]/g, "");

    // Verificar se é um CPF ou CNPJ
    if (cleanedDocument.length === 11) {
      return isValidCPF(document);
    } else if (cleanedDocument.length === 14) {
      return isValidCNPJ(document);
    }

    return false; // Documento tem um tamanho inválido
  }

  async function fetchAccounts() {
    try {
      setLoading(true);
      const { data } = await service.get("/graphic");
      //setAccounts(data.accounts);

      // Filtrar contas para incluir apenas aquelas com role "GRAPHIC"
      const graphicAccounts = data.accounts.filter(
        (account: { role: string }) => account.role === "GRAPHIC"
      );

      // Definir o estado 'accounts' com as contas filtradas
      setAccounts(graphicAccounts);

      const total = data.accounts.reduce((acc: number, item: Account) => {
        return (acc += item.balance);
      }, 0);
      setBalanceTotal(total);
    } catch (err) {
      // err
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAccounts();
  }, []);

  async function handleOperation() {
    setLoadingOperation(true);
    const parseAmount = Number(amount.replace(/[/,R$ .,-\s]/g, "")) / 100;
    try {
      await service.post(`graphic/${operation}`, {
        graphic_account_id: currentItem.id,
        amount: parseAmount,
      });
      await fetchAccounts();
      setOpenBalance(false);
    } catch (err) {
      setError(serviceError(err).friend);
    } finally {
      setLoadingOperation(false);
    }
  }

  async function handleCreateAccount(e: FormEvent) {
    e.preventDefault();
    setLoadingCreateAccount(true);

    try {
      // Validar o campo 'name'
      if (!validateName(name)) {
        throw new Error(
          "O campo 'name' deve ter pelo menos 8 caracteres e conter apenas letras e acentos."
        );
      }

      // Validar o campo 'email'
      if (!validateEmail(email)) {
        throw new Error(
          "O campo 'email' deve ter um formato de e-mail válido."
        );
      }

      // Validar o campo 'password'
      if (!validatePassword(password)) {
        throw new Error(
          "O campo 'password' deve conter pelo menos 1 letra, 1 número, 1 caractere especial e ter no mínimo 8 dígitos."
        );
      }

      // Validar o campo 'document'
      if (!isValidDocument(document)) {
        throw new Error("O campo 'document' deve ser um CPF ou CNPJ válido.");
      }

      if (!selectedPlanId || !pin || pin.length < 4) {
        throw new Error("O campo 'document' deve ser um CPF ou CNPJ válido.");
      }

      const raw = {
        name,
        document: document.replace(/[/,.,-\s]/g, ""),
        email,
        pin,
        planId: selectedPlanId,
        password,
        passwordEletronic: passwordEletronic.replace(/[^0-9]/g, ""),
        isRole: "GRAPHIC",
        isUser: user.id,
      };

      await service.post("/graphic/create", raw);
      await fetchAccounts();

      // Exibir mensagem de sucesso
      setOpenNotification({
        title: "Sucesso",
        notification: "Conta criada com sucesso.",
        type: "success",
      });

      setOpen(false);
    } catch (err) {
      setError(serviceError(err).friend);
      // Exibir mensagem de erro
      setOpenNotification({
        title: "Erro",
        notification:
          "Não foi possível criar a conta. Verifique os dados e tente novamente.",
        type: "error",
      });
    } finally {
      setLoadingCreateAccount(false);
    }
  }

  // Função para validar o campo 'name'
  function validateName(name: string): boolean {
    const nameRegex = /^[a-zA-Záéíóúâêîôûãõàèìòùäëïöüç\s]{8,}$/;
    return nameRegex.test(name);
  }

  // Função para validar o campo 'email'
  function validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Função para validar o campo 'password'
  function validatePassword(password: string): boolean {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return passwordRegex.test(password);
  }

  const handleNextScreen = () => {
    setOpen(true);
  };

  async function handleApproveAll() {
    if (valueEletronic.length !== 8) return alert("Senha eletrônica inválida!");
    setLoadingOperation(true);
    try {
      const data = {
        pin: account.pin,
        graphic_account: true,
        data: accounts.map((item) => item.id),
      };
      await service.post("/graphic/transactions/approved", data);

      await fetchAccounts();

      setApproveAll(false);
      setLoadingOperation(false);
      setOpenNotification({
        title: "Sucesso",
        notification: "Pagamentos aprovados",
        type: "success",
      });
    } catch (error) {
      setApproveAll(false);
      setLoadingOperation(false);
      setOpenNotification({
        title: "Erro",
        notification: "Não foi possível aprovar todos",
        type: "error",
      });
    }
  }

  const pinInputRef = useRef<HTMLInputElement>(null);

  function handleBalance(item: Account) {
    setCurrentItem(item);
    setOpenBalance(true);
  }

  async function handleExtract(item: Account) {
    setLoadExtract(true);
    setOpenModalExtract(true);
    try {
      const account = accounts.find(({ id }) => id === item.id);
      if (account) setCurrentItem(account);

      const { data } = await service.get(`/graphic/${item.id}/transactions`);
      setExtract(data.transactions);
    } catch (err) {
      setError(serviceError(err).friend);
    } finally {
      setLoadExtract(true);
    }
  }

  function handleApprove(item: Account) {
    navigate(`/aprovacoes/${item.id}`);
  }

  function handleGeneratePDF() {
    const pdf = new GerenciarContasHandlePDF(extract, currentItem);
    pdf.generate();
  }

  function getDescription(description: string) {
    if (description) {
      if (description === "waiting") {
        return "Aguar. Aprovação";
      }

      if (description === "done") {
        return "Concluído";
      }

      if (description === "denied") {
        return "Cancelado";
      }

      if (description === "error") {
        return "Falha";
      }
    }

    return description;
  }

  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false);

  const fetchPlans = async () => {
    try {
      const response = await api.get("/graphic/plans", {
        params: {
          role: "WALLET",
        },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <>
      <Container>
        <Content>
          <ContentWrapper>
            <Action>
              <Search>
                <span>
                  Liste todas as suas contas, crie conta e realize depositos
                  e/ou saques. Você também pode acessar o extrato delas clicando
                  em detalhes.
                </span>
                {/* <InputWrapper>
                  <input
                    type="text"
                    placeholder="Pesquisar por CPF/CNPJ"
                  />
                </InputWrapper> */}
              </Search>
              <ButtonWrapper>
                <Button handleFunction={handleNextScreen} title="Criar Conta" />
                <Button
                  handleFunction={() => setApproveAll(true)}
                  title="Aprovar todos"
                />
              </ButtonWrapper>
            </Action>
            <Table>
              <TableWrapper>
                {loading ? (
                  <Skeleton space={4} isActive={loading} repeat={5} />
                ) : (
                  <>
                    {accounts && accounts.length <= 0 ? (
                      <div className="text-center p-4">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                          Nenhuma conta
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Você ainda não criou nenhuma conta adicional.
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="p-4 flex justify-end">
                          Saldo em contas: {currencyFormat(balanceTotal)}
                        </div>
                        <table>
                          <tbody>
                            <tr>
                              <th>Nome</th>
                              <th>Favoritar</th>
                              <th>CPF/CNPJ</th>
                              <th>Saldo</th>
                              <th>Gerenciar</th>
                              <th>Extrato</th>
                              <th>Aprovações</th>
                            </tr>

                            {accounts.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>
                                    <div>
                                      <ToggleSwitchFavorite
                                        userId={item.id}
                                        initialStatus={
                                          item.status_pin_eletronic
                                        }
                                      />
                                    </div>
                                  </td>

                                  <td>{documentFormatted(item.document)}</td>
                                  <td>{currencyFormat(item.balance)}</td>
                                  <td>
                                    <ButtonsTableWrapper>
                                      <RedirectWrapperButton
                                        onClick={() => handleBalance(item)}
                                      >
                                        <span>Gerenciar Saldo</span>
                                        <ArrowRightDepositIcon />
                                      </RedirectWrapperButton>
                                    </ButtonsTableWrapper>
                                  </td>

                                  <td>
                                    <ButtonsTableWrapper>
                                      <RedirectWrapperButton
                                        onClick={() => handleExtract(item)}
                                      >
                                        <span>Extrato</span>
                                        <ArrowRightDepositIcon />
                                      </RedirectWrapperButton>
                                    </ButtonsTableWrapper>
                                  </td>
                                  <td>
                                    <ButtonsTableWrapper>
                                      <RedirectWrapperButton
                                        onClick={() => handleApprove(item)}
                                      >
                                        <span>Ir para aprovações</span>
                                        <ArrowRightDepositIcon />
                                      </RedirectWrapperButton>
                                    </ButtonsTableWrapper>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </>
                )}
              </TableWrapper>
            </Table>
          </ContentWrapper>
        </Content>
      </Container>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleCreateAccount}
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-custom-blue px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Nova Conta
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                {/* Get started by filling in the information below to create your new project. */}
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-base hover:text-base-900"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 py-6 sm:px-6">
                          <FormInput
                            label="NOME"
                            required
                            value={name}
                            // error={error.name || ""}
                            onChanged={(e) => setName(e.target.value)}
                          />

                          <FormInput
                            label="CPF/CNPJ"
                            required
                            mask={maskDocument}
                            value={document}
                            // error={error.name || ""}
                            onChanged={(e) => handleDocument(e.target.value)}
                          />

                          <FormInput
                            label="E-mail"
                            required
                            value={email}
                            // error={error.name || ""}
                            onChanged={(e) => setEmail(e.target.value)}
                          />

                          <FormInput
                            ref={pinInputRef}
                            id="pin-input"
                            maxLength={4}
                            label="SECURITY DIGIT (Camada extra de segurança)"
                            type="password"
                            showPasswordIcon={true}
                            value={pin}
                            onChanged={(e) => setPin(e.target.value)}
                          />

                          <FormInput
                            label="Senha Eletrônica  ( Digite 8 números não sequenciais. )"
                            required
                            ref={password2InputRef}
                            id="password2-input"
                            showPasswordIcon={true}
                            type="password"
                            value={passwordEletronic}
                            // error={error.name || ""}
                            onChanged={(e) => {
                              // Use a função de formatação e atualize o estado da senha eletrônica
                              const formattedPassword =
                                passwordEletronicFormatted(e.target.value);
                              setPasswordEletronic(formattedPassword);
                            }}
                          />

                          <FormInput
                            label="Senha ( Digite pelo menos 1 letra, 1 número e ao menos 1 carácter especial. Míninmo de 8 dígitos ) "
                            required
                            ref={passwordInputRef}
                            id="password-input"
                            showPasswordIcon={true}
                            type="password"
                            value={password}
                            // error={error.name || ""}
                            onChanged={(e) => setPassword(e.target.value)}
                          />
                           <div className="flex items-center gap-2 bg-[#f6f6f6] pr-4 rounded-xl">
                    <FormInputSelect
                      placeholder="PLANO"
                      options={plans.map((p) => ({
                        id: p.id,
                        name: `${p.name} R$ ${toLocaleAmount(p.price)}`,
                      }))}
                      onChanged={(e) => setSelectedPlanId(e.target.value)}
                      // onChanged={(e) => {
                      //   const translatedValue = mapplanToTranslation(
                      //     e.target.value
                      //   );
                      //   setFormInfo({ ...formInfo, name: translatedValue }); // add plans  no info forms e raw
                      // }}
                      // error={error.isPep || ""}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowPlanModal(true);
                      }}
                    >
                      <HelpCircle size={28} color="#009EE2" />
                    </button>
                          </div>
                          {/* <WrapperButton> */}
                          <ButtonForm type="submit">
                            <Loading isLoading={loadingCreateAccount} />
                            Criar Conta
                          </ButtonForm>

                          <div className="mt-2 text-red-500">{error}</div>
                          {/* </WrapperButton> */}
                        </div>
                      </div>

                      {/* Action buttons */}
                      {/* <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Criar Conta
                        </button>
                      </div>
                    </div> */}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
          <Modal
            onClickClose={() => {
              setShowPlanModal(false);
              setOpen(true);
            }}
            open={showPlanModal}
          >
            <div className="flex flex-col items-center gap-8">
              <span className="text-3xl font-extrabold">Planos</span>
              <div className="flex w-full max-w-[800px] items-center gap-3 overflow-x-auto">
                {plans.map((p) => (
                  <div className="flex flex-col justify-between p-4 min-w-[220px] max-w-[280px] h-[250px] overflow-hidden shadow-inner border border-zinc-200 rounded-xl">
                    <div className="flex flex-col">
                      <span className="text-2xl font-semibold">{p.name}</span>
                      <span className="text-3xl font-extrabold text-blue-500">
                        R$ {toLocaleAmount(p.price)}{" "}
                        <span className="text-sm">/ mês</span>
                      </span>
                    </div>
                    <span className="text-sm font-semibold text-zinc-800 text-clip">
                      {p.description}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={approveAll} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenBalance}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Aprovar Todos
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="mt-5 flex flex-col justify-center gap-4">
                          <TextInput
                            placeholder="Senha eletrônica de 8 dígitos"
                            maxLength={8}
                            value={valueEletronic}
                            type="password"
                            onChange={(input) =>
                              setValueEletronic(input.target.value)
                            }

                            // onChange={(input) =>
                            //  setPassword(input.target.value)
                            // }
                          />
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 flex justify-center">
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md bg-green-600 border border-green-300 shadow-sm px-4 py-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:mt-0 sm:col-start-1 sm:text-sm"
                          onClick={handleApproveAll}
                          ref={cancelButtonRef}
                        >
                          <Loading isLoading={loadingOperation} />
                          Enviar
                        </button>
                        <button
                          type="button"
                          className="mt-3 ml-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-custom-blue text-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                          onClick={() => setApproveAll(false)}
                          ref={cancelButtonRef}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openBalance} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenBalance}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <Money
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Gerenciar Saldo
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md text-gray-500">
                          Escolha a opção que deseja abaixo
                        </p>
                        <div className="mt-5 flex flex-col justify-center gap-4">
                          <div className="flex flex-1 gap-4">
                          <ButtonForm
                              type="button"
                              style={{
                                backgroundColor: "var(--green)",
                                border:
                                  operation === "withdraw"
                                    ? "2px solid var(--green)"
                                    : "2px solid #333",
                                    outline: operation === "withdraw" ? "5px solid var(--green)" : "none",

                              }}
                              onClick={() => setOperation("withdraw")}
                            >
                              Retirar
                            </ButtonForm>
                            
                            <ButtonForm
                              type="button"
                              onClick={() => setOperation("deposit")}
                              style={{
                                backgroundColor: "var(--red)",
                              border:
                                  operation === "deposit"
                                    ? "2px solid var(--red)"
                                    : "2px solid #333",
                             outline: operation === "deposit" ? "5px solid var(--red)" : "none",

                              }}
                            >
                              Depositar
                            </ButtonForm>

                            
                          </div>

                          {!!operation && (
                            <>
                              <div className="w-full flex flex-1">
                                <FormInput
                                  label="Valor"
                                  mask="currency"
                                  required
                                  onChanged={(e) => setAmount(e.target.value)}
                                />
                              </div>

                              <div>
                                <ButtonForm
                                  type="button"
                                  onClick={handleOperation}
                                  style={{
                                    backgroundImage: `url(${ButtonSignInColor})`,
                                    border:
                                      operation === "withdraw"
                                        ? "2px solid #333"
                                        : "2px solid #333",
                                       
                                  }}
                                >
                                  <Loading isLoading={loadingOperation} />
                                  Realizar operação
                                </ButtonForm>
                                <div className="mt-2 text-red-500">{error}</div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center">
                    {/* <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => {}}
                  >
                    Continuar
                  </button> */}
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-custom-blue text-white font-medium text-gray-700 hover:bg-custom-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => setOpenBalance(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openModalExtract} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenModalExtract}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Extrato
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                {/* Get started by filling in the information below to create your new project. */}
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpenModalExtract(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 py-6 sm:px-6">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {extract && extract.length <= 0 ? (
                                  <div>Não há itens para exibir.</div>
                                ) : (
                                  <>
                                    <PrintButtonIcon>
                                      <PrinterIcon
                                        onClick={handleGeneratePDF}
                                        color="var(--boxText)"
                                        className="h-4 w-4 inline"
                                      />
                                    </PrintButtonIcon>
                                    <table className="min-w-full divide-y divide-gray-300">
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                          >
                                            Data
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Descrição
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Valor
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Status
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200">
                                        {!loading &&
                                          extract.map((item) => {
                                            if (
                                              item.direction === "out" &&
                                              item.description === "P2P Ajiopay"
                                            ) {
                                              return null; // Ignora esta transação
                                            }
                                            return (
                                              <tr key={item.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                  {format(
                                                    new Date(item.created_at),
                                                    "dd/MM/yyyy"
                                                  )}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                  {item.description.substring(
                                                    0,
                                                    40
                                                  )}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                  {item.direction === "out"
                                                    ? "-"
                                                    : ""}
                                                  {currencyFormat(item.amount)}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                  {getDescription(item.status)}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

import styled from "styled-components";

interface props {
    color?: string;
}

export const Container = styled.button<props>`

cursor: pointer;
background: ${props => props.color ? props.color : 'var(--cian)'};
height: 40px;
width: 100%;
padding: 0 32px;
border-style: none;
align-items: center;
justify-content: center;
border-radius: 4px;
display: flex;
margin-bottom: 16px;

> p {
    color: var(--white);
    font-family: var(--semiBold);
    font-size: 14px;
}

> svg {
    margin-right: 8px;
}

`
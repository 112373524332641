import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Billet } from "../pages/billet";
import { CreateBillet } from "../pages/billet/create";
import { Billets } from "../pages/billet/billets";
import { Charge } from "../pages/charge";
import { Dashboard } from "../pages/dashboard";
import { Deposit } from "../pages/deposit";
import { Layout } from "../pages/layout";
import { Layout as BackofficeLayout } from "../pages/backoffice/Layout/base";
import { PayBills } from "../pages/payBills";
import { PayBillsPayInvoice } from "../pages/payBillsPayInvoice";
import { PixMyKeys } from "../pages/pixMyKeys";
import { PixNewKey } from "../pages/pixNewKey";
import { PixOptions } from "../pages/pixOptions";
import { Receipt } from "../pages/receipt";
import { TransferSubscribe } from "../pages/transferSubscribe";
import { Tax } from "../pages/tax";
import { Transfer } from "../pages/transfer";
import { TransferList } from "../pages/transferList";
import { PixList } from "../pages/pixList";
import { PixTransfer } from "../pages/pixTransfer";
import { PayBillsSchedulePayments } from "../pages/payBillsSchedulePayments";
import { PayBillsSubscribe } from "../pages/payBillsSubscribe";
import { PayBillsInfoInvoice } from "../pages/payBillsPayInvoiceInfo";
import { PayBillsInvoiceConfirm } from "../pages/payBillsInvoiceConfirm";
import { TransferConfirm } from "../pages/transferConfirm";
import { PixConfirm } from "../pages/pixConfirm";
import { SignIn } from "../pages/signIn";
import { SignIn as BackofficeSignIn } from "../pages/backoffice/SignIn";
import { FillOutToken } from "../pages/fillOutToken";
import { DepositInfoTedDoc } from "../pages/depositInfoTedDoc";
import { DepositNewInvoice } from "../pages/depositNewInvoice";
import { DepositNewInvoiceConfirm } from "../pages/depositNewInvoiceConfirm";
import { DepositInvoicesList } from "../pages/depositInvoicesList";
import { DepositPixMyKeys } from "../pages/depositPixMyKeys";
import { NewCreateAccountType, ConfirmUpgrade, UpgradeSuccess } from "../pages/newCreateAccountType";
import Requirements from "../pages/createAccountRequirements";
import Form from "../pages/createAccountForm";

import { SignoutPage } from "../pages/signout";
import { useAuth } from "../hooks/auth";
import { Forgot } from "../pages/forgot";
import { MyAccount } from "../pages/myAccount";
import { PixTransferCopiaECola } from "../pages/pixTransfer/PixTransferCopiaECola";
import { PixConfirmCopieEcola } from "../pages/pixConfirm/PixConfirmCopieEcola";
import { SignInToken } from "../pages/SingnInToken";
import { GerenciarContas } from "../pages/gerenciarContas";
import { Aprovacoes } from "../pages/aprovacoes";
import { MobileRecharger } from "../pages/recharge/mobileRecharger";
import { RechargerConfirm } from "../pages/rechargerConfirm/rechargerConfirm";
import { PrivacyPolicy } from "../pages/privacyPolicy";
import GuardedRoute from "./GuardedRoute";
import { useAuthBackoffice } from "../hooks/authBackoffice";
import { HomeDash } from "../pages/backoffice/Home";
import { Accounts } from "../pages/backoffice/Accounts";
import { Member, Wallet, Graphic } from "../pages/backoffice/AccountsDetails";
import { AccountsDetailsTax } from "../pages/backoffice/AccountsDetailsTax";
import { Transfers } from "../pages/backoffice/Transfers";
import { Logout } from "../pages/backoffice/Logout";
import { AccountsDetailsTransactions } from "../pages/backoffice/AccountsDetailsTransactions";
import { ControlAccountsTransactions } from "src/pages/backoffice/TransactionsCA";
import { ContaAccounts } from "src/pages/backoffice/ContaAccounts";
import { AddWalletFounds } from "src/pages/addsFounsWalletControlAccount";
import { PasswordControlAccountChange } from "src/pages/changePasswordControlAccount";
import { QrCodeStatic } from "src/pages/qrCodeStatic";
import { QrCodeDynamic } from "src/pages/qrCodeDynamic";
import { KeyPasswordEletronicConfig } from "src/pages/configPasswordEletronic";
import { PasswordEletronicControlAccountChange } from "src/pages/changePasswordEletronicControlAccount";
import CreateAccountTypeWallet from "src/pages/createAccountWalletInitials";
import { NewPassword } from "src/pages/newPassword";
import { RecoveryPassword } from "src/pages/forgot/RecoveryPassword";
import PDF from "src/pages/receipt/PDF";
import { TaxBackoffice } from "src/pages/backoffice/Tax";
import { AccountsWallet } from "src/pages/backoffice/AccountsWallet";
import { AccountsCA } from "src/pages/backoffice/ControlAccounts";
import { TransactionsWallet } from "src/pages/backoffice/TransactionsWallet";
import { Plans } from "src/pages/backoffice/Plans";
import { BilletPrint } from "src/pages/billet/details";
import Home from "src/pages/Home";
import { TransactionsPagbank } from "src/pages/backoffice/TransactionsPagbank";
import { TransactionsPagbankDetails } from "src/pages/backoffice/TransactionsPagbankDetails";
import { ConfigPersons } from "src/pages/backoffice/ConfigPersons";
import { ConfigTax } from "src/pages/backoffice/ConfigTax";
import { ExtractsPagbank } from "src/pages/backoffice/ExtractsPagbank";
import { FormConfigTax } from "src/pages/backoffice/FormConfigTax";
import { ReceiptPos } from "src/pages/receiptPos";
import { ExtractPdf } from "src/components/extractPdf";
import { MyTerminals } from "src/pages/myTerminals";
import { IncreasingLimits } from "src/pages/myLimits/increasingLimits";
import { ForwardInvoicePagbank } from "src/pages/backoffice/ForwardInvoicePagbank";
import { MyConfigLimits } from "src/pages/myLimits/configuringLimits";
import { MyLimits } from "src/pages/myLimits";
import RequestLimitsWalllet from "src/pages/backoffice/RequestLimits";
import { PixFavorites } from "src/pages/pixFavorites";
import { IncreasingLimitsMaster } from "src/pages/myLimits/master/increasingLimits";
import { MyConfigLimitsMaster } from "src/pages/myLimits/master/configuringLimits";
import { WalletTransactions } from "src/pages/backoffice/WalletTransactions";
import { PublicBilletPrint } from "src/pages/billet/public-billet";
import Support from "src/pages/support";
import How from "src/pages/support/How";
import SupportBackoffice from "src/pages/backoffice/Support";
import { GetTrasactionsMaster } from "src/pages/backoffice/GetTrasactionsMaster";
import { FormOperators } from "src/pages/backoffice/Operators/FormOperator";
import { ListOperators } from "src/pages/backoffice/Operators/ListOperators";
import { SignUpPfForBackoffice } from "src/pages/backoffice/SignUpForBackoffice";
import { SignUpPjForBackoffice } from "src/pages/backoffice/SignUpForBackoffice/signUpPj";
import { UploadDocumentsDelbank } from "src/pages/backoffice/SignUpForBackoffice/uploadDocumentsDelbank";
import { LostTransactionsOneHour } from "src/pages/backoffice/Logs/LostTransactions/oneHour";
import { LostTransactionsSixHours } from "src/pages/backoffice/Logs/LostTransactions/sixHours";
import { WalletDetailsTax } from "src/pages/backoffice/WalletDetailsTax";
import {
  WhyCreateWallet,
  ChooseAccountType,
  InsertAccountData,
  ChoosePlan,
  InsertAccountAddress,
  CreatePassword,
  AddingSecure,
  Identification,
  SendIdentificationPhoto,
  IdentificationAlert,
  SendDocument,
  Success,
  InsertAccountDataPj,
  InsertPartnerData,
  InsertPartnerAccountAddress,
  SendDocumentPJ,
  PjAlert,
  SendCCMEI,
  InvoicingSendAlert,
  SendInvoicingPDF,
} from "src/pages/newCreateAccountWallet";
import CountTransactions from "src/pages/backoffice/CountTransactions";

const BACKOFFICE_ROUTE = "/backoffice";
const LOGIN_ROUTE = "/login/backoffice";

function RequireAuth({ children }: { children: JSX.Element }) {
  let { account } = useAuth();
  let location = useLocation();

  if (!account) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    function clearStorage() {
      let session = sessionStorage.getItem("register");
      if (session == null) {
        localStorage.removeItem("@stricv2:token");
        localStorage.removeItem("@stricv2:account");
        localStorage.removeItem("@stricv2:user");
      }
      sessionStorage.setItem("register", `1`);
    }
    window.addEventListener("load", clearStorage);
  }, []);

  return children;
}

function RequireAuthBackoffice({ children }: { children: JSX.Element }) {
  useEffect(() => {
    function clearStorage() {
      let session = sessionStorage.getItem("registerbackoffice");
      console.log("teste");
      if (session == null) {
        console.log("qwdqwe");
        localStorage.removeItem("@backoffice:token");
        localStorage.removeItem("@backoffice:user");
        localStorage.removeItem("@backofficev2:token");
      }
      sessionStorage.setItem("registerbackoffice", `2`);
    }
    window.addEventListener("load", clearStorage);
  }, []);

  return children;
}

const Router: React.FC = () => {
  const { isAuthenticated } = useAuthBackoffice();

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="home" element={<Home />} /> */}
        <Route path="create-account-wallet" element={<WhyCreateWallet />} />
        <Route path="/create-account-wallet/choose-account-type" element={<ChooseAccountType />} />
        <Route path="/create-account-wallet/insert-data" element={<InsertAccountData />} />
        <Route path="/create-account-wallet/insert-data-pj" element={<InsertAccountDataPj />} />
        <Route path="/create-account-wallet/choose-plan" element={<ChoosePlan />} />
        <Route path="/create-account-wallet/insert-partner-data" element={<InsertPartnerData />} />
        <Route path="/create-account-wallet/insert-account-address" element={<InsertAccountAddress />} />
        <Route path="/create-account-wallet/insert-partner-account-address" element={<InsertPartnerAccountAddress />} />
        <Route path="/create-account-wallet/create-password" element={<CreatePassword />} />
        <Route path="/create-account-wallet/adding-secure" element={<AddingSecure />} />
        <Route path="/create-account-wallet/identification" element={<Identification />} />
        <Route path="/create-account-wallet/pj-alert" element={<PjAlert />} />
        <Route path="/create-account-wallet/send-identification-photo" element={<SendIdentificationPhoto />} />
        <Route path="/create-account-wallet/identification-alert" element={<IdentificationAlert />} />
        <Route path="/create-account-wallet/send-document" element={<SendDocument />} />
        <Route path="/create-account-wallet/send-document-pj" element={<SendDocumentPJ />} />
        <Route path="/create-account-wallet/success" element={<Success />} />
        <Route path="/create-account-wallet/send-ccmei" element={<SendCCMEI />} />
        <Route path="/create-account-wallet/invoicing-alert" element={<InvoicingSendAlert />} />
        <Route path="/create-account-wallet/send-invoicing-pdf" element={<SendInvoicingPDF />} />

        <Route path="signin" element={<SignIn />} />
        <Route path="token" element={<FillOutToken />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="new-password/:email/:hash" element={<NewPassword />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="recoveryPassword" element={<RecoveryPassword />} />

        <Route path="signin-token/:token" element={<SignInToken />} />
        <Route path="PDF" element={<PDF />} />
        <Route path="PDF/:id" element={<PDF />} />

        <Route path="requirements-create-account-wallet" element={<Requirements />} />
        <Route path="create-account-wallet-form" element={<Form />} />
        <Route path="requirements-create-account-wallet" element={<Requirements />} />

        <Route path="type-create-account-wallet" element={<NewCreateAccountType />} />

        <Route></Route>

        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="create-account" element={<Form />} />
          <Route path="requirements-create-account" element={<Requirements />} />
          <Route path="type-create-account" element={<NewCreateAccountType />} />
          <Route path="confirm-upgrade" element={<ConfirmUpgrade />} />
          <Route path="type-create-account/success" element={<UpgradeSuccess />} />

          <Route index element={<Dashboard />} />
          <Route path="tax" element={<Tax />} />

          <Route path="myLimits" element={<MyLimits />} />

          <Route path="configkeypasswordeletronic" element={<KeyPasswordEletronicConfig />} />
          <Route path="configcontrolaccountkeypasswordeletronic" element={<PasswordEletronicControlAccountChange />} />
          <Route path="myaccount" element={<MyAccount />} />
          <Route path="signout" element={<SignoutPage />} />
          {/* <Route path="extractlist" element={<Extract />} /> */}
          <Route path="extract" element={<Receipt />} />
          <Route path="extract-pos" element={<ReceiptPos />} />
          <Route path="my-terminals" element={<MyTerminals />} />
          <Route path="deposit">
            <Route index element={<Deposit />} />
            <Route path="tedordoc" element={<DepositInfoTedDoc />} />
            <Route path="invoices-list" element={<DepositInvoicesList />} />
            <Route path="pixmykeys" element={<DepositPixMyKeys />} />

            <Route path="invoice">
              <Route index element={<DepositNewInvoice />} />
              <Route path="confirm" element={<DepositNewInvoiceConfirm />} />
            </Route>
          </Route>
          <Route path="charge" element={<Charge />} />
          <Route path="transfer">
            <Route index element={<Transfer />} />
            <Route path="list" element={<TransferList />} />
            <Route path="subscribe" element={<TransferSubscribe />} />
            <Route path="confirm" element={<TransferConfirm />} />
          </Route>
          <Route path="/billet">
            <Route index element={<Billet />} />
            <Route path="create" element={<CreateBillet />} />
            <Route path="billets/:status" element={<Billets />} />
          </Route>

          <Route path="/pix">
            <Route index element={<PixOptions />} />
            <Route path="list" element={<PixList />} />
            <Route path="mykeys" element={<PixMyKeys />} />
            <Route path="mykeys/new" element={<PixNewKey />} />
            <Route path="transfer">
              <Route index element={<PixTransfer />} />
              <Route path="confirm" element={<PixConfirm />} />
            </Route>

            {/*  <Route path="configKeyControlAccount" element={<KeyControlAccountConfig />} /> */}
            <Route path="qrcodeStatic" element={<QrCodeStatic />} />
            <Route path="qrcodeDynamic" element={<QrCodeDynamic />} />
            <Route path="favorites" element={<PixFavorites />} />

            <Route path="copiaecola">
              <Route index element={<PixTransferCopiaECola />} />
              <Route path="confirm" element={<PixConfirmCopieEcola />} />
            </Route>
          </Route>

          <Route path="/limits">
            <Route path="increasing-limits" element={<IncreasingLimits />} />
            <Route path="config-limits" element={<MyConfigLimits />} />
            <Route path="master/increasing-limits" element={<IncreasingLimitsMaster />} />
            <Route path="master/config-limits" element={<MyConfigLimitsMaster />} />
          </Route>

          <Route path="/paybills">
            <Route index element={<PayBills />} />
            <Route path="invoice" element={<PayBillsPayInvoice />} />
            <Route path="info-invoice" element={<PayBillsInfoInvoice />} />
            <Route path="subscribe" element={<PayBillsSubscribe />} />
            <Route path="confirm-invoice" element={<PayBillsInvoiceConfirm />} />
            <Route path="schedule-payments" element={<PayBillsSchedulePayments />} />
          </Route>
          <Route path="/mobileRecharger">
            <Route index element={<MobileRecharger />} />
            <Route path="confirm" element={<RechargerConfirm />} />
          </Route>
          <Route path="gerenciar" element={<GerenciarContas />} />
          <Route path="adds-founds-wallet" element={<AddWalletFounds />} />

          <Route path="passwordKeyControlAccountChange" element={<PasswordControlAccountChange />} />

          <Route path="aprovacoes/:id" element={<Aprovacoes />} />

          <Route path="support">
            <Route index element={<Support />} />
            <Route path="/support/how" element={<How />} />
          </Route>
        </Route>

        <Route path="billets/print" element={<BilletPrint />} />
        <Route path="billets/print/:id" element={<PublicBilletPrint />} />
        <Route path="resume/extract" element={<ExtractPdf />} />

        <Route
          element={
            <GuardedRoute
              isRouteAccessible={!isAuthenticated}
              redirectRoute={isAuthenticated ? BACKOFFICE_ROUTE : LOGIN_ROUTE}
            />
          }
        >
          <Route path="/signin/backoffice" element={<BackofficeSignIn />} />
        </Route>

        <Route element={<GuardedRoute isRouteAccessible={isAuthenticated} redirectRoute={"/signin/backoffice"} />}>
          <Route
            path="/backoffice"
            element={
              <RequireAuthBackoffice>
                <BackofficeLayout />
              </RequireAuthBackoffice>
            }
          >
            <Route path="/backoffice/plans" element={<Plans />} />
            <Route path="/backoffice/transactions-pagbank" element={<TransactionsPagbank />} />
            <Route path="/backoffice/extracts-pagbank" element={<ExtractsPagbank />} />
            <Route path="/backoffice/signup-pf" element={<SignUpPfForBackoffice />} />

            <Route path="/backoffice/signup-pj" element={<SignUpPjForBackoffice />} />

            <Route path="/backoffice/forward-invoice-pagbank" element={<ForwardInvoicePagbank />} />
            <Route path="/backoffice/request-limits-users" element={<RequestLimitsWalllet />} />
            <Route path="/backoffice/transactions-pagbank/:id" element={<TransactionsPagbankDetails />} />
            <Route index element={<HomeDash />} />

            <Route path="/backoffice/accounts" element={<Accounts />} />
            <Route path="/backoffice/get-transaction-delbank" element={<GetTrasactionsMaster />} />
            <Route path="/backoffice/accounts/wallet" element={<AccountsWallet />} />
            <Route path="/backoffice/accounts/ca" element={<AccountsCA />} />
            <Route path="/backoffice/accounts/:id" element={<Member />} />
            <Route path="/backoffice/upload/delbank/:id" element={<UploadDocumentsDelbank />} />
            <Route path="/backoffice/accounts/wallet/:id" element={<Wallet />} />
            <Route path="/backoffice/accounts/wallet/:id/extract" element={<WalletTransactions />} />

            <Route path="/backoffice/accounts/graphic/:id" element={<Graphic />} />

            <Route path="/backoffice/accounts/:id/tax" element={<AccountsDetailsTax />} />
            <Route path="/backoffice/accounts/wallet/:id/tax" element={<WalletDetailsTax />} />

            <Route path="/backoffice/transfers" element={<Transfers />} />

            <Route path="/backoffice/financial" element={<AccountsDetailsTransactions />} />

            <Route path="/backoffice/control-account" element={<ControlAccountsTransactions />} />
            <Route path="/backoffice/wallet/transactions" element={<TransactionsWallet />} />

            <Route path="/backoffice/accounts/:id/extract" element={<ContaAccounts />} />

            <Route path="/backoffice/config/persons" element={<ConfigPersons />} />
            <Route path="/backoffice/config/tax" element={<ConfigTax />} />
            <Route path="/backoffice/get-count-transactions" element={<CountTransactions />} />
            <Route path="/backoffice/config/tax/form" element={<FormConfigTax />} />
            <Route path="/backoffice/config/tax/form/:id" element={<FormConfigTax />} />
            <Route path="/backoffice/config/operators/form" element={<FormOperators />} />
            <Route path="/backoffice/config/operators/list" element={<ListOperators />} />

            <Route path="/backoffice/logout" element={<Logout />} />
            <Route path="/backoffice/tax" element={<TaxBackoffice />} />
            <Route path="/backoffice/support" element={<SupportBackoffice />} />
          </Route>
        </Route>
        <Route path="/logs/transactions/one" element={<LostTransactionsOneHour />} />
        <Route path="/logs/transactions/six" element={<LostTransactionsSixHours />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Skeleton } from "../../components/skeleton";
import { useNotification } from "../../hooks/notification";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

import {
  Container,
  Content,
  StyledTextContainer,
  Table,
  TableWrapper,
  Title,
  TitleContainer,
} from "./styles";

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export const KeyPasswordEletronicConfig = () => {
  const { user, account } = useAuth();
  const token = localStorage.getItem("@stricv2:token");
  const navigate = useNavigate();
  const { setOpenNotification } = useNotification();

  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);

  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [isLocked1, setIsLocked1] = useState(true);
  const [isLocked2, setIsLocked2] = useState(true);

  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState<KeysProps[]>([]);
  const [displayText, setDisplayText] = useState('');





  // Definindo a função identificarTipoString
  function identificarTipoString(str: string): string {
    const senhaEletronica = account.security_eletronic;
    const pin = account.pin;

    
    const regex8Digits = /^\d{8}$/;
    const regex4Digits = /^\d{4}$/;
    
    

    if (regex8Digits.test(senhaEletronica) && (regex4Digits.test(pin)) ) {
      console.log("Senha eletrônica válida");

      return "Senha eletrônica válida";
    } else  {
      console.log("Senha eletrônica inválida ou pin incorreto");

      return "Senha eletrônica inválida ou pin incorreto";
    }
    
   }


//--------------------------------


//---------------------------------

  // Chamar a função e exibir o tipo de string identificado
  const tipoStringIdentificado = identificarTipoString(inputValue1);
  const tipoStringIdentificado2 = identificarTipoString(inputValue2);

  console.log(tipoStringIdentificado);
  console.log(tipoStringIdentificado2);



  useEffect(() => {
    const mockKeys = [
      {
        key: "key1",
        keyType: "type1",
        keyStatus: "status1",
        dateKeyCreated: "date1",
        dateKeyCreate: "dater1",
      },
      {
        key: "ke1",
        keyType: "typ1",
        keyStatus: "statu1",
        dateKeyCreated: "dat1",
        dateKeyCreate: "dater1",
      },
    ];
    setKeys(mockKeys);
    setLoading(false);
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleLock = (ref: React.RefObject<HTMLInputElement>, setIsLocked: React.Dispatch<React.SetStateAction<boolean>>) => {
    setIsLocked((prev) => !prev);
    if (ref.current) {
      ref.current.disabled = !isLocked1;
    }
    if (inputRef2.current) {
      inputRef2.current.disabled = !isLocked1; // Habilitar/desabilitar o input do PIN ao clicar em "Editar"
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setInputValue: React.Dispatch<React.SetStateAction<string>>, isLocked: boolean) => {
    if (!isLocked) {
      setInputValue(e.target.value);
    }
  };

  const containsDuplicateCharacters = (str: string): boolean => {
    for (let i = 0; i < str.length - 5; i++) {
      if (str.substring(i, i + 6) === str[i].repeat(6)) {
        return true;
      }
    }
    return false;
  };

  const inputWidth = `${Math.max(280, inputValue1.length * 8)}px`; // Adjust the multiplier as needed
  console.log('Valor atual do input:', inputValue1);


  const handleSave = async () => {
    console.log("Entrou em handleSave");

    if (!isLocked1 && inputValue1.length >= 8 && !containsDuplicateCharacters(inputValue1)) {
      try {
        const userId = account.id;
        console.log("userID:", userId);
        console.log("inputValue:", inputValue1);
        console.log("inputValue2:", inputValue2);

        console.log("Enviando requisição para o backend:", { newSecurityEletronic: inputValue1, newPin: inputValue2 });
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/update-security-password-eletronic/${userId}`,
          { newSecurityEletronic: inputValue1, newPin: inputValue2, accountId: userId },
          config
        );

        console.log("Resposta do backend:", response);

        if (response.status === 200) {
          console.log("Atualização bem-sucedida");
          setOpenNotification({
            type: "success",
            title: "Senha Eletrônica atualizada",
            notification: "Sua senha eletrônica foi atualizada com sucesso!",
          });
          setIsLocked1(true); // Defina o estado de volta para 'Editar'
          const identifiedType = identificarTipoString(account.pin as string && account.security_eletronic as string);
        }
      } catch (error) {
        console.error("Erro ao fazer a solicitação:", error);

        setOpenNotification({
          type: "error",
          title: "Erro ao atualizar a senha eletrônica",
          notification: "Houve um erro ao tentar atualizar sua senha eletrônica. Por favor, tente novamente mais tarde.",
        });
      }
    }
  };

  return (
    <Container>
      <Content>
        <TitleContainer>
          <Title>Crie ou altere a sua Senha Eletrônica por aqui!</Title>
        </TitleContainer>

        <StyledTextContainer>
          <span>
            Aproveite para configurar sua Senha Eletrônica. Insira sua nova senha eletrônica, composta por 8 dígitos numéricos não sequenciais, na primeira entrada do formulário e, na segunda entrada, digite seu PIN de 4 dígitos para validar a criação.
          </span>
          <span>
            Por que preciso de uma senha eletrônica? Simples: para trazer mais segurança e tranquilidade às suas transações diárias.
          </span>
        </StyledTextContainer>

        <Table>
          <TableWrapper>
            <Skeleton space={4} isActive={loading} repeat={3} />

            <table>
              {!loading && keys.length > 0 && (
                <thead>
                  <tr>
                    <th>Senha Eletrônica</th>
                    {user?.type !== "GRAPHIC" && <th>Validação via Pin</th>}
                    <th>Ações</th>
                    <th>Status</th>
                  </tr>
                </thead>
              )}

              <tbody>
                {keys.map((key, index) => (
                  <tr key={key.key}>
                    <td>
                      {index === 0 && (
                        <input
                          ref={inputRef1}
                          maxLength={8}
                          id="passwordInput"
                          type="text"
                          value={inputValue1}
                          onChange={(e) => handleInputChange(e, setInputValue1, isLocked1)}
                          placeholder="Inserir Senha Eletrônica:"
                          style={{
                            opacity: isLocked1 ? 0.5 : 1,
                            backgroundColor: isLocked1 ? "lightgrey" : "white",
                            border: isLocked1 ? "2px solid black" : "2px solid black",
                            borderRadius: "4px",
                            width: isLocked1 ? inputWidth : inputWidth,
                            padding: "4px",
                            paddingBottom: "2px",
                            textAlign: "center",
                            color: "black",
                          }}
                        />
                      )}
                    </td>

                    <td>
                      {index === 0 && (
                        <input
                          ref={inputRef2}
                          maxLength={4}
                          id="passwordInput2"
                          type="text"
                          value={inputValue2}
                          onChange={(e) => handleInputChange(e, setInputValue2, isLocked2)}
                          placeholder="Digite seu pin para validação"
                          style={{
                            opacity: isLocked2 ? 0.5 : 1,
                            backgroundColor: isLocked2 ? "lightgrey" : "white",
                            border: isLocked2 ? "2px solid black" : "2px solid black",
                            borderRadius: "4px",
                            width: isLocked2 ? inputWidth : inputWidth,
                            padding: "4px",
                            paddingBottom: "2px",
                            textAlign: "center",
                            color: "black",
                          }}
                          //disabled={!isLocked2} // Desabilitar o input do PIN se estiver bloqueado
                        />
                      )}
                    </td>

                    <td>
                      {index === 0 && (
                        <button
                          onClick={() => {
                            if (isLocked1) {
                              handleLock(inputRef1, setIsLocked1);
                              handleLock(inputRef2, setIsLocked2); // Adiciona esta linha para desbloquear o input do PIN ao clicar em "Editar"
                            } else {
                              handleSave();
                              setDisplayText(identificarTipoString(inputValue1));
                            }
                          }}
                          id="submitButton"
                          disabled={!isLocked1 && (inputValue1.length < 4 || containsDuplicateCharacters(inputValue1))}
                          style={{
                            width: "80px",
                            backgroundColor: isLocked1 ? "#3498db" : "#4caf50",
                            color: "#ffffff",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 20px",
                            paddingBottom: "2px",
                            transition: "background-color 0.3s",
                            cursor: !isLocked1 && (inputValue1.length < 4 || containsDuplicateCharacters(inputValue1)) ? "not-allowed" : "pointer",
                          }}
                        >
                          {isLocked1 ? "Editar" : "Enviar"}
                        </button>
                      )}
                    </td>

                    {index === 0 && (
                      <td
                        style={{
                          color:
                          inputValue1 !== "" && inputValue1.length === 8 && inputValue2 !== "" && isLocked1

                              ? "green"
                              : "red",
                              
                          visibility: inputValue1 !== "" ? "visible" : "hidden",
                        }}
                      >
                       {inputValue1 !== "" && inputValue1.length === 8 && inputValue2 !== "" && isLocked1
      ? "Senha Válida"
      : "Senha Inválida"}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </TableWrapper>
        </Table>
      </Content>
    </Container>
  );
};
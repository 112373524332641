import React from "react";
import { CardActionOption } from "../../components/cardActionOptionLimits";
import { useAuth } from "../../hooks/auth";

import {
  Container,
  Content,
  OptionsWrapper,
  // PixHelpMeIcon,
  ConfigLimitsIcon,
  UpIcon,
  PixMyKeysIcon,
} from "./styles";

export const MyLimits = () => {
  const { user } = useAuth();

  return (
    <Container>
      <Content>
        <OptionsWrapper>
          {user.role == "WALLET" && (
            <>
              <CardActionOption
                linkTo="/limits/increasing-limits"
                Icon={UpIcon}
                title={`Aumentar limite`}
              />
              <CardActionOption
                linkTo="/limits/config-limits"
                Icon={ConfigLimitsIcon}
                title={"Configurar limite disponivel"}
              />
            </>
          )}

          {user.role == "MASTER" && (
            <>
              <CardActionOption
                linkTo="/limits/master/increasing-limits"
                Icon={UpIcon}
                title={`Aumentar limite`}
              />
              <CardActionOption
                linkTo="/limits/master/config-limits"
                Icon={ConfigLimitsIcon}
                title={"Configurar limite disponivel"}
              />
            </>
          )}
        </OptionsWrapper>
      </Content>
    </Container>
  );
};

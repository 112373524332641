import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledDatePicker } from './styles';


interface InputDateExpirationQrCodeDynamicProps {
  value: string; // Defina o tipo de 'value' como string
  onChange: (value: string) => void;
}

const InputDateExpirationQrCodeDynamic: React.FC<InputDateExpirationQrCodeDynamicProps> = ({
  onChange,
}) => {   
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [linkValue, setLinkValue] = useState('');

  const handleDateChangeExpiration = (date: Date) => {
    const formattedDate = date.toISOString().split("T")[0];
    console.log("Formatted Date: ", formattedDate);
    setSelectedDate(date);
    onChange(formattedDate);
  };
  

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const inputWidth = `${Math.max(100, linkValue.length * 8)}px`; // Ajuste o multiplicador conforme necessário
  const width = linkValue === '' ? '300px' : inputWidth;

  return (
    <div>
<StyledDatePicker
      selected={selectedDate}
      onChange={handleDateChangeExpiration}
      dateFormat="dd-MM-yyyy"
      placeholderText="Data de Vencimento:"
    />

</div>

     

  );
};

export default InputDateExpirationQrCodeDynamic;

import jsPDF from "jspdf";
import { currencyFormat } from "../../../utils/currencyFormat";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import logoImage from "../../../../src/assets/clients/vilapaybank/logo/white.png";
import { api } from "../../../services/api";
import autoTable from "jspdf-autotable";
import { formatTableTransaction } from "src/utils/transactions";
import { ExtractItem } from "src/utils/extractDescription";
import groupTransactionsByDate from "src/utils/groupTransactionsByDate";
import toLocaleAmount from "src/utils/toLocaleAmount";
import moment from "moment";
import axios from "axios";

type TransactionMachine = {
  id: string;
  type: string;
  grossAmount: number;
  netAmount: number;
  taxTotal: number;
  isAwaitingTransfer: boolean;
  installments: number;
  machineId: string;
  machineSerial: string;
  transactionId: string;
  created_at: string;
  updated_at: string;
  status: number;
};

interface ExportPdfMachine {
  machineTransactions: TransactionMachine[];
  date: string;
  endDate: string;
  startDate: string;
  graphicAccountId: string;
}

export interface Transaction {
  id: string;
  credit: boolean;
  amount: number;
  type: string;
  typeId: string;
  description: string;
  status: string;
  balanceAfter: number;
  date: string;
}

interface IItems {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  type: string;
  credit: boolean;
  amount: number;
  [x: string]: any;
  transactionId: string;

  category: string;
  createdAt: string;
  transactionData: any;
}

const sourceOut = (pdf: any, data: any) => {
  const { name, document, bank_name } = data.detail.payer_payee?.bank_account;
  const { name: userName, document: userDocument } = data.user;

  pdf.setFontSize(18);
  pdf.text("Dados da conta debitada", 10, 80);

  pdf.setFontSize(12);
  pdf.text("Nome: " + userName, 10, 90);
  pdf.text("Documento: " + userDocument, 10, 100);
  pdf.setFontSize(12);
  pdf.text("intermediador: PIXWAVE BANK", 10, 110);
  pdf.setFontSize(18);
  pdf.text("Dados do favorecido", 10, 130);

  pdf.setFontSize(12);
  pdf.text("Nome: " + name, 10, 140);
  pdf.text("Documento: " + document, 10, 150);
  pdf.text("Instituição: " + bank_name, 10, 160);

  return pdf;
};

const sourceIn = (pdf: any, data: any) => {
  console.log(data);

  const { name, document, bank_name } = data.detail.payer_payee?.bank_account;
  const { name: userName, document: userDocument } = data.user;

  pdf.setFontSize(18);
  pdf.text("Dados do favorecido2", 10, 80);

  pdf.setFontSize(12);
  pdf.text("Nome: " + name, 10, 90);
  pdf.text("Documento: " + document, 10, 100);
  pdf.text("Instituição: " + bank_name, 10, 110);

  pdf.setFontSize(18);
  pdf.text("Dados da conta debitada", 10, 120);

  pdf.setFontSize(12);
  pdf.text("Nome: " + userName, 10, 130);
  pdf.text("Documento: " + userDocument, 10, 140);

  return pdf;
};

const getDirection = (transaction: Transaction): "in" | "out" => {
  // Verificar o tipo de direção da transação (entrada ou saída)
  if (transaction.credit) {
    return "in"; // Entrada (crédito)
  } else {
    return "out"; // Saída (débito)
  }
};

const dateFormat = (date: string | undefined): string => {
  // Formata a data para o formato desejado
  const formattedDate = format(new Date(date || ""), "dd/MM/yyyy", {
    locale: ptBR,
  });
  return formattedDate;
};

export async function ExportPDF_V2_BACKOFFICE(
  items: ExtractItem[],
  params: {
    startDate: string | Date;
    endDate: string | Date;
    date: string | Date;
    title: string;
    isWallet: boolean;
  }
) {
  try {
    const token = window.localStorage.getItem("@backoffice:token");
    const data = groupTransactionsByDate(items);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/backoffice/export/pdf`,
      {
        data,
        startDate: params.startDate,
        endDate: params.endDate,
        date: params.date,
        isWallet: params.isWallet,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response.data);
    const base64 = response.data.pdfBase64;

    if (!base64) {
      throw new Error("Base64 não encontrado");
    }
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.pdf";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
  }
}

export async function ExportPDF_V2(
  items: ExtractItem[],
  params: any,
  backoffice?: boolean,
  account_id?: string,
  graphicAccount_Id?: string,
  user_id?: string
) {
  const token = window.localStorage.getItem(!backoffice ? "@stricv2:token" : "@backoffice:token");
  const data = groupTransactionsByDate(items);

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/extract/`,
    {
      data,
      startDate: params.startDate,
      endDate: params.endDate,
      date: params.date,
      account_id,
      graphicAccount_Id,
      user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const base64 = response.data.pdfBase64;

  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "export.pdf";
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
}

export async function ExportPDF_MACHINE({
  date,
  endDate,
  graphicAccountId,
  machineTransactions,
  startDate,
}: ExportPdfMachine) {
  const token = window.localStorage.getItem("@backoffice:token");

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/backoffice/export/machine`,
    {
      date,
      endDate,
      graphicAccountId,
      machineTransactions,
      startDate,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const base64 = response.data.pdfBase64;

  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "export.pdf";
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
}

export async function ExportPDF_V2_DELBANK(items: ExtractItem[], params: any, user_id: string, backoffice: boolean) {
  const token = window.localStorage.getItem(!backoffice ? "@stricv2:token" : "@backoffice:token");

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/extract/delbank`,
    {
      data: items,
      startDate: params.startDate,
      endDate: params.endDate,
      date: params.date,
      user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const base64 = response.data.pdfBase64;

  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "export.pdf";
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
}

export default async function handleExportPDF(items: ExtractItem[], params: any, backoffice?: boolean) {
  const token = window.localStorage.getItem(!backoffice ? "@stricv2:token" : "@backoffice:token");
  const data = groupTransactionsByDate(items);

  const response = await axios.get(`${process.env.REACT_APP_API_URL}/transactions/daily-final-balances`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dailyBalances = response.data[0]?.dailyBalances?.map((balance: { amount: number; createdAt: string }) => {
    return {
      amount: balance.amount,
      createdAt: moment(balance.createdAt.split("T")[0]).format("DD/MM/YYYY"),
    };
  });

  const update_date = data.map((item) => {
    const matchedDaily = dailyBalances?.find((daily: { createdAt: string }) => daily.createdAt === item.date);

    const amount = matchedDaily ? matchedDaily.amount : 0;

    return {
      ...item,
      dayly_amount: amount,
    };
  });

  const { pdf, usableWidth } = createPDF();

  const imgWidth = 100;
  const imgHeight = 28;
  const imgY = 10;
  const imgX = (usableWidth - imgWidth) / 2;

  pdf.addImage(logoImage, "PNG", imgX, imgY, imgWidth, imgHeight);

  pdf.setFontSize(16);
  if (params.data) {
    pdf.text("Nome: " + params.data.name, 15, imgY + 30);
    pdf.text("Documento: " + formatCpfCnpj(params.data.document), 15, imgY + 40);
    pdf.setFontSize(12);
    pdf.text(`${dateFormat(params.startDate)} até ${dateFormat(params.endDate)}`, 15, imgY + 50);

    autoTable(pdf, {
      html: "#table-extract",
      margin: { top: 60 },
    });
  } else {
    console.log(params);
    pdf.text("Extrato: " + params.title, 15, imgY + 30);
    pdf.setFontSize(12);
    pdf.text(`${dateFormat(params.startDate)} até ${dateFormat(params.endDate)}`, 15, imgY + 40);
    if (params.currentBalance) {
      pdf.text(`Saldo atual:  ${currencyFormat(params.currentBalance)}`, 15, imgY + 50);
    }
    autoTable(pdf, {
      html: "#table-extract",
      margin: { top: 55 },
    });
  }

  const transactions: any[] = [];
  for (const groupedTransactions of update_date) {
    transactions.push([
      `Entradas: \nR$ ${toLocaleAmount(groupedTransactions.in)}`,
      `Saídas: \nR$ ${toLocaleAmount(groupedTransactions.out)}`,
      `Saldo do dia: ${moment().format("DD/MM/YYYY")}`,
      "",
      `Total: \nR$ ${toLocaleAmount(groupedTransactions.dayly_amount)}`,
    ]);

    groupedTransactions.transactions.map((item) => {
      if (item.status == "done") {
        const { created_at, type, data, description, amount, title, status } = formatTableTransaction(item);
        const formatedData = data.length > 30 ? `${data.substring(0, 30)}...` : data;
        transactions.push([
          created_at,
          type,
          title,
          formatedData,
          `${item.direction == "in" ? "+" : "-"} ${currencyFormat(Number(item.amount))}`,
        ]);
      }
    });
  }

  autoTable(pdf, {
    head: [["Data", "Tipo", "Transação", "Beneficiário / Pagador", "Valor"]],
    body: transactions,
    headStyles: {
      fillColor: [87, 214, 244], // Cor de fundo do cabeçalho
      textColor: 255, // Cor do texto do cabeçalho
      fontSize: 12, // Tamanho da fonte do cabeçalho
      cellPadding: 2, // Espaçamento interno das células do cabeçalho
    },
    styles: {
      fontSize: 10, // Tamanho da fonte do corpo da tabela
    },
  });
  pdf.save("Extrato.pdf");
}

export async function handleGeneratePDF(item: ExtractItem | IItems, backoffice?: boolean) {
  const token = window.localStorage.getItem(!backoffice ? "@stricv2:token" : "@backoffice:token");
  const response = await api.get(`/transactions/${item.id}/all`, {
    headers: { Authorization: "Bearer " + token },
  });

  const transaction = {
    ...response.data.transaction.data,
    user: response.data.transaction.user,
  };

  let pdf = new jsPDF("p", "mm", "a4");

  const marginLeft = 10;
  const marginTop = 10;
  const marginRight = 10;
  const marginBottom = 10;

  const usableWidth = pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
  const usableHeight = pdf.internal.pageSize.getHeight() - marginTop - marginBottom;

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(22);
  pdf.setTextColor(0, 0, 0); // Definindo a cor do texto como preto

  // Adicione a imagem no início do PDF
  const imgWidth = 100; // Largura da imagem
  const imgHeight = 20; // Altura da imagem
  const imgY = marginTop;
  const imgX = (usableWidth - imgWidth) / 2 + marginLeft;

  pdf.addImage(logoImage, "PNG", imgX, imgY, imgWidth, imgHeight);

  const formattedDate = dateFormat(item.created_at || item.created_at || "");
  const formattedAmount = currencyFormat(item.amount);

  pdf.setFontSize(12);
  pdf.text(`Data: ${formattedDate}`, 10, 50);
  pdf.text("Valor:", 10, 60);
  pdf.text(`${formattedAmount}`, 10, 70);

  const tipoPagamentoTextWidth = pdf.getStringUnitWidth("Tipo de pagamento:") * 12;
  const pageWidthTypePayment = pdf.internal.pageSize.getWidth();
  const tipoPagamentoX = pageWidthTypePayment - tipoPagamentoTextWidth - 10;
  pdf.text("Tipo de pagamento:", tipoPagamentoX, 60);
  pdf.text(`${transaction.title}`, tipoPagamentoX, 70);

  if (transaction.description !== "Reembolso Recebido") {
    const direction = getDirection(transaction);
    if (direction === "in") {
      sourceIn(pdf, transaction);
    } else if (direction === "out") {
      sourceOut(pdf, transaction);
    }
  }
  pdf.setFontSize(12);
  pdf.text(`CÓDIGO DE AUTENTICAÇÃO`, 80, 180);
  pdf.text(transaction.id, 70, 190);
  pdf.save("Extrato.pdf");
}

function createPDF() {
  const pdf = new jsPDF("p", "mm", "a4");

  const marginLeft = 10;
  const marginTop = 10;
  const marginRight = 10;
  const marginBottom = 10;

  const usableWidth = pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
  const usableHeight = pdf.internal.pageSize.getHeight() - marginTop - marginBottom;

  return {
    pdf,
    usableWidth,
    usableHeight,
  };
}

function formatCpfCnpj(number: string): string {
  if (number.length === 11) {
    // CPF
    return `${number.substr(0, 3)}.${number.substr(3, 3)}.${number.substr(6, 3)}-${number.substr(9)}`;
  } else if (number.length === 14) {
    // CNPJ
    return `${number.substr(0, 2)}.${number.substr(2, 3)}.${number.substr(5, 3)}/${number.substr(8, 4)}-${number.substr(
      12
    )}`;
  } else {
    return number;
  }
}

import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  ContentWrapper,
  Text,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  WrapperButton,
  WrapperForm,
  Button,
  WrapperDivButton,
  WrapperButtonBack,
} from "./styles";

import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import * as Yup from "yup";

import { api, parseError } from "../../services/api";
import { ExclamationIcon } from "@heroicons/react/solid";
import { useClient } from "../../hooks/client";
import axios from "axios";
import { useNotification } from "../../hooks/notification";
import { useAuth } from "../../hooks/auth";
import { service, serviceError } from "../../services/ajio";
import { validateCNPJ, validateCPF, validateDate, validateEmail } from "../../utils/validations";
import { FormInput, FormRadio, FormSelect } from "../../components/LPFormButton2";
import { FormInputSelect } from "./components/formInputSelectIndiviuals";
import { Plan } from "../createAccountWalletInitials/account_wallet_create";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { HelpCircle } from "lucide-react";
import Modal from "src/components/Modal";
import { convertToBase64 } from "src/utils/convertToBase64";

type IFormInfo = {
  type: "company";
  name: string;
  document: string;
  phone: string;
  // email: string;
  password: string;
  password2: string;
  pin: string;
  securityEletronic: string;
  legalName: string;
  legalNature: string;
  establishmentDate: string;
  establishmentType: string;
  mainCnae: string;
  revenue: string;
  amountMonthlyInvoicing: string;
  amountShareCapital: string;
  amountPatrimony: string;
  address: {
    street: string;
    number: string;
    extra: string;
    neighborhood: string;
    city: string;
    type: string;
    state: string; //only the UF code
    postalCode: string; //only numbers
  };
  partner: {
    isPep: boolean;
    name: string;
    document: string;
    phone: string;
    email: string;
    birthDate: string;
    motherName: string;
    address: {
      street: string;
      number: string;
      extra: string;
      type: string;
      neighborhood: string;
      city: string;
      state: string; //only the UF code
      postalCode: string; //only numbers
    };
  };
};

type IFormError = {
  type?: string;
  name?: string;
  document?: string;
  phone?: string;
  email?: string;
  password?: string;
  password2?: string;
  pin?: string;
  securityEletronic?: string;
  legalName?: string;
  legalNature?: string;
  establishmentDate?: string;
  establishmentType?: string;
  mainCnae?: string;
  revenue?: string;
  amountMonthlyInvoicing?: string;
  amountShareCapital?: string;
  amountPatrimony?: string;
  address?: {
    street?: string;
    number?: string;
    extra?: string;
    neighborhood?: string;
    city?: string;
    state?: string; //only the UF code
    postalCode?: string; //only numbers
  };
  partner?: {
    isPep?: string;
    name?: string;
    document?: string;
    phone?: string;
    email?: string;
    birthDate?: string;
    motherName?: string;
    address?: {
      street?: string;
      number?: string;
      extra?: string;
      neighborhood?: string;
      city?: string;
      state?: string; //only the UF code
      postalCode?: string; //only numbers
    };
  };
};

interface CompanieProps {
  status: string | null;
}

const getLegalNature = (establishmentType: string) => {
  if (establishmentType === "MEI") return "2135";
  if (establishmentType === "LTDA") return "2062";
  if (establishmentType === "SA") return "2046";
  if (establishmentType === "EIRELI") return "2305";
  if (establishmentType === "COOP") return "2011";

  return "2028";
};

export default function Companies({ status }: CompanieProps) {
  const { signOut, signIn, user } = useAuth();
  const location = useLocation();
  const { client } = useClient();
  const { account, refreshAccount } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { setOpenNotification } = useNotification();

  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [errorMessage, setErrorMessage] = useState(""); // general form erros
  const [typeDoc, setTypeDoc] = useState("SELFIE");
  const [stateChanges, setStateChanges] = useState<string[]>([]);
  const [franchiseId, setFranchiseId] = useState<string | undefined>();
  const [accountType, setAccountType] = useState<string | undefined>();

  const [plans, setPlans] = useState<Plan[]>([]);
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");

  const [documentBase64, setDocumentBase64] = useState<string>();

  const [step, setStep] = useState(() => {
    const statusStorage = status || localStorage.getItem("@stricv2:status");

    if (account.id_master_user && !account.status_master_user) {
      return 7;
    }

    if (statusStorage) {
      if (statusStorage === "WAITING_TOKEN") {
        return 2;
      }

      if (statusStorage === "WAITING_FLOW") {
        return 3;
      }

      if (statusStorage === "WAITING_PARTNERS") {
        return 3;
      }

      if (statusStorage === "WAITING_ADDRESS") {
        return 5;
      }

      if (statusStorage === "waiting_documents") {
        return 7;
      }

      if (statusStorage === "under_review" || "PENDING_DOCUMENTATION") {
        return 99;
      }
    }

    return 1;
  });

  const validationSchema = Yup.object().shape({
    partner: Yup.object().shape({
      birthDate: Yup.date()
        .nullable()
        .required("A data de nascimento é obrigatória")
        .max(new Date(), "A data de nascimento não pode ser no futuro")
        .min(new Date("1920-01-01"), "A data de nascimento não pode ser anterior a 1920-01-01")
        .test("is-valid-date", "Data de nascimento inválida", function (value) {
          if (!value) return true; // Permite que o campo seja nulo (caso opcional)
          const date = new Date(value);
          return !isNaN(date.getTime());
        }),
    }),
  });

  const [sucess, setSuccess] = useState(() => {
    const statusStorage = localStorage.getItem("@stricv2:status");

    if (statusStorage === "under_review" || statusStorage === "under_review") {
      return true;
    }

    return false;
  });

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem("@stricv2:token");
    if (tokenStorage) {
      return tokenStorage;
    }
    return "";
  });

  //--------------
  useEffect(() => {
    setStateChanges((prev) => [...prev, typeDoc]);
    setLoading(false);
  }, [typeDoc]);

  // console.log("Mudanças de estado:", stateChanges);
  // stateChanges.map((change, index) => {
  //   console.log(`Mudança ${index + 1}: ${change}`);
  // });
  //----------------------------

  const navigation = useNavigate();
  // const { setOpenNotification } = useNotification();
  const handleGoBack = async () => {
    signOut();
    navigation("/signin");
  };

  console.log(error);

  const fetchPlans = async () => {
    try {
      const response = await api.get("/graphic/plans", {
        params: {
          role: "MEMBERPJ",
        },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("FRANCHISE_ID modificado: ", franchiseId);
  }, [franchiseId]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const accountTypeQuery = queryParameters.get("type");
    const franchiseIdQuery = queryParameters.get("service_code");

    franchiseIdQuery && setFranchiseId(franchiseIdQuery);
    accountTypeQuery && setAccountType(accountTypeQuery);

    if (
      !location.search ||
      location.search === null ||
      location.search === "" ||
      !accountTypeQuery ||
      !franchiseIdQuery
    ) {
      navigation("/signin");
    }
  }, [location.search, navigation]);

  const handleOneSubmit = async () => {
    setError({} as IFormError);

    // CNPJ xxx.xxx.xxx-xx
    if (formInfo.document.length < 18) {
      setError({ document: "CNPJ Inválido." });
      return;
    }

    if (!validateCNPJ(formInfo.document.replace(/[/,.,-\s]/g, ""))) {
      setError({ document: "CNPJ Inválido." });
      return;
    }

    // Email
    // if (formInfo.email.length < 6) {
    //   setError({ email: "Preencha o email." });
    //   return;
    // }

    // if (!validateEmail(formInfo.email)) {
    //   setError({ email: "Email Inválido." });
    //   return;
    // }

    // Phone (xx) xxxxx-xxxx
    if (formInfo.phone.length < 14) {
      setError({ phone: "Telefone Inválido." });
      return;
    }

    setLoading(true);

    try {
      setLoading(false);
      setStep(2);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      const errorAll = err as any;
      setLoading(false);
      if (error.code === "document.already" || error.code === "email.already") {
        setOpenNotification({
          title: "Alerta",
          notification:
            "Nós já nos conhecemos!\n\nIdentificamos que você já possui um vínculo com a gente.\n\nPara continuar, utilize sua senha e comece a desfrutar de todas as vantangens.",
          type: "warning",
        });
      } else {
        setOpenNotification({
          title: "Erro",
          notification: error.friend || errorAll?.response?.data?.message?.[0],
          type: "error",
        });
      }
    }
  };

  const handleTwoSubmitStepPJ = async () => {
    setError({} as IFormError);

    if (formInfo.establishmentDate.length < 10) {
      setError({ establishmentDate: "Preencha a data de fundação completa." });
      return;
    }

    if (!validateDate(formInfo.establishmentDate)) {
      setError({ establishmentDate: "Data inválida." });
      return;
    }

    if (formInfo.mainCnae.length < 7) {
      setError({ mainCnae: "Preencha o CNAE completo." });
      return;
    }

    if (formInfo.revenue === "R$ 0,00" || formInfo.revenue === undefined) {
      setError({ revenue: "Preencha sua receita." });
      return;
    }
    if (formInfo.amountMonthlyInvoicing == undefined || formInfo.amountMonthlyInvoicing == "R$ 0,000") {
      setError({ amountMonthlyInvoicing: "Faturamento inválido" });
      return;
    }
    if (formInfo.amountPatrimony == undefined || formInfo.amountPatrimony == "R$ 0,00") {
      setError({ amountPatrimony: "Patrimonio inválido" });
      return;
    }
    if (formInfo.amountShareCapital == undefined || formInfo.amountShareCapital == "R$ 0,00") {
      setError({ amountShareCapital: "Capital social inválido" });
      return;
    }
    if (formInfo.establishmentType === "DEMAIS" || formInfo.establishmentType === "SA") {
      if (!formInfo.legalNature) {
        setError({ legalNature: "Selecione sua natureza jurídica." });
        return;
      }
    }

    setLoading(true);
    try {
      setLoading(false);
      setStep(3);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }
  };

  async function handleCep(cep?: string, isPartener?: boolean) {
    setError({} as IFormError);

    const _cep = cep ? cep : isPartener ? formInfo.partner.address.postalCode : formInfo.address.postalCode;
    // ? cep
    //   ? cep
    //   : formInfo.partner.address.postalCode
    // : formInfo.address.postalCode;

    if (_cep.length < 9) {
      setError({
        ...error,
        address: {
          ...error.address,
          postalCode: "Preencha o CEP completo.",
        },
      });
      return;
    }

    const rawPostalCode = _cep.replace(/-/g, "");
    if (rawPostalCode.length >= 8) {
      setLoading(true);

      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${rawPostalCode}/json/`);
        console.log(data);
        if (data) {
          if (isPartener) {
            setFormInfo({
              ...formInfo,
              partner: {
                ...formInfo.partner,
                address: {
                  ...formInfo.address,
                  street: data.logradouro,
                  neighborhood: data.bairro,
                  city: data.localidade,
                  state: data.uf,
                  postalCode: data.cep,
                },
              },
            });
          } else {
            setFormInfo({
              ...formInfo,
              address: {
                ...formInfo.address,
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf,
                postalCode: data.cep,
              },
            });
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setStep(4);
      }
    }
  }

  async function handleAddress() {
    setError({} as IFormError);

    //if (formInfo.address.postalCode.length < 8) {
    //// setError({
    //    ...error,

    //   address: {
    //     ...error.address,
    //     postalCode: "Preencha o CEP completo.",
    //   },
    // });
    //  return;
    // }

    if (formInfo.address.state.length !== 2) {
      setError({
        ...error,
        address: {
          ...error.address,
          state: "Estado Inválido.",
        },
      });
      return;
    }

    if (formInfo.address.state.length !== 2) {
      setError({
        ...error,
        address: { ...error.address, state: "Estado Inválido." },
      });
      return;
    }

    setLoading(true);
    try {
      setLoading(false);
      setStep(4);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  async function handlePartner() {
    setError({} as IFormError);
    setErrorMessage("");

    // Name and Mother Name Validations
    if (!formInfo.partner?.name?.includes(" ") || formInfo.partner?.name?.length < 5) {
      setError({ partner: { name: "Preencha o nome completo." } });
      setErrorMessage("Preencha o nome completo do sócio.");
      return;
    }
    if (!formInfo.partner?.motherName?.includes(" ") || formInfo.partner?.motherName?.length < 5) {
      setError({ partner: { motherName: "Preencha o nome da mãe completo." } });
      setErrorMessage("Preencha o nome completo da mãe do sócio.");
      return;
    }

    // CPF xxx.xxx.xxx-xx
    if (formInfo.partner?.document?.length < 14) {
      setError({ partner: { motherName: "CPF Inválido." } });
      setErrorMessage("CPF Inválido.");
      return;
    }

    if (!validateCPF(formInfo.partner?.document?.replace(/[/,.,-\s]/g, ""))) {
      setError({ partner: { motherName: "CPF Inválido." } });
      setErrorMessage("CPF Inválido.");
      return;
    }
    // BirthDate xxxx/xx/xx
    if (formInfo.partner?.birthDate?.length < 10) {
      setError({ partner: { birthDate: "Data de Nascimento incompleta." } });
      setErrorMessage("Data de Nascimento incompleta.");
      return;
    }

    if (!validateDate(formInfo.partner?.birthDate)) {
      setError({ partner: { birthDate: "Data de Nascimento inválida." } });
      setErrorMessage("Data de Nascimento inválida.");
      return;
    }

    if (formInfo.partner?.isPep === undefined) {
      setError({ partner: { isPep: "Selecione se o sócio é ou não PEP." } });
      setErrorMessage("Selecione se o sócio é ou não PEP.");
      return;
    }

    setLoading(true);
    try {
      setLoading(false);
      setStep(5);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  const handlePartnerAddress = () => {
    setError({} as IFormError);
    setErrorMessage("");

    if (formInfo?.partner?.address?.postalCode.length < 8) {
      setError({ address: { postalCode: "Preencha o CEP completo." } });
      setErrorMessage("Preencha o CEP completo.");
      return;
    }

    if (formInfo?.partner?.address?.state.length !== 2) {
      setError({ address: { state: "Estado Inválido." } });
      setErrorMessage("Estado Inválido");
      return;
    }

    try {
      setLoading(false);
      setStep(6);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  };

  function transformCurrency(currency: string) {
    const result = Number(currency?.replace(/[/,R$ .,-\s]/g, "")) / 100;

    return result;
  }

  async function handlePasswords() {
    setError({} as IFormError);

    // // Password Validations
    const pass = formInfo.password;
    const pass2 = formInfo.password2;
    if (pass.length < 8) {
      setError({ password: "A senha deve ter pelo menos 8 dígitos." });
      return;
    }

    if (!/[A-Z]/.test(pass) || !/[a-z]/.test(pass) || !/\d/.test(pass)) {
      setError({
        password: "A senha deve ter pelo uma letra maiúscula, uma letra minúscula e um número.",
      });
      return;
    }

    if (pass !== pass2) {
      setError({ password2: "As senhas não estão iguais." });
      return;
    }

    if (formInfo.pin.length !== 4) {
      setError({ ...error, pin: "O Security Digit deve ter 4 dígitos." });
      return;
    }

    if (
      [
        "1234",
        "2345",
        "3456",
        "4567",
        "5678",
        "6789",
        "4321",
        "5432",
        "6543",
        "7654",
        "8765",
        "9876",
        "3210",
        "0123",
      ].includes(formInfo.pin)
    ) {
      setError({
        ...error,
        pin: "O Security Digit não pode ser composto de números consecutivos.",
      });
      return;
    }

    if (formInfo.securityEletronic.length !== 8) {
      setError({
        ...error,
        securityEletronic: "A Senha Eletrônica deve ter 8 dígitos.",
      });
      return;
    }

    if (
      [
        "1234",
        "2345",
        "3456",
        "4567",
        "5678",
        "6789",
        "4321",
        "5432",
        "6543",
        "7654",
        "8765",
        "9876",
        "3210",
        "0123",
      ].includes(formInfo.securityEletronic)
    ) {
      setError({
        ...error,
        securityEletronic: "A Senha Eletrônica não pode ser composto de números consecutivos.",
      });
      return;
    }

    setLoading(true);
    function generateRandomUUID() {
      const hexChars = "0123456789abcdef";
      let uuid = "";

      for (let i = 0; i < 36; i++) {
        if (i === 8 || i === 13 || i === 18 || i === 23) {
          uuid += "-";
        } else if (i === 14) {
          uuid += "4";
        } else if (i === 19) {
          uuid += hexChars[(Math.random() * 4) | 8];
        } else {
          uuid += hexChars[Math.floor(Math.random() * 16)];
        }
      }

      return uuid;
    }
    console.log(formInfo.address?.state.toLowerCase(), "jdbasdhasbdha");
    try {
      const raw = {
        password: formInfo.password,
        security_eletronic: formInfo.securityEletronic,
        document: formInfo.document?.replace(/[/,.,-\s]/g, ""),
        // businessEmail: formInfo.email,
        corporateName: formInfo.legalName,
        fantasyName: formInfo.name,
        mother_name: formInfo.partner.motherName,
        activityCnae: formInfo.mainCnae,
        openingDate: formInfo.establishmentDate?.split("/").reverse().join("-"),
        isPoliticallyExposedPerson: false,
        amountMonthlyInvoicing: transformCurrency(formInfo.amountMonthlyInvoicing),
        amountShareCapital: transformCurrency(formInfo.amountShareCapital),
        amountPatrimony: transformCurrency(formInfo.amountPatrimony),
        planId: selectedPlanId,
        address: {
          state: formInfo.address?.state.toLowerCase(),
          cityName: formInfo.address?.city,
          isConfirmed: true,
          type: formInfo.address.type,
          publicPlace: formInfo.address?.street,
          number: formInfo.address?.number,
          zipCode: formInfo.address?.postalCode?.replace(/-/g, ""),
          neighborhood: formInfo.address?.neighborhood,
          complement: formInfo.address?.extra || "",
        },
        phone: {
          ddd: formInfo.phone?.replace(/\D/g, "").substring(0, 2),
          number: formInfo.phone?.replace(/^\(\d{2}\)/, "").replace(/\D/g, ""),
          type: "CELL",
        },
        partners: [
          {
            is_pep: formInfo.partner?.isPep,
            name: formInfo.partner?.name,
            document: formInfo.partner?.document?.replace(/[/,.,-\s]/g, ""),
            // phone: formInfo.phone?.replace(/[-,\s,(,)]/g, ""), //same as company
            // email: formInfo.email, //same as company
            birthDate: formInfo.partner?.birthDate?.split("/").reverse().join("-"),
            mother_name: formInfo.partner?.motherName,
            phone: {
              ddd: formInfo.phone?.replace(/\D/g, "").substring(0, 2),
              number: formInfo.phone?.replace(/^\(\d{2}\)/, "").replace(/\D/g, ""),
              type: "CELL",
            },
            address: {
              zipCode: formInfo.partner?.address?.postalCode?.replace(/-/g, ""),
              publicPlace: formInfo.partner?.address?.street,
              number: formInfo.partner?.address?.number,
              complement: formInfo.partner?.address?.extra || "",
              neighborhood: formInfo.partner?.address?.neighborhood,
              cityName: formInfo.partner?.address?.city,
              state: formInfo.partner?.address?.state,
              isConfirmed: true,
              type: formInfo.address.type,
            },
          },
        ],
      };

      await service.post("users/companies", raw, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      refreshAccount(); // pra tazer o id_user_master e o type dele (NATURAL | LEGAL)

      setLoading(false);
      setStep(7);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
      // @ts-ignore
      console.log(err);
    }
  }

  const handleFileSelect = async (event: any) => {
    // console.log("Evento de seleção de arquivo:", event);
    setErrorMessage("");

    const file = event.target?.files[0];

    const fileBase64 = await convertToBase64(file);

    setLoading(true);
    try {
      // let parseType = '';
      // if (typeDoc === 'SELFIE') {
      //   parseType = 'documentFace';
      // }
      // else if (typeDoc === 'CNH_FRONT' || typeDoc === 'RG_FRONT') {
      //   parseType = 'documentFront';
      // }
      // else if (typeDoc === 'RG_BACK' || typeDoc === 'CNH_BACK') {
      //   parseType = 'documentBack';
      // } else {
      //   parseType = 'documentBack';
      // }

      console.log("Enviando arquivo...");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await api.post(
        "/users/documents",
        {
          documentType: typeDoc,
          base64: fileBase64,
          id_master_user: account.id_master_user,
          activeMasterUser: typeDoc == "PERSON_LEGAL_REVENUES",
        },
        config
      );
      console.log("Resposta recebida:", data); // Este console.log mostrará a resposta após a conclusão da solicitação POST

      if (typeDoc === "SELFIE") {
        return setTypeDoc("DOCS");
      }

      if (typeDoc === "CNH_FRONT") {
        return setTypeDoc("CNH_BACK");
      }

      if (typeDoc === "RG_FRONT") {
        return setTypeDoc("RG_BACK");
      }

      let companyType = user?.company_type || "MEI"; // ta vindo nulo pq nao tem user
      console.log(companyType);

      if (typeDoc === "RG_BACK" || typeDoc === "CNH_BACK") {
        return setTypeDoc("PERSON_LEGAL_SOCIAL_CONTRACT");
        // if (companyType === "MEI") return setTypeDoc("CCMEI");

        // if (
        //   companyType === "EI" ||
        //   companyType === "EIRELI" ||
        //   companyType === "LTDA" ||
        //   companyType === "SA"
        // ) {
        //   return setTypeDoc("social_contract");
        // }
      }

      if (typeDoc === "PERSON_LEGAL_SOCIAL_CONTRACT") {
        return setTypeDoc("PERSON_LEGAL_REVENUES");
      }

      if (data?.status && data.status === "PENDING_DOCUMENTATION") {
        // mensagem de aguardar até 1 dia útil.
        setStep(99);
        setSuccess(true);
        try {
          // await signIn({
          //   document: formInfo.document,
          //   password: formInfo.password,
          // });
          setTimeout(() => {
            navigation("/");
          }, 5000);
        } catch (error) {
          setTimeout(() => {
            navigation("/signin");
          }, 5000);
        }
      }

      if (data?.status && data.status === "denied") {
        // mensagem de não foi possível abrir a conta.
        setStep(66);
        setSuccess(true);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);

      const error = parseError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }
  };

  console.log(error);

  const mapAdressTypeToTranslation = (type: string): string => {
    const adressTypeTranslationMap: { [key: string]: string } = {
      Residencial: "RESIDECIAL",
      Comercial: "COMMERCIAL",
      // Adicione mais mapeamentos conforme necessário
    };
    return adressTypeTranslationMap[type] || type;
  };

  interface PlanMapping {
    [key: string]: string;
  }

  const [plansTranslationMap, setPlansTranslationMap] = useState<PlanMapping>({});

  useEffect(() => {
    const fetchPlansTranslations = async () => {
      try {
        const response = await axios.get("/chose-plans");
        const planMappings: PlanMapping = response.data;
        setPlansTranslationMap(planMappings);
      } catch (error) {
        console.error("Erro ao buscar mapeamento de planos:", error);
      }
    };
    fetchPlans();
    fetchPlansTranslations();
  }, []);

  const mapplanToTranslation = (plan: string): string => {
    return plansTranslationMap[plan] || plan;
  };

  const inputRefs: React.RefObject<HTMLInputElement>[] = [];

  // Crie as referências usando React.createRef
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const password2InputRef = useRef<HTMLInputElement>(null);
  const pinInputRef = useRef<HTMLInputElement>(null);
  const SecurityEletronicInputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      {/* <button
        onClick={() => {
          setFormInfo({ ...formInfo, address: {} as any });
          // setTypeDoc("DOCS");
        }}
      >
        set step
      </button> */}
      <Modal
        onClickClose={() => {
          setShowPlanModal(false);
        }}
        open={showPlanModal}
      >
        <div className="flex flex-col items-center gap-8">
          <span className="text-3xl font-extrabold">Planos</span>
          <div className="flex w-full max-w-[800px] items-center gap-3 overflow-x-auto">
            {plans.map((p) => (
              <div className="flex flex-col justify-between p-4 min-w-[220px] max-w-[280px] h-[250px] overflow-hidden shadow-inner border border-zinc-200 rounded-xl">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">{p.name}</span>
                  <span className="text-3xl font-extrabold text-blue-500">
                    R$ {toLocaleAmount(p.price)} <span className="text-sm">/ mês</span>
                  </span>
                </div>
                <span className="text-sm font-semibold text-zinc-800 text-clip">{p.description}</span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <ContentWrapper>
        <div className="flex p-6">
          {step <= 7 && (
            <span className="text-lg border bg-white bg-opacity-85 rounded-md w-12 h-min text-center border-[#03142F]">
              {step}/7
            </span>
          )}
        </div>
        <WrapperRight>
          {/*<GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            Voltar ao Login
          </GoBackButtonWrapper>  */}
          <WrapperContent>
            <div className="relative w-full">
              {step < 2 && (
                <span className="text-3xl text-[#03142F] mb-2">
                  Você está a poucos passos de uma nova
                  <br />
                  <strong>experiência financeira</strong>
                </span>
              )}
            </div>

            {step === 1 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleOneSubmit();
                  }}
                >
                  <FormInput
                    placeholder="Nome Fantasia"
                    required
                    error={error.name || ""}
                    value={formInfo.name}
                    onChanged={(e) => setFormInfo({ ...formInfo, name: e.target.value })}
                  />
                  <FormInput
                    placeholder="Razão Social"
                    required
                    error={error.legalName || ""}
                    value={formInfo.legalName}
                    onChanged={(e) => setFormInfo({ ...formInfo, legalName: e.target.value })}
                  />
                  <FormInput
                    placeholder="CNPJ"
                    required
                    mask="99.999.999/9999-99"
                    error={error.document || ""}
                    value={formInfo.document}
                    onChanged={(e) => setFormInfo({ ...formInfo, document: e.target.value })}
                  />
                  {/* <FormInput
                    placeholder="E-mail"
                    required
                    error={error.email || ""}
                    value={formInfo.email}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, email: e.target.value })
                    }
                  /> */}

                  <div className="flex items-center gap-2 bg-[#f6f6f6] pr-4 rounded-xl mb-2">
                    <FormInputSelect
                      placeholder="PLANO"
                      options={plans.map((p) => ({
                        id: p.id,
                        name: `${p.name} R$ ${toLocaleAmount(p.price)}`,
                      }))}
                      onChanged={(e) => setSelectedPlanId(e.target.value)}
                      // onChanged={(e) => {
                      //   const translatedValue = mapplanToTranslation(
                      //     e.target.value
                      //   );
                      //   setFormInfo({ ...formInfo, name: translatedValue }); // add plans  no info forms e raw
                      // }}
                      // error={error.isPep || ""}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowPlanModal(true);
                      }}
                    >
                      <HelpCircle size={28} color="#009EE2" />
                    </button>
                  </div>

                  <FormInput
                    placeholder="Telefone"
                    required
                    mask="(99) 99999-9999"
                    error={error.phone || ""}
                    value={formInfo.phone}
                    onChanged={(e) => setFormInfo({ ...formInfo, phone: e.target.value })}
                  />

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black" }}
                        type="reset"
                        onClick={() => navigation("/type-create-account")}
                      >
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 2 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleTwoSubmitStepPJ();
                  }}
                >
                  <FormSelect
                    label={formInfo.establishmentType ? formInfo.establishmentType : "Selecione uma opção"}
                    options={["MEI", "LTDA", "SA", "EIRELI", "COOP", "OUTROS"]}
                    error={error.establishmentType || ""}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        establishmentType: e.target.value,
                      })
                    }
                  />
                  {/* {(formInfo.establishmentType === "DEMAIS"||
              formInfo.establishmentType === "SA"
              ) && <FormSelect
                placeholder="Natureza Jurídica"
                placeholder="Selecione uma opção"
                options={getLegalNatureOptions(formInfo.establishmentType)}
                error={error.legalNature || ""}
                onChanged={(e)=> setFormInfo({...formInfo, legalNature: e.target.value})}
              />} */}
                  <FormInput
                    placeholder="CNAE Principal"
                    required
                    maxLength={7}
                    error={error.mainCnae || ""}
                    value={formInfo.mainCnae}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        mainCnae: e.target.value.replace(/[/,.,-\s]/g, ""),
                      })
                    }
                  />
                  <FormInput
                    placeholder="Data de fundação"
                    mask="99/99/9999"
                    required
                    error={error.establishmentDate || ""}
                    value={formInfo.establishmentDate}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        establishmentDate: e.target.value,
                      })
                    }
                  />

                  <FormInput
                    label="Receita media mensal"
                    mask="currency"
                    required
                    value={formInfo.revenue}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        revenue: e.target.value,
                      })
                    }
                    error={error.revenue || ""}
                  />
                  <FormInput
                    label="Patrimonio"
                    mask="currency"
                    required
                    value={formInfo.amountPatrimony}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        amountPatrimony: e.target.value,
                      })
                    }
                    error={error.amountPatrimony || ""}
                  />
                  <FormInput
                    label="Capital social"
                    mask="currency"
                    required
                    value={formInfo.amountShareCapital}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        amountShareCapital: e.target.value,
                      })
                    }
                    error={error.amountShareCapital || ""}
                  />
                  <FormInput
                    label="Faturamento mensal"
                    mask="currency"
                    required
                    value={formInfo.amountMonthlyInvoicing}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        amountMonthlyInvoicing: e.target.value,
                      })
                    }
                    error={error.amountMonthlyInvoicing || ""}
                  />

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="reset" onClick={() => setStep(1)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {/* {step === 3 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleCep();
                  }}
                >
                  <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    error={error.address?.postalCode || ""}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          postalCode: e.target.value,
                        },
                      })
                    }
                  />

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButton>
                      <Button
                        style={{ backgroundColor: "#009ee2" }}
                        type="submit"
                      >
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black" }}
                        type="reset"
                        onClick={() => setStep(2)}
                      >
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )} */}

            {step === 3 && (
              <>
                <Text>Todos os dados a seguir devem ser obrigatoriamente do titular.</Text>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>

                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddress();
                  }}
                >
                  <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    error={error.address?.postalCode || ""}
                    onChanged={(e) => {
                      const newValue = e.target.value;

                      if (newValue.length == 9) {
                        handleCep(newValue);
                      } else {
                        setFormInfo({
                          ...formInfo,
                          address: {
                            ...formInfo.address,
                            postalCode: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                  {/* <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    error={error.address?.postalCode || ""}
                    value={formInfo.address.postalCode}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          postalCode: e.target.value,
                        },
                      })
                    }
                  /> */}

                  {formInfo?.address?.city && (
                    <>
                      <div className="flex gap-5">
                        <FormInput
                          nomargin
                          placeholder="Cidade"
                          required
                          error={error.address?.city || ""}
                          value={formInfo.address.city}
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                city: e.target.value,
                              },
                            })
                          }
                        />

                        <FormInput
                          nomargin
                          placeholder="Estado"
                          value={formInfo.address.state}
                          error={error.address?.state || ""}
                          required
                          maxLength={2}
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                state: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <FormInput
                        placeholder="Bairro"
                        value={formInfo.address.neighborhood}
                        error={error.address?.neighborhood || ""}
                        required
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            address: {
                              ...formInfo.address,
                              neighborhood: e.target.value,
                            },
                          })
                        }
                      />

                      <FormInput
                        placeholder="Rua"
                        error={error.address?.street || ""}
                        value={formInfo.address.street}
                        maxLength={39}
                        required
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            address: {
                              ...formInfo.address,
                              street: e.target.value,
                            },
                          })
                        }
                      />

                      <div className="flex gap-5">
                        <FormInput
                          nomargin
                          placeholder="Número"
                          error={error.address?.number || ""}
                          value={formInfo.address.number}
                          required
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                number: e.target.value,
                              },
                            })
                          }
                        />

                        <FormInput
                          nomargin
                          placeholder="Complemento"
                          value={formInfo.address.extra}
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                extra: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <FormInputSelect
                        label="Tipo"
                        placeholder="Tipo do endereço"
                        options={["Residencial", "Comercial"]}
                        onChanged={(e) => {
                          const typeAdressValue = mapAdressTypeToTranslation(e.target.value);
                          setFormInfo({
                            ...formInfo,
                            address: {
                              ...formInfo.address,
                              type: typeAdressValue,
                            },
                          });
                        }}
                      />
                    </>
                  )}

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="reset" onClick={() => setStep(2)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 4 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Informe os dados de um sócio majoritário ou administrador.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePartner();
                  }}
                >
                  <FormInput
                    placeholder="Nome do Sócio"
                    required
                    error={error.partner?.name || ""}
                    value={formInfo.partner?.name}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        partner: { ...formInfo.partner, name: e.target.value },
                      })
                    }
                  />
                  <FormInput
                    placeholder="CPF"
                    required
                    mask="999.999.999-99"
                    error={error.partner?.document || ""}
                    value={formInfo.partner?.document}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        partner: {
                          ...formInfo.partner,
                          document: e.target.value,
                        },
                      })
                    }
                  />

                  <FormInput
                    placeholder="Data de nascimento"
                    required
                    mask="99/99/9999"
                    error={error.partner?.birthDate || ""}
                    value={formInfo.partner?.birthDate}
                    onBlur={(e) => {
                      const { value } = e.target;
                      validationSchema
                        .validate({ partner: { birthDate: value } })
                        .then(() => {})
                        .catch((error) => {
                          console.error(error.errors[0]);
                        });
                    }}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        partner: {
                          ...formInfo.partner,
                          birthDate: e.target.value,
                        },
                      })
                    }
                  />
                  <FormInput
                    placeholder="Nome da mãe"
                    required
                    error={error.partner?.motherName || ""}
                    value={formInfo.partner?.motherName}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        partner: {
                          ...formInfo.partner,
                          motherName: e.target.value,
                        },
                      })
                    }
                  />
                  <FormRadio
                    label="O sócio é PEP?"
                    options={["Não", "Sim"]}
                    value=""
                    error={error.partner?.isPep || ""}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        partner: {
                          ...formInfo.partner,
                          isPep: e.target.value === "Sim" ? true : false,
                        },
                      })
                    }
                  />

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="reset" onClick={() => setStep(3)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 5 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Informe o endereço do sócio majoritário ou administrador.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    setStep(6);
                  }}
                >
                  <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    error={error?.partner?.address?.postalCode || ""}
                    value={formInfo?.partner?.address?.postalCode}
                    onChanged={(e) => {
                      const newValue = e.target.value;

                      if (newValue.length == 9) handleCep(newValue, true);
                      setFormInfo({
                        ...formInfo,
                        partner: {
                          ...formInfo.partner,
                          address: {
                            ...formInfo.partner?.address,
                            postalCode: e.target.value,
                          },
                        },
                      });
                    }}
                  />

                  {formInfo?.partner?.address?.city && (
                    <>
                      <FormInput
                        nomargin
                        placeholder="Cidade"
                        required
                        error={error.partner?.address?.city || ""}
                        value={formInfo.partner?.address?.city}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            partner: {
                              ...formInfo.partner,
                              address: {
                                ...formInfo.partner?.address,
                                city: e.target.value,
                              },
                            },
                          })
                        }
                      />

                      <FormInput
                        nomargin
                        placeholder="Estado"
                        required
                        maxLength={2}
                        error={error.partner?.address?.state || ""}
                        value={formInfo.partner?.address?.state}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            partner: {
                              ...formInfo.partner,
                              address: {
                                ...formInfo.partner?.address,
                                state: e.target.value,
                              },
                            },
                          })
                        }
                      />

                      <FormInput
                        placeholder="Bairro"
                        required
                        error={error.partner?.address?.neighborhood || ""}
                        value={formInfo.partner?.address?.neighborhood}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            partner: {
                              ...formInfo.partner,
                              address: {
                                ...formInfo.partner?.address,
                                neighborhood: e.target.value,
                              },
                            },
                          })
                        }
                      />

                      <FormInput
                        placeholder="Rua"
                        required
                        error={error.partner?.address?.street || ""}
                        value={formInfo.partner?.address?.street}
                        maxLength={39}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            partner: {
                              ...formInfo.partner,
                              address: {
                                ...formInfo.partner?.address,
                                street: e.target.value,
                              },
                            },
                          })
                        }
                      />

                      <FormInput
                        nomargin
                        placeholder="Número"
                        required
                        error={error.partner?.address?.number || ""}
                        value={formInfo.partner?.address?.number}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            partner: {
                              ...formInfo.partner,
                              address: {
                                ...formInfo.partner?.address,
                                number: e.target.value,
                              },
                            },
                          })
                        }
                      />

                      <FormInput
                        nomargin
                        placeholder="Complemento"
                        value={formInfo.partner?.address?.extra}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            partner: {
                              ...formInfo.partner,
                              address: {
                                ...formInfo.partner?.address,
                                extra: e.target.value,
                              },
                            },
                          })
                        }
                      />
                      <FormInputSelect
                        label="Tipo"
                        placeholder="Tipo do endereço"
                        options={["Residencial", "Comercial"]}
                        onChanged={(e) => {
                          const typeAdressValue = mapAdressTypeToTranslation(e.target.value);
                          setFormInfo({
                            ...formInfo,
                            partner: {
                              ...formInfo.partner,
                              address: {
                                ...formInfo.partner.address,

                                type: typeAdressValue,
                              },
                            },
                          });
                        }}
                      />
                    </>
                  )}

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="reset" onClick={() => setStep(4)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 6 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">Defina suas credencias de acesso.</span>

                <Text
                  style={{
                    color: "black",
                    marginBottom: "-1px",
                    marginTop: "-10px",
                  }}
                >
                  <strong>
                    A sua senha deve ter pelo menos 8 dígitos, uma letra maiúscula e um caractere especial.
                  </strong>
                </Text>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePasswords();
                  }}
                >
                  <FormInput
                    ref={passwordInputRef}
                    id="password-input"
                    borderColor="#ff0013"
                    placeholder="SENHA"
                    type="password"
                    showPasswordIcon={true}
                    error={error.password || ""}
                    value={formInfo.password}
                    onChanged={(e) => setFormInfo({ ...formInfo, password: e.target.value })}
                  />
                  <FormInput
                    ref={password2InputRef}
                    id="password2-input"
                    borderColor="#ff0013"
                    placeholder="REPITA A SENHA"
                    type="password"
                    showPasswordIcon={true}
                    error={error.password2 || ""}
                    value={formInfo.password2}
                    onChanged={(e) => setFormInfo({ ...formInfo, password2: e.target.value })}
                  />

                  <FormInput
                    ref={pinInputRef}
                    id="pin-input"
                    borderColor="#ff0013"
                    maxLength={4}
                    placeholder="SECURITY DIGIT (Camada extra de segurança)"
                    type="password"
                    error={error.pin || ""}
                    showPasswordIcon={true}
                    value={formInfo.pin}
                    onChanged={(e) => setFormInfo({ ...formInfo, pin: e.target.value })}
                  />

                  <FormInput
                    ref={SecurityEletronicInputRef}
                    id="securty-eletronic-input"
                    borderColor="#ff0013"
                    maxLength={8}
                    placeholder="SENHA ELETRÔNIA (Senha de operações bancárias)"
                    type="password"
                    error={error.securityEletronic || ""}
                    showPasswordIcon={true}
                    value={formInfo.securityEletronic}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        securityEletronic: e.target.value,
                      })
                    }
                  />
                  <WrapperDivButton>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="reset" onClick={() => setStep(5)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 7 && (
              <>
                {typeDoc === "SELFIE" && (
                  <>
                    <Title>Selfie do sócio cadastrado.</Title>
                    <span className="text-lg font-extralight text-gray-900 mb-2">
                      Precisamos que envie uma foto de rosto. Não se preocupe ela é apenas para identificação e
                      segurança.
                    </span>
                  </>
                )}

                {typeDoc !== "SELFIE" && (
                  <>
                    {(typeDoc === "CNH_FRONT" || typeDoc === "RG_FRONT") && (
                      <>
                        <Title>Documentos do sócio cadastrado.</Title>
                        <span className="text-lg font-extralight text-gray-900 mb-2">
                          Precisamos que envie agora uma foto da FRENTE do documento escolhido anteriormente (CNH ou
                          RG). Não se preocupe, é apenas para identificação e segurança.
                        </span>
                      </>
                    )}

                    {(typeDoc === "CNH_BACK" || typeDoc === "RG_BACK") && (
                      <>
                        <Title>Documentos do sócio cadastrado.</Title>
                        <span className="text-lg font-extralight text-gray-900 mb-2">
                          Precisamos que envie agora uma foto do VERSO do documento escolhido anteriormente (CNH ou RG).
                          Não se preocupe, é apenas para identificação e segurança.
                        </span>
                      </>
                    )}

                    {typeDoc !== "CNH_BACK" &&
                      typeDoc !== "RG_BACK" &&
                      typeDoc !== "CNH_FRONT" &&
                      typeDoc !== "RG_FRONT" && (
                        <>
                          <Title>Documentos da Empresa</Title>
                          <span className="text-lg font-extralight text-gray-900 mb-2">
                            Precisamos que envie fotos de documentos da empresa. Não se preocupe, é apenas para sua
                            identificação e segurança.
                          </span>
                        </>
                      )}
                  </>
                )}
                <WrapperForm onSubmit={(e) => e.preventDefault()}>
                  {typeDoc === "DOCS" && (
                    <>
                      <Button
                        type="button"
                        style={{
                          marginBottom: "30px",
                          backgroundColor: "#009ee2",
                        }}
                        onClick={() => setTypeDoc("CNH_FRONT")}
                      >
                        CNH
                      </Button>
                      <Button
                        style={{ backgroundColor: "#009ee2" }}
                        type="button"
                        onClick={() => setTypeDoc("RG_FRONT")}
                      >
                        RG
                      </Button>
                    </>
                  )}
                  {typeDoc !== "DOCS" && (
                    <>
                      {typeDoc === "RG_BACK" && (
                        <h3 className="text-lg text-gray-900 mb-5">Ótimo! agora precisamos do verso</h3>
                      )}
                      {typeDoc === "PERSON_LEGAL_SOCIAL_CONTRACT" && (
                        <h3 className="text-lg text-zinc-900 mb-5">
                          Ótimo! Se você é um Microempreendedor Individual (MEI), por favor, envie seu CCMEI. Para
                          qualquer outro tipo de empresa, por favor, envie seu contrato social.
                        </h3>
                      )}
                      {typeDoc === "PERSON_LEGAL_REVENUES" && (
                        <h3 className="text-lg text-zinc-900 mb-5">
                          Ótimo! Envie um documento que comprove seu rendimentos mensais. (Extrato bancário, Declarações
                          de imposto de renda, Declarações de rendimento e etc)
                        </h3>
                      )}
                      <div className="flex justify-center  items-center w-full">
                        <label
                          htmlFor="dropzone-file"
                          className={`${
                            loading ? "bg-gray-600" : ""
                          } flex flex-col justify-center items-center w-full h-40 border-2 border-dashed cursor-pointer border-gray-400 bg-white bg-opacity-75 rounded-xl`}
                        >
                          <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            {/* <div> ver isso depois pra aparecer a imagem do usuario selfie e tals pra ver se ficou bom
      <input type="file" onChange={handleFileSelect} />
      {base64Image && <img src={base64Image} alt="Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />}
    </div>
                            */}
                            {loading ? (
                              <>
                                <Loading isLoading />
                                <p className="text-gray-700 mt-5">Analisando imagem...</p>
                              </>
                            ) : (
                              <>
                                <svg
                                  className="mb-3 w-10 h-10 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                  ></path>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">Clique para fazer upload</span> ou arraste e solte
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400"> PNG or JPG (MAX. 800x400px)</p>
                              </>
                            )}
                          </div>
                          <input id="dropzone-file" type="file" className="hidden" onChange={handleFileSelect} />
                        </label>
                      </div>
                      <div className="flex mt-2">
                        {typeDoc === "CCMEI" && (
                          <p>
                            <ul>
                              <li>Envie o Certificado da Condição do Microempreendedor Individual (CCMEI)</li>
                            </ul>
                          </p>
                        )}

                        {typeDoc === "PERSON_LEGAL_SOCIAL_CONTRACT" && (
                          <p>
                            <ul>
                              <li>Envie o contrato social ou CCMEI</li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "ARTICLES_OF_ASSOCIATION" && (
                          <p>
                            <ul>
                              <li>Envie o contrato social</li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "COMPANY_BYLAWS" && (
                          <p>
                            <ul>
                              <li>Envie o Estatuto da Empresa</li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "EI_REGISTRATION_REQUIREMENT" && (
                          <p>
                            <ul>
                              <li>Envie o documento Requerimento de Empresário</li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "CNH_FRONT" && (
                          <p>
                            <ul>
                              <li>Envie a CNH com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento embaçado
                              </li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "CNH_BACK" && (
                          <p>
                            <ul>
                              <li>Envie o verso da CNH com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento embaçado
                              </li>
                            </ul>
                          </p>
                        )}

                        {typeDoc === "RG_FRONT" && (
                          <p>
                            <ul>
                              <li>Envie o RG com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento embaçado
                              </li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "RG_BACK" && (
                          <p>
                            <ul>
                              <li>Envie o verso do RG com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento embaçado
                              </li>
                            </ul>
                          </p>
                        )}

                        {typeDoc === "SELFIE" && (
                          <p>
                            <ul>
                              <li>Escolha uma foto com boa iluminação</li>
                              <li>
                                <strong>Não</strong> envie fotos com acessórios que escondem o seu rosto
                              </li>
                              <li>
                                <strong>Não</strong> envie fotos com mais de uma pessoa
                              </li>
                            </ul>
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </WrapperForm>
              </>
            )}

            {step === 66 && (
              <span className="text-lg font-extralight text-gray-700 mb-2">
                Infelizmente não foi possível abrir sua conta neste momento.
              </span>
            )}

            {step === 99 && (
              <span className="text-lg font-extralight text-gray-700 mb-2">
                Pronto! Agora é só esperar a análise dos dados. Em até 1 dia você irá receber a resposta em seu e-mail.
              </span>
            )}
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
}

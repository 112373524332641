import { FC, useEffect, useState } from "react";
import { GradientButton } from "../newCreateAccountWallet/components";
import { useLocation, useNavigate } from "react-router-dom";
import { CurrencyCircleDollar, FileText, QrCode } from "phosphor-react";
import { useAuth } from "src/hooks/auth";
import { api } from "src/services/api";

import ConfirmUpgradePNG from "../../assets/ConfirmUpgradePNG.png";
import PixLogoBrown from "../../assets/PixLogoBrown.svg";

import "./styles.css";

export const ConfirmUpgrade: FC = () => {
  const location = useLocation();
  const { accountType } = location.state;

  const navigate = useNavigate();

  const { user, account } = useAuth();

  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState<any>();

  function extractNumber(str: string) {
    // Remove "R$" do início da string
    let numeroStr = str.replace("R$", "").trim();

    // Remove os pontos e substitui a vírgula por um ponto
    numeroStr = numeroStr.replace(/\./g, "").replace(",", ".");

    // Converte a string para um número (float)
    let numero = parseFloat(numeroStr);

    return numero;
  }

  const handleUpdateIndividualsPJ = async () => {
    if (!address) {
      return;
    }
    try {
      setIsLoading(true);
      const token = localStorage.getItem("@stricv2:token");
      const raw = {
        franchise_id: user.franchise_id,
        document: user.document,
        name: user.name,
        email: user.email,
        planId: selectedPlan.id,
        phone: {
          ddd: user.phone.ddd,
          number: user.phone.number,
          type: user.phone.type,
        },
        birthDate: user.birthDate,
        monthlyInvoicing: user.monthlyInvoicing ? Number(user.monthlyInvoicing) : 0,
        educationLevel: user.educationLevel ? user.educationLevel : "",
        maritalStatus: user.maritalStatus ? user.maritalStatus : "",
        gender: user.gender,
        address: {
          isConfirmed: true,
          publicPlace: address.publicPlace,
          number: address.number,
          complement: address.complement,
          neighborhood: address.neighborhood,
          cityName: address.cityName,
          zipCode: address.zipCode,
          type: address.type,
          state: address.state,
        },
        userId: account.user_id,
        pin: user.pin,
        security_eletronic: user.security_eletronic,
      };
      await api.post("users/individuals", raw, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleUpdateIndividualsPF = async () => {
    if (!address) {
      return;
    }
    try {
      setIsLoading(true);
      const token = localStorage.getItem("@stricv2:token");

      const raw = {
        franchise_id: user.franchise_id,
        document: user.document,
        name: user.name,
        email: user.email,
        planId: selectedPlan.id,
        phone: {
          ddd: user.phone.ddd,
          number: user.phone.number,
          type: user.phone.type,
        },
        birthDate: user.birthDate,
        monthlyInvoicing: user.monthlyInvoicing ? extractNumber(user.monthlyInvoicing) : 0,
        educationLevel: user.educationLevel ? user.educationLevel : "",
        maritalStatus: user.maritalStatus ? user.maritalStatus : "",
        gender: user.gender,
        address: {
          isConfirmed: true,
          publicPlace: address.publicPlace,
          number: address.number,
          complement: address.complement,
          neighborhood: address.neighborhood,
          cityName: address.cityName,
          zipCode: address.zipCode,
          type: address.type,
          state: address.state,
        },
        userId: account.user_id,
        pin: user.pin,
        security_eletronic: user.security_eletronic,
      };

      console.log(raw);
      await api.post("users/individuals", raw, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      navigate("/type-create-account/success");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("@stricv2:token");

    api
      .get("/graphic/plans")
      .then((response) => {
        const contasWallet = response.data.filter((item: any) => item.avaliableToRoles.includes("WALLET"));
        setSelectedPlan(contasWallet[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get(`/users/address/${account.user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAddress(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="w-[86vw] h-[90vh] flex items-center justify-center gap-60 upgrade-background max-2xl:gap-0 max-2xl:w-[81vw]">
      <div>
        <svg className="relative max-2xl:w-[75vw] max-2xl:h-[57vh] bottom-20" width="82vw" height="69vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            className="max-2xl:w-[70vw] max-2xl:h-[55vh]"
            x="10"
            y="10"
            width="80vw"
            height="67vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>
        <div className="absolute flex top-[13.2vh] ml-3 max-2xl:top-[14.8vh] max-2xl:ml-[.65rem]">
          <img className="h-[66.8vh] rounded-tl-lg rounded-bl-lg max-2xl:h-[54.6vh]" src={ConfirmUpgradePNG} alt="" />

          <div className="flex flex-col items-start justify-start h-full ml-14 mt-8">
            <p className="text-white text-3xl font-semibold mb-10 max-2xl:mt-[-1.2rem] max-2xl:text-xl max-2xl:mb-5">
              Você tem certeza que deseja <br />
              realizar o Upgrade para conta <br />
              Física?
            </p>

            <div className="flex items-center gap-2 mb-4 max-2xl:gap-1 max-2xl:mb-2">
              <img className="max-2xl:h-7" height={42} width={42} src={PixLogoBrown} alt="" />
              <h4 className="text-white text-2xl font-light max-2xl:text-lg">Cadastrar chave PIX</h4>
            </div>
            <div className="flex items-center gap-2 mb-4 max-2xl:gap-1 max-2xl:mb-2">
              <FileText className="max-2xl:h-7" color="#274683" size={42} />
              <h4 className="text-white text-2xl font-light max-2xl:text-lg">Boleto Recorrente</h4>
            </div>
            <div className="flex items-center gap-2 mb-4 max-2xl:gap-1 max-2xl:mb-2">
              <CurrencyCircleDollar className="max-2xl:h-7" color="#274683" size={42} />
              <h4 className="text-white text-2xl font-light max-2xl:text-lg">Transferência entre contas</h4>
            </div>
            <div className="flex items-center gap-2 mb-20 max-2xl:gap-1">
              <QrCode className="max-2xl:h-7" color="#274683" size={42} />
              <h4 className="text-white text-2xl font-light max-2xl:text-lg">Cobrança programada</h4>
            </div>
            <div className="max-2xl:ml-[-8rem] max-2xl:mt-10">
              <GradientButton
                tailwindStyles="bg-[#171717] w-[25rem] h-16 text-white text-lg"
                title="Aceitar e continuar"
                onClick={handleUpdateIndividualsPF}
                // onClick={() => navigate("/type-create-account/success", { state: { accountType: "PJ" } })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// import { useEffect } from "react";
import Routes from "./routes";
import { GlobalStyle } from "./styles/globalStyle";
import { Hooks } from "./hooks";

import "./styles/index.css";
import { Notification } from "./components/notification";
import Message from "./components/Message";
import { useEffect } from "react";

function App() {
    useEffect(() => {
      const handleUnload = () => {
        localStorage.removeItem("@stricv2:token");
        localStorage.removeItem("@stricv2:account");
        localStorage.removeItem("@stricv2:user");
        localStorage.removeItem("@backoffice:user");
        localStorage.removeItem("@backoffice:token");
      };

      // window.addEventListener("unload", handleUnload);

      // return () => {
      //   window.removeEventListener("unload", handleUnload);
      // };
    }, []);

  return (
    <>
      <Hooks>
        <Routes />
        <GlobalStyle />
        <Message />
        <Notification />
      </Hooks>
    </>
  );
}

export default App;

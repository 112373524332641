import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ExtractItem } from "src/utils/extractDescription";
import { SlipResponseTx } from "../ProofResponses";
import transactionLabel from "src/utils/transactionLabel";
import {
  ButtonWrapper,
  CloseIcon,
  DownloadIcon,
  InfoModal,
  ModalContent,
  ModalHeader,
  Section,
  SectionCPNJ,
  SectionItem,
  SectionPayerName,
  SectionTitle,
} from "../../ModalConfirmOperation/styles";
import toLocaleAmount from "src/utils/toLocaleAmount";
import dateToLine from "src/utils/dateToLine";
import { documentFormatted } from "src/utils/documentFormatted";
import { Button } from "../../button";
import { config } from "src/config";
import { dateFormat } from "src/utils/dateFormat";
import { DefaultLabel } from "src/pages/receipt/PDF";

export const Slip: FC<{ data: ExtractItem; closeModal: () => void }> = ({
  data,
  closeModal,
}) => {
  const txType = data.type.toLowerCase();

  console.log(txType);

  const naviagate = useNavigate();

  if (!txType.includes("slip")) {
    naviagate("/extract");
  }

  function printPDF() {
    if (!data) return null;

    const receiptString = JSON.stringify(data);

    localStorage.setItem("temp_receipt_to_print", receiptString);

    window.open(`${config.FRONTEND_URL}/PDF`);
  }

  const response = data.response as SlipResponseTx;

  const label = transactionLabel(data);

  console.log(response);

  return (
    <>
      <div className="w-full h-16 flex px-10 items-center bg-blue-500">
        <span className="text-white text-xl font-bold">{label}</span>
      </div>
      <div className="flex flex-col px-10 py-4 gap-1">
        <span className="text-zinc-500 font-semibold mb-2 text-3xl">
          Pagador
        </span>

        <DefaultLabel title="NOME" value={response.payer.name} />
        <DefaultLabel
          title={response.payer.document.length > 11 ? "CNPJ" : "CPF"}
          value={documentFormatted(response.payer.document)}
        />
        <DefaultLabel
          title={"Valor"}
          value={`R$ ${toLocaleAmount(data.amount)}`}
        />
      </div>
      <div className="flex flex-col px-10 py-4 gap-1">
        <span className="text-zinc-500 font-semibold mb-2 text-3xl">
          Outras informações
        </span>
        <DefaultLabel
          title="Número da carteira"
          value={response.walletNumber}
        />
        <DefaultLabel title={"ID da transação"} value={data.id} />
        <DefaultLabel
          title="Data da transação"
          value={dateFormat(data.created_at)}
        />
      </div>
    </>
  );
};

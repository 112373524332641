import styled from "styled-components";

import {ReactComponent as ArrowRight} from '../../assets/arrowRight.svg';

export const Container = styled.div`

grid-area: orders;
background-color: var(--white);
border-radius: 8px;

`

export const Header = styled.div`


display: flex;
justify-content: space-between;
padding: 24px 32px;
border-bottom: 1px solid var(--gray-light-line);

> span {
    color: var(--gray);
    font-size: 16px;
    font-family: var(--semiBold);
}

> a > p {
    color: var(--gray);
    font-size: 14px;
    font-family: var(--regular);

}

`

export const OrdersWrapper = styled.div`

padding: 32px;
display: flex;
flex-direction: column;
`

export const Order = styled.div`

height: 40px;
display: flex;
align-items: center;
justify-content: space-between;
position: relative;

& + div {
    margin-top: 16px;

    &::before {
    content: "";
    background: var(--gray-light-line);
    height: 18px;
    width: 1px;
    position: absolute;
    left: 20px;
    top: -16px;
    }
}

`

export const IconWrapper = styled.div`

height: 40px;
width: 40px;
border-radius: 8px;
border: 1px solid var(--gray-light-line);
display: flex;
justify-content: center;
align-items: center;
margin-right: 18px;
position: relative;

`

export const OrderDetails = styled.div`

display: flex;
align-items: center;


`

export const BalanceDetails = styled.div`

display: flex;
align-items: center;



`

export const Type = styled.span`

font-size: 12px;
color: var(--gray-light-order);
font-family: var(--regular);

`

export const DateUltimosLancamentos = styled.span`

font-size: 14px;
color: var(--black);
font-family: var(--regular);
width: 140px;
`

export const Name = styled.p`
max-width: 340px;
font-size: 15px;
color: var(--gray);
font-family: var(--regular);

`

export const Icon = styled.img``

export const InfoOrder = styled.div``


interface BalanceProps {
  credit: boolean | string;
}

export const Balance = styled.p<BalanceProps>`
color: ${({credit}) => credit ? 'var(--green)' : 'var(--red)'};

font-family: var(--regular);
font-size: 14px;
`

export const ArrowIcon = styled(ArrowRight)<BalanceProps>`
fill: ${({credit}) => credit && credit !== 'false' ? 'var(--green)' : 'var(--boxText)'};
color: ${({credit}) => credit && credit !== 'false' ? 'var(--green)' : 'var(--boxText)'};
margin-left: 12px;

path {
    color: ${({credit}) => credit && credit !== 'false'  ? 'var(--green)' : 'var(--boxText)'};
    fill: currentcolor;
}

`
export const WrapperMobile = styled.div`
  width: 30px;
  position: fixed;
  bottom: 20px;
  right: 50px;
  transition: width 0.3s ease-in-out;
  z-index: 1000;

&:hover {
  width: 40px;
}
`

export const DivMobile = styled.div`
  position: relative;
`

export const Tooltip = styled.div<{ show: boolean }>`
display: ${props => (props.show ? 'block' : 'none')};
position: absolute;
top: 0px;
left: -80px;
width: 150px;
transform: translateX(-50%);
background-color: #f5f5f5;
padding: 8px;
border-radius: 4px;
animation: fade-in 0.3s ease-in-out;
z-index: 100000;
border: 2px solid var(--gray);
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
`;

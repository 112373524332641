import React, { useState } from 'react';
import onImage from './../../../assets/on.png';
import offImage from './../../../assets/off.png';
import {ToogleDiv} from './styles';
import axios from 'axios';
import { useNotification } from 'src/hooks/notification';

interface ToggleSwitchFavoriteProps {
    userId: string; // Passar o ID da conta como propriedade
    blocked: boolean; // Passar o status inicial como propriedade
    
  }

  const ToggleSwitchBlocked: React.FC<ToggleSwitchFavoriteProps> = ({ userId, blocked }) => {
    const [isOn, setIsOn] = useState(blocked);
    const token = localStorage.getItem('@stricv2:account');
    const { setOpenNotification } = useNotification();
  
    const handleToggle = async () => {
      const newStatus = !isOn;
      setIsOn(newStatus);
  
      try {
        console.log('Início da função handleToggle');
  
        // Fazer chamada para API para atualizar o status no servidor
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/update-config-blocked-password-status/${userId}`,
          { blocked: newStatus },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log('Resposta da requisição PATCH:', response);
  
        if (response.status === 200) {
          console.log('Atualização bem-sucedida no servidor');
          setOpenNotification({
            type: 'success',
            title: 'Atualização Concluída:',
            notification: newStatus
              ? 'Senha Desbloqueada com Sucesso!'
              : 'Senha Bloqueada com Sucesso!',
          });
          // Se necessário, adicione aqui uma função para atualizar o estado ou refazer a busca de dados no banco de dados
        }
      } catch (error) {
        console.error('Erro ao atualizar status no servidor:', error);
  
        // Reverter o estado em caso de erro
        setIsOn(!isOn);
  
        setOpenNotification({
          type: 'error',
          title: 'Erro na tentativa de atualização!',
          notification: 'Houve um erro ao tentar atualizar, tente novamente mais tarde.',
        });
      } finally {
        console.log('Final da função handleToggle');
      }
    };
  
    return (
      <ToogleDiv onClick={handleToggle} style={{ cursor: 'pointer' }}>
        <img
          src={isOn ? onImage : offImage}
          alt={isOn ? 'On' : 'Off'}
          style={{ width: '100px', height: '40px' }}
        />
      </ToogleDiv>
    );
  };
  
  export default ToggleSwitchBlocked;
  
import { ChevronDown } from "lucide-react";
import { useState } from "react";

export interface DropdownOption {
  title: string;
  selected: boolean;
  onSelect: () => void;
}

interface DropdownProps {
  options: DropdownOption[];
  closeOnSelect?: boolean;
  title: string | React.ReactChild;
  width?: number;
  listWidth?: number;
  min?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  title,
  closeOnSelect = false,
  width = 120,
  listWidth = 120,
  min = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (index: number) => {
    options[index].onSelect();
    if (closeOnSelect) {
      setIsOpen(false);
    }
  };

  return (
    <div
      onBlur={() => setIsOpen(false)}
      style={{ width }}
      className="relative inline-block text-left"
    >
      <div>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          style={{
            border: min ? "none" : "",
            boxShadow: min ? "none" : "",
          }}
          className="flex active:bg-zinc-50 duration-100 border border-zinc-50 items-center gap-1 justify-center w-full rounded-md shadow-inner h-10 bg-white text-sm font-medium text-gray-500 outline-none focus:outline-none"
        >
          <span
            className="transition-all duration-200 ease-in-out"
            style={{
              rotate: typeof title != "string" && isOpen ? "25deg" : "",
            }}
          >
            {title}
          </span>
          {!min && (
            <ChevronDown
              size={15}
              color="#445"
              strokeWidth={2.5}
              className="mt-[2px] duration-100"
              style={{
                rotate: isOpen ? "180deg" : "0deg",
              }}
            />
          )}
        </button>
      </div>
      <div
        style={{
          width: listWidth,
          opacity: isOpen ? 1 : 0,
          height: isOpen ? "auto" : 0,
        }}
        className="origin-top-right overflow-y-auto absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 hideScrollbar duration-100 transition-all"
      >
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          className="z-50"
        >
          {options.map((option, index) => (
            <button
              key={index}
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                handleSelect(index);
              }}
              className={`${
                option.selected ? "bg-gray-50 underline" : "bg-white"
              } block hover:bg-zinc-50 px-4 py-2 text-sm text-gray-700 w-full text-left`}
              role="menuitem"
            >
              {option.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

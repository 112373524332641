import { DuplicateIcon } from "@heroicons/react/solid";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { Skeleton } from "../../components/skeleton";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios'; 
import { useAuth } from "../../hooks/auth";

import {
  ActionPixTable,
  // KeyCpnjIcon,
  // KeyPixTable,
  // ShareIcon,
} from "../extract/styles";
import { Container, Content, StyledTextContainer, Table, TableWrapper, Title, TitleContainer } from "./styles";
import { useNotification } from "../../hooks/notification";
import { useNavigate } from "react-router-dom";

interface AuthState {
    token: string;
  }
  

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export const PasswordControlAccountChange = () => {
    const token = localStorage.getItem('@stricv2:token');

  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState<KeysProps[]>([]);

  const navigate = useNavigate();
  const { setOpenNotification } = useNotification();

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [key, setKey] = useState<KeysProps>({} as KeysProps);

  const [inputValue, setInputValue] = useState("");
  const [isLocked, setIsLocked] = useState(true);

  const { signIn, account, user } = useAuth(); 

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  const handleLock = () => {
    setIsLocked(!isLocked);
  };

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const handleSave = async () => {
    if (!isLocked && inputValue.length >= 6 && !containsDuplicateCharacters(inputValue)) {
      try {
        const userId = user.id;
      console.log(userId, "eu sou o id:")
      console.log('Antes da requisição PATCH');
      console.log('userId:', userId);
      console.log('inputValue:', inputValue);


        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/graphic/change-password/${userId}`, { newPassword: inputValue  }, config);
        console.log('Resposta da requisição PATCH:', response);
       
        if (response.status === 200) {
          setOpenNotification({
            type: 'success',
            title: 'Senha atualizada',
            notification: 'Sua senha foi atualizada com sucesso!',
          });
          // Se necessário, adicione aqui uma função para atualizar o estado ou refazer a busca de chaves no banco de dados
        }
      } catch (error) {
        setOpenNotification({
          type: 'error',
          title: 'Erro ao atualizar a senha',
          notification: 'Houve um erro ao tentar atualizar sua senha. Por favor, tente novamente mais tarde.',
        });
      }
    }
  };
  

// Definindo a função identificarTipoString
function identificarTipoString(str: string): string {

  const regexPassword = /^(?:(.)\1{4,}|[\w@#$%]{6,})$/;  
  
    if (regexPassword.test(str)) {
      return "Senha Validada";
    } else {
      return "Formato Inválido";
    }
  }
  

// Chamar a função e exibir o tipo de string identificado
const tipoStringIdentificado = identificarTipoString(inputValue);
console.log(tipoStringIdentificado);



const mockKeys = [
  {
    key: "key1",
    keyType: "type1",
    keyStatus: "status1",
    dateKeyCreated: "date1"
  }
  // Adicione mais chaves se necessário
];

useEffect(() => {
  setKeys(mockKeys);
  setLoading(false);
}, []);


  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isLocked) {
        setInputValue(e.target.value);
    }
  };
  
  const containsDuplicateCharacters = (str: string): boolean => {
    for (let i = 0; i < str.length - 5; i++) {
      if (str.substring(i, i + 6) === str[i].repeat(6)) {
        return true;
      }
    }
    return false;
  };


  const inputWidth = `${Math.max(200, inputValue.length * 8)}px`; // Adjust the multiplier as needed

  return (
    <Container>
      <Content>

      <TitleContainer>  
<Title>Crie ou altere a sua Senha de Acesso por aqui!</Title>
</TitleContainer> 

<StyledTextContainer> 

              
<span>
  Personalize sua experiência de segurança configurando sua Senha de Acesso. Edite uma nova senha composta por no mínimo 6 caracteres, incluindo números, letras e caracteres especiais, com pelo menos uma letra maiúscula e um caractere especial. Comece clicando no botão 'Editar', insira sua nova senha de acesso no primeiro campo do formulário e, em seguida, conclua clicando em 'Enviar'. Caso queira trocar sua senha de acesso novamente, sinta-se à vontade para repetir esse processo e criar uma nova senha de acesso sempre que necessário. Estamos aqui para garantir a segurança e praticidade em sua conta.
</span>
              
                </StyledTextContainer>  

                
        <Table>
         
          <TableWrapper>
          <Skeleton space={4} isActive={loading} repeat={3} />
            <table>
             {!loading  && (
              <thead>
               <tr>
                  <th>Trocar sua Senha</th>
                  <th>Confirmar</th>
                  <th>Copiar Senha</th>
                  <th>Senha Valida</th> 
                  <th>Status</th>              
             
                </tr>
              </thead>
             )}

             <tbody>
              {keys.map(key => (
                <tr key={key.key}>
                  <td> <input 
        id="passwordInput"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Digite sua nova senha aqui!"
        style={{
          opacity: isLocked ? 0.5 : 1,
          backgroundColor: isLocked ? 'lightgrey' : 'white',
          border: isLocked ? '2px solid black' : '2px solid black',
          borderRadius: '4px',
          width: isLocked ? inputWidth : inputWidth,
          padding: "4px",
          paddingBottom: '2px',
          textAlign: 'center',
          color: 'black',
        }}      />
        </td>
<td> 
<button
  onClick={() => {
    if (isLocked) {
      handleLock();
    } else {
      handleSave();
    }
  }}
  id="submitButton"
  disabled={!isLocked && (inputValue.length < 6 || containsDuplicateCharacters(inputValue))}
  style={{
    backgroundColor: isLocked ? '#3498db' : '#4caf50',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    paddingBottom: '2px',
    transition: 'background-color 0.3s',
    cursor: !isLocked && (inputValue.length < 6 || containsDuplicateCharacters(inputValue)) ? 'not-allowed' : 'pointer',
  }}
>
  {isLocked ? 'Editar' : 'Salvar'}
</button>
</td>
                  <td>
                      <ActionPixTable>
                      <CopyToClipboard text={inputValue}
                        onCopy={() =>
                          setOpenNotification({
                            type: 'success',
                            title: 'Senha copiada',
                            notification: 'Senha copiada com sucesso.'
                          })
                        }>
                          <DuplicateIcon width={21} color="var(--cian)"  className="cursor-pointer"/>
                      </CopyToClipboard>
                      </ActionPixTable> 
                    </td>
                  <td>{identificarTipoString(inputValue)}</td>
                  <td style={{ color: identificarTipoString(inputValue) === 'Formato não reconhecido' ? 'red' : (isLocked ? 'green' : 'red') }}>
  {identificarTipoString(inputValue) === 'Formato não reconhecido' ? 'AGUARDANDO' : (isLocked ? 'CONCLUÍDO' : 'AGUARDANDO')}
</td>
               </tr>
              ))}
              </tbody>            
            </table>
          </TableWrapper>
        </Table>
      </Content>
     
    </Container>
  );
};


import React, { FC, useEffect, useState } from "react";
import { Container, Content, ContentWrapper, Table, Text } from "./styles";
import { api } from "src/services/api";
import { useNavigate } from "react-router-dom";
import { useNotification } from "src/hooks/notification";

interface FormValues {
  name: string;
  taxes: {
    debit: number;
    credit_amount: number;
    credit_2x: number;
    credit_3x: number;
    credit_4x: number;
    credit_5x: number;
    credit_6x: number;
    credit_7x: number;
    credit_8x: number;
    credit_9x: number;
    credit_10x: number;
    credit_11x: number;
    credit_12x: number;
    credit_13x: number;
    credit_14x: number;
    credit_15x: number;
    credit_16x: number;
    credit_17x: number;
    credit_18x: number;
    boleto: number;
    pix: number;
    ted: number;
    doc: number;
    other: number;
  };
}

export const FormConfigTax: React.FC = () => {
  const token = localStorage.getItem("@backoffice:token");
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setError("");
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [error]);

  const handleCreatePlan = async () => {
    if (name.length < 4) return setError("Nome muito curto");

    try {
      await api.post(
        "backoffice/pag-plans",
        { name },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      navigate("/backoffice/config/tax");
    } catch (error) {
      console.error("Erro ao criar plano:", error);
    }
  };

  return (
    <Container className="flex justify-center items-center h-screen">
      <Content className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <ContentWrapper>
          <Text className="text-gray-700 text-center text-2xl font-bold mb-8">
            Criação de tarifas
          </Text>
          <Table className="min-w-[400px]">
            <form>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Nome do Plano:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <span className="text-sm font-semibold text-red-600">
                  {error}
                </span>
              </div>

              <button
                type="button"
                onClick={handleCreatePlan}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Criar Plano
              </button>
            </form>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

import { FC } from "react";
import { GradientButton } from "../newCreateAccountWallet/components";
import { useNavigate } from "react-router-dom";

import VilapayLogoPNG from "../../assets/login-logo.png";

import "./styles.css";

export const UpgradeSuccess: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-[86vw] h-[90vh] flex items-center upgrade-background">
      <svg className="relative top-0 max-2xl:w-[78vw]" width="85vw" height="90vh">
        <defs>
          <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#2E2E2E" />
            <stop offset="25%" stopColor="#FFFFFF" />
            <stop offset="75%" stopColor="#274683" />
          </linearGradient>
        </defs>

        <rect
          className="max-2xl:w-[76vw]"
          x="10"
          y="10"
          width="83vw"
          height="85vh"
          rx="10"
          ry="10"
          fill="black"
          fillOpacity="0.4"
          stroke="url(#borderGradient)"
          strokeWidth="1"
        />
      </svg>

      <div className="absolute top-[20vh] w-[83vw] flex flex-col items-center justify-center max-2xl:top-[15vh]">
        <img className="max-2xl:w-[15%]" width={300} src={VilapayLogoPNG} alt="" />

        <h2 className="text-white text-4xl text-center font-semibold mt-4 max-2xl:text-xl">
          Tudo <strong className="text-[#4BBD9C] text-4xl max-2xl:text-xl text-center font-semibold">certo</strong> com
          as <br />
          solicitação!
        </h2>
        <p className="text-white text-3xl text-center font-extralight mt-8 max-2xl:text-base max-2xl:mt-2">
          Sua solicitação está em análise, em até 72h <br />
          úteis você receberá um e-mail informando <br />
          sobre a confirmação do Upgrade{" "}
          <strong className="text-white text-3xl font-extrabold max-2xl:text-base">PIXWAVE.</strong>
        </p>

        <p className="text-white text-3xl text-center font-extralight mt-3 mb-8 max-2xl:text-base">
          Enquanto isso continue usando a{" "}
          <strong className="text-white text-3xl font-extrabold max-2xl:text-base">sua</strong> <br />
          <strong className="text-white text-3xl font-extrabold max-2xl:text-base">Wallet</strong> Normalmente.
        </p>

        <GradientButton
          tailwindStyles="bg-[#274683] w-[25rem] h-16 text-white text-lg max-2xl:h-14"
          title="Continuar"
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
};

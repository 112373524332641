import { format } from "date-fns";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import LogoImage from "../../src/assets/clients/vilapaybank/logo/logo_dash.png";
import { currencyFormat } from "./currencyFormat";
import { formatTransactionType } from "./formatTransactionType";

interface Transaction {
  amount: number;
  created_at: Date;
  direction: string;
  description: string;
  type: string;
  id: string;
  status: string;
}

const createNewPDF = () => {
  const pdf = new jsPDF("p", "mm", "a4");
  return pdf;
};

const build = (item: Transaction) => {
  const amount = currencyFormat(item.amount);
  const negative = item.direction === "out" ? "-" : "";
  const formatAmount = `${negative}${amount}`;
  const created_at = format(new Date(item.created_at), "dd/MM/yyyy");

  return { ...item, created_at, amount: formatAmount };
};

export const extractEntriesExitPDF = (
  transactions: any,
  days: number,
  type: string,
  user: any
) => {
  const pdf = createNewPDF();
  console.log(transactions, days);

  const marginLeft = 10;
  const marginTop = 10;
  const marginRight = 10;
  const marginBottom = 10;

  const usableWidth =
    pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
  const usableHeight =
    pdf.internal.pageSize.getHeight() - marginTop - marginBottom;

  pdf.setFont("Helvetica", "bold");
  pdf.setTextColor(0, 0, 0);

  const imgWidth = 100;
  const imgHeight = 30;
  const imgY = marginTop;
  const imgX = (usableWidth - imgWidth) / 2 + marginLeft;

  pdf.addImage(LogoImage, "PNG", imgX, imgY, imgWidth, imgHeight);

  pdf.setFontSize(12);
  pdf.text(` Extrato de ${type} dos Últimos ${days} Dias`, 15, 50, {
    align: "justify",
  });

  autoTable(pdf, {
    html: "#table-extract",
    margin: { top: 45 },
  });

  const transactionsFormatted = [];
  for (let i = 0; i < transactions.length; i++) {
    const item = transactions[i];
    const transaction = build(item) as any;

    const type = formatTransactionType(item, user);

    transactionsFormatted.push([
      transaction.created_at,
      transaction.direction === "in"
        ? type
        : transaction.description || "Sem descrição",
      transaction.amount,
      "Conluído",
    ]);
  }

  autoTable(pdf, {
    head: [["Data", "Descrição", "Valor", "Status"]],
    body: transactionsFormatted,
  });

  pdf.save("Extrato.pdf");
};

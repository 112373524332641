import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthBackoffice } from "../../../hooks/authBackoffice";
import icon from "./../../../assets/clients/pixwave/logo/icon.png";

export function SignIn() {
  const navigate = useNavigate();
  const { onSignIn } = useAuthBackoffice();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function handleForm(e: FormEvent) {
    e.preventDefault();

    try {
      let dataIsValid = true;
      if (!(email.trim().length > 0 || password.trim().length > 0)) {
        dataIsValid = false;
      }

      if (!dataIsValid) {
        alert("Deu bleibleide");
      }

      await onSignIn({ email, password });
      localStorage.setItem("@backofficev2:token", "fakeToken");
      navigate("/backoffice");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-5 text-center text-3xl font-bold tracking-tight text-gray-900">
            Bem-vindo ao Backoffice
          </h2>
          <div className="flex justify-center mt-12 sm:mx-auto sm:w-full sm:max-w-md">
            <div
              style={{
                width: "180.7px",
                height: "78.7px",
                backgroundImage: `url(${icon})`,
                backgroundSize: "400px 180px",
                backgroundPosition: "center",
                marginBottom: "25px",
                marginTop: "-31px",
                marginLeft: "50px",
              }}
            ></div>
          </div>
          <div style={{ marginBottom: "-15px" }}>
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
              <span style={{ color: '#00cbda' }}>Pixwave</span>
            </h2>
          </div>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleForm}>
              <div>
                <label
                  htmlFor="document"
                  className="block text-sm font-medium text-gray-700"
                >
                    Admin              </label>
                <div className="mt-1">
                  <input
                    id="document"
                    name="document"
                    type="text"
                    required
                    value={email}
                    onChange={(input) => setEmail(input.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Senha
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(input) => setPassword(input.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-400 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2"
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
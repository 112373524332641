const banksRaw = [
  {
    code: 1,
    name: "Banco do Brasil S.A.",
    description: "Banco do Brasil S.A.",
  },
  {
    code: 341,
    name: "Itau Unibanco S.A",
    description: "Itaú Unibanco S.A.",
  },
  {
    code: 237,
    name: "Banco Bradesco S.A.",
    description: "Banco Bradesco S.A.",
  },
  {
    code: 33,
    name: "Banco Santander (Brasil) S.A.",
    description: "Banco Santander (Brasil) S.A.",
  },
  {
    code: 104,
    name: "Caixa Econômica Federal",
    description: "Caixa Econômica Federal",
  },
  {
    code: 260,
    name: "Nu Pagamentos S.A",
    description: "Nu Pagamentos S.A",
  },
  {
    code: 77,
    name: "Banco Inter S.A.",
    description: "Banco Inter S.A.",
  },
  {
    code: 336,
    name: "Banco C6 S.A.",
    description: "Banco C6 S.A .",
  },
  {
    code: 756,
    name: "Banco Cooperativo do Brasil S.A. – Bancoob",
    description: "Banco Cooperativo do Brasil S.A. – Bancoob",
  },
  {
    code: 3,
    name: "Banco da Amazônia S.A.",
    description: "Banco da Amazônia S.A.",
  },
  {
    code: 4,
    name: "Banco do Nordeste do Brasil S.A.",
    description: "Banco do Nordeste do Brasil S.A.",
  },
  {
    code: 7,
    name: "Banco Nacional de Desenvolvimento Econômico e Social – BNDES",
    description: "Banco Nacional de Desenvolvimento Econômico e Social – BNDES",
  },
  {
    code: 10,
    name: "Credicoamo Crédito Rural Cooperativa",
    description: "Credicoamo Crédito Rural Cooperativa",
  },
  {
    code: 11,
    name: "Credit Suisse Hedging-Griffo Corretora de Valores S.A.",
    description: "Credit Suisse Hedging-Griffo Corretora de Valores S.A.",
  },
  {
    code: 12,
    name: "Banco Inbursa S.A.",
    description: "Banco Inbursa S.A.",
  },
  {
    code: 14,
    name: "Natixis Brasil S.A. Banco Múltiplo",
    description: "Natixis Brasil S.A. Banco Múltiplo",
  },
  {
    code: 15,
    name: "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
    description:
      "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
  },
  {
    code: 16,
    name: "Cooperativa de Crédito Mútuo dos Despachantes de Trânsito de Sta.Catarina e RS",
    description:
      "Cooperativa de Crédito Mútuo dos Despachantes de Trânsito de Sta.Catarina e RS",
  },
  {
    code: 17,
    name: "BNY Mellon Banco S.A.",
    description: "BNY Mellon Banco S.A.",
  },
  {
    code: 18,
    name: "Banco Tricury S.A.",
    description: "Banco Tricury S.A.",
  },
  {
    code: 21,
    name: "Banco do Estado do Espírito Santo – Baneste S.A.",
    description: "Banco do Estado do Espírito Santo – Baneste S.A.",
  },
  {
    code: 24,
    name: "Banco Bandepe S.A.",
    description: "Banco Bandepe S.A.",
  },
  {
    code: 25,
    name: "Banco Alfa S.A.",
    description: "Banco Alfa S.A.",
  },
  {
    code: 29,
    name: "Banco Itau Consignado S.A.",
    description: "Banco Itau Consignado S.A.",
  },
  {
    code: 37,
    name: "Banco do Estado do Pará S.A.",
    description: "Banco do Estado do Pará S.A.",
  },
  {
    code: 40,
    name: "Banco Cargill S.A.",
    description: "Banco Cargill S.A.",
  },
  {
    code: 41,
    name: "Banco do Estado do Rio Grande do Sul S.A.",
    description: "Banco do Estado do Rio Grande do Sul S.A.",
  },
  {
    code: 60,
    name: "Confidence Corretora de Câmbio S.A.",
    description: "Confidence Corretora de Câmbio S.A.",
  },
  {
    code: 62,
    name: "Hipercard Banco Múltiplo S.A.",
    description: "Hipercard Banco Múltiplo S.A.",
  },
  {
    code: 63,
    name: "Banco Bradescard S.A.",
    description: "Banco Bradescard S.A.",
  },
  {
    code: 64,
    name: "Goldman Sachs do Brasil Banco Múltiplo S.A.",
    description: "Goldman Sachs do Brasil Banco Múltiplo S.A.",
  },
  {
    code: 65,
    name: "Banco Andbank (Brasil) S.A.",
    description: "Banco Andbank (Brasil) S.A.",
  },
  {
    code: 66,
    name: "Banco Morgan Stanley S.A.",
    description: "Banco Morgan Stanley S.A.",
  },
  {
    code: 69,
    name: "Banco Crefisa S.A.",
    description: "Banco Crefisa S.A.",
  },
  {
    code: 70,
    name: "Banco de Brasília S.A. (BRB)",
    description: "Banco de Brasília S.A. (BRB)",
  },
  {
    code: 74,
    name: "Banco J. Safra S.A.",
    description: "Banco J. Safra S.A.",
  },
  {
    code: 75,
    name: "Banco ABN Amro S.A.",
    description: "Banco ABN Amro S.A.",
  },
  {
    code: 76,
    name: "Banco KDB S.A.",
    description: "Banco KDB S.A.",
  },
  {
    code: 78,
    name: "Haitong Banco de Investimento do Brasil S.A.",
    description: "Haitong Banco de Investimento do Brasil S.A.",
  },
  {
    code: 79,
    name: "Banco Original do Agronegócio S.A.",
    description: "Banco Original do Agronegócio S.A.",
  },
  {
    code: 80,
    name: "BT Corretora de Câmbio Ltda.",
    description: "BT Corretora de Câmbio Ltda.",
  },
  {
    code: 81,
    name: "Banco Brasileiro de Negócios S.A. – BBN",
    description: "Banco Brasileiro de Negócios S.A. – BBN",
  },
  {
    code: 82,
    name: "Banco Topázio S.A.",
    description: "Banco Topázio S.A.",
  },
  {
    code: 83,
    name: "Banco da China Brasil S.A.",
    description: "Banco da China Brasil S.A.",
  },
  {
    code: 84,
    name: "Uniprime Norte do Paraná – Cooperativa de Crédito Ltda.",
    description: "Uniprime Norte do Paraná – Cooperativa de Crédito Ltda.",
  },
  {
    code: 85,
    name: "Cooperativa Central de Crédito Urbano – Cecred",
    description: "Cooperativa Central de Crédito Urbano – Cecred",
  },
  {
    code: 88,
    name: "Banco Randon S.A.",
    description: "Banco Randon S.A.",
  },
  {
    code: 89,
    name: "Cooperativa de Crédito Rural da Região da Mogiana",
    description: "Cooperativa de Crédito Rural da Região da Mogiana",
  },
  {
    code: 91,
    name: "Central de Cooperativas de Economia e Crédito Mútuo do Estado RS – Unicred",
    description:
      "Central de Cooperativas de Economia e Crédito Mútuo do Estado RS – Unicred",
  },
  {
    code: 92,
    name: "Brickell (BRK) S.A. Crédito, Financiamento e Investimento",
    description: "Brickell (BRK) S.A. Crédito, Financiamento e Investimento",
  },
  {
    code: 93,
    name: "Pólocred Sociedade de Crédito ao Microempreendedor e Empresa de Pequeno Porte",
    description:
      "Pólocred Sociedade de Crédito ao Microempreendedor e Empresa de Pequeno Porte",
  },
  {
    code: 94,
    name: "Banco Finaxis S.A.",
    description: "Banco Finaxis S.A.",
  },
  {
    code: 95,
    name: "Banco Confidence de Câmbio S.A.",
    description: "Banco Confidence de Câmbio S.A.",
  },
  {
    code: 96,
    name: "Banco BM&FBovespa",
    description: "Banco BM&FBovespa",
  },
  {
    code: 97,
    name: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda. (CentralCredi)",
    description:
      "Cooperativa Central de Crédito Noroeste Brasileiro Ltda. (CentralCredi)",
  },
  {
    code: 99,
    name: "Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.",
    description:
      "Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.",
  },
  {
    code: 100,
    name: "Planner Corretora de Valores S.A.",
    description: "Planner Corretora de Valores S.A.",
  },
  {
    code: 101,
    name: "Renascença Distribuidora de Títulos e Valores Mobiliários Ltda.",
    description:
      "Renascença Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: 102,
    name: "XP Investimentos Corretora de Câmbio, Títulos e Valores Mobiliários S/A",
    description:
      "XP Investimentos Corretora de Câmbio, Títulos e Valores Mobiliários S/A",
  },
  {
    code: 105,
    name: "Lecca Crédito, Financiamento e Investimento S/A",
    description: "Lecca Crédito, Financiamento e Investimento S/A",
  },
  {
    code: 107,
    name: "Banco Bocom BBM S.A.",
    description: "Banco Bocom BBM S.A.",
  },
  {
    code: 108,
    name: "PortoCred S.A. Crédito, Financiamento e Investimento",
    description: "PortoCred S.A. Crédito, Financiamento e Investimento",
  },
  {
    code: 111,
    name: "Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
    description:
      "Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    code: 113,
    name: "Magliano S.A. Corretora de Cambio e Valores Mobiliarios",
    description: "Magliano S.A. Corretora de Cambio e Valores Mobiliarios",
  },
  {
    code: 114,
    name: "Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP",
    description:
      "Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP",
  },
  {
    code: 117,
    name: "Advanced Corretora de Câmbio Ltda.",
    description: "Advanced Corretora de Câmbio Ltda.",
  },
  {
    code: 118,
    name: "Standard Chartered Bank (Brasil) S.A. Banco de Investimento",
    description: "Standard Chartered Bank (Brasil) S.A. Banco de Investimento",
  },
  {
    code: 119,
    name: "Banco Western Union do Brasil S.A.",
    description: "Banco Western Union do Brasil S.A.",
  },
  {
    code: 120,
    name: "Banco Rodobens S.A.",
    description: "Banco Rodobens S.A.",
  },
  {
    code: 121,
    name: "Banco Agibank S.A.",
    description: "Banco Agibank S.A.",
  },
  {
    code: 122,
    name: "Banco Bradesco BERJ S.A.",
    description: "Banco Bradesco BERJ S.A.",
  },
  {
    code: 124,
    name: "Banco Woori Bank do Brasil S.A.",
    description: "Banco Woori Bank do Brasil S.A.",
  },
  {
    code: 125,
    name: "Banco Brasil Plural S.A. – Banco Múltiplo",
    description: "Banco Brasil Plural S.A. – Banco Múltiplo",
  },
  {
    code: 126,
    name: "BR Partners Banco de Investimento S.A.",
    description: "BR Partners Banco de Investimento S.A.",
  },
  {
    code: 127,
    name: "Codepe Corretora de Valores e Câmbio S.A.",
    description: "Codepe Corretora de Valores e Câmbio S.A.",
  },
  {
    code: 128,
    name: "MS Bank S.A. Banco de Câmbio",
    description: "MS Bank S.A. Banco de Câmbio",
  },
  {
    code: 129,
    name: "UBS Brasil Banco de Investimento S.A.",
    description: "UBS Brasil Banco de Investimento S.A.",
  },
  {
    code: 130,
    name: "Caruana S.A. Sociedade de Crédito, Financiamento e Investimento",
    description:
      "Caruana S.A. Sociedade de Crédito, Financiamento e Investimento",
  },
  {
    code: 131,
    name: "Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.",
    description: "Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.",
  },
  {
    code: 132,
    name: "ICBC do Brasil Banco Múltiplo S.A.",
    description: "ICBC do Brasil Banco Múltiplo S.A.",
  },
  {
    code: 133,
    name: "Cresol – Confederação Nacional Cooperativas Centrais de Crédito e Econ Familiar",
    description:
      "Cresol – Confederação Nacional Cooperativas Centrais de Crédito e Econ Familiar",
  },
  {
    code: 134,
    name: "BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda.",
    description:
      "BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: 135,
    name: "Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
    description:
      "Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
  },
  {
    code: 136,
    name: "Confederação Nacional das Cooperativas Centrais Unicred Ltda (Unicred do Brasil)",
    description:
      "Confederação Nacional das Cooperativas Centrais Unicred Ltda (Unicred do Brasil)",
  },
  {
    code: 137,
    name: "Multimoney Corretora de Câmbio Ltda",
    description: "Multimoney Corretora de Câmbio Ltda",
  },
  {
    code: 138,
    name: "Get Money Corretora de Câmbio S.A.",
    description: "Get Money Corretora de Câmbio S.A.",
  },
  {
    code: 139,
    name: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo",
    description: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo",
  },
  {
    code: 140,
    name: "Easynvest – Título Corretora de Valores SA",
    description: "Easynvest – Título Corretora de Valores SA",
  },
  {
    code: 142,
    name: "Broker Brasil Corretora de Câmbio Ltda.",
    description: "Broker Brasil Corretora de Câmbio Ltda.",
  },
  {
    code: 143,
    name: "Treviso Corretora de Câmbio S.A.",
    description: "Treviso Corretora de Câmbio S.A.",
  },
  {
    code: 144,
    name: "Bexs Banco de Câmbio S.A.",
    description: "Bexs Banco de Câmbio S.A.",
  },
  {
    code: 145,
    name: "Levycam – Corretora de Câmbio e Valores Ltda.",
    description: "Levycam – Corretora de Câmbio e Valores Ltda.",
  },
  {
    code: 146,
    name: "Guitta Corretora de Câmbio Ltda.",
    description: "Guitta Corretora de Câmbio Ltda.",
  },
  {
    code: 149,
    name: "Facta Financeira S.A. – Crédito Financiamento e Investimento",
    description: "Easynvest – Título Corretora de Valores SA",
  },
  {
    code: 157,
    name: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.",
    description: "Broker Brasil Corretora de Câmbio Ltda.",
  },
  {
    code: 159,
    name: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
    description:
      "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
  },
  {
    code: 163,
    name: "Commerzbank Brasil S.A. – Banco Múltiplo",
    description: "Commerzbank Brasil S.A. – Banco Múltiplo",
  },
  {
    code: 169,
    name: "Banco Olé Bonsucesso Consignado S.A.",
    description: "Banco Olé Bonsucesso Consignado S.A.",
  },
  {
    code: 172,
    name: "Albatross Corretora de Câmbio e Valores S.A ",
    description: "Albatross Corretora de Câmbio e Valores S.A ",
  },
  {
    code: 173,
    name: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
    description:
      "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    code: 174,
    name: "Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento",
    description:
      "Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento",
  },
  {
    code: 177,
    name: "Guide Investimentos S.A. Corretora de Valores",
    description: "Guide Investimentos S.A. Corretora de Valores",
  },
  {
    code: 182,
    name: "Dacasa Financeira S/A – Sociedade de Crédito, Financiamento e Investimento ",
    description:
      "Dacasa Financeira S/A – Sociedade de Crédito, Financiamento e Investimento ",
  },
  {
    code: 183,
    name: "Socred S.A. – Sociedade de Crédito ao Microempreendedor",
    description: "Socred S.A. – Sociedade de Crédito ao Microempreendedor",
  },
  {
    code: 184,
    name: "Banco Itau BBA S.A.",
    description: "Banco Itau BBA S.A.",
  },
  {
    code: 188,
    name: "Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores",
    description:
      "Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores",
  },
  {
    code: 189,
    name: "HS Financeira S/A Crédito, Financiamento e Investimentos",
    description: "HS Financeira S/A Crédito, Financiamento e Investimentos",
  },
  {
    code: 190,
    name: "Servicoop – Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio ",
    description:
      "Servicoop – Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio",
  },
  {
    code: 191,
    name: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
    description: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: 194,
    name: "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.",
    description:
      "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: 196,
    name: "Fair Corretora de Câmbio S.A.",
    description: "Fair Corretora de Câmbio S.A.",
  },
  {
    code: 197,
    name: "Stone Pagamentos S.A.",
    description: "Stone Pagamentos S.A.",
  },
  {
    code: 204,
    name: "Banco Bradesco Cartões S.A.",
    description: "Banco Bradesco Cartões S.A.",
  },
  {
    code: 208,
    name: "Banco BTG Pactual S.A.",
    description: "Banco BTG Pactual S.A.",
  },
  {
    code: 212,
    name: "Banco Original S.A.",
    description: "Banco Original S.A.",
  },
  {
    code: 213,
    name: "Banco Arbi S.A.",
    description: "Banco Arbi S.A.",
  },
  {
    code: 217,
    name: "Banco John Deere S.A.",
    description: "Banco John Deere S.A.",
  },
  {
    code: 218,
    name: "Banco BS2 S.A.",
    description: "Banco BS2 S.A.",
  },
  {
    code: 222,
    name: "Banco Credit Agricole Brasil S.A.",
    description: "Banco Credit Agricole Brasil S.A.",
  },
  {
    code: 224,
    name: "Banco Fibra S.A.",
    description: "Banco Fibra S.A.",
  },
  {
    code: 233,
    name: "Banco Cifra S.A.",
    description: "Banco Cifra S.A.",
  },
  {
    code: 241,
    name: "Banco Clássico S.A.",
    description: "Banco Clássico S.A.",
  },
  {
    code: 243,
    name: "Banco Máxima S.A.",
    description: "Banco Máxima S.A.",
  },
  {
    code: 246,
    name: "Banco ABC Brasil S.A.",
    description: "Banco ABC Brasil S.A.",
  },
  {
    code: 248,
    name: "Banco Boavista Interatlântico S.A.",
    description: "Banco Boavista Interatlântico S.A.",
  },
  {
    code: 249,
    name: "Banco Investcred Unibanco S.A.",
    description: "Banco Investcred Unibanco S.A.",
  },
  {
    code: 250,
    name: "Banco de Crédito e Varejo S.A. – BCV",
    description: "Banco de Crédito e Varejo S.A. – BCV",
  },
  {
    code: 253,
    name: "Bexs Corretora de Câmbio S.A.",
    description: "Bexs Corretora de Câmbio S.A.",
  },
  {
    code: 254,
    name: "Paraná Banco S.A.",
    description: "Paraná Banco S.A.",
  },
  {
    code: 265,
    name: "Banco Fator S.A.",
    description: "Banco Fator S.A.",
  },
  {
    code: 266,
    name: "Banco Cédula S.A.",
    description: "Banco Cédula S.A.",
  },
  {
    code: 320,
    name: "China Construction Bank (Brasil) Banco Múltiplo S.A.",
    description: "China Construction Bank (Brasil) Banco Múltiplo S.A.",
  },
  {
    code: 366,
    name: "Banco Société Générale Brasil S.A.",
    description: "Banco Société Générale Brasil S.A.",
  },
  {
    code: 370,
    name: "Banco Mizuho do Brasil S.A.",
    description: "Banco Mizuho do Brasil S.A.",
  },
  {
    code: 376,
    name: "Banco J. P. Morgan S.A.",
    description: "Banco J. P. Morgan S.A.",
  },
  {
    code: 394,
    name: "Banco Bradesco Financiamentos S.A.",
    description: "Banco Bradesco Financiamentos S.A.",
  },
  {
    code: 412,
    name: "Banco Capital S.A.",
    description: "Banco Capital S.A.",
  },
  {
    code: 456,
    name: "Banco MUFG Brasil S.A.",
    description: "Banco MUFG Brasil S.A.",
  },
  {
    code: 464,
    name: "Banco Sumitomo Mitsui Brasileiro S.A.",
    description: "Banco Sumitomo Mitsui Brasileiro S.A.",
  },
  {
    code: 473,
    name: "Banco Caixa Geral – Brasil S.A.",
    description: "Banco Caixa Geral – Brasil S.A.",
  },
  {
    code: 477,
    name: "Citibank N.A.",
    description: "Citibank N.A.",
  },
  {
    code: 479,
    name: "Banco ItauBank S.A",
    description: "Banco ItauBank S.A",
  },
  {
    code: 487,
    name: "Deutsche Bank S.A. – Banco Alemão",
    description: "Deutsche Bank S.A. – Banco Alemão",
  },
  {
    code: 488,
    name: "JPMorgan Chase Bank, National Association",
    description: "JPMorgan Chase Bank, National Association",
  },
  {
    code: 492,
    name: "ING Bank N.V.",
    description: "ING Bank N.V.",
  },
  {
    code: 494,
    name: "Banco de La Republica Oriental del Uruguay",
    description: "Banco de La Republica Oriental del Uruguay",
  },
  {
    code: 495,
    name: "Banco de La Provincia de Buenos Aires",
    description: "Banco de La Provincia de Buenos Aires",
  },
  {
    code: 505,
    name: "Banco Credit Suisse (Brasil) S.A.",
    description: "Banco Credit Suisse (Brasil) S.A.",
  },
  {
    code: 545,
    name: "Senso Corretora de Câmbio e Valores Mobiliários S.A. ",
    description: "Senso Corretora de Câmbio e Valores Mobiliários S.A. ",
  },
  {
    code: 600,
    name: "Banco Luso Brasileiro S.A.",
    description: "Banco Luso Brasileiro S.A.",
  },
  {
    code: 604,
    name: "Banco Industrial do Brasil S.A.",
    description: "Banco Industrial do Brasil S.A.",
  },
  {
    code: 610,
    name: "Banco VR S.A.",
    description: "Banco VR S.A.",
  },
  {
    code: 611,
    name: "Banco Paulista S.A.",
    description: "Banco Paulista S.A.",
  },
  {
    code: 612,
    name: "Banco Guanabara S.A.",
    description: "Banco Guanabara S.A.",
  },
  {
    code: 613,
    name: "Omni Banco S.A. ",
    description: "Omni Banco S.A. ",
  },
  {
    code: 623,
    name: "Banco Pan S.A.",
    description: "Banco Pan S.A.",
  },
  {
    code: 626,
    name: "Banco Ficsa S.A.",
    description: "Banco Ficsa S.A.",
  },
  {
    code: 630,
    name: "Banco Intercap S.A.",
    description: "Banco Intercap S.A.",
  },
  {
    code: 633,
    name: "Banco Rendimento S.A.",
    description: "Banco Rendimento S.A.",
  },
  {
    code: 634,
    name: "Banco Triângulo S.A.",
    description: "Banco Triângulo S.A.",
  },
  {
    code: 637,
    name: "Banco Sofisa S.A.",
    description: "Banco Sofisa S.A.",
  },
  {
    code: 641,
    name: "Banco Alvorada S.A.",
    description: "Banco Alvorada S.A.",
  },
  {
    code: 643,
    name: "Banco Pine S.A.",
    description: "Banco Pine S.A.",
  },
  {
    code: 652,
    name: "Banco Itau Unibanco Holding S.A.",
    description: "Banco Itau Unibanco Holding S.A.",
  },
  {
    code: 653,
    name: "Banco Indusval S.A.",
    description: "Banco Indusval S.A.",
  },
  {
    code: 654,
    name: "Banco A.J. Renner S.A.",
    description: "Banco A.J. Renner S.A.",
  },
  {
    code: 655,
    name: "Banco Votorantim S.A.",
    description: "Banco Votorantim S.A.",
  },
  {
    code: 707,
    name: "Banco Daycoval S.A.",
    description: "Banco Daycoval S.A.",
  },
  {
    code: 712,
    name: "Banco Ourinvest S.A.",
    description: "Banco Ourinvest S.A.",
  },
  {
    code: 719,
    name: "Banco Internacional do Funchal (Brasil) S.A. – Banif",
    description: "Banco Internacional do Funchal (Brasil) S.A. – Banif",
  },
  {
    code: 735,
    name: "Banco Neon S.A.",
    description: "Banco Pine S.A.",
  },
  {
    code: 739,
    name: "Banco Cetelem S.A.",
    description: "Banco Cetelem S.A.",
  },
  {
    code: 740,
    name: "Banco Barclays S.A.",
    description: "Banco Barclays S.A.",
  },
  {
    code: 268,
    name: "Barigui Companhia Hipotecária",
    description: "Barigui Companhia Hipotecária",
  },
  {
    code: 269,
    name: "HSBC Brasil S.A. Banco de Investimento",
    description: "HSBC Brasil S.A. Banco de Investimento",
  },
  {
    code: 271,
    name: "IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.",
    description: "IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: 300,
    name: "Banco de La Nacion Argentina",
    description: "Banco de La Nacion Argentina",
  },
  {
    code: 318,
    name: "Banco BMG S.A.",
    description: "Banco BMG S.A.",
  },
  {
    code: 399,
    name: "HSBC Bank Brasil S.A. - Banco Múltiplo",
    description: "HSBC Bank Brasil S.A. - Banco Múltiplo",
  },
  {
    code: 745,
    name: "Banco Citibank S.A.",
    description: "Banco Citibank S.A.",
  },
  {
    code: 47,
    name: "Banco do Estado de Sergipe S.A.",
    description: "Banco do Estado de Sergipe S.A.",
  },
  {
    code: 389,
    name: "Banco Mercantil do Brasil S.A.",
    description: "Banco Mercantil do Brasil S.A.",
  },
  {
    code: 422,
    name: "Banco Safra S.A.",
    description: "Banco Safra S.A.",
  },
  {
    code: 748,
    name: "Banco Cooperativo Sicredi S.A.",
    description: "Banco Cooperativo Sicredi S.A.",
  },
  {
    code: 741,
    name: "Banco Ribeirão Preto S.A.",
    description: "Banco Ribeirão Preto S.A.",
  },
  {
    code: 743,
    name: "Banco Semear S.A.",
    description: "Banco Semear S.A.",
  },
  {
    code: 746,
    name: "Banco Modal S.A.",
    description: "Banco Modal S.A.",
  },
  {
    code: 747,
    name: "Banco Rabobank International Brasil S.A.",
    description: "Banco Rabobank International Brasil S.A.",
  },
  {
    code: 751,
    name: "Scotiabank Brasil S.A. Banco Múltiplo",
    description: "Scotiabank Brasil S.A. Banco Múltiplo",
  },
  {
    code: 752,
    name: "Banco BNP Paribas Brasil S.A.",
    description: "Banco BNP Paribas Brasil S.A.",
  },
  {
    code: 753,
    name: "Novo Banco Continental S.A. – Banco Múltiplo",
    description: "Novo Banco Continental S.A. – Banco Múltiplo",
  },
  {
    code: 754,
    name: "Banco Sistema S.A.",
    description: "Banco Sistema S.A.",
  },
  {
    code: 755,
    name: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
    description: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
  },
  {
    code: 757,
    name: "Banco Keb Hana do Brasil S.A.",
    description: "Banco Keb Hana do Brasil S.A.",
  },
  {
    code: 336,
    name: "Banco C6 S.A.",
    description: "Banco C6 S.A .",
  },
  {
    code: 278,
    name: "Genial Investimentos Cvm S.A",
    description: "Genial Investimentos Cvm S.A",
  },
  {
    code: 403,
    name: "Cora Sociedade de Crédito Direto S.A.",
    description: "Cora Sociedade de Crédito Direto S.A.",
  },
  {
    code: 332,
    name: "Acesso Soluções de Pagamento S.A.",
    description: "Acesso Soluções de Pagamento S.A.",
  },
  {
    code: 323,
    name: "Mercado Pago – Conta Do Mercado Livre",
    description: "Mercado Pago – Conta Do Mercado Livre",
  },
  {
    code: 290,
    name: "PagSeguro Internet S.A.",
    description: "PagSeguro Internet S.A.",
  },
  {
    code: 380,
    name: "PICPAY SERVICOS S.A.",
    description: "PICPAY SERVICOS S.A.",
  },
  {
    code: 450,
    name: "Fitbank Instituicao De Pagamentos Eletronicos S.A.",
    description: "Fitbank Instituicao De Pagamentos Eletronicos S.A.",
  },
  {
    code: 37,
    name: "BANCO DO ESTADO DO PARA S.A",
    description: "BANCO DO ESTADO DO PARA S.A",
  },
];
//
//
//
//
//
export const banksISPBS = [
  {
    code: "001",
    ispb: "00000000",
    name: "Banco do Brasil S.A.",
  },
  {
    code: "070",
    ispb: "208",
    name: "BRB – BANCO DE BRASILIA S.A.",
  },
  {
    code: "n/a",
    ispb: "38121",
    name: "Banco Central do Brasil – Selic",
  },
  {
    code: "n/a",
    ispb: "38166",
    name: "Banco Central do Brasil",
  },
  {
    code: "272",
    ispb: "250699",
    name: "AGK CORRETORA DE CAMBIO S.A.",
  },
  {
    code: "136",
    ispb: "315557",
    name: "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. – UNICRED DO BRASI",
  },
  {
    code: "104",
    ispb: "360305",
    name: "CAIXA ECONOMICA FEDERAL",
  },
  {
    code: "n/a",
    ispb: "394460",
    name: "Secretaria do Tesouro Nacional – STN",
  },
  {
    code: "077",
    ispb: "416968",
    name: "Banco Inter S.A.",
  },
  {
    code: "741",
    ispb: "517645",
    name: "BANCO RIBEIRAO PRETO S.A.",
  },
  {
    code: "330",
    ispb: "556603",
    name: "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
  },
  {
    code: "739",
    ispb: "558456",
    name: "Banco Cetelem S.A.",
  },
  {
    code: "743",
    ispb: "795423",
    name: "Banco Semear S.A.",
  },
  {
    code: "100",
    ispb: "806535",
    name: "Planner Corretora de Valores S.A.",
  },
  {
    code: "096",
    ispb: "997185",
    name: "Banco B3 S.A.",
  },
  {
    code: "747",
    ispb: "1023570",
    name: "Banco Rabobank International Brasil S.A.",
  },
  {
    code: "362",
    ispb: "1027058",
    name: "CIELO S.A.",
  },
  {
    code: "322",
    ispb: "1073966",
    name: "Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz",
  },
  {
    code: "748",
    ispb: "1181521",
    name: "BANCO COOPERATIVO SICREDI S.A.",
  },
  {
    code: "350",
    ispb: "1330387",
    name: "COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE",
  },
  {
    code: "752",
    ispb: "1522368",
    name: "Banco BNP Paribas Brasil S.A.",
  },
  {
    code: "091",
    ispb: "1634601",
    name: "CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S",
  },
  {
    code: "379",
    ispb: "1658426",
    name: "COOPERFORTE – COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONÁRIOS DE INSTIT",
  },
  {
    code: "399",
    ispb: "1701201",
    name: "Kirton Bank S.A. – Banco Múltiplo",
  },
  {
    code: "108",
    ispb: "1800019",
    name: "PORTOCRED S.A. – CREDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    code: "378",
    ispb: "1852137",
    name: "BBC LEASING S.A. – ARRENDAMENTO MERCANTIL",
  },
  {
    code: "756",
    ispb: "2038232",
    name: "BANCO COOPERATIVO DO BRASIL S.A. – BANCOOB",
  },
  {
    code: "360",
    ispb: "2276653",
    name: "TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  {
    code: "757",
    ispb: "2318507",
    name: "BANCO KEB HANA DO BRASIL S.A.",
  },
  {
    code: "102",
    ispb: "2332886",
    name: "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A",
  },
  {
    code: "084",
    ispb: "2398976",
    name: "UNIPRIME NORTE DO PARANÁ – COOPERATIVA DE CRÉDITO LTDA",
  },
  {
    code: "180",
    ispb: "2685483",
    name: "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    code: "066",
    ispb: "2801938",
    name: "BANCO MORGAN STANLEY S.A.",
  },
  {
    code: "015",
    ispb: "2819125",
    name: "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
  },
  {
    code: "143",
    ispb: "2992317",
    name: "Treviso Corretora de Câmbio S.A.",
  },
  {
    code: "n/a",
    ispb: "2992335",
    name: "Câmara Interbancária de Pagamentos – CIP – LDL",
  },
  {
    code: "062",
    ispb: "3012230",
    name: "Hipercard Banco Múltiplo S.A.",
  },
  {
    code: "074",
    ispb: "3017677",
    name: "Banco J. Safra S.A.",
  },
  {
    code: "099",
    ispb: "3046391",
    name: "UNIPRIME CENTRAL – CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.",
  },
  {
    code: "387",
    ispb: "3215790",
    name: "Banco Toyota do Brasil S.A.",
  },
  {
    code: "326",
    ispb: "3311443",
    name: "PARATI – CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    code: "025",
    ispb: "3323840",
    name: "Banco Alfa S.A.",
  },
  {
    code: "315",
    ispb: "3502968",
    name: "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    code: "075",
    ispb: "3532415",
    name: "Banco ABN Amro S.A.",
  },
  {
    code: "040",
    ispb: "3609817",
    name: "Banco Cargill S.A.",
  },
  {
    code: "307",
    ispb: "3751794",
    name: "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: "190",
    ispb: "3973814",
    name: "SERVICOOP – COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN",
  },
  {
    code: "296",
    ispb: "4062902",
    name: "VISION S.A. CORRETORA DE CAMBIO",
  },
  {
    code: "063",
    ispb: "4184779",
    name: "Banco Bradescard S.A.",
  },
  {
    code: "191",
    ispb: "4257795",
    name: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: "382",
    ispb: "4307598",
    name: "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L",
  },
  {
    code: "064",
    ispb: "4332281",
    name: "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.",
  },
  {
    code: "n/a",
    ispb: "4391007",
    name: "Câmara Interbancária de Pagamentos",
  },
  {
    code: "097",
    ispb: "4632856",
    name: "Credisis – Central de Cooperativas de Crédito Ltda.",
  },
  {
    code: "016",
    ispb: "4715685",
    name: "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
  },
  {
    code: "299",
    ispb: "4814563",
    name: "SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    code: "012",
    ispb: "4866275",
    name: "Banco Inbursa S.A.",
  },
  {
    code: "003",
    ispb: "4902979",
    name: "BANCO DA AMAZONIA S.A.",
  },
  {
    code: "060",
    ispb: "4913129",
    name: "Confidence Corretora de Câmbio S.A.",
  },
  {
    code: "037",
    ispb: "4913711",
    name: "Banco do Estado do Pará S.A.",
  },
  {
    code: "359",
    ispb: "5351887",
    name: "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A",
  },
  {
    code: "159",
    ispb: "5442029",
    name: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
  },
  {
    code: "085",
    ispb: "5463212",
    name: "Cooperativa Central de Crédito – Ailos",
  },
  {
    code: "114",
    ispb: "5790149",
    name: "Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP",
  },
  {
    code: "036",
    ispb: "6271464",
    name: "Banco Bradesco BBI S.A.",
  },
  {
    code: "394",
    ispb: "7207996",
    name: "Banco Bradesco Financiamentos S.A.",
  },
  {
    code: "004",
    ispb: "7237373",
    name: "Banco do Nordeste do Brasil S.A.",
  },
  {
    code: "320",
    ispb: "7450604",
    name: "China Construction Bank (Brasil) Banco Múltiplo S/A",
  },
  {
    code: "189",
    ispb: "7512441",
    name: "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS",
  },
  {
    code: "105",
    ispb: "7652226",
    name: "Lecca Crédito, Financiamento e Investimento S/A",
  },
  {
    code: "076",
    ispb: "7656500",
    name: "Banco KDB do Brasil S.A.",
  },
  {
    code: "082",
    ispb: "7679404",
    name: "BANCO TOPÁZIO S.A.",
  },
  {
    code: "286",
    ispb: "7853842",
    name: "COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO",
  },
  {
    code: "093",
    ispb: "7945233",
    name: "PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT",
  },
  {
    code: "391",
    ispb: "8240446",
    name: "COOPERATIVA DE CREDITO RURAL DE IBIAM – SULCREDI/IBIAM",
  },
  {
    code: "273",
    ispb: "8253539",
    name: "Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel",
  },
  {
    code: "368",
    ispb: "8357240",
    name: "Banco CSF S.A.",
  },
  {
    code: "290",
    ispb: "8561701",
    name: "Pagseguro Internet S.A.",
  },
  {
    code: "259",
    ispb: "8609934",
    name: "MONEYCORP BANCO DE CÂMBIO S.A.",
  },
  {
    code: "364",
    ispb: "9089356",
    name: "GERENCIANET S.A.",
  },
  {
    code: "157",
    ispb: "9105360",
    name: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: "183",
    ispb: "9210106",
    name: "SOCRED S.A. – SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P",
  },
  {
    code: "014",
    ispb: "9274232",
    name: "STATE STREET BRASIL S.A. ? BANCO COMERCIAL",
  },
  {
    code: "130",
    ispb: "9313766",
    name: "CARUANA S.A. – SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    code: "127",
    ispb: "9512542",
    name: "Codepe Corretora de Valores e Câmbio S.A.",
  },
  {
    code: "079",
    ispb: "9516419",
    name: "Banco Original do Agronegócio S.A.",
  },
  {
    code: "340",
    ispb: "9554480",
    name: "Super Pagamentos e Administração de Meios Eletrônicos S.A.",
  },
  {
    code: "081",
    ispb: "10264663",
    name: "BancoSeguro S.A.",
  },
  {
    code: "133",
    ispb: "10398952",
    name: "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E",
  },
  {
    code: "323",
    ispb: "10573521",
    name: "MERCADOPAGO.COM REPRESENTACOES LTDA.",
  },
  {
    code: "121",
    ispb: "10664513",
    name: "Banco Agibank S.A.",
  },
  {
    code: "083",
    ispb: "10690848",
    name: "Banco da China Brasil S.A.",
  },
  {
    code: "138",
    ispb: "10853017",
    name: "Get Money Corretora de Câmbio S.A.",
  },
  {
    code: "024",
    ispb: "10866788",
    name: "Banco Bandepe S.A.",
  },
  {
    code: "384",
    ispb: "11165756",
    name: "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO",
  },
  {
    code: "088",
    ispb: "11476673",
    name: "BANCO RANDON S.A.",
  },
  {
    code: "319",
    ispb: "11495073",
    name: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    code: "274",
    ispb: "11581339",
    name: "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
  },
  {
    code: "095",
    ispb: "11703662",
    name: "Travelex Banco de Câmbio S.A.",
  },
  {
    code: "094",
    ispb: "11758741",
    name: "Banco Finaxis S.A.",
  },
  {
    code: "276",
    ispb: "11970623",
    name: "Senff S.A. – Crédito, Financiamento e Investimento",
  },
  {
    code: "092",
    ispb: "12865507",
    name: "BRK S.A. Crédito, Financiamento e Investimento",
  },
  {
    code: "047",
    ispb: "13009717",
    name: "Banco do Estado de Sergipe S.A.",
  },
  {
    code: "144",
    ispb: "13059145",
    name: "BEXS BANCO DE CÂMBIO S/A",
  },
  {
    code: "332",
    ispb: "13140088",
    name: "Acesso Soluções de Pagamento S.A.",
  },
  {
    code: "126",
    ispb: "13220493",
    name: "BR Partners Banco de Investimento S.A.",
  },
  {
    code: "325",
    ispb: "13293225",
    name: "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    code: "301",
    ispb: "13370835",
    name: "BPP Instituição de Pagamento S.A.",
  },
  {
    code: "173",
    ispb: "13486793",
    name: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    code: "331",
    ispb: "13673855",
    name: "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    code: "119",
    ispb: "13720915",
    name: "Banco Western Union do Brasil S.A.",
  },
  {
    code: "396",
    ispb: "13884775",
    name: "HUB PAGAMENTOS S.A",
  },
  {
    code: "309",
    ispb: "14190547",
    name: "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
  },
  {
    code: "254",
    ispb: "14388334",
    name: "PARANÁ BANCO S.A.",
  },
  {
    code: "268",
    ispb: "14511781",
    name: "BARI COMPANHIA HIPOTECÁRIA",
  },
  {
    code: "107",
    ispb: "15114366",
    name: "Banco Bocom BBM S.A.",
  },
  {
    code: "412",
    ispb: "15173776",
    name: "BANCO CAPITAL S.A.",
  },
  {
    code: "124",
    ispb: "15357060",
    name: "Banco Woori Bank do Brasil S.A.",
  },
  {
    code: "149",
    ispb: "15581638",
    name: "Facta Financeira S.A. – Crédito Financiamento e Investimento",
  },
  {
    code: "197",
    ispb: "16501555",
    name: "Stone Pagamentos S.A.",
  },
  {
    code: "313",
    ispb: "16927221",
    name: "AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
  },
  {
    code: "142",
    ispb: "16944141",
    name: "Broker Brasil Corretora de Câmbio Ltda.",
  },
  {
    code: "389",
    ispb: "17184037",
    name: "Banco Mercantil do Brasil S.A.",
  },
  {
    code: "184",
    ispb: "17298092",
    name: "Banco Itaú BBA S.A.",
  },
  {
    code: "634",
    ispb: "17351180",
    name: "BANCO TRIANGULO S.A.",
  },
  {
    code: "545",
    ispb: "17352220",
    name: "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
  },
  {
    code: "132",
    ispb: "17453575",
    name: "ICBC do Brasil Banco Múltiplo S.A.",
  },
  {
    code: "298",
    ispb: "17772370",
    name: "Vip’s Corretora de Câmbio Ltda.",
  },
  {
    code: "377",
    ispb: "17826860",
    name: "MS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA",
  },
  {
    code: "321",
    ispb: "18188384",
    name: "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
  },
  {
    code: "260",
    ispb: "18236120",
    name: "Nu Pagamentos S.A.",
  },
  {
    code: "129",
    ispb: "18520834",
    name: "UBS Brasil Banco de Investimento S.A.",
  },
  {
    code: "128",
    ispb: "19307785",
    name: "MS Bank S.A. Banco de Câmbio",
  },
  {
    code: "194",
    ispb: "20155248",
    name: "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    code: "383",
    ispb: "21018182",
    name: "BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.",
  },
  {
    code: "324",
    ispb: "21332862",
    name: "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    code: "310",
    ispb: "22610500",
    name: "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
  },
  {
    code: "380",
    ispb: "22896431",
    name: "PICPAY SERVICOS S.A.",
  },
  {
    code: "163",
    ispb: "23522214",
    name: "Commerzbank Brasil S.A. – Banco Múltiplo",
  },
  {
    code: "280",
    ispb: "23862762",
    name: "Avista S.A. Crédito, Financiamento e Investimento",
  },
  {
    code: "146",
    ispb: "24074692",
    name: "GUITTA CORRETORA DE CAMBIO LTDA.",
  },
  {
    code: "343",
    ispb: "24537861",
    name: "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
  },
  {
    code: "279",
    ispb: "26563270",
    name: "COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE",
  },
  {
    code: "335",
    ispb: "27098060",
    name: "Banco Digio S.A.",
  },
  {
    code: "349",
    ispb: "27214112",
    name: "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    code: "374",
    ispb: "27351731",
    name: "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    code: "278",
    ispb: "27652684",
    name: "Genial Investimentos Corretora de Valores Mobiliários S.A.",
  },
  {
    code: "271",
    ispb: "27842177",
    name: "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
  },
  {
    code: "021",
    ispb: "28127603",
    name: "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
  },
  {
    code: "246",
    ispb: "28195667",
    name: "Banco ABC Brasil S.A.",
  },
  {
    code: "292",
    ispb: "28650236",
    name: "BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    code: "n/a",
    ispb: "28719664",
    name: "B3 SA – Brasil, Bolsa, Balcão – Segmento Cetip UTVM",
  },
  {
    code: "n/a",
    ispb: "29011780",
    name: "Câmara Interbancária de Pagamentos – CIP C3",
  },
  {
    code: "751",
    ispb: "29030467",
    name: "Scotiabank Brasil S.A. Banco Múltiplo",
  },
  {
    code: "352",
    ispb: "29162769",
    name: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    code: "208",
    ispb: "30306294",
    name: "Banco BTG Pactual S.A.",
  },
  {
    code: "746",
    ispb: "30723886",
    name: "Banco Modal S.A.",
  },
  {
    code: "241",
    ispb: "31597552",
    name: "BANCO CLASSICO S.A.",
  },
  {
    code: "336",
    ispb: "31872495",
    name: "Banco C6 S.A.",
  },
  {
    code: "612",
    ispb: "31880826",
    name: "Banco Guanabara S.A.",
  },
  {
    code: "604",
    ispb: "31895683",
    name: "Banco Industrial do Brasil S.A.",
  },
  {
    code: "505",
    ispb: "32062580",
    name: "Banco Credit Suisse (Brasil) S.A.",
  },
  {
    code: "329",
    ispb: "32402502",
    name: "QI Sociedade de Crédito Direto S.A.",
  },
  {
    code: "196",
    ispb: "32648370",
    name: "FAIR CORRETORA DE CAMBIO S.A.",
  },
  {
    code: "342",
    ispb: "32997490",
    name: "Creditas Sociedade de Crédito Direto S.A.",
  },
  {
    code: "300",
    ispb: "33042151",
    name: "Banco de la Nacion Argentina",
  },
  {
    code: "477",
    ispb: "33042953",
    name: "Citibank N.A.",
  },
  {
    code: "266",
    ispb: "33132044",
    name: "BANCO CEDULA S.A.",
  },
  {
    code: "122",
    ispb: "33147315",
    name: "Banco Bradesco BERJ S.A.",
  },
  {
    code: "376",
    ispb: "33172537",
    name: "BANCO J.P. MORGAN S.A.",
  },
  {
    code: "348",
    ispb: "33264668",
    name: "Banco XP S.A.",
  },
  {
    code: "473",
    ispb: "33466988",
    name: "Banco Caixa Geral – Brasil S.A.",
  },
  {
    code: "745",
    ispb: "33479023",
    name: "Banco Citibank S.A.",
  },
  {
    code: "120",
    ispb: "33603457",
    name: "BANCO RODOBENS S.A.",
  },
  {
    code: "265",
    ispb: "33644196",
    name: "Banco Fator S.A.",
  },
  {
    code: "007",
    ispb: "33657248",
    name: "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
  },
  {
    code: "188",
    ispb: "33775974",
    name: "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES",
  },
  {
    code: "134",
    ispb: "33862244",
    name: "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    code: "029",
    ispb: "33885724",
    name: "Banco Itaú Consignado S.A.",
  },
  {
    code: "243",
    ispb: "33923798",
    name: "Banco Máxima S.A.",
  },
  {
    code: "397",
    ispb: "34088029",
    name: "LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
  },
  {
    code: "078",
    ispb: "34111187",
    name: "Haitong Banco de Investimento do Brasil S.A.",
  },
  {
    code: "355",
    ispb: "34335592",
    name: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    code: "367",
    ispb: "34711571",
    name: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  {
    code: "373",
    ispb: "35977097",
    name: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
  },
  {
    code: "111",
    ispb: "36113876",
    name: "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.",
  },
  {
    code: "408",
    ispb: "36586946",
    name: "BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    code: "404",
    ispb: "37241230",
    name: "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    code: "403",
    ispb: "37880206",
    name: "CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    code: "306",
    ispb: "40303299",
    name: "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
  },
  {
    code: "017",
    ispb: "42272526",
    name: "BNY Mellon Banco S.A.",
  },
  {
    code: "174",
    ispb: "43180355",
    name: "PEFISA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    code: "495",
    ispb: "44189447",
    name: "Banco de La Provincia de Buenos Aires",
  },
  {
    code: "125",
    ispb: "45246410",
    name: "Plural S.A. Banco Múltiplo",
  },
  {
    code: "488",
    ispb: "46518205",
    name: "JPMorgan Chase Bank, National Association",
  },
  {
    code: "065",
    ispb: "48795256",
    name: "Banco AndBank (Brasil) S.A.",
  },
  {
    code: "492",
    ispb: "49336860",
    name: "ING Bank N.V.",
  },
  {
    code: "145",
    ispb: "50579044",
    name: "LEVYCAM – CORRETORA DE CAMBIO E VALORES LTDA.",
  },
  {
    code: "250",
    ispb: "50585090",
    name: "BCV – BANCO DE CRÉDITO E VAREJO S.A.",
  },
  {
    code: "354",
    ispb: "52904364",
    name: "NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
  },
  {
    code: "253",
    ispb: "52937216",
    name: "Bexs Corretora de Câmbio S/A",
  },
  {
    code: "269",
    ispb: "53518684",
    name: "BANCO HSBC S.A.",
  },
  {
    code: "213",
    ispb: "54403563",
    name: "Banco Arbi S.A.",
  },
  {
    code: "n/a",
    ispb: "54641030",
    name: "BMF Bovespa S.A. – Bolsa de Valores, Mercadorias e Futuros – Camara BMFBOVESPA",
  },
  {
    code: "139",
    ispb: "55230916",
    name: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo",
  },
  {
    code: "018",
    ispb: "57839805",
    name: "Banco Tricury S.A.",
  },
  {
    code: "422",
    ispb: "58160789",
    name: "Banco Safra S.A.",
  },
  {
    code: "630",
    ispb: "58497702",
    name: "Banco Smartbank S.A.",
  },
  {
    code: "224",
    ispb: "58616418",
    name: "Banco Fibra S.A.",
  },
  {
    code: "393",
    ispb: "59109165",
    name: "Banco Volkswagen S.A.",
  },
  {
    code: "600",
    ispb: "59118133",
    name: "Banco Luso Brasileiro S.A.",
  },
  {
    code: "390",
    ispb: "59274605",
    name: "BANCO GM S.A.",
  },
  {
    code: "623",
    ispb: "59285411",
    name: "Banco Pan S.A.",
  },
  {
    code: "655",
    ispb: "59588111",
    name: "Banco Votorantim S.A.",
  },
  {
    code: "479",
    ispb: "60394079",
    name: "Banco ItauBank S.A.",
  },
  {
    code: "456",
    ispb: "60498557",
    name: "Banco MUFG Brasil S.A.",
  },
  {
    code: "464",
    ispb: "60518222",
    name: "Banco Sumitomo Mitsui Brasileiro S.A.",
  },
  {
    code: "341",
    ispb: "60701190",
    name: "ITAÚ UNIBANCO S.A.",
  },
  {
    code: "237",
    ispb: "60746948",
    name: "Banco Bradesco S.A.",
  },
  {
    code: "381",
    ispb: "60814191",
    name: "BANCO MERCEDES-BENZ DO BRASIL S.A.",
  },
  {
    code: "613",
    ispb: "60850229",
    name: "Omni Banco S.A.",
  },
  {
    code: "652",
    ispb: "60872504",
    name: "Itaú Unibanco Holding S.A.",
  },
  {
    code: "637",
    ispb: "60889128",
    name: "BANCO SOFISA S.A.",
  },
  {
    code: "n/a",
    ispb: "60934221",
    name: "BMF Bovespa S/A – Bolsa de Valores, Mercadorias e Futuros – Camara Cambio",
  },
  {
    code: "653",
    ispb: "61024352",
    name: "BANCO INDUSVAL S.A.",
  },
  {
    code: "069",
    ispb: "61033106",
    name: "Banco Crefisa S.A.",
  },
  {
    code: "370",
    ispb: "61088183",
    name: "Banco Mizuho do Brasil S.A.",
  },
  {
    code: "249",
    ispb: "61182408",
    name: "Banco Investcred Unibanco S.A.",
  },
  {
    code: "318",
    ispb: "61186680",
    name: "Banco BMG S.A.",
  },
  {
    code: "626",
    ispb: "61348538",
    name: "BANCO C6 CONSIGNADO S.A.",
  },
  {
    code: "270",
    ispb: "61444949",
    name: "Sagitur Corretora de Câmbio Ltda.",
  },
  {
    code: "366",
    ispb: "61533584",
    name: "BANCO SOCIETE GENERALE BRASIL S.A.",
  },
  {
    code: "113",
    ispb: "61723847",
    name: "Magliano S.A. Corretora de Cambio e Valores Mobiliarios",
  },
  {
    code: "131",
    ispb: "61747085",
    name: "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA",
  },
  {
    code: "011",
    ispb: "61809182",
    name: "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
  },
  {
    code: "611",
    ispb: "61820817",
    name: "Banco Paulista S.A.",
  },
  {
    code: "755",
    ispb: "62073200",
    name: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
  },
  {
    code: "089",
    ispb: "62109566",
    name: "CREDISAN COOPERATIVA DE CRÉDITO",
  },
  {
    code: "643",
    ispb: "62144175",
    name: "Banco Pine S.A.",
  },
  {
    code: "140",
    ispb: "62169875",
    name: "Easynvest – Título Corretora de Valores SA",
  },
  {
    code: "707",
    ispb: "62232889",
    name: "Banco Daycoval S.A.",
  },
  {
    code: "288",
    ispb: "62237649",
    name: "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
  },
  {
    code: "363",
    ispb: "62285390",
    name: "SOCOPA SOCIEDADE CORRETORA PAULISTA S.A.",
  },
  {
    code: "101",
    ispb: "62287735",
    name: "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    code: "487",
    ispb: "62331228",
    name: "DEUTSCHE BANK S.A. – BANCO ALEMAO",
  },
  {
    code: "233",
    ispb: "62421979",
    name: "Banco Cifra S.A.",
  },
  {
    code: "177",
    ispb: "65913436",
    name: "Guide Investimentos S.A. Corretora de Valores",
  },
  {
    code: "365",
    ispb: "68757681",
    name: "SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
  },
  {
    code: "633",
    ispb: "68900810",
    name: "Banco Rendimento S.A.",
  },
  {
    code: "218",
    ispb: "71027866",
    name: "Banco BS2 S.A.",
  },
  {
    code: "169",
    ispb: "71371686",
    name: "BANCO OLÉ CONSIGNADO S.A.",
  },
  {
    code: "293",
    ispb: "71590442",
    name: "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    code: "285",
    ispb: "71677850",
    name: "Frente Corretora de Câmbio Ltda.",
  },
  {
    code: "080",
    ispb: "73622748",
    name: "B&T CORRETORA DE CAMBIO LTDA.",
  },
  {
    code: "753",
    ispb: "74828799",
    name: "Novo Banco Continental S.A. – Banco Múltiplo",
  },
  {
    code: "222",
    ispb: "75647891",
    name: "BANCO CRÉDIT AGRICOLE BRASIL S.A.",
  },
  {
    code: "281",
    ispb: "76461557",
    name: "Cooperativa de Crédito Rural Coopavel",
  },
  {
    code: "754",
    ispb: "76543115",
    name: "Banco Sistema S.A.",
  },
  {
    code: "098",
    ispb: "78157146",
    name: "Credialiança Cooperativa de Crédito Rural",
  },
  {
    code: "610",
    ispb: "78626983",
    name: "Banco VR S.A.",
  },
  {
    code: "712",
    ispb: "78632767",
    name: "Banco Ourinvest S.A.",
  },
  {
    code: "010",
    ispb: "81723108",
    name: "CREDICOAMO CREDITO RURAL COOPERATIVA",
  },
  {
    code: "283",
    ispb: "89960090",
    name: "RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA",
  },
  {
    code: "033",
    ispb: "90400888",
    name: "BANCO SANTANDER (BRASIL) S.A.",
  },
  {
    code: "217",
    ispb: "91884981",
    name: "Banco John Deere S.A.",
  },
  {
    code: "041",
    ispb: "92702067",
    name: "Banco do Estado do Rio Grande do Sul S.A.",
  },
  {
    code: "117",
    ispb: "92856905",
    name: "ADVANCED CORRETORA DE CÂMBIO LTDA",
  },
  {
    code: "654",
    ispb: "92874270",
    name: "BANCO DIGIMAIS S.A.",
  },
  {
    code: "371",
    ispb: "92875780",
    name: "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
  },
  {
    code: "212",
    ispb: "92894922",
    name: "Banco Original S.A.",
  },
  {
    code: "289",
    ispb: "94968518",
    name: "DECYSEO CORRETORA DE CAMBIO LTDA.",
  },
  {
    code: "",
    ispb: "",
    name: "",
  },
];

export const banks = banksRaw.map((bank) => {
  return {
    id: bank.code,
    name: bank.name,
    hidden: `${String(bank.code)} ${String(bank.name).toLocaleLowerCase()}`,
  };
});

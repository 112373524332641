import React, { FormEvent, Fragment, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { InputInsertValue } from "../../components/inputValue";
import { StepHeader } from "../../components/stepHeader";
import { documentFormatted } from "../../utils/documentFormatted";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import brasilLocale from "date-fns/locale/pt-BR";

import {
  BoxContentWrapper,
  BoxInfoWrapper,
  BoxWrapper,
  ButtonsWrapper,
  Container,
  DataWrapper,
  Date as DateComponent,
  InfoWrapper,
  Key,
  // LabelInput,
  Name,
  TextAreaInput,
  // Value,
  WrapperHeader,
} from "./styles";
import { format } from "date-fns";
import { ExclamationIcon } from "@heroicons/react/solid";
import { useAuth } from "../../hooks/auth";
import TextInput from "../../components/TextInput";
import { service, serviceError } from "../../services/ajio";
import usePerformP2PTransaction from "src/components/transferTax";
import KeyboardPressPasswordEletronic from "src/components/keyPressPasswordeletronic";
import { banksISPBS } from "src/utils/banks";
import { useNotification } from "src/hooks/notification";

interface StateProps {
  bank: {
    hidden: string;
    id: number;
    name: string;
  };
  type: string;
  beneficiaryType: string;
  id?: string | null;
  name: string;
  document: string;
  agency: string;
  account: string;
  digit: string;
}

interface PropsTed {
  id: string;
}

export const TransferConfirm: React.FC = () => {
  const navigate = useNavigate();
  const { account, refresh, user } = useAuth();
  // const { openTransferModal } = useModalTransfer();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [maskedValue, setMaskedValue] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tedData, setTedData] = useState<PropsTed>({} as PropsTed);

  const [pinOpen, setPinOpen] = useState(false);
  const [pin, setPin] = useState("");
  const [hasPin, setHasPin] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [valueEletronic, setValueEletronic] = useState("");
  const [scheduled, setScheduled] = useState(false);
  const [scheduleDate, setScheduleDate] = useState<Date | null>("" as any);

  const { performP2PTransactions } = usePerformP2PTransaction();
  const [clickedValues, setClickedValues] = useState<string[]>([]);

  const state = location.state as StateProps;

  console.log("STATEEEEE", state);

  if (state === null) {
    navigate("/transfer");
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate("/transfer");
  };

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleConfirm = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const bankDetails = banksISPBS.find(
        (bk) => bk.code === String(state.bank.id)
      );

      if (state.bank.id !== 0) {
        const { data } = await service.post("/bank-transfers/ted-doc/pix", {
          // pin: account.pin,
          amount: value,
          beneficiary: {
            holder: {
              name: state.name,
              document: state.document.replace(/[/,.,-\s]/g, ""),
              type: state.type,
            },
            participantIspb: bankDetails?.ispb,
            number: state.account + state.digit,
            branch: state.agency,
            type: state.beneficiaryType,
            // account_digit: state.digit,
          },
          scheduled: scheduled,
          scheduleDate: scheduleDate,
        });
        // const { data } = await service.post("/bank-transfers/ted-doc/pix", {
        //   // pin: account.pin,
        //   amount: value,
        //   bank_account: {
        //     name: state.name,
        //     document: state.document.replace(/[/,.,-\s]/g, ""),
        //     bank: String(state.bank.id),
        //     branch: state.agency,
        //     account_number: state.account,
        //     account_digit: state.digit,
        //   },
        // });
        setTedData(data.bank_transfer);
      } else {
        console.log("state state", state);
        setOpenNotification({
          type: "success",
          title: "Atualização Concluída:",
          notification: "",
        });
        // const { data } = await service.post("/bank-transfers/p2p", {
        //   //pin: account.pin, // naose deve usar mais
        //   payee_id: state.id, //beneficiary
        //   amount: value,
        // });

        // setTedData(data.transfer);
      }

      setLoading(false);
      setOpen(true);
      refresh();
    } catch (err) {
      setLoading(false);
      const error = serviceError(err);
      setError(error.friend);
      setHasPin(false);
    }
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }

  const handleKeyPress = (prevValue: string) => {
    // ...
    setClickedValues((prevValue: any) => [...prevValue]);
    // ...
  };

  const handlePasswordVerification = (clickedValues: string[]) => {
    const securityCode = account.security_eletronic;
    console.log("sou securityCode", securityCode);
    console.log(
      "sou clickedValues de dentro do handlePasswordVerification ",
      clickedValues
    );

    // Converte o array de valores para uma string
    const clickedValuesString = clickedValues.join("").trim();
    console.log(
      "sou clickedValuesString de dentro do handlePasswordVerification ",
      clickedValuesString
    );
    const handleConfirm = (passwords: string[]) => {
      // Lógica para lidar com a confirmação da senha
      setPinOpen(false);
      formRef.current?.requestSubmit();
    };
    // Verifique cada par de dígitos em clickedValuesString com os dígitos em securityCode
    const isPasswordCorrect = clickedValues.every((_, index) => {
      const start = index * 2;
      const end = start + 2;
      const clickedPair = clickedValuesString.substring(start, end);
      const securityPair = securityCode.substr(start, 2);

      console.log(
        `Comparando: clickedPair = ${clickedPair}, securityPair = ${securityPair}`
      );

      const isPairCorrect = clickedPair === securityPair;

      console.log(
        `Par ${index + 1}: ${isPairCorrect ? "Correto" : "Incorreto"}`
      );

      return isPairCorrect;
    });
    console.log(
      "sou isPasswordCorrect de dentro do handlePasswordVerification ",
      isPasswordCorrect
    );

    if (isPasswordCorrect) {
      // Senha correta, faça o que for necessário
      console.log("Senha correta!");
      console.log("sou boy 3");
    } else {
      // Senha incorreta, faça o que for necessário
      console.log("Senha incorreta!222222");
      console.log("sou boy 2");
    }
  };

  const updateValueEletronic = (newValue: any) => {
    setValueEletronic(newValue);
  };

  const handleUpdateClickedValues = (values: string[]) => {
    setClickedValues(values);
  };

  const { setOpenNotification } = useNotification();

  return (
    <Container>
      <WrapperHeader>
        <StepHeader
          handleGoBackFunction={handleGoBack}
          title="Confirme e transfira"
        />
      </WrapperHeader>
      <form onSubmit={handleConfirm} ref={formRef}>
        <BoxInfoWrapper>
          <BoxContentWrapper>
            <InfoWrapper>
              <Name>{state.name}</Name>
              <DataWrapper>
                <span>Documento</span>
                <Key>{documentFormatted(state.document)}</Key>
              </DataWrapper>
              <DataWrapper>
                <span>BANCO</span>
                <Key>{state.bank.name}</Key>
              </DataWrapper>
              <DataWrapper>
                <span>AGÊNCIA</span>
                <Key>{state.agency}</Key>
              </DataWrapper>
              <DataWrapper>
                <span>CONTA</span>
                <Key>
                  {state.account}-{state.digit}
                </Key>
              </DataWrapper>
              {/* <DataWrapper>
              <span>TIPO DE CONTA</span>
              <Key>Corrente</Key>
            </DataWrapper> */}
            </InfoWrapper>
            <DateComponent>
              {format(new Date(), "dd LLL yyyy HH:mm", {
                locale: brasilLocale,
              }).toUpperCase()}
            </DateComponent>
          </BoxContentWrapper>
          <InputInsertValue
            onChange={(event, value, masked) =>
              handleChange(event, value, masked)
            }
          />
          <DateComponent>
            {format(new Date(), "dd LLL yyyy HH:mm", {
              locale: brasilLocale,
            }).toUpperCase()}
          </DateComponent>
          {scheduled && (
            <div className="mt-4">
              <label
                htmlFor="scheduleDate"
                className="block text-sm font-medium text-yellow-500"
              >
                Data de agendamento:
              </label>
              <input
                type="date"
                id="scheduleDate"
                name="scheduleDate"
                value={
                  scheduleDate ? scheduleDate.toISOString().split("T")[0] : ""
                }
                onChange={(e) => setScheduleDate(new Date(e.target.value))}
                className="mt-1 p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 block w-full"
              />
            </div>
          )}
          <button
            type="button"
            onClick={() => {
              setScheduled(!scheduled);
              if (scheduled == true) {
                setScheduleDate("" as any);
              }
            }}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {scheduled ? "Cancelar Agendamento" : "Agendar Transferência"}
          </button>
        </BoxInfoWrapper>
        <BoxWrapper>
          {/* <TextAreaInput placeholder="Descrição *opcional"></TextAreaInput> */}
          {/* <LabelInput>Senha</LabelInput> */}
          {/* <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="********" /> */}
        </BoxWrapper>
        {!!error && (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 w-[480px]">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">{error}</p>
              </div>
            </div>
          </div>
        )}
        <ButtonsWrapper>
          <Button
            handleFunction={handleCancel}
            color="#e3e3e3"
            title="Cancelar"
          />
          {!hasPin ? (
            <Button
              type="button"
              isLoading={loading}
              title="Continuar para senha"
              handleFunction={() => setPinOpen(true)}
            />
          ) : (
            <Button type="submit" isLoading={loading} title="Transferir" />
          )}

          <Transition.Root show={pinOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative bg-darkgoldenrod rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                      <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-white"
                          >
                            Senha
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="mt-5 flex justify-center">
                              <div className="w-48 ">
                                <TextInput
                                  placeholder="Senha Eletrônica"
                                  maxLength={8}
                                  value={valueEletronic}
                                  style={{ color: "black" }} // Adicione esta linha
                                  type="password"
                                  onChange={(input) =>
                                    setValueEletronic(input.target.value)
                                  }
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <KeyboardPressPasswordEletronic
                          updateParentInput={updateValueEletronic}
                          updateClickedValues={setClickedValues}
                          onKeyPress={handleKeyPress}
                          onConfirm={handlePasswordVerification}
                        />

                        <Button
                          type="submit"
                          isLoading={loading}
                          title="Transferir"
                          handleFunction={() => {
                            const handlePasswordVerification = (
                              clickedValues: string[]
                            ) => {
                              // Verificar se clickedValues está vazio
                              if (clickedValues.length === 0) {
                                console.error(
                                  "Erro: Nenhum valor fornecido. Digite a senha eletrônica."
                                );
                                return;
                              }

                              const securityCode = account.security_eletronic;
                              console.log("Sou securityCode", securityCode);
                              console.log(
                                "em handleFunction no pix confirm Sou clickedValues pra referencia fui resultado dos clicks do usuario no front end: ",
                                clickedValues
                              );

                              // Verifique cada posição de clickedValuesArray com os dígitos em securityCode
                              let isPasswordCorrect = true;

                              clickedValues.some((position, index) => {
                                // Remove 'ou' e espaços e divide a posição em um array
                                const digitsArray: string[] = position
                                  .split(" ou ")
                                  .map((digit) => digit.trim());

                                console.log(
                                  `Posição ${
                                    index + 1
                                  } - Original: ${position}, digitsArray:`,
                                  digitsArray
                                );

                                // Verifica se temos exatamente dois dígitos na posição (exceto na última posição)
                                if (digitsArray.length !== 2) {
                                  if (index === 7) {
                                    // Se for a última posição, verifica se tem apenas um dígito
                                    if (digitsArray.length !== 2) {
                                      console.error(
                                        `Erro: posição ${
                                          index + 1
                                        } não possui um dígito válido.`
                                      );
                                      isPasswordCorrect = false;
                                      return true; // Sai do loop
                                    }
                                  } else {
                                    console.error(
                                      `Erro: posição ${
                                        index + 1
                                      } não possui dois dígitos válidos.`
                                    );
                                    isPasswordCorrect = false;
                                    return true; // Sai do loop
                                  }
                                }

                                // Continua com a lógica original de comparação
                                const digitOptionsString = digitsArray.join("");
                                const securityDigit = securityCode[index];
                                console.log(
                                  `Posição ${
                                    index + 1
                                  } - Comparando: digitOptions = ${digitOptionsString}, securityDigit = ${securityDigit}`
                                );

                                // Ajuste na lógica de comparação
                                const isDigitCorrect = digitsArray.some(
                                  (digit) => digit === securityDigit
                                );
                                console.log(
                                  `Posição ${index + 1}: ${
                                    isDigitCorrect ? "Correta" : "Incorreta"
                                  }`
                                );

                                if (!isDigitCorrect) {
                                  isPasswordCorrect = false;
                                  console.error(
                                    "Senha incorreta! Algum dígito está errado."
                                  );
                                  return true; // Sai do loop
                                }

                                return false; // Continua para a próxima posição
                              });

                              if (isPasswordCorrect) {
                                // Senha correta, faça o que for necessário
                                if (
                                  scheduled &&
                                  (!scheduleDate ||
                                    isNaN(scheduleDate.getTime()))
                                ) {
                                  setOpenNotification({
                                    notification:
                                      "Data de agendamento inválida",
                                    title: "Data inválida",
                                    type: "error",
                                  });
                                } else {
                                  console.log("Senha correta!");
                                  console.log("Sou boy 3");
                                  setHasPin(true);
                                  setPinOpen(false);
                                  formRef.current?.requestSubmit();
                                }
                              } else {
                                // Senha incorreta, faça o que for necessário
                                console.error(
                                  "Senha incorreta! Toda a senha está incorreta."
                                );
                                console.log("Sou boy 23");
                                setOpenNotification({
                                  notification:
                                    "Verifique sua senha e tente novamente",
                                  title: "Senha incorreta",
                                  type: "error",
                                });
                              }
                            };

                            handlePasswordVerification(clickedValues);
                          }}
                        />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </ButtonsWrapper>
      </form>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Transferência realizada
                        <br />
                        {maskedValue}
                      </Dialog.Title>
                      <div className="mt-2 text-left">
                        <p className="text-sm text-gray-500">
                          <h2 className="mb-5 mt-5 text-lg">
                            Dados do favorecido
                          </h2>
                          <div>
                            <h2>Nome</h2>
                            <h3>{state.name}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Documento</h2>
                            <h3>{documentFormatted(state.document)}</h3>
                          </div>

                          <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>{state.agency}</h3>
                            </div>

                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>
                                {state.account}-{state.digit}
                              </h3>
                            </div>
                          </div>

                          <h2 className="mb-5 mt-5 text-lg">
                            Dados da conta debitada
                          </h2>

                          <div>
                            <h2>Nome</h2>
                            <h3>{user.name}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Documento</h2>
                            <h3>{documentFormatted(user.document)}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Banco</h2>
                            <h3>DELCRED SCD S.A.</h3>
                          </div>

                          {/* <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>{account.branch}</h3>
                            </div>

                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>
                                {account.accountNumber}-{account.accountDigit}
                              </h3>
                            </div>
                          </div> */}
                          {/* 
                          <div className="mt-5">
                            <h2>ID da transação</h2>
                            <h3>{tedData?.id}</h3>
                          </div> */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => onCloseModal()}
                    >
                      Ir para o extrato
                    </button>

                    <button
                      type="button"
                      className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                      // onClick={() => onCloseModal()}
                    >
                      Comprovante disponível no extrato
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Container>
  );
};

import moment from "moment";
import React, { FC } from "react";

import vilapayLogo from "src/assets/logo-pdf.png";
import { useAuth } from "src/hooks/auth";
import { ExtractItem, extractDescription } from "src/utils/extractDescription";
import toLocaleAmount from "src/utils/toLocaleAmount";
import transactionLabel from "src/utils/transactionLabel";

export const ExtractPdf = () => {
  const transactionsString = localStorage.getItem("transactions");

  const { account, user } = useAuth();

  if (!transactionsString || !user || !account) {
    window.close();
    return <></>;
  }

  const transactions: ExtractItem[] = JSON.parse(transactionsString);

  if (!transactions) {
    window.close();
  }

  const isUser = user.role == "MEMBER" || user.role == "ADMIN" || user.role == "ADMIN_BAG" || user.role == "MASTER";

  return (
    <div
      onLoad={() => {
        setTimeout(() => {
          window.print();
        }, 300);
      }}
      className="flex gap-3 flex-col w-[720px] min-h-screen p-8 bg-white"
    >
      <div className="flex overflow-hidden w-full items-center h-24 border-zinc-100 border-2 rounded-lg">
        <div className="p-8">
          <img src={vilapayLogo} className="object-cover h-full aspect-auto" />
        </div>
        <div className="flex items-center justify-between py-4 flex-col h-full flex-1 bg-[#274683]">
          <span className="font-bold text-white">Extrato de Conta</span>
          <span className=" text-white">Cliente: {user.name}</span>
        </div>
      </div>
      <div className="flex flex-col overflow-hidden w-full items-center h-24 border-zinc-100 border-2 rounded-lg">
        <div className="flex items-center justify-center w-full h-10 bg-[#274683] font-bold text-white">
          Dados da conta
        </div>
        <div className="flex flex-1 w-full justify-center items-center gap-4">
          {isUser ? (
            <>
              <span className="font-semibold">Agência: {account.branch_number}</span>
              <span className="font-semibold">Conta: {account.account_number}</span>
            </>
          ) : (
            <span className="font-semibold">Número identificador: {account.number_identifier}</span>
          )}
        </div>
      </div>
      <div className="flex w-full bg-gray-200 p-1 mt-4 font-bold text-lg">Lançamentos</div>
      {/* <div className="grid grid-cols-5 px-4 w-full border border-zinc-200">
        <span className="text-zinc-500 text-lg">11/03/2024</span>
        <span className="text-zinc-500 text-lg">Saldo anterior:</span>
        <span className="text-zinc-500 text-lg text-right">0,00 (+)</span>
      </div> */}
      <div className="flex px-4 w-full">
        <span className="text-[#274683] text-lg flex-1">Data</span>
        <span className="text-[#274683] text-lg text-center flex-[2]">Balanço</span>
        <span className="text-[#274683] text-lg flex-1">Descrição</span>
        <span className="text-[#274683] text-center text-lg flex-[2]">Beneficiario/Pagador</span>
        <span className="text-[#274683] text-lg text-right flex-1">Valor</span>
      </div>
      <div className="flex flex-col border-y border-zinc-200 divide-y-[1px] divide-zinc-200">
        {transactions.map((d, i) => {
          if (
            d.direction === "out" &&
            // d.type === "p2p_transfer" &&
            d.description === "P2P AJIOPAY"
          ) {
            return null; // Ignora esta transação
          }

          // if (d.type == "p2p_transfer") return null;

          if (d.status === "PENDING") {
            return null; // Ignora esta transação
          }

          if (d.status == "SUSPECT_TRANSACTION") {
            return null; // Ignora esta transação
          }

          if (d.amount == 0) return null;

          return (
            <TransactionDetail
              key={i}
              transaction={d}
              data={{
                date: moment(d.created_at).format("DD/MM/YYYY"),
                description: `${transactionLabel(d)}`,
                value: `R$ ${d.amount} (${d.direction == "out" ? "-" : "+"})`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const TransactionDetail: FC<{
  transaction: ExtractItem;
  data: { date: string; description: string; value: string };
}> = ({ data, transaction }) => {
  const names = getTransactionNames(transaction);
  const direction = transaction.direction;

  return (
    <div className="flex px-4 w-full py-1">
      <span className="text-zinc-500 text-center text-sm flex flex-1">{data.date}</span>
      <div className="text-zinc-500 text-sm justify-center flex flex-[2]">
        <span>
          Saldo anterior: R$ {toLocaleAmount(transaction.previousValue || 0)} <br />
          Saldo atual: R$ {toLocaleAmount(transaction.newValue || 0)}
        </span>
      </div>
      <div className="text-zinc-500 items-center justify-center text-sm flex flex-1">
        <span>{data.description}</span>
      </div>
      <span className="text-zinc-500 text-center justify-center text-sm flex flex-[2]">
        {direction == "in" ? names.payerName : names.beneficiaryName}
      </span>
      <div className="flex flex-1 justify-end">
        <span className="text-zinc-500 text-sm">{data.value}</span>
      </div>
    </div>
  );
};

export const getTransactionNames = (transaction: ExtractItem) => {
  const { response, data, direction } = transaction;

  let beneficiaryName = "";
  let payerName = "";

  if (transaction.type.toLowerCase().includes("p2p")) {
    payerName = response?.proof?.payer?.holder?.name;
    beneficiaryName = response?.proof?.beneficiary?.holder?.name;
  }

  if (transaction.type.includes("PAGBANK_PAYMENT")) {
    payerName = data?.payer?.name;
    beneficiaryName = data?.beneficiary?.name;
  }

  if (transaction.type.toLowerCase().includes("pix")) {
    payerName = response?.proof?.payer?.holder?.name || response?.payer?.holder?.name;
    beneficiaryName = response?.proof?.beneficiary?.holder?.name || response?.beneficiary?.holder?.name;
  }

  if (transaction.type == "INTERNAL_TAX") {
    payerName = response?.payer?.name;
    beneficiaryName = response?.beneficiary?.name;
  }

  if (transaction.type == "TED") {
    payerName = response?.proof?.payer?.holder?.name;
    beneficiaryName = response?.proof?.beneficiary?.holder?.name;
  }

  if (transaction.type == "payment") {
    payerName = "";
    beneficiaryName = response?.beneficiary?.name;
  }

  beneficiaryName = beneficiaryName ? beneficiaryName : "Desconhecido";
  payerName = payerName ? payerName : "Desconhecido";

  return { beneficiaryName, payerName };
};

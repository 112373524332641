import React, { SelectHTMLAttributes, ForwardRefRenderFunction, ChangeEvent } from "react";

interface PropsSelect extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: string[];
  onChanged?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onSelectOption?: (data: { name: string; id: string }) => void;
  placeholder?: string;
  error?: string;
}

export const FormSelectInput: ForwardRefRenderFunction<HTMLSelectElement, PropsSelect> = (
  {
    label,
    error,
    options,
    onChanged,
    onSelectOption,
    id,
    placeholder = "Selecione uma opção",
    ...rest
  },
) => {
  return (
    <div className={`w-full`}>
      {label && <label className="block text-sm font-medium text-gray-700">{label}</label>}
      <select
        className={`block w-full h-12 rounded-lg mb-2 pl-3 pr-10 py-2 text-base max-2xl:h-9 focus:outline-none sm:text-sm`}
        onChange={onChanged}
        {...rest}
      >
        <option value="">{placeholder}</option>
        {options?.map((opt) => {
          if (typeof opt === "string") {
            return (
              <option key={opt} value={opt}>
                {opt}
              </option>
            );
          }
          return (
            <option key={opt} value={opt}>
              {opt}
            </option>
          );
        })}
      </select>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

import { DuplicateIcon } from "@heroicons/react/solid";
import React, { FormEvent, Fragment, useRef, useEffect, useState } from "react";
//import { Skeleton } from "../../components/skeleton";
//import { api } from "../../services/api";
//import { dateFormat } from "../../utils/dateFormat";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import QRCode from "react-qr-code";
//import {
//ActionPixTable,
//// KeyCpnjIcon,
// KeyPixTable,
// ShareIcon,
//TrashIcon,
//}
//from "../extract/styles";
//import { useNotification } from "../../hooks/notification";
//import { Button } from "../../components/button";
//import { useNavigate } from "react-router-dom";
//import InputCustom from "./custom-input-dynamic";
//import { useAuth } from "../../hooks/auth";
///import InputDateQrCodeDynamic from "./data-picker-expiration-qrcode-dynamic";
//import InputDateExpirationQrCodeDynamic from "./data-picker-expiration-qrcode-dynamic";
//------------------------------
//import { XIcon } from '@heroicons/react/outline';
//import { FormInput } from "src/components/LPFormButton2";
//import { ButtonForm } from "./styles";
import { Money } from "phosphor-react";
//import { Loading } from "src/components/loading";
import {
  ContainerTwo,
  ContentTwo,
  QrContainerHidden,
  QrInput,
  TableTwo,
  TableWrapperTwo,
  LeftDiv,
  RightDiv,
  DivDate,
} from "./styles";
import { useAuth } from "src/hooks/auth";
import { service } from "src/services/ajio";
import PixButtonCopyPaste from "./button-copy-and-paste";
import { Button } from "./button-generate-qrcode";
import axios from "axios";
import InputAmount, { InputInsertValueQrCodeWallet } from "./input-amount";
import { QrContainer } from "./qr-code-container";

const one_hour = 3600;
const one_day = one_hour * 24;

type ModalProps = {
  openBalanceDialog: boolean;
  handleCloseBalanceDialog: () => void;
};

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

const ModalComponent: React.FC<ModalProps> = ({
  openBalanceDialog,
  handleCloseBalanceDialog,
}) => {
  const token = localStorage.getItem("@stricv2:token");
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();
  //const { setOpenNotification } = useNotification();
  const [key, setKey] = useState<KeysProps>({} as KeysProps);
  const [keys, setKeys] = useState<KeysProps[]>([]);
  const [amount, setAmount] = useState<number>(0.0);
  const [qrImage, setQrImage] = useState<string>("");
  const [isNamePayer, setNamePayer] = useState<string>("");
  const [isDocumentPayer, setEmvDocumentPayer] = useState<string>("");
  const [isDateExpiration, setDateExpiration] = useState<string>("");
  const [isQuestion, setQuestion] = useState<string>("");
  const [isLimitDays, setLimitDays] = useState<string>("");
  const [isTitle, setTitle] = useState<string>("");
  const [isContent, setContent] = useState<string>("");
  const [selectedKey, setSelectedKey] = useState("");
  const [maskedValue, setMaskedValue] = useState("");
  const [value, setValue] = useState(0);
  const [isEmvData, setEmvData] = useState<string>("");
  const [responseId, setResponseId] = useState("");
  const [isClicked, setIsClicked] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [keyOptions, setKeyOptions] = useState<string[]>([]);
  const { signIn, account, user } = useAuth();

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  // Definindo a função identificarTipoString
  function identificarTipoString(str: string): string {
    const regexPix = /^[a-fA-F0-9]{8}-(?:[a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$/;
    const regexCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$|^\d{14}$/;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regexPix.test(str)) {
      return "Chave Aleatória de Pix";
    } else if (regexCNPJ.test(str)) {
      return "CNPJ";
    } else if (regexEmail.test(str)) {
      return "E-mail";
    } else {
      return "Formato não reconhecido";
    }
  }

  // Chamar a função e exibir o tipo de string identificado
  const tipoStringIdentificado = identificarTipoString(inputValue);
  console.log(tipoStringIdentificado);

  const handleAmountChange = (newValue: number) => {
    if (!isNaN(newValue)) {
      setAmount(newValue);
    }
  };

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleDownloadQRCode = () => {
    downloadImage(qrImage, { id: linkValue });
  };

  useEffect(() => {
    async function getKeys() {
      try {
        const response = await service.get("/pix/keys");
        setKeys(response.data.keys);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    getKeys();
  }, []);

  useEffect(() => {
    if (keys.length > 0) {
      const firstKey = keys[0].key; // Obtendo o valor da primeira posição do array dinâmico
      setSelectedKey(firstKey); // Definindo o valor de selectedKey

      // Utilize selectedKey como necessário, como em uma chamada para o back end
      console.log("Valor da primeira posição:", selectedKey);
    }
  }, [keys, selectedKey]);

  console.log("sou select key", selectedKey);
  const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmvDocumentPayer(e.target.value);
  };
  // const handleDateExpirationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //  setDateExpiration(e.target.value);
  // }
  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
  };
  //const handleLimitDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //  setLimitDays(e.target.value);
  // }
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  const handlePayerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNamePayer(e.target.value);
  };

  const handleDateExpirationChange = (date: string) => {
    setDateExpiration(date);
    console.log("Selected Date: ", date);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.value);
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const userId = user.id;
  console.log(userId, "eu sou o id:");
  const configPixKey = user.config_key_pix;
  console.log("EU SOU A CHAVE PIX: ", configPixKey);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const userName = user.name;
  const amountTransaction = value;
  console.log("oi sou eu: ", userName);

  const handleGenerateQRCode = async () => {
    try {
      const today = new Date();
      const futureDate = new Date(today.setDate(today.getDate() + 30));
      const year = futureDate.getFullYear();
      const month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
      const day = futureDate.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      const payload = {
        userId: userId,
        type: "PIX_DYNAMIC",
        id_tx: responseId,
        payer: {
          name: isNamePayer,
          document: isDocumentPayer,
        },
        date_expiration: isDateExpiration,
        amount: value,
        additionalInformation: [
          {
            key: inputValue,
            value: linkValue,
          },
        ],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pix/generate/dynamic`,
        payload,
        config
      );
      console.log("oi sou  o log", response.data); // Imprime a resposta do backend no console

      const imageData = response.data.qrCodeImageBase64; // Assumindo que a propriedade 'image' contém os dados da imagem em base64
      setQrImage(imageData); // Atualiza o estado da imagem QR Code

      const emvData = response.data.qrCodePayload; // Atribua o valor de response.data.evm a emvData se response.data estiver definido
      setEmvData(emvData);

      // Certifique-se de que emvData está recebendo o valor esperado
      const idTx = response.data.correlationId; // Obtém o ID da resposta do POST
      setResponseId(idTx); // Atualiza o estado com o ID retornado

      const createdDate = response.data.createdDate;
      console.log("ola EMV:", emvData);
      console.log("OLA IDTX:", idTx);
      console.log("OLA CREATE:", createdDate);
      /// const imageData2 = response.data.image; // Assumindo que a propriedade 'image' contém os dados da imagem em base64
      // const responseId = payload.description; // Substitua por uma variável apropriada do seu contexto, se disponível
      // downloadImage(imageData, { id: responseId });

      // Aguarde 2 segundos antes de persistir os dados
      // persistirDados(responseId, payload); // Chame sua função de persistência aqui com o ID retornado da API e o payload completo

      setIsClicked(true);
    } catch (error) {
      console.error(
        "Ocorreu um erro ao enviar os dados para o backend:",
        error
      );
    }
  };

  // Função para persistir os dados
  {
    /*onst persistirDados = async (idTx: any, payload: any) => {
  try {
    const payloadParaPersistencia = {
       idTx, // Use o ID retornado da API
      ...payload, // Outras propriedades do payload de persistência
    };

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/pix/persisting/dynamic`, payloadParaPersistencia, config);
    console.log("Dados persistidos com sucesso:", response.data);
  } catch (error) {
    console.error("Ocorreu um erro ao persistir os dados:", error);
  }
};*/
  }

  const downloadImage = (base64Image: string, data: { id: string }) => {
    // // Remova o prefixo 'data:image/png;base64,' da string Base64
    // console.log(base64Image)
    // const base64Content = base64Image.split("base64,")[1];

    // if (!base64Content) return;
    // // Decodifique a string Base64
    // const byteCharacters = atob(base64Content);
    // const byteNumbers = new Array(byteCharacters.length);

    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }

    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], { type: "image/png" });

    // const link = document.createElement("a");
    // link.href = window.URL.createObjectURL(blob);
    // link.download = `qrCode-${data.id}.png`;
    // link.click();
  };

  function parseType(type: string): string {
    if (type === "evp") {
      return "Aleatória";
    }

    if (type === "email") {
      return "E-mail";
    }

    if (type === "nationalRegistration") {
      return "CPF/CNPJ";
    }

    if (type === "celular") {
      return "Celular";
    }

    return type;
  }

  function parseStatus(status: string): string {
    if (status === "WAITING") {
      return "Aguardando";
    }

    if (status === "CREATED") {
      return "Aguardando";
    }

    if (status === "PROCESSED") {
      return "Em processamento";
    }

    if (status === "REGISTERED") {
      return "Registrada";
    }

    if (status === "PRE-CANCELED") {
      return "Em cancelamento";
    }

    if (status === "CANCELED") {
      return "Cancelada";
    }

    return status;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const inputWidth = `${Math.max(100, inputValue.length * 8)}px`; // Adjust the multiplier as needed
  const width = inputValue === "" ? "300px" : inputWidth;

  return (
    <>
      {openBalanceDialog && (
        <Transition.Root show={openBalanceDialog} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={handleCloseBalanceDialog}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                      {!qrImage && (
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <Money
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      <div className="mt-3 text-center sm:mt-5">
                        {!qrImage && (
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Recarregar Wallet
                          </Dialog.Title>
                        )}
                        <div className="mt-2">
                          <div
                            className="flex items-center justify-center p-4 pb-8"
                            onClick={handleDownloadQRCode}
                            // style={{ height: isClicked ? "30vh" : "5vh" }}
                          >
                            {isEmvData ? (
                              <QRCode
                                  size={256}
                                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                  value={isEmvData}
                                  viewBox={`0 0 256 256`}
                                />
                            ) : (
                              <QrContainerHidden>
                                {" "}
                                <p className="text-md text-gray-500">
                                  Recarga via Pix
                                </p>{" "}
                              </QrContainerHidden>
                            )}
                          </div>

                          <InputInsertValueQrCodeWallet
                            onChange={(event, value, masked) =>
                              handleChange(event, value, masked)
                            }
                          />

                          <div className="mt-5 flex flex-col justify-center gap-4">
                            <div className="flex flex-1 gap-4">
                              <Button
                                type="button"
                                style={{
                                  backgroundColor: "#333",
                                  border: "2px solid var(--cian) !important",
                                }}
                                title="Gerar Qr Code"
                                handleFunction={handleGenerateQRCode}
                              />

                              <PixButtonCopyPaste pixString={isEmvData} />
                            </div>

                            {!!Option && <></>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex justify-center">
                      {/* <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => {}}
                      >
                        Continuar
                      </button> */}
                      <button
                        onClick={handleCloseBalanceDialog}
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        // onClick={() => setOpenBalance(false)}
                        // ref={cancelButtonRef}
                      >
                        Cancelar
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default ModalComponent;

import { ArrowLeft } from "phosphor-react";
import { FC } from "react";

import PixwaveLogoGraySVG from "../../../assets/logo-black.png";
import CreateWalletPhoneFrameSVG from "../../../assets/CreateWalletPhoneFrameSVG.png";
import GooglePlayAppButton from "../../../assets/android.png";
import AppleAppButton from "../../../assets/ios.png";

interface PhoneFrameProps {
  onPress?: () => void;
}

export const PhoneFrame: FC<PhoneFrameProps> = ({ onPress }) => {
  return (
    <div>
      <div className="flex items-center gap-3 mb-8">
        <div className="outer-container">
          <button
            onClick={onPress}
            className="w-11 h-11 bg-black rounded-xl flex items-center justify-center inner-container"
          >
            <ArrowLeft weight="bold" color="white" size={26} />
          </button>
        </div>

        <img className="w-[35%] h-[35%]" src={PixwaveLogoGraySVG} alt="" />
      </div>

      <img className="w-[70%] h-[70%] max-2xl:w-[65%] max-2xl:h-[65%]" src={CreateWalletPhoneFrameSVG} alt="" />

      <div className="flex items-center my-4">
        <button>
          <img className="w-[80%] h-[80%] max-2xl:w-[50%] max-2xl:h-[50%]" src={GooglePlayAppButton} alt="" />
        </button>
        <button>
          <img className="w-[80%] h-[80%] max-2xl:w-[50%] max-2xl:h-[50%]" src={AppleAppButton} alt="" />
        </button>
      </div>

      <p className="font-extralight text-white text-lg max-2xl:text-xs">
        Copyright © 2024 - PIXWAVE - Todos os direitos reservados
      </p>
    </div>
  );
};

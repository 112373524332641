import styled, { css } from "styled-components";

import { ReactComponent as MobileIconSvg } from "../../assets/mobile-screen-solid.svg";
import { ReactComponent as ChargeIconSVG } from "../../assets/charge.svg";
import { ReactComponent as BillIconSVG } from "../../assets/bill.svg";
import { ReactComponent as TransferIconSVG } from "../../assets/transfer.svg";
import { ReactComponent as BellIconSVG } from "../../assets/bell.svg";
import { ReactComponent as SidebarIconSVG } from "../../assets/sidebarIcon.svg";
import { ReactComponent as CloseIconSVG } from "../../assets/close.svg";
import { ReactComponent as HomeIconSVG } from "../../assets/home.svg";
import { Link } from "react-router-dom";

type boxMessageProps = {
  isActive: boolean;
};

type otherAccountsProps = {
  isActive: boolean;
};

export const Container = styled.div`
  height: 100vh;
`;

export const Content = styled.div`
  grid-area: content;
  background-color: pink;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  display: flex;
`;

export const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  background-color: var(--white);
  position: relative;
  padding: 0 32px;
`;

export const SectionInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionIcon = styled.img``;

export const SectionTitle = styled.span`
  color: var(--gray-dark);
  font-size: 18px;
  font-family: var(--regular);
  margin-left: 16px;
`;

export const DashboardInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ClientInfo = styled.div`
  margin-left: 32px;
  border: 1px solid var(--gray-light-line);
  border-radius: 8px;

  width: 280px;

  background-color: var(--white);

  display: flex;
  flex-direction: column;

  position: absolute;
  right: 32px;
  top: 9px;
  display: none;

  @media (min-width: 720px) {
    display: unset;
  }
`;

export const OtherAccounts = styled.div<otherAccountsProps>`
  display: ${(props) => (props.isActive ? "block" : "none")};
`;

export const ArrowIcon = styled.img`
  height: 7px;
`;
export const AccountStats = styled.div`
  width: 100%;
  min-height: 192px;
  padding: 32px;
  gap: 32px;
  background-color: var(--black);

  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Card = styled.div`
  flex: 1;
  height: 90px;
  border-radius: 8px;
  padding: 14px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
  }

  > p {
    font-family: var(--light);
    font-size: 16px;
    color: var(--white);

    @media (min-width: 720px) {
      font-size: 12px;
    }
  }

  > span {
    font-family: var(--semiBold);
    font-size: 32px;
    color: var(--white);

    @media (min-width: 720px) {
      font-size: 24px;
    }
  }

  &.balance {
    background-color: var(--cian);
  }
  &.profitability {
    background-color: var(--green);
  }
  &.bills {
    background-color: var(--white);

    > p,
    span {
      color: var(--gray);
    }
  }

  @media (min-width: 720px) {
    height: 128px;
  }

  @media (min-width: 1000px) {
    > p {
      font-family: var(--light);
      font-size: 14px;
      color: var(--white);
    }
    > span {
      font-family: var(--semiBold);
      font-size: 28px;
      color: var(--white);
    }
  }

  @media (min-width: 1280px) {
    > p {
      font-family: var(--light);
      font-size: 16px;
      color: var(--white);
    }
    > span {
      font-family: var(--semiBold);
      font-size: 32px;
      color: var(--white);
    }
  }
`;
export const CardAccountInfo = styled.div`
  color: var(--black);
  max-height: 128px;
  border-radius: 8px;
  padding: 12px 20px;
  display: none;
  background-color: var(--white);

  > div {
    > p {
      font-size: 12px;
      color: var(--black);

      &.name {
        font-family: var(--semiBold);
        white-space: nowrap;
      }
      &.id {
        font-family: var(--light);
      }
      &.bankCode {
        font-family: var(--light);
      }
      &.agency {
        font-family: var(--light);
      }
      &.account {
        font-family: var(--light);
      }
    }
  }

  @media (min-width: 720px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @media (min-width: 1280px) {
    > div {
      > p {
        font-family: var(--light);
        font-size: 14px;
        color: var(--black);

        &.name {
          font-family: var(--semiBold);
          font-size: 15px;
        }
      }
    }
  }
`;

export const Transactions = styled.div`
  flex: 1;
  background-color: var(--background);

  padding: 32px;
  gap: 32px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "orders"
    "projection"
    "contacts"
    "items";

  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "orders orders projection contacts"
      "orders orders items items";
  }
`;

export const Items = styled.div`
  grid-area: items;
  gap: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 176px 176px;

  @media (min-width: 720px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(176px, 1fr);
    grid-gap: 32px;
  }
`;

export const ItemTitle = styled.span`
  font-size: 16px;
  font-family: var(--semiBold);
  color: var(--white);
  margin-bottom: 19px;
`;

export const BellWrapper = styled.div`
  position: relative;

  @media (min-width: 720px) {
    margin-right: 320px;
  }
`;

export const BellNotifications = styled.div`
  background-color: var(--red);
  height: 13px;
  width: 13px;
  position: absolute;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;

  > p {
    font-size: 10px;
    color: var(--white);
    font-family: var(--regular);
  }
`;

export const Box = styled.div`
  > span {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white);
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -5px;
  }
`;

export const BoxNotifications = styled.div`
  width: 280px;
  height: 280px;
  overflow: scroll;
  padding: 16px;

  ::-webkit-scrollbar {
    visibility: hidden;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const BoxWrapper = styled.div<boxMessageProps>`
  display: ${(props) => (props.isActive ? "inline-block" : "none")};

  background-color: var(--white);
  border-radius: 8px;
  position: absolute;

  left: -130px;
  top: 30px;

  box-shadow: 0px 0px 10px -5px black;

  z-index: 2;
`;

export const Action = styled(Link)`
  background-color: var(--gray);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  &::after {
    content: "";
    width: 16px;
    height: 4px;
    background-color: var(--gray);
    position: absolute;
    top: 0;
  }
`;

const iconStyle = css`
  fill: var(--white) !important;
  width: 64px;
`;

export const MobileIcon = styled(MobileIconSvg)`
  ${iconStyle}
`;
export const ChargeIcon = styled(ChargeIconSVG)`
  ${iconStyle}
`;
export const BillIcon = styled(BillIconSVG)`
  ${iconStyle}
`;
export const TransferIcon = styled(TransferIconSVG)`
  ${iconStyle}
`;
export const BellIcon = styled(BellIconSVG)``;
export const SidebarIcon = styled(SidebarIconSVG)`
  ${iconStyle}
`;
export const CloseIcon = styled(CloseIconSVG)`
  ${iconStyle}
`;
export const HomeIcon = styled(HomeIconSVG)`
  fill: var(--cian);
`;

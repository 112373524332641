import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { service, serviceError } from "../../services/ajio";

import {
  ArrowRightIcon,
  BillIcon,
  Box,
  BoxButton,
  BoxInput,
  BoxText,
  Container,
  Content,
  ContentWrapper,
  InputWrapper,
} from "./styles";

export const PayBillsPayInvoice: React.FC = () => {
  const [barCode, setBarCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigation = useNavigate();
  const handleNextScreen = async () => {
    setLoading(true);
    setError("");

    try {
      const { data } = await service.post("payments/validate", {
        barCode: barCode.replace(/[\s,-,/]/g, ""),
      });

      navigation("/paybills/info-invoice", {
        state: { ...data.payment },
      });
    } catch (err) {
      setLoading(false);
      setError(serviceError(err).friend);
    }
  };

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Box>
            <BoxText>
              <h2>Realizar Pagamento de Boleto </h2>
              <span>Insira os dados do boleto</span>
            </BoxText>
            <BoxInput>
              <InputWrapper>
                <BillIcon />
                <input
                  type="text"
                  placeholder="Insira os dados do boleto"
                  value={barCode}
                  onChange={(input) => setBarCode(input.target.value)}
                />
              </InputWrapper>
              <BoxButton>
                <Button
                  handleFunction={handleNextScreen}
                  isLoading={loading}
                  title="Pagar"
                >
                  {/* <p>Pagar</p>
                  <ArrowRightIcon/> */}
                </Button>
              </BoxButton>
            </BoxInput>

            <BoxText>
              {error && <div className="block mt-3 text-red-500">{error}</div>}
            </BoxText>
          </Box>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

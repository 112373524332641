import styled, { css } from "styled-components";
import { motion } from "framer-motion";

import { ReactComponent as SidebarIconSVG } from "../../assets/sidebarIcon.svg";
import { ReactComponent as CloseIconSVG } from "../../assets/close.svg";
import { ReactComponent as BellIconSVG } from "../../assets/bell.svg";
import { ReactComponent as HomeIconSVG } from "../../assets/home.svg";
import { ReactComponent as ReceiptIconSVG } from "../../assets/receipt.svg";
import { ReactComponent as BankMoneySVG } from "../../assets/bankmoney.svg";
import { ReactComponent as ArrowPageRightIconSVG } from "../../assets/arrowPageRight.svg";
import { ReactComponent as ArrowUpRightIconSVG } from "../../assets/arrow-up-right.svg";
import { ReactComponent as SettingsIconSVG } from "../../assets/settings-2.svg";


import { ReactComponent as ArrowUpIconSVG } from "../../assets/arrowUp.svg";

type otherAccountsProps = {
  isActive: boolean;
};

type boxMessageProps = {
  isActive: boolean;
};

const iconStyle = css`
  fill: var(--white);
  width: 64px;
`;

const iconHeaderStyle = css`
  fill: var(--white);
`;


export const SettingsIcon = styled(SettingsIconSVG)`
  ${iconHeaderStyle}
`;


export const ArrowUpRightIcon = styled(SettingsIconSVG)`
 ${iconHeaderStyle}
`;

export const HomeIcon = styled(HomeIconSVG)`
  ${iconHeaderStyle}
`;
export const ReceiptIcon = styled(ReceiptIconSVG)`
  ${iconHeaderStyle}
`;
export const BankMoney = styled(BankMoneySVG)`
  ${iconHeaderStyle}
`;
export const ArrowPageRightIcon = styled(ArrowPageRightIconSVG)`
  ${iconHeaderStyle}
  margin-left: 24px;
  fill: var(--white);
`;

export const ArrowUpIcon = styled(ArrowUpIconSVG)`
  fill: var(--white !important);
`;

export const SidebarIcon = styled(SidebarIconSVG)`
  ${iconStyle}
`;
export const CloseIcon = styled(CloseIconSVG)`
  ${iconStyle}
`;
export const BellIcon = styled(BellIconSVG)``;

export const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  //border-bottom: 1px solid black;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 10vh;
  background-color: var(--white);
  position: relative;
  padding: 0 32px;
`;

export const SectionInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionIcon = styled.img``;

export const SectionTitle = styled.span`
  color: var(--gray);
  font-size: 18px;
  font-family: var(--regular);
  margin-left: 16px;
`;

export const DashboardInfo = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75vw;
  max-height: 10vh;
  margin-right: 2px;
  gap: 8px;
`;

export const ClientInfo = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: space-between;
margin-left: 25px;
 //border: 1px solid var(--gray-light-line); 
//border-radius: 8px;
//cursor: pointer;
width: 140px;
z-index: 1010;
height: 8vh;
background: var(--white);
//display: flex;
//flex-direction: column;
position: absolute;
top: 20px;
justify-content: left !important;
color: black !important ;
@media (min-width: 720px){
    display: unset;
    background-color: var(--white);
    justify-content: left !important;
    color: black !important;

}
`
export const SidebarButton = styled.button`
  background: none;
  border-style: none;
  height: 80px;
  width: 80px;
  background-color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const BellWrapper = styled.div`
  position: relative;

  @media (min-width: 720px) {
    padding: 1px;
  }
`;
export const ButtonBell = styled.button`
  background: none;
  outline: 0;
  border-style: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
`;

export const BellNotifications = styled.div`
  background-color: var(--red);
  height: 13px;
  width: 13px;
  position: absolute;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 1000;

  > p {
    font-size: 10px;
    color: var(--white);
    font-family: var(--regular);
  }
`;

export const Box = styled.div`
  > span {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white);
    position: absolute;
    margin-left: 240px;
    top: -5px;

    @media (min-width: 720px) {
      margin-right: auto;
      margin-left: auto;
      left: 0;
      right: 0;
      top: -5px;
    }
  }
`;

export const BoxNotifications = styled.div`
  width: 280px;
  height: 280px;
  overflow: scroll;
  padding: 16px;

  ::-webkit-scrollbar {
    visibility: hidden;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const BoxWrapper = styled(motion.div)`
  background-color: var(--white);
  border-radius: 8px;
  position: absolute;

  left: -230px;
  top: 40px;

  @media (min-width: 720px) {
    left: -125px;
    top: 40px;
  }

  box-shadow: 0px 0px 10px -5px black;

  z-index: 2;
`;

export const OtherAccounts = styled(motion.div)``;

export const ButtonWrapper = styled.div`
  margin-right: 32px;
  display: none;

  @media (min-width: 720px) {
    display: unset;
  }
`;

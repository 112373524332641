import React, { CSSProperties } from "react";

import { Container } from "./styles";

interface Props {
  buttonText?: string;
  linkTo?: string;
  onClick?: () => void;
  style?: CSSProperties; 

}

const ButtonOpenAccount: React.FC<Props> = ({
  buttonText = "Abrir minha conta",
  linkTo = "/create-account-wallet",
  onClick,
  style 
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Container href={linkTo ? linkTo : undefined} onClick={handleClick} style={style}>
        <span>{buttonText}</span>
      </Container>
    </>
  );
};

export default ButtonOpenAccount;


import styled from "styled-components"

import {ReactComponent as PixIconSVG} from '../../assets/pix.svg'
import {ReactComponent as LockIconSVG} from '../../assets/lock.svg'
import {ReactComponent as MailIconSVG} from '../../assets/mail.svg'

export const PixIcon = styled(PixIconSVG)`
fill: var(--cian);
`

export const LockIcon = styled(LockIconSVG)`
fill: var(--cian);
`

export const MailIcon = styled(MailIconSVG)`
fill: var(--cian);
`

export const Container = styled.div`

height: calc(100vh - 80px);
background-color: var(--background);


`

export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;


`

export const Table = styled.div`

flex: 1;
padding: 32px;

`


export const TableWrapper = styled.div`

  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px){
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px){
      width: 100%;
  }


> table {

    width: 100%;
    border-radius: 8px;
    border: none;
    border-collapse: collapse;

    tr {
        position: relative;

        &:first-child {

            &::after {
                content: '';
                height: 16px;
                width: 4px;
                background-color: var(--cian);
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
            }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
            text-align: start;
            font-family: var(--semiBold);
            font-size: 14px;
            color: var(--gray);
            padding: 16px;


            @media (min-width: 720px){
                font-size: 16px;
                padding: 21px 32px;
            }
        }

        > td {
            padding: 6px 16px;
            font-family: var(--regular);
            font-size: 10px;
            color: var(--gray-light-text);

            &:last-child {
                color: var(--red);
            }

                @media (min-width: 720px){
                    font-size: 14px;
                    padding: 12px 32px;

                }

            }
        }
    }


`


export const TitleContainer = styled.div `

display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 140px; /* Espaçamento inferior */
  margin-bottom: 20px; /* Espaçamento inferior */
`;
export const Title = styled.h1`
  font-family: 'Arial', sans-serif; /* Escolha a fonte desejada */
  font-size: 2.5rem; /* Tamanho da fonte */
  color: #333; /* Cor do texto */
  margin: 0; /* Remove margens padrão para melhorar a aparência */
`;

export const StyledTextContainer = styled.div`
font-family: 'Arial', sans-serif; /* Escolha a fonte desejada */
color: #333; /* Cor do texto */
line-height: 1.5; /* Espaçamento entre linhas */
margin-top: 50px; /* Espaçamento inferior */

margin-bottom: 50px; /* Espaçamento inferior */
//border: 1px solid black;
margin-left: 3rem;
span {
  display: block;
  margin-bottom: 10px; /* Espaçamento inferior entre os spans */
}
`;

import React from "react";
import { CardActionOption } from "../../components/cardActionOption";
import { useAuth } from "../../hooks/auth";

import {
  Container,
  Content,
  OptionsWrapper,
  // PixHelpMeIcon,
  PixIcon,
  QrCodeIcon,
  PixMyKeysIcon,
} from "./styles";

export const PixOptions = () => {
  const { user } = useAuth();

  return (
    <Container>
      <Content>
        <OptionsWrapper>
          <CardActionOption
            linkTo="/pix/transfer"
            Icon={PixIcon}
            title={`Transferir`}
          />
          <CardActionOption
            linkTo="/pix/copiaecola"
            Icon={PixIcon}
            title={"Copia e Cola"}
          />
          {user.role === "GRAPHIC" || user.role === "WALLET" ? null : (
            <CardActionOption
              linkTo="mykeys"
              Icon={PixMyKeysIcon}
              title={"Minhas chaves"}
            />
          )}
          {/*    {user.type === "GRAPHIC" ? null : (
     <CardActionOption
        linkTo="/pix/configKeyControlAccount"
        Icon={PixMyKeysIcon}
        title={"Config. Chave Control Account"}
          />
    )}        */}

          <CardActionOption
            linkTo="/pix/qrcodeStatic"
            Icon={QrCodeIcon}
            title={"Cobrança Estática"}
          />

          <CardActionOption
            linkTo="/pix/favorites"
            Icon={QrCodeIcon}
            title={"Pix Favoritos"}
          />

          {user.role === "GRAPHIC" || user.role === "WALLET" ? null : (
            <CardActionOption
              linkTo="/pix/qrcodeDynamic"
              Icon={QrCodeIcon}
              title={"Cobrança Dinâmica"}
            />
          )}
        </OptionsWrapper>
      </Content>
    </Container>
  );
};

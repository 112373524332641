import styled from "styled-components";
import { ReactComponent as ArrowPageLeftIconSVG } from "../../assets/arrowPageLeft.svg";

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`
  fill: var(--cian);
  width: 16px;
`;

export const Container = styled.div`
  height: 100vh;
  padding: 32px;
  background-color: var(--background);
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.div`
  max-width: 460px;
`;

export const HeaderForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
`;

export const GoBackButton = styled.button`
  border-style: none;
  margin-right: 24px;
  height: 40px;
  width: 40px;
  background: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
`;

export const FormTitle = styled.h2`
  font-size: 24px;
  font-family: var(--bold);
  color: var(--gray-dark);
`;

export const FormWrapper = styled.div`
  padding: 35px 24px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  gap: 30px;
`;

export const ActionText = styled.label`
  font-size: 16px;
  font-family: var(--regular);
  color: var(--gray-dark);
  margin-bottom: 10px;
`;

export const TextAreaInput = styled.textarea`
  width: 100%;
  height: 124px;
  resize: none;
  border: 1px solid var(--gray-light-line);
  border-radius: 4px;
  margin-top: 24px;
  padding: 16px 14px;
  font-family: var(--semiBold);
  font-size: 14px;
  outline: none;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  max-width: 460px;
`;
export const WrapperInputs = styled.div`
  display: flex;
  gap: 15px;
`;

export const FormItem = styled.div`
  display: grid;
`;

export const WrapperHeader = styled.div`
  margin-bottom: 44px;
`;

export const Input = styled.input`
  border: 1px solid var(--gray-light-text);
  height: 35px;
  border-radius: 4px;
  width: 100%;
  padding-left: 10px !important;
  &.ddd {
    width: 15%;
    padding: 0 !important;
    text-align: center;
  }
`;

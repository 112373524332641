import { FC, useRef, useState } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { UploadSimple } from "phosphor-react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "src/services/firebase";
import { api } from "src/services/api";

export const SendDocument: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const [loading, setLoading] = useState(false);
  const [frontImage, setFrontImage] = useState<any>(null);
  const [backImage, setBackImage] = useState<any>(null);

  const navigate = useNavigate();

  const frontImageInputRef = useRef<any>(null);
  const backImageInputRef = useRef<any>(null);

  const handleFrontImageUpload = () => {
    frontImageInputRef?.current?.click();
  };

  const handleBackImageUpload = () => {
    backImageInputRef?.current?.click();
  };

  const handleSelectFrontImage = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setFrontImage(file);
    }
  };

  const handleSelectBackImage = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      setBackImage(file);
    }
  };

  const handleSavePhoto = async () => {
    if (!frontImage || !backImage) {
      return;
    }

    setLoading(true);
    const frontStorageRef = ref(storage, `documents/${frontImage.name}`);
    const frontImageUploadTask = uploadBytesResumable(frontStorageRef, frontImage);

    frontImageUploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        setLoading(false);
        console.log(error);
      },
      async () => {
        const frontDocumentUri = await getDownloadURL(frontImageUploadTask.snapshot.ref);

        if (frontDocumentUri) {
          const backStorageRef = ref(storage, `documents/${backImage.name}`);
          const backImageUploadTask = uploadBytesResumable(backStorageRef, backImage);

          backImageUploadTask.on(
            "state_changed",
            () => {},
            (error) => {
              setLoading(false);
              console.log(error);
            },
            async () => {
              const backDocumentUri = await getDownloadURL(backImageUploadTask.snapshot.ref);

              if (backDocumentUri) {
                const addressData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-03")));
                const planData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-02")));
                const userData = JSON.parse(String(localStorage.getItem("@yntech:signup-data-01")));
                const selfUri = JSON.parse(String(localStorage.getItem("@yntech:documentUri")));

                const data = {
                  ...addressData,
                  ...planData,
                  ...userData,
                  selfUri,
                  documentBack: backDocumentUri,
                  documentFront: frontDocumentUri,
                };

                // Fazer a requisição
                await api.post("/verify/create", {
                  data,
                  rg_cnh: planData.documentNumber.replace(/[/,.,-\s]/g, ""),
                });

                // Limpar o localStorage
                localStorage.removeItem("@yntech:signup-data-03");
                localStorage.removeItem("@yntech:signup-data-02");
                localStorage.removeItem("@yntech:signup-data-01");
                localStorage.removeItem("@yntech:signup-data-partner");
                localStorage.removeItem("@yntech:signup-data-partner-address");

                // Navegar
                setLoading(false);
                navigate("/create-account-wallet/success", { state: { accountType } });
              }
            }
          );
        }
      }
    );
  };

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/identification-alert", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 20 }} width="750" height="95vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="700"
            height="90vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[8vh] ml-14">
          <p className="text-white font-extralight text-2xl">
            Precisamos que nos envie as fotos <br />
            do <strong className="text-white font-bold text-2xl">seu</strong> documento
          </p>

          <h3 className="text-white font-extralight text-lg mt-3">Selecione a foto em seu computador:</h3>

          <h3 className="text-white font-extralight text-lg mt-3">Frente</h3>
          <input
            type="file"
            accept="image/*"
            onChange={handleSelectFrontImage}
            ref={frontImageInputRef}
            style={{ display: "none" }}
          />

          <button
            onClick={handleFrontImageUpload}
            className="w-[35rem] h-36 rounded-2xl bg-white flex items-center justify-center mt-3 mb-3"
          >
            {frontImage !== null ? (
              <img
                className="w-[35rem] h-36 rounded-2xl overflow-hidden object-contain"
                src={URL.createObjectURL(frontImage)}
                alt=""
              />
            ) : (
              <UploadSimple color="#CFCFCF" size={32} />
            )}
          </button>

          <h3 className="text-white font-extralight text-lg mt-3">Verso</h3>

          <input
            type="file"
            accept="image/*"
            onChange={handleSelectBackImage}
            ref={backImageInputRef}
            style={{ display: "none" }}
          />

          <button
            onClick={handleBackImageUpload}
            className="w-[35rem] h-36 rounded-2xl bg-white flex items-center justify-center mt-3 mb-3"
          >
            {backImage !== null ? (
              <img
                className="w-[35rem] h-36 rounded-2xl overflow-hidden object-contain"
                src={URL.createObjectURL(backImage)}
                alt=""
              />
            ) : (
              <UploadSimple color="#CFCFCF" size={32} />
            )}
          </button>

          <p className="text-white font-extralight text-sm mt-3">
            O ambiente deve está bem iluminado de forma moderada. Dê <br />
            preferência á iluminação natural. Tome cuidado com os reflexos <br />
            que possam deixara sua foto com uma visualização ruim.{" "}
          </p>

          <div className="flex gap-4 mt-10">
            <GradientButton
              isLoading={loading}
              title="Voltar"
              tailwindStyles="bg-[#171717] text-white w-[17.7rem] h-12"
              onClick={() => navigate("/create-account-wallet/identification-alert", { state: { accountType } })}
            />
            <GradientButton
              isLoading={loading}
              title="Avançar"
              tailwindStyles="bg-[#274683] text-white w-[17.7rem] h-12"
              onClick={handleSavePhoto}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { DuplicateIcon } from "@heroicons/react/solid";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { Skeleton } from "../../components/skeleton";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios'; 
import { useAuth } from "../../hooks/auth";

import {
  ActionPixTable,
  // KeyCpnjIcon,
  // KeyPixTable,
  // ShareIcon,
} from "../extract/styles";
import { Container, Content, StyledTextContainer, Table, TableWrapper, Title, TitleContainer } from "./styles";
import { useNotification } from "../../hooks/notification";
import { useNavigate } from "react-router-dom";

interface AuthState {
    token: string;
  }
  

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export const PasswordEletronicControlAccountChange = () => {
    const token = localStorage.getItem('@stricv2:token');

  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState<KeysProps[]>([]);

  const navigate = useNavigate();
  const { setOpenNotification } = useNotification();

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [key, setKey] = useState<KeysProps>({} as KeysProps);

  const [inputValue, setInputValue] = useState("");
  const [isLocked, setIsLocked] = useState(true);

  const { signIn, account, user } = useAuth(); 

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  const handleLock = () => {
    setIsLocked(!isLocked);
  };

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const handleSave = async () => {
    if (!isLocked && inputValue.length >= 8 && !containsDuplicateCharacters(inputValue)) {
      try {
        const userId = user.id;
console.log(userId, "eu sou o id:")
       console.log('Antes da requisição PATCH');
      console.log('userId:', userId);
      console.log('inputValue:', inputValue);


        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/graphic/change-password-eletronic/${userId}`, { newPasswordEletronic: inputValue  }, config);
        console.log('Resposta da requisição PATCH:', response);
       
        if (response.status === 200) {
          setOpenNotification({
            type: 'success',
            title: 'Senha Eletrõnica atualizada',
            notification: 'Sua senha Eletrônica foi atualizada com sucesso!',
          });
          // Se necessário, adicione aqui uma função para atualizar o estado ou refazer a busca de chaves no banco de dados
        }
      } catch (error) {
        setOpenNotification({
          type: 'error',
          title: 'Erro ao atualizar a senha Eletrônica!',
          notification: 'Houve um erro ao tentar atualizar sua Senha Eletrônica. Por favor, tente novamente mais tarde.',
        });
      }
    }
  };
  

// Definindo a função identificarTipoString
function identificarTipoString(str: string): string {

    const regexPasswordEletronic = /^(?!.*(\d)(?:-?\1){4,})[0-9]{8}$/;
  
    if (regexPasswordEletronic.test(str)) {
      return "Senha Validada";
    } else {
      return "Formato Inválido";
    }
  }
  

// Chamar a função e exibir o tipo de string identificado
const tipoStringIdentificado = identificarTipoString(inputValue);
console.log(tipoStringIdentificado);



const mockKeys = [
  {
    key: "key1",
    keyType: "type1",
    keyStatus: "status1",
    dateKeyCreated: "date1"
  }
  // Adicione mais chaves se necessário
];

useEffect(() => {
  setKeys(mockKeys);
  setLoading(false);
}, []);


const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Impede a digitação de caracteres não numéricos
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  
    if (!isLocked && e.key === 'Backspace') {
      // Permitir exclusão mesmo quando está desbloqueado
      setInputValue((prevValue) => prevValue.slice(0, -1));
    } else if (!isLocked && inputValue.length >= 8 && e.key !== 'Backspace') {
      // Impedir adição de mais caracteres quando atingir o limite
      e.preventDefault();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isLocked) {
        setInputValue(e.target.value);
    }
  };
  
  const containsDuplicateCharacters = (str: string): boolean => {
    for (let i = 0; i < str.length - 5; i++) {
      if (str.substring(i, i + 6) === str[i].repeat(6)) {
        return true;
      }
    }
    return false;
  };


  const inputWidth = `${Math.max(200, inputValue.length * 8)}px`; // Adjust the multiplier as needed

  return (
    <Container>
      <Content>


<TitleContainer>  
<Title>Crie ou altere a sua Senha Eletrônica por aqui!</Title>
</TitleContainer> 

<StyledTextContainer> 

              <span>
              Personalize sua experiência de segurança configurando sua Senha Eletrônica. Digite uma nova senha composta por 8 dígitos numéricos não sequenciais. Ao clicar no botão 'Editar', insira sua nova senha na primeira entrada do formulário e, em seguida, finalize clicando em 'Enviar'. Caso esqueça sua senha, sinta-se à vontade para repetir esse processo e criar uma nova senha sempre que necessário. Estamos aqui para garantir a segurança e praticidade em sua conta.                              </span>
                <br/>
              <span>Por que preciso de uma senha eletrônica? </span>
                <span>  Simples: Maior proteção, menos preocupações! Personalize sua senha eletrônica com 8 dígitos não sequenciais, assegurando transações seguras e uma experiência tranquila.</span>

                </StyledTextContainer>  
                
        <Table>
         
          <TableWrapper>
          <Skeleton space={4} isActive={loading} repeat={3} />
            <table>
             {!loading  && (
              <thead>
               <tr>
                  <th>Senha Eletrônica</th>
                  <th>Confirmar</th>
                  <th>Copiar Senha</th>
                  <th>Senha Valida</th> 
                  <th>Status</th>              
             
                </tr>
              </thead>
             )}

             <tbody>
              {keys.map(key => (
                <tr key={key.key}>
                  <td> <input 
        id="passwordInput"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Nova Senha Eletrônica:"
        title="Digite 8 números não sequenciais."
        style={{
          opacity: isLocked ? 0.5 : 1,
          backgroundColor: isLocked ? 'lightgrey' : 'white',
          border: isLocked ? '2px solid black' : '2px solid black',
          borderRadius: '4px',
          width: isLocked ? inputWidth : inputWidth,
          padding: "4px",
          paddingBottom: '2px',
          textAlign: 'center',
          color: 'black',
        }}      />
        </td>
<td> 
<button
  onClick={() => {
    if (isLocked) {
      handleLock();
    } else {
      handleSave();
    }
  }}
  id="submitButton"
  disabled={!isLocked && (inputValue.length < 6 || containsDuplicateCharacters(inputValue))}
  style={{
    backgroundColor: isLocked ? '#3498db' : '#4caf50',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    paddingBottom: '2px',
    transition: 'background-color 0.3s',
    cursor: !isLocked && (inputValue.length < 6 || containsDuplicateCharacters(inputValue)) ? 'not-allowed' : 'pointer',
  }}
>
  {isLocked ? 'Editar' : 'Salvar'}
</button>
</td>
                  <td>
                      <ActionPixTable>
                      <CopyToClipboard text={inputValue}
                        onCopy={() =>
                          setOpenNotification({
                            type: 'success',
                            title: 'Senha copiada',
                            notification: 'Senha copiada com sucesso.'
                          })
                        }>
                          <DuplicateIcon width={21} color="var(--cian)"  className="cursor-pointer"/>
                      </CopyToClipboard>
                      </ActionPixTable> 
                    </td>
                  <td>{identificarTipoString(inputValue)}</td>
                  <td style={{ color: identificarTipoString(inputValue) === 'Formato não reconhecido' ? 'red' : (isLocked ? 'green' : 'red') }}>
  {identificarTipoString(inputValue) === 'Formato não reconhecido' ? 'AGUARDANDO' : (isLocked ? 'CONCLUÍDO' : 'AGUARDANDO')}
</td>
               </tr>
              ))}
              </tbody>            
            </table>
          </TableWrapper>
        </Table>
      </Content>
     
    </Container>
  );
};


import React from "react";
import { ModalInvoiceContextProvider } from "./modalInvoice";
import { ModalPixContextProvider } from "./modalPix";
import { ModalTransferContextProvider } from "./modalTransfer";
import { AuthProvider } from './auth';
import { ClientProvider } from './client';
import { NotificationProvider } from "./notification";
import { AuthBackofficeProvider } from "./authBackoffice";
import { SocketContextProvider } from "./SocketContext";

export const Hooks: React.FC = ({ children }) => {
  return (
    <>
    <ClientProvider>
      <AuthBackofficeProvider>
        <AuthProvider>
          <NotificationProvider>
          <ModalTransferContextProvider>
            <ModalInvoiceContextProvider>
              <ModalPixContextProvider>{children}</ModalPixContextProvider>
            </ModalInvoiceContextProvider>
          </ModalTransferContextProvider>
          </NotificationProvider>
        </AuthProvider>
      </AuthBackofficeProvider>
    </ClientProvider>
    </>
  );
};

import React, { useState } from 'react';
import onImage from './../../../assets/on.png';
import offImage from './../../../assets/off.png';
import {ToogleDiv} from './../styles';
import axios from 'axios';
import { useNotification } from 'src/hooks/notification';

interface ToggleSwitchFavoriteProps {
    userId: string; // Passar o ID da conta como propriedade
    initialStatus: boolean; // Passar o status inicial como propriedade
  }

  const ToggleSwitchFavorite: React.FC<ToggleSwitchFavoriteProps> = ({ userId, initialStatus }) => {
    const [isOn, setIsOn] = useState(initialStatus);
    const token = localStorage.getItem('@stricv2:token');
    const { setOpenNotification } = useNotification();

    const handleToggle = () => {
        const newStatus = !isOn;
        setIsOn(newStatus);      
        updateStatusOnServer(userId, !isOn);
    };



  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const updateStatusOnServer = async (accountId: string, newStatus: boolean) => {
    try {
      // Fazer chamada para API para atualizar o status no servidor
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/graphic/updateStatusGraphicTransaction/${accountId}`,
        { status_pin_eletronic: newStatus }, config);
                console.log('Resposta da requisição PATCH:', response);
              
        
      if (response.status === 200) {
          setOpenNotification({
            type: 'success',
            title: 'Atualização Concluída:',
            notification: newStatus
            ? 'Permissão Concedida com Sucesso!'
            : 'Permissão Cancelada com Sucesso!',  
                });
          // Se necessário, adicione aqui uma função para atualizar o estado ou refazer a busca de dados no banco de dados
        }
      } catch (error) {
       console.error('Error updating status on the server:', error);
      // Reverter o estado em caso de erro
            setIsOn(!isOn);
        setOpenNotification({
          type: 'error',
          title: 'Erro na tentativa de atualização!',
          notification: 'Houve um erro ao tentar atualizar, tente novamente mais tarde.',
        });
      }
  };

  return (
    <ToogleDiv onClick={handleToggle} style={{ cursor: 'pointer' }}>
      <img
        src={isOn ? onImage : offImage}
        alt={isOn ? 'On' : 'Off'}
        style={{ width: '100px', height: '40px' }}
      />
    </ToogleDiv>
  );
};

export default ToggleSwitchFavorite;

import React from "react";
import {
  BoxInfoWrapper,
  BoxMessageTitle,
  BoxMessageDescription,
  BoxLink,
  Container,
} from "./styles";
import moment from "moment";

interface Notification {
  accountId?: string | null;
  created_at?: string;
  deleted_at?: string | null;
  graphicAccountId?: string;
  icon?: string | null;
  id?: string;
  idSchema?: string | null;
  isRead?: boolean;
  message?: string;
  path?: string | null;
  schema?: string;
  title?: string;
  updated_at?: string;
}

interface Props {
  notification: Notification;
}
export const BellMessage: React.FC<Props> = ({ notification }) => {
  return (
    <Container>
      <BoxInfoWrapper>
        <BoxMessageTitle>{notification.title}</BoxMessageTitle>
        <BoxMessageDescription>{notification.message}</BoxMessageDescription>
        <BoxMessageDescription>
          {moment(notification.created_at).format("DD/MM/YYYY HH:mm:ss")}
        </BoxMessageDescription>
      </BoxInfoWrapper>
      {/* <BoxLink href={link}>Ver</BoxLink> */}
    </Container>
  );
};

import styled, { css } from "styled-components";
//
import ButtonSignIn2 from "../../../src/assets/button-color.svg";

import { ReactComponent as EyeEnabledIconSVG } from "../../assets/eyeEnabled.svg";
import { ReactComponent as EyeDisabledIconSVG } from "../../assets/eyeDisabled.svg";
import { Link } from "react-router-dom";
import logby from "../../../src/assets/background_login.png";

interface WrapperInterface {
  image: string;
  bank: string;
}

export const EyeEnabledIcon = styled(EyeEnabledIconSVG)`
  fill: var(--black);
`;
export const EyeDisabledIcon = styled(EyeDisabledIconSVG)`
  fill: var(--black);
`;

export const Container = styled.div<WrapperInterface>`
  ${(props) =>
    css<WrapperInterface>`
      background: url(${(props) => props.image}) no-repeat;
      background-size: cover;
    `}

  @media (max-width: 1100px) {
    padding: 0px 60px;
  }
  @media (max-width: 720px) {
    justify-content: center;
    padding: 0px;
  }
  gap: 0 20px;

  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 40px;
`;


export const WrapperBackground = styled.div<WrapperInterface>`
  /* background-color: var(--loginBackground); */
  ${(props) =>
    props.bank !== "PIXWAVE" &&
    css<WrapperInterface>`
      // background: url(${(props) => props.image}) no-repeat;
      background-size: cover;
    `}
  height: 100vh;
  flex: 1;
  display: none;

  @media (max-width: 720px) {
    background: linear-gradient(
      196deg,
      #1a70b4 9.36%,
      rgba(217, 217, 217, 0.1) 100.78%
    );
  }

  @media (min-width: 720px) {
    display: block;
  }
`;

export const ImageLogo = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    width: 100%;
    height: 104px;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }
`;

export const WrapperContent = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 720px) {
    background-color: #ffffff70;
    width: 100%;

    .hide-on-small-screens {
      display: none !important;
    }
  }

  @media (max-width: 1100px) {
    align-items: flex-end;
  }
`;

export const Content = styled.div`
  height: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  @media (max-width: 720px) {
    align-items: center;
    align-self: center;
  }
`;

export const TitleMobile = styled.div`
  display: none;
  @media (max-width: 720px) {
    margin-top: 4px;
    font-size: 24px;
    color: var(--colorLogin);
    font-family: var(--regular);

    > b {
      font-family: var(--semiBold);
      > h1 {
        font-size: 50px; /* Ajuste o tamanho conforme necessário */
        margin-top: 8px; /* Ajuste o espaçamento conforme necessário */
        display: flex;
        justify-content: center;
        color: #00cbda;
      }
    }

    > h1 {
    }
  }
`;

export const Title = styled.div`
  margin-top: 24px;
  font-size: 24px;
  color: var(--colorLogin);
  font-family: var(--regular);

  > b {
    font-family: var(--semiBold);
  }

  @media (max-width: 720px) {
    .hide-on-small-screens {
      display: none;
    }
  }

  @media (min-width: 1280px) {
    font-size: 24px;
  }
`;

export const Separator = styled.div`
  margin-top: 25px;
  width: 42px;
  height: 4px;
  background: var(--cian);

  @media (max-width: 720px) {
    display: none;
  }
`;

export const Reference = styled.span`
  margin-top: 18px;
  font-size: 16px;
  color: var(--colorLogin);
  font-family: var(--regular);

  @media (max-width: 720px) {
    display: none;
  }
`;

export const WrapperLogo = styled.div<WrapperInterface>`
  width: 100%;
  display: ${(props) => (props.bank === "PIXWAVE" ? "none" : "block")};
`;

export const WrapperForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 42px;
`;

export const DivInputMobile = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    width: 329px;
    height: 65px;
    border-radius: 65px;
    background-color: transparent;
    padding: 10px;
    box-shadow: 10px -10px 20px 0px #00000026;
    margin-botom: 10px;
    input {
      background-color: transparent;
      border-left: 2px solid;
      padding-inline-start: 10px;
    }
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--black);
  border-radius: 4px;
  height: 66px;
  width: 100%;
  padding: 10px 11px;

  & + div {
    margin-top: 24px;
  }

  > span {
    flex: 1;
    color: var(--black);
    font-family: var(--regular);
    font-size: 14px;
  }

  > input {
    border-style: none;
    flex: 1;
    background: transparent;
    color: var(--colorLogin);
    font-family: var(--semiBold);
    outline: 0;

    &::placeholder {
      color: var(--colorLogin);
      opacity: 1;
      font-family: var(--semiBold);
      font-size: 14px;
    }
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ButtonEyeVisibility = styled.button`
  background: none;
  border-style: none;
  cursor: pointer;
`;

export const ButtonVisibility = styled.button`
  background: none;
  border-style: none;
  cursor: pointer;
`;

export const DivInputPasswordMobile = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    width: 329px;
    height: 65px;
    border-radius: 65px;
    background-color: transparent;
    box-shadow: 10px -10px 20px 0px #00000026;
    padding: 12px;
    gap: 7px;
    margin-top: 20px;
    margin-bottom: 30px;
    input {
      background-color: transparent;
      border-left: 2px solid;
      padding-inline-start: 10px;
    }
  }
`;

export const InputPassword = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--black);
  border-radius: 4px;
  height: 66px;
  width: 100%;
  padding: 10px 11px;

  & + div {
    margin-top: 24px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    > span {
      flex: 1;
      color: var(--black);
      font-family: var(--regular);
      font-size: 14px;
    }

    > input {
      border-style: none;
      flex: 1;
      background: transparent;
      color: var(--colorLogin);
      font-family: var(--semiBold);
      outline: 0;

      &::placeholder {
        color: var(--colorLogin);
        opacity: 1;
        font-family: var(--semiBold);
        font-size: 14px;
      }
    }
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ForgetPassword = styled.a`
  color: "4D4D4D";
  font-family: var(--semiBold);
  font-size: 16px;
  align-self: center;
  margin-top: 22px;
  text-decoration: underline;

  @media (max-width: 720px) {
    font-family: "Inter";
    font-weight: 400;
    font-size: 13px;
    line-height: 15.68px;
    color: #3f36a7;
    margin-top: 10px;
    text-decoration: underline;
  }
`;

export const ForgetPasswordMobile = styled.a`
  display: none;

  @media (max-width: 720px) {
    font-family: "Inter";
    font-weight: 400;
    font-size: 13px;
    line-height: 15.68px;
    color: #3f36a7;
    margin-top: 10px;
  }
`;
export const Line = styled.div`
  border-bottom: 1px dashed var(--colorLogin);
  width: 100%;
  margin: 10px 0;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const NotHaveAnAccount = styled(Link)`
  align-self: center;
  color: var(--colorLogin);
  font-family: var(--bold);
  font-size: 16px;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const NotHaveAnAccountMobile = styled(Link)`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #3f36a7;
    margin-top: 10px;
  }
`;

export const ButtonSignInMobile = styled.button`
  display: none;

  @media (max-width: 720px) {
    display: block;
    width: 100%;
    height: 64px;
    border-radius: 64px;
    box-shadow: 10px -10px 20px rgba(0, 0, 0, 0.15);
    background: linear-gradient(101deg, white 0%, rgba(255, 255, 255, 0) 100%),
      rgba(217, 217, 217, 0.25);
    margin-top: 20px;
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
  }
`;

export const ButtonSignIn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  height: 69px;
  width: 100%;
  color: var(--white);
  font-family: var(--semiBold);
  border-style: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-image: url(${ButtonSignIn2});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ButtonAcess = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  height: 69px;
  width: 400px;
  color: var(--white);
  font-family: var(--semiBold);
  border-style: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  // background-image: url(${ButtonSignIn2});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const ButtonAcessSignup = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  height: 69px;
  width: 100%;
  color: var(--gray);
  border-style: solid; /* Alterado para 'solid' para definir o estilo da borda */
  border-width: 6px; /* Definindo a largura da borda */
  border-color: var(--gray); /* Definindo a cor da borda */
  font-family: var(--semiBold);
  border-style: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff;
  text-align: center; /* Centraliza o texto */
  line-height: 69px; /* Centraliza verticalmente o texto */

  @media (max-width: 720px) {
    display: none;
  }
`;
// export const ButtonSignIn = styled.button`
// display: flex;
// justify-content: center;
// align-items: center;
// margin-top: 24px;
// height: 69px;
// width: 100%;
// color: var(--white);
// font-family: var(--semiBold);
// border-style: none;
// border-radius: 4px;
// font-size: 16px;
// cursor: pointer;
// backgroundImage: `url(${ButtonSignIn2})`;
//   backgroundRepeat: "no-repeat";
//   backgroundSize: "cover";
// `

export const Error = styled.div`
  color: var(--red);
  /* border: 1px solid var(--colorLogin); */
  border-radius: 4px;
  padding: 6px;
`;

import moment from "moment";
import { ExtractItem } from "./extractDescription";

type GroupedTransactions = {
  dailyBalances: any;
  transactions: ExtractItem[];
  total: number;
  in: number;
  out: number;
  date: string;
  rawDate: string;
};

export default function groupTransactionsByDate(
  transactions: ExtractItem[]
): GroupedTransactions[] {
  let data: any = {};

  for (let tx of transactions) {
    const date = moment(tx.created_at).format("DD/MM/YYYY");

    let dateData = data[date];

    if (dateData) {
      data[date] = {
        transactions: [...dateData.transactions, tx],
        in: tx.direction == "in" ? dateData.in + tx.amount : dateData.in,
        out: tx.direction == "out" ? dateData.out + tx.amount : dateData.out,
        total: dateData.total + tx.amount,
        rawDate: tx.created_at,
      };
    } else {
      data[date] = {
        transactions: [tx],
        in: tx.direction == "in" ? tx.amount : 0,
        out: tx.direction == "out" ? tx.amount : 0,
        total: tx.amount,
        rawDate: tx.created_at,
      };
    }
  }

  const groupedTransactions: GroupedTransactions[] = Object.keys(data).map(
    (key) => ({
      ...data[key],
      date: key,
    })
  );

  return groupedTransactions;
}

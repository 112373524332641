export default function transactionLabel(data: {
  direction: string | "in" | "out";
  type: string | "PIX" | "WITHDRAW" | "RECHARGE";
}) {
  const type = data.type.toUpperCase();

  switch (type) {
    case "PIX_STATIC":
    case "PIX_DYNAMIC":
    case "PIX_KEY":
    case "PIX_MANUAL":
    case "PIX":
      if (data.direction == "in") return "Pix recebido";
      if (data.direction == "out") return "Pix enviado";
    case "WITHDRAW":
      if (data.direction == "in") return "Saque de conta C-A";
      if (data.direction == "out") return "";
    case "RECHARGE":
      if (data.direction == "in") return "Recarga";
      if (data.direction == "out") return "";
    case "BANK_SLIPS":
      if (data.direction == "in") return "Recarga";
      if (data.direction == "out") return "Pagamento de conta";
    case "INTERNAL":
    case "P2P":
      return "P2P Interno";
    case "TED":
      return "Transferência TED";
    case "INTERNAL_TAX":
      return "Taxa interna";
    case "TRANSFER_WITH_PIX":
      return "Pix com agência";
    case "PAGBANK_PAYMENT":
      return "Repasse";
    case "PAGBANK_PAYMENT_DISCOUNT":
      return "Desconto de repasse";
    case "PAYMENT":
      return "Pagamento de conta";
    default:
      return "Desconhecido";
  }
}

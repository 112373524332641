import styled from 'styled-components';

export const KeyboardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 300px;
  margin: 20px auto;
`;

export const KeyboardButton = styled.button`
  padding: 10px;
  font-size: 16px;
  text-align: center;
  background-color: white;
  color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #3498db;
    color: white;
  }

  &:active {
    background-color: #2980b9;
    color: white;
  }
`;

import React, { FormEvent, useEffect, useRef, useState } from "react";
import {
  Container,
  ContentWrapper,
  GoBackButtonWrapper,
  Text,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  WrapperButton,
  WrapperForm,
  Button,
  ResendButton,
  WrapperDivButton,
  WrapperButtonBack,
} from "./styles";

import { ReactComponent as ArrowLeft } from "../../assets/LParrowLeft.svg";
import { FormInput, FormRadio, FormSelect } from "../../components/LPFormButton2";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";

import { api, parseError } from "../../services/api";
import { ExclamationIcon } from "@heroicons/react/solid";
import { addHours, format } from "date-fns";
import { useClient } from "../../hooks/client";
import axios from "axios";
import { useNotification } from "../../hooks/notification";
import { isValid } from "date-fns";
import { useAuth } from "../../hooks/auth";
import { professions } from "../../utils/professions";
import { service, serviceError } from "../../services/ajio";
import { validateCellphone, validateCPF, validateDate, validateDDD, validateEmail } from "../../utils/validations";
import { FormInputSelect } from "../createAccountForm/components/formInputSelectIndiviuals";
import Veriff from "@veriff/js-sdk";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import Profile from "./form";
import { StringSchema } from "yup";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { HelpCircle } from "lucide-react";
import Modal from "src/components/Modal";
import { config } from "src/config";
import VilapayLogoPNG from "../../../src/assets/login-logo.png";
import { Camera } from "phosphor-react";

{
  /**/
}
{
  /**/
}
{
  /*
type IFormInfo = {
  "isPep": boolean;
  "isForeign": false; //fixed to false because we don't acept foreigners yet
  "name": string;
  "document": string;
  "email": string;
  "birthDate": string;
  "motherName": string;
  "phone": string;
  "profession": string;
  "identityType": "rg"; //fixed to RG
  "identityNumber": string;
  "identityIssuedAt": string;
  "monthlyIncome": string;
  "password": string;
  "password2": string;
  "emailValidationToken": string;
  "pin": string;
  "address": {
    "street": string;
    "number": string;
    "extra": string;
    "neighborhood": string;
    "city": string;
    "state": string; //only the UF code
    "postalCode": string; //only numbers
  }
}
*/
}
type IFormInfo = {
  pin: string;
  password: string;
  password2: string;
  maritalStatus: string;
  education: string;
  monthlyIncome: string;
  name: string;
  document: string;
  email: string; // opcional
  birthDate: string;
  gender: string;
  securityEletronic: string;
  phone: {
    ddd: string;
    number: string;
    type: string;
  };
  address: {
    state: string; //only the UF code
    publicPlace: string;
    number: string;
    zipCode: string; //only numbers
    neighborhood: string;
    complement?: string;
    isConfirmed: boolean;
    type: string;
    cityId?: number;
    cityName?: string;
  };
};

type IFormError = {
  isPep?: string;
  maritalStatus?: string;
  education?: string;
  isForeign?: string; //fixed to false because we don't acept foreigners yet
  name?: string;
  document?: string;
  email?: string;
  birthDate?: string;
  motherName?: string;
  profession?: string;
  identityType?: string; //fixed to RG
  identityNumber?: string;
  identityIssuedAt?: string;
  monthlyIncome?: string;
  password?: string;
  password2?: string;
  emailValidationToken?: string;
  pin?: string;
  securityEletronic?: string;
  phone?: string;
  address?: {
    street?: string;
    number?: string;
    extra?: string;
    neighborhood?: string;
    city?: string;
    type?: string;
    state?: string; //only the UF code
    postalCode?: string; //only numbers
  };
};

export type Plan = {
  id: string;
  name: string;
  description: string;
  price: number;
};
interface WalletIndividualsProps {
  status: string | null;
}

export default function CreateWalletIndividuals({ status }: WalletIndividualsProps) {
  const { signOut, signIn } = useAuth();
  const location = useLocation();
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { setOpenNotification } = useNotification();
  const [base64Image, setBase64Image] = useState<string>("");

  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [errorMessage, setErrorMessage] = useState(""); // general form erros
  const [typeDoc, setTypeDoc] = useState("SELFIE");
  const [franchiseId, setFranchiseId] = useState<string | undefined>();
  const [accountType, setAccountType] = useState<string | undefined>();

  const [selectedDocType, setSelectedDocType] = useState(""); // Estado para controlar o tipo de documento selecionado
  const [rgIssuedAt, setRgIssuedAt] = useState(""); // Estado para a data de emissão do RG
  const [rgNumber, setRgNumber] = useState(""); // Estado para o número do RG
  const [cnhNumber, setCnhNumber] = useState(""); // Estado para o número da CNH
  const [cnhIssuedAt, setCnhIssuedAt] = useState(""); // Estado para a data de expedição da CNH
  const [cnhExpirationDate, setCnhExpirationDate] = useState(""); // Estado para a data de vencimento da CNH
  const [validFrom, setValidFrom] = useState(""); // Estado para a data de emissão do RG
  const [validUntil, setValidUntil] = useState(""); // Estado para a data de vencimento da CNH

  const [step, setStep] = useState(() => {
    const statusStorage = status || localStorage.getItem("@stricv2:status");
    if (statusStorage) {
      if (statusStorage === "WAITING_TOKEN") {
        return 2;
      }

      if (statusStorage === "WAITING_FLOW") {
        return 3;
      }

      if (statusStorage === "WAITING_PARTNERS") {
        return 3;
      }

      if (statusStorage === "WAITING_ADDRESS") {
        return 5;
      }

      if (statusStorage === "waiting_documents") {
        return 6;
      }

      if (statusStorage === "denied") {
        return 66;
      }

      if (statusStorage === "under_review") {
        return 99;
      }
    }

    return 1;
  });

  const [sucess, setSuccess] = useState(() => {
    const statusStorage = localStorage.getItem("@stricv2:status");

    if (statusStorage === "active" || statusStorage === "under_review") {
      return true;
    }

    return false;
  });

  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false);

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem("@stricv2:id");
    if (tokenStorage) {
      return tokenStorage;
    }
    return "";
  });

  const apiKey = config.VERIFF_APIKEY;
  const redirectTo = config.VERIFF_CALLBACK;
  const apiUrl = "https://stationapi.veriff.com/v1/sessions/";
  const createVeriffCheck = async () => {
    try {
      const formattedBirthDate = formInfo.birthDate.split("/").reverse().join("-");
      const cleanedDocument =
        selectedDocType === "CNH" ? cnhNumber.replace(/[/,.,-\s]/g, "") : rgNumber.replace(/[/,.,-\s]/g, "");
      const formattedValidFrom = validFrom.split("/").reverse().join("-");
      const formattedValidUntil = selectedDocType === "CNH" ? validUntil.split("/").reverse().join("-") : undefined;
      const formattedCnhIssuedAt = cnhIssuedAt.split("/").reverse().join("-");

      const response = await axios.post(
        `${apiUrl}`,
        {
          verification: {
            callback: redirectTo,
            person: {
              firstName: formInfo.name.split(" ")[0],
              lastName: formInfo.name.split(" ")[1],
              dateOfBirth: formattedBirthDate,
            },
            document: {
              number: cleanedDocument,
              type: typeDoc,
              validFrom: formattedValidFrom || formattedCnhIssuedAt,
              validUntil: formattedValidUntil,
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "X-AUTH-CLIENT": apiKey as string,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Resposta da Veriff:", response.data);
      if (response.data && response.data.verification && response.data.verification.url) {
        // window.location.href = response.data.verification.url;
        const veriffFrame = createVeriffFrame({
          url: response.data.verification.url,
          onEvent: (message) => {
            switch (message) {
              case MESSAGES.CANCELED:
                break;
              case MESSAGES.FINISHED:
                break;
              case MESSAGES.RELOAD_REQUEST:
                break;
              case MESSAGES.STARTED:
                break;
            }
          },
        });
      }
    } catch (error) {
      console.error("Erro ao criar uma verificação na Veriff:", error);
    }
  };

  const navigation = useNavigate();
  // const { setOpenNotification } = useNotification();
  const handleGoBack = async () => {
    await signOut();
    navigation("/signin");
  };

  useEffect(() => {
    console.log("FRANCHISE_ID modificado: ", franchiseId);
  }, [franchiseId]);

  const [phone, setPhone] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const accountTypeQuery = queryParameters.get("type");
    const franchiseIdQuery = queryParameters.get("service_code");

    franchiseIdQuery && setFranchiseId(franchiseIdQuery);
    accountTypeQuery && setAccountType(accountTypeQuery);

    if (
      !location.search ||
      location.search === null ||
      location.search === "" ||
      !accountTypeQuery ||
      !franchiseIdQuery
    ) {
      navigation("/signin");
    }
  }, [location.search, navigation]);

  const clearErrorMessageAfterDelay = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const handleOneSubmit = async () => {
    setError({} as IFormError);

    // Name and Mother Name Validations
    if (!formInfo.name.includes(" ") || formInfo.name.length < 5) {
      setError({ name: "Preencha o nome completo." });
      return;
    }

    if (selectedDocType === "RG") {
      if (!rgNumber || rgNumber.length < 9) {
        setError({ ...error, identityNumber: "Número de RG inválido." });
        return;
      }
    }
    // Verifique se o tipo de documento selecionado é CNH
    else if (selectedDocType === "CNH") {
      // Remove non-numeric characters and check the length
      const cleanedCnhNumber = cnhNumber.replace(/[^0-9]/g, "");
      if (cleanedCnhNumber.length !== 11) {
        setError({ ...error, identityNumber: "Número da CNH inválido." });
        return;
      }
    }

    // CPF xxx.xxx.xxx-xx
    if (formInfo.document.length < 14) {
      setError({ document: "CPF Inválido." });
      return;
    }

    if (!validateCPF(formInfo.document.replace(/[/,.,-\s]/g, ""))) {
      setError({ document: "CPF Inválido." });
      return;
    }

    // Email
    if (formInfo.email.length < 6) {
      setError({ email: "Preencha o email." });
      return;
    }

    if (!validateEmail(formInfo.email)) {
      setError({ email: "Email Inválido." });
      return;
    }

    if (phone.length < 15) {
      setError({ phone: "Telefone Inválido." });
      return;
    }

    // BirthDate xxxx/xx/xx
    if (formInfo.birthDate.length < 10) {
      setError({ birthDate: "Data de Nascimento incompleta." });
      return;
    }
    if (!validateDate(formInfo.birthDate)) {
      setError({ birthDate: "Preencha a data de emissão completa." });
      return;
    }

    setLoading(true);

    try {
      setLoading(false);
      setStep(2);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      const errorAll = err as any;
      setLoading(false);
      if (error.code === "document.already" || error.code === "email.already") {
        setOpenNotification({
          title: "Alerta",
          notification:
            "Nós já nos conhecemos!\n\nIdentificamos que você já possui um vínculo com a gente.\n\nPara continuar, utilize sua senha e comece a desfrutar de todas as vantangens.",
          type: "warning",
        });
      } else {
        setOpenNotification({
          title: "Erro",
          notification: error.friend || errorAll?.response?.data?.message?.[0],
          type: "error",
        });
      }
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await api.get("/graphic/plans", {
        params: {
          role: "WALLET",
        },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function handlePasswords() {
    setError({} as IFormError);

    // // Password Validations
    const pass = formInfo.password;
    const pass2 = formInfo.password2;
    if (pass.length < 8) {
      setError({
        ...error,
        password: "A senha deve ter pelo menos 8 dígitos.",
      });
      return;
    }

    if (!/[A-Z]/.test(pass) || !/\d/.test(pass)) {
      setError({
        ...error,
        password: "A senha deve ter pelo uma letra maiúscula e um número.",
      });
      return;
    }

    if (pass !== pass2) {
      setError({ ...error, password2: "As senhas não estão iguais." });
      return;
    }

    if (formInfo.pin.length !== 4) {
      setError({ ...error, pin: "O PIN deve ter 4 dígitos." });
      return;
    }

    if (["1234", "2345", "3456", "4567", "5678", "6789"].includes(formInfo.pin)) {
      setError({
        ...error,
        pin: "O PIN não pode ser composto de números consecutivos.",
      });
      return;
    }

    if (formInfo.securityEletronic.length !== 8) {
      setError({
        ...error,
        securityEletronic: "A Senha Eletrônica deve ter 8 dígitos.",
      });
      return;
    }

    if (
      [
        "1234",
        "2345",
        "3456",
        "4567",
        "5678",
        "6789",
        "4321",
        "5432",
        "6543",
        "7654",
        "8765",
        "9876",
        "3210",
        "0123",
      ].includes(formInfo.securityEletronic)
    ) {
      setError({
        ...error,
        securityEletronic: "A Senha Eletrônica não pode ser composto de números consecutivos.",
      });
      return;
    }

    setLoading(true);
    console.log("aqui");

    // ...
    try {
      const rgOrCnh = rgNumber.length > 0 ? rgNumber : cnhNumber;

      const ddd = phone.split(" ")[0].replace("(", "").replace(")", "");
      const number = phone.split(" ")[1].replace("-", "");

      console.log(formInfo.address);

      const raw = {
        franchise_id: franchiseId,
        document: formInfo.document.replace(/[/,.,-\s]/g, ""),
        name: formInfo.name,
        email: formInfo.email,
        birthDate: formInfo.birthDate.split("/").reverse().join("-"),
        gender: formInfo.gender,
        password: formInfo.password,
        pin: formInfo.pin,
        passwordEletronic: formInfo.securityEletronic,
        rg_cnh: rgOrCnh.replace(/[/,.,-\s]/g, ""),
        isRole: "WALLET",
        planId: selectedPlanId,
        phone: { ddd, number, type: "CELL" },
        address: {
          isConfirmed: true,
          publicPlace: formInfo.address.publicPlace,
          number: formInfo.address.number,
          complement: formInfo.address.complement || "",
          neighborhood: formInfo.address.neighborhood,
          cityName: formInfo.address.cityName,
          zipCode: formInfo.address.zipCode.replace(/-/g, ""), //only numbers
          type: formInfo.address.type,
          state: formInfo.address.state,
        },
        // type: 'WALLET'
      };
      console.log("oi eu sou o raw da wallet", raw);
      await service.post("/graphic/create", raw);
      //  await fetchAccounts();

      // Exibir mensagem de sucesso
      setOpenNotification({
        title: "Sucesso",
        notification: "Conta criada com sucesso.",
        type: "success",
      });

      //await signIn({ document: raw.document, password: raw.password });
      setLoading(false);
      setStep(99);
      await createVeriffCheck();
    } catch (err) {
      const myError = serviceError(err);
      console.log(err);

      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  const mapAdressTypeToTranslation = (type: string): string => {
    const adressTypeTranslationMap: { [key: string]: string } = {
      Residencial: "RESIDECIAL",
      Comercial: "COMMERCIAL",
      // Adicione mais mapeamentos conforme necessário
    };
    return adressTypeTranslationMap[type] || type;
  };

  async function handleCep(cep: string) {
    setError({} as IFormError);
    console.log("aqui");

    // xx.xxx-xxx
    if (cep?.length < 9) {
      setError({
        ...error,
        address: {
          ...formInfo.address,
          postalCode: "Preencha o CEP completo.",
        },
      });
      return;
    }

    const rawPostalCode = cep.replace(/-/g, "");
    if (rawPostalCode.length >= 8) {
      setLoading(true);

      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${rawPostalCode}/json/`);
        console.log(data);
        if (data) {
          setFormInfo({
            ...formInfo,
            address: {
              ...formInfo.address,
              publicPlace: data.logradouro,
              neighborhood: data.bairro,
              cityName: data.localidade,
              state: data.uf,
              zipCode: data.cep,
            },
          });
        }
        setLoading(false);
        setStep(3);
      } catch (err) {
        setLoading(false);
        setStep(3);
      }
    }
  }

  async function handleAddress() {
    setError({} as IFormError);

    //if(formInfo.address.postalCode.length < 9){
    // setError({...error, address: {...formInfo.address, postalCode: "Preencha o CEP completo."}});
    // return;
    //}

    if (formInfo?.address?.state?.length !== 2) {
      setError({
        ...error,
        address: { ...error.address, state: "Estado Inválido." },
      });
      return;
    }

    if (!formInfo.address.type || (formInfo.address.type != "RESIDECIAL" && formInfo.address.type != "COMMERCIAL")) {
      setError({
        ...error,
        address: {
          ...error.address,
          type: "Escolha um tipo de endereço",
        },
      });
      return;
    }

    // if(formInfo.address.state.length !== 2){
    //   setError({...error, address: {...formInfo.address, state: "Estado Inválido."}});
    //   return;
    // }

    setLoading(true);
    try {
      setLoading(false);
      setStep(4);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  const mapGenderToTranslation = (gender: string): string => {
    const genderTranslationMap: { [key: string]: string } = {
      Masculino: "MALE",
      Feminino: "FEMALE",
      // Adicione mais mapeamentos conforme necessário
    };
    return genderTranslationMap[gender] || gender;
  };

  interface PlanMapping {
    [key: string]: string;
  }

  const [plansTranslationMap, setPlansTranslationMap] = useState<PlanMapping>({});

  useEffect(() => {
    fetchPlans();
  }, []);

  const mapplanToTranslation = (plan: string): string => {
    return plansTranslationMap[plan] || plan;
  };

  const inputRefs: React.RefObject<HTMLInputElement>[] = [];

  // Crie as referências usando React.createRef
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const password2InputRef = useRef<HTMLInputElement>(null);
  const pinInputRef = useRef<HTMLInputElement>(null);
  const SecurityEletronicInputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Modal
        onClickClose={() => {
          setShowPlanModal(false);
        }}
        open={showPlanModal}
      >
        <div className="flex flex-col items-center gap-8">
          <span className="text-3xl font-extrabold">Planos</span>
          <div className="flex w-full max-w-[800px] items-center gap-3 overflow-x-auto">
            {plans.map((p) => (
              <div className="flex flex-col justify-between p-4 min-w-[220px] max-w-[280px] h-[250px] overflow-hidden shadow-inner border border-zinc-200 rounded-xl">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">{p.name}</span>
                  <span className="text-3xl font-extrabold text-blue-500">
                    R$ {toLocaleAmount(p.price)} <span className="text-sm">/ mês</span>
                  </span>
                </div>
                <span className="text-sm font-semibold text-zinc-800 text-clip">{p.description}</span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <ContentWrapper>
        <div className="flex p-6">
          <div className="absolute top-20 left-20">
            <img width={300} height={300} className="object-contain" src={VilapayLogoPNG} alt="" />

            <h1 className="font-bold text-white text-6xl mt-[80%]">
              Abra sua conta, <br />
              vem ser Pixwave
            </h1>
            <h2 className="font-extralight text-white text-4xl mt-5">
              Não basta ser digital, <br />
              tem que ser <strong className="font-bold text-white text-5xl">PIXWAVE</strong>
            </h2>
          </div>
        </div>
        <WrapperRight>
          {/* <GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            Voltar ao Login
          </GoBackButtonWrapper> */}
          <WrapperContent>
            <div className="relative w-[70%] self-center">
              {step <= 5 && (
                <span className="text-lg border rounded-md w-14 h-min text-center border-[#03142F] absolute bg-transparent right-0 top-8">
                  {step}/5
                </span>
              )}
              <br />
              <span className="text-4xl text-[#03142F] mb-2 self-center">
                Você está a poucos <br /> passos de uma nova
                <br />
                <strong>experiência financeira</strong>
              </span>
            </div>
            {step == 1 && (
              <>
                <span className="text-xl font-extralight text-[#686868] my-4 w-[70%] self-center">
                  Todos os dados a seguir devem ser <br />
                  obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleOneSubmit();
                  }}
                >
                  <FormInput
                    placeholder="Nome"
                    required
                    error={error.name || ""}
                    onChanged={(e) => setFormInfo({ ...formInfo, name: e.target.value })}
                  />
                  <FormInput
                    placeholder="E-mail"
                    required
                    error={error.email || ""}
                    onChanged={(e) => setFormInfo({ ...formInfo, email: e.target.value })}
                  />
                  <FormInput
                    placeholder="Telefone"
                    required
                    mask="(99) 99999-9999"
                    error={error.phone || ""}
                    onChanged={(e) => {
                      const completePhone = e.target.value as string;
                      setPhone(completePhone);
                    }}
                  />
                  <FormInput
                    placeholder="CPF"
                    required
                    mask="999.999.999-99"
                    error={error.document || ""}
                    onChanged={(e) => setFormInfo({ ...formInfo, document: e.target.value })}
                  />

                  <FormInputSelect
                    marginBottom="20px"
                    placeholder="Estado Civil"
                    options={[
                      "Solteiro(a)",
                      "Casado(a)",
                      "Separado(a) Legalmente",
                      "Divorciado(a)",
                      "União Estável",
                      "Desimpedido(a)",
                      "Viúvo(a)",
                      "Outros",
                    ]}
                    onChanged={(e) => {
                      const translatedValue = mapGenderToTranslation(e.target.value);
                      setFormInfo({ ...formInfo, maritalStatus: translatedValue });
                    }}
                    error={error.isPep || ""}
                  />

                  <FormInputSelect
                    marginBottom="20px"
                    placeholder="Nível De Educação"
                    options={[
                      "Analfabeto",
                      "Ensino Fundamental - Anos Iniciais (1 a 4 anos)",
                      "Ensino Fundamental - 5º ano",
                      "Ensino Fundamental - 6º a 9º ano",
                      "Ensino Fundamental Completo",
                      "Ensino Médio Incompleto",
                      "Ensino Médio Completo",
                      "Ensino Superior Incompleto",
                      "Ensino Superior Completo",
                      "Pós-Graduação",
                      "Mestrado",
                      "Doutorado",
                    ]}
                    onChanged={(e) => {
                      const translatedValue = mapGenderToTranslation(e.target.value);
                      setFormInfo({ ...formInfo, education: translatedValue });
                    }}
                    error={error.isPep || ""}
                  />

                  <FormInput
                    mask={formInfo.monthlyIncome && "currency"}
                    placeholder="Renda Mensal"
                    type="number"
                    required
                    error={error.monthlyIncome || ""}
                    onChanged={(e) => setFormInfo({ ...formInfo, monthlyIncome: e.target.value })}
                  />

                  <FormInput
                    placeholder="DATA DE NASCIMENTO"
                    required
                    mask="99/99/9999"
                    error={error.birthDate || ""}
                    onChanged={(e) => setFormInfo({ ...formInfo, birthDate: e.target.value })}
                  />

                  <FormInputSelect
                    placeholder="GÊNERO"
                    options={["Masculino", "Feminino"]}
                    onChanged={(e) => {
                      const translatedValue = mapGenderToTranslation(e.target.value);
                      setFormInfo({ ...formInfo, gender: translatedValue });
                    }}
                    error={error.isPep || ""}
                  />

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mb-[27px]"></div>

                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black", width: "95%", borderRadius: 10 }}
                        type="button"
                        onClick={() => navigation("/signin")}
                      >
                        Voltar
                      </Button>
                    </WrapperButtonBack>

                    <WrapperButton>
                      <Button
                        style={{ backgroundColor: "#274683", width: "95%", borderRadius: 10, marginLeft: 15 }}
                        type="submit"
                      >
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}
            {step === 2 && (
              <>
                <span className="text-xl font-extralight text-[#686868] my-4 w-[70%] self-center">
                  Todos os dados a seguir devem ser <br />
                  obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    setStep(3);
                  }}
                >
                  <div className="flex items-center gap-2 bg-[#f6f6f6] pr-4 rounded-xl justify-between">
                    <FormInputSelect
                      style={{ flex: 1 }}
                      placeholder="PLANO"
                      options={plans.map((p) => ({
                        id: p.id,
                        name: `${p.name} R$ ${toLocaleAmount(p.price)}`,
                      }))}
                      onChanged={(e) => setSelectedPlanId(e.target.value)}
                      // onChanged={(e) => {
                      //   const translatedValue = mapplanToTranslation(
                      //     e.target.value
                      //   );
                      //   setFormInfo({ ...formInfo, name: translatedValue }); // add plans  no info forms e raw
                      // }}
                      // error={error.isPep || ""}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowPlanModal(true);
                      }}
                    >
                      <HelpCircle size={28} color="#009EE2" />
                    </button>
                  </div>

                  <div className="text-sm text-gray-700 my-4">
                    Lembre-se: use o respectivo documento para verificação.
                  </div>
                  <FormSelect
                    placeholder="TIPO DE DOCUMENTO"
                    options={["CNH", "RG"]}
                    onChanged={(e) => {
                      setSelectedDocType(e.target.value);
                      if (e.target.value === "CNH") {
                        setTypeDoc("DRIVERS_LICENSE");
                      } else if (e.target.value === "RG") {
                        setTypeDoc("ID_CARD");
                      }
                    }}
                  />

                  {selectedDocType === "RG" && (
                    <>
                      <FormInput
                        placeholder="NÚMERO DO RG"
                        required
                        mask="99.999.999-9"
                        error={error.identityNumber || ""}
                        value={rgNumber}
                        onChanged={(e) => setRgNumber(e.target.value)}
                      />
                      <FormInput
                        placeholder="DATA DE EMISSÃO DO RG"
                        required
                        mask="99/99/9999"
                        error={error.identityIssuedAt || ""}
                        onChanged={(e) => setValidFrom(e.target.value)}
                      />
                    </>
                  )}

                  {selectedDocType === "CNH" && (
                    <>
                      <FormInput
                        placeholder="NÚMERO DA CNH"
                        required
                        mask="99999999999"
                        error={error.identityNumber || ""}
                        value={cnhNumber}
                        onChanged={(e) => setCnhNumber(e.target.value)}
                      />
                      <FormInput
                        placeholder="DATA DE EXPEDIÇÃO DA CNH"
                        required
                        mask="99/99/9999"
                        error={error.identityIssuedAt || ""}
                        onChanged={(e) => setCnhIssuedAt(e.target.value)}
                      />
                      <FormInput
                        placeholder="DATA DE VENCIMENTO DA CNH"
                        required
                        mask="99/99/9999"
                        error={error.identityIssuedAt || ""}
                        onChanged={(e) => setValidUntil(e.target.value)}
                      />
                    </>
                  )}

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mb-[27px]"></div>

                  <WrapperDivButton style={{ gap: 20 }}>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="button" onClick={() => setStep(1)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}
            {step === 3 && (
              <>
                <span className="text-xl font-extralight text-[#686868] my-4 w-[70%] self-center">
                  Todos os dados a seguir devem ser <br />
                  obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddress();
                  }}
                >
                  <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    value={formInfo?.address?.zipCode}
                    error={error?.address?.postalCode || ""}
                    onChanged={(e) => {
                      const newValue = e.target.value as string;

                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          zipCode: newValue,
                        },
                      });

                      if (newValue.length == 9) handleCep(newValue);
                    }}
                  />
                  {/* <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    error={error.address?.postalCode || ""}
                    value={formInfo?.address?.zipCode}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          zipCode: e.target.value,
                        },
                      })
                    }
                  /> */}

                  {formInfo?.address?.cityName && (
                    <>
                      <div className="flex gap-4 w-full">
                        <FormInput
                          style={{ flex: 1 }}
                          nomargin
                          placeholder="Cidade"
                          required
                          error={error.address?.city || ""}
                          value={formInfo.address.cityName}
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                cityName: e.target.value,
                              },
                            })
                          }
                        />

                        <FormInput
                          nomargin
                          style={{ flex: 1 }}
                          placeholder="Estado"
                          value={formInfo.address.state}
                          error={error.address?.state || ""}
                          required
                          maxLength={2}
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                state: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <FormInput
                        placeholder="Bairro"
                        value={formInfo.address.neighborhood}
                        error={error.address?.neighborhood || ""}
                        required
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            address: {
                              ...formInfo.address,
                              neighborhood: e.target.value,
                            },
                          })
                        }
                      />

                      <FormInput
                        placeholder="Rua"
                        error={error.address?.street || ""}
                        value={formInfo.address.publicPlace}
                        maxLength={70}
                        required
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            address: {
                              ...formInfo.address,
                              publicPlace: e.target.value,
                            },
                          })
                        }
                      />

                      <div className="flex gap-4">
                        <FormInput
                          nomargin
                          placeholder="Número"
                          error={error.address?.number || ""}
                          value={formInfo.address.number}
                          required
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                number: e.target.value,
                              },
                            })
                          }
                        />

                        <FormInput
                          nomargin
                          placeholder="Complemento"
                          value={formInfo.address.complement}
                          onChanged={(e) =>
                            setFormInfo({
                              ...formInfo,
                              address: {
                                ...formInfo.address,
                                complement: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <FormInputSelect
                        placeholder="Local"
                        options={["Residencial", "Comercial"]}
                        onChanged={(e) => {
                          const typeAdressValue = mapAdressTypeToTranslation(e.target.value);
                          setFormInfo({
                            ...formInfo,
                            address: {
                              ...formInfo.address,
                              type: typeAdressValue,
                            },
                          });
                        }}
                      />
                    </>
                  )}
                  <span className="text-sm text-red-600 font-medium ml-2">{error.address?.type}</span>
                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mb-[27px]"></div>

                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="button" onClick={() => setStep(2)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 4 && (
              <>
                <Text>Defina suas credencias de acesso.</Text>
                <Text
                  style={{
                    color: "black",
                    marginBottom: "-1px",
                    marginTop: "-10px",
                  }}
                >
                  <strong>
                    A sua senha deve ter pelo menos 8 dígitos, uma letra maiúscula e um caractere especial.
                  </strong>
                </Text>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePasswords();
                  }}
                >
                  <FormInput
                    ref={passwordInputRef}
                    id="password-input"
                    placeholder="Senha"
                    type="password"
                    showPasswordIcon={true}
                    error={error.password || ""}
                    value={formInfo.password}
                    onChanged={(e) => setFormInfo({ ...formInfo, password: e.target.value })}
                  />
                  <FormInput
                    ref={password2InputRef}
                    id="password2-input"
                    placeholder="Repita a senha"
                    type="password"
                    showPasswordIcon={true}
                    error={error.password2 || ""}
                    value={formInfo.password2}
                    onChanged={(e) => setFormInfo({ ...formInfo, password2: e.target.value })}
                  />

                  <FormInput
                    ref={pinInputRef}
                    id="pin-input"
                    maxLength={4}
                    placeholder="SECURITY DIGIT (Camada extra de segurança)"
                    type="password"
                    error={error.pin || ""}
                    showPasswordIcon={true}
                    value={formInfo.pin}
                    onChanged={(e) => setFormInfo({ ...formInfo, pin: e.target.value })}
                  />

                  <FormInput
                    ref={SecurityEletronicInputRef}
                    id="securty-eletronic-input"
                    maxLength={8}
                    placeholder="SENHA ELETRÔNIA (Senha de operações bancárias)"
                    type="password"
                    error={error.securityEletronic || ""}
                    showPasswordIcon={true}
                    value={formInfo.securityEletronic}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        securityEletronic: e.target.value,
                      })
                    }
                  />
                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mb-[27px]"></div>

                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button style={{ backgroundColor: "black" }} type="button" onClick={() => setStep(3)}>
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button style={{ backgroundColor: "#009ee2" }} type="submit">
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 66 && <Text>Infelizmente não foi possível abrir sua conta neste momento.</Text>}

            {step === 99 && (
              <Text>
                Pronto! Agora é só esperar a análise dos dados. Em até 1 dia você irá receber a resposta em seu e-mail.
              </Text>
            )}
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
}

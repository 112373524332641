import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  ContentWrapper,
  Text,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  WrapperButton,
  WrapperForm,
  Button,
  WrapperDivButton,
  WrapperButtonBack,
} from "./styles";

import { FormInput } from "../../components/LPFormButton2";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";

import { api } from "../../services/api";
import { ExclamationIcon } from "@heroicons/react/solid";
import { useClient } from "../../hooks/client";
import axios from "axios";
import { useNotification } from "../../hooks/notification";
import { useAuth } from "../../hooks/auth";
import { service, serviceError } from "../../services/ajio";
import {
  validateCPF,
  validateDate,
  validateDDD,
  validateEmail,
} from "../../utils/validations";
import { FormInputSelect } from "./components/formInputSelectIndiviuals";
import { Plan } from "../createAccountWalletInitials/account_wallet_create";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { ChevronLeft, HelpCircle } from "lucide-react";
import Modal from "src/components/Modal";

{
  /**/
}
{
  /**/
}
{
  /*
type IFormInfo = {
  "isPep": boolean;
  "isForeign": false; //fixed to false because we don't acept foreigners yet
  "name": string;
  "document": string;
  "email": string;
  "birthDate": string;
  "motherName": string;
  "phone": string;
  "profession": string;
  "identityType": "rg"; //fixed to RG
  "identityNumber": string;
  "identityIssuedAt": string;
  "monthlyIncome": string;
  "password": string;
  "password2": string;
  "emailValidationToken": string;
  "pin": string;
  "address": {
    "street": string;
    "number": string;
    "extra": string;
    "neighborhood": string;
    "city": string;
    "state": string; //only the UF code
    "postalCode": string; //only numbers
  }
}
*/
}
type IFormInfo = {
  pin: string;
  // "isPep": boolean;
  password: string;
  password2: string;
  name: string;
  document: string;
  email: string; // opcional
  phone: {
    ddd: string;
    number: string;
    type: string;
  };
  monthlyInvoicing: string;
  birthDate: string;
  educationLevel: string;
  maritalStatus: string;
  gender: string;
  address: {
    state: string; //only the UF code
    publicPlace: string;
    number: string;
    zipCode: string; //only numbers
    neighborhood: string;
    complement?: string;
    isConfirmed: boolean;
    type: string;
    cityId?: number;
    cityName?: string;
  };
  securityEletronic: string;
};

type IFormError = {
  isPep?: string;
  isForeign?: string; //fixed to false because we don't acept foreigners yet
  name?: string;
  document?: string;
  email?: string;
  birthDate?: string;
  motherName?: string;
  phone?: string;
  profession?: string;
  identityType?: string; //fixed to RG
  identityNumber?: string;
  identityIssuedAt?: string;
  monthlyIncome?: string;
  password?: string;
  password2?: string;
  emailValidationToken?: string;
  pin?: string;
  securityEletronic?: string;
  address?: {
    street?: string;
    number?: string;
    extra?: string;
    neighborhood?: string;
    city?: string;
    state?: string; //only the UF code
    postalCode?: string; //only numbers
  };
};

interface IndividualProps {
  status: string | null;
}

export default function Individuals({ status }: IndividualProps) {
  const { signOut, signIn } = useAuth();
  const location = useLocation();
  const { client } = useClient();
  const { account, refreshAccount } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { setOpenNotification } = useNotification();
  const [base64Image, setBase64Image] = useState<string>("");

  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [errorMessage, setErrorMessage] = useState(""); // general form erros
  const [typeDoc, setTypeDoc] = useState("SELFIE");
  const [franchiseId, setFranchiseId] = useState<string | undefined>();
  const [accountType, setAccountType] = useState<string | undefined>();
  const statusStorage = status || localStorage.getItem("@stricv2:status");

  const [plans, setPlans] = useState<Plan[]>([]);
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");

  const fetchPlans = async () => {
    try {
      const response = await api.get("/graphic/plans", {
        params: {
          role: "MEMBERPF",
        },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [step, setStep] = useState(() => {
    const statusStorage = status || localStorage.getItem("@stricv2:status");

    if (account.id_master_user && !account.status_master_user) {
      return 6;
    }

    if (statusStorage) {
      if (statusStorage === "WAITING_TOKEN") {
        return 2;
      }

      if (statusStorage === "WAITING_FLOW") {
        return 3;
      }

      if (statusStorage === "WAITING_PARTNERS") {
        return 3;
      }

      if (statusStorage === "WAITING_ADDRESS") {
        return 5;
      }

      if (statusStorage === "waiting_documents") {
        return 6;
      }

      if (statusStorage === "denied") {
        return 66;
      }

      if (statusStorage === "under_review") {
        return 99;
      }
    }

    return 1;
  });
  console.log(account)

  const [sucess, setSuccess] = useState(() => {
    const statusStorage = localStorage.getItem("@stricv2:status");

    if (statusStorage === "under_review" || statusStorage === "under_review") {
      return true;
    }

    return false;
  });

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem("@stricv2:id");
    if (tokenStorage) {
      return tokenStorage;
    }
    return "";
  });

  const navigation = useNavigate();
  // const { setOpenNotification } = useNotification();
  const handleGoBack = async () => {
    await signOut();
    navigation("/signin");
  };

  useEffect(() => {
    console.log("FRANCHISE_ID modificado: ", franchiseId);
  }, [franchiseId]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const accountTypeQuery = queryParameters.get("type");
    const franchiseIdQuery = queryParameters.get("service_code");

    franchiseIdQuery && setFranchiseId(franchiseIdQuery);
    accountTypeQuery && setAccountType(accountTypeQuery);

    if (
      !location.search ||
      location.search === null ||
      location.search === "" ||
      !accountTypeQuery ||
      !franchiseIdQuery
    ) {
      navigation("/signin");
    }
  }, [location.search, navigation]);

  const clearErrorMessageAfterDelay = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const handleOneSubmit = async () => {
    setError({} as IFormError);

    // Name and Mother Name Validations
    if (!formInfo.name.includes(" ") || formInfo.name.length < 5) {
      setError({ name: "Preencha o nome completo." });
      return;
    }

    // CPF xxx.xxx.xxx-xx
    if (formInfo.document.length < 14) {
      setError({ document: "CPF Inválido." });
      return;
    }

    if (!validateCPF(formInfo.document.replace(/[/,.,-\s]/g, ""))) {
      setError({ document: "CPF Inválido." });
      return;
    }

    // Email
    if (formInfo.email.length < 6) {
      setError({ email: "Preencha o email." });
      return;
    }

    if (!validateEmail(formInfo.email)) {
      setError({ email: "Email Inválido." });
      return;
    }

    //if(!validateCellphone(formInfo.phone.number.replace(/[-,\s,(,)]/g, ""))){
    //   setError({phone: 'Celular Inválido.'});
    // }

    // BirthDate xxxx/xx/xx
    if (formInfo.birthDate.length < 10) {
      setError({ birthDate: "Data de Nascimento incompleta." });
      return;
    }
    if (!validateDate(formInfo.birthDate)) {
      setError({ birthDate: "Preencha a data de emissão completa." });
      return;
    }

    setLoading(true);

    try {
      setLoading(false);
      setStep(2);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      const errorAll = err as any;
      setLoading(false);
      if (error.code === "document.already" || error.code === "email.already") {
        setOpenNotification({
          title: "Alerta",
          notification:
            "Nós já nos conhecemos!\n\nIdentificamos que você já possui um vínculo com a gente.\n\nPara continuar, utilize sua senha e comece a desfrutar de todas as vantangens.",
          type: "warning",
        });
      } else {
        setOpenNotification({
          title: "Erro",
          notification: error.friend || errorAll?.response?.data?.message?.[0],
          type: "error",
        });
      }
    }
  };

  const handleTwoSubmitStepPF = async () => {
    setError({} as IFormError);
    // Identity validation xx.xxx.xxx
    // if(formInfo.identityNumber.length < 10){
    //  setError({...error, identityNumber: 'Preencha o número da identidade completo.'});
    //  return;
    //}

    // if(formInfo.identityIssuedAt.length < 10){
    //   setError({...error, identityIssuedAt: 'Preencha a data de emissão completa.'});
    //   return;
    // }

    // if(!validateDate(formInfo.identityIssuedAt)){
    //   setError({...error, identityIssuedAt: 'Data inválida.'});
    //   return;
    // }

    // if(formInfo.monthlyIncome === "R$ 0,00" || formInfo.monthlyIncome === undefined){
    //  setError({...error, monthlyIncome: 'Preencha sua renda mensal.'});
    //  return;
    //}

    //if (formInfo.isPep === undefined) {
    //  setError({ ...error, isPep: 'Selecione se você é ou não PEP.' });
    //  return;
    //}

    // Phone (xx) xxxxx-xxxx
    if (formInfo.phone.ddd.length < 1) {
      setError({ phone: "DDD Inválido." });
      return;
    }

    if (!validateDDD(formInfo.phone.ddd.replace(/[-,\s,(,)]/g, ""))) {
      setError({ phone: "DDD Inválido." });
      return;
    }

    // Phone (xx) xxxxx-xxxx
    if (formInfo.phone.number.length < 9) {
      setError({ phone: "Celular Inválido." });
      clearErrorMessageAfterDelay(); // Limpa a mensagem de erro após 5 segundos
      return;
    }

    setLoading(true);
    try {
      setLoading(false);
      setStep(3);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }
  };

  async function handleCep() {
    setError({} as IFormError);

    // xx.xxx-xxx
    if (formInfo.address.zipCode.length < 9) {
      setError({
        ...error,
        address: {
          ...formInfo.address,
          postalCode: "Preencha o CEP completo.",
        },
      });
      return;
    }

    const rawPostalCode = formInfo.address.zipCode.replace(/-/g, "");
    if (rawPostalCode.length >= 8) {
      setLoading(true);

      try {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${rawPostalCode}/json/`
        );
        console.log(data);
        if (data) {
          setFormInfo({
            ...formInfo,
            address: {
              ...formInfo.address,
              publicPlace: data.logradouro,
              neighborhood: data.bairro,
              cityName: data.localidade,
              state: data.uf,
            },
          });
        }
        setLoading(false);
        setStep(4);
      } catch (err) {
        setLoading(false);
        setStep(4);
      }
    }
  }

  async function handleAddress() {
    setError({} as IFormError);

    //if(formInfo.address.postalCode.length < 9){
    // setError({...error, address: {...formInfo.address, postalCode: "Preencha o CEP completo."}});
    // return;
    //}

    if (formInfo.address.state.length !== 2) {
      setError({
        ...error,
        address: { ...formInfo.address, state: "Estado Inválido." },
      });
      return;
    }

    // if(formInfo.address.state.length !== 2){
    //   setError({...error, address: {...formInfo.address, state: "Estado Inválido."}});
    //   return;
    // }

    setLoading(true);
    try {
      setLoading(false);
      setStep(5);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  async function handlePasswords() {
    setError({} as IFormError);

    // // Password Validations
    const pass = formInfo.password;
    const pass2 = formInfo.password2;
    if (pass.length < 8) {
      setError({
        ...error,
        password: "A senha deve ter pelo menos 8 dígitos.",
      });
      return;
    }

    if (!/[A-Z]/.test(pass) || !/[a-z]/.test(pass) || !/\d/.test(pass)) {
      setError({
        ...error,
        password:
          "A senha deve ter pelo uma letra maiúscula, uma letra minúscula e um número.",
      });
      return;
    }

    if (pass !== pass2) {
      setError({ ...error, password2: "As senhas não estão iguais." });
      return;
    }

    if (formInfo.pin.length !== 4) {
      setError({ ...error, pin: "O PIN deve ter 4 dígitos." });
      return;
    }

    if (
      ["1234", "2345", "3456", "4567", "5678", "6789"].includes(formInfo.pin)
    ) {
      setError({
        ...error,
        pin: "O PIN não pode ser composto de números consecutivos.",
      });
      return;
    }

    if (formInfo.securityEletronic.length !== 8) {
      setError({
        ...error,
        securityEletronic: "A Senha Eletrônica deve ter 8 dígitos.",
      });
      return;
    }

    if (
      [
        "1234",
        "2345",
        "3456",
        "4567",
        "5678",
        "6789",
        "4321",
        "5432",
        "6543",
        "7654",
        "8765",
        "9876",
        "3210",
        "0123",
      ].includes(formInfo.securityEletronic)
    ) {
      setError({
        ...error,
        securityEletronic:
          "A Senha Eletrônica não pode ser composto de números consecutivos.",
      });
      return;
    }

    setLoading(true);

    // ...
    try {
      const raw = {
        franchise_id: franchiseId,
        document: formInfo.document.replace(/[/,.,-\s]/g, ""),
        name: formInfo.name,
        email: formInfo.email,
        planId: selectedPlanId,
        phone: {
          ddd: formInfo.phone.ddd.replace("(", "").replace(")", ""),
          number: formInfo.phone.number.replace(" ", "").replace("-", ""),
          type: formInfo.phone.type,
        },
        monthlyInvoicing:
          Number(formInfo.monthlyInvoicing.replace(/[/,R$ .,-\s]/g, "")) / 100,
        birthDate: formInfo.birthDate.split("/").reverse().join("-"),
        educationLevel: formInfo.educationLevel,
        maritalStatus: formInfo.maritalStatus,
        gender: formInfo.gender,
        address: {
          isConfirmed: true,
          publicPlace: formInfo.address.publicPlace,
          number: formInfo.address.number,
          complement: formInfo.address.complement || "",
          neighborhood: formInfo.address.neighborhood,
          cityName: formInfo.address.cityName,
          zipCode: formInfo.address.zipCode.replace(/-/g, ""), //only numbers
          type: formInfo.address.type,
          state: formInfo.address.state,
        },
        password: formInfo.password,
        pin: formInfo.pin,
        security_eletronic: formInfo.securityEletronic,
      };

      await service.post("users/individuals", raw, {
        headers: {
          //  Authorization: `Bearer ${token}`
          accept: "application/json",
          "content-type": "application/json",
          "x-delbank-api-key":
            "PJPyJ2xGmyB9oDHyNIUwNOt1dgpgolBwcE16ybaKD5rYEc8ujLtarBP0nNw2FKdgK+5YJFciFwTdORlZsdaTzjEbKN5ut+Ag4xGy69bbtXJmzkzRDHry9ubYbMW4xFMb",
        },
      });

      refreshAccount(); // pra tazer o id_user_master e o type dele (NATURAL | LEGAL)

      // await signIn({ document: raw.document, password: raw.password });
      setLoading(false);
      setStep(6);
    } catch (err) {
      const myError = serviceError(err);
      console.log(error);

      setLoading(false);
      setErrorMessage(myError.friend);
    } finally {
      setLoading(false);
    }
  }

  let customerDocument = formInfo && formInfo.document;

  if (customerDocument) {
    // Agora você pode usar o replace sem medo de erros
    const cleanedDocument = customerDocument.replace(/[/.,-\s]/g, "");

    // Atualiza a variável customerDocument com o valor limpo
    customerDocument = cleanedDocument;
  }
  //const documentType = 'RG_FRONT' ;
  console.log("oi eu sou o CustomerDocument", customerDocument);
  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorMessage("");
    setLoading(true);

    const file = event.target?.files?.[0];

    if (file) {
      try {
        // Converte a imagem para Base64
        const documentType = typeDoc;

        const fileBase64 = await convertToBase64(file);

        console.log("oi eu sou o base64:", fileBase64);
        // Agora, você pode enviar o base64String para o servidor ou fazer o que precisar
        // com a imagem convertida antes de chamar a API.

        // ...

        // Exemplo de chamada à API
        // const { data: response } = await axios.post(
        //   //  `https://apisandbox.delbank.com.br/baas/api/v1/customers/${customerDocument}/documents`,
        //   `https://apisandbox.delbank.com.br/baas/api/v1/customers/${customerDocument}/documents`,

        //   {
        //     base64: base64String,
        //     documentType,
        //     //description: 'Descrição opcional do documento',
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${Token}`,
        //       accept: "application/json",
        //       "Content-Type": "application/json",
        //       "x-delbank-api-key":
        //         "PJPyJ2xGmyB9oDHyNIUwNOt1dgpgolBwcE16ybaKD5rYEc8ujLtarBP0nNw2FKdgK+5YJFciFwTdORlZsdaTzjEbKN5ut+Ag4xGy69bbtXJmzkzRDHry9ubYbMW4xFMb",
        //     },
        //   }
        // );

        const _token = localStorage.getItem("@stricv2:token");

        const config = {
          headers: { Authorization: `Bearer ${_token}` },
        };

        const { data } = await api.post(
          "/users/documents",
          {
            documentType,
            base64: fileBase64,
            id_master_user: account.id_master_user,
            activeMasterUser: typeDoc == "RG_BACK",
          },
          config
        );

        // if (data?.status && data.status === "PENDING_DOCUMENTATION") {
        //   // mensagem de aguardar até 1 dia útil.
        //   setStep(99);
        //   setSuccess(true);
        // }

        if (data?.status && data.status === "denied") {
          // mensagem de não foi possível abrir a conta.
          setStep(66);
          setLoading(false);
          setSuccess(true);
        }

        if (typeDoc === "SELFIE") {
          setTypeDoc("RG_FRONT");
          setLoading(false);
          return;
        }

        // if (typeDoc === "CNH_FRONT") {
        //   setTypeDoc("CNH_BACK");
        // }

        if (typeDoc === "RG_FRONT") {
          setTypeDoc("RG_BACK");
          setLoading(false);
          return;
        }

        setStep(99);
        setSuccess(true);
        setLoading(false);

        setTimeout(() => {
          navigation("/");
        }, 5000);

        // if (typeDoc === 'IDENTITY_CARD_VERSE' || typeDoc === 'DRIVER_LICENSE_VERSE') {
        //   setTypeDoc('DOCUMENT_CARD');
        // }

        // if (typeDoc === 'DOCUMENT_CARD') {
        //   setTypeDoc('ARTICLES_OF_ASSOCIATION');
        // }

        // if (typeDoc === 'ARTICLES_OF_ASSOCIATION') {
        //   setTypeDoc('PROOF_ADDRESS');
        // }
        // ...

        // const responseDocument = await api.post(
        //   "/users/individuals/documents",
        //   {
        //     base64: base64Image,
        //   }
        // );
      } catch (error) {
        console.error("Error handling file:", error);
        setLoading(false);
        setErrorMessage("Error handling file");
      }
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === "string") {
          // Remover a parte "data:image/png;base64," da string Base64
          const base64String = reader.result.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          );
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert image to Base64."));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const mapStatusToTranslation = (status: string): string => {
    const statusTranslationMap: { [key: string]: string } = {
      Residencial: "RESIDENTIAL",
      Celular: "CELL",
      // Adicione mais mapeamentos conforme necessário
    };
    return statusTranslationMap[status] || status;
  };

  const mapGenderToTranslation = (gender: string): string => {
    const genderTranslationMap: { [key: string]: string } = {
      Masculino: "MALE",
      Feminino: "FEMALE",
      // Adicione mais mapeamentos conforme necessário
    };
    return genderTranslationMap[gender] || gender;
  };

  const mapMaritalStatusToTranslation = (status: string): string => {
    const maritalStatusTranslationMap: { [key: string]: string } = {
      "Solteiro(a)": "SINGLE",
      "Casado(a)": "MARRIED",
      "Separado(a) Legalmente": "LEGALLY_SEPARATED",
      "Divorciado(a)": "DIVORCED",
      "União Estável": "STABLE_UNION",
      "Desimpedido(a)": "DETACHED",
      "Viúvo(a)": "WIDOWER",
      Outros: "OTHERS",
      // Adicione mais mapeamentos conforme necessário
    };
    return maritalStatusTranslationMap[status] || status;
  };

  const mapEducationLevelToTranslation = (educationLevel: string): string => {
    const educationLevelTranslationMap: { [key: string]: string } = {
      Analfabeto: "ILLITERATE",
      "Ensino Fundamental - Anos Iniciais (1 a 4 anos)":
        "ELEMENTARY_1_TO_4_YEAR",
      "Ensino Fundamental - 5º ano": "ELEMENTARY_5_YEAR",
      "Ensino Fundamental - 6º a 9º ano": "ELEMENTARY_6_TO_4_YEAR",
      "Ensino Fundamental Completo": "COMPLETE_ELEMENTARY",
      "Ensino Médio Incompleto": "INCOMPLETE_HIGH_SCHOOL",
      "Ensino Médio Completo": "COMPLETE_HIGH_SCHOOL",
      "Ensino Superior Incompleto": "INCOMPLETE_HIGHER",
      "Ensino Superior Completo": "COMPLETE_HIGHER",
      "Pós-Graduação": "POSTGRADUATE",
      Mestrado: "MASTER_DEGREE",
      Doutorado: "DOCTORATE_DEGREE",
      // Adicione mais mapeamentos conforme necessário
    };
    const translatedEducationLevel =
      educationLevelTranslationMap[educationLevel] || educationLevel;

    // Adicione um console.log para imprimir o mapeamento
    console.log(
      `Nível de educação original: ${educationLevel}, Nível de educação traduzido: ${translatedEducationLevel}`
    );

    return translatedEducationLevel;
  };

  const mapAdressTypeToTranslation = (type: string): string => {
    const adressTypeTranslationMap: { [key: string]: string } = {
      Residencial: "RESIDECIAL",
      Comercial: "COMMERCIAL",
      // Adicione mais mapeamentos conforme necessário
    };
    return adressTypeTranslationMap[type] || type;
  };

  interface PlanMapping {
    [key: string]: string;
  }

  const [plansTranslationMap, setPlansTranslationMap] = useState<PlanMapping>(
    {}
  );

  useEffect(() => {
    const fetchPlansTranslations = async () => {
      try {
        const response = await axios.get("/chose-plans");
        const planMappings: PlanMapping = response.data;
        setPlansTranslationMap(planMappings);
      } catch (error) {
        console.error("Erro ao buscar mapeamento de planos:", error);
      }
    };

    fetchPlans();
    fetchPlansTranslations();
  }, []);

  const mapplanToTranslation = (plan: string): string => {
    return plansTranslationMap[plan] || plan;
  };

  const inputRefs: React.RefObject<HTMLInputElement>[] = [];

  // Crie as referências usando React.createRef
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const password2InputRef = useRef<HTMLInputElement>(null);
  const pinInputRef = useRef<HTMLInputElement>(null);
  const SecurityEletronicInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  return (
    <Container>
      {/* <button onClick={() => setStep(2)}>ASd</button> */}
      <div
        onClick={() => navigate("/")}
        className="absolute top-5 left-5 cursor-pointer z-40 px-2 py-1 flex items-center gap-1 bg-white bg-opacity-80 rounded-md"
      >
        <ChevronLeft size={22} color="black" />
        <span>Voltar para o banco</span>
      </div>
      <Modal
        onClickClose={() => {
          setShowPlanModal(false);
        }}
        open={showPlanModal}
      >
        <div className="flex flex-col items-center gap-8">
          <span className="text-3xl font-extrabold">Planos</span>
          <div className="flex w-full max-w-[800px] items-center gap-3 overflow-x-auto">
            {plans.map((p) => (
              <div className="flex flex-col justify-between p-4 min-w-[220px] max-w-[280px] h-[250px] overflow-hidden shadow-inner border border-zinc-200 rounded-xl">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">{p.name}</span>
                  <span className="text-3xl font-extrabold text-blue-500">
                    R$ {toLocaleAmount(p.price)}{" "}
                    <span className="text-sm">/ mês</span>
                  </span>
                </div>
                <span className="text-sm font-semibold text-zinc-800 text-clip">
                  {p.description}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <ContentWrapper>
        <div className="flex p-6">
          {step <= 6 && (
            <span className="text-lg border bg-white bg-opacity-85 rounded-md w-12 h-min text-center border-[#03142F]">
              {step}/6
            </span>
          )}
        </div>
        <WrapperRight>
          {/*<GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            Voltar ao Login
          </GoBackButtonWrapper>  */}
          <WrapperContent>
            {step <= 1 && (
              <div className="relative w-full">
                <span className="text-3xl text-[#03142F] mb-2">
                  Você está a poucos passos de uma nova
                  <br />
                  <strong>experiência financeira</strong>
                </span>
              </div>
            )}

            {step === 1 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>

                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleOneSubmit();
                  }}
                >
                  <FormInput
                    placeholder="Nome"
                    required
                    error={error.name || ""}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, name: e.target.value })
                    }
                  />
                  <FormInput
                    placeholder="CPF"
                    required
                    mask="999.999.999-99"
                    error={error.document || ""}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, document: e.target.value })
                    }
                  />
                  <FormInput
                    placeholder="Data de Nascimento"
                    required
                    mask="99/99/9999"
                    error={error.birthDate || ""}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, birthDate: e.target.value })
                    }
                  />
                  <FormInput
                    placeholder="E-mail"
                    required
                    error={error.email || ""}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, email: e.target.value })
                    }
                  />
                  <div className="flex items-center gap-2 bg-[#f6f6f6] pr-4 rounded-xl justify-between">
                    <FormInputSelect
                      label="Plano"
                      options={plans.map((p) => ({
                        id: p.id,
                        name: `${p.name} R$ ${toLocaleAmount(p.price)}`,
                      }))}
                      onChanged={(e) => setSelectedPlanId(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowPlanModal(true);
                      }}
                    >
                      <HelpCircle size={28} color="#009EE2" />
                    </button>
                  </div>

                  {/*
              <FormInput
                placeholder="NOME DA MÃE"
                required
                error={error.motherName || ""}
                onChanged={(e)=> setFormInfo({...formInfo, motherName: e.target.value})}
                /> */}
                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black" }}
                        type="button"
                      >
                        {/* <button
                        type="button"
                        onClick={() => {
                          setStep((old) => (old - 1 >= 1 ? old - 1 : 1));
                        }}
                      > */}
                        Voltar
                        {/* </button> */}
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button
                        style={{ backgroundColor: "#009ee2" }}
                        type="submit"
                      >
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 2 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleTwoSubmitStepPF();
                  }}
                >
                  <FormInputSelect
                    label="Gênero"
                    options={["Masculino", "Feminino"]}
                    onChanged={(e) => {
                      const translatedValue = mapGenderToTranslation(
                        e.target.value
                      );
                      setFormInfo({ ...formInfo, gender: translatedValue });
                    }}
                    error={error.isPep || ""}
                  />
                  <FormInputSelect
                    label="Estado Civil"
                    options={[
                      "Solteiro(a)",
                      "Casado(a)",
                      "Separado(a) Legalmente",
                      "Divorciado(a)",
                      "União Estável",
                      "Desimpedido(a)",
                      "Viúvo(a)",
                      "Outros",
                    ]}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        maritalStatus: mapMaritalStatusToTranslation(
                          e.target.value
                        ),
                      })
                    }
                    error={error.monthlyIncome || ""}
                  />
                  <FormInputSelect
                    label="Nível de educação"
                    options={[
                      "Analfabeto",
                      "Ensino Fundamental - Anos Iniciais (1 a 4 anos)",
                      "Ensino Fundamental - 5º ano",
                      "Ensino Fundamental - 6º a 9º ano",
                      "Ensino Fundamental Completo",
                      "Ensino Médio Incompleto",
                      "Ensino Médio Completo",
                      "Ensino Superior Incompleto",
                      "Ensino Superior Completo",
                      "Pós-Graduação",
                      "Mestrado",
                      "Doutorado",
                    ]}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        educationLevel: mapEducationLevelToTranslation(
                          e.target.value
                        ),
                      })
                    }
                    error={error.monthlyIncome || ""}
                  />
                  <FormInput
                    label="Renda mensal"
                    mask="currency"
                    required
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        monthlyInvoicing: e.target.value,
                      })
                    }
                    error={error.monthlyIncome || ""}
                  />
                  <div className="flex mb-[-15px] gap-2">
                    <div className="w-20">
                      <FormInput
                        placeholder="DDD"
                        required
                        mask="(99)"
                        error={error.phone || ""}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            phone: { ...formInfo.phone, ddd: e.target.value },
                          })
                        }
                      />
                    </div>
                    <div className="flex-1">
                      <FormInput
                        placeholder="Celular"
                        required
                        mask="9 9999-9999"
                        error={error.phone || ""}
                        onChanged={(e) =>
                          setFormInfo({
                            ...formInfo,
                            phone: {
                              ...formInfo.phone,
                              number: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <FormInputSelect
                    label="Tipo"
                    options={["Residencial", "Celular"]}
                    onChanged={(e) => {
                      const translatedValue = mapStatusToTranslation(
                        e.target.value
                      );
                      setFormInfo({
                        ...formInfo,
                        phone: { ...formInfo.phone, type: translatedValue },
                      });
                    }}
                    error={error.phone || ""}
                  />
                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black" }}
                        type="reset"
                        onClick={() => setStep(1)}
                      >
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button
                        style={{ backgroundColor: "#009ee2" }}
                        type="submit"
                      >
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 3 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleCep();
                  }}
                >
                  <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    error={error.address?.postalCode || ""}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          zipCode: e.target.value,
                        },
                      })
                    }
                  />

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black" }}
                        type="reset"
                        onClick={() => setStep(2)}
                      >
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button
                        style={{ backgroundColor: "#009ee2" }}
                        type="submit"
                      >
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 4 && (
              <>
                <span className="text-lg font-extralight text-gray-700 mb-2">
                  Todos os dados a seguir devem ser obrigatoriamente do titular.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddress();
                  }}
                >
                  <FormInput
                    placeholder="CEP"
                    mask="99999-999"
                    required
                    error={error.address?.postalCode || ""}
                    value={formInfo.address.zipCode}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          zipCode: e.target.value,
                        },
                      })
                    }
                  />

                  <div className="flex gap-5 mb-[27px]">
                    <FormInput
                      nomargin
                      placeholder="Cidade"
                      required
                      error={error.address?.city || ""}
                      value={formInfo.address.cityName}
                      onChanged={(e) =>
                        setFormInfo({
                          ...formInfo,
                          address: {
                            ...formInfo.address,
                            cityName: e.target.value,
                          },
                        })
                      }
                    />

                    <FormInput
                      nomargin
                      placeholder="Estado"
                      value={formInfo.address.state}
                      error={error.address?.state || ""}
                      required
                      maxLength={2}
                      onChanged={(e) =>
                        setFormInfo({
                          ...formInfo,
                          address: {
                            ...formInfo.address,
                            state: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <FormInput
                    placeholder="Bairro"
                    value={formInfo.address.neighborhood}
                    error={error.address?.neighborhood || ""}
                    required
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          neighborhood: e.target.value,
                        },
                      })
                    }
                  />

                  <FormInput
                    placeholder="Rua"
                    error={error.address?.street || ""}
                    value={formInfo.address.publicPlace}
                    maxLength={70}
                    required
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        address: {
                          ...formInfo.address,
                          publicPlace: e.target.value,
                        },
                      })
                    }
                  />

                  <div className="flex gap-5 mb-[27px]">
                    <FormInput
                      nomargin
                      placeholder="Número"
                      error={error.address?.number || ""}
                      value={formInfo.address.number}
                      required
                      onChanged={(e) =>
                        setFormInfo({
                          ...formInfo,
                          address: {
                            ...formInfo.address,
                            number: e.target.value,
                          },
                        })
                      }
                    />

                    <FormInput
                      nomargin
                      placeholder="Complemento"
                      value={formInfo.address.complement}
                      onChanged={(e) =>
                        setFormInfo({
                          ...formInfo,
                          address: {
                            ...formInfo.address,
                            complement: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <FormInputSelect
                    placeholder="Local"
                    options={["Residencial", "Comercial"]}
                    onChanged={(e) => {
                      const typeAdressValue = mapAdressTypeToTranslation(
                        e.target.value
                      );
                      setFormInfo({
                        ...formInfo,
                        address: { ...formInfo.address, type: typeAdressValue },
                      });
                    }}
                  />

                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black" }}
                        type="reset"
                        onClick={() => setStep(3)}
                      >
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button
                        style={{ backgroundColor: "#009ee2" }}
                        type="submit"
                      >
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 5 && (
              <>
                <span className="font-extralight text-gray-700 mb-2">
                  Defina suas credencias de acesso.
                </span>
                <span className="font-extralight text-gray-700 mb-2">
                  A sua senha deve ter pelo menos 8 dígitos, uma letra maiúscula
                  e um caractere especial.
                </span>
                <WrapperForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePasswords();
                  }}
                >
                  <FormInput
                    ref={passwordInputRef}
                    id="password-input"
                    borderColor="#ff0013"
                    placeholder="Senha"
                    type="password"
                    showPasswordIcon
                    error={error.password || ""}
                    value={formInfo.password}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, password: e.target.value })
                    }
                  />
                  <FormInput
                    ref={password2InputRef}
                    id="password2-input"
                    // borderColor="#ff0013"
                    placeholder="Confirme a senha"
                    type="password"
                    showPasswordIcon
                    error={error.password2 || ""}
                    value={formInfo.password2}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, password2: e.target.value })
                    }
                  />

                  <FormInput
                    ref={pinInputRef}
                    id="pin-input"
                    borderColor="#ff0013"
                    maxLength={4}
                    placeholder="SECURITY DIGIT (Camada extra de segurança)"
                    type="password"
                    mask="9999"
                    error={error.pin || ""}
                    showPasswordIcon
                    value={formInfo.pin}
                    onChanged={(e) =>
                      setFormInfo({ ...formInfo, pin: e.target.value })
                    }
                  />

                  <FormInput
                    ref={SecurityEletronicInputRef}
                    id="securty-eletronic-input"
                    borderColor="#ff0013"
                    maxLength={8}
                    placeholder="SENHA ELETRÔNIA (Senha de operações bancárias)"
                    type="password"
                    mask="99999999"
                    error={error.securityEletronic || ""}
                    showPasswordIcon
                    value={formInfo.securityEletronic}
                    onChanged={(e) =>
                      setFormInfo({
                        ...formInfo,
                        securityEletronic: e.target.value,
                      })
                    }
                  />
                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <WrapperDivButton>
                    <WrapperButtonBack>
                      <Button
                        style={{ backgroundColor: "black" }}
                        type="reset"
                        onClick={() => setStep(4)}
                      >
                        Voltar
                      </Button>
                    </WrapperButtonBack>
                    <WrapperButton>
                      <Button
                        style={{ backgroundColor: "#009ee2" }}
                        type="submit"
                      >
                        <Loading isLoading={loading} />
                        Avançar
                      </Button>
                    </WrapperButton>
                  </WrapperDivButton>
                </WrapperForm>
              </>
            )}

            {step === 6 && (
              <>
                {typeDoc === "SELFIE" && (
                  <>
                    <span className="text-blue-600 text-4xl my-2">
                      É hora da selfie!
                    </span>
                    <span className="text-gray-700">
                      Precisamos que envie uma foto do seu rosto para ninguém se
                      passar por você. Não se preocupe ela é apenas para sua
                      identificação e segurança.
                    </span>
                  </>
                )}

                {typeDoc !== "SELFIE" && (
                  <>
                    {typeDoc === "RG_FRONT" && (
                      <>
                        <span className="text-blue-600 text-4xl my-2">
                          É hora dos documentos
                        </span>
                        <span className="text-gray-700 my-2">
                          Precisamos que envie agora uma foto da FRENTE do seu
                          RG. Não se preocupe, é apenas para sua identificação e
                          segurança.
                        </span>
                      </>
                    )}

                    {(typeDoc === "CNH_BACK" || typeDoc === "RG_BACK") && (
                      <>
                        <span className="text-blue-600 text-4xl my-2">
                          É hora dos documentos
                        </span>
                        <span className="text-gray-700">
                          Precisamos que envie agora uma foto do VERSO do seu
                          documento escolhido anteriormente (CNH ou RG). Não se
                          preocupe, é apenas para sua identificação e segurança.
                        </span>
                      </>
                    )}

                    {typeDoc !== "CNH_BACK" &&
                      typeDoc !== "RG_BACK" &&
                      typeDoc !== "CNH_FRONT" &&
                      typeDoc !== "RG_FRONT" && (
                        <>
                          <span className="text-blue-600 text-4xl my-2">
                            É hora dos documentos
                          </span>
                          <span className="text-gray-700">
                            Precisamos que envie fotos do seu documento (CNH ou
                            RG). Não se preocupe, é apenas para sua
                            identificação e segurança. Escolhe abaixo qual
                            documento vai enviar.
                          </span>
                        </>
                      )}
                  </>
                )}
                <WrapperForm onSubmit={(e) => e.preventDefault()}>
                  {typeDoc === "DOCS" && (
                    <>
                      <Button
                        type="button"
                        style={{ marginBottom: "30px" }}
                        onClick={() => setTypeDoc("CNH_FRONT")}
                      >
                        CNH
                      </Button>
                      <Button
                        type="button"
                        onClick={() => setTypeDoc("RG_FRONT")}
                      >
                        RG
                      </Button>
                    </>
                  )}
                  {typeDoc !== "DOCS" && (
                    <>
                      {typeDoc === "RG_BACK" && (
                        <h3 className="text-2xl mb-5">
                          Ótimo! agora precisamos do verso
                        </h3>
                      )}
                      <div className="flex justify-center  items-center w-full">
                        <label
                          htmlFor="dropzone-file"
                          className={`${
                            loading ? "bg-gray-600" : ""
                          } flex flex-col justify-center items-center w-full h-40 border-2 border-dashed cursor-pointer border-gray-400 bg-white bg-opacity-75 rounded-xl`}
                        >
                          <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            {/* <div> ver isso depois pra aparecer a imagem do usuario selfie e tals pra ver se ficou bom
      <input type="file" onChange={handleFileSelect} />
      {base64Image && <img src={base64Image} alt="Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />}
    </div>
                            */}
                            {loading ? (
                              <>
                                <Loading isLoading />
                                <p className="text-gray-700 mt-5">
                                  Analisando imagem...
                                </p>
                              </>
                            ) : (
                              <>
                                <svg
                                  className="mb-3 w-10 h-10 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                  ></path>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">
                                    Clique para fazer upload
                                  </span>{" "}
                                  ou arraste e solte
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                  {" "}
                                  PNG or JPG (MAX. 800x400px)
                                </p>
                              </>
                            )}
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            onChange={handleFileSelect}
                          />
                        </label>
                      </div>
                      <div className="flex mt-2">
                        {typeDoc === "DOCUMENT_CARD" && (
                          <p>
                            <ul>
                              <li>Envie o cartão CNPF com boa definição</li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "ARTICLES_OF_ASSOCIATION" && (
                          <p>
                            <ul>
                              <li>Envie o contrato social</li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "PROOF_ADDRESS" && (
                          <p>
                            <ul>
                              <li>Envie o comprovante de endereço</li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "CNH_FRONT" && (
                          <p>
                            <ul>
                              <li>Envie a CNH com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento
                                embaçado
                              </li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "CNH_BACK" && (
                          <p>
                            <ul>
                              <li>Envie o verso da CNH com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento
                                embaçado
                              </li>
                            </ul>
                          </p>
                        )}

                        {typeDoc === "RG_FRONT" && (
                          <p>
                            <ul>
                              <li>Envie o RG com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento
                                embaçado
                              </li>
                            </ul>
                          </p>
                        )}
                        {typeDoc === "RG_BACK" && (
                          <p>
                            <ul>
                              <li>Envie o verso do RG com boa definição</li>
                              <li>
                                <strong>Não</strong> envie seu documento
                                embaçado
                              </li>
                            </ul>
                          </p>
                        )}

                        {typeDoc === "SELFIE" && (
                          <p>
                            <ul>
                              <li>Escolha uma foto com boa iluminação</li>
                              <li>
                                <strong>Não</strong> envie fotos com acessórios
                                que escondem o seu rosto
                              </li>
                              <li>
                                <strong>Não</strong> envie fotos com mais de uma
                                pessoa
                              </li>
                            </ul>
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {!!errorMessage && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </WrapperForm>
              </>
            )}

            {step === 66 && (
              <Text>
                Infelizmente não foi possível abrir sua conta neste momento.
              </Text>
            )}

            {step === 99 && (
              <Text>
                Pronto! Agora é só esperar a análise dos dados. Em até 1 dia
                você irá receber a resposta em seu e-mail.
              </Text>
            )}
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
}

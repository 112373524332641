/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { api } from "src/services/api";
import { useNotification } from "src/hooks/notification";
import { Edit2, Trash2 } from "lucide-react";
import {
  validateCellphone,
  validateCPF,
  validateDate,
  validateDDD,
  validateEmail,
} from "src/utils/validations";
import InputMask from 'react-input-mask';
import { serviceError } from "src/services/ajio";

type Plan = {
  id: string;
  name: string;
  description: string;
  price: number;
  subscribersIds: string[];
  avaliableToRoles: string[];
};

type IFormError = {
  phone?: string;
  document?: string;
  name?: string;
  email?: string;
  birthDate?: string;
  gender?: string;
  ddd?: string;
  password?: string;
  repeatPassword?: string;
  password2?: string;
  status?: string;
  plan?: string;
};

type IFormInfo = {
  password2: any;
  password: any;
  repeatPassword?: string;
  phone: {
    ddd: string;
    number: string;
    type: string;
  };
  document: string;
  name: string;
  email: string;

};
export function FormOperators() {
  const token = localStorage.getItem("@backoffice:token");

  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [ddd, setDdd] = useState("");
  const [phone, setPhone] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [status, setStatus] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [selectedNav, setSelectedNav] = useState("Create operator");
  const { setOpenNotification } = useNotification();
  const [operators, setOperators] = useState<any[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [operatorToDelete, setOperatorToDelete] = useState<{
    id: string;
  } | null>(null);

  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [errorMessage, setErrorMessage] = useState(""); // general form erros

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState<any | null>(null);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {

      if (password !== repeatPassword) {
        setError({ repeatPassword: "As senhas não coincidem." });
        setOpenNotification({
          type: "error",
          title: "Erro ao cadastrar operador.",
          notification: "As senhas não coincidem.",
        });
        return;
      }

      const data = {
        name: name,
        document: documentNumber.replace(/[.-]/g, ""),

        birthDate: new Date(birthDate).toISOString(),
        email: email,
        gender: gender,
        phone: {
          ddd: ddd.replace("(", "").replace(")", ""),
          number: phone.replace(" ", "").replace("-", ""),
          type: "mobile",
        },
        role: "OPERATOR",
        status: "active",
        password: password,
      };
      const response = await api.post("/backoffice/operators", data, {
        headers: { Authorization: "Bearer " + token },
      });
      console.log(response);

      setOpenNotification({
        type: "success",
        title: "Operador registrado com sucesso!",
        notification: "",
      });
    } catch (error) {
      setOpenNotification({
        type: "error",
        title: "Erro ao cadastrar operador.",
        notification: "Confira seus dados e tente novamente.",
      });
    }
  };

  const [editedName, setEditedName] = useState("");
  const [editedBirthDate, setEditedBirthDate] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedGender, setEditedGender] = useState("");
  const [editedDdd, setEditedDdd] = useState("");
  const [editedPhone, setEditedPhone] = useState("");
  const [editedDocumentNumber, setEditedDocumentNumber] = useState("");
  const [editedStatus, setEditedStatus] = useState("");
  const [editedPassword, setEditedPassword] = useState("");
  const [editedRepeatPassword, setEditedRepeatPassword] = useState("");

  // Adicione esta função para lidar com a submissão do formulário de edição
  const handleSubmitEdit = async (event: any) => {
    event.preventDefault();
    try {

      if (editedPassword !== editedRepeatPassword) {
        setError({ repeatPassword: "As senhas não coincidem." });
        setOpenNotification({
          type: "error",
          title: "Erro ao cadastrar operador.",
          notification: "As senhas não coincidem.",
        });
        return;
      }

      const data = {
        name: editedName,
        document: editedDocumentNumber.replace(/[.-]/g, ""),
        birthDate: new Date(editedBirthDate).toISOString(),
        email: editedEmail,
        gender: editedGender,
        role: "OPERATOR",
        phone: {
          ddd: editedDdd.replace("(", "").replace(")", ""),
          number: editedPhone.replace(" ", "").replace("-", ""),
          type: "mobile",
        },
        status: editedStatus,
        password: editedPassword,
      };
      const response = await api.put(
        `/backoffice/operators/${editingUser.id}`,
        data,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      setOpenNotification({
        type: "success",
        title: "Operador atualizado com sucesso.",
        notification: "",
      });

      fetchOperators();
      handleCloseEditModal();
    } catch (error) {
      setOpenNotification({
        type: "error",
        title: "Erro ao atualizar o operador tente novamente.",
        notification: "",
      });
    }
  };

  const fetchOperators = async () => {
    try {
      const response = await api.get("/backoffice/operators", {
        headers: { Authorization: "Bearer " + token },
      });
      setOperators(response.data);
    } catch (error) {
      console.error("Error fetching operators:", error);
      setOpenNotification({
        type: "error",
        title: "Error fetching operators.",
        notification: "",
      });
    }
  };

  useEffect(() => {
    if (selectedNav === "List operators") {
      fetchOperators();
    }
  }, [selectedNav]);

  const handleOpenEditModal = (operator: any) => {
    setEditingUser(operator);
    setShowEditModal(true);
    setEditedName(operator.name);
    setEditedBirthDate(operator.birthDate);
    setEditedEmail(operator.email);
    setEditedGender(operator.gender);
    setEditedDdd(operator.phone.ddd);
    setEditedPhone(operator.phone.number);
    setEditedDocumentNumber(operator.document);
    setEditedStatus(operator.status);
    setEditedPassword(operator.password);
    setEditedRepeatPassword(operator.password);
  };

  useEffect(() => {
    if (showEditModal) {
      setEditedPassword("");
      setEditedRepeatPassword("");
    }
  }, [showEditModal]);

  const handleCloseEditModal = () => {
    setEditingUser(null);
    setShowEditModal(false);
  };

  const handleOpenConfirmationModal = (operator: any) => {
    setOperatorToDelete(operator);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setOperatorToDelete(null);
  };

  const handleConfirmDelete = async (operatorId: any) => {
    try {
      
    
    const response = await api.delete(`/backoffice/operators/${operatorId}`, {
      headers: { Authorization: "Bearer " + token },
    });
    handleCloseConfirmationModal();

    setOpenNotification({
      type: "success",
      title: "Operador excluído com sucesso.",
      notification: "",
    });

    fetchOperators();
    handleCloseEditModal();
  } catch (error) {
    setOpenNotification({
      type: "error",
      title: "Erro ao excluir operador tente novamente.",
      notification: "",
    });
  }
};


  
  const clearErrorMessageAfterDelay = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const handleOneSubmit = async () => {
    setError({} as IFormError);

    // Name and Mother Name Validations
    if (!formInfo.name.includes(" ") || formInfo.name.length < 5) {
      setError({ name: "Preencha o nome completo." });
      return;
    }

    // CPF xxx.xxx.xxx-xx
    if (formInfo.document.length < 14) {
      setError({ document: "CPF Inválido." });
      return;
    }

    if (!validateCPF(formInfo.document.replace(/[/,.,-\s]/g, ""))) {
      setError({ document: "CPF Inválido." });
      return;
    }

    // Email
    if (formInfo.email.length < 6) {
      setError({ email: "Preencha o email." });
      return;
    }

    if (!validateEmail(formInfo.email)) {
      setError({ email: "Email Inválido." });
      return;
    }

    if(!validateCellphone(formInfo.phone.number.replace(/[-,\s,(,)]/g, ""))){
       setError({phone: 'Celular Inválido.'});
       return;
    }
    
  };

 
  async function handlePasswords() {
    setError({} as IFormError);

    // // Password Validations
    const pass = formInfo.password;
    const pass2 = formInfo.password2;
    if (pass.length < 8) {
      setError({
        ...error,
        password: "A senha deve ter pelo menos 8 dígitos.",
      });
      return;
    }

    if (!/[A-Z]/.test(pass) || !/[a-z]/.test(pass) || !/\d/.test(pass)) {
      setError({
        ...error,
        password:
          "A senha deve ter pelo uma letra maiúscula, uma letra minúscula e um número.",
      });
      return;
    }

    if (pass !== pass2) {
      setError({ ...error, password2: "As senhas não estão iguais." });
      return;
    }

    
  }
  

  function getStatus(status : string) {
    const statusMapper = {
      active: { label: "ATIVO", color: "green" },
      inactive: { label: "INATIVO", color: "red" },
    };
  
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus in statusMapper) {
      return statusMapper[lowerCaseStatus as keyof typeof statusMapper];
    } else {
      // Retorne um valor padrão caso o status não seja reconhecido
      return { label: "Desconhecido", color: "gray" };
    }
  }

  return (
    <div className="flex">
      <div className="w-1/4 p-4 border-r border-gray-300">
        <nav>
          <ul>
            <li
              className={`cursor-pointer ${
                selectedNav === "Create operator" ? "text-blue-600" : ""
              }`}
              onClick={() => setSelectedNav("Create operator")}
            >
              Cadastrar Operador
            </li>
            <li
              className={`cursor-pointer mt-2 ${
                selectedNav === "List operators" ? "text-blue-600" : ""
              }`}
              onClick={() => setSelectedNav("List operators")}
            >
              Visualizar Operadores
            </li>
          </ul>
        </nav>
      </div>
      <div className="w-3/4 p-4">
        {selectedNav === "List operators" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Operadores</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full rounded-lg overflow-hidden">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-2 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                      Nome
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {operators.map((operator) => (
                    <tr key={operator.id}>
                      <td className="px-4 py-2 text-sm">{operator.name}</td>
                      <td className="px-4 py-2 text-sm">{operator.email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                              className={`inline-flex rounded-full bg-${
                                getStatus(operator.status).color
                              }-100 px-2 text-xs font-semibold leading-5 text-${
                                getStatus(operator.status).color
                              }-800`}
                            >
                              {getStatus(operator.status).label}
                            </span></td>
                      <td className="px-4 py-2 text-sm">
                        <button
                          className="mr-2"
                          onClick={() => handleOpenEditModal(operator)}
                        >
                          <Edit2 size={18} />
                        </button>
                        <button
                          onClick={() => handleOpenConfirmationModal(operator)}
                        >
                          <Trash2 size={18} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>

                {/* Modais */}
                {showConfirmationModal && (
                  <div className="fixed inset-0 z-10 overflow-y-auto flex justify-center items-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden max-w-md w-full mx-4">
                      <div className="p-6">
                        <h2 className="text-lg font-semibold mb-4 text-center">
                          Deseja excluir o operador?
                        </h2>
                        <button
                          onClick={() => {
                            if (operatorToDelete) {
                              handleConfirmDelete(operatorToDelete.id);
                            }
                          }}
                          className="w-full bg-red-600 text-white py-2 rounded-md mb-4 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          Sim
                        </button>
                        <button
                          onClick={handleCloseConfirmationModal}
                          className="w-full bg-gray-200 text-gray-700 py-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                        >
                          Não
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {showEditModal && editingUser && (
                  <div className="fixed inset-0 z-10 overflow-y-auto flex justify-center items-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden max-w-2xl w-full mx-4">
                      <div className="p-6">
                        <h2 className="text-lg font-semibold mb-4 text-center">
                          Editar operador
                        </h2>
                        <form
                          onSubmit={handleSubmitEdit}
                          className="flex flex-wrap"
                        >
                          <div className="mb-4 w-1/2 pr-2">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Nome:
                            </label>
                            <input
                              type="text"
                              id="name"
                              value={editedName}
                              onChange={(e) => setEditedName(e.target.value)}
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"

                           />
                          </div>
                          <div className="mb-4 w-1/4 pl-2">
                            <label
                              htmlFor="birthDate"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Data de nascimento:
                            </label>
                            <input
                              type="date"
                              id="birthDate"
                              value={editedBirthDate.slice(0, 10)} // Exibe apenas a parte da data
                              onChange={(e) =>
                                setEditedBirthDate(e.target.value)
                              }
                              required
                  className="mt-1 py-1.5 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                          </div>
                          <div className="mb-4 w-1/4 pl-2">
                            <label
                              htmlFor="gender"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Gênero:
                            </label>
                            <select
                              id="gender"
                              value={editedGender}
                              onChange={(e) => setEditedGender(e.target.value)}
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            >
                              <option value="" disabled hidden>
                                Selecione
                              </option>
                              <option value="male">Masculino</option>
                              <option value="female">Feminino</option>
                              <option value="other">Outro</option>
                            </select>
                          </div>
                          <div className="mb-4 w-1/2 pr-4 md:pr-2">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email:
                            </label>
                            <input
                              type="email"
                              id="email"
                              value={editedEmail}
                              onChange={(e) => setEditedEmail(e.target.value)}
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                          </div>
                          
                          <div className="mb-4 w-2/12 pl-2 md:pl-2">
                            <label
                              htmlFor="ddd"
                              className="block text-sm font-medium text-gray-700"
                            >
                              DDD:
                            </label>
                            <InputMask    
                              type="text"
                              id="ddd"
                              placeholder="DDD"
                              required
                              mask="(99)"
                              value={editedDdd}
                              onChange={(e) => setEditedDdd(e.target.value)}
                              className="mt-1 py-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                          </div>
                          <div className="mb-4 w-2/2.5 pl-1 md:pl-3.5">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Telefone Celular:
                            </label>
                            <InputMask        
                              type="text"
                              id="phone"
                              mask="9 9999-9999"
                              value={editedPhone}
                              onChange={(e) => setEditedPhone(e.target.value)}
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                          </div>
                          <div className="mb-4 w-1/2 pr-2">
                            <label
                              htmlFor="documentNumber"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Número do documento:
                            </label>
                            <InputMask
                              mask={"999.999.999-99"}
                              type="text"
                              id="documentNumber"
                              value={editedDocumentNumber}
                              onChange={(e) =>
                                setEditedDocumentNumber(e.target.value)
                              }
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                          </div>
                          <div className="mb-4 w-1/2 pl-2">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Status:
                            </label>
                            <select
                              id="status"
                              value={editedStatus}
                              onChange={(e) => setEditedStatus(e.target.value)}
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            >
                              <option value="" disabled hidden>
                                Status
                              </option>
                              <option value="active">Ativo</option>
                              <option value="inactive">Inativo</option>
                            </select>
                          </div>
                          <div className="mb-4 w-1/2 pr-2">
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Senha:
                            </label>
                            <input
                              type="password"
                              id="password"
                              placeholder="*************"
                              value={editedPassword}
                              onChange={(e) =>
                                setEditedPassword(e.target.value)
                              }
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                          </div>
                          <div className="mb-4 w-1/2 pl-2">
                            <label
                              htmlFor="repeatPassword"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Repetir Senha:
                            </label>
                            <input
                              type="password"
                              id="repeatPassword"
                              value={editedRepeatPassword}
                              placeholder="*************"
                              onChange={(e) =>
                                setEditedRepeatPassword(e.target.value)
                              }
                              required
                              className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                          </div>
                          <button
                            type="submit"
                            className="bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 w-full"
                            onClick={handleSubmitEdit}
                          >
                            Salvar alterações
                          </button>
                          <button
                            className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100 w-full mt-2"
                            onClick={handleCloseEditModal}
                          >
                            Cancelar
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </table>
            </div>
          </div>
        )}
        {selectedNav === "Create operator" && (
          <form
            onSubmit={handleSubmit}
            className="max-w mt-8 p-4 border border-gray-300 rounded-lg shadow-md"
          >
            <div className="flex justify-between mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-bold text-blue-500"
              >
                Cadastrar novo operador
              </label>
            </div>
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-bold text-gray-700"
              >
                Informação
              </label>
            </div>
            <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/4 px-2 mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome:
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="Digite um Nome"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full md:w-1/6 px-1 mb-4">
                <label
                  htmlFor="birthDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Data de nascimento:
                </label>
                <input
                  type="date"
                  id="birthDate"
                  value={birthDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                  required
                  placeholder="Select date of birth"
                  className="mt-0.5 py-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full md:w-1/6 px-2 mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Digite um email"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
              </div>
              <div className="w-full md:w-1/6 px-1 mb-4">
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Genêro:
                </label>
                <select
                  id="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                  className="mt-1 py-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="" disabled hidden>
                    Select
                  </option>
                  <option value="male">Masculino</option>
                  <option value="female">Feminino</option>
                  <option value="other">Outro</option>
                </select>
              </div>
              <div className="w-1/2 md:w-1/12 px-5 mb-4">
                <label
                  htmlFor="ddd"
                  className="block text-sm font-medium text-gray-700"
                >
                  DDD:
                </label>
                <InputMask
                  type="text"
                  id="ddd"
                  value={ddd}
                  onChange={(e) => setDdd(e.target.value)}
                  mask="(99)"
                  required
                  placeholder="DDD"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full md:w-1/6 px-2 mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Telefone Celular:
                </label>
                <InputMask
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  mask="9 9999-9999"
                  required
                  placeholder="Número de telefone"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
            <div className="flex justify-between mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-bold text-gray-700"
              >
                Documentos
              </label>
            </div>
            <div className="flex  mb-4">
              <div className="w-full md:w-1/5  px-2 mb-4">
                <label
                  htmlFor="documentNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Número do CPF:
                </label>
                <InputMask
                 mask={"999.999.999-99"}
                  type="text"
                  id="documentNumber"
                  value={documentNumber}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                  required
                  placeholder="000.000.000-00"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full md:w-1/6 px-2 mb-4">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Status:
                </label>
                <select
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="" disabled hidden>
                    Status{" "}
                  </option>
                  <option value="active">Ativo</option>
                  <option value="inactive">Inativo</option>
                </select>
              </div>
            </div>
            <label
              htmlFor="name"
              className="block ml-2 text-sm font-bold text-gray-700"
            >
              Credênciais
            </label>
            <div className="flex  mb-4">
            <div className="w-full md:w-1/5  px-2 mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Senha:
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Digite uma Senha"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full md:w-1/6 px-2 mb-4">
                <label
                  htmlFor="repeatPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Repetir Senha:
                </label>
                <input
                  type="password"
                  id="repeatPassword"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  required
                  placeholder="Repita a Senha"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
            <button
              type="submit"
              className="bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 w-full"
            >
              Completar cadastro
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { CardActionOption } from "../../components/cardActionOption";
import { useClient } from "../../hooks/client";

import {
  Container,
  Content,
  OptionsWrapper,
  SentIcon,
  // StricIcon,
  Text,
} from "./styles";

export const Transfer: React.FC = () => {
  const {client} = useClient();
  // const [SidebarMobileIsActive, setSidebarMobileIsActive] = useState(false);

  // const handleSidebarMobileToggle = () => {
  //   setSidebarMobileIsActive((state) => !state);
  // };

  return (
    <Container>
      <Content>
        <OptionsWrapper>
          <CardActionOption
            // Icon={StricIcon}
            linkTo="list"
            title={`Transferir para ${client.name}`}
          />
          <CardActionOption
            Icon={SentIcon}
            linkTo="subscribe"
            title={"Transferir para outros bancos"}
          />
        </OptionsWrapper>
        <Text>
            <span>
              {/* Transferências solicitadas para outros bancos são processadas em
              até 1 hora. */}
            </span>
          </Text>
      </Content>
    </Container>
  );
};

export function passwordEletronicFormatted(val: string): string {
    // Remover caracteres não numéricos
    const cleanedVal = val.replace(/[^0-9]/g, "");
  
    // Verificar se a string resultante tem pelo menos 8 dígitos
    if (cleanedVal.length >= 8) {
      // Pegar apenas os primeiros 8 dígitos
      const first8Digits = cleanedVal.slice(0, 8);
  
      // Verificar se não há sequências de dígitos consecutivos
      if (!/(01234567|12345678|23456789|87654321|76543210)/.test(first8Digits)) {
        // Verificar se não há repetição de dígitos consecutivos
        if (!/(.)\1{7}/.test(first8Digits)) {
          return first8Digits;
        }
      }
    }
  
    // Se não atender aos requisitos, retorne uma string vazia
    return cleanedVal;
  }
  
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ExtractItem } from "src/utils/extractDescription";
import transactionLabel from "src/utils/transactionLabel";
import {
  ButtonWrapper,
  CloseIcon,
  DownloadIcon,
  InfoModal,
  ModalContent,
  ModalHeader,
  Section,
  SectionAccountinfo,
  SectionCPNJ,
  SectionItem,
  SectionPayerName,
  SectionTitle,
} from "../../ModalConfirmOperation/styles";
import toLocaleAmount from "src/utils/toLocaleAmount";
import dateToLine from "src/utils/dateToLine";
import { documentFormatted } from "src/utils/documentFormatted";
import { Button } from "../../button";
import { config } from "src/config";
import { useAuth } from "src/hooks/auth";
import { PixKeyResponse } from "../ProofResponses";
import { DefaultLabel } from "src/pages/receipt/PDF";

export const PixKey: FC<{ data: ExtractItem; closeModal: () => void }> = ({
  data,
  closeModal,
}) => {
  const { user } = useAuth();
  const txType = data.type.toLowerCase();

  console.log(txType);

  const naviagate = useNavigate();

  if (!txType.includes("pix")) {
    naviagate("/extract");
  }

  function printPDF() {
    if (!data) return null;

    const receiptString = JSON.stringify(data);

    localStorage.setItem("temp_receipt_to_print", receiptString);

    window.open(`${config.FRONTEND_URL}/PDF`);
  }

  const response = data.response as PixKeyResponse;

  const label = transactionLabel(data);

  const beneficiaryName = response.beneficiary.holder.name
    ? response.beneficiary.holder.name
    : data.direction == "in"
    ? user.name
    : "Desconhecido";

  return (
    <>
      <div className="w-full h-16 flex px-10 items-center bg-blue-500">
        <span className="text-white text-xl font-bold">{label}</span>
      </div>
      <div className="flex w-full justify-between p-10">
        <div>
          <span className="text-zinc-400 text-2xl font-bold">
            {response.beneficiary.holder.name}
          </span>
          <p className="text-blue-500 text-5xl font-bold">
            R$ {toLocaleAmount(data.amount)}
          </p>
        </div>
        <span className="font-bold text-lg">{dateToLine(data.created_at)}</span>
      </div>
      <div className="flex flex-col px-10 py-4 gap-1">
        <span className="text-zinc-500 font-semibold mb-2 text-3xl">
          Pagador
        </span>

        <DefaultLabel title="NOME" value={response.payer.holder.name} />
        <DefaultLabel
          title={response.payer.holder.document.length > 11 ? "CNPJ" : "CPF"}
          value={documentFormatted(response.payer.holder.document)}
        />
        <DefaultLabel
          title="INSTITUIÇÃO"
          //@ts-ignore
          value={response.payer.participant.name}
        />
        <DefaultLabel
          title="Agência"
          //@ts-ignore
          value={response.payer.branch}
        />
        <DefaultLabel
          title="Conta"
          //@ts-ignore
          value={response.payer.number}
        />
      </div>
      <div className="flex flex-col px-10 py-4 gap-1">
        <span className="text-zinc-500 font-semibold mb-2 text-3xl">
          Favorecido
        </span>
        <DefaultLabel title="NOME" value={beneficiaryName} />
        <DefaultLabel
          title={
            response.beneficiary.holder.document.length > 11 ? "CNPJ" : "CPF"
          }
          value={documentFormatted(response.beneficiary.holder.document)}
        />
        <DefaultLabel
          title="INSTITUIÇÃO"
          value={response.beneficiary.participant.name}
        />

        <div className="flex w-[50%] mt-2 justify-between items-center">
          {data?.data?.beneficiary?.role == "WALLET" ||
          data?.data?.beneficiary?.role == "GRAPHIC" ||
          data.GraphicAccount ? (
            <DefaultLabel
              size="md"
              title="N° Identificador"
              //@ts-ignore
              value={
                data?.data?.beneficiary?.number_identifier ||
                data?.GraphicAccount?.number_identifier
              }
            />
          ) : (
            <>
              <DefaultLabel
                size="md"
                title="AG"
                value={response.beneficiary.branch}
              />
              <DefaultLabel
                size="md"
                title="CONTA"
                value={response.beneficiary.number}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

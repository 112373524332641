/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from "react";
import { Container, Content, ContentWrapper } from "../Tax/styles";
import { api } from "src/services/api";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import { Dot } from "lucide-react";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TransactionDataPos } from "src/pages/receiptPos";
import { PagBankPaymentStatus } from "src/components/cardsTrade/types";

const formatValue = (value: number) => `R$ ${toLocaleAmount(value)}`;

const TransactionDetail = ({
  label,
  value,
  textColor,
}: {
  label: string;
  value: any;
  textColor?: string;
}) => (
  <p className="text-gray-500 mb-2">
    <span className="mr-2">{label}:</span>
    <span className={`font-bold ${textColor ? textColor : "text-gray-500"}`}>
      {typeof value === "number" ? formatValue(value) : value}
    </span>
  </p>
);

export function TransactionPagbankDetail({
  transaction,
}: {
  transaction: TransactionDataPos;
}) {
  const token = localStorage.getItem("@backoffice:token");
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col max-w-[500px]">
      <h2
        id="payment-details-heading"
        className="flex justify-between text-lg leading-6 font-bold text-gray-500 mb-8"
      >
        Detalhes da transação
      </h2>
      <TransactionDetail
        label="Status"
        value={
          transaction.status === PagBankPaymentStatus["COMPLETE"] ||
          transaction.status === PagBankPaymentStatus["DONE"]
            ? "Aprovada"
            : transaction.status === PagBankPaymentStatus["ANALYSIS"]
            ? "Em analise"
            : transaction.status === PagBankPaymentStatus["REFOUND"]
            ? "Devolvida"
            : "Cancelada"
        }
      />
      <TransactionDetail
        label="Código da transação"
        value={transaction.transactionId}
      />

      <TransactionDetail label="Valor Bruto" value={transaction.grossAmount} />
      <TransactionDetail
        label="Taxas"
        value={transaction.taxTotal}
        textColor="text-red-500"
      />
      <TransactionDetail
        label="Total (líquido)"
        value={transaction.netAmount}
      />
      <TransactionDetail
        label="Meio de pagamento"
        value={
          transaction.type == "1"
            ? `Crédito ${transaction.installments}x`
            : `Débito`
        }
      />

      <TransactionDetail
        label="Número de série da maquininha"
        value={transaction.machineSerial}
      />
    </div>
  );
}

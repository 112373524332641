import { CheckCircleIcon, ChevronRightIcon, XCircleIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { FC, Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { classNames } from "../Layout/base";
import { Dialog, Transition } from "@headlessui/react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { api } from "../../../services/api";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { currencyFormat } from "../../../utils/currencyFormat";
import { documentFormatted } from "../../../utils/documentFormatted";
import dateToLine from "src/utils/dateToLine";

type AccountProps = {
  id: string;
  name: string;
  balance: number;
  document: string;
  email: string;
  monthly_invoicing: number;
  phone: any;
  created_at: string;
  address?: {
    state: string; //only the UF code
    publicPlace: string;
    number: string;
    zipCode: string; //only numbers
    neighborhood: string;
    complement?: string;
    isConfirmed: boolean;
    type: string;
    cityId?: number;
    cityName?: string;
  };
};

type AddressProps = {
  city: string;
  complement: string;
  date: string;
  id: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
};

export function Member() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<AccountProps>({} as AccountProps);
  const [address, setAddress] = useState<AddressProps>({} as AddressProps);

  const [open, setOpen] = useState(false);

  const loadAccount = async () => {
    try {
      const token = localStorage.getItem("@backoffice:token");
      const response = await api.get(`/backoffice/accounts/${id}`, {
        params: {
          role: "MEMBER",
        },
        headers: { Authorization: "Bearer " + token },
      });
      setAccount({
        ...response?.data?.account?.user,
        ...response?.data?.account,
        address: response?.data?.account?.user?.Address[0] || undefined,
      });
      // setAddress(response?.data?.account?.user?.Address[0]);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  // console.log("response do account user", response)
  // const { token, user, account } = response.data;

  useEffect(() => {
    window.scrollTo(0, 0);
    loadAccount();
  }, []);

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
        <nav className="space-y-1">
          <Link
            to={`/backoffice/accounts/${id}`}
            className={classNames(
              "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
              "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
            )}
          >
            <span className="truncate">Dados gerais</span>
          </Link>
          <Link
            to={`/backoffice/accounts/${id}/extract`}
            className={classNames(
              "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
              "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
            )}
          >
            <span className="truncate">Transações (Extrato)</span>
          </Link>
          <Link
            to={`/backoffice/accounts/${id}/tax`}
            className={classNames(
              "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
              "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
            )}
          >
            <span className="truncate">Limites operacionais</span>
          </Link>
          <Link
            to={`/backoffice/upload/delbank/${id}`}
            className={classNames(
              "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
              "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
            )}
          >
            <span className="truncate">Envio de Documentos</span>
          </Link>
        </nav>
      </aside>

      <div className={classNames("space-y-6 sm:px-6 lg:px-0 w-ful", "lg:col-span-10")}>
        <section aria-labelledby="payment-details-heading">
          <>
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 sm:p-6">
                  <Skeleton isActive={loading} repeat={3} />
                  {!loading && (
                    <>
                      <div>
                        <h2
                          id="payment-details-heading"
                          className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                        >
                          {account.name}
                          <span>{currencyFormat(account.balance)}</span>
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          {/* Update your billing information. Please note that updating your location could affect your tax
                          rates. */}
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-4 gap-6">
                        <PropDetail title="Nome" value={account.name} />
                        <PropDetail cols={2} title="Documento" value={documentFormatted(account.document)} />
                        <PropDetail cols={2} title="Email" value={account.email} />
                        <PropDetail cols={1} title="Renda mensal" value={account.monthly_invoicing || "R$ ***"} />
                        <PropDetail
                          cols={1}
                          title="Celular"
                          value={`(${account?.phone?.ddd}) ${account?.phone?.number?.slice(
                            0,
                            5
                          )}-${account?.phone?.number?.slice(5)}`}
                        />
                        <PropDetail cols={2} title="Data de criação da conta" value={dateToLine(account.created_at)} />

                        {account.address && (
                          <>
                            <h1 className="col-span-4">Endereço</h1>

                            <PropDetail cols={4} title="CEP" value={account.address.zipCode} />
                            {account.address.cityName && (
                              <PropDetail cols={2} title="Cidade" value={account.address.cityName} />
                            )}

                            <PropDetail cols={2} title="Bairro" value={account.address.neighborhood} />
                            <PropDetail cols={1} title="Estado" value={account.address.state} />
                            <PropDetail cols={1} title="Número" value={account.address.number} />
                            <PropDetail cols={2} title="Rua" value={account.address.publicPlace} />

                            {account.address.complement && (
                              <PropDetail cols={4} title="Complemento" value={account.address.complement} />
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          </>
        </section>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {/* <CopyToClipboard text={baseString}
                          onCopy={() => console.log('copied')}>
                          <button>Copiar base64</button>
                        </CopyToClipboard> */}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 break-all">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <div className="h-full border-2 border-dashed border-gray-200" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

const PropDetail: FC<{
  title: string;
  value: string | number;
  cols?: number;
}> = ({ title, value, cols = 4 }) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <input
        type="text"
        name="name"
        id="name"
        disabled
        value={value}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
      />
    </div>
  );
};

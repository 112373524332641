/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { api } from "src/services/api";
import { Upload } from "lucide-react";
import {
  Container,
  Content,
  ContentWrapper,
  Table,
  TableWrapper,
  Text,
} from "../tax/styles";
import { useAuth } from "src/hooks/auth";

type TransactionData = {
  id: string;
  description: string;
  serialNum: string;
  status: string;
};

export function MyTerminals() {
  const token = localStorage.getItem("@stricv2:token");
  const [data, setData] = useState<TransactionData[]>([]);
  const { user, account } = useAuth();

  const fetchMachines = async () => {
    try {
      const { data: responseData } = await api.get(
        `/graphic/card-machines/${account.id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      setData(responseData);
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  useEffect(() => {
    fetchMachines();
  }, []);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Relatório das maquininhas</span>
          </Text>
          <Table>
            <div className="flex justify-between items-center h-full mb-8">
              <div>
                <h3 className="text-xl">Suas de máquinas</h3>
              </div>
              <div>
                <div className="inline-flex items-center bg-white p-2 rounded shadow border border-blue-500">
                  <span className="flex items-center font-medium text-sm text-blue-500">
                    <Upload
                      size={15}
                      color="#5393F3"
                      strokeWidth={2.5}
                      className="mt-[2px] duration-100"
                    />
                    <span className="ml-1">Exportar relatório</span>
                  </span>
                </div>
              </div>
            </div>

            <TableWrapper>
              <table className="border-collapse border-none">
                <thead>
                  <tr className="text-center font-semibold">
                    <td>Máquina Nome</td>
                    <td>Número de Série</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody className="border-collapse">
                  {data.map((machine) => (
                    <tr
                      key={machine.id}
                      className="text-center font-semibold h-20 mt-4"
                    >
                      <td>{machine.description}</td>
                      <td>{machine.serialNum}</td>
                      <td style={{ textAlign: "center" }}>Ativo</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
}

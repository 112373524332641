// Seu arquivo FormInputSelect.tsx
import React, { SelectHTMLAttributes, forwardRef, ForwardRefRenderFunction, ChangeEvent } from "react";
import { Container, ContainerNoMargin, Label } from "../styles";

interface PropsSelect extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: string[] | { name: string; id: string }[];
  onChanged?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onSelectOption?: (data: { name: string; id: string }) => void;
  placeholder?: string;
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  marginBottom?: string; //
}

const InputSelect: ForwardRefRenderFunction<HTMLSelectElement, PropsSelect> = (
  {
    label,
    error,
    options,
    nomargin = false,
    borderColor,
    borderRadius,
    marginBottom,
    onChanged,
    onSelectOption,
    id,
    placeholder,
    ...rest
  },
  ref
) => {
  return (
    <div
      style={{
        marginBottom,
        height: 55
      }}
    >
      <ContainerNoMargin>
        <Label>{label}</Label>
        <select ref={ref} {...rest} onChange={onChanged}>
          <option value="">{placeholder}</option>
          {options.map((opt) => {
            if (typeof opt === "string") {
              return (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              );
            }
            return (
              <option key={opt.id} value={opt.id}>
                {opt.name}
              </option>
            );
          })}
        </select>
      </ContainerNoMargin>
    </div>
  );
};

export const FormInputSelect = forwardRef(InputSelect);

import React, { FC, useEffect, useState } from "react";
import { dateFormat } from "src/utils/dateFormat";
import { documentFormatted } from "src/utils/documentFormatted";
import toLocaleAmount from "src/utils/toLocaleAmount";
import Barcode from "react-barcode";
import { useParams } from "react-router-dom";
import { api } from "src/services/api";

interface Payer {
  name: string;
  address: {
    city: string;
    state: string;
    number: string;
    zipCode: string;
    complement: string;
    publicPlace: string;
    neighborhood: string;
  };
  document: string;
}

interface Payment {
  // Defina a tipagem para os pagamentos, se necessário.
}

interface ChargeData {
  type: string;
  payer: Payer;
  beneficiary: Beneficiary;
  amount: number;
  qrCode: string;
  status: string;
  barCode: string;
  dueDate: string;
  payments: Payment[];
  createdAt: string;
  ourNumber: string;
  updatedAt: string;
  yourNumber: string;
  walletNumber: string;
  correlationId: string;
  digitableLine: string;
  additionalInfo: any[]; // Atualize conforme necessário
  qrCodeImageBase64: string;
}

type Beneficiary = {
  id: string;
  name: string;
  role: string;
  type: string;
  email: string;
  phone: {
    ddd: string;
    type: string;
    number: string;
  };
  refId: string;
  planId: null | string;
  status: string;
  api_key: string;
  document: string;
  password: string;
  created_at: string;
  updated_at: string;
  access_token: null | string;
  company_type: string;
  config_key_pix: null | string;
  monthly_invoicing: null | string;
  hash_reset_password: string;
};

export interface BankSlip {
  id: string;
  type: string;
  charge_type: string;
  due_date: string;
  amount: number;
  instructions: string;
  barcode: string;
  digitable_line: string;
  reference_id: string;
  created_at: string;
  qrcode64: string;
  correlationId: string;
  status: string;
  data: ChargeData; // Agora 'data' é tipado como ChargeData
}

const logo = require("../../assets/clients/vilapaybank/logo/white.png");

export const PublicBilletPrint: FC = () => {
  const [data, setData] = useState<BankSlip>();
  const [display, setDisplay] = useState<boolean>(true);

  const { id } = useParams();

  useEffect(() => {
    fetchBillet();
  }, []);

  const fetchBillet = async () => {
    try {
      const response = await api.get(`/bank-slips/${id}`);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  if (!data) return <>Erro ao buscar informacoes do boleto</>;

  return (
    <div
      onLoad={
        display
          ? () => {
              setDisplay(false);
              window.print();
            }
          : undefined
      }
      id="content"
      className="flex flex-col min-h-screen w-[720px] p-4 gap-2 bg-white"
    >
      <div className="flex w-full justify-between h-20 ">
        <div className="flex flex-col gap-2">
          <span className="text-blue-600 text-4xl">Olá,</span>
          <span className="font-bold text-2xl">{data.data.payer.name}</span>
        </div>
        <img src={logo} className="object-contain max-h-[70px]" />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col flex-1 border-2 border-zinc-300 p-4">
          <span className="text-blue-500 text-xl">Beneficiário</span>
          <span className="font-bold text-sm">
            {data.data.beneficiary.name}
          </span>
          <span className="font-bold">
            {documentFormatted(data.data.beneficiary.document)}
          </span>
        </div>
        <div className="flex flex-col flex-1 border-2 border-zinc-300 p-4">
          <span className="text-blue-500 text-xl">
            Pague com código de barras
          </span>
          <span className="text-sm">
            Pagamentos utilizando o método convencional podem ser feitos através
            da leitura do código de barras ou copiando a linha digitável:
          </span>
          <span className="font-bold text-sm text-clip">
            {data.data.digitableLine}
          </span>
          {data.qrcode64 && (
            <>
              <span className="text-blue-500 text-xl">Pague com o PIX</span>
              <span className="text-sm">
                Pagamento instantâneo através do PIX
              </span>
            </>
          )}
          {data.qrcode64 ? (
            <img
              src={data.qrcode64}
              className="self-center w-[140px] aspect-square"
            />
          ) : (
            <div className="h-48" />
          )}
        </div>
        <div className="w-[65px] pt-2">
          <div className="rotate-90">
            <Barcode
              value={data.data.barCode}
              width={1.2}
              height={45}
              margin={0}
              displayValue={false}
              fontSize={12}
            />
          </div>
        </div>
      </div>
      <div className="flex text-xs justify-end w-full border-b-2 border-dashed border-black">
        Corte na linha pontilhada
      </div>
      <div className="flex h-1/2 gap-2">
        <div className="flex flex-col w-[220px]">
          <img src={logo} className="object-contain w-[100px] self-center" />
          <div className="flex flex-col h-full border border-black divide-y-[1px] divide-black">
            <span className="self-end text-sm p-1">Recibo do pagador</span>
            <div className="flex flex-col p-1">
              <span className="text-sm">Beneficiário</span>
              <span className="text-xs">{data.data.beneficiary.name}</span>
            </div>
            <div className="flex w-full divide-x-[1px] divide-black">
              <div className="flex flex-col flex-1 p-1">
                <span className="text-xs">Parcela total</span>
                <span className="text-xs">1/1</span>
              </div>
              <div className="flex flex-col flex-1 p-1">
                <span className="text-xs">Vencimento</span>
                <span className="text-xs">{dateFormat(data.data.dueDate)}</span>
              </div>
            </div>
            <div className="flex flex-col p-1">
              <span className="text-sm">Agência/Código do Cadente</span>
              <span className="text-xs">N/A</span>
            </div>
            <div className="flex flex-col p-1">
              <span className="text-sm">Valor do Documento</span>
              <span className="text-xs font-bold">
                R$ {toLocaleAmount(data.amount)}
              </span>
            </div>
            <span className="text-xs p-1">(-) Descontos / Abatimentos</span>
            <span className="text-xs p-1">(-) Outras deduções</span>
            <div className="flex flex-col p-1">
              <span className="text-sm">(+) Mora / Multa</span>
              <span className="text-xs font-bold">R$ {toLocaleAmount(0)}</span>
            </div>
            <div className="flex flex-col p-1">
              <span className="text-sm">Pagador</span>
              <span className="text-xs font-bold">{data.data.payer.name}</span>
            </div>
            <div className="flex flex-col p-1">
              <span className="text-sm">Documento pagador</span>
              <span className="text-xs font-bold">
                {documentFormatted(data.data.payer.document)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex gap-1 items-end divide-x-[1px] divide-black">
            <img src={logo} className="object-contain w-[100px]" />
            <span className="p-1 font-bold text-sm h-full break-keep w-[50px]">
              435-9
            </span>
            <span className="p-1 text-xs h-full">
              {data.data.digitableLine}
            </span>
          </div>
          <div className="border border-black flex flex-col flex-1">
            <div className="flex border-b border-black divide-x-[1px] divide-black">
              <div className="flex flex-col p-1 w-[72%]">
                <span className="text-xs">Local do pagamento</span>
                <span className="text-xs font-bold">
                  PAGÁVEL EM QUALQUER BANCO
                </span>
              </div>
              <div className="flex flex-col p-1">
                <span className="text-xs">Vencimento</span>
                <span className="text-xs font-bold">
                  {dateFormat(data.data.dueDate)}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="flex border-b border-black divide-x-[1px] divide-black">
              <div className="flex flex-col p-1">
                <span className="text-xs">Beneficiário</span>
                <span className="text-xs font-bold">
                  {data.data.beneficiary.name}
                </span>
              </div>
              <div className="flex flex-col p-1">
                <span className="text-xs">
                  Agência / Código do Beneficiário
                </span>
                <span className="text-xs font-bold">0001/000036420</span>
              </div>
            </div>
            {/*  */}
            <div className="flex border-b border-black divide-x-[1px] divide-black">
              <div className="flex flex-col p-1">
                <span className="text-xs">Espécie</span>
                <span className="text-xs font-bold">R$</span>
              </div>
              <div className="flex flex-col p-1">
                <span className="text-xs">Espécie doc.</span>
                <span className="text-xs font-bold">DM</span>
              </div>
              <div className="flex flex-col p-1">
                <span className="text-xs">Nº documento</span>
                <span className="text-xs font-bold">{data.barcode}</span>
              </div>
            </div>
            {/*  */}
            <div className="flex border-b border-black divide-x-[1px] divide-black">
              <div className="flex flex-col w-[80%] p-1">
                <span className="text-xs">
                  Instruções ( Texto de responsabilidade do beneficiário)
                </span>
                <span className="text-xs font-bold">{data.instructions}</span>
              </div>
              <div className="flex flex-col divide-y-[1px] divide-black">
                <span className="flex flex-col text-xs p-1">
                  (=) Valor documento
                  <br />
                  <span className="self-end font-bold text-sm">
                    R$ {toLocaleAmount(data.amount)}
                  </span>
                </span>
                <span className="text-xs p-1">(-) Descontos / Abatimentos</span>
                <span className="text-xs p-1">(-) Outras deduções</span>
                <span className="text-xs break-all p-1">(+) Mora / Multa</span>
                <span className="text-xs break-all p-1">
                  (+) Outros Acréscimos
                </span>
                <span className="text-xs break-all p-1">(=) Valor cobrado</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <Barcode
          value={data.data.barCode}
          width={2.46}
          height={50}
          margin={0}
          fontSize={12}
        />
      </div>
    </div>
  );
};

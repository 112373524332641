import React, { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import FormSelectButton from "../../components/FormSelectButton";
// import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { InputSelect, SelectObject } from "../../components/select";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { banks } from "../../utils/banks";

import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Form,
  FormTitle,
  FormWrapper,
  SelectButtonWrapper,
  WrapperHeader,
} from "./styles";

type FormData = {
  document: string;
  name: string;
  agency: string;
  account: string;
  digit: string;
};

const schema = yup
  .object({
    name: yup.string().required("Nome é obrigatório"),
    document: yup.string().required("Documento é obrigatório"),
    agency: yup.string().required("Agência é obrigatório"),
    account: yup.string().required("Conta é obrigatório"),
    digit: yup.string().required("Dígito é obrigatório."),
  })
  .required();

const beneficiaryTypes: BeneficiaryType[] = [
  "CURRENT",
  "PAYMENT",
  "SAVING",
  "SALARY",
  "DIGITAL",
];

const mapBeneficiaryTypes = {
  CURRENT: "Corrente",
  DIGITAL: "Digital",
  PAYMENT: "Pagamento",
  SAVING: "Poupança",
  SALARY: "Salário",
};

type BeneficiaryType = "CURRENT" | "PAYMENT" | "SAVING" | "SALARY" | "DIGITAL";

export const TransferSubscribe: React.FC = () => {
  const [buttonSelected, setButtonSelected] = useState<"NATURAL" | "LEGAL">(
    "NATURAL"
  );
  const [mask, setMask] = useState("999.999.999-99");
  const [bankSelected, setBankSelected] = useState<SelectObject>(banks[0]);

  const [selectedBeneficiaryType, setSelectedBeneficiaryType] =
    useState<BeneficiaryType>(beneficiaryTypes[0]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const handlePersonalAccount = () => {
    setButtonSelected("NATURAL");
  };

  const handleBusinnessAccount = () => {
    setButtonSelected("LEGAL");
  };

  // const [selectedAccountType, setSelectedAccountType] =
  //   useState("Tipo de conta");

  // const handleAccountTypeSelect = (option: string) => {
  //   setSelectedAccountType(option);
  // };

  // const [selectedBank, setSelectedBank] = useState("Banco");
  // const handleBankSelect = (option: string) => {
  //   setSelectedBank(option);
  // };

  const handleFormSubmit: SubmitHandler<FormData> = async (values, event) => {
    console.log("values", values);

    navigate("/transfer/confirm", {
      state: {
        ...values,
        bank: bankSelected,
        type: buttonSelected,
        beneficiaryType:
          selectedBeneficiaryType === "DIGITAL"
            ? "CURRENT"
            : selectedBeneficiaryType,
      },
    });

    return;
  };

  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate("/transfer/confirm");
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (buttonSelected === "NATURAL") {
      setMask("999.999.999-99");
    } else {
      setMask("99.999.999/9999-99");
    }
  }, [buttonSelected]);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
            <StepHeader
              handleGoBackFunction={handleGoBack}
              title="Selecione uma das opções"
            />
          </WrapperHeader>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <SelectButtonWrapper>
              <FormSelectButton
                isSelected={buttonSelected === "NATURAL"}
                onClick={handlePersonalAccount}
                buttonName={"Transferir para Pessoa Física"}
              />
              <FormSelectButton
                isSelected={buttonSelected === "LEGAL"}
                onClick={handleBusinnessAccount}
                buttonName={"Transferir para Pessoa Jurídica"}
              />
            </SelectButtonWrapper>

            <FormTitle>Complete os dados</FormTitle>
            <FormWrapper>
              <div className="mb-5 bg-white">
                <InputSelect
                  label=""
                  selected={bankSelected}
                  options={banks}
                  onChange={setBankSelected}
                  onSearch="Digite um banco para pesquisar"
                />
              </div>

              <div className="mb-5 bg-white rounded-xl">
                <TextInput
                  placeholder={buttonSelected === "NATURAL" ? "CPF" : "CNPJ"}
                  mask={mask}
                  {...register("document")}
                  error={errors.document}
                />
              </div>

              <div className="mb-5 ">
                <TextInput
                  placeholder="Nome"
                  {...register("name")}
                  error={errors.name}
                />
              </div>

              <div className="mb-5 ">
                <TextInput
                  placeholder="Agência"
                  mask="9999"
                  {...register("agency")}
                  error={errors.agency}
                />
              </div>

              <div className="mb-5 ">
                <TextInput
                  placeholder="Conta sem dígito (só números)"
                  mask="9999999999999999999"
                  {...register("account")}
                  error={errors.account}
                />
              </div>

              <div className="mb-2">
                <TextInput
                  placeholder="Dígito (só número)"
                  // mask="9"
                  maxLength={1}
                  {...register("digit")}
                  error={errors.digit}
                />
              </div>
              <div className="flex flex-col gap-2">
                <span className="ml-1 text-sm">Tipo de conta</span>
                <div className="flex items-center gap-2">
                  {beneficiaryTypes.map((bt) => (
                    <BeneficiaryTypeButton
                      value={bt}
                      onSelect={() => setSelectedBeneficiaryType(bt)}
                      selected={selectedBeneficiaryType === bt}
                    />
                  ))}
                </div>
              </div>
              {/* <SelectInput
                optionSelected={selectedAccountType}
                handleFunction={handleAccountTypeSelect}
                options={["Conta Corrente", "Conta Poupança"]}
              /> */}
            </FormWrapper>
            <ButtonWrapper>
              <Button type="submit" title="Continuar" />
            </ButtonWrapper>
          </Form>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

const BeneficiaryTypeButton: FC<{
  value: BeneficiaryType;
  onSelect: () => void;
  selected: boolean;
}> = ({ onSelect, value, selected }) => {
  return (
    <div
      onClick={onSelect}
      className={`flex ${
        selected ? "bg-black border-none text-white" : "bg-zinc-200"
      } hover:border-zinc-300 border duration-100 cursor-pointer rounded-full text-sm items-center justify-center h-7 px-4`}
    >
      {mapBeneficiaryTypes[value]}
    </div>
  );
};

import "react-datepicker/dist/react-datepicker.css";

import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { endOfMonth, format, startOfMonth, subHours } from "date-fns";

import DatePicker from "react-datepicker";
import { PrintButtonIcon } from "src/pages/billet/styles";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { Transition } from "@headlessui/react";
import { api } from "../../../services/api";
import { classNames } from "../Layout/base";
import { currencyFormat } from "../../../utils/currencyFormat";
import { extractTransactionsPDF } from "src/utils/extract-transactions-pdf";
import handleExportPDF from "../Export/pdf";
import handleGenerateExcel from "../Export/excel";
import handleGeneratePDF from "../Export/pdf";
import ptBR from "date-fns/locale/pt-BR";
import { trancactionBuildItems } from "src/utils/transactions";
import { ExtractItem } from "src/utils/extractDescription";
import { useAuth } from "src/hooks/auth";
import handleExportExcelBackoffice from "src/utils/excel/backoffice";

export interface Transaction {
  id: string;
  credit: boolean;
  amount: number;
  type: string;
  typeId: string;
  description: string;
  status: string;
  balanceAfter: number;
  date: string;
}

export interface Detail {
  id: string;
  credit: boolean;
  amount: number;
  description: string;
  rateValue: number;
  consignorName: string;
  consignorDocument: string;
  consignorBankNumber: string;
  consignorBankBranch: string;
  consignorBankAccount: string;
  consignorBankAccountDigit: string;
  beneficiaryName: string;
  beneficiaryDocument: string;
  beneficiaryBankNumber: string;
  beneficiaryBankBranch: string;
  beneficiaryBankAccount: string;
  beneficiaryBankAccountDigit: string;
  status: string;
  date: string;
}

export interface MetaProps {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

export function Transfers() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [items, setItems] = useState<ExtractItem[]>([]);
  console.log(items)
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const [show, setShow] = useState(false);

  const token2 = window.localStorage.getItem("@backoffice:token");
  const perPage = 20;

  const { account, user } = useAuth();

  const loadTransfers = async (page: number) => {
    setLoading(true);

    try {
      let url = `/backoffice/transactions`;
      const { data } = await api.get(url, {
        params: {
          page: currentPage,
          perPage: perPage,
          role: "MEMBER",
        },
        headers: { Authorization: "Bearer " + token2 },
      });

      // Ordenar as transações com base na data de criação
      // const sortedItems = transactions.sort((a: any, b: any) => {
      //   const dateA = new Date(a.created_at);
      //   const dateB = new Date(b.created_at);
      //   return dateB.getTime() - dateA.getTime();
      // });

      setItems(data.transactions);
      setTotal(data.total);

      setLoading(false);
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await loadTransfers(currentPage);
    })();
  }, [currentPage, id]);

  const handleClickExportPDF = () => {
    handleExport(items, "pdf");
  };

  const handleClickExportExcel = () => {
    handleExport(items, "excel");
  };

  function handleExport(items: ExtractItem[], type: string) {
    setIsExporting(true);
    if (type == "pdf") {
      const params = {
        title: "Control Account",
        startDate: startDate,
        endDate: endDate,
        isBackoffice: true,
      };
      handleExportPDF(items, params, true);
    } else if (type == "excel") {
      handleExportExcelBackoffice(items);
    }
    setIsExporting(false);
  }

  function getType(type: string): string {
    if (type === "PIX") {
      return "Pix";
    }

    if (type === "TED") {
      return "Transferência";
    }

    if (type === "P2P") {
      return "Transferência";
    }

    if (type === "BILLET") {
      return "Boleto";
    }

    if (type === "PAYMENT") {
      return "Pagamento";
    }

    if (type === "INITIAL") {
      return "";
    }

    return type;
  }

  async function handlePrevious() {
    const page = currentPage - 1;
    if (page === 0) return;
    setCurrentPage(page);
  }

  const totalPages = Math.ceil(total / perPage);

  function handleNext() {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  const dateFormat = (date: string | undefined): string => {
    // Formata a data para o formato desejado
    const formattedDate = format(new Date(date || ""), "dd/MM/yyyy HH:mm", {
      locale: ptBR,
    });
    return formattedDate;
  };

  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-semibold text-gray-900">
          Extrato: Control Account
        </h1>
        <section aria-labelledby="">
          <>
            <div>
              <div>
                <Skeleton isActive={loading} repeat={3} />
                {!loading && (
                  <>
                    <div>
                      <span>Selecione um período para filtrar</span>
                      <div className="flex w-40">
                        <DatePicker
                          className="bg-gray-300 rounded px-2 py-1"
                          selected={startDate}
                          onChange={(date: Date) => {
                            setStartDate(date);
                            loadTransfers(currentPage);
                          }}
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                        <span className="px-2 py-1">até</span>
                        <DatePicker
                          className="bg-gray-300 rounded px-2 py-1"
                          selected={endDate}
                          onChange={(date: Date) => {
                            setEndDate(date);
                            loadTransfers(currentPage);
                          }}
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto"></div>
                      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                          type="button"
                          onClick={handleClickExportPDF}
                          disabled={items.length === 0}
                          className={`inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-3 ${
                            items.length === 0 ? "opacity-70 cursor-not-allowed" : ""
                          }`}
                        >
                          {isExporting ? "Exportando..." : "Exportar PDF"}
                        </button>

                        <button
                          type="button"
                          onClick={handleClickExportExcel}
                          disabled={items.length === 0}
                          className={`inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ${
                            items.length === 0 ? "opacity-70 cursor-not-allowed" : ""
                          }`}
                        >
                          {isExporting ? "Exportando..." : "Exportar Excel"}
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Data
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Tipo
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Transação
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Beneficiário
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Valor
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Imprimir
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {!loading &&
                                  items.map((item) => (
                                    <tr
                                      key={item.id}
                                      onClick={() => {
                                        // showTransaction(item as Transaction)
                                      }}
                                    >
                                      <td className="whitespace-nowrap py-4 pl-4 text-sm">
                                        {dateFormat(item.created_at)}
                                      </td>
                                      <td className="px-3 py-4 text-sm">
                                        {item.direction === "in"
                                          ? "Crédito"
                                          : "Débito"}
                                      </td>
                                      <td className="px-1 py-4 text-sm">
                                        {item.type === "pix" &&
                                        item.direction === "out"
                                          ? item?.GraphicAccount?.name ||
                                            "Conta encerrada e/ou sem registros"
                                          : item.direction === "in" &&
                                            item.description === "Deposito" &&
                                            item.type === "internal"
                                          ? "Deposito para C-A"
                                          : item.direction === "out" &&
                                            item.description === "Retirada" &&
                                            item.type === "internal"
                                          ? "Retirada de C-A"
                                          : item.direction === "out"
                                          ? item.GraphicAccount?.name
                                          : item.direction === "in"
                                          ? item.data.payer?.name
                                          : "deu bleiblade"}{" "}
                                      </td>
                                      <td className="px-3 py-4 text-sm">
                                        {item.direction === "out" &&
                                        item.type === "pix"
                                          ? item.response?.payer_payee
                                              ?.bank_account?.name ||
                                            item.description
                                          : item.description === "Deposito"
                                          ? item.GraphicAccount?.name ||
                                            "Conta encerrada e/ou sem registros"
                                          : item.description !== "Deposito"
                                          ? `${item.description} pela conta de Tutela` ||
                                            "nada aqui"
                                          : item.description !== "Deposito" &&
                                            item.type === "ted"
                                          ? item.description
                                          : item.direction === "in" &&
                                            item.type === "ted"
                                          ? item.description
                                          : "nulinho"}
                                      </td>
                                      <td className="px-3 py-4 text-sm">
                                        <span
                                          style={{
                                            color:
                                              item.direction === "in"
                                                ? "var(--green)"
                                                : "var(--boxText)",
                                            marginLeft:
                                              item.direction === "in"
                                                ? "8px"
                                                : "0",
                                          }}
                                        >
                                          {currencyFormat(Number(item.amount))}
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: "20px",
                                            display:
                                              item.direction === "in"
                                                ? "none"
                                                : "inline",
                                          }}
                                        ></span>
                                      </td>
                                      <td className="px-6 py-4 text-sm">
                                        <PrintButtonIcon>
                                          <PrinterIcon
                                            onClick={() =>
                                              extractTransactionsPDF(item)
                                            }
                                            color="var(--boxText)"
                                            className="h-4 w-4 inline"
                                          />
                                        </PrintButtonIcon>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            <div className="flex items-center justify-center mt-5 mb-5">
                              <ChevronDoubleLeftIcon
                                onClick={handlePrevious}
                                className="mr-3 cursor-pointer w-5 h-5 text-gray-500"
                              />
                              <span>
                                Página {currentPage} de {totalPages}
                              </span>
                              <ChevronDoubleRightIcon
                                onClick={handleNext}
                                className="ml-3 cursor-pointer w-5 h-5 text-gray-500"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              aria-live="assertive"
              className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
              <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                  show={show}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-sm font-medium text-gray-900">
                            Configurações salvar
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            As configurações de taxas foram alteradas.
                          </p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <button
                            type="button"
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </>
        </section>
      </div>
    </div>
  );
}

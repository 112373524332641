/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";

import { api } from "src/services/api";
import {
  CheckCircle2,
  ChevronRight,
  Clock,
  Undo2,
  Upload,
  X,
  XCircle,
} from "lucide-react";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { useNavigate } from "react-router-dom";
import CreditCardInfo from "src/components/creditCardInfo";
import { format, startOfMonth } from "date-fns";
import DatePicker from "react-datepicker";
import { ptBR } from "date-fns/locale";
import {
  Table,
  Container,
  Content,
  ContentWrapper,
  Text,
  TableWrapper,
} from "../tax/styles";
import { useAuth } from "src/hooks/auth";
import moment from "moment";
import { TransactionsPagbankDetails } from "../backoffice/TransactionsPagbankDetails";
import { TransactionPagbankDetail } from "../backoffice/TransactionsPagbankDetails/TransactionPagbankDetail";
import Modal from "src/components/Modal";
import { PagBankPaymentStatus } from "src/components/cardsTrade/types";

export type TransactionDataPos = {
  id: string;
  type: string;
  grossAmount: number;
  netAmount: number;
  taxTotal: number;
  isAwaitingTransfer: boolean;
  installments: number;
  machineId: string;
  machineSerial: string;
  transactionId: string;
  created_at: string;
  updated_at: string;
  status: number;
};

export function ReceiptPos() {
  const token = localStorage.getItem("@stricv2:token");
  const start = moment().subtract(1, "day");
  const end = moment();

  const formattedStartDate = start.format("YYYY-MM-DD");
  const formattedEndDate = end.format("YYYY-MM-DD");

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<string>(formattedStartDate);
  const [endDate, setEndDate] = useState<string>(formattedEndDate);
  const [data, setData] = useState<TransactionDataPos[]>([]);
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionDataPos>();
  const [detailsModalIsVisible, setDetailsModalIsVisible] =
    useState<boolean>(false);

  const { user } = useAuth();

  const handleRowClick = (transaction: TransactionDataPos) => {
    navigate(`/backoffice/transactions-pagbank/${transaction.id}`, {
      state: { transactionData: transaction },
    });
  };

  const fetchPlans = async () => {
    try {
      console.log(token);

      let url = `/pagbank/${startDate}/${endDate}/${user.id}`;
      const { data: responseData } = await api.get(url, {
        headers: { Authorization: "Bearer " + token },
      });

      if (responseData) {
        setData(responseData);
      } else {
        console.error("Não foram encontrados detalhes na resposta da API");
      }
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, [startDate, endDate]);

  const handleStartDateChange = (date: Date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setStartDate(formattedDate);
  };
  const handleEndDateChange = (date: Date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setEndDate(formattedDate);
  };

  const totalBruto = useMemo(() => {
    return data.reduce(
      (acc, transaction) =>
        (transaction.status == PagBankPaymentStatus["COMPLETE"] ||
          transaction.status == PagBankPaymentStatus["DONE"]) &&
        transaction.isAwaitingTransfer
          ? acc + transaction.grossAmount
          : acc,
      0
    );
  }, [data]);

  const totalLiquido = useMemo(() => {
    return data.reduce(
      (acc, transaction) =>
        (transaction.status == PagBankPaymentStatus["COMPLETE"] ||
          transaction.status == PagBankPaymentStatus["DONE"]) &&
        transaction.isAwaitingTransfer
          ? acc + transaction.netAmount
          : acc,
      0
    );
  }, [data]);
  return (
    <Container>
      <Modal
        open={detailsModalIsVisible}
        onClickClose={() => setDetailsModalIsVisible(false)}
      >
        {selectedTransaction && (
          <TransactionPagbankDetail transaction={selectedTransaction} />
        )}
      </Modal>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Relatório das maquininhas</span>
          </Text>
          <Table>
            <div className="flex justify-between items-center h-full mb-8">
              <div>
                <h3 className="text-xl">Período</h3>
                <span>Selecione um período</span>
                <div className="flex gap-4 items-center mt-1">
                  <DatePicker
                    className="bg-gray-300 rounded px-2 py-1"
                    selected={
                      new Date(
                        moment(startDate).add(1, "day").format("YYYY-MM-DD")
                      )
                    }
                    onChange={handleStartDateChange}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                  />
                  <span>até</span>
                  <DatePicker
                    className="bg-gray-300 rounded px-2 py-1"
                    selected={
                      new Date(
                        moment(endDate).add(1, "day").format("YYYY-MM-DD")
                      )
                    }
                    onChange={handleEndDateChange}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={() => window.print()}
                  className="inline-flex items-center bg-white p-2 rounded shadow border border-blue-500"
                >
                  <span className="flex items-center font-medium text-sm text-blue-500">
                    <Upload
                      size={15}
                      color="#5393F3"
                      strokeWidth={2.5}
                      className="mt-[2px] duration-100"
                    />
                    <span className="ml-1">Exportar relatório</span>
                  </span>
                </button>
              </div>
            </div>

            <TableWrapper>
              <table className="border-collapse border-none">
                <thead>
                  <tr className="text-center font-semibold">
                    <td style={{ color: "#686868", padding: "40px" }}>
                      Data e hora
                    </td>
                    <td style={{ color: "#686868" }}>Forma de pagamento</td>
                    <td style={{ color: "#686868" }}>Canal de venda</td>
                    <td style={{ color: "#686868" }}>Status</td>
                    <td style={{ color: "#686868" }}>Valor bruto</td>
                    <td style={{ color: "#686868" }}>Valor líquido</td>
                    <td colSpan={1}></td>
                  </tr>
                </thead>
                <tr className="h-10 bg-gradient-to-r from-slate-300 via-slate-200 to-slate-300 mt-4 text-center font-semibold">
                  <td style={{ color: "#686868" }}>
                    {/* {format(new Date(startDate), "dd 'de' MMM", {
                      locale: ptBR,
                    }).toUpperCase()}{" "}
                    | */}
                    {data.length} vendas
                  </td>
                  <td />
                  <td />
                  <td />
                  <td style={{ color: "#686868" }}>
                    R$ {toLocaleAmount(totalBruto)}
                  </td>
                  <td style={{ color: "#686868" }}>
                    R$ {toLocaleAmount(totalLiquido)}
                  </td>
                  <td style={{ color: "#686868" }} colSpan={1}></td>
                </tr>
                {data.length === 0 ? (
                  <tr>
                    <td colSpan={7} style={{ color: "#686868" }}>
                      Não há dados para o período selecionado
                    </td>
                  </tr>
                ) : (
                  data.map((transaction, index) => (
                    <tr
                      onClick={() => {
                        setSelectedTransaction(transaction);
                        setDetailsModalIsVisible(true);
                      }}
                      className="text-center cursor-pointer font-semibold h-20 mt-4 duration-150 hover:bg-gray-100"
                      key={index}
                    >
                      <td className="text-sm" style={{ color: "#686868" }}>
                        {moment(transaction.created_at).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </td>

                      <td className="text-sm" style={{ color: "#686868" }}>
                        Maquininha: {transaction.machineSerial}
                      </td>
                      {transaction.status ===
                        PagBankPaymentStatus["COMPLETE"] ||
                      transaction.status === PagBankPaymentStatus["DONE"] ? (
                        <td>
                          <div className="flex items-center text-sm">
                            <CheckCircle2
                              color="#4AD858"
                              className="h-5 w-5 mr-1"
                            />
                            Aprovada -{" "}
                            {transaction.isAwaitingTransfer
                              ? "Aguardando repasse"
                              : "Pago"}
                          </div>
                        </td>
                      ) : transaction.status ===
                        PagBankPaymentStatus["ANALYSIS"] ? (
                        <td>
                          <div className="flex text-sm items-center">
                            <Clock color="#d3d84a" className="h-5 w-5 mr-1" />
                            Em analise
                          </div>
                        </td>
                      ) : transaction.status ===
                        PagBankPaymentStatus["REFOUND"] ? (
                        <td>
                          <div className="flex text-sm items-center">
                            <Undo2 color="#d87c4a" className="h-5 w-5 mr-1" />
                            Devolvida
                          </div>
                        </td>
                      ) : (
                        <td>
                          <div className="flex text-sm items-center ">
                            <XCircle color="#d84a4a" className="h-5 w-5 mr-1" />
                            Cancelada
                          </div>
                        </td>
                      )}

                      <td
                        className="text-sm"
                        style={{ color: "#588DA5" }}
                        colSpan={1}
                      >
                        {transaction.type == "1"
                          ? `Crédito ${transaction.installments}x`
                          : `Débito`}
                      </td>
                      <td className="text-sm" style={{ color: "#686868" }}>
                        R$ {toLocaleAmount(transaction.grossAmount)}
                      </td>
                      <td className="text-sm" style={{ color: "#686868" }}>
                        R${" "}
                        {toLocaleAmount(
                          transaction.status !=
                            PagBankPaymentStatus["COMPLETE"] &&
                            transaction.status != PagBankPaymentStatus["DONE"]
                            ? 0
                            : transaction.netAmount
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  Container,
  Content,
  ContentWrapper,
  Table,
  TableWrapper,
  Text,
} from "../Tax/styles";
import { api } from "src/services/api";
import {
  CheckCircle2,
  Clock,
  Undo2,
  XCircle,
} from "lucide-react";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { ptBR } from "date-fns/locale";
import moment from "moment";
import {
  PagBankPaymentStatus,
  PagBankPaymentType,
} from "src/components/cardsTrade/types";
import { ExportPDF_MACHINE } from "../Export/pdf";
import handleExportExcelMachine from "src/utils/excel/machine";

type Machine = {
  description: string
  id: string
  serialNum: string
}

type User = {
  id: string;
  pagBankMachines: Machine[]
  name: string
}

type TransactionData = {
  id: string;
  type: string;
  grossAmount: number;
  netAmount: number;
  taxTotal: number;
  isAwaitingTransfer: boolean;
  installments: number;
  machineId: string;
  machineSerial: string;
  transactionId: string;
  created_at: string;
  updated_at: string;
  status: number;
};

export function TransactionsPagbank() {
  const token = localStorage.getItem("@backoffice:token");
  const start = new Date();
  const end = new Date();
  const navigate = useNavigate();

  const [endDate, setEndDate] = useState<string>(moment(end).format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState<string>(moment(start).subtract(1, "day").format("YYYY-MM-DD"));
  const [data, setData] = useState<TransactionData[]>([]);
  const [filteredData, setFilteredData] = useState<TransactionData[]>([]);

  const [users, setUser] = useState<User[]>();
  const [machines, setMachine] = useState<Machine[]>();

  const [selectedUser, setSelectedUser] = useState<User>();
  const [showUserSelectionModal, setShowUserSelectionModal] = useState(false);

  const [selectedType, setSelectedType] = useState<{ type: string }>({ type: "" });
  const [showTypeSelectionModal, setShowTypeSelectionModal] = useState(false);

  const [selectedMachine, setSelectedMachine] = useState<Machine>();
  const [showMachineSelectionModal, setShowMachineSelectionModal] = useState(false);

  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [isExportExcel, setIsExportExcel] = useState<boolean>(false);

  const types = [
    "Débito",
    ...Array.from({ length: 18 }, (_, i) => `Crédito ${i + 1}x`),
  ];


  const totalBruto = useMemo(() => {
    return filteredData.reduce(
      (acc, transaction) =>
        (transaction.status === PagBankPaymentStatus["COMPLETE"] ||
          transaction.status === PagBankPaymentStatus["DONE"]) &&
        transaction.isAwaitingTransfer
          ? acc + transaction.grossAmount
          : acc,
      0
    );
  }, [filteredData]);

  const totalLiquido = useMemo(() => {
    return filteredData.reduce(
      (acc, transaction) =>
        (transaction.status === PagBankPaymentStatus["COMPLETE"] ||
          transaction.status === PagBankPaymentStatus["DONE"]) &&
        transaction.isAwaitingTransfer
          ? acc + transaction.netAmount
          : acc,
      0
    );
  }, [filteredData]);

  const handleRowClick = (transaction: TransactionData) => {
    navigate(`/backoffice/transactions-pagbank/${transaction.transactionId}`, {
      state: { transactionData: transaction },
    });
  };

  const handleUserSelect = (user: User) => {
    setSelectedUser({
      id: user.id,
      name: user.name,
      pagBankMachines: user.pagBankMachines,
    });
    setMachine(user.pagBankMachines);
    setShowUserSelectionModal(false);
  };

  const handleMachineSelect = (machine: Machine) => {
    setSelectedMachine(machine);
    setShowMachineSelectionModal(false);
  };

  const handleTypeSelect = (type: string) => {
    setSelectedType({ type });
    setShowTypeSelectionModal(false);
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(format(date, "yyyy-MM-dd"));
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleClickExportPDF = () => {
    handleExport("pdf");
  };

  const handleClickExportExcel = () => {
    handleExport("excel");
  };

  async function handleExport(type: string) {
    if (type === "pdf") {
      setIsExportingPdf(true);

      ExportPDF_MACHINE({
        startDate: startDate,
        endDate: endDate,
        machineTransactions: filteredData,
        graphicAccountId: selectedUser!.id!,
        date: `${new Date().toLocaleDateString()}, ${new Date().toLocaleTimeString()}`
      }).finally(() => {
        setIsExportingPdf(false);
      });

    } else if (type === "excel") {
      setIsExportExcel(true);

      handleExportExcelMachine(
          {
            startDate: startDate,
            endDate: endDate,
            machineTransactions: filteredData,
            date: `${new Date().toLocaleDateString()}, ${new Date().toLocaleTimeString()}`
          }
        ).finally(() => {
          setIsExportExcel(false);
        });
      }

  }



  const fetchTransactions = async () => {
    try {
      let url = `/pagbank/${startDate}/${endDate}`;

      const { data: responseData } = await api.get(url, {
        headers: { Authorization: "Bearer " + token },
      });

      console.log(responseData);

      if (responseData) {
        setData(responseData);
        filterData(responseData, selectedUser, selectedMachine);
      } else {
        console.error("Não foram encontrados detalhes na resposta da API");
      }
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      let url = `/pagbank/wallet-machines`;
      const { data: responseData } = await api.get(url, {
        headers: { Authorization: "Bearer " + token },
      });

      console.log(responseData);

      if (responseData) {
        setUser(responseData);
      } else {
        console.error("Não foram encontrados detalhes na resposta da API");
      }
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  const filterData = (transactions: TransactionData[], user?: User, machine?: Machine, type?: { type: string }) => {
    let filtered = transactions;

    // Filtragem por máquina, se fornecida
    if (machine) {
      filtered = filtered.filter(transaction => transaction.machineId === machine.id);
    }

    // Filtragem por tipo, se fornecido
   if (type) {
    if (type.type === 'Débito') {
      // Filtra todas as transações que NÃO são de crédito
      filtered = filtered.filter(transaction => transaction.type !== "1");
    } else {
      // Extrai o número de parcelas para "Crédito"
      const installments = parseInt(type.type.split(' ')[1], 10);
      // Filtra apenas transações de crédito com o número específico de parcelas
      filtered = filtered.filter(transaction =>
        transaction.type === "1" && transaction.installments === installments
      );
    }
  }

    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchTransactions();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    filterData(data, selectedUser, selectedMachine, selectedType);
  }, [selectedUser, selectedMachine, data, selectedType]);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Relatório das maquininhas</span>
          </Text>
          <Table>
            <div className="flex justify-between items-center h-full mb-8">
              <div>
                <h3 className="text-xl">Período</h3>
                <span>Selecione um período</span>
                <div className="flex w-full items-center justify-center">
                  <DatePicker
                    className="bg-gray-300 rounded px-2 py-1"
                    selected={
                      new Date(
                        moment(startDate).add(1, "day").format("YYYY-MM-DD")
                      )
                    }
                    onChange={handleStartDateChange}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                  />
                  <span className="px-2 py-1">até</span>
                  <DatePicker
                    className="bg-gray-300 rounded px-2 py-1"
                    selected={
                      new Date(moment(endDate).add(1, "day").format("YYYY-MM-DD"))
                    }
                    onChange={handleEndDateChange}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                  />
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={selectedUser?.name}
                    onClick={() => setShowUserSelectionModal(true)}
                    className="ml-4 rounded px-2 py-1 w-24 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Nome"
                    readOnly
                  />
                  <button
                    onClick={() => setShowUserSelectionModal(true)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 100 4 2 2 0 000-4zM8 10a2 2 0 100-4 2 2 0 000 4zm8-2a2 2 0 11-4 0 2 2 0 014 0zm-4 4a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {showUserSelectionModal && (
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div
                          className="fixed inset-0 transition-opacity"
                          aria-hidden="true"
                        >
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span
                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true"
                        >
                          &#8203;
                        </span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                  className="text-lg leading-6 font-medium text-gray-900"
                                  id="modal-title"
                                >
                                  Selecione um Usuário
                                </h3>
                                <div className="mt-2">
                                  {users?.map((user) => (
                                    <div
                                      key={user.id}
                                      className="p-2 hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleUserSelect(user)}
                                    >
                                      {user.name}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setShowUserSelectionModal(false)}
                            >
                              Fechar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <input
                    type="text"
                    id="machine"
                    name="machine"
                    value={selectedMachine?.serialNum}
                    onClick={() => setShowMachineSelectionModal(true)}
                    className="ml-4 rounded px-2 py-1 w-24 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Maquinas"
                    readOnly
                  />
                  <button
                    onClick={() => setShowMachineSelectionModal(true)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 100 4 2 2 0 000-4zM8 10a2 2 0 100-4 2 2 0 000 4zm8-2a2 2 0 11-4 0 2 2 0 014 0zm-4 4a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {showMachineSelectionModal && (
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div
                          className="fixed inset-0 transition-opacity"
                          aria-hidden="true"
                        >
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span
                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true"
                        >
                          &#8203;
                        </span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                  className="text-lg leading-6 font-medium text-gray-900"
                                  id="modal-title"
                                >
                                  Selecione maquina do Usuário
                                </h3>
                                <div className="mt-2">
                                  {machines?.map((machine) => (
                                    <div
                                      key={machine.serialNum}
                                      className="p-2 hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleMachineSelect(machine)}
                                    >
                                      {machine.serialNum}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setShowMachineSelectionModal(false)}
                            >
                              Fechar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <input
                    type="text"
                    id="type"
                    name="type"
                    value={selectedType.type}
                    onClick={() => setShowTypeSelectionModal(true)}
                    className="ml-4 rounded px-2 py-1 w-24 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Tipo"
                    readOnly
                  />
                  <button
                    onClick={() => setShowTypeSelectionModal(true)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 100 4 2 2 0 000-4zM8 10a2 2 0 100-4 2 2 0 000 4zm8-2a2 2 0 11-4 0 2 2 0 014 0zm-4 4a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {showTypeSelectionModal && (
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div
                          className="fixed inset-0 transition-opacity"
                          aria-hidden="true"
                        >
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span
                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true"
                        >
                          &#8203;
                        </span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                  className="text-lg leading-6 font-medium text-gray-900"
                                  id="modal-title"
                                >
                                  Selecione o tipo de pagamento
                                </h3>
                                <div className="mt-2">
                                  {types.map((type) => (
                                    <div
                                      key={type}
                                      className="p-2 hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleTypeSelect(type)}
                                    >
                                      {type}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setShowTypeSelectionModal(false)}
                            >
                              Fechar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <h3 className="text-xl text-right">Valor</h3>
                  <div className="mt-2 text-right">
                      <p>Total Bruto: {toLocaleAmount(totalBruto)}</p>
                      <p>Total Líquido: {toLocaleAmount(totalLiquido)}</p>
                  </div>
                <div className="flex">
                  <button
                          type="button"
                          onClick={() => {handleClickExportPDF()}}
                          disabled={filteredData.length === 0}
                          className={`inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-3 ${
                            filteredData.length === 0 ? "opacity-70 cursor-not-allowed" : ""
                          }`}
                        >
                          {isExportingPdf ? "Exportando..." : "Exportar PDF"}
                        </button>

                <button
                  type="button"
                  onClick={() => {handleClickExportExcel()}}
                  disabled={filteredData.length === 0}
                  className={`inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ${
                   filteredData.length === 0 ? "opacity-70 cursor-not-allowed" : ""
                  }`}
                  >
                {isExportExcel ? "Exportando..." : "Exportar Excel"}
              </button>
                </div>
              </div>
            </div>
            <TableWrapper>
              <table className="border-collapse border-none">
                <thead>
                  <tr className="text-center font-semibold">
                    <td style={{ color: "#686868", padding: "40px" }}>
                      Data e hora
                    </td>
                    <td style={{ color: "#686868" }}>Maquininha</td>
                    <td style={{ color: "#686868" }}>Status</td>
                    <td style={{ color: "#686868" }}>Tipo</td>
                    <td style={{ color: "#686868" }}>Valor bruto</td>
                    <td style={{ color: "#686868" }}>Valor líquido</td>
                    {/* <td colSpan={1}></td> */}
                  </tr>
                </thead>
                <tr className="h-10 bg-gradient-to-r from-slate-300 via-slate-200 to-slate-300 mt-4 text-center font-semibold">
                  <td style={{ color: "#686868" }}>
                    {/* {format(new Date(startDate), "dd 'de' MMM", {
                      locale: ptBR,
                    }).toUpperCase()}{" "}
                    | */}
                    {filteredData.length} vendas
                  </td>
                  <td />
                  <td />
                  <td />
                  <td style={{ color: "#686868" }}>
                    {toLocaleAmount(totalBruto)}
                  </td>
                  <td style={{ color: "#686868" }}>
                    {toLocaleAmount(totalLiquido)}
                  </td>
                  <td style={{ color: "#686868" }} colSpan={1}></td>
                </tr>
                {/* <tbody className="border-collapse"> */}
                {filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={7} style={{ color: "#686868" }}>
                      Não há dados para o período selecionado
                    </td>
                  </tr>
                ) : (
                  filteredData.map((transaction, index) => (
                    <tr
                      onClick={() => handleRowClick(transaction)}
                      className="text-center cursor-pointer font-semibold h-20 mt-4"
                      key={index}
                    >
                      <td style={{ color: "#686868" }}>
                        {moment(transaction.created_at).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </td>
                      <td style={{ color: "#686868" }}>
                        Maquininha: {transaction.machineSerial}
                      </td>
                      <td style={{ color: "#686868" }}>
                        <div className="flex items-center">
                          {transaction.status ===
                            PagBankPaymentStatus["COMPLETE"] ||
                          transaction.status ===
                            PagBankPaymentStatus["DONE"] ? (
                            <div className="flex items-center ml-8">
                              <CheckCircle2
                                color="#4AD858"
                                className="h-5 w-5 mr-1"
                              />
                              Aprovada{" "}
                              {transaction.isAwaitingTransfer
                                ? "| Aguardando repasse"
                                : "| Pago"}
                            </div>
                          ) : transaction.status ===
                            PagBankPaymentStatus["ANALYSIS"] ? (
                            <div className="flex items-center ml-8">
                              <Clock color="#d3d84a" className="h-5 w-5 mr-1" />
                              Em analise
                            </div>
                          ) : transaction.status ===
                            PagBankPaymentStatus["REFOUND"] ? (
                            <div className="flex items-center ml-8">
                              <Undo2 color="#d87c4a" className="h-5 w-5 mr-1" />
                              Devolvida
                            </div>
                          ) : (
                            <div className="flex items-center ml-8">
                              <XCircle
                                color="#d84a4a"
                                className="h-5 w-5 mr-1"
                              />
                              Cancelada
                            </div>
                          )}
                        </div>
                      </td>
                      <td style={{ color: "#686868" }}>
                        {transaction.type === "1"
                          ? `Crédito ${transaction.installments}x`
                          : `Débito`}
                      </td>
                      <td style={{ color: "#686868" }}>
                        {toLocaleAmount(transaction.grossAmount)}
                      </td>
                      <td style={{ color: "#686868" }}>
                        {toLocaleAmount(
                          transaction.status !=
                            PagBankPaymentStatus["COMPLETE"] &&
                            transaction.status != PagBankPaymentStatus["DONE"]
                            ? 0
                            : transaction.netAmount
                        )}
                      </td>
                      {/* <td style={{ color: "#588DA5" }} colSpan={1}>
                          <ChevronRight
                            onClick={() => handleRowClick(transaction)}
                          />
                        </td> */}
                    </tr>
                  ))
                )}
                {/* </tbody> */}
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
}

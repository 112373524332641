import React, { useEffect, useState, Fragment, FormEvent, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/button";

import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/react/outline";

import {
  Action,
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  RedirectWrapperButton,
  Search,
  Table,
  TableWrapper,
  ArrowRightDepositIcon,
  ButtonsTableWrapper,
} from "./styles";

import { Button as ButtonForm } from "../createAccountForm/styles";

import { service, serviceError } from "../../services/ajio";
import { documentFormatted } from "../../utils/documentFormatted";
import { currencyFormat } from "../../utils/currencyFormat";
import { FormInput } from "../../components/LPFormButton2";
import { Loading } from "../../components/loading";
import { Skeleton } from "../../components/skeleton";
import { Money } from "phosphor-react";
import { format } from "date-fns";
import TextInput from "../../components/TextInput";
import axios from "axios";

interface Transaction {
  created_at: string | number | Date;
  id: string;
  transaction_id: string | null;
  status: string;
  direction: "out" | "in";
  amount: number;
  description: string;
  type: string;
}

interface Account {
  id: string;
  name: string;
  balance: number;
  document: string;
  email: string;
  security_eletronic: string;
  pin: string;
}

export const Aprovacoes: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingExecute, setLoadingExecute] = useState(false);
  const [error, setError] = useState("");

  const [account, setAccount] = useState<Account>({} as Account);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const [idsAprove, setIdsAprove] = useState<string[]>([]);
  const [idsReprove, setIdsReprove] = useState<string[]>([]);

  const [pin, setPin] = useState("");
  const [valueEletronic, setValueEletronic] = useState("");

  async function handleApproveList() {
    setLoading(true);
    try {
      const { data } = await service.get(`/graphic/${id}/transactions`, {
        params: {
          status: "waiting",
        },
      });

      console.log("Transações:", data.transactions); // Exibir as transações no console

      setAccount(data);
      setTransactions(data.transactions);
    } catch (err) {
      setError(serviceError(err).friend);
    } finally {
      setLoading(false);
    }
  }

  //------------------
  function encontrarTransacoesRelacionadas(
    transacoes: Transaction[]
  ): string[] {
    const comparacoes: string[] = [];

    // Filtra as transações do tipo "pix" em estado "waiting"
    const transacoesPix = transacoes.filter(
      (transacao) => transacao.type === "pix" && transacao.status === "waiting"
    );

    // Itera sobre as transações do tipo "pix"
    transacoesPix.forEach((pixTransacao) => {
      // Filtra as transações do tipo "p2p_transfer" em estado "waiting"
      const transacoesP2P = transacoes.filter(
        (transacao) =>
          transacao.type === "p2p_transfer" &&
          transacao.status === "waiting" &&
          Math.abs(
            new Date(transacao.created_at).getTime() -
              new Date(pixTransacao.created_at).getTime()
          ) <= 10000
      );

      // Se houver transações do tipo "p2p_transfer" com condições semelhantes, realiza a comparação
      if (transacoesP2P.length > 0) {
        const transacaoRelacionada = transacoesP2P[0];
        const mensagem =
          "Oi, eu sou uma transação relacionada com o valor do ID: " +
          transacaoRelacionada.id +
          transacaoRelacionada.amount +
          transacaoRelacionada.description +
          transacaoRelacionada.type;
        comparacoes.push(mensagem);
      } else {
        comparacoes.push("Não há transação relacionada");
      }
    });

    return comparacoes;
  }

  const comparacoes = encontrarTransacoesRelacionadas(transactions);
  console.log(comparacoes);

  //-------------------------

  useEffect(() => {
    handleApproveList();
  }, []);

  async function handleAproove(transaction_id: string) {
    const txIdExists = idsAprove.find((_id) => _id == transaction_id);
    const txIdExistsInReprove = idsReprove.find((_id) => _id == transaction_id);

    if (txIdExistsInReprove) {
      setIdsReprove((old) => old.filter((_id) => _id !== txIdExistsInReprove)); //deleta caso exista :)
    }

    if (txIdExists) {
      setIdsAprove(idsAprove.filter((_id) => _id !== transaction_id));
    } else {
      setIdsAprove([...idsAprove, transaction_id]);
    }
  }

  async function handleReprove(transaction_id: string) {
    const txIdExists = idsReprove.find((_id) => _id == transaction_id);
    const txIdExistsInAproove = idsAprove.find((_id) => _id == transaction_id);

    if (txIdExistsInAproove) {
      setIdsAprove((old) => old.filter((_id) => _id !== txIdExistsInAproove)); //deleta caso exista :)
    }

    if (txIdExists) {
      setIdsReprove(idsReprove.filter((_id) => _id !== transaction_id));
    } else {
      setIdsReprove([...idsReprove, transaction_id]);
    }
  }

  async function handleApproveAll() {
    setError("");
    if (
      !account.security_eletronic ||
      account.security_eletronic.length !== 8
    ) {
      setError("Digite a senha eletronica.");
      return;
    }

    if (!idsAprove.length && !idsReprove.length) {
      setError("É preciso selecionar ao menos um item");
      return;
    }

    try {
      if (idsReprove.length > 0) {
        const responseDenied = await service.post(
          `/graphic/transactions/denied`,
          {
            data: idsReprove,
            graphic_account_id: id,
          }
        );
        console.log("oi sou a response do post responseDenied", responseDenied);
      }

      if (idsAprove.length > 0) {
        const responseAproved = await service.post(
          `/graphic/transactions/approved`,
          {
            data: idsAprove,
            graphic_account_id: id,
          }
        );
        console.log(
          "oi sou a response do post responseAproved",
          responseAproved
        );
      }

      await handleApproveList();
      setLoadingExecute(false);
    } catch (error) {
      setLoadingExecute(false);
      setError("Erro ao processar ação.");
    }
  }

  return (
    <>
      <Container>
        <Content>
          <ContentWrapper>
            <Action>
              <Search>
                <span>
                  Liste todas as suas transações pendentes da conta: <br />
                  {account.name} - {documentFormatted(account.document)}
                </span>
              </Search>
              {/* <ButtonWrapper>
                <Button handleFunction={() => {}} title="Criar Conta" />
              </ButtonWrapper> */}
            </Action>
            <Table>
              <TableWrapper>
                {loading ? (
                  <Skeleton space={4} isActive={loading} repeat={5} />
                ) : (
                  <>
                    {transactions && transactions.length <= 0 ? (
                      <div className="text-center p-4">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                          Nenhuma transação para aprovar.
                        </h3>
                      </div>
                    ) : (
                      <>
                        <table>
                          <tbody>
                            <tr>
                              <th>Descrição</th>
                              <th>Tipo</th>
                              <th>Valor</th>
                              <th>Saldo na conta</th>
                              <th>Aprovar</th>
                              <th>Reprovar</th>
                            </tr>

                            {transactions.map((item) => {
                              // Aplicar as condições desejadas para evitar a renderização
                              if (
                                item.status === "waiting" &&
                                item.type === "p2p_transfer" &&
                                item.description === "P2P AJIOPAY"
                              ) {
                                return null; // Evitar a renderização desta transação
                              }

                              return (
                                <tr key={item.id}>
                                  <td>{item.description}</td>
                                  <td>{item.type}</td>
                                  <td>{currencyFormat(item.amount)}</td>
                                  <td>{currencyFormat(account.balance)}</td>

                                  <td>
                                    {idsAprove.includes(item.id) ? (
                                      <button
                                        onClick={() => handleAproove(item.id)}
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-green-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                      >
                                        Selecionado
                                        <CheckCircleIcon
                                          className="-mr-0.5 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => handleAproove(item.id)}
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                      >
                                        Aprovar
                                        {/* <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" /> */}
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    {idsReprove.includes(item.id) ? (
                                      <button
                                        onClick={() => handleReprove(item.id)}
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-red-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                      >
                                        Selecionado
                                        <CheckCircleIcon
                                          className="-mr-0.5 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => handleReprove(item.id)}
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                      >
                                        Reprovar
                                        {/* <XCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" /> */}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </>
                )}

                {(idsAprove.length > 0 || idsReprove.length > 0) && (
                  <>
                    <div className="p-4 flex justify-end">
                      <div className="w-48 ">
                        <TextInput
                          placeholder="Senha eletrônica"
                          maxLength={8}
                          value={valueEletronic}
                          type="password"
                          onChange={(input) =>
                            setValueEletronic(input.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="mb-4 pr-4 flex justify-end">
                      <Button
                        type="button"
                        isLoading={loadingExecute}
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        title="Aprovar/reprovar itens selecionados"
                        handleFunction={handleApproveAll}
                      />
                    </div>

                    <div className="mb-4 pr-4 flex justify-end">
                      {error && <div>{error}</div>}
                    </div>
                  </>
                )}
              </TableWrapper>
            </Table>
          </ContentWrapper>
        </Content>
      </Container>
    </>
  );
};

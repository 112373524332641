import { getTransactionNames } from "src/components/extractPdf";
import { currencyFormat } from "./currencyFormat";
import { dateFormat } from "./dateFormat";
import { extractDescription } from "./extractDescription";
import transactionLabel from "./transactionLabel";

export const formatTableTransaction = (item: any) => {
  const type = item.direction === "in" ? "Crédito" : "Débito";
  let varData: string = "";
  if (item.data && (item.data.payer || item.data.payer_payee)) {
    if (item.data.payer && item.data.payer.name) {
      varData = item.data.payer.name;
    } else if (item.data.payer_payee && item.data.payer_payee?.name) {
      varData = item.data.payer_payee.name;
    }
  }
  if (
    item.response &&
    item.response.payer_payee &&
    item.response.payer_payee.bank_account &&
    item.response.payer_payee.bank_account.name
  ) {
    varData = "Control Account " + item.response.payer_payee?.bank_account.name;
  }

  const names = getTransactionNames(item);

  return {
    type,
    title: transactionLabel(item),
    description: "asdasd",
    status: transactionStatus(item.status, true),
    created_at: dateFormat(item.created_at),
    amount: currencyFormat(item.amount),
    data:
      item.direction == "in"
        ? `De ${names.payerName}`
        : `Para ${names.beneficiaryName}`, // beneficiary
  };
};

export const transactionStatus = (status: string, graphic: boolean) => {
  const message: Record<string, string> = {
    denied: "Transação negada",
  };
  return message[status] ?? "Status não encontrado";
};

export function trancactionBuildItems(item: any, users: any) {
  item.title = extractDescription(item);
  if (users.role === "GRAPHIC") {
    item.GraphicAccount = users;
  }
  if (item.direction === "in") {
    if (item.GraphicAccount) {
      item.data = {
        payer: {
          name: item.GraphicAccount.name,
        },
      };
    } else {
      item.data = {
        payer: {
          name: users.name ?? item.data.payer.name,
        },
      };
    }
  }
  if (item.title === "Retirada")
    item.data = {
      payer: item.GraphicAccount,
    };
  item.GraphicAccount = users.role !== "GRAPHIC" ? true : false;
  return item;
}

import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import supportSmile from "src/assets/support-smile.png";
import logoBlack from "src/assets/logo-black.png";
import { ChevronDown, MessageSquareIcon, MessagesSquare } from "lucide-react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

export default function Support() {
  const height = useMemo(() => window.innerHeight - 76, [window.innerHeight, window]);

  const navigate = useNavigate();

  return (
    <div style={{ height }} className="flex contentHeight">
      <img src={supportSmile} className="flex object-cover h-full w-[380px] 2xl:w-[570px]" />
      <div className="flex flex-col flex-1 px-20 py-6 gap-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <img src={logoBlack} className="object-contain aspect-square h-[70px] w-[70px]" />
            <span className="text-4xl font-bold text-zinc-800">Perguntas frequentes</span>
          </div>
          <button
            onClick={() => navigate("how")}
            className="flex items-center gap-2 bg-[#274683] rounded-md shadow-lg px-3 p-2"
          >
            <span className="text-white font-medium">Preciso de ajuda!</span>
            <MessageSquareIcon strokeWidth={2.5} size={22} color="#fff" />
          </button>
        </div>
        <div className="overflow-auto hideScroll">
          {questions.map((q, index) => (
            <DropdownFrequentQuestion {...q} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

const DropdownFrequentQuestion: FC<{ question: string; answer: string }> = ({ answer, question }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="flex flex-col w-full overflow-hidden shadow-md">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full items-center duration-75 p-4 xl:p-6 bg-white cursor-pointer z-20"
      >
        <span className="font-bold text-sm xl:text-base 2xl:text-lg">{question}</span>
        <div className="flex flex-col items-end w-10 ">
          <ChevronDown
            size={20}
            color="#333"
            style={{
              rotate: isOpen ? "180deg" : "0deg",
            }}
            className="duration-200"
          />
        </div>
      </div>
      {isOpen && (
        <div className="flex p-4 xl:p-6 bg-zinc-50 border-l border-r border-b border-zinc-100">
          <span className="text-xs xl:text-sm 2xl:text-base pb-2">{answer}</span>
        </div>
      )}
    </div>
  );
};

const questions = [
  {
    question: "O que é o Vila Pay Bank?",
    answer:
      "O Vila Pay Bank é uma Conta Digital criada por empreendedores e para empreendedores, projetada para reduzir burocracias, eliminar taxas abusivas e descomplicar a administração das finanças de quem empreende!",
  },
  {
    question: "A conta Vila Pay Bank é segura?",
    answer:
      "Somos uma instituição regulada pelo Banco Central no formado de Sociedade de Crédito Direto nessa modalidade, o seu dinheiro fica separado do patrimônio da Vila Pay Bank. Nós não movimentamos e emprestamos seu dinheiro, ele é realmente seu. Trata-se de configuração legal mais segura que existe!",
  },
  {
    question: "Quanto custa?",
    answer:
      "A Conta Vila Pay Bank tem baixa tarifa na manutenção. Você também paga pouco por transferências e emissão de boletos no plano Padrão. Cobramos também pela emissão de boletos que são emitidos por meio da integração de sistemas individuais e/ou parceiros da Vila Pay Bank, como por exemplo ERPs.",
  },
  {
    question: "Porque não existem taxas?",
    answer:
      "Optamos por não cobrar tarifas relacionadas a conta bancária. Os serviços que nos financiam são (e serão) outros, como cartão de crédito, integrações e funcionalidades avançadas dentro da conta. Além disso, não acreditamos que é justo cobrar tarifas abusivas pela manutenção de contas bancárias sem que nossos clientes desfrutem de um serviço especial. Cobrar pela emissão (ou compensação) de boletos pode inviabilizar o negócio de nossos clientes.",
  },
];

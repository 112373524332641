import styled from "styled-components"

import {ReactComponent as PixIconSVG} from '../../../assets/pix.svg'
import {ReactComponent as LockIconSVG} from '../../../assets/lock.svg'
import {ReactComponent as MailIconSVG} from '../../../assets/mail.svg'

export const PixIcon = styled(PixIconSVG)`
fill: var(--cian);
`

export const LockIcon = styled(LockIconSVG)`
fill: var(--cian);
`

export const MailIcon = styled(MailIconSVG)`
fill: var(--cian);
`

export const Container = styled.div`

height: calc(100vh - 80px);
background-color: var(--background);


`

export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;


`

export const QrInput = styled.div`
  //border: 1px solid blue;
  display: flex;
  flex-direction: column;
`;



export const QrContainer = styled.div`

gap: 5px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #C1C1C1;
height: 80.8vh;
`

export const Table = styled.div`

flex: 1;
padding: 32px;
//border: 1px solid black;
background: #b6b6b6;
height: 100vh;
`


export const TableWrapper = styled.div`
  border: 3px solid white;
  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px){
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px){
      width: 100%;
  }


> table {

    width: 100%;
    border-radius: 8px;
    border: none;
    border-collapse: collapse;

    tr {
        position: relative;

        &:first-child {

            &::after {
                content: '';
                height: 16px;
                width: 4px;
                background-color: var(--cian);
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
            }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
            text-align: start;
            font-family: var(--semiBold);
            font-size: 14px;
            color: var(--gray);
            padding: 16px;


            @media (min-width: 720px){
                font-size: 16px;
                padding: 21px 32px;
            }
        }

        > td {
            padding: 6px 16px;
            font-family: var(--regular);
            font-size: 10px;
            color: var(--gray-light-text);

            &:last-child {
                color: var(--red);
            }

                @media (min-width: 720px){
                    font-size: 14px;
                    padding: 12px 32px;

                }

            }
          }
        }
        display: flex;

`
export const LeftDiv = styled.div`
  width: 35%;
  //border: 1px solid blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;

`;


export const RightDiv = styled.div`
  flex: 1;
 // border: 1px solid red;
  gap: "5px";
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
//border-radius: 6px;
background: #C1C1C1;
`;


export const Text = styled.span`
  font-size: 16px;
  color: #505050;
  font-family: var(--regular);
  margin-right: 18px;
`;
import { FC, useState } from "react";
import { FormInput, FormSelectInput, GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { validateCPF, validateDate } from "src/utils/validations";

import "./styles.css";

const FormSchema = z.object({
  name: z.string({
    required_error: "Insira o nome do Sócio.",
  }),
  bornDate: z.string({
    required_error: "Insira a Data de nascimento.",
  }),
  cpf: z.string({
    required_error: "Insira o CPF.",
  }),
  motherName: z.string({
    required_error: "Insira o Nome da Mãe.",
  }),
});

type FormInputs = z.infer<typeof FormSchema>;

export const InsertPartnerData: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isPartnerPEP, setIsPartnerPEP] = useState<boolean | undefined>(undefined);

  const { control, handleSubmit, setError } = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
  });

  async function handlePartner(data: FormInputs) {
    if (!data.name?.includes(" ") || data.name?.length < 5) {
      setError("name", { message: "Preencha o nome completo." });
      return;
    }
    if (!data.motherName?.includes(" ") || data.motherName?.length < 5) {
      setError("motherName", { message: "Preencha o nome da mãe completo." });

      return;
    }

    if (!validateCPF(data.cpf.replace(/[/,.,-\s]/g, ""))) {
      setError("cpf", { message: "CPF Inválido." });
      return;
    }

    if (!validateDate(data.bornDate)) {
      setError("bornDate", { message: "Data de Nascimento inválida." });
      return;
    }

    setLoading(true);
    try {
      localStorage.setItem("@yntech:signup-data-partner", JSON.stringify(data));
      navigate("/create-account-wallet/insert-partner-account-address", { state: { accountType } });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame
        onPress={() => navigate("/create-account-wallet/insert-account-address", { state: { accountType } })}
      />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="700" height="83vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="670"
            height="79vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[15vh] ml-20">
          <div className="w-full flex items-start gap-10">
            <p className="text-white font-medium text-lg mr-28">
              Você está a poucos passos de uma <br />
              nova <strong>experiência financeira</strong>
            </p>

            <GradientButton
              title={accountType === "PF" ? "3/5" : "4/7"}
              tailwindStyles="bg-black text-white w-14"
              onClick={() => console.log("")}
            />
          </div>

          <p className="text-white text-base font-light mt-3 mb-4">
            Informe os dados de um sócio majoritário ou <br />
            administrador
          </p>
          <form onSubmit={handleSubmit(handlePartner)}>
            {fields.map((item: any, index: number) => {
              return (
                <div key={item.id}>
                  {item.breakPoint === false && (
                    <Controller
                      name={item.id as any}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormInput
                          placeholder={item.placeholder}
                          required
                          value={field.value}
                          onChangeText={(e) => field.onChange(e)}
                          mask={item.mask}
                          errorMessage={fieldState.error?.message}
                        />
                      )}
                    />
                  )}

                  {item.breakPoint && (
                    <div className="flex items-center gap-5 justify-between">
                      <Controller
                        name={item.id as any}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormInput
                            style={{
                              width: 230,
                            }}
                            onChangeText={(e) => field.onChange(e)}
                            placeholder={item.placeholder}
                            required
                            {...field}
                            mask={item.mask}
                            errorMessage={fieldState.error?.message}
                          />
                        )}
                      />
                      <Controller
                        name={fields[index + 1].id as any}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormInput
                            style={{
                              width: 230,
                            }}
                            placeholder={fields[index + 1].placeholder}
                            required
                            onChangeText={(e) => field.onChange(e)}
                            {...field}
                            mask={fields[index + 1].mask}
                            errorMessage={fieldState.error?.message}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              );
            })}

            <p className="text-white text-base font-extralight mt-2 mb-3">O sócio é PEP?</p>

            <div className="flex items-center gap-5">
              <button
                type="button"
                onClick={() => setIsPartnerPEP(true)}
                className="flex items-center justify-center gap-2 text-white font-extralight"
              >
                <div className="w-4 h-4 rounded-full border-[1px] border-white flex items-center justify-center">
                  {isPartnerPEP && <div className="rounded-full bg-white w-2 h-2"></div>}
                </div>
                Sim
              </button>
              <button
                type="button"
                onClick={() => setIsPartnerPEP(false)}
                className="flex items-center justify-center gap-2 text-white font-extralight"
              >
                <div className="w-4 h-4 rounded-full border-[1px] border-white flex items-center justify-center">
                  {!isPartnerPEP && <div className="rounded-full bg-white w-2 h-2"></div>}
                </div>
                Não
              </button>
            </div>

            <div className="flex w-full gap-5 items-center justify-between mt-7">
              <GradientButton
                isLoading={loading}
                title="Voltar"
                tailwindStyles="bg-[#171717] text-white w-[16rem] h-12"
                onClick={() => navigate("/create-account-wallet/insert-account-address", { state: { accountType } })}
              />
              <GradientButton
                isLoading={loading}
                title="Avançar"
                tailwindStyles="bg-[#274683] text-white w-[16rem] h-12"
                onClick={handleSubmit(handlePartner)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const fields = [
  {
    id: "name",
    placeholder: "Nome do sócio",
    breakPoint: false,
  },
  {
    id: "bornDate",
    placeholder: "Data de nascimento",
    mask: "99/99/9999",
    breakPoint: true,
  },
  {
    id: "cpf",
    placeholder: "CPF",
    breakPoint: undefined,
    mask: "999-999-999-99",
  },
  {
    id: "motherName",
    placeholder: "Nome da mãe",
    breakPoint: false,
  },
];

import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

import {
  ArrowPageLeftIcon,
  ArrowPageRightIcon,
  Container,
  Content,
  Table,
  TableWrapper,
  PaginationWrapper,
  PrintButtonIcon,
  ContentWrapper,
  Action,
  ButtonWrapper,
  Search,
  ButtonRecharger,
} from "./styles";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ExtractItem,
  extractDescription,
} from "../../utils/extractDescription";
import { Fragment, useEffect, useState } from "react";
import { PrinterIcon, XIcon } from "@heroicons/react/outline";
import { endOfMonth, startOfMonth, subHours } from "date-fns";

import ModalConfirmOperation from "../../components/ModalConfirmOperation";
import { Skeleton } from "../../components/skeleton";
import { service as api } from "../../services/ajio";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormatDaily } from "../../utils/dateFormat";
import { documentFormatted } from "../../utils/documentFormatted";
import { extractTransactionsPDF } from "../../utils/extract-transactions-pdf";
import pt from "date-fns/locale/pt";
import { registerLocale } from "react-datepicker";
import { trancactionBuildItems } from "src/utils/transactions";
import { useAuth } from "../../hooks/auth";
import { useClient } from "../../hooks/client";
import { useNotification } from "../../hooks/notification";
import ModalComponent from "./components/modal";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

registerLocale("pt", pt);

export interface Transaction {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  credit: boolean;
  data: any;
  GraphicAccount: boolean;
  createdAt: string;
  category: string;
  amount: string;
  type: string;
  transactionData: {
    clientNamePayer: string;
    clientName: string;
    keyTarget: string;
  };
}

export interface Detail {
  id: string;
  credit: boolean;
  amount: number;
  description: string;
  rateValue: number;
  consignorName: string;
  consignorDocument: string;
  consignorBankNumber: string;
  consignorBankBranch: string;
  consignorBankAccount: string;
  consignorBankAccountDigit: string;
  beneficiaryName: string;
  beneficiaryDocument: string;
  beneficiaryBankNumber: string;
  beneficiaryBankBranch: string;
  beneficiaryBankAccount: string;
  beneficiaryBankAccountDigit: string;
  status: string;
  date: string;
}

export interface ShowTransaction {
  category: string;
  transactionData: any;
  detail: any;
  transaction: any;
  createdAt: string;
  amount: string;
}

export const AddWalletFounds = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ExtractItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const users = JSON.parse(localStorage.getItem("@stricv2:user") as string);
  useEffect(() => {
    setLoading(true);

    api
      .get(`/transactions`, {
        params: {
          page: currentPage,
        },
      })
      .then(({ data }) => {
        const transactions = data.transactions.data.filter(
          (item: any) => item.type === "PIX_DYNAMIC"
        );

        setItems(transactions);
        setLoading(false);
      });
  }, [currentPage]);

  const [modalIsOpen, setIsOpen] = useState(false);

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  function handlePrevious() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function handleNext() {
    setCurrentPage(currentPage + 1);
  }

  const [openBalanceDialog, setOpenBalanceDialog] = useState(false);

  const handleOpenBalanceDialog = () => {
    setOpenBalanceDialog(true);
  };

  const handleCloseBalanceDialog = () => {
    setOpenBalanceDialog(false);
  };

  return (
    <>
      <ModalConfirmOperation
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
      />
      <Container>
        <Content>
          <ContentWrapper>
            <Action>
              <Search>
                <span>
                  Acompanhe por aqui seu histórico de recargas, realize seus
                  depósitos por aqui. Você também pode acessar o extrato deles
                  clicando em detalhes.
                </span>
              </Search>
              <ButtonWrapper>
                <ButtonRecharger onClick={handleOpenBalanceDialog}>
                  Recarregar Wallet
                </ButtonRecharger>
              </ButtonWrapper>
            </Action>

            <Table>
              <TableWrapper>
                {!loading && items.length <= 0 && (
                  <div className="p-6">
                    <div className="text-center">
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        Nenhum lançamento para o período selecionado
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Selecione outro período ou faça uma transferência e
                        comece a usar agora mesmo
                      </p>
                    </div>
                  </div>
                )}

                <Skeleton space={4} isActive={loading} repeat={5} />

                <table>
                  <tbody>
                    {!loading && items.length > 0 && (
                      <tr>
                        <th>Data</th>
                        <th>Tipo</th>
                        <th>Transação</th>
                        <th>Beneficiário</th>
                        <th style={{ paddingLeft: "40px" }}>Valor</th>
                        <th style={{ paddingLeft: "15px" }}>Imprimir</th>
                      </tr>
                    )}

                    {!loading &&
                      items.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>{dateFormatDaily(item.created_at)}</td>
                            <td>
                              {item.description === "Retirada"
                                ? "Estornado"
                                : item.direction === "in"
                                ? "Crédito"
                                : "Débito"}
                            </td>
                            <td>
                              {item.GraphicAccount &&
                              item.GraphicAccount?.name ? (
                                <>
                                  <span className="extract-graphic-account">
                                    Control Account - {item.GraphicAccount.name}
                                  </span>
                                </>
                              ) : (
                                <span className="extract-graphic-account">
                                  {item.user?.name}
                                </span>
                              )}
                              <br />
                              <span
                                style={{
                                  color:
                                    item.direction === "in" &&
                                    item.type !== "internal"
                                      ? "var(--green)"
                                      : "black",
                                }}
                              >
                                {item.type === "pix" && item.direction === "in"
                                  ? "Pix recebido de:"
                                  : item.type === "pix" &&
                                    item.direction === "out"
                                  ? "Pix enviado para:"
                                  : item.type === "internal" &&
                                    item.direction === "in"
                                  ? "Recebido via: "
                                  : item.type === "internal" &&
                                    item.direction === "out"
                                  ? "Retirado via:"
                                  : /* @ts-ignore */
                                  item?.response?.message ===
                                    "Prezado cliente, a partir do horário vigente as transferências podem ser processadas até o próximo dia útil. Se preferir você pode agendá-la ou utilizar o PIX via APP!"
                                  ? "Valor estornado fora do horário autorizado."
                                  : "Wallet Recarregada"}{" "}
                              </span>{" "}
                              <br />
                              {item.description}
                            </td>
                            {item.response === null &&
                            item.title === "Retirada" ? (
                              <>
                                <span style={{ color: "darkgray" }}>C-A</span>
                                <br />
                                {item.data.payer.name}
                                <span>
                                  <br />
                                  {item.description === "Retirada"
                                    ? "Saldo devolvido para a conta principal."
                                    : item.GraphicAccount}
                                </span>
                              </>
                            ) : (
                              <>
                                <br />
                                <span className="extract-graphic-account">
                                  {item.data?.payer?.name ||
                                    item?.response?.payer_payee?.bank_account?.name}
                                </span>
                              </>
                            )}

                            {/* <td>{item.data?.payer?.name} response.payer_payee?.bank_account.name</td> */}
                            <td>
                              <span
                                style={{
                                  color:
                                    item.direction === "in"
                                      ? "var(--green)"
                                      : "var(--red)",
                                  marginLeft: "8px",
                                }}
                              >
                                {item.direction === "in" ? "+" : "-"}
                                {currencyFormat(item.amount)}
                              </span>

                              <span
                                style={{
                                  marginLeft: "20px",
                                  display:
                                    item.direction === "in" ? "none" : "inline",
                                }}
                              ></span>
                            </td>

                            <td>
                              <PrintButtonIcon>
                                <PrinterIcon
                                  onClick={() => extractTransactionsPDF(item)}
                                  color="var(--boxText)"
                                  className="h-4 w-4 inline"
                                />
                              </PrintButtonIcon>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </TableWrapper>
            </Table>
          </ContentWrapper>
          <PaginationWrapper>
            <ArrowPageLeftIcon onClick={handlePrevious} />
            <span>Página {currentPage} </span>
            <ArrowPageRightIcon onClick={handleNext} />
          </PaginationWrapper>
        </Content>

        <div>
          {openBalanceDialog && (
            <ModalComponent
              openBalanceDialog={openBalanceDialog}
              handleCloseBalanceDialog={handleCloseBalanceDialog}
            />
          )}
        </div>
      </Container>
    </>
  );
};

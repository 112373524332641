import { AnimatePresence } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { documentFormatted } from "../../utils/documentFormatted";
import { Button } from "../button";
import { ClientInfoAccount } from "../clientInfoAccount";
import IconHeader from "../IconHeader";
import {
  BellIcon,
  // BellNotifications,
  BellWrapper,
  Box,
  BoxNotifications,
  BoxWrapper,
  ButtonBell,
  ClientInfo,
  CloseIcon,
  Container,
  DashboardInfo,
  HeaderWrapper,
  // OtherAccounts,
  SectionInfo,
  SectionTitle,
  SidebarButton,
  SidebarIcon,
  // BankMoney,
  // HomeIcon,
  // ReceiptIcon,
  ArrowPageRightIcon,
  ArrowUpRightIcon,
  // ArrowUpIcon,
  ButtonWrapper,
  OtherAccounts,
  BellNotifications,
  ArrowUpIcon,
} from "./styles";
import { IconSwitch } from "./SwitchIcon";
import Carousel from "../carouselModule";
import { api } from "src/services/api";
import { BellMessage } from "../bellMessage";
import { set } from "date-fns";
const audioMP3 = require("../../assets/cash-in.mp3");

type props = {
  handleSidebarFunction: () => void;
  sidebarIsActive: boolean;
};

export const Header: React.FC<props> = ({
  // children,
  handleSidebarFunction,
  sidebarIsActive,
}) => {
  const routes = useLocation();
  const { account, user } = useAuth();
  const navigate = useNavigate();

  const routesNames = useMemo(() => {
    return [
      {
        path: "/",
        name: "Início",
        iconName: "HomeIcon",
      },
      {
        path: "/receipt",
        name: "Extrato",
        iconName: "ReceiptIcon",
      },
      {
        path: "/extract",
        name: "Extrato",
        iconName: "ReceiptIcon",
      },
      {
        path: "/deposit",
        name: "Depositar",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/tedordoc",
        name: "Depositar",
        subname: "TED ou DOC",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoice",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoice/confirm",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoices-list",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/charge",
        name: "Gestão de cobrança",
        iconName: "ChargeIcon",
      },
      {
        path: "/adds-founds-wallet",
        name: "Wallet ",
        iconName: "ChargeIcon",
      },
      {
        path: "/passwordKeyControlAccountChange",
        name: "Senha ",
        iconName: "KeysIcon",
      },
      {
        path: "/gerenciar",
        name: "Gerenciar ",
        iconName: "AccountDeclarationIcon",
      },
      {
        path: "/myLimits",
        name:
          user.type === "GRAPHIC" || user.type === "WALLET"
            ? "Limites operacionais"
            : "",
        iconName: "SettingsIcon",
      },
      {
        path: "/limits/increasing-limits",
        name:
          user.type === "GRAPHIC" || user.type === "WALLET"
            ? "Mais Limite"
            : "",
        iconName: "ArrowUpRightIcon",
      },
      {
        path: "/limits/config-limits",
        name:
          user.type === "GRAPHIC" || user.type === "WALLET"
            ? "Gerenciar Limite"
            : "",
        iconName: "SettingsIcon",
      },
      {
        path: "/pix",
        name: user.type === "GRAPHIC" ? "Área Pix" : "Área Pix",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/list",
        name: "Área Pix",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/transfer",
        name: "Área pix",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/mykeys",
        name: "Chaves",
        // subname: "Minhas chaves",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/mykeys/new",
        name: "Nova chave",
        // subname: "Minhas chaves",
        iconName: "PixIconSVG",
      },
      {
        path: "/configkeypasswordeletronic",
        name: "Senha Eletrônica",
        // subname: "Minhas chaves",
        iconName: "KeysIcon",
      },
      {
        path: "/configcontrolaccountkeypasswordeletronic",
        name: "Senha Eletrônica",
        // subname: "Minhas chaves",
        iconName: "KeysIcon",
      },
      {
        path: "/paybills",
        name: "Pagar contas",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/invoice",
        name: "Pagar contas",
        subname: "Pagamento de boleto",

        iconName: "BillIcon",
      },
      {
        path: "/paybills/info-invoice",
        name: "Pagar contas",
        subname: "Pagamento de boleto",

        iconName: "BillIcon",
      },
      {
        path: "/paybills/ticket",
        name: "Pagar contas",
        subname: "Pagamento de boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/confirm-invoice",
        name: "Pagar contas",
        subname: "Pagamento de boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/subscribe",
        name: "Pagar contas",
        subname: "Cadastrar favorecido",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/schedule-payments",
        name: "Pagar contas",
        subname: "Pagamentos agendados",
        iconName: "BillIcon",
      },
      {
        path: "/extract",
        name: "Pagar contas",
        subname: "Pagamento de boleto",
        iconName: "ChargeIcon",
      },
      {
        path: "/transfer",
        name: "TRANSFERIR",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/confirm",
        name: "TED/DOC",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/list",
        name: "Entr. Contas",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/subscribe",
        name: "TED/DOC",
        subname: "",
        iconName: "BankMoney",
      },
      {
        path: "/billet",
        name: "Boletos",
        subname: "",
        iconName: "BankMoney",
      },
      {
        path: "/tax",
        name: "Tarifas",
        iconName: "TaxIcon",
      },
      {
        path: "/pix/qrcodeStatic",
        name: "Estático",
        iconName: "QrCodeIcon",
      },
      {
        path: "/pix/qrcodeDynamic",
        name: "Dinâmico",
        iconName: "QrCodeIcon",
      },
    ];
  }, []);

  const currentRouteInfo = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return routesNames.find((route) => {
      if (routes.pathname === route.path) {
        return route;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes.pathname]);

  const token = localStorage.getItem("@stricv2:token");
  const audioRef = useRef<HTMLAudioElement>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [quantity, setQuantity] = useState(0);

  const fetchNotifications = async () => {
    try {
      let url = `/notifications`;
      const { data } = await api.get(url, {
        headers: { Authorization: "Bearer " + token },
      });
      console.log("notifications", data.notifications);
      setQuantity(data.quantity);
      if(data.quantity > 0 ) {
      try {
            audioRef.current!.play()
      } catch (error) {
            console.log(error);
          }
      }
      setNotifications(data.notifications);
    } catch (error) {}
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const [BellBoxMessageIsActive, setBoxMessageIsActive] = useState(false);
  const [otherAccountsIsActive, setOtherAccountsIsActive] = useState(true);

  const HandleBoxMessageToggle = async () => {
    if (quantity > 0) {
      try {
        let url = `/notifications/read`;
        const { data } = await api.get(url, {
          headers: { Authorization: "Bearer " + token },
        });
        console.log("notifications", data.notifications);
        setQuantity(data.quantity);
        setNotifications(data.notifications);
      } catch (error) {}
    }
    setBoxMessageIsActive((state) => !state);
  };

  const HandleOtherAccountsToggle = () => {
    setOtherAccountsIsActive((state) => !state);
  };

  const shouldRenderContent = user.type !== "GRAPHIC" ? "none" : "block";

  return (
    <Container>
      <SidebarButton onClick={handleSidebarFunction}>
        {sidebarIsActive ? <CloseIcon /> : <SidebarIcon />}
      </SidebarButton>
      <HeaderWrapper>
        <SectionInfo>
          <IconHeader Icon={IconSwitch(currentRouteInfo?.iconName!)} />

          <SectionTitle>{currentRouteInfo?.name}</SectionTitle>

          {currentRouteInfo?.subname && (
            <div className="flex justify-center items-center">
              <ArrowPageRightIcon />
              <SectionTitle>{currentRouteInfo?.subname}</SectionTitle>
            </div>
          )}
        </SectionInfo>
        <DashboardInfo>
          {/*  {currentRouteInfo?.path === "/extract" && (
            <ButtonWrapper>
              <Button Icon={ArrowUpIcon} title="Exportar" />
            </ButtonWrapper>
          )}
          {currentRouteInfo?.path === "/pix/mykeys" && (
            <ButtonWrapper className="md:hidden">

              <Button title="Cadastrar nova chave" handleFunction={() => {
                navigate("/pix/mykeys/new")
              }} />
            </ButtonWrapper>
          )}

          {currentRouteInfo?.path === "/charge" && (
            <ButtonWrapper>
              <Button title="Nova cobrança" handleFunction={() => {
                navigate("/deposit/invoice")
              }} />
            </ButtonWrapper>
          )}

          {currentRouteInfo?.path === "/extract" && (
            <ButtonWrapper>
              <Button title="Exportar"/>
            </ButtonWrapper>
          )} */}

          <BellWrapper>
            <ButtonBell onClick={HandleBoxMessageToggle}>
              <BellIcon />
            </ButtonBell>
            <BellNotifications>
              <p>{quantity}</p>
              <audio ref={audioRef} src={audioMP3} />
            </BellNotifications>
            <AnimatePresence>
              {BellBoxMessageIsActive && (
                <BoxWrapper
                  initial={{ opacity: 0, y: "-10%" }}
                  animate={{ opacity: 1, y: "0%" }}
                  exit={{ opacity: 0, y: "-10%" }}
                  transition={{ duration: 0.2 }}
                >
                  <Box>
                    <span></span>
                    <BoxNotifications>
                      {notifications.length === 0 ? (
                        <BellMessage
                          notification={{
                            title: "Nenhuma mensagem",
                            message: "Nenhuma mensagem pendente",
                          }}
                        />
                      ) : (
                        notifications.map((notification, index) => (
                          <BellMessage
                            key={index}
                            notification={notification}
                          />
                        ))
                      )}
                    </BoxNotifications>
                  </Box>
                </BoxWrapper>
              )}
            </AnimatePresence>
          </BellWrapper>

          <Carousel />
        </DashboardInfo>
      </HeaderWrapper>
    </Container>
  );
};

import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Companies from "./companies";
import Individuals from "./individuals";
import CreateWalletIndividuals from "../createAccountWalletInitials/account_wallet_create";

const Form: React.FC = () => {
  const location = useLocation();

  const navigation = useNavigate();

  const [status, setStatus] = useState<string | null>(null);
  const [franchiseId, setFranchiseId] = useState<string>();
  const [accountType, setAccountType] = useState<string>();

  useEffect(() => {
    console.log("useEffect  >>> ", accountType, franchiseId);
  }, [accountType, franchiseId]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const accountTypeQuery = queryParameters.get("type");
    const franchiseIdQuery = queryParameters.get("service_code");
    const statusQuery = queryParameters.get("status");

    franchiseIdQuery && setFranchiseId(franchiseIdQuery);
    accountTypeQuery && setAccountType(accountTypeQuery);
    statusQuery && setStatus(statusQuery);
  }, [location.search]);

  useEffect(() => {
    if (
      !location.search ||
      location.search === null ||
      location.search === ""
    ) {
      navigation("/signin");
    }
  }, [location.search, navigation]);

  return (
    <>
      {accountType === "pf" && <Individuals status={status} />}
      {accountType === "pj" && <Companies status={status} />}
      {accountType === "wallet" && <CreateWalletIndividuals status={status} />}
    </>
  );
};

export default Form;

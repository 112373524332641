import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { StepHeader } from "../../../components/stepHeader";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../../../components/TextInput";
import { InputSelect } from "../../../components/select";
import { Button } from "../../../components/button";
import { SelectObject } from "../../../components/select";
import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Form,
  FormSection,
  WrapperHeader,
  FormContainer,
  InputSection,
  TextArea,
  InputGrouping,
  SectionTitle,
} from "./styles";
import { service, serviceError } from "../../../services/ajio";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { useNotification } from "src/hooks/notification";
import axios from "axios";
import jsPDF from "jspdf";
import { cleanup } from "@testing-library/react";
import { ChangeIcon } from "src/components/clientInfoAccount/styles";
import { MdChangeCircle, MdDoubleArrow } from "react-icons/md";
import { ArrowLeftRight } from "lucide-react";
import { documentFormatted } from "src/utils/documentFormatted";
import { dateFormat } from "src/utils/dateFormat";
import moment from "moment";

interface FormData {
  type: "BANKSLIP" | "BANKSLIP_PIX";
  dueDate: Date;
  amount: string;
  //limit_date: yup.NumberSchema<number, number>
  // instructions: string;
  // charge_type: string;
  payer: {
    name: string;
    document: string;
    address: {
      publicPlace: string;
      number: string;
      neighborhood: string;
      city: string;
      state: string;
      zipCode: string;
      complement: string;
    };
  };
  recurrence?: boolean;
  quantityInstallments?: number;
}

interface Allvalues {
  amount: number;
  name: string;
  barcode: string;
  document: string;
}

export const CreateBillet: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGeneratePDF = () => {
    let pdf = new jsPDF("p", "mm", "a4");

    // Definindo as margens do PDF (por padrão, as margens são 10mm)
    const marginLeft = 10;
    const marginTop = 10;
    const marginRight = 10;
    const marginBottom = 10;

    const usableWidth =
      pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
    const usableHeight =
      pdf.internal.pageSize.getHeight() - marginTop - marginBottom;

    pdf.setFont("helvetica", "bold"); // Definindo a fonte como negrito
    pdf.setFontSize(22);
    pdf.setTextColor(0, 0, 0); // Definindo a cor do texto como preto
    pdf.setFontSize(12);
    pdf.text("Valor:", 10, 60);
    pdf.setFontSize(12);
    pdf.text(`CÓDIGO DE AUTENTICAÇÃO`, 80, 180);
    pdf.save("saveBoleto.pdf");
  };

  const schema = yup
    .object({
      type: yup.string().default("BANKSLIP"),
      amount: yup.string().required("Valor é obrigatório"),
      limit_date: yup.number().default(() => 60),
      charge_type: yup.string().default("service"),
      dueDate: yup
        .date()
        .typeError("Deve ser uma data válida")
        .required("Vencimento é obrigatório"),
      payer: yup.object({
        name: yup.string().required("Nome é obrigatório"),
        document: yup.string().required("Documento é obrigatório"),
        address: yup.object({
          publicPlace: yup.string().required("Rua é obrigatório"),
          number: yup.string().required("Número do endereço é obrigatório"),
          neighborhood: yup.string().required("Bairro é obrigatório"),
          city: yup.string().required("Cidade é obrigatório"),
          state: yup.string().required("Estado é obrigatório"),
          zipCode: yup.string().required("CEP é obrigatório"),
          complement: yup.string(),
        }),
      }),
      // fine: yup.object({
      //   type: yup.string(),
      //   amount: yup.string(),
      //   date: yup.string(),
      // }),
      // interest: yup.object({
      //   type: yup.string(),
      //   amount: yup.number().when("interest.amount", {
      //     is: "-",
      //     then: yup.number().required("Rerori"),
      //   }),
      //   //when('interest.amount').is(['-']).required('Valor de juros é obrigatório')
      //   //.when("interest.amount", {is: '-', then: yup.string().required('Valor de juros é obrigatório')})
      //   date: yup.string(),
      // }),
      // discount: yup.object({
      //   type: yup.string(),
      //   amount: yup.string(),
      //   date: yup.string(),
      // }),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const operators: SelectObject[] = [
    { id: 0, name: "-", hidden: "" },
    { id: 1, name: "Percentual", hidden: "" },
    { id: 2, name: "Quantia fixa", hidden: "" },
  ];
  const [fine, setFine] = useState<SelectObject>(operators[0]);
  const [interest, setInterest] = useState<SelectObject>(operators[0]);
  const [discount, setDiscount] = useState<SelectObject>(operators[0]);

  const borderColor = "var(--gray-light-line)";
  const errorHintStyle = "absolute z-[2] pl-2 pt-[4.5rem] pointer-events-none";
  const { setOpenNotification } = useNotification();

  function onCloseModal() {
    setOpen(false);
    navigate("/billet");
  }

  const [street, setStreet] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [allvalues, setAllvalues] = useState<any>([]);
  const [state, setState] = useState("");
  const [cep, setCep] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<"BANKSLIP" | "BANKSLIP_PIX">("BANKSLIP_PIX");
  const [recurrent, setRecurrent] = useState<boolean>(false);
  const [numParcels, setNumParcels] = useState<number>(2);

  const handleChangeCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    const formattedValue = formatCPForCNPJ(value);

    event.target.value = formattedValue;
  };

  const formatCPForCNPJ = (value: string) => {
    const cleanValue = value.replace(/\D/g, "");
    if (cleanValue.length < 11) {
      return cleanValue;
    } else if (cleanValue.length == 11) {
      return cleanValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{0,2})/,
        "$1.$2.$3-$4"
      );
    } else if (cleanValue.length == 13 || cleanValue.length == 12) {
      return cleanValue;
    } else if (cleanValue.length <= 14) {
      return cleanValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/,
        "$1.$2.$3/$4-$5"
      );
    } else {
      return cleanValue
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, "$1.$2.$3/$4-$5")
        .slice(0, 18);
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    const formattedValue = formatMoney(value);

    event.target.value = formattedValue;
  };

  const formatMoney = (value: string) => {
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      const formattedValue = (numericValue * 0.01).toFixed(2).replace(".", ",");

      return formattedValue;
    }

    return value;
  };

  const handleStreetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStreet(event.target.value);
  };

  const handleNeighborhoodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNeighborhood(event.target.value);
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };

  async function handleCep(value: string) {
    setCep(value);

    if (value.length >= 9) {
      setLoading(true);
      setLoadingButton(true);

      try {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${value}/json/`
        );
        if (data) {
          const { logradouro, bairro, localidade, uf } = data;
          setState(data?.uf || "");
          setCity(data?.localidade || "");
          setStreet(data?.logradouro || "");
          setNeighborhood(data?.bairro || "");
        }
        setLoading(false);
        setLoadingButton(false);
      } catch (err) {
        setLoading(false);
        setLoadingButton(false);
      }
    }
  }

  const handleFormSubmit: SubmitHandler<FormData> = async (values, event) => {
    try {
      setLoadingButton(true);
      let titlefine = "";

      if (fine.id == "1") {
        titlefine = "until_date";
      } else {
        titlefine = "fixed_amount";
      }

      let titletypeInterest = "";

      if (interest.id == "1") {
        titletypeInterest = "until_date";
      } else {
        titletypeInterest = "fixed_amount";
      }

      let titletypeDiscount = "";

      if (discount.id == "1") {
        titletypeDiscount = "until_date";
      } else {
        titletypeDiscount = "fixed_amount";
      }

      const date = new Date(values.dueDate);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const convertedDate = `${year}-${month}-${day}`;

      const formattedPostalCode = values.payer.address.zipCode.replace(
        /-/g,
        ""
      );
      let json = {
        type,
        // charge_type: "service",
        dueDate: convertedDate,
        // limit_date: 60,
        amount: parseFloat(values.amount),
        // instructions: values.instructions,
        payer: {
          name: values.payer.name,
          document: values.payer.document.replace(/\D/g, ""),
          address: {
            publicPlace: values.payer.address.publicPlace,
            number: values.payer.address.number,
            neighborhood: values.payer.address.neighborhood,
            city: values.payer.address.city,
            state: values.payer.address.state,
            zipCode: formattedPostalCode,
            complement: values.payer.address.complement,
          },
        },
        recurrence: recurrent,
        quantityInstallments: recurrent ? numParcels : 1,
      };

      // if (fine.id !== 0) {
      //   json["fine"] = {
      //     type: titlefine,
      //     amount: parseInt(values.fine.amount),
      //     date: values.fine.date,
      //   };
      // }

      // if (discount.id !== 0) {
      //   json["discount"] = {
      //     type: titletypeDiscount,
      //     amount: parseInt(values.discount.amount),
      //     date: values.discount.date,
      //   };
      // }

      // if (discount.id !== 0) {
      //   json["discount"] = {
      //     type: titletypeInterest,
      //     amount: parseInt(values.interest.amount),
      //     date: values.interest.date,
      //   };
      // }

      const { data } = await service.post("bank-slips", json);
      setAllvalues(data);
      setOpen(true);
      setLoadingButton(false);
    } catch (err: any) {
      const erro = serviceError(err).friend;
      console.log("err:", err);
      setOpenNotification({
        type: "error",
        title: "Valor inválido",
        notification: erro,
      });
      setLoadingButton(false);
    }
  };

  console.log("allvalues", allvalues);

  console.log("ERRORS", errors);

  return (
    <Container className="">
      <Content>
        <ContentWrapper>
          <WrapperHeader>
            <StepHeader
              // handleGoBackFunction={handleGeneratePDF}
              handleGoBackFunction={handleGoBack}
              title="Gerar boleto"
            />
          </WrapperHeader>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormContainer>
              <FormSection>
                <InputGrouping cols="3">
                  <InputSection>
                    <label htmlFor="amount">Valor R$</label>
                    <TextInput
                      //type="number"
                      id="amount"
                      mask=""
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("amount")}
                      onChange={handleChangeMoney}
                      error={errors.amount as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="dueDate">Vencimento</label>
                    <TextInput
                      id="dueDate"
                      type="date"
                      mask=""
                      min={moment().add(3, "d").format("YYYY-MM-DD")}
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("dueDate")}
                      error={errors.dueDate as FieldError}
                    />
                  </InputSection>
                </InputGrouping>
                <div className="flex gap-4">
                  {/* Componente Tipo de Boleto */}
                  <div>
                    <label>Tipo de boleto</label>
                    <div
                      onClick={() => {
                        setType((old) =>
                          old == "BANKSLIP" ? "BANKSLIP_PIX" : "BANKSLIP"
                        );
                      }}
                      className={`flex cursor-pointer shadow-inner border select-none ${
                        type == "BANKSLIP"
                          ? "bg-blue-200 border-blue-300"
                          : "bg-green-200 border-green-300"
                      } self-start rounded-md h-8 px-2 items-center gap-x-2`}
                    >
                      <span
                        className={`${
                          type == "BANKSLIP"
                            ? "text-blue-600"
                            : "text-green-600"
                        }`}
                      >
                        {type == "BANKSLIP" ? "Boleto normal" : "Boleto PIX"}
                      </span>

                      <ArrowLeftRight
                        size={18}
                        strokeWidth={3}
                        color={type == "BANKSLIP" ? "#2563eb" : "#16a34a"}
                      />
                    </div>
                  </div>

                  {/* Componente Recorrente */}
                  <div>
                    <label>Recorrente?</label>
                    <div className="flex gap-2">
                      {/* Opção Sim */}
                      <div
                        onClick={() => {
                          setRecurrent(true);
                        }}
                        className={`flex cursor-pointer shadow-inner border select-none ${
                          recurrent
                            ? "bg-green-200 border-green-300"
                            : "bg-gray-200 border-gray-300"
                        } self-start rounded-md h-8 px-2 items-center gap-x-2`}
                      >
                        <span
                          className={`${
                            recurrent ? "text-green-600" : "text-gray-600"
                          }`}
                        >
                          Sim
                        </span>
                      </div>

                      {/* Opção Não */}
                      <div
                        onClick={() => {
                          setRecurrent(false);
                        }}
                        className={`flex cursor-pointer shadow-inner border select-none ${
                          !recurrent
                            ? "bg-red-200 border-red-300"
                            : "bg-gray-200 border-gray-300"
                        } self-start rounded-md h-8 px-2 items-center gap-x-2`}
                      >
                        <span
                          className={`${
                            !recurrent ? "text-red-600" : "text-gray-600"
                          }`}
                        >
                          Não
                        </span>
                      </div>
                    </div>
                  </div>
                  {recurrent && (
                    <div>
                      <label className="block">Número de Parcelas</label>
                      <select
                        value={numParcels}
                        onChange={(e) =>
                          setNumParcels(parseInt(e.target.value))
                        }
                        className="shadow-inner border rounded-md h-8 px-2"
                      >
                        {[...Array(31)].map((_, index) => (
                          <option key={index + 2} value={index + 2}>
                            {index + 2}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                {recurrent && (
                  <p className="text-xs text-gray-500 mt-1">
                    Parcelas serão geradas em todos os meses subsequentes a
                    partir da data de vencimento selecionada.
                  </p>
                )}
                {/* <TextArea
                  id="instructions"
                  borderColor={borderColor}
                  {...register("instructions")}
                /> */}
              </FormSection>

              <FormSection>
                <InputGrouping cols="3">
                  <InputSection>
                    <label htmlFor="payer.name">Nome</label>
                    <TextInput
                      id="payer.name"
                      mask=""
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.name")}
                      error={errors.payer?.name as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="payer.document">CPF/CNPJ</label>
                    <TextInput
                      id="payer.document"
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.document")}
                      onChange={handleChangeCPF}
                      //{...register("payer.document")}
                      error={errors.payer?.document as FieldError}
                    />
                  </InputSection>
                </InputGrouping>

                <label>Endereço</label>
                <InputGrouping cols="4">
                  <InputSection>
                    <label htmlFor="payer.address.zipCode">CEP</label>
                    <TextInput
                      id="payer.address.zipCode"
                      mask="99999-999"
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.address.zipCode")}
                      onChange={(e) => handleCep(e.target.value)}
                      error={errors.payer?.address?.zipCode as FieldError}
                    />
                  </InputSection>
                </InputGrouping>

                <InputGrouping cols="3">
                  <InputSection>
                    <label htmlFor="payer.address.publicPlace">Rua</label>
                    <TextInput
                      id="payer.address.street"
                      mask=""
                      value={street}
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.address.publicPlace")}
                      onChange={handleStreetChange}
                      error={errors.payer?.address?.publicPlace as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="payer.address.number">Número</label>
                    <TextInput
                      id="payer.address.number"
                      mask=""
                      type="number"
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.address.number")}
                      error={errors.payer?.address?.number as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="payer.address.complement">
                      Complemento
                    </label>
                    <TextInput
                      id="payer.address.complement"
                      mask=""
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.address.complement")}
                      error={errors.payer?.address?.complement as FieldError}
                    />
                  </InputSection>
                </InputGrouping>

                <InputGrouping cols="3">
                  <InputSection>
                    <label htmlFor="payer.address.neighborhood">Bairro</label>
                    <TextInput
                      id="payer.address.neighborhood"
                      mask=""
                      value={neighborhood}
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.address.neighborhood")}
                      onChange={handleNeighborhoodChange}
                      error={errors.payer?.address?.neighborhood as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="payer.address.state">Estado</label>
                    <TextInput
                      id="payer.address.state"
                      mask=""
                      maxLength={2}
                      value={state}
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.address.state")}
                      onChange={handleStateChange}
                      error={errors.payer?.address?.state as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="payer.address.city">Cidade</label>
                    <TextInput
                      id="payer.address.city"
                      mask=""
                      value={city}
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("payer.address.city")}
                      onChange={handleCityChange}
                      error={errors.payer?.address?.city as FieldError}
                    />
                  </InputSection>
                </InputGrouping>
              </FormSection>

              {/* <FormSection>
                <SectionTitle>Multas</SectionTitle>
                <InputGrouping cols="3">
                  <InputSection>
                    <InputSelect
                      label="Tipo"
                      selected={fine}
                      options={operators}
                      {...register("fine.type")}
                      onChange={setFine}
                    />
                  </InputSection>
                  <InputSection className="text-">
                    <label htmlFor="fine.amount">Valor</label>
                    <TextInput
                      type="number"
                      id="fine.amount"
                      mask=""
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("fine.amount")}
                      disabled={fine.id === 0}
                      error={errors.fine?.amount as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="fine.date">Data</label>
                    <TextInput
                      id="fine.date"
                      type="date"
                      mask=""
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("fine.date")}
                      error={errors.fine?.type as FieldError}
                    />
                  </InputSection>
                </InputGrouping>

                <SectionTitle>Juros</SectionTitle>
                <InputGrouping cols="3">
                  <InputSection>
                    <InputSelect
                      label="Tipo"
                      selected={interest}
                      options={operators}
                      {...register("interest.type")}
                      onChange={setInterest}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="interest.amount">Valor</label>
                    <TextInput
                      type="number"
                      id="interest.amount"
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("interest.amount")}
                      disabled={interest.id === 0}
                      error={errors.interest?.amount as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="interest.date">Data</label>
                    <TextInput
                      id="interest.date"
                      type="date"
                      mask=""
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("interest.date")}
                      error={errors.interest?.type as FieldError}
                    />
                  </InputSection>
                </InputGrouping>

                <SectionTitle>Desconto</SectionTitle>
                <InputGrouping cols="3">
                  <InputSection>
                    <label hidden htmlFor="discount.type"></label>
                    <InputSelect
                      label="Tipo"
                      selected={discount}
                      options={operators}
                      {...register("discount.type")}
                      onChange={setDiscount}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="discount.amount">Valor</label>
                    <TextInput
                      id="discount.amount"
                      mask=""
                      type="number"
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("discount.amount")}
                      disabled={discount.id === 0}
                      error={errors.discount?.amount as FieldError}
                    />
                  </InputSection>
                  <InputSection>
                    <label htmlFor="discount.date">Data</label>
                    <TextInput
                      id="discount.date"
                      type="date"
                      mask=""
                      borderColor={borderColor}
                      errortextstyle={errorHintStyle}
                      {...register("discount.date")}
                      error={errors.discount?.date as FieldError}
                    />
                  </InputSection>
                </InputGrouping>
              </FormSection> */}
            </FormContainer>

            <ButtonWrapper>
              <Button
                type="submit"
                title="Gerar boleto"
                isLoading={loadingButton}
              />
            </ButtonWrapper>
          </Form>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                      <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Boleto Gerado
                            <br />
                            {allvalues.amount}
                          </Dialog.Title>
                          <div className="mt-2 text-left">
                            <p className="text-sm text-gray-500">
                              <h2 className="mb-5 mt-3 text-lg">
                                Dados do favorecido
                              </h2>
                              <div>
                                <h2>Nome</h2>
                                <h3>{allvalues.transation?.description}</h3>
                              </div>

                              <div className="mt-3">
                                <h2>Documento</h2>
                                <h3>
                                  {documentFormatted(
                                    allvalues?.data?.payer?.document
                                  )}
                                </h3>
                              </div>

                              <div className="mt-3">
                                <h2>Data de vencimento</h2>
                                <h3>{dateFormat(allvalues?.data?.due_date)}</h3>
                              </div>
                              <div className="mt-3">
                                <h2>ID da transação</h2>
                                <h3>{allvalues.transation?.id}</h3>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                          onClick={() => onCloseModal()}
                        >
                          Download do Boleto
                        </button>

                        <button
                          type="button"
                          className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                          onClick={handleGeneratePDF}
                        >
                          Imprimir boleto
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

import React, { createContext, useContext, useState } from "react";

interface AprovacoesContextData {
  approveTransactions: (transactionIds: string[]) => Promise<void>;
}

const AprovacoesContext = createContext<AprovacoesContextData>(
  {} as AprovacoesContextData
);

export const useAprovacoesContext = () => useContext(AprovacoesContext);

export const AprovacoesProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  async function approveTransactions(transactionIds: string[]) {
    // Coloque aqui a lógica para aprovar as transações
    // Por exemplo, a chamada à função "handleApproveAll" que você já tem no componente "Aprovacoes"
  }

  return (
    <AprovacoesContext.Provider value={{ approveTransactions }}>
      {children}
    </AprovacoesContext.Provider>
  );
};

import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import FormSelectButton from "../../components/FormSelectButton";
import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Form,
  FormTitle,
  FormWrapper,
  SelectButtonWrapper,
  WrapperHeader,
} from "./styles";

export const PayBillsSubscribe: React.FC = () => {
  const [buttonSelected, setButtonSelected] = useState("personal");
  const [selectedBank, setSelectedBank] = useState("Banco");

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const handleAccountTypeSelect = (option: string) => {
    setSelectedAccountType(option);
  };

  const handleSelectedAccount = (type: string) => {
    setButtonSelected(type);
  };

  const [selectedAccountType, setSelectedAccountType] =
    useState("Tipo de conta");

  const handleBankSelect = (option: string) => {
    setSelectedBank(option);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate("/transfer/confirm");
  };

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
            <StepHeader
              handleGoBackFunction={handleGoBack}
              title="Selecione uma das opções"
            />
          </WrapperHeader>
          <Form onSubmit={handleFormSubmit}>
            <SelectButtonWrapper>
              <FormSelectButton
                isSelected={buttonSelected === "personal"}
                onClick={() => handleSelectedAccount("personal")}
                buttonName={"Transferir para Pessoa Física"}
              />
              <FormSelectButton
                isSelected={buttonSelected === "businness"}
                onClick={() => handleSelectedAccount("businness")}
                buttonName={"Transferir para Pessoa Jurídica"}
              />
            </SelectButtonWrapper>
            <SelectButtonWrapper>
              <FormSelectButton
                isSelected={buttonSelected === "stric"}
                onClick={() => handleSelectedAccount("stric")}
                buttonName={"Transferir para conta Stric"}
              />
              <FormSelectButton
                isSelected={buttonSelected === "pix"}
                onClick={() => handleSelectedAccount("pix")}
                buttonName={"Pix"}
              />
            </SelectButtonWrapper>

            <FormTitle>Complete dados</FormTitle>
            <FormWrapper>
              <SelectInput
                optionSelected={selectedBank}
                handleFunction={handleBankSelect}
                options={["Banco Inter S.A", "Itaú", "Santader", "Bradesco"]}
              />
              <TextInput placeholder="CPF do Titular" />
              <TextInput placeholder="Nome da Pessoa" />
              <TextInput placeholder="Agência" />
              <TextInput placeholder="Conta com dígito (só números)" />
              <TextInput placeholder="Conta com dígito (só números)" />
              <SelectInput
                optionSelected={selectedAccountType}
                handleFunction={handleAccountTypeSelect}
                options={["Conta Corrente", "Conta Poupança"]}
              />
            </FormWrapper>
            <ButtonWrapper>
              <Button
                handleFunction={handleNextScreen}
                title="Cadastrar Favorecido"
              />
            </ButtonWrapper>
          </Form>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

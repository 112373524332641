import styled from "styled-components";

interface props {
  borderRadius: string;
  borderColor: string;
  height: string;
}

export const Container = styled.div<props>`
  height: ${(props) => props.height};
  width: 100%;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: ${(props) => props.borderRadius};

  display: flex;
  align-items: center;
  overflow: hidden;
  > input {
    outline: 0;
    background: none;
    border-style: none;
    width: 100%;
    padding: 16px 14px;
    color: #000;

    background-color: white;

    &::placeholder {
      font-size: 14px;
      font-family: var(--semiBold);
      color: #aaa;
    }
  }

  & + div {
    /* margin-top: 16px; */
  }
`;

import { DuplicateIcon } from "@heroicons/react/solid";
import React, { FormEvent, Fragment, useRef, useEffect, useState } from "react";
import { Skeleton } from "../../components/skeleton";
import { api } from "../../services/api";
import { dateFormat } from "../../utils/dateFormat";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import QRCode from "react-qr-code";
import {
  ActionPixTable,
  // KeyCpnjIcon,
  // KeyPixTable,
  // ShareIcon,
  TrashIcon,
} from "../extract/styles";
import {
  Container,
  Content,
  QrContainer,
  QrInput,
  Table,
  TableWrapper,
  LeftDiv,
  RightDiv,
  DivDate,
} from "./styles";
import { useNotification } from "../../hooks/notification";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";
import { service } from "../../services/ajio";
import InputCustom, {
  InputInsertValueQrCodeDynamic,
} from "./custom-input-dynamic";
import axios from "axios"; // Importe o axios se ainda não estiver importado
import { useAuth } from "../../hooks/auth";
import PixTextArea from "./pix-text-area-dynamic";
import InputDateQrCodeDynamic from "./data-picker-expiration-qrcode-dynamic";
import InputDateExpirationQrCodeDynamic from "./data-picker-expiration-qrcode-dynamic";

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export const QrCodeDynamic = () => {
  const token = localStorage.getItem("@stricv2:token");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  //const { setOpenNotification } = useNotification();
  const [key, setKey] = useState<KeysProps>({} as KeysProps);
  const [keys, setKeys] = useState<KeysProps[]>([]);
  const [amount, setAmount] = useState<number>(0.0);
  const [qrImage, setQrImage] = useState<string>("");
  const [isNamePayer, setNamePayer] = useState<string>("");
  const [isDocumentPayer, setEmvDocumentPayer] = useState<string>("");
  const [isDateExpiration, setDateExpiration] = useState<string>("");
  const [isQuestion, setQuestion] = useState<string>("");
  const [isLimitDays, setLimitDays] = useState<string>("");
  const [isTitle, setTitle] = useState<string>("");
  const [isContent, setContent] = useState<string>("");

  const [isEmvData, setEmvData] = useState<string>("");
  const [responseId, setResponseId] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [maskedValue, setMaskedValue] = useState("");
  const [value, setValue] = useState(0);
  const [keyOptions, setKeyOptions] = useState<string[]>([]);
  const { signIn, account, user } = useAuth();

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  // Definindo a função identificarTipoString
  function identificarTipoString(str: string): string {
    const regexPix = /^[a-fA-F0-9]{8}-(?:[a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$/;
    const regexCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$|^\d{14}$/;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regexPix.test(str)) {
      return "Chave Aleatória de Pix";
    } else if (regexCNPJ.test(str)) {
      return "CNPJ";
    } else if (regexEmail.test(str)) {
      return "E-mail";
    } else {
      return "Formato não reconhecido";
    }
  }

  // Chamar a função e exibir o tipo de string identificado
  const tipoStringIdentificado = identificarTipoString(inputValue);
  console.log(tipoStringIdentificado);

  const handleAmountChange = (newValue: number) => {
    if (!isNaN(newValue)) {
      setAmount(newValue);
    }
  };

  const handleDownloadQRCode = () => {
    downloadImage(qrImage, { id: linkValue });
  };

  async function getKeys() {
    service
      .get("/pix/keys")
      .then(({ data }) => {
        setKeys(data.keys);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getKeys();
  }, []);

  useEffect(() => {
    const options = keys.map((key) => key.key);
    setKeyOptions(options);
  }, [keys]);

  const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmvDocumentPayer(e.target.value);
  };
  //const handleDateExpirationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //  setDateExpiration(e.target.value);
  //}
  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
  };
  //const handleLimitDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //  setLimitDays(e.target.value);
  // }
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  const handlePayerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNamePayer(e.target.value);
  };

  const handleDateExpirationChange = (date: string) => {
    setDateExpiration(date);
    console.log("Selected Date: ", date);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.value);
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const userId = user.id;
  console.log(userId, "eu sou o id:");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleGenerateQRCode = async () => {
    try {
      const payload = {
        userId: userId,
        type: "PIX_DYNAMIC",
        //key: inputValue,
        // id_tx: responseId,
        payer: {
          name: isNamePayer,
          document: isDocumentPayer,
          // question: linkValue,
        },
        expiresIn: isDateExpiration,
        amount: value, // Defina o valor apropriado aqui
        additionalInformation: [
          {
            key: inputValue,
            value: isContent,
          },
        ],
        // detail: {
        //   title: isTitle,
        //  content: isContent,
        // },
        // allow_change: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}pix/generate/dynamic`,
        payload,
        config
      );
      console.log("oi sou  o log", response.data); // Imprime a resposta do backend no console

      const imageData = response.data.qrCodeImageBase64; // Assumindo que a propriedade 'image' contém os dados da imagem em base64
      setQrImage(imageData); // Atualiza o estado da imagem QR Code

      const emvData = response.data.qrCodePayload; // Atribua o valor de response.data.evm a emvData se response.data estiver definido
      setEmvData(emvData);

      // Certifique-se de que emvData está recebendo o valor esperado
      const idTx = response.data.correlationId; // Obtém o ID da resposta do POST
      //setResponseId(idTx); // Atualiza o estado com o ID retornado

      const createdDate = response.data.createdDate;
      console.log("ola EMV:", emvData);
      console.log("OLA IDTX:", idTx);
      console.log("OLA CREATE:", createdDate);
      /// const imageData2 = response.data.image; // Assumindo que a propriedade 'image' contém os dados da imagem em base64
      // const responseId = payload.description; // Substitua por uma variável apropriada do seu contexto, se disponível
      // downloadImage(imageData, { id: responseId });

      // Aguarde 2 segundos antes de persistir os dados
      // persistirDados(responseId, payload); // Chame sua função de persistência aqui com o ID retornado da API e o payload completo

      // Lógica adicional aqui, se necessário
      // A persistência deve ocorrer somente após a obtenção de idTx
    } catch (error) {
      console.error(
        "Ocorreu um erro ao enviar os dados para o backend:",
        error
      );
    }
  };

  // Função para persistir os dados
  {
    /*onst persistirDados = async (idTx: any, payload: any) => {
  try {
    const payloadParaPersistencia = {
       idTx, // Use o ID retornado da API
      ...payload, // Outras propriedades do payload de persistência
    };

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/pix/persisting/dynamic`, payloadParaPersistencia, config);
    console.log("Dados persistidos com sucesso:", response.data);
  } catch (error) {
    console.error("Ocorreu um erro ao persistir os dados:", error);
  }
};*/
  }

  const downloadImage = (base64Image: string, data: { id: string }) => {
    const byteCharacters = atob(base64Image);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `qrCode-${data.id}.png`;
    link.click();
  };

  function parseType(type: string): string {
    if (type === "evp") {
      return "Aleatória";
    }

    if (type === "email") {
      return "E-mail";
    }

    if (type === "nationalRegistration") {
      return "CPF/CNPJ";
    }

    if (type === "celular") {
      return "Celular";
    }

    return type;
  }

  function parseStatus(status: string): string {
    if (status === "WAITING") {
      return "Aguardando";
    }

    if (status === "CREATED") {
      return "Aguardando";
    }

    if (status === "PROCESSED") {
      return "Em processamento";
    }

    if (status === "REGISTERED") {
      return "Registrada";
    }

    if (status === "PRE-CANCELED") {
      return "Em cancelamento";
    }

    if (status === "CANCELED") {
      return "Cancelada";
    }

    return status;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const inputWidth = `${Math.max(100, inputValue.length * 8)}px`; // Adjust the multiplier as needed
  const width = inputValue === "" ? "300px" : inputWidth;

  return (
    <Container>
      <Content>
        <Table>
          <TableWrapper>
            {/* <div className=" flex justify-end">
            <div className="mb-5">
            <Button title="Cadastrar nova chave" handleFunction={() => {
                //navigate("/pix/mykeys/new")
              }} />
            </div>
            </div>*/}
            <LeftDiv>
              <DivDate>
                <InputDateExpirationQrCodeDynamic
                  value={isDateExpiration}
                  onChange={handleDateExpirationChange}
                />
              </DivDate>

              <select
                placeholder="Selecionar  Chave"
                onChange={handleSelectChange}
                value={inputValue}
                style={{
                  border: "1px solid #b6b6b6",
                  marginTop: "12px",
                  outline: "none",
                  padding: "8px",
                  borderRadius: "8px",
                  width: width,
                  height: "38px",
                  paddingBottom: "2px",
                  textAlign: "center",
                  color: "black",
                }}
              >
                <option value="" disabled selected>
                  Selecionar Chave:
                </option>
                {keyOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <InputInsertValueQrCodeDynamic
                onChange={(event, value, masked) =>
                  handleChange(event, value, masked)
                }
              />

              {/*//--------------------------------------------//*/}
              <input
                onChange={handlePayerNameChange}
                type="text"
                value={isNamePayer}
                placeholder="Nome do Pagador:"
                style={{
                  // opacity: isLocked ? 0.5 : 1,
                  //backgroundColor: 'white',
                  border: "1px solid #b6b6b6",
                  //  backgroundColor: isLocked ? 'lightgrey' : 'white',
                  // border: isLocked ? '2px solid black' : '2px solid black',
                  margin: "2px 0",
                  outline: "none",
                  padding: "8px",
                  borderRadius: "8px",
                  //  width: isLocked ? inputWidth :inputWidth,
                  width: width,
                  height: "38px",
                  // width: inputWidth,
                  paddingBottom: "2px",
                  textAlign: "center",
                  color: "black",
                }}
              />

              <input
                onChange={handleDocumentChange}
                type="text"
                value={isDocumentPayer}
                placeholder="Documento do Pagador:"
                style={{
                  // opacity: isLocked ? 0.5 : 1,
                  //backgroundColor: 'white',
                  border: "1px solid #b6b6b6",
                  //  backgroundColor: isLocked ? 'lightgrey' : 'white',
                  // border: isLocked ? '2px solid black' : '2px solid black',
                  margin: "2px 0",
                  outline: "none",
                  padding: "8px",
                  borderRadius: "8px",
                  //  width: isLocked ? inputWidth :inputWidth,
                  width: width,
                  height: "38px",
                  // width: inputWidth,
                  paddingBottom: "2px",
                  textAlign: "center",
                  color: "black",
                }}
              />
              <input
                onChange={handleLinkChange}
                type="text"
                value={linkValue}
                maxLength={25} // Definindo o limite máximo de caracteres
                placeholder="Descrição:"
                style={{
                  // opacity: isLocked ? 0.5 : 1,
                  //backgroundColor: 'white',
                  border: "1px solid #b6b6b6",
                  //  backgroundColor: isLocked ? 'lightgrey' : 'white',
                  // border: isLocked ? '2px solid black' : '2px solid black',
                  margin: "2px 0",
                  outline: "none",
                  padding: "8px",
                  borderRadius: "8px",
                  //  width: isLocked ? inputWidth :inputWidth,
                  width: width,
                  height: "38px",
                  // width: inputWidth,
                  paddingBottom: "2px",
                  textAlign: "center",
                  color: "black",
                }}
              />

              <input
                onChange={handleTitleChange}
                type="text"
                value={isTitle}
                maxLength={25} // Definindo o limite máximo de caracteres
                placeholder="Título:"
                style={{
                  // opacity: isLocked ? 0.5 : 1,
                  //backgroundColor: 'white',
                  border: "1px solid #b6b6b6",
                  //  backgroundColor: isLocked ? 'lightgrey' : 'white',
                  // border: isLocked ? '2px solid black' : '2px solid black',
                  margin: "2px 0",
                  outline: "none",
                  padding: "8px",
                  borderRadius: "8px",
                  //  width: isLocked ? inputWidth :inputWidth,
                  width: width,
                  height: "38px",
                  // width: inputWidth,
                  paddingBottom: "2px",
                  textAlign: "center",
                  color: "black",
                }}
              />
              <input
                onChange={handleContentChange}
                type="text"
                value={isContent}
                maxLength={25} // Definindo o limite máximo de caracteres
                placeholder="Mensagem:"
                style={{
                  // opacity: isLocked ? 0.5 : 1,
                  //backgroundColor: 'white',
                  border: "1px solid #b6b6b6",
                  //  backgroundColor: isLocked ? 'lightgrey' : 'white',
                  // border: isLocked ? '2px solid black' : '2px solid black',
                  margin: "2px 0",
                  outline: "none",
                  padding: "8px",
                  borderRadius: "8px",
                  //  width: isLocked ? inputWidth :inputWidth,
                  width: width,
                  height: "38px",
                  // width: inputWidth,
                  paddingBottom: "2px",
                  textAlign: "center",
                  color: "black",
                }}
              />

              {/*//--------------------------------------------//*/}

              <div className=" flex justify-end" style={{ gap: "5px" }}>
                <div className="mb-5">
                  <Button
                    title="Gerar QrCode"
                    handleFunction={handleGenerateQRCode}
                  />
                </div>
                <div className="mb-5">
                  <Button
                    title="Baixar QrCode"
                    handleFunction={handleDownloadQRCode}
                  />
                </div>
              </div>
              <PixTextArea pixString={isEmvData} />
            </LeftDiv>

            <RightDiv>
              <QrContainer>
                {qrImage ? (
                  <img
                    style={{
                      border: "3px solid #00cbda",
                      borderRadius: "6px",
                      padding: "2px",
                    }}
                    src={`${qrImage}`}
                    alt="QR Code"
                  />
                ) : (
                  <div>Aguardando QR Code...</div>
                )}
              </QrContainer>
            </RightDiv>
          </TableWrapper>
        </Table>
      </Content>
    </Container>
  );
};

import { FC, useState } from "react";
import { FormInput, FormSelectInput, GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { serviceError } from "../../services/ajio";

import axios from "axios";

import "./styles.css";

const FormSchema = z.object({
  postalCode: z.string({
    required_error: "Insira o seu CEP.",
  }),
  city: z.string({
    required_error: "Insira sua Cidade.",
  }),
  state: z.string({
    required_error: "Insira o seu Estado.",
  }),
  neighborhood: z.string({
    required_error: "Insira seu Bairro.",
  }),
  address: z.string({
    required_error: "Insira seu Endereço.",
  }),
  number: z.string({
    required_error: "Insira o Número.",
  }),
  complement: z.string().optional(),
  type: z.string({
    required_error: "Insira o tipo de Endereço",
  }),
});

type FormInputs = z.infer<typeof FormSchema>;

export const InsertAccountAddress: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setError, setValue } = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
  });

  const navigate = useNavigate();

  async function handleAddress(data: FormInputs) {
    if (data?.state?.length !== 2) {
      setError("state", {
        message: "Estado Inválido.",
      });
      return;
    }

    if (!data.type || (data.type != "Residencial" && data.type != "Comercial")) {
      setError("type", { message: "Escolha um tipo de endereço" });
      return;
    }

    setLoading(true);
    try {
      localStorage.setItem("@yntech:signup-data-03", JSON.stringify(data));
      setLoading(false);
      if (accountType === "PF") {
        navigate("/create-account-wallet/create-password", { state: { accountType } });
      } else {
        navigate("/create-account-wallet/insert-partner-data", { state: { accountType } });
      }
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
    }
  }

  const handleSetAddress = async (cep: string) => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      if (response.status === 200) {
        setValue("city", response.data.localidade);
        setValue("address", response.data.logradouro);
        setValue("neighborhood", response.data.bairro);
        setValue("state", response.data.uf);
      }
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/choose-plan", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="700" height="86vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="670"
            height="83vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[12vh] ml-20">
          <div className="w-full flex items-start gap-10">
            <p className="text-white font-medium text-lg mr-28">
              Você está a poucos passos de uma <br />
              nova <strong>experiência financeira</strong>
            </p>

            <GradientButton
              title={accountType === "PF" ? "3/5" : "3/7"}
              tailwindStyles="bg-black text-white w-14"
              onClick={() => console.log("")}
            />
          </div>

          <p className="text-white text-base font-light mt-3 mb-4">
            Todos os dados a seguir devem ser <br />
            obrigatoriamente do titular.
          </p>
          <form onSubmit={handleSubmit(handleAddress)}>
            {fields.map((item: any, index: number) => {
              return (
                <div key={item.id}>
                  {item.isDropdown && (
                    <Controller
                      name={item.id as any}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormSelectInput
                          placeholder={field.value ? field.value : item.placeholder}
                          options={item!.options!}
                          {...field}
                          onChanged={(e) => field.onChange(e)}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  )}

                  {!item.isDropdown && item.breakPoint === false && (
                    <Controller
                      name={item.id as any}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormInput
                          placeholder={item.placeholder}
                          required
                          onBlur={(e) => {
                            handleSetAddress(e.target.value);
                          }}
                          value={field.value}
                          onChangeText={(e) => field.onChange(e)}
                          mask={item.mask}
                          errorMessage={fieldState.error?.message}
                        />
                      )}
                    />
                  )}

                  {item.breakPoint && (
                    <div className="flex items-center gap-5 justify-between">
                      <Controller
                        name={item.id as any}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormInput
                            style={{
                              width: 230,
                            }}
                            onChangeText={(e) => field.onChange(e)}
                            placeholder={item.placeholder}
                            required
                            {...field}
                            mask={item.mask}
                            errorMessage={fieldState.error?.message}
                          />
                        )}
                      />
                      <Controller
                        name={fields[index + 1].id as any}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormInput
                            style={{
                              width: 230,
                            }}
                            placeholder={fields[index + 1].placeholder}
                            required
                            onChangeText={(e) => field.onChange(e)}
                            {...field}
                            mask={fields[index + 1].mask}
                            errorMessage={fieldState.error?.message}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              );
            })}

            <div className="flex w-full gap-5 items-center justify-between mt-7">
              <GradientButton
                isLoading={loading}
                title="Voltar"
                tailwindStyles="bg-[#171717] text-white w-[16rem] h-12"
                onClick={() => navigate("/create-account-wallet/choose-plan")}
              />
              <GradientButton
                isLoading={loading}
                title="Avançar"
                tailwindStyles="bg-[#274683] text-white w-[16rem] h-12"
                onClick={handleSubmit(handleAddress)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const fields = [
  {
    id: "postalCode",
    placeholder: "CEP",
    breakPoint: false,
    mask: "99999-999",
  },
  {
    id: "city",
    placeholder: "Cidade",
    breakPoint: false,
  },
  {
    id: "state",
    placeholder: "Estado",
    breakPoint: true,
  },
  {
    id: "neighborhood",
    placeholder: "Bairro",
    breakPoint: undefined,
  },
  {
    id: "address",
    placeholder: "Endereço (Rua)",
    breakPoint: false,
  },
  {
    id: "number",
    placeholder: "Número",
    breakPoint: true,
  },
  {
    id: "complement",
    placeholder: "Complemento",
    breakPoint: undefined,
  },
  {
    id: "type",
    placeholder: "Tipo de endereço",
    isDropdown: true,
    options: ["Residencial", "Comercial"],
    breakPoint: false,
  },
];

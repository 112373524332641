import { Account } from "src/hooks/auth";
import { Transaction } from "src/pages/receipt";

interface DataItem {
  date: string;
  amount: string;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export function transactionsToChartData(
  data: Transaction[]
): { value: number; date: string }[] {
  const groupedData: { [key: string]: number } = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Abr: 0,
    Mai: 0,
    Jun: 0,
    Jul: 0,
    Ago: 0,
    Set: 0,
    Out: 0,
    Nov: 0,
    Dez: 0,
  };

  data.forEach((item) => {
    const date = months[new Date(item.created_at).getMonth()]; //0 a 11

    if (!groupedData[date]) {
      groupedData[date] = 0;
    }

    groupedData[date] += item.amount;
  });

  const resultArray = Object.keys(groupedData).map((date) => ({
    value: groupedData[date],
    date,
  }));

  return resultArray;
}

export function transactionsToChartDataCount(
  data: Transaction[]
): { value: number; date: string }[] {
  const groupedData: { [key: string]: number } = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Abr: 0,
    Mai: 0,
    Jun: 0,
    Jul: 0,
    Ago: 0,
    Set: 0,
    Out: 0,
    Nov: 0,
    Dez: 0,
  };

  data.forEach((item) => {
    const date = months[new Date(item.created_at).getMonth()]; //0 a 11

    if (!groupedData[date]) {
      groupedData[date] = 0;
    }

    groupedData[date] += 1;
  });

  const resultArray = Object.keys(groupedData).map((date) => ({
    value: groupedData[date],
    date,
  }));

  return resultArray;
}

export function accountsToChartDataCount(
  data: Account[]
): { value: number; date: string }[] {
  const groupedData: { [key: string]: number } = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Abr: 0,
    Mai: 0,
    Jun: 0,
    Jul: 0,
    Ago: 0,
    Set: 0,
    Out: 0,
    Nov: 0,
    Dez: 0,
  };

  data.forEach((item) => {
    const date = months[new Date(item.created_at).getMonth()]; //0 a 11

    if (!groupedData[date]) {
      groupedData[date] = 0;
    }

    groupedData[date] += 1;
  });

  const resultArray = Object.keys(groupedData).map((date) => ({
    value: groupedData[date],
    date,
  }));

  return resultArray;
}

import React, { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import IntlCurrencyInput from "react-intl-currency-input";


const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

interface IInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>  {
  onChange: (e: any, number: number, masked: string) => void;
}


const InputBaseQrCodeStatic: ForwardRefRenderFunction<HTMLInputElement, IInputProps>
  = ({onChange,  ...rest}, ref) => {


  return (
    <>
    <IntlCurrencyInput
      currency="BRL"
      config={currencyConfig}
      ref={ref}
      {...rest}
      onChange={onChange}
      style={{
        // opacity: isLocked ? 0.5 : 1,
         //backgroundColor: 'white',
         border:'1px solid #b6b6b6',
       //  backgroundColor: isLocked ? 'lightgrey' : 'white',
         // border: isLocked ? '2px solid black' : '2px solid black'
         margin: '2px 0',
         outline: "none",
         padding: "8px",
         borderRadius: '8px',
       //  width: isLocked ? inputWidth :inputWidth,
       minWidth: "300px" , 
       maxWidth: "300px" ,    
        height:"38px",
      // width: inputWidth,
         paddingBottom: '2px',
         textAlign: 'center',
         color: 'black',
       }}     
    />

  </>
  );
}

export const InputInsertValueQrCodeStatic = forwardRef(InputBaseQrCodeStatic);

export default InputInsertValueQrCodeStatic;


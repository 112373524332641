import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import { FormEvent, Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import IntlCurrencyInput from "react-intl-currency-input";
import { Transition } from "@headlessui/react";
import { classNames } from "../Layout/base";
import { api } from "../../../services/api";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import { GraphicAccount } from "src/pages/myLimits/graphic-account.interface";

export interface Transaction {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  // type: string;
  credit: boolean;
  // amount: number;
  //

  createdAt: string;
  category: string;
  amount: string;
  type: string;
  transactionData: {
    clientNamePayer: string;
    clientName: string;
    keyTarget: string;
  };
}

interface IItems {
  [x: string]: any;
  id: string;
  transactionId: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  // type: string;
  credit: boolean;
  // amount: number;
  //

  category: string;
  amount: string;
  type: string;
  createdAt: string;
  transactionData: any;
}

export interface ExtractItem {
  id: string;
  direction: "in" | "out";
  description: string;
  amount: number;
  created_at: string;
  operation: string;
  title: string;
}

type AccountProps = {
  id: string;
  name: string;
  balance: number;
  document: string;
  email: string;
};

type AddressProps = {
  city: string;
  complement: string;
  date: string;
  id: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
};

type DocumentsProps = {
  id: string;
  originalName: string;
  name: string;
  status: string;
  type: string;
  date: string;
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export function WalletDetailsTax() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<AccountProps>({} as AccountProps);
  const [address, setAddress] = useState<AddressProps>({} as AddressProps);
  const [documents, setDocuments] = useState<DocumentsProps[]>(
    [] as DocumentsProps[]
  );

  const [graphicAccount, setGraphicAccount] = useState<GraphicAccount>();

  const [feeBillet, setFeeBillet] = useState(0);
  const [feeP2pIn, setFeeP2pIn] = useState(0);
  const [feeP2pOut, setFeeP2pOut] = useState(0);
  const [feePixIn, setFeePixIn] = useState(0);
  const [feePixOut, setFeePixOut] = useState(0);
  const [feeTedOut, setFeeTedOut] = useState(0);
  const [limitDay, setLimitDay] = useState(0);
  const [limitNightly, setLimitNightly] = useState(0);
  const [limitRequestStatus, setLimitRequestStatus] = useState("");
  const [limitMonth, setLimitMonth] = useState(0);

  const [show, setShow] = useState(false);

  const token = window.localStorage.getItem("@backoffice:token");

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .get(`/backoffice/fee-limits/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        setGraphicAccount(data);
        if (data && data.feeLimits && data.feeLimits.length > 0) {
          setFeeBillet(data.feeLimits[0].feeBillet);
          setFeeP2pIn(data.feeLimits[0].feeP2pIn);
          setFeeP2pOut(data.feeLimits[0].feeP2pOut);
          setFeePixIn(data.feeLimits[0].feePixIn);
          setFeePixOut(data.feeLimits[0].feePixOut);
          setFeeTedOut(data.feeLimits[0].feeTedOut);
          setLimitDay(data.feeLimits[0].limitDay);
          setLimitNightly(data.feeLimits[0].limitNightly);
          setLimitMonth(data.feeLimits[0].limitMonth);
          setLimitRequestStatus(data.feeLimitChangeRequests[0]?.status ?? "");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id, token]);

  async function handleForm(e: FormEvent) {
    e.preventDefault();
    setLoading(true);

    try {
      await api.patch(`/backoffice/accounts/${id}`, {
        id: id,
        feePixIn,
        feePixOut,
        feeTedOut,
        feeP2pOut,
        feeBillet,
        limitDay,
        limitMonth,
      });

      setLoading(false);
      setShow(true);
    } catch {
      setLoading(false);
      //
    }
  }
  console.log("RESPONSE DO 04:");

  /**
 * return (
                        <tr
                          key={item.id}
                          onClick={() => {
                            // showTransaction(item as Transaction)
                          }}
                        >
                          <td>{dateFormat(item.created_at)}</td>
                          <td>
                            {item.direction === "in" ? "Crédito" : "Débito"}
                          </td>
                          <td>{item.description}</td>
                          <td>{item.title}</td>
                          <td>
                            <span
                              style={{
                                color:
                                  item.direction === "in"
                                    ? "var(--green)"
                                    : "var(--boxText)",
                                marginLeft:
                                  item.direction === "in" ? "8px" : "0", // Aplica o paddingLeft somente quando a direção for 'in'
                              }}
                            >
                              {currencyFormat(Number(item.amount))}
                            </span>

                            <span
                              style={{
                                marginLeft: "20px",
                                display:
                                  item.direction === "in" ? "none" : "inline",
                              }}
                            ></span>
                          </td>

                          <td>
                            <PrintButtonIcon>
                              <PrinterIcon
                                onClick={() => handleGeneratePDF(item)}
                                color="var(--boxText)"
                                className="h-4 w-4 inline"
                              />
                            </PrintButtonIcon>
                          </td>
                        </tr>
                      );
 */

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
          <nav className="space-y-1">
            <Link
              to={`/backoffice/accounts/wallet/${id}`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
              <span className="truncate">Dados gerais</span>
            </Link>
            <Link
              to={`/backoffice/accounts/${id}/extract`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
              <span className="truncate">Transações (Extrato)</span>
            </Link>
            <Link
              to={`/backoffice/accounts/wallet/${id}/tax`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
              <span className="truncate">Limites operacionais</span>
            </Link>
            <Link
              to={`/backoffice/upload/delbank/${id}`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
            <span className="truncate">Envio de Documentos</span>
          </Link>
          </nav>
      </aside>

      <div
        className={classNames(
          "space-y-6 sm:px-6 lg:px-0 w-ful",
          "lg:col-span-10"
        )}
      >
        <section aria-labelledby="payment-details-heading">
          <>
            <form onSubmit={handleForm}>
              <div className="shadow sm:rounded-md sm:overflow-hidden mt-10">
                <div className="bg-white py-6 px-4 sm:p-6">
                  <Skeleton isActive={loading} repeat={3} />
                  {!loading && (
                    <>
                      <div>
                        <h2
                          id="payment-details-heading"
                          className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                        >
                          Limites:
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          {/* Update your billing information. Please note that updating your location could affect your tax
                    rates. */}
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-6 gap-6">
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Boleto
                          </label>
                          <IntlCurrencyInput
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            currency="BRL"
                            config={currencyConfig}
                            value={feeBillet}
                            onChange={(_event: FormEvent, value: number) =>
                              setFeeBillet(value)
                            }
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            TED
                          </label>
                          <IntlCurrencyInput
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            currency="BRL"
                            config={currencyConfig}
                            value={feeTedOut}
                            onChange={(_event: FormEvent, value: number) =>
                              setFeeTedOut(value)
                            }
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            P2P Saída (Entre contas)
                          </label>
                          <IntlCurrencyInput
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            currency="BRL"
                            config={currencyConfig}
                            value={feeP2pOut}
                            onChange={(_event: FormEvent, value: number) =>
                              setFeeP2pOut(value)
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-6 grid grid-cols-6 gap-6">
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            P2P Entrada (Entre contas)
                          </label>
                          <input
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            type="text"
                            value="Ilimitado"
                            disabled
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Pix (Saída)
                          </label>
                          <IntlCurrencyInput
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            currency="BRL"
                            config={currencyConfig}
                            value={feePixOut}
                            onChange={(_event: FormEvent, value: number) =>
                              setFeePixOut(value)
                            }
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Pix (Entrada)
                          </label>
                          <input
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            type="text"
                            value="Ilimitado"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="mt-5">
                        <h2
                          id="payment-details-heading"
                          className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                        >
                          Meus limites operacionais:
                        </h2>
                      </div>

                      <div className="mt-6 grid grid-cols-6 gap-6">
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Limite diário
                          </label>
                          <IntlCurrencyInput
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            currency="BRL"
                            config={currencyConfig}
                            value={limitDay}
                            onChange={(_event: FormEvent, value: number) =>
                              setLimitDay(value)
                            }
                          />
                        </div>

                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Limite notuno
                          </label>
                          <IntlCurrencyInput
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            currency="BRL"
                            config={currencyConfig}
                            value={limitNightly}
                            onChange={(_event: FormEvent, value: number) =>
                              setLimitNightly(value)
                            }
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Limite mensal
                          </label>
                          <IntlCurrencyInput
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            currency="BRL"
                            config={currencyConfig}
                            value={limitMonth}
                            onChange={(_event: FormEvent, value: number) =>
                              setLimitMonth(value)
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* {!loading && (
                  <div className="px-4 py-3 bg-white text-right sm:px-6">
                    <button
                      type="submit"
                      className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      Salvar
                    </button>
                  </div>
               )}*/}
              </div>
            </form>

            <div
              aria-live="assertive"
              className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
              <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                  show={show}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-sm font-medium text-gray-900">
                            Configurações salvar
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            As configurações de taxas foram alteradas.
                          </p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <button
                            type="button"
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </>
        </section>
      </div>
    </div>
  );
}


import styled from "styled-components";
import { ReactComponent as ArrowPageLeftIconSVG } from "../../../assets/arrowPageLeft.svg";

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`
  fill: var(--cian);
  width: 16px;
`;
export const Container = styled.div`
  height: 100vh;
`;
export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
`;
export const Form = styled.form`
  // max-width: 488px;
`;
export const HeaderForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
`;
export const GoBackButton = styled.button`
  border-style: none;
  margin-right: 24px;
  height: 40px;
  width: 40px;
  background: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
`;
export const FormContainer = styled.div`
display: flex;
flex-direction: column;
gap: 50px;
`
export const FormSection = styled.section`
padding: 35px 24px;
background-color: var(--white);
display: flex;
flex-direction: column;
gap: 12px;
box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
border-radius: 8px;
`
export const InputSection = styled.section`
position: relative;
border-radius: 8px;
display: flex;
flex-direction: column;
justify-content: space-evenly;
justify-content: center;
`
export const InputGrouping = styled.section<{cols: string}>`
border-radius: 8px;
display: grid;
grid-template-columns: repeat(${c => c.cols}, minmax(0, 1fr));
gap 14px;
position: relative;
`
export const TextArea = styled.textarea<{borderColor: string}>`
  border: 1px solid ${p => p.borderColor};
  border-radius: 8px;
  padding: 16px 14px;
`
export const SectionTitle = styled.p`
  font-weight: bold;
  font-size: medium;
`
export const ContentWrapper = styled.div`
  padding: 32px;
`;
export const ButtonWrapper = styled.div`
  width: 100%;

  margin-top: 24px;

  @media (min-width: 720px) {
    width: 240px;
  }
`;
export const WrapperHeader = styled.div`

margin-bottom: 44px;

`

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import ModalConfirmOperation from "../../components/ModalConfirmOperation";
import { useModalTransfer } from "../../hooks/modalTransfer";
import { Contacts } from "src/components/contacts";

export const PixFavorites: React.FC = () => {
  const navigate = useNavigate();

  const handleNextScreen = () => {
    navigate("/pix/transfer");
  };

  return (
    <>
      <Contacts />
    </>
  );
};

import { DocumentAddIcon, XCircleIcon } from "@heroicons/react/solid";
import {
  ChangeEvent,
  FC,
  ForwardRefRenderFunction,
  Fragment,
  SelectHTMLAttributes,
  useEffect,
  useState,
  forwardRef,
  InputHTMLAttributes,
  Ref,
} from "react";

import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";

import { Link, useParams } from "react-router-dom";
import { classNames } from "../Layout/base";
import { Dialog, Transition } from "@headlessui/react";

import { api } from "../../../services/api";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { useNotification } from "src/hooks/notification";
import { Plan } from "src/pages/createAccountWalletInitials/account_wallet_create";
import axios from "axios";
import { service, serviceError } from "src/services/ajio";
import { Loading } from "src/components/loading";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { convertToBase64 } from "src/utils/convertToBase64";
import Dropzone from "src/components/Dropzone";
import { DocumentIcon } from "@heroicons/react/outline";
import { Alert } from "antd";

const businessTypes = ["SA", "LTDA", "SS", "ME", "EPP", "MEI", "EIRELI"];

const typeNumber: any = {
  SA: "01",
  LTDA: "02",
  SS: "03",
  ME: "04",
  EPP: "05",
  MEI: "06",
  EIRELI: "07",
};

type IFormInfo = {
  type: "company";
  name: string;
  email: string;
  document: string;
  phone: string;
  // email: string;
  password: string;
  password2: string;
  pin: string;
  securityEletronic: string;
  legalName: string;
  legalNature: string;
  establishmentDate: string;
  establishmentType: string;
  mainCnae: string;
  revenue: string;
  amountMonthlyInvoicing: string;
  amountShareCapital: string;
  amountPatrimony: string;
  address: {
    street: string;
    number: string;
    extra: string;
    neighborhood: string;
    city: string;
    type: string;
    state: string; //only the UF code
    postalCode: string; //only numbers
  };
  partner: {
    isPep: boolean;
    name: string;
    document: string;
    phone: string;
    email: string;
    birthDate: string;
    motherName: string;
    address: {
      street: string;
      number: string;
      extra: string;
      type: string;
      neighborhood: string;
      city: string;
      state: string; //only the UF code
      postalCode: string; //only numbers
    };
  };
};

type IFormError = {
  type?: string;
  name?: string;
  document?: string;
  phone?: string;
  email?: string;
  password?: string;
  password2?: string;
  pin?: string;
  securityEletronic?: string;
  legalName?: string;
  legalNature?: string;
  establishmentDate?: string;
  establishmentType?: string;
  mainCnae?: string;
  revenue?: string;
  amountMonthlyInvoicing?: string;
  amountShareCapital?: string;
  amountPatrimony?: string;
  address?: {
    street?: string;
    number?: string;
    extra?: string;
    neighborhood?: string;
    city?: string;
    state?: string; //only the UF code
    postalCode?: string; //only numbers
  };
  partner?: {
    isPep?: string;
    name?: string;
    document?: string;
    phone?: string;
    email?: string;
    birthDate?: string;
    motherName?: string;
    address?: {
      street?: string;
      number?: string;
      extra?: string;
      neighborhood?: string;
      city?: string;
      state?: string; //only the UF code
      postalCode?: string; //only numbers
    };
  };
};

interface IndividualProps {
  status: string | null;
}

//colocar component de documento e salvar o id da conta criada para edicao dos docs

export function SignUpPjForBackoffice() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const { setOpenNotification } = useNotification();

  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [typeDoc, setTypeDoc] = useState("SELFIE");
  const [franchiseId, setFranchiseId] = useState<string | undefined>("52452452435-562546546-56456245645");

  const [step, setStep] = useState<"ACCOUNT" | "DOCUMENTS">("ACCOUNT");

  const [createdUserId, setCreatedUserId] = useState();

  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("19e10399-2c96-4fd9-b411-ed773501b7d4");
  const [constituitionType, setConstituitionType] = useState("");

  const token = localStorage.getItem("@backoffice:token");

  const fetchPlans = async () => {
    try {
      const response = await api.get("/graphic/plans", {
        params: {
          role: "MEMBERPF",
        },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const id = process.env.REACT_APP_FRANCHISE_ID;
    if (id) {
      setFranchiseId(id);
    }
    console.log("FRANCHISE_ID modificado: ", id);
  }, [franchiseId]);

  const handleOneSubmit = async () => {
    setError({} as IFormError);

    // // Password Validations
    const pass = formInfo.password;
    const pass2 = formInfo.password2;
    if (pass.length < 8) {
      setError({ password: "A senha deve ter pelo menos 8 dígitos." });
      return;
    }

    if (!/[A-Z]/.test(pass) || !/[a-z]/.test(pass) || !/\d/.test(pass)) {
      setError({
        password: "A senha deve ter pelo uma letra maiúscula, uma letra minúscula e um número.",
      });
      return;
    }

    if (pass !== pass2) {
      setError({ password2: "As senhas não estão iguais." });
      return;
    }

    if (formInfo.pin.length !== 4) {
      setError({ ...error, pin: "O Security Digit deve ter 4 dígitos." });
      return;
    }

    if (
      [
        "1234",
        "2345",
        "3456",
        "4567",
        "5678",
        "6789",
        "4321",
        "5432",
        "6543",
        "7654",
        "8765",
        "9876",
        "3210",
        "0123",
      ].includes(formInfo.pin)
    ) {
      setError({
        ...error,
        pin: "O Security Digit não pode ser composto de números consecutivos.",
      });
      return;
    }

    if (formInfo.securityEletronic.length !== 8) {
      setError({
        ...error,
        securityEletronic: "A Senha Eletrônica deve ter 8 dígitos.",
      });
      return;
    }

    if (
      [
        "1234",
        "2345",
        "3456",
        "4567",
        "5678",
        "6789",
        "4321",
        "5432",
        "6543",
        "7654",
        "8765",
        "9876",
        "3210",
        "0123",
      ].includes(formInfo.securityEletronic)
    ) {
      setError({
        ...error,
        securityEletronic: "A Senha Eletrônica não pode ser composto de números consecutivos.",
      });
      return;
    }

    setLoading(true);

    try {
      const raw = {
        password: formInfo.password,
        email: formInfo.email,
        security_eletronic: formInfo.securityEletronic,
        document: formInfo.document?.replace(/[/,.,-\s]/g, ""),
        constituitionType,
        // businessEmail: formInfo.email,
        corporateName: formInfo.legalName,
        fantasyName: formInfo.name,
        mother_name: formInfo.partner.motherName,
        activityCnae: formInfo.mainCnae,
        openingDate: formInfo.establishmentDate?.split("/").reverse().join("-"),
        isPoliticallyExposedPerson: false,
        amountMonthlyInvoicing: Number(formInfo?.amountMonthlyInvoicing?.replace(/[/,R$ .,-\s]/g, "")) / 100,
        amountShareCapital: Number(formInfo?.amountShareCapital?.replace(/[/,R$ .,-\s]/g, "")) / 100,
        amountPatrimony: Number(formInfo?.amountPatrimony?.replace(/[/,R$ .,-\s]/g, "")) / 100,
        planId: selectedPlanId,
        address: {
          state: formInfo.address?.state.toLowerCase(),
          cityName: formInfo.address?.city,
          isConfirmed: true,
          type: formInfo.address.type,
          publicPlace: formInfo.address?.street,
          number: formInfo.address?.number,
          zipCode: formInfo.address?.postalCode?.replace(/-/g, ""),
          neighborhood: formInfo.address?.neighborhood,
          complement: formInfo.address?.extra || "",
        },
        phone: {
          ddd: formInfo.phone?.replace(/\D/g, "").substring(0, 2),
          number: formInfo.phone?.replace(/^\(\d{2}\)/, "").replace(/\D/g, ""),
          type: "CELL",
        },
        partners: [
          {
            is_pep: formInfo.partner?.isPep,
            name: formInfo.partner?.name,
            document: formInfo.partner?.document?.replace(/[/,.,-\s]/g, ""),
            // phone: formInfo.phone?.replace(/[-,\s,(,)]/g, ""), //same as company
            // email: formInfo.email, //same as company
            birthDate: formInfo.partner?.birthDate?.split("/").reverse().join("-"),
            mother_name: formInfo.partner?.motherName,
            phone: {
              ddd: formInfo.phone?.replace(/\D/g, "").substring(0, 2),
              number: formInfo.phone?.replace(/^\(\d{2}\)/, "").replace(/\D/g, ""),
              type: "CELL",
            },
            address: {
              zipCode: formInfo.partner?.address?.postalCode?.replace(/-/g, ""),
              publicPlace: formInfo.partner?.address?.street,
              number: formInfo.partner?.address?.number,
              complement: formInfo.partner?.address?.extra || "",
              neighborhood: formInfo.partner?.address?.neighborhood,
              cityName: formInfo.partner?.address?.city,
              state: formInfo.partner?.address?.state,
              isConfirmed: true,
              type: formInfo.address.type,
            },
          },
        ],
      };
      console.log("asdadasdasdasdasassvvvv");

      const response = await service.post("/backoffice/accounts/compaine", raw, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const createdUser = response.data;

      setCreatedUserId(createdUser.id);

      setStep("DOCUMENTS");

      setOpenNotification({
        title: "Conta criada com sucesso",
        notification: "",
        type: "success",
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);

      setOpenNotification({
        title: "Erro ao criar conta",
        notification: "Revise seus dados",
        type: "error",
      });
      // @ts-ignore
    }
  };

  async function handleCep(cep: string) {
    setError({} as IFormError);
    if (cep.length < 9) {
      setError({
        ...error,
        address: {
          ...formInfo.address,
          postalCode: "Preencha o CEP completo.",
        },
      });
      return;
    }
    const rawPostalCode = cep.replace(/-/g, "");
    if (rawPostalCode.length >= 8) {
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${rawPostalCode}/json/`);

        if (data) {
          setFormInfo({
            ...formInfo,
            address: {
              ...formInfo.address,
              street: data.logradouro,
              neighborhood: data.bairro,
              city: data.localidade,
              state: data.uf,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  async function handleCepPartner(cep: string) {
    setError({} as IFormError);
    if (cep.length < 9) {
      setError({
        ...error,
        partner: {
          ...error?.partner,
          address: {
            ...error?.partner?.address,
            postalCode: "Preencha o CEP completo.",
          },
        },
      });
      return;
    }

    const rawPostalCode = cep.replace(/-/g, "");

    if (rawPostalCode.length >= 8) {
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${rawPostalCode}/json/`);

        if (data) {
          setFormInfo({
            ...formInfo,
            partner: {
              ...formInfo?.partner,
              address: {
                ...formInfo?.partner?.address,
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf,
              },
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleDocuments = async () => {
    setLoading(true);
    documents.map(async (document) => {
      try {
        if (document.base64) {
          const response = await service.post(
            "backoffice/accounts/document",
            {
              userId: createdUserId,
              documentBase64: document.base64,
              documentType: document.type,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      } catch (error) {
        console.log("erro ao alterar documento", document, error);
      }
    });

    setTimeout(() => {
      setLoading(false);

      setOpenNotification({
        notification: `Documentos salvos`,
        title: `Sucesso`,
        type: "success",
      });
    }, 3000);
  };

  interface PlanMapping {
    [key: string]: string;
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    const partnerCep = formInfo?.partner?.address?.postalCode;
    const cep = formInfo?.address?.postalCode;

    if (cep?.length == 9 && !formInfo?.address?.city) handleCep(cep);
    if (partnerCep?.length == 9 && !formInfo?.partner?.address?.city) handleCepPartner(partnerCep);
  }, [formInfo]);

  const [documents, setDocuments] = useState([
    {
      name: "Selfie",
      type: "SELFIE",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "CNH verso",
      type: "CNH_BACK",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "CNH frontal",
      type: "CNH_FRONT",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "RG fontal",
      type: "RG_FRONT",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "RG verso",
      type: "RG_BACK",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "Contrato social / CCMEI",
      type: "PERSON_LEGAL_SOCIAL_CONTRACT",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "Rendimentos mensais",
      type: "PERSON_LEGAL_REVENUES",
      base64: "",
      isImage: false,
      fileName: "",
    },
  ]);

  function ajustarFormatoData(data: string) {
    let partes = data.split("-");
    let dataFormatada = partes[2] + "/" + partes[1] + "/" + partes[0];

    return dataFormatada;
  }

  useEffect(() => {
    const document = formInfo.document?.replace(/\D/g, "");
    const handleGetCnpj = async () => {
      try {
        const user = await api.get(`/backoffice/user/${document}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (user.data) {
          setOpenNotification({
            notification: `CNPJ já cadastrado no sistema`,
            title: `Erro`,
            type: "error",
          });
          return;
        }

        const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${document}`);
        const info = response.data;
        if (!info) {
          setOpenNotification({
            notification: `Informações do CNPJ não encontradas`,
            title: `Erro`,
            type: "error",
          });
          return;
        }
        setFormInfo({
          ...formInfo,
          name: info.nome_fantasia,
          legalName: info.razao_social,
          phone: info.ddd_telefone_1,
          mainCnae: info.cnae_fiscal,
          establishmentDate: ajustarFormatoData(info.data_inicio_atividade),
          amountShareCapital: info.capital_social,
          email: info.email,
          address: {
            ...formInfo.address,
            postalCode: info.cep,
            number: info.numero,
            city: info.municipio,
            state: info.uf,
            neighborhood: info.bairro,
            street: info.logradouro,
            extra: info.complemento,
          },
        });

        if (info.qsa && info.qsa.length > 0) {
          setFormInfo({
            ...formInfo,
            partner: {
              ...formInfo.partner,
              name: info.qsa[0].nome_socio,
            },
          });
        }
      } catch (err: any) {
        setOpenNotification({
          notification: err?.response?.data?.message || `Erro ao buscar informações do CNPJ`,
          title: `Erro`,
          type: "error",
        });
        console.error(err);
      }
    };

    if (document && document.length === 14) {
      handleGetCnpj();
    }
  }, [formInfo.document]);

  if (step == "ACCOUNT")
    return (
      <div className="flex w-full items-center justify-center">
        <div className="py-6 px-4 sm:p-6 bg-white rounded-xl shadow-sm max-w-[1200px]">
          <>
            <div>
              <h2
                id="payment-details-heading"
                className="flex justify-between text-lg leading-6 font-medium text-gray-900"
              >
                Cadastro de conta PJ:
              </h2>

              <p className="mt-1 text-sm text-gray-500">
                {/* Update your billing information. Please note that updating your location could affect your tax
                        rates. */}
              </p>
            </div>

            <div className="mt-6 grid grid-cols-5 gap-6 ">
              <PropDetail
                cols={2}
                title="CNPJ"
                placeholder="CNPJ"
                required
                mask="99.999.999/9999-99"
                error={error.document || ""}
                value={formInfo.document}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    document: e.target.value,
                  })
                }
              />
              <PropDetail
                cols={1}
                title="Nome Fantasia"
                placeholder="Nome Fantasia"
                required
                error={error.name || ""}
                value={formInfo.name}
                onChanged={(e) => setFormInfo({ ...formInfo, name: e.target.value })}
              />
              <PropDetail
                cols={2}
                title="Razão social"
                placeholder="Razão social"
                required
                error={error.legalName || ""}
                value={formInfo.legalName}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    legalName: e.target.value,
                  })
                }
              />

              <PropDetailOptions
                title="Plano"
                placeholder="Plano"
                label="Plano"
                options={plans.map((p) => ({
                  id: p.id,
                  name: `${p.name} R$ ${toLocaleAmount(p.price)}`,
                }))}
                onChanged={(e: any) => setSelectedPlanId(e.target.value)}
              />
              <PropDetail
                title="Telefone celular"
                cols={1}
                placeholder=""
                required
                mask="(99) 99999-9999"
                error={error.phone || ""}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    phone: e.target.value,
                  })
                }
              />
              <PropDetailOptions
                cols={1}
                title="Tipo da empresa"
                placeholder="Escolha um"
                options={businessTypes.map((bt, index) => ({
                  id: typeNumber[bt],
                  name: bt,
                }))}
                onChanged={(e: any) => setConstituitionType(e.target.value)}
              />
              <PropDetail
                cols={1}
                title="CNAE Principal"
                error={error.mainCnae || ""}
                value={formInfo.mainCnae}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    mainCnae: e.target.value,
                  })
                }
              />
              <PropDetail
                cols={1}
                title="Data de fundação"
                mask="99/99/9999"
                error={error.establishmentDate || ""}
                value={formInfo.establishmentDate}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    establishmentDate: e.target.value,
                  })
                }
              />
              <FormInputCurrency
                title="Receita media mensal"
                cols={1}
                placeholder="Receita media mensal"
                required
                value={parseFloat(formInfo.amountMonthlyInvoicing)}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    amountMonthlyInvoicing: e.target.value,
                  })
                }
                error={error.amountMonthlyInvoicing || ""}
              />
              <FormInputCurrency
                title="Patrimonio"
                cols={1}
                placeholder="Patrimonio"
                required
                value={parseFloat(formInfo.amountPatrimony)}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    amountPatrimony: e.target.value,
                  })
                }
                error={error.amountPatrimony || ""}
              />
              <FormInputCurrency
                title="Capital social"
                cols={1}
                placeholder="Capital social"
                required
                value={parseFloat(formInfo.amountShareCapital)}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    amountShareCapital: e.target.value,
                  })
                }
                error={error.amountShareCapital || ""}
              />
              <PropDetail
                title="Email"
                cols={2}
                placeholder="Email"
                required
                value={formInfo.email}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    email: e.target.value,
                  })
                }
                error={error.email || ""}
              />

              <h1 className="col-span-5 mb-[-14px] mt-1">Endereço</h1>
              <PropDetail
                title="CEP"
                cols={1}
                placeholder="CEP"
                required
                mask="99999-999"
                value={formInfo?.address?.postalCode}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      postalCode: e.target.value,
                    },
                  })
                }
                error={error?.address?.postalCode || ""}
              />
              <PropDetail
                title="Cidade"
                cols={1}
                placeholder="Cidade"
                required
                value={formInfo?.address?.city}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      city: e.target.value,
                    },
                  })
                }
                error={error?.address?.city || ""}
              />
              <PropDetail
                title="UF"
                cols={1}
                placeholder="UF"
                required
                value={formInfo?.address?.state}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      state: e.target.value,
                    },
                  })
                }
                error={error?.address?.state || ""}
              />
              <PropDetail
                title="Bairro"
                cols={2}
                placeholder="Bairro"
                required
                value={formInfo?.address?.neighborhood}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      neighborhood: e.target.value,
                    },
                  })
                }
                error={error?.address?.neighborhood || ""}
              />
              <PropDetail
                title="Rua"
                cols={2}
                placeholder="Rua"
                required
                value={formInfo?.address?.street}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      street: e.target.value,
                    },
                  })
                }
                error={error?.address?.street || ""}
              />
              <PropDetail
                title="Número"
                cols={1}
                placeholder="Número"
                required
                value={formInfo?.address?.number}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      number: e.target.value,
                    },
                  })
                }
                error={error?.address?.number || ""}
              />
              <PropDetail
                title="Complemento"
                cols={1}
                placeholder="Complemento"
                required
                value={formInfo?.address?.extra}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      extra: e.target.value,
                    },
                  })
                }
                error={error?.address?.extra || ""}
              />
              <PropDetailOptions
                cols={1}
                title="Tipo de endereço"
                placeholder="Escolha um"
                options={[
                  {
                    id: "1",
                    name: "Residencial",
                  },
                  {
                    id: "2",
                    name: "Comercial",
                  },
                ]}
                onChanged={(e: any) =>
                  setFormInfo({
                    ...formInfo,
                    address: {
                      ...formInfo.address,
                      type: e.target.value,
                    },
                  })
                }
              />
              <h1 className="col-span-5 mb-[-14px] mt-1">Sócio</h1>
              <PropDetail
                title="Nome do sócio"
                cols={1}
                placeholder="nome"
                required
                value={formInfo?.partner?.name}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      name: e.target.value,
                    },
                  })
                }
                error={error?.partner?.name || ""}
              />
              <PropDetail
                title="CPF"
                cols={1}
                placeholder="CPF"
                required
                mask="999.999.999-99"
                value={formInfo?.partner?.document}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      document: e.target.value,
                    },
                  })
                }
                error={error?.partner?.document || ""}
              />
              <PropDetail
                title="Data de nascimento"
                cols={1}
                required
                mask="99/99/9999"
                value={formInfo?.partner?.birthDate}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      birthDate: e.target.value,
                    },
                  })
                }
                error={error?.partner?.birthDate || ""}
              />
              <PropDetail
                title="Nome da mãe"
                cols={1}
                required
                value={formInfo?.partner?.motherName}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      motherName: e.target.value,
                    },
                  })
                }
                error={error?.partner?.motherName || ""}
              />
              <div className="flex flex-col col-span-1 gap-2">
                <span>O sócio é PEP?</span>
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-1">
                    <input
                      onClick={() =>
                        setFormInfo({
                          ...formInfo,
                          partner: {
                            ...formInfo.partner,
                            isPep: true,
                          },
                        })
                      }
                      type="radio"
                      name="pep"
                      id="pep-yes"
                    />
                    <label htmlFor="pep-yes">Sim</label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      onClick={() =>
                        setFormInfo({
                          ...formInfo,
                          partner: {
                            ...formInfo.partner,
                            isPep: false,
                          },
                        })
                      }
                      type="radio"
                      name="pep"
                      id="pep-no"
                    />
                    <label htmlFor="pep-no">Não</label>
                  </div>
                </div>
              </div>
              <h1 className="col-span-5 mb-[-14px] mt-1">Endereço do sócio</h1>
              <PropDetail
                title="CEP"
                cols={1}
                placeholder="CEP"
                required
                mask="99999-999"
                value={formInfo?.partner?.address?.postalCode}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo?.partner,
                      address: {
                        ...formInfo?.partner?.address,
                        postalCode: e.target.value,
                      },
                    },
                  })
                }
                error={error?.partner?.address?.postalCode || ""}
              />
              <PropDetail
                title="Cidade"
                cols={1}
                placeholder="Cidade"
                required
                value={formInfo?.partner?.address?.city}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      address: {
                        ...formInfo.partner.address,
                        city: e.target.value,
                      },
                    },
                  })
                }
                error={error?.partner?.address?.city || ""}
              />
              <PropDetail
                title="UF"
                cols={1}
                placeholder="UF"
                required
                value={formInfo?.partner?.address?.state}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      address: {
                        ...formInfo.partner.address,
                        state: e.target.value,
                      },
                    },
                  })
                }
                error={error?.partner?.address?.state || ""}
              />
              <PropDetail
                title="Bairro"
                cols={2}
                placeholder="Bairro"
                required
                value={formInfo?.partner?.address?.neighborhood}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      address: {
                        ...formInfo.partner.address,
                        neighborhood: e.target.value,
                      },
                    },
                  })
                }
                error={error?.partner?.address?.neighborhood || ""}
              />
              <PropDetail
                title="Rua"
                cols={2}
                placeholder="Rua"
                required
                value={formInfo?.partner?.address?.street}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      address: {
                        ...formInfo.partner.address,
                        street: e.target.value,
                      },
                    },
                  })
                }
                error={error?.partner?.address?.street || ""}
              />
              <PropDetail
                title="Número"
                cols={1}
                placeholder="Número"
                required
                value={formInfo?.partner?.address?.number}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      address: {
                        ...formInfo.partner.address,
                        number: e.target.value,
                      },
                    },
                  })
                }
                error={error?.partner?.address?.number || ""}
              />
              <PropDetail
                title="Complemento"
                cols={1}
                placeholder="Complemento"
                required
                value={formInfo?.partner?.address?.extra}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      address: {
                        ...formInfo.partner.address,
                        extra: e.target.value,
                      },
                    },
                  })
                }
                error={error?.partner?.address?.extra || ""}
              />
              <PropDetailOptions
                cols={1}
                title="Tipo de endereço"
                placeholder="Escolha um"
                options={[
                  {
                    id: "1",
                    name: "Residencial",
                  },
                  {
                    id: "2",
                    name: "Comercial",
                  },
                ]}
                onChanged={(e: any) =>
                  setFormInfo({
                    ...formInfo,
                    partner: {
                      ...formInfo.partner,
                      address: {
                        ...formInfo.partner.address,
                        type: e.target.value,
                      },
                    },
                  })
                }
              />
              <h1 className="col-span-5 mb-[-14px] mt-1">Senhas</h1>
              <PropDetail
                title="Senha"
                cols={1}
                placeholder="Senha"
                required
                value={formInfo?.password}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    password: e.target.value,
                  })
                }
                error={error?.password || ""}
              />
              <PropDetail
                title="Confirme a senha"
                cols={1}
                placeholder="Confirme a senha"
                required
                value={formInfo?.password2}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    password2: e.target.value,
                  })
                }
                error={error?.password2 || ""}
              />
              <PropDetail
                title="PIN"
                cols={1}
                placeholder="4 digitos"
                required
                maxLength={4}
                value={formInfo?.pin}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    pin: e.target.value,
                  })
                }
                error={error?.pin || ""}
              />
              <PropDetail
                title="Senha Eletrônica"
                cols={1}
                placeholder="Senha Eletrônica"
                required
                value={formInfo?.securityEletronic}
                onChanged={(e) =>
                  setFormInfo({
                    ...formInfo,
                    securityEletronic: e.target.value,
                  })
                }
                error={error?.securityEletronic || ""}
              />

              <button className="flex justify-end col-span-5" onClick={handleOneSubmit}>
                <Button>
                  Cadastrar
                  <Loading isLoading={loading} />
                </Button>
              </button>
            </div>
          </>
        </div>
      </div>
    );

  return (
    <div className="flex w-full items-center justify-center">
      <div className="py-6 px-4 sm:p-6 bg-white rounded-xl shadow-sm w-full flex flex-wrap ">
        <>
          <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <Alert
              message="Por favor, sinta-se à vontade para completar o seu cadastro anexando uma selfie sua e uma cópia frente e verso de um documento de identificação, como RG ou CNH. Estamos aqui para garantir sua segurança e privacidade. *OBS: Caso você se enquadre como MEI no campo de envio contrato social envie o seu CCMEI*"
              type="warning"
              style={{ marginBottom: 20 }}
            />
          </div>
          <div className="grid w-full grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2">
            {documents.map((doc, i) => (
              <DocumentDetail
                cols={1}
                key={i}
                onPickFile={async (f) => {
                  const fileBase64 = await convertToBase64(f);
                  const isImage = f.name.includes("png") || f.name.includes("jpeg") || f.name.includes("jpg");

                  setDocuments((documents) =>
                    documents.map((_doc) =>
                      _doc.type == doc.type
                        ? {
                            ...doc,
                            base64: fileBase64,
                            isImage,
                            fileName: f.name,
                          }
                        : _doc
                    )
                  );
                }}
                base64={doc.base64}
                name={doc.name}
                isImage={doc.isImage}
                fileName={doc.fileName}
              />
            ))}
          </div>
          <button className="flex w-full justify-end mt-2" onClick={handleDocuments}>
            <Button className="gap-2">
              Salvar documentos
              <Loading isLoading={loading} />
            </Button>
          </button>
        </>
      </div>
    </div>
  );
}

const DocumentDetail: FC<{
  name: string;
  base64: string;
  cols: number;
  isImage: boolean;
  fileName?: string;
  onPickFile: (file: File) => void;
}> = ({ base64, name, onPickFile, cols, isImage }) => {
  return (
    <>
      <div className={`flex flex-col gap-2 grid-cols-[${cols}]`}>
        {base64 ? (
          <div className="flex items-center justify-center w-full h-64 bg-zinc-100 rounded-lg overflow-hidden">
            {isImage ? (
              <img className="w-full h-64 bg-red-400 object-cover" src={`data:image/png;base64, ${base64}`} />
            ) : (
              <div className="flex flex-col items-center justify-center">
                <DocumentIcon width={42} color="#888" />
                <span className="text-zinc-400 text-sm">{name}</span>
              </div>
            )}
          </div>
        ) : (
          <Dropzone
            onDrop={(files) => {
              onPickFile(files[0]);
              console.log(files);
            }}
          />
        )}

        <div className="flex items-center justify-center w-full h-12 text-white rounded-lg bg-[#274683]">
          <span>{name}</span>
        </div>
      </div>
    </>
  );
};

interface PropDetailProps {
  title: string;
  value?: string | number | undefined;
  placeholder?: string;
  required?: boolean;
  error?: string;
  onChanged?: (e: ChangeEvent<HTMLInputElement>) => void;
  cols?: number;
  maxLength?: number;
  mask?: string; // Adicionando a propriedade mask aqui
  id?: string;
}

export const PropDetail: FC<PropDetailProps> = (
  { mask, title, value, placeholder, required, error, onChanged, cols = 4, maxLength }: PropDetailProps,
  ref: Ref<HTMLInputElement>
) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChanged) {
      onChanged(event);
    }
  };

  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {mask ? (
        <InputMask
          mask={mask}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
            error ? "border-red-500" : ""
          }`}
        />
      ) : (
        <input
          type="text"
          id={title}
          name={title}
          value={value}
          placeholder={placeholder}
          required={required}
          maxLength={maxLength}
          onChange={handleChange}
          className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
            error ? "border-red-500" : ""
          }`}
        />
      )}
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};
interface ButtonProps {
  title?: string;
  cols?: number;
  type?: "button" | "submit" | "reset";
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({
  title,
  cols = 4,
  type = "button",
  className = "",
  isLoading = false,
  onClick,
  children,
}) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700 mb-1">
        {title}
      </label>
      <button
        type={type}
        onClick={onClick}
        className={`mt-1 w-full inline-flex justify-center rounded-md bg-green-600 border border-green-300  px-4 py-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`}
        disabled={isLoading}
      >
        {isLoading && <span className="animate-spin mr-2">Loading...</span>}
        {children}
      </button>
    </div>
  );
};

///////////////

type CombinedOnChange =
  | {
      onChanged?: (e: any, number?: number, masked?: string) => void;
    }
  | {
      onChanged?: (e: ChangeEvent<HTMLSelectElement>) => void;
    };

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  cols?: number;
  label?: string;
  mask?: string;
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderInput?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  onChanged?: (e: any, number?: number, masked?: string) => void;
  showPasswordIcon?: boolean; // Adicione a propriedade showPasswordIcon aqui
  id?: string; // Adicione um campo "id" exclusivo para identificar o FormInput
  eyeIconColor?: string; // Adicione a propriedade eyeIconColor aqui
  value?: string | number;
  placeholder?: string;
  required?: boolean;
}

interface PropsSelect extends SelectHTMLAttributes<HTMLSelectElement> {
  title?: string;
  cols?: number;
  label?: string;
  placeholder?: string;
  options: string[] | { name: string; id: string }[];
  error?: string;
  nomargin?: boolean;
  borderColor?: string;
  borderRadius?: string;
  onChanged?: CombinedOnChange["onChanged"]; // Aqui você referencia o tipo combinado de onChanged
  value?: string | number;
  required?: boolean;
  mask?: string;
  onSelectOption?: (data: { name: string; id: string }) => void;
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    title,
    cols = 1,
    label,
    mask,
    error,
    nomargin = false,
    borderColor,
    borderInput,
    borderRadius,
    onChanged,
    showPasswordIcon = false,
    id,
    type = "text",
    ...rest
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>

      <input
        {...rest}
        onChange={(e) => onChanged && onChanged(e)}
        ref={ref as React.RefObject<HTMLInputElement>} // Use ref como RefObject<HTMLInputElement>
      />
      {error && (
        <div>
          <p className="text-sm text-red-600 " id="email-error">
            {error}
          </p>
        </div>
      )}
    </div>
  );
};

///////////// esse ok
const InputSelect = ({
  cols = 1,
  title,
  label,
  placeholder = "Selecione uma opção",
  options,
  error,
  nomargin = false,
  borderColor,
  borderRadius,
  onChanged,
  ...rest
}: PropsSelect) => {
  const containerStyle = {
    marginBottom: 27,
    borderRadius: "4px", // Define o raio dos cantos arredondados
  };

  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <select {...rest} onChange={onChanged}>
        <option value="">{placeholder}</option>
        {options.map((opt) => {
          if (typeof opt === "string") {
            return (
              <option key={opt} value={opt}>
                {opt}
              </option>
            );
          } else {
            return (
              <option key={opt.id} value={opt.id}>
                {opt.name}
              </option>
            );
          }
        })}
      </select>
      {!!error && (
        <div>
          {!!error && (
            <p className="text-sm text-red-600" id="email-error">
              {error}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export const FormInput = forwardRef(InputBase);

export const FormInputSelect = InputSelect;

/////////////////////

interface PropDetailOptions extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: string[] | { name: string; id: string }[];
  onChanged?: (e: ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  error?: string;
  title?: string;
  cols?: number;
}

const PropDetailOptions: React.FC<PropsSelect> = ({
  cols = 1,
  title,
  label,
  error,
  options,
  onChanged,
  placeholder,
  ...rest
}) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>

      <select
        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
          error ? "border-red-500" : ""
        }`}
        {...rest}
        onChange={onChanged}
      >
        <option value="">{placeholder}</option>
        {options.map((opt, index) =>
          typeof opt === "string" ? (
            <option key={index} value={opt}>
              {opt}
            </option>
          ) : (
            <option key={opt.id} value={opt.id}>
              {opt.name}
            </option>
          )
        )}
      </select>
    </div>
  );
};

const InputBaseCurrency: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    title,
    cols = 1,
    label,
    mask,
    error,
    nomargin = false,
    borderColor,
    borderInput,
    borderRadius,
    onChanged,
    showPasswordIcon = false,
    id,
    type = "text",
    ...rest
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>

      {mask !== "currency" ? (
        <div className="flex gap-2 items-center mt-1">
          <div className="col-span-1 border border-gray-300 rounded-md p-1 h-[38px] overflow-hidden">
            <IntlCurrencyInput currency="BRL" config={currencyConfig} onChange={onChanged} {...rest} />
          </div>
        </div>
      ) : (
        <input
          {...rest}
          onChange={(e) => onChanged && onChanged(e)}
          ref={ref as React.RefObject<HTMLInputElement>} // Use ref como RefObject<HTMLInputElement>
        />
      )}
      {error && (
        <div>
          <p className="text-sm text-red-600 " id="email-error">
            {error}
          </p>
        </div>
      )}
    </div>
  );
};

export const FormInputCurrency = forwardRef(InputBaseCurrency);

interface PropsPassword extends InputHTMLAttributes<HTMLInputElement> {
  cols?: number;
  label?: string;
  mask?: string;
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderInput?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  onChanged?: (e: any, number?: number, masked?: string) => void;
  showPasswordIcon?: boolean; // Adicione a propriedade showPasswordIcon aqui
  id?: string; // Adicione um campo "id" exclusivo para identificar o FormInput
  eyeIconColor?: string; // Adicione a propriedade eyeIconColor aqui
  value?: string | number;
  placeholder?: string;
  required?: boolean;
  title: string;
}

const InputBasePassword: ForwardRefRenderFunction<HTMLInputElement, PropsPassword> = (
  { title, value, placeholder, required, error, onChanged, cols = 4, maxLength }: PropDetailProps,
  ref: Ref<HTMLInputElement>
) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChanged) {
      onChanged(event);
    }
  };

  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <input
        type="text"
        id={title}
        name={title}
        value={value}
        placeholder={placeholder}
        required={required}
        maxLength={maxLength}
        onChange={handleChange}
        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export const FormInputPassword = forwardRef(InputBasePassword);

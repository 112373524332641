// types.ts

export interface CurrencyData {
  code: string;
  codein: string;
  name: string;
  high: string;
  low: string;
  varBid: string;
  pctChange: string;
  bid: string;
  ask: string;
  timestamp: string;
  create_date: string;
}

export interface ApiResponse {
  USDBRL: CurrencyData;
  EURBRL: CurrencyData;
  BTCBRL: CurrencyData;
  ARSBRL: CurrencyData;
  AUDBRL: CurrencyData;
  CADBRL: CurrencyData;
  CHFBRL: CurrencyData;
  CNYBRL: CurrencyData;
  GBPBRL: CurrencyData;
  JPYBRL: CurrencyData;
  XAGGBRL: CurrencyData;
  UYUBRL: CurrencyData;
  PYGBRL: CurrencyData;
}

export const PagBankPaymentStatus = {
  ANALYSIS: 2,
  COMPLETE: 3,
  DONE: 4,
  REFOUND: 8,
  RETURNED: 6,
  CANCELED: 7,
};

export const PagBankPaymentType = {
  CREDIT: 1,
  DEBIT: 3,
};

import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import { endOfMonth, format, startOfMonth, subHours } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { classNames } from "../Layout/base";
import DatePicker from "react-datepicker";
import handleExportPDF, { ExportPDF_V2 } from "../Export/pdf";
import handleGeneratePDF from "../Export/pdf";
import handleGenerateExcel from "../Export/excel";

import "react-datepicker/dist/react-datepicker.css";

import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import { api } from "../../../services/api";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { currencyFormat } from "../../../utils/currencyFormat";
import ptBR from "date-fns/locale/pt-BR";
import { PrintButtonIcon } from "src/pages/billet/styles";
import { extractTransactionsPDF } from "src/utils/extract-transactions-pdf";
import { trancactionBuildItems } from "src/utils/transactions";
import { ExtractItem } from "src/utils/extractDescription";
import { useAuth } from "src/hooks/auth";
import { getTransactionNames } from "src/components/extractPdf";
import transactionLabel from "src/utils/transactionLabel";
import { config } from "src/config";
import handleExportExcelWallet from "src/utils/excel/wallet";
import { separateTransactions } from "src/utils/separateTransactions";

export interface Transaction {
  id: string;
  credit: boolean;
  amount: number;
  type: string;
  typeId: string;
  description: string;
  status: string;
  balanceAfter: number;
  date: string;
}

export interface Detail {
  id: string;
  credit: boolean;
  amount: number;
  description: string;
  rateValue: number;
  consignorName: string;
  consignorDocument: string;
  consignorBankNumber: string;
  consignorBankBranch: string;
  consignorBankAccount: string;
  consignorBankAccountDigit: string;
  beneficiaryName: string;
  beneficiaryDocument: string;
  beneficiaryBankNumber: string;
  beneficiaryBankBranch: string;
  beneficiaryBankAccount: string;
  beneficiaryBankAccountDigit: string;
  status: string;
  date: string;
}

export interface MetaProps {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}

export function WalletTransactions() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [items, setItems] = useState<ExtractItem[]>([]);
  const perPage = 10
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const [show, setShow] = useState(false);

  const token2 = window.localStorage.getItem("@backoffice:token");

  const loadTransfers = async (page: number) => {
    setLoading(true);
    try {
      const url = `/backoffice/accounts/${id}/transactions`;

      const { data } = await api.get(url, {
        params: {
          page,
          end_date: endDate.toISOString(),
          start_date: startDate.toISOString(),
        },
        headers: { Authorization: "Bearer " + token2 },
      });

      console.log(data);

      const transactions = data.transactions.data;
      console.log(transactions[0])

      const filteredTransactions = transactions.filter((transaction: any) => {
        const transactionDate = new Date(transaction.created_at);
        return transactionDate >= startDate && transactionDate <= endDate;
      });

      const { orderedTransactions } = separateTransactions(filteredTransactions)


      const { per_page, total } = data.transactions;

      const pages = Math.ceil(total / per_page);

      setItems(orderedTransactions);
      setTotalPages(pages);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await loadTransfers(currentPage);
    })();
  }, [currentPage, id]);

  const handleClickExportPDF = () => {
    handleExport(items, "pdf");
  };

  const handleClickExportExcel = () => {
    handleExport(items, "excel");
  };

  const fetchExportTransactions = async () => {
    try {
      const response = await api.get(
        `/transactions?start=${startDate.toISOString()}&end=${endDate.toISOString()}`
      );
      return response.data.transactions.data;
    } catch (err) {
      console.log(err);
    }
  };


  async function handleExport(items: ExtractItem[], type: string) {
    try {
      const token = localStorage.getItem("@backoffice:token");
      const response = await api.get(`/backoffice/accounts/${id}`, {
        headers: { Authorization: "Bearer " + token },
      });
      const data = response.data.account.user;
      const account = response.data.account;

      if (type == "pdf") {
        setIsExportingPdf(true)
        const params = {
          title: "",
          startDate: startDate,
          endDate: endDate,
          data: data,
          isBackoffice: true,
          graphicAccount_Id: id
        };
        ExportPDF_V2(items, params, true, undefined, id).finally(() => {
          setIsExportingPdf(false);
        });
      } else if (type == "excel") {
        handleExportExcelWallet(items, {
          number: account.number_identifier,
          balance: account.balance || data?.balance,
          document: account.document,
          name: account.name,
        }).finally(() => setIsExportingExcel(false));
      }
    } catch (err) {
      setIsExportingPdf(false);
      setIsExportingExcel(false)
    }
  }

  function getType(type: string): string {
    if (type === "PIX") {
      return "Pix";
    }

    if (type === "TED") {
      return "Transferência";
    }

    if (type === "P2P") {
      return "Transferência";
    }

    if (type === "BILLET") {
      return "Boleto";
    }

    if (type === "PAYMENT") {
      return "Pagamento";
    }

    if (type === "INITIAL") {
      return "";
    }

    return type;
  }

  async function handlePrevious() {
    const page = currentPage - 1;
    if (page === 0) return;
    setCurrentPage(page);
  }

  async function handleNext() {
    const page = currentPage + 1;
    if (page > totalPages) return;
    setCurrentPage(page);
  }

  const dateFormat = (date: string | undefined): string => {
    // Formata a data para o formato desejado
    const formattedDate = format(new Date(date || ""), "dd/MM/yyyy HH:mm", {
      locale: ptBR,
    });
    return formattedDate;
  };

  const startIdx = (currentPage - 1) * perPage;
  const endIdx = startIdx + perPage;
  const paginatedItems = items.slice(startIdx, endIdx);

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      {id ? (
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
          <nav className="space-y-1">
            <Link
              to={`/backoffice/accounts/wallet/${id}`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
              <span className="truncate">Dados gerais</span>
            </Link>
            <Link
              to={`/backoffice/accounts/${id}/extract`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
              <span className="truncate">Transações (Extrato)</span>
            </Link>
            <Link
              to={`/backoffice/accounts/wallet/${id}/tax`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
              <span className="truncate">Limites operacionais</span>
            </Link>
            <Link
              to={`/backoffice/upload/delbank/${id}`}
              className={classNames(
                "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
            >
            <span className="truncate">Envio de Documentos</span>
          </Link>
          </nav>
        </aside>
      ) : (
        <></>
      )}
      <div
        className={classNames(
          "space-y-6 sm:px-6 lg:px-0 w-ful",
          "lg:col-span-10"
        )}
      >
        <section aria-labelledby="payment-details-heading">
          <>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 sm:p-6">
                <Skeleton isActive={loading} repeat={3} />
                {!loading && (
                  <>
                    <div>
                      <h2
                        id="payment-details-heading"
                        className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                      >
                        Extrato
                      </h2>
                      <p className="mt-1 text-sm text-gray-500">
                        {/* Update your billing information. Please note that updating your location could affect your tax
                          rates. */}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-xl">Período</h3>
                      <span>Selecione um período para filtrar</span>
                      <div className="flex w-40">
                        <DatePicker
                          className="bg-gray-300 rounded px-2 py-1"
                          selected={startDate}
                          onChange={(date: Date) => setStartDate(date)}
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                        <span className="px-2 py-1">até</span>
                        <DatePicker
                          className="bg-gray-300 rounded px-2 py-1"
                          selected={endDate}
                          onChange={(date: Date) => setEndDate(date)}
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                        <button
                          className="ml-2 bg-yellow-600 text-white px-4 py-2 rounded-md text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => loadTransfers(1)}
                        >
                          Filtrar
                        </button>
                      </div>
                    </div>
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto"></div>
                      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                          type="button"
                          onClick={handleClickExportPDF}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-3"
                        >
                          {isExportingPdf ? "Exportando..." : "Exportar PDF"}
                        </button>

                        <button
                          type="button"
                          onClick={handleClickExportExcel}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                          {isExportingExcel ? "Exportando..." : "Exportar Excel"}
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Data
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Tipo
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Transação
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Beneficiário
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Valor
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Imprimir
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {!loading &&
                                  paginatedItems.map((item) => {
                                    const names = getTransactionNames(item);

                                    if (
                                      item.status == "waiting" ||
                                      item.status == "PENDING"
                                    )
                                      return null;

                                    console.log(item);

                                    if (
                                      item.direction === "out" &&
                                      item.type === "p2p_transfer" &&
                                      item.title ===
                                        "Transferência para P2P Ajiopay"
                                    ) {
                                      return null; // Ignora esta transação
                                    }
                                    return (
                                      <tr key={item.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 text-sm">
                                          {dateFormat(item.created_at)}
                                        </td>
                                        <td className="px-3 py-4 text-sm">
                                          {item.direction === "in"
                                            ? "Crédito"
                                            : "Débito"}
                                        </td>
                                        <td className="px-3 py-4 text-sm">
                                          {transactionLabel(item)}
                                        </td>
                                        <td className="px-1 py-4 text-sm">
                                          {item.direction == "in"
                                            ? `De ${names.payerName}`
                                            : `Para ${names.beneficiaryName}`}
                                        </td>
                                        <td className="px-3 py-4 text-sm">
                                          <span
                                            style={{
                                              color:
                                                item.direction === "in"
                                                  ? "var(--green)"
                                                  : "var(--boxText)",
                                              marginLeft:
                                                item.direction === "in"
                                                  ? "8px"
                                                  : "0",
                                            }}
                                          >
                                            {currencyFormat(
                                              Number(item.amount)
                                            )}
                                          </span>
                                          <span
                                            style={{
                                              marginLeft: "20px",
                                              display:
                                                item.direction === "in"
                                                  ? "none"
                                                  : "inline",
                                            }}
                                          ></span>
                                        </td>
                                        <td className="px-6 py-4 text-sm">
                                          <PrintButtonIcon>
                                            <PrinterIcon
                                              onClick={() => {
                                                const receiptString =
                                                  JSON.stringify(item);

                                                localStorage.setItem(
                                                  "temp_receipt_to_print",
                                                  receiptString
                                                );

                                                window.open(
                                                  `${config.FRONTEND_URL}/PDF`
                                                );
                                              }}
                                              color="var(--boxText)"
                                              className="h-4 w-4 inline"
                                            />
                                          </PrintButtonIcon>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                            <div className="flex items-center justify-center mt-5 mb-5">
                              <ChevronDoubleLeftIcon
                                onClick={handlePrevious}
                                className="mr-3 cursor-pointer w-5 h-5 text-gray-500"
                              />
                              <span>
                                Página {currentPage} de {totalPages}
                              </span>
                              <ChevronDoubleRightIcon
                                onClick={handleNext}
                                className="ml-3 cursor-pointer w-5 h-5 text-gray-500"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              aria-live="assertive"
              className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
              <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                  show={show}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-sm font-medium text-gray-900">
                            Configurações salvar
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            As configurações de taxas foram alteradas.
                          </p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <button
                            type="button"
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </>
        </section>
      </div>
    </div>
  );
}

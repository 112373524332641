import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StepHeader } from "../../components/stepHeader";
import { CardActionOption } from "../../components/cardActionOption";
import { Skeleton } from "../../components/skeleton";

import {
  BillIcon,
  ButtonIcon,
  Container,
  Content,
  ContentWrapper,
  PrintButtonIcon,
  RecentBilletsTitle,
  WrapperHeader,
} from "./styles";

import { currencyFormat } from "../../utils/currencyFormat";
import { service } from "../../services/ajio";
import { Table, TableWrapper } from "../receipt/styles";
import { Menu, Transition } from "@headlessui/react";
import { PrinterIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CardContainer } from "../billet/styles";
import { useNotification } from "../../hooks/notification";
import { BankSlip } from "./details";

export const Billet: React.FC = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<BankSlip[]>([]);
  const [billetStatus, setBilletStatus] = useState("pending"); // Pode ser "emitido" ou "pago"

  const handleTabClick = (status: React.SetStateAction<string>) => {
    setBilletStatus(status);
  };
  const { setOpenNotification } = useNotification();

  useEffect(() => {
    setLoading(true);

    service.get(`bank-slips/all-slips`).then(({ data }) => {
      setItems(data);
      setLoading(false);
    });
  }, []);

  //
  const sourceIn = (pdf: any, data: any) => {
    console.log(data);

    const { name, document, bank_name } = data.detail.payer_payee?.bank_account;
    const { name: userName, document: userDocument } = data.user;

    pdf.setFontSize(18);
    pdf.text("Dados do favorecido", 10, 80);

    pdf.setFontSize(12);
    pdf.text("Nome: " + name, 10, 90);
    pdf.text("Documento: " + document, 10, 100);
    pdf.text("Instituição: " + bank_name, 10, 110);

    pdf.setFontSize(18);
    pdf.text("Dados da conta debitada", 10, 120);

    pdf.setFontSize(12);
    pdf.text("Nome: " + userName, 10, 130);
    pdf.text("Documento: " + userDocument, 10, 140);

    // pdf.setFontSize(12);
    // pdf.text("Instituição:", 10, 150);
    return pdf;
  };

  const sourceOut = (pdf: any, data: any) => {
    console.log(data);

    const { name, document, bank_name } = data.detail.payer_payee?.bank_account;
    const { name: userName, document: userDocument } = data.user;

    pdf.setFontSize(18);
    pdf.text("Dados do favorecido", 10, 80);

    pdf.setFontSize(12);
    pdf.text("Nome: " + userName, 10, 90);
    pdf.text("Documento: " + userDocument, 10, 100);
    pdf.setFontSize(12);
    pdf.text("intermediador: Conebanx", 10, 110);
    pdf.setFontSize(18);
    pdf.text("Dados da conta debitada", 10, 130);

    pdf.setFontSize(12);
    pdf.text("Nome: " + name, 10, 140);
    pdf.text("Documento: " + document, 10, 150);
    pdf.text("Instituição: " + bank_name, 10, 160);

    return pdf;
  };

  //----------------------------------------------------
  const handleGeneratePDF = async (data: BankSlip) => {
    navigate("/billets/print", { state: data });

    // if (data.status !== "done") {
    //   setOpenNotification({
    //     type: "error",
    //     title: "Aguardando criação do boleto",
    //     notification: "",
    //   });
    //   return;
    // }

    // console.log(data);

    // const decodedData = atob(
    //   data.qrcode64.replace("data:image/png;base64,", "")
    // );

    // const byteArray = new Uint8Array(decodedData.length);

    // for (let i = 0; i < decodedData.length; i++) {
    //   byteArray[i] = decodedData.charCodeAt(i);
    // }

    // Criar o Blob com o array de bytes
    // const blob = new Blob([byteArray]);
    // const link = document.createElement("a");
    // link.href = window.URL.createObjectURL(blob);
    // link.download = `boleto-${data.id}.png`;
    // link.click();
  };

  const dateFormat = (date: string | undefined): string => {
    // Formata a data para o formato desejado
    const formattedDate = format(
      new Date(date || ""),
      "EEEE, dd 'de' MMMM yyyy HH:mm",
      { locale: ptBR }
    );
    return formattedDate;
  };

  const currencyFormat = (amount: number | string) => {
    // Formata o valor para o formato de moeda brasileira (R$)
    const formattedAmount = amount.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formattedAmount;
  };

  // const getTransferType = (type: string): string => {
  //   // Verificar o tipo do objeto item
  //   if ("type" in item) {
  //     // Se a propriedade "type" existe, retornar o valor dela
  //     return item.type;
  //   } else {
  //     // Caso contrário, retornar uma string vazia ou outro valor padrão
  //     return "";
  //   }
  // };

  function getType(type: string): string {
    if (type === "PIX") {
      return "Pix";
    }

    if (type === "TED") {
      return "Transferência";
    }

    if (type === "P2P") {
      return "Transferência";
    }

    if (type === "BILLET") {
      return "Boleto";
    }

    if (type === "PAYMENT") {
      return "Pagamento";
    }

    if (type === "INITIAL") {
      return "";
    }

    return type;
  }

  const setStatusMessage = (status: string) => {
    switch (status) {
      case "OPEN":
        return "Em aberto";
      case "EXPIRED":
        return "Expirado";
      case "PAID":
        return "Pago";
    }
  };

  // ----------------------------------
  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
            <StepHeader handleGoBackFunction={handleGoBack} title="Boletos" />
          </WrapperHeader>
          <CardContainer>
            <CardActionOption
              linkTo="create"
              Icon={BillIcon}
              title={`Gerar boleto`}
            />
            <CardActionOption
              linkTo="billets/waiting"
              Icon={BillIcon}
              title={`Boletos em aberto`}
            />
            <CardActionOption
              linkTo="billets/paid"
              Icon={BillIcon}
              title={`Boletos pagos`}
            />
          </CardContainer>

          <RecentBilletsTitle>Últimos boletos</RecentBilletsTitle>

          <Table>
            <div className="flex justify-between items-center mb-5">
              <div className="flex flex-end">
                <Menu as="div" className="relative inline-block text-left">
                  <Transition
                    //    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => <span>PDF</span>}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <TableWrapper>
              {!loading && items.length <= 0 && (
                <div className="p-6">
                  <div className="text-center">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Nenhum lançamento para o período selecionado
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Selecione outro período ou gere um Boleto e comece a usar
                      agora mesmo
                    </p>
                  </div>
                </div>
              )}

              <Skeleton space={4} isActive={loading} repeat={5} />

              <table>
                <tbody>
                  {!loading && items.length > 0 && (
                    <tr>
                      <th>Data</th>
                      <th>Pagador</th>
                      <th>Valor</th>
                      <th>Status</th>
                      <th>Vencimento</th>
                      <th style={{ paddingLeft: "15px" }}>Imprimir</th>
                    </tr>
                  )}

                  {!loading &&
                    items.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          onClick={() => {
                            // showTransaction(item as Transaction)
                          }}
                        >
                          <td>{dateFormat(item.created_at)}</td>

                          <td>{item?.data?.payer?.name}</td>
                          <td>
                            <span
                              style={{
                                color: "var(--green)",
                              }}
                            >
                              {currencyFormat(Number(item.amount))}
                            </span>

                            <span
                              style={{
                                marginLeft: "25px",
                                display: "inline",
                              }}
                            ></span>
                          </td>
                          <td>{setStatusMessage(item.status)}</td>
                          <td>
                            {dateFormat(
                              new Date(
                                new Date(item.due_date).getTime() +
                                  24 * 60 * 60 * 1000
                              ).toISOString()
                            )}
                          </td>
                          <td>
                            {/* // onClick={() => handleGeneratePDF(item)} */}
                            <PrintButtonIcon>
                              <PrinterIcon
                                onClick={() => handleGeneratePDF(item)}
                                color="var(--boxText)"
                                className="h-4 w-4 inline"
                              />
                            </PrintButtonIcon>
                            {/* <ButtonIcon onClick={() => handleGeneratePDF(item)} style={{ paddingRight: "10px", gap: "12px" }}>
                            Imprimir
                            <PrinterIcon
                                color="var(--white)"
                                className="h-4 w-4 inline"
                              />                              
                           </ButtonIcon> */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

import styled, { css, keyframes } from "styled-components";
import { ReactComponent as Icon } from "../../assets/BR.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconAr } from "../../assets/AR.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconEu } from "../../assets/EU.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconUs } from "../../assets/US.svg"; // Certifique-se de substituir o caminho correto para o seu ícone

import { ReactComponent as IconAU } from "../../assets/AU.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconCA } from "../../assets/CA.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconCH } from "../../assets/CH.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconCN } from "../../assets/CN.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconJP } from "../../assets/JP.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import { ReactComponent as IconGB } from "../../assets/GB.svg"; // Certifique-se de substituir o caminho correto para o seu ícone
import ParaguayFlag from "../../assets/paraguay.png";
import UruguaiFlag from "../../assets/uruguay.png";

type InnerDivProps = {
  isPaused: boolean;
  progress: number; // Certifique-se de que 'progress' está definido corretamente aqui
};

const slideAnimation = keyframes`
  0% {
    transform: translateX(1500vw);
  }
  100% {
    transform: translateX(-1500vw);
  }
`;

export const calculateAnimation = (progress: number, isPaused: boolean) => {
  const animationDuration = 750; // Duração da animação em segundos

  return css`
    ${slideAnimation} ${animationDuration}s linear infinite;
    animation-play-state: ${isPaused ? "paused" : "running"};
    animation-delay: 3s;
  `;
};

export const Container = styled.div<{ userType: string }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  //margin-top: 3px;
  //margin-right: 15%;

  max-height: 10vh;
  max-width: 100vw;
  ${(props) =>
    props.userType === "GRAPHIC"
      ? `margin-left: 3px; width: 100vw;`
      : `margin-rigth: 10px; width: 100vw;`};
  //border: 2px solid green;
  gap: 10px;
  overflow: hidden;
  //border: 2px solid red;
  //border-radius: 5px;
`;

const InnerDiv = styled.div<InnerDivProps>`
  border: 2px solid red;
  width: 100%;
  height: 60px;
  animation: ${({ progress, isPaused }) =>
    calculateAnimation(progress, isPaused)};
`;

export const StyledSpan = styled.span`
  --ui-lib-typography-font-size: 13px;
  --ui-lib-typography-line-height: 1.5;
  position: relative;
  bottom: -17px;
  color: #6a6d78;
  display: inline-block;
  font-size: var(--ui-lib-typography-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ui-lib-typography-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  left: 0 auto;
  top: -13px;
`;

export const StyledSpanDois = styled.span`
  --ui-lib-typography-font-size: 13px;
  --ui-lib-typography-line-height: 1.5;
  position: relative;
  bottom: -7px;
  color: blue;
  display: inline-block;
  font-size: var(--ui-lib-typography-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ui-lib-typography-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  left: 50px;
  top: 10px;
`;

export const StyledSpanTres = styled.span`
  --ui-lib-typography-font-size: 13px;
  --ui-lib-typography-line-height: 1.5;
  position: relative;
  bottom: -7px;
  color: #ff0013;
  display: inline-block;
  font-size: var(--ui-lib-typography-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ui-lib-typography-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  left: 55px;
  top: -3px;
  z-index: 3;
`;

export const IconBrStyled = styled(Icon)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 7px; // Ajuste conforme necessário
  left: 10px; // Ajuste conforme necessário
  z-index: 1; // Certifique-se de que este ícone esteja sobre o outro
`;

export const IconArStyled = styled(IconAr)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconEuStyled = styled(IconEu)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconUsStyled = styled(IconUs)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconCaStyled = styled(IconCA)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconAuStyled = styled(IconAU)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconChStyled = styled(IconCH)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconCnStyled = styled(IconCN)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconGbStyled = styled(IconGB)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconJpStyled = styled(IconJP)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const IconPyStyled = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
  background-image: url(${ParaguayFlag});
`;

export const IconUrStyled = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
  background-image: url(${UruguaiFlag});
`;

export const StyledImage = styled.div`
  width: 110%;
  height: 110%;
  background-size: cover;
  background-position: center;
`;

export const FaviconStyled = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  top: 13px; // Ajuste conforme necessário
  left: -3px; // Ajuste conforme necessário
  z-index: 2; // Certifique-se de que este ícone esteja acima do outro
`;

export const ColorDiv1 = styled(InnerDiv)`
  background-color: transparent;
`;

export const ColorDiv = styled.div<InnerDivProps>`
  background-color: transparent;
  // border: 2px solid black;
  min-width: 180px !important;
  height: 60px;
  animation: ${({ progress, isPaused }) =>
    calculateAnimation(progress, isPaused)};
`;

export const CustomDiv = styled.div<InnerDivProps>`
  width: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${({ progress, isPaused }) =>
    calculateAnimation(progress, isPaused)};
  gap: 3px;
  padding: 10px;
`;

// Opção 1
export const VerticalLine = styled.div`
  width: 2px;
  border: 1px solid #6a6d78;
  height: 30px;
  z-index: 4;
`;

import styled from "styled-components";


export const ToogleDiv = styled.div`
  flex: 1;
  margin-left: 0 auto;
  //border: 1px solid black;
  //border-radius: 2px;
  width: 5rem;
  padding: 0 auto;
`;

export function currencyFormat(currency: number): string {
  if (currency === undefined) {
    return "***";
  };

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
    }).format(currency);
}


//-------------------
export function currencyFormatBalance(currency: number): string {
  if (currency === undefined) {
    return '***';
  }

  // Se o saldo for negativo, retorna 'R$ 0,00'
  if (currency < 0) {
    return 'R$ 0,00';
  }

  // Caso contrário, formata o saldo normalmente
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(currency);
}
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { useNotification } from "../../hooks/notification";
import { service, serviceError } from "../../services/ajio";
import {
  ButtonsWrapper,
  Container,
  Form,
  FormItem,
  FormWrapper,
  WrapperHeader,
  ActionText,
  Input,
  WrapperInputs,
} from "./styles";
import { ExclamationIcon } from "@heroicons/react/outline";
import SelectInput from "../../components/SelectInput";

export const MobileRecharger: React.FC = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedKey, setSelectedKey] = useState("Selecione");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [operator, setOperator] = useState("");

  const operatorMapping: Record<string, string> = {
    Vivo: "001",
    Claro: "002",
    Oi: "003",
    Tim: "004",
  };

  const handleNextButton = async () => {
    const formattedcell = phoneNumber.replace(/-/g, "");
    let textoSemParenteses = countryCode.replace(/\(|\)/g, "");
    setLoading(true)
    try {
      const { data } = await service.post("phone-recharges", {
        dealer_code: operatorMapping[operator],
        area_code: textoSemParenteses,
        phone_number: formattedcell,
      });

      setLoading(false);
      navigation("/mobileRecharger/confirm", {
        state: { ...data },
      });
    } catch (err) {
      setLoading(false);
      const error = serviceError(err);
      setError(error.friend);
    }
  };

  const handleKeySelect = (option: string) => {
    setSelectedKey(option);
    setOperator(option);
  };

  const handleGoBack = () => {
    navigation(-1);
  };

  const handleCancelButton = () => {
    navigation("/");
  };

  const formatCountryCode = (input: any) => {
    const countryCode = input.replace(/\D/g, "").substring(0, 2); // First two digits
    return countryCode.length < 2 ? `(${countryCode}` : `(${countryCode})`;
  };

  const formatPhoneNumber = (input: any) => {
    const phoneNumber = input.replace(/\D/g, "").substring(0, 9); // First nine digits
    const firstPart = phoneNumber.substring(0, 5);
    const secondPart = phoneNumber.substring(5);
    return `${firstPart}-${secondPart}`;
  };

  return (
    <Container>
      <WrapperHeader>
        <StepHeader
          handleGoBackFunction={handleGoBack}
          title="Recarga de celular"
        />
      </WrapperHeader>
      <Form>
        <FormWrapper>
          <FormItem>
            <ActionText>Telefone</ActionText>
            <WrapperInputs>
              <Input
                className="ddd"
                value={countryCode}
                onChange={(e) =>
                  setCountryCode(formatCountryCode(e.target.value))
                }
              />
              <Input
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(formatPhoneNumber(e.target.value))
                }
              />
            </WrapperInputs>
          </FormItem>
          <FormItem>
            <ActionText>Operadora</ActionText>
            <SelectInput
              placeholderColor={"#B5B7BD"}
              borderColor={"#E3E3E3"}
              optionSelected={selectedKey}
              handleFunction={handleKeySelect}
              options={["Vivo", "Claro", "Oi", "Tim"]}
            />
          </FormItem>
        </FormWrapper>
        {!!error && (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">{error}</p>
              </div>
            </div>
          </div>
        )}
        <ButtonsWrapper>
          <Button
            color="#e3e3e3"
            title="Cancelar"
            handleFunction={handleCancelButton}
          />
          <Button
            type="button"
            isLoading={loading}
            handleFunction={handleNextButton}
            title="Continuar"
          />
        </ButtonsWrapper>
      </Form>
    </Container>
  );
};

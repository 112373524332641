import { FC } from "react";
import { ArrowLeft } from "phosphor-react";
import { useNavigate } from "react-router-dom";

import VilapayLogoPNG from "../../assets/login-logo.png";

export const Success: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background max-2xl:gap-0">
      <svg style={{ position: "relative", top: 0 }} width="91vw" height="90vh">
        <defs>
          <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#2E2E2E" />
            <stop offset="25%" stopColor="#FFFFFF" />
            <stop offset="75%" stopColor="#274683" />
          </linearGradient>
        </defs>

        <rect
          x="10"
          y="10"
          width="90vw"
          height="85vh"
          rx="10"
          ry="10"
          fill="black"
          fillOpacity="0.4"
          stroke="url(#borderGradient)"
          strokeWidth="1"
        />
      </svg>

      <div className="absolute top-[13vh] flex flex-col items-center justify-center">
        <img className="max-2xl:w-[55%]" width={300} src={VilapayLogoPNG} alt="" />

        <h2 className="text-white text-4xl text-center font-semibold mt-4 max-2xl:text-2xl">
          Tudo <strong className="text-[#4BBD9C] text-4xl max-2xl:text-xl text-center font-semibold">certo</strong> com
          as <br />
          informações!
        </h2>
        <p className="text-white text-3xl text-center font-extralight mt-8 max-2xl:text-xl max-2xl:mt-2">
          Sua solicitação está em análise, em até <br />
          72h úteis você receberá um e-mail <br />
          informando sobre a confirmação de <br />
          abertura da{" "}
          <strong className="text-white text-3xl text-center font-extrabold max-2xl:text-xl">sua conta PIXWAVE</strong>
        </p>

        <div className="open-wallet-button mt-8">
          <button
            onClick={() => navigate("/signin")}
            className="inner-open-wallet-button font-extralight text-white text-xl flex items-center justify-center gap-3 bg-[#274683] w-[30rem] h-16 max-2xl:h-12 max-2xl:w-[20rem] max-2xl:text-base"
          >
            <ArrowLeft size={24} />
            Voltar para Login
          </button>
        </div>
      </div>
    </div>
  );
};

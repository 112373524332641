import { Minus, Plus } from "phosphor-react";
import { FC, useState } from "react";

interface ExpansiveButtonProps {
  text: string;
  title: string;
}

export const ExpansiveButton: FC<ExpansiveButtonProps> = ({ text, title }) => {
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div className={`overflow-hidden mb-6 ${isExpand ? "h-auto" : "h-28"}`}>
      <button
        onClick={() => setIsExpand(!isExpand)}
        className="w-full flex justify-between items-center px-10 bg-[#FFF3E5] h-28 rounded-2xl"
      >
        <h5 className="font-bold text-black text-2xl">{title}</h5>

        <div className="w-10 h-10 rounded-full bg-[#274683] flex items-center justify-center">
          {!isExpand ? <Plus weight="bold" color="white" size={32} /> : <Minus weight="bold" color="white" size={32} />}
        </div>
      </button>
      <div className="mt-5 w-full mb-3 px-8">
        <p className="text-xl">{text}</p>
      </div>
    </div>
  );
};

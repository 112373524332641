export const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        // Remover a parte "data:image/png;base64," da string Base64
        let base64String = "";

        if (file.name.endsWith(".pdf")) {
          base64String = reader.result.replace(
            /^data:application\/[a-z]+;base64,/,
            ""
          );
        } else {
          base64String = reader.result.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          );
        }

        resolve(base64String);
      } else {
        reject(new Error("Failed to convert image to Base64."));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

import { FC } from "react";

interface GradientButtonProps {
  title: string;
  tailwindStyles: string;
  isLoading?: boolean;
  onClick: () => void;
}

const Spinner: FC = () => {
  return (
    <svg className="animate-spin mx-auto h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const GradientButton: FC<GradientButtonProps> = ({ tailwindStyles, title, isLoading = false, onClick }) => {
  return (
    <div className="open-wallet-button flex justify-center items-center">
      <button disabled={isLoading} onClick={onClick} className={`inner-open-wallet-button ${tailwindStyles}`}>
        {isLoading ? <Spinner /> : title}
      </button>
    </div>
  );
};

import { FC, useMemo, useState } from "react";
import { ChevronRightCircle, MessageSquareIcon, Wifi } from "lucide-react";
import "./styles.css";
import { ChatModal } from "./ChatModal";
import { api } from "src/services/api";
import { useAuth } from "src/hooks/auth";
import { Loading } from "src/components/loading";
import { useNotification } from "src/hooks/notification";

const headerHeight = 76;

export default function How() {
  const [chatIsOpen, setChatIsOpen] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [createdTicketId, setCreatedTicketId] = useState("");

  const [problem, setProblem] = useState("");

  const { user } = useAuth();

  const token = localStorage.getItem("@stricv2:token");

  const height = useMemo(() => window.innerHeight - headerHeight, [window.innerHeight, window]);

  const { setOpenNotification } = useNotification();

  const createTicket = async (p: string) => {
    try {
      setLoadingTicket(true);
      const response = await api.post(
        "/tickets",
        {
          title: p,
          description: `${user.name} abriu um ticket`,
          origem: "",
          type: "",
          category: "",
          applicant: user.id,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setCreatedTicketId(response.data?.id);
      setChatIsOpen(true);
    } catch (error: any) {
      const msg = error?.response?.data?.message || "Algo deu errado...";

      setOpenNotification({
        notification: msg,
        title: "Ops...",
        type: "error",
      });

      console.log(error);
    }
    setLoadingTicket(false);
  };

  return (
    <div style={{ height }} className="flex flex-col gap-2 p-14">
      {chatIsOpen && (
        <div
          onClick={(e) => {
            setChatIsOpen(false);
          }}
          className="flex items-center justify-center fixed z-50 top-0 left-0 w-screen h-screen bg-black bg-opacity-70"
        >
          <ChatModal selectedId={createdTicketId} />
        </div>
      )}

      {loadingTicket && (
        <div
          onClick={(e) => {
            setChatIsOpen(false);
          }}
          className="flex items-center justify-center fixed z-50 top-0 left-0 w-screen h-screen bg-black bg-opacity-70"
        >
          <div className="flex flex-col gap-4 items-center bg-zinc-100 p-8 rounded-lg">
            <span className="font-semibold text-center text-xl text-zinc-800">
              Aguarde, já vamos te direcionar
              <br /> para um especialista
            </span>
            <Loading isLoading style={{ color: "#274683", height: 40, width: 40 }} />
          </div>
        </div>
      )}
      <span className="text-4xl font-bold text-zinc-800">Como podemos te ajudar?</span>
      <span className="text-lg font-bold text-zinc-600">
        Selecione uma opção para iniciar a conversa com um especialista!
      </span>
      <button
        onClick={() => setChatIsOpen(true)}
        className="flex self-start items-center gap-2 bg-[#274683] rounded-md shadow-lg px-3 p-2"
      >
        <span className="text-white font-medium">Chats abertos</span>
        <MessageSquareIcon strokeWidth={2.5} size={22} color="#fff" />
      </button>
      <div className="grid grid-cols-3 2xl:grid-cols-4 pt-5 gap-6">
        {problems.map((p, index) => (
          <Block title={p} key={index} onClick={() => createTicket(p)} />
        ))}
      </div>
    </div>
  );
}

const Block: FC<{ title: string; onClick: () => void }> = ({ title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer bg-gradient-to-br from-zinc-50 to-zinc-200 hover:from-zinc-100 active:from-zinc-200 rounded-xl border-4 border-[#274683] flex justify-between items-center px-5 py-8 gap-4"
    >
      <span className="text-xl font-bold text-zinc-800">{title}</span>
      <ChevronRightCircle color="#274683" size={38} />
    </div>
  );
};

const problems = [
  "Problemas com PIX",
  "Problemas ao pagar conta",
  "Problemas com transferências",
  "Problemas gerais da conta",
  "Problemas com Boleto",
  "Gostaria de tirar uma dúvida!",
  "Outros problemas",
];

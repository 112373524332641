import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { Flex, Button } from "antd";
import DatePicker from "react-datepicker";
import { api } from "src/services/api";

interface CountTransactionsData {
  allTransactions: number;
  transactions: number;
  graphicTransactions: number;
  tax: number;
  graphicTax: number;
  allTaxes: number;
  transactionsWithNullNsu: number;
  graphicTransactionsWithNullNsu: number;
  allSuspiciousTransactions: number;
}

const CountTransactionsPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [countData, setCountData] = useState<CountTransactionsData | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const token = window.localStorage.getItem("@backoffice:token");

  const fetchData = async (startDate: Date | null, endDate: Date | null) => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.get<CountTransactionsData>("/backoffice/all/count/transactions/tax", {
        headers: { Authorization: "Bearer " + token },
        params: {
          startDate: startDate ? format(startDate, "yyyy-MM-dd") : undefined,
          endDate: endDate ? format(endDate, "yyyy-MM-dd") : undefined,
        },
      });

      setCountData(response.data);
    } catch (error) {
      console.error("Error fetching count data:", error);
      setError("Erro ao carregar os dados de contagem de transações.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(startDate, endDate);
  }, []);

  const fetchCustomDateTransactions = () => {
    fetchData(startDate, endDate);
  };

  return (
    <div className="flex flex-col w-full max-w-4xl mx-auto px-4 py-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Resumo de Contagem de Transações e Taxas</h2>

      <Flex align="center" gap={10} style={{ width: "max-content", marginBottom: "20px" }}>
        <DatePicker
          clearButtonTitle="Limpar"
          dateFormat="dd/MM/yyyy"
          selected={startDate}
          maxDate={endDate}
          onChange={(date) => setStartDate(date)}
        />
        <span>Até</span>
        <DatePicker
          selected={endDate}
          clearButtonTitle="Limpar"
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          onChange={(date) => setEndDate(date)}
        />
        <Button
          style={{
            color: "white",
            background: "#274683",
            width: "100%",
          }}
          onClick={fetchCustomDateTransactions}
        >
          Filtrar
        </Button>
      </Flex>

      {loading && <div>Carregando...</div>}
      {error && <div>Erro: {error}</div>}
      {!loading && !error && countData && (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Descrição
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total de Transações</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{countData.allTransactions}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  Transações Conta Corrente
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{countData.transactions}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  Transações Conta Wallet
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{countData.graphicTransactions}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total de Taxas</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{countData.allTaxes}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Taxas Conta Corrente</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{countData.tax}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Taxas Conta Wallet</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{countData.graphicTax}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  transações suspeitas em Conta Corrente
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {countData.transactionsWithNullNsu}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  transações suspeitas em Conta Wallet
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {countData.graphicTransactionsWithNullNsu}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  Total de transações suspeitas
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {countData.allSuspiciousTransactions}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startDate && endDate ? `${format(startDate, "dd/MM/yyyy")} - ${format(endDate, "dd/MM/yyyy")}` : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CountTransactionsPage;

import React, { useState, useEffect, FC } from "react";
import { Container, Content, ContentWrapper, Table, TableWrapper, Text } from "../Tax/styles";
import "./styles.css";
import { api } from "src/services/api";
import { useNotification } from "src/hooks/notification";
import Modal from "src/components/Modal";
import ShowRequestLimits from "src/assets/show-limits-eyes.png";
import AcepetRequestLimits from "src/assets/limits-confirm.png";
import DeinedRequestLimits from "src/assets/limits-deined.png";
import { GraphicAccount } from "src/pages/myLimits/graphic-account.interface";
import { dateFormat } from "src/utils/dateFormat";
import dateToLine from "src/utils/dateToLine";

export function RequestLimitsWalllet() {
  const [selectedMachines, setSelectedMachines] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [amount, setAmount] = useState<string>("");
  const [isRepassModalOpen, setIsRepassModalOpen] = useState<boolean>(false);
  const [observations, setObservations] = useState<string>("");
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  const [data, setData] = useState<GraphicAccount[]>();

  useEffect(() => {
    fetchPosDetails();
  }, []);

  const token = localStorage.getItem("@backoffice:token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchPosDetails = async () => {
    try {
      const response = await api.get("/graphic/fee-limits/change/request", config);

      setData(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Requisições de aumento de limite</span>
          </Text>
          {data && data.length > 0 ? (
            <table className="flex mt-4 bg-white rounded-lg shadow-lg p-4 w-full flex-col gap-2 text-center">
              <thead className="flex w-full flex-col">
                <tr className="font-semibold grid grid-cols-5 items-center h-20">
                  <th style={{ color: "#274683", padding: "10px" }}>Nome </th>
                  <th style={{ color: "#274683" }}>Conta</th>
                  <th style={{ color: "#274683" }}>Data da solicitação</th>
                  <th style={{ color: "#274683" }}>Valor de aumento solicitado</th>
                  <th style={{ color: "#274683" }}>Ação para o pedido</th>
                </tr>
              </thead>
              <tbody className="flex flex-col gap-4">
                {data.map((graphic, index) => (
                  <GraphicDetail
                    data={graphic}
                    key={graphic.id}
                    onPay={fetchPosDetails}
                    fetchPosDetails={fetchPosDetails}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mt-2">Não há requisições de aumento de limite no momento.</p>
          )}
        </ContentWrapper>
      </Content>
    </Container>
  );
}

// fazer um componente pra cada conta grafica

const GraphicDetail: FC<{
  data: GraphicAccount;
  onPay: () => void;
  fetchPosDetails: () => void;
}> = ({ data, onPay, fetchPosDetails }) => {
  const [selectedMachines, setSelectedMachines] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);

  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [selectedGraphicDetail, setSelectedGraphicDetail] = useState<GraphicAccount | null>(null);

  const token = localStorage.getItem("@backoffice:token");

  const { setOpenNotification } = useNotification();

  const handlePayMachines = async () => {
    try {
      let a = "a";

      if (a === "a") {
        setLoading(true);

        setOpenNotification({
          title: "Pago!",
          notification: `$data.payedTransactions transações processadas em $data.payedMachines maquininhas.`,
          type: "success",
        });
        onPay();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDetailModal = (graphicDetail: GraphicAccount) => {
    setSelectedGraphicDetail(graphicDetail);
    setIsDetailModalOpen(true);
  };

  const [modalDescriptionIsOpen, setModalDescriptionIsOpen] = useState(false);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  async function handleApproved(data: any) {
    const requestData = {
      graphicAccountId: data.id,
      feeLimitChangeRequestId: data.feeLimitChangeRequests[0].id,
    };
    try {
      const response = await api.post("/graphic/fee-limits/change/request/accept", requestData, config);
    } catch (error) {
      console.error("Erro ao enviar solicitação:", error);
    }

    fetchPosDetails();
  }

  async function handleRejected(data: any) {
    const requestData = {
      graphicAccountId: data.account_id,
      feeLimitChangeRequestId: data.feeLimitChangeRequests[0].id,
    };
    try {
      const response = await api.post("/graphic/fee-limits/change/request/deny", requestData, config);
    } catch (error) {
      console.error("Erro ao enviar solicitação:", error);
    }

    fetchPosDetails();
  }

  function formatMoney(value: number): string {
    console.log(value);
    if (typeof value !== "number" || isNaN(value)) {
      return "R$ 00,00";
    }

    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return (
    <>
      <tr
        className="grid grid-cols-5 items-center gap-4"
        key={data.id}
        // onClick={() => handleMachineSelect(data.machine)}
        style={{
          borderRadius: "10px",
        }}
      >
        <td className="text-sm font-semibold text-zinc-600">{data.name}</td>
        <td style={{ color: "#8A8A8A", textAlign: "center" }}>{data.role}</td>
        <td style={{ color: "#8A8A8A", textAlign: "center" }}>
          {/*R$ {toLocaleAmount(totalToPay)}*/}

          <span>{dateToLine(data.feeLimitChangeRequests[0].createdAt)}</span>
        </td>
        <td style={{ color: "#8A8A8A", textAlign: "center" }}>
          <button
            disabled={loading}
            className="p-2 w-full rounded-lg text-white flex items-center justify-between"
            onClick={() => handleOpenDetailModal(data)}
          >
            <span className="font-semibold" style={{ color: "#274683", marginRight: "8px" }}>
              Ver pedidos
            </span>
            <img src={ShowRequestLimits} alt="ver pedido de limite pendente" style={{ verticalAlign: "middle" }} />
          </button>
        </td>
        <td className="text-center rounded-md" style={{ textAlign: "center" }}>
          <div
            className="p-2 w-full rounded-lg text-white flex items-center justify-center"
            onClick={() => {
              setModalDescriptionIsOpen(true);
            }}
          >
            <button
              disabled={loading}
              className="p-2 w-full rounded-lg text-white flex items-center justify-center"
              onClick={() => {
                handleApproved(data);
                return setOpenNotification({
                  title: "APROVADO",
                  notification: `Limite aprovado com sucesso`,
                  type: "success",
                });
              }}
            >
              <img src={AcepetRequestLimits} alt="ver pedido de limite pendente" style={{ verticalAlign: "middle" }} />
              <span
                className="font-semibold"
                style={{
                  color: "#8A8A8A",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              >
                Aprovar
              </span>
            </button>

            <button
              disabled={loading}
              className="p-2 w-full rounded-lg text-white flex items-center justify-center"
              onClick={() => {
                handleRejected(data);

                return setOpenNotification({
                  title: "RECUSADO",
                  notification: `Limite recusado`,
                  type: "error",
                });
              }}
            >
              <img src={DeinedRequestLimits} alt="ver pedido de limite pendente" style={{ verticalAlign: "middle" }} />
              <span
                className="font-semibold"
                style={{
                  color: "#8A8A8A",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              >
                Negar
              </span>
            </button>
          </div>
        </td>
      </tr>

      {isDetailModalOpen && selectedGraphicDetail && (
        <Modal open={isDetailModalOpen} onClickClose={() => setIsDetailModalOpen(false)}>
          <div className="flex flex-col items-center p-8 w-[400px] gap-4">
            <span className="text-lg font-bold">Valores de aumento solicitado:</span>
            <div className="flex flex-col w-full gap-4">
              <div className="flex gap-4">
                <div className="flex flex-col w-full">
                  <label htmlFor="feePixIn" className="text-left">
                    Taxa Pix (Entrada):
                  </label>
                  <input
                    id="feePixIn"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].feePixIn))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="feePixOut" className="text-left">
                    Taxa Pix (Saída):
                  </label>
                  <input
                    id="feePixOut"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].feePixOut))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex flex-col w-full">
                  <label htmlFor="feeTedOut" className="text-left">
                    Taxa TED (Saída):
                  </label>
                  <input
                    id="feeTedOut"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].feeTedOut))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="feeP2pIn" className="text-left">
                    Taxa P2P (Entrada):
                  </label>
                  <input
                    id="feeP2pIn"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].feeP2pIn))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex flex-col w-full">
                  <label htmlFor="feeP2pOut" className="text-left">
                    Taxa P2P (Saída):
                  </label>
                  <input
                    id="feeP2pOut"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].feeP2pOut))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="feeBillet" className="text-left">
                    Taxa de Boleto:
                  </label>
                  <input
                    id="feeBillet"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].feeBillet))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex flex-col w-full">
                  <label htmlFor="limitDay" className="text-left">
                    Limite Diário:
                  </label>
                  <input
                    id="limitDay"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].limitDay))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="limitNightly" className="text-left">
                    Limite Noturno:
                  </label>
                  <input
                    id="limitNightly"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].limitNightly))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex flex-col w-full">
                  <label htmlFor="limitMonth" className="text-left">
                    Limite Mensal:
                  </label>
                  <input
                    id="limitMonth"
                    type="text"
                    value={formatMoney(Number(selectedGraphicDetail.feeLimitChangeRequests[0].limitMonth))}
                    readOnly
                    className="input-field text-gray-600"
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => setIsDetailModalOpen(false)}
              className="w-full h-12  rounded-md text-white"
              style={{ background: "#274683" }}
            >
              OK
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RequestLimitsWalllet;

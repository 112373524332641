import { useState, useRef, useEffect } from "react";
import {
  ColorDiv,
  Container,
  IconBrStyled,
  IconArStyled,
  IconUsStyled,
  IconEuStyled,
  IconJpStyled,
  IconGbStyled,
  StyledSpan,
  StyledSpanDois,
  StyledSpanTres,
  VerticalLine,
  CustomDiv,
  IconCnStyled,
  IconChStyled,
  IconAuStyled,
  IconCaStyled,
  IconPyStyled,
  IconUrStyled,
  FaviconStyled,
  StyledImage,
} from "./styles"; // Importe os componentes estilizados
import { ApiResponse, CurrencyData } from "../cardsTrade/types";
import React from "react";
import Paraguai from "../../assets/paraguay.png";
import Uruguay from "../../assets/uruguay.png";
import { useAuth } from "../../hooks/auth";

const Carousel = () => {
  const [numDivs, setNumDivs] = useState(10); // Número de divs a serem renderizadas
  const divWidth = 270; // Largura de cada div
  const containerWidth = divWidth * numDivs; // Largura total do container
  const { user } = useAuth();
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [animationRef, setAnimationRef] = useState<NodeJS.Timeout>();
  const [data, setData] = useState<ApiResponse>({} as ApiResponse);

  const moedas =
    "USD-BRL,USDT-BRL,EUR-BRL,BTC-BRL,ARS-BRL,JPY-BRL,GBP-BRL,AUD-BRL,CAD-BRL,CHF-BRL,CNY-BRL,PYG-BRL,UYU-BRL";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://economia.awesomeapi.com.br/last/${moedas}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData: ApiResponse = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container
      userType={user.type}
      onMouseEnter={() => {
        setPaused(true);
        clearInterval(animationRef);
      }}
      onMouseLeave={() => {
        setPaused(false);
        const interval = setInterval(() => {
          setProgress((prevProgress) => (prevProgress + 0.1) % 1);
        }, 100);
        setAnimationRef(interval);
      }}
    >
      {Array.from({ length: numDivs * 4 }).map((_, index) => (
        <React.Fragment key={index}>
          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <FaviconStyled>
              {" "}
              <StyledImage
                style={{ backgroundImage: `url(${Uruguay})` }}
              />{" "}
            </FaviconStyled>
            <StyledSpanDois>
              {" "}
              {data.UYUBRL?.code}
              {data.UYUBRL?.codein} R${data.UYUBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.UYUBRL?.pctChange}% {data.UYUBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.UYUBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <FaviconStyled>
              {" "}
              <StyledImage
                style={{ backgroundImage: `url(${Paraguai})` }}
              />{" "}
            </FaviconStyled>
            <StyledSpanDois>
              {" "}
              {data.PYGBRL?.code}
              {data.PYGBRL?.codein} R${data.PYGBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.PYGBRL?.pctChange}% {data.PYGBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.PYGBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconEuStyled />
            <StyledSpanDois>
              {" "}
              {data.EURBRL?.code}
              {data.EURBRL?.codein} R${data.EURBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.EURBRL?.pctChange}% {data.EURBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.EURBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconArStyled />
            <StyledSpanDois>
              {" "}
              {data.ARSBRL?.code}
              {data.ARSBRL?.codein} R${data.ARSBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.ARSBRL?.pctChange}% {data.ARSBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.ARSBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconUsStyled />
            <StyledSpanDois>
              {" "}
              {data.USDBRL?.code}
              {data.USDBRL?.codein} R${data.USDBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.USDBRL?.pctChange}% {data.USDBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.USDBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconJpStyled />
            <StyledSpanDois>
              {" "}
              {data.JPYBRL?.code}
              {data.JPYBRL?.codein} R${data.JPYBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.JPYBRL?.pctChange}% {data.JPYBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.JPYBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconGbStyled />
            <StyledSpanDois>
              {" "}
              {data.GBPBRL?.code}
              {data.GBPBRL?.codein} R${data.GBPBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.GBPBRL?.pctChange}% {data.GBPBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.GBPBRL?.name} </StyledSpan>
          </ColorDiv>
          {/*//------------*/}
          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconCnStyled />
            <StyledSpanDois>
              {" "}
              {data.CNYBRL?.code}
              {data.CNYBRL?.codein} R${data.CNYBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.CNYBRL?.pctChange}% {data.CNYBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.CNYBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconChStyled />
            <StyledSpanDois>
              {" "}
              {data.CHFBRL?.code}
              {data.CHFBRL?.codein} R${data.CHFBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.CHFBRL?.pctChange}% {data.CHFBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.CHFBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconAuStyled />
            <StyledSpanDois>
              {" "}
              {data.AUDBRL?.code}
              {data.AUDBRL?.codein} R${data.AUDBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.AUDBRL?.pctChange}% {data.AUDBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.AUDBRL?.name} </StyledSpan>
          </ColorDiv>

          <CustomDiv progress={progress} isPaused={paused}>
            {" "}
            <VerticalLine />{" "}
          </CustomDiv>

          <ColorDiv progress={progress} isPaused={paused}>
            <IconBrStyled />
            <IconCaStyled />
            <StyledSpanDois>
              {" "}
              {data.CADBRL?.code}
              {data.CADBRL?.codein} R${data.CADBRL?.bid}{" "}
            </StyledSpanDois>
            <StyledSpanTres>
              {" "}
              {data.CADBRL?.pctChange}% {data.CADBRL?.varBid}{" "}
            </StyledSpanTres>
            <StyledSpan> {data.CADBRL?.name} </StyledSpan>
          </ColorDiv>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default Carousel;

{
  /* Legendas 

key              Label
bid              Compra
ask              Venda
varBid           Variação
pctChange        Porcentagem de Variação 
high             Máximo       
low              Mínimo

*/
}

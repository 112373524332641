import { FC } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone: FC<{ onDrop: (files: File[]) => void; fileTitle?: string }> = ({
  onDrop,
  fileTitle,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className={`h-64 border border-dashed border-gray-200 flex justify-center items-center rounded-2xl ${
        isDragActive ? "border-blue-500" : ""
      }`}
    >
      <input {...getInputProps()} className="hidden" />
      <div className="text-center p-5">
        <p className="mb-2 text-gray-300">Arraste e solte os arquivos aqui</p>
        <p className="text-sm text-gray-400">
          ou clique para selecionar{" "}
          {fileTitle ? `o seu ${fileTitle}` : "arquivos"}
        </p>
      </div>
    </div>
  );
};

export default Dropzone;
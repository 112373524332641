import { FC } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";

import PhotoIdentificationSVG from "../../assets/IdentificationPhotoSVG.svg";

export const IdentificationAlert: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background max-2xl:gap-0">
      <PhoneFrame
        onPress={() => navigate("/create-account-wallet/send-identification-photo", { state: { accountType } })}
      />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="750" height="80vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="700"
            height="75vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[15vh] ml-14 flex flex-col justify-center items-center">
          <h1 className="font-bold text-white text-4xl max-2xl:text-xl">Identifique-se</h1>

          <p className="font-extralight text-white text-xl text-center mt-5 mb-8 max-2xl:mt-2 max-2xl:text-base">
            Para segurança do seu cadastro, será <br />
            necessário que você nos envie a foto <br />
            do <strong>{accountType === "PF" ? "seu RG" : "seu RG / CNH"}</strong> para prosseguir.
          </p>

          <img width={300} className="mb-8 ml-5 max-2xl:mb-0 max-2xl:w-[45%]" src={PhotoIdentificationSVG} alt="" />

          <div className="flex gap-4 mt-16">
            <GradientButton
              title="Voltar"
              tailwindStyles="bg-[#171717] text-white w-[17.7rem] h-12"
              onClick={() => navigate("/create-account-wallet/send-identification-photo", { state: { accountType } })}
            />
            <GradientButton
              title="Avançar"
              tailwindStyles="bg-[#274683] text-white w-[17.7rem] h-12"
              onClick={() => {
                navigate("/create-account-wallet/send-document-pj", { state: { accountType } });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

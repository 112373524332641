import React, { FC, useEffect, useState } from "react";
import {
  ContentWrapper,
  Container,
  Content,
  Table,
  TableWrapper,
  Text,
} from "./styles";
import { api } from "src/services/api";
import { X } from "lucide-react";
import { Plan } from "../ConfigTax";
import { useNotification } from "src/hooks/notification";

interface CreditCardMachine {
  id: string;
  serialNum: string;
  identificationCode: string;
  description: string;
  graphic_account_id: string;
  graphicAccount: {
    id: string;
    name: string;
    document: string;
    email: string;
    password_hash: string;
    balance: number;
    status: string;
    created_at: string;
    updated_at: string;
    user_id: string;
    account_id: string;
    number_identifier: string;
    access_token: string | null;
    pin: string;
    security_eletronic: string;
    status_pin_eletronic: boolean;
    blocked: boolean;
    counter: number;
    gender: string;
    status_master_user: boolean;
    id_master_user: string | null;
    birthDate: string;
    franchise_id: string;
    document_base64_front: string | null;
    document_base64_verse: string | null;
    document_base64_selfie: string | null;
    role: string;
    pin_wallet: string | null;
    parent_api_key: string;
    rg_cnh: string;
    planId: string;
    hash_reset_password: string | null;
    phone: {
      ddd: string;
      type: string;
      number: string;
    };
  };
}

export const ConfigPersons: FC = () => {
  const token = localStorage.getItem("@backoffice:token");

  const [cardMachines, setCardMachines] = useState<CreditCardMachine[]>([]);
  const [filteredCardMachines, setFilteredCardMachines] = useState<
    CreditCardMachine[]
  >([]);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUserName, setSelectedUserName] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [plans, setPlans] = useState<Plan[]>();

  console.log(plans);

  const [newMachine, setNewMachine] = useState<Partial<CreditCardMachine>>({
    serialNum: "",
    identificationCode: "",
    description: "",
    graphic_account_id: "",
  });
  const [selectedMachine, setSelectedMachine] =
    useState<CreditCardMachine | null>(null);
  const [showUserSelectionModal, setShowUserSelectionModal] = useState(false);
  const [showSelectPlanModal, setShowSelectPlanModal] = useState(false);

  useEffect(() => {
    fetchCardMachines();
    fetchUsers();
    fetchPlans();
  }, []);

  const { setOpenNotification } = useNotification();

  const fetchCardMachines = async () => {
    try {
      const { data } = await api.get<CreditCardMachine[]>(
        "/backoffice/card-machines",
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      setCardMachines(data);
      setFilteredCardMachines(data);
    } catch (error) {
      console.error("Error fetching card machines:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await api.get<CreditCardMachine[]>("/backoffice/users", {
        headers: { Authorization: "Bearer " + token },
      });
      setUsers(data);
    } catch (error) {
      console.error("Error fetching card machines:", error);
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await api.get<Plan[]>("/backoffice/pag-plans", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddMachine = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!selectedPlan)
        return setOpenNotification({
          notification: "",
          title: "Selecione um plano para a maquina",
          type: "error",
        });

      if (!selectedUserName)
        return setOpenNotification({
          notification: "",
          title: "Selecione um usuário para a maquina",
          type: "error",
        });

      const token = localStorage.getItem("@backoffice:token");
      await api.post(
        "/backoffice/card-machines",
        {
          ...newMachine,
          machinePlanId: selectedPlan.id,
          graphic_account_id: selectedUserId,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      fetchCardMachines();
      setNewMachine({
        serialNum: "",
        identificationCode: "",
        description: "",
        graphic_account_id: "",
      });
    } catch (error) {
      setOpenNotification({
        notification: "Essa maquininha já está cadastrada.",
        title: "Algo deu errado",
        type: "error",
      });
      console.error("Error adding card machine:", error);
    }
  };

  const handleEditMachine = (machine: CreditCardMachine) => {
    setSelectedMachine(machine);
    setNewMachine(machine);
  };

  const handleUpdateMachine = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("@backoffice:token");
      if (selectedMachine) {
        await api.patch(
          `/backoffice/card-machines/${selectedMachine.id}`,
          selectedMachine,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
        fetchCardMachines();
        setSelectedMachine(null);
        setNewMachine({
          serialNum: "",
          identificationCode: "",
          description: "",
          graphic_account_id: "",
        });
      } else {
        await api.post(
          "/backoffice/card-machines",
          {
            ...newMachine,
            serialNum: newMachine.serialNum?.trim(),
            graphic_account_id: selectedUserId,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
        fetchCardMachines();
        setNewMachine({
          serialNum: "",
          identificationCode: "",
          description: "",
          graphic_account_id: "",
        });
      }
    } catch (error) {
      setOpenNotification({
        notification: "Essa maquininha já está cadastrada.",
        title: "Algo deu errado",
        type: "error",
      });
      console.error("Error updating card machine:", error);
    }
  };

  const handleDeleteMachine = async (id: string) => {
    try {
      const token = localStorage.getItem("@backoffice:token");
      await api.delete(`/backoffice/card-machines/${id}`, {
        headers: { Authorization: "Bearer " + token },
      });
      fetchCardMachines();
    } catch (error) {
      console.error("Error deleting card machine:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewMachine((prevMachine) => ({
      ...prevMachine,
      [name]: value,
    }));
  };

  const handleUserSelect = (user: any) => {
    setSelectedUserName(user.name);
    setSelectedUserId(user.id);
    const userMachines = cardMachines.filter(
      (machine) => machine.graphic_account_id === user.id
    );
    setFilteredCardMachines(userMachines);
    setShowUserSelectionModal(false);
  };

  const handleClearFilter = () => {
    setFilteredCardMachines(cardMachines);
    setSelectedUserName("");
  };

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <div className="flex justify-between mb-4">
            <div>
              <Text>
                <span>Adicionar/Editar Máquina</span>
              </Text>
              <form
                onSubmit={
                  selectedMachine ? handleUpdateMachine : handleAddMachine
                }
                className="mt-6 flex flex-wrap gap-6 justify-between"
              >
                <div className="flex flex-col w-full sm:w-auto">
                  <label
                    htmlFor="serialNum"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Número de Série
                  </label>
                  <input
                    type="text"
                    id="serialNum"
                    name="serialNum"
                    value={newMachine.serialNum}
                    onChange={handleInputChange}
                    className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Número de Série"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-auto">
                  <label
                    htmlFor="identificationCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Código de Identificação
                  </label>
                  <input
                    type="text"
                    id="identificationCode"
                    name="identificationCode"
                    value={newMachine.identificationCode}
                    onChange={handleInputChange}
                    className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Código de Identificação"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-auto">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Descrição
                  </label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    value={newMachine.description}
                    onChange={handleInputChange}
                    className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Descrição"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-auto">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nome
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={selectedUserName}
                      onClick={() => setShowUserSelectionModal(true)}
                      className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Nome"
                      readOnly
                    />
                    <button
                      onClick={() => setShowUserSelectionModal(true)}
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 4a2 2 0 100 4 2 2 0 000-4zM8 10a2 2 0 100-4 2 2 0 000 4zm8-2a2 2 0 11-4 0 2 2 0 014 0zm-4 4a2 2 0 100-4 2 2 0 000 4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    {showUserSelectionModal && (
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div
                            className="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                          >
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                          >
                            &#8203;
                          </span>
                          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                              <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                  <h3
                                    className="text-lg leading-6 font-medium text-gray-900"
                                    id="modal-title"
                                  >
                                    Selecione um Usuário
                                  </h3>
                                  <div className="mt-2">
                                    {users.map((user) => (
                                      <div
                                        key={user.id}
                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                        onClick={() => handleUserSelect(user)}
                                      >
                                        {user.name}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                onClick={() => setShowUserSelectionModal(false)}
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                              >
                                Fechar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  onClick={() => setShowSelectPlanModal(true)}
                  className="flex flex-col w-full sm:w-auto"
                >
                  <span className="block text-sm font-medium text-gray-700">
                    Plano da maquininha
                  </span>
                  <input
                    value={selectedPlan?.name || ""}
                    readOnly
                    className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                {showSelectPlanModal && (
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div
                        className="fixed inset-0 transition-opacity"
                        aria-hidden="true"
                      >
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                      >
                        &#8203;
                      </span>
                      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-title"
                              >
                                Selecione um plano
                              </h3>
                              <div className="mt-2">
                                {plans?.map((plan) => (
                                  <div
                                    key={plan.id}
                                    className="p-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => {
                                      setShowSelectPlanModal(false);
                                      setSelectedPlan(plan);
                                    }}
                                  >
                                    {plan.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            onClick={() => setShowSelectPlanModal(false)}
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Fechar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex w-full items-end justify-between">
                  <div>
                    <button
                      onClick={() => setShowUserSelectionModal(true)}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                    >
                      Buscar Usuário
                    </button>
                    <button
                      type="button"
                      onClick={handleClearFilter}
                      className="inline-flex ml-4 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                    >
                      Limpar Filtro
                    </button>
                  </div>

                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Adicionar
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Table>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <td className="text-center">Número de Série</td>
                    <td className="text-center">Código de Identificação</td>
                    <td className="text-center">Descrição</td>
                    <td className="text-center">Usuário</td>
                    <td className="text-center">Ações</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredCardMachines.map((machine) => (
                    <tr key={machine.id}>
                      <td className="text-center">{machine.serialNum}</td>
                      <td className="text-center">
                        {machine.identificationCode}
                      </td>
                      <td className="text-center">{machine.description}</td>
                      <td className="text-center">
                        {machine.graphicAccount
                          ? machine.graphicAccount.name
                          : ""}
                      </td>
                      <td className="text-center flex">
                        <button onClick={() => handleEditMachine(machine)}>
                          Editar
                        </button>
                        <button
                          className="px-4"
                          onClick={() => handleDeleteMachine(machine.id)}
                        >
                          <X size={16} color="#dd2222" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

export default ConfigPersons;

import { FC } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";

import InvoicingSendAlertSVG from "../../assets/InvoicingSendAlertSVG.svg";

export const InvoicingSendAlert: FC = () => {
  const location = useLocation();
  const { accountType } = location.state;

  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background max-2xl:gap-0">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/send-ccmei", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="750" height="88vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="700"
            height="86vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[10vh] ml-14 flex flex-col justify-center items-center">
          <p className="font-bold text-white text-4xl text-center max-2xl:text-xl">
            Precisamos de uma <br />
            última informação.
          </p>

          <p className="font-extralight text-white text-xl text-center mt-4 mb-8 max-2xl:text-base max-2xl:mb-2">
            Para segurança do seu cadastro, será <br />
            necessário que você anexe no cadastro o PDF <br />
            do faturamento mensal da sua empresa.
          </p>

          <img width={300} className="mb-8 ml-5 max-2xl:w-[40%] max-2xl:mb-2" src={InvoicingSendAlertSVG} alt="" />

          <div className="flex gap-4 mt-16">
            <GradientButton
              title="Voltar"
              tailwindStyles="bg-[#171717] text-white w-[17.7rem] h-12"
              onClick={() => navigate("/create-account-wallet/send-ccmei", { state: { accountType } })}
            />
            <GradientButton
              title="Avançar"
              tailwindStyles="bg-[#274683] text-white w-[17.7rem] h-12"
              onClick={() => navigate("/create-account-wallet/send-invoicing-pdf", { state: { accountType } })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

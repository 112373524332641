import {ReactComponent as VisionIconSVG} from '../../assets/vision.svg'
import {ReactComponent as HomeIconSVG} from '../../assets/home.svg'
import {ReactComponent as ReceiptIconSVG} from '../../assets/receipt.svg'
import {ReactComponent as BankMoneySVG} from '../../assets/bankmoney.svg'
import {ReactComponent as ArrowIconSVG} from '../../assets/arrow.svg'

import {ReactComponent as signOutIconSVG} from '../../assets/sign-out.svg'
import {ReactComponent as HelpIconSVG} from '../../assets/help.svg'
import {ReactComponent as AccountDeclarationIconSVG} from '../../assets/accountDeclaration.svg'
import {ReactComponent as TaxIconSVG} from '../../assets/tax.svg'
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as PixIconSVG} from '../../assets/pix.svg'
import {ReactComponent as ChargeIconSVG} from '../../assets/chargeSide.svg'
import {ReactComponent as DepositIconSVG} from '../../assets/deposit.svg'
import {ReactComponent as transferMoneyIconSVG} from '../../assets/transferSide.svg'
import {ReactComponent as ShareIconSVG} from '../../assets/share.svg'
import {ReactComponent as KeysIconSVG} from '../../assets/pixmykeys.svg'
import {ReactComponent as QrCodeIconSVG} from '../../assets/QR-Code-Free-Icon-0sdf.svg'
import {ReactComponent as SettingsIconSVG} from '../../assets/settings-2.svg'
import {ReactComponent as ArrowUpRightIconSVG} from '../../assets/settings-2.svg'




export const IconSwitch = (nameIcon: string) => {


    switch (nameIcon) {
        case 'HomeIcon':
            return HomeIconSVG
        case 'ReceiptIcon':
            return ReceiptIconSVG
        case 'BankMoney':
            return BankMoneySVG
        case 'DepositIcon':
            return DepositIconSVG
        case 'ChargeIcon':
            return ChargeIconSVG
        case 'PixIconSVG':
            return PixIconSVG
        case 'BillIcon':
            return BillIconSVG
        case 'TaxIcon':
            return TaxIconSVG
        case 'KeysIcon':
            return KeysIconSVG
        case 'AccountDeclarationIcon':
            return AccountDeclarationIconSVG
        case 'QrCodeIcon':
            return QrCodeIconSVG 
        case 'SettingsIcon':
                return SettingsIconSVG 
        case 'ArrowUpRightIcon':
                return ArrowUpRightIconSVG 
    } 
    
}
import React from 'react';


import {
    Container
} from './styles'

// interface IconTypeProps {
//     width?: number;
//     height?: number;
//     color?: string;
//   }

// type IconType = (props: IconTypeProps) => JSX.Element;

interface Props {
    Icon?: React.FC;
  }



const IconHeader: React.FC<Props> = ({
   Icon
}) => {

  return (
        <Container>
            {
                Icon && <Icon/>
            }
        </Container>
    )
}

export default IconHeader;

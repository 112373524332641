import styled from "styled-components";
import ReactModal from "react-modal";

import {ReactComponent as ExportIconSVG} from "../../assets/export.svg"
import {ReactComponent as ReceiptIconSVG} from "../../assets/receipt.svg"
import {ReactComponent as ArrowPageRightIconSVG} from "../../assets/arrowPageRight.svg"
import {ReactComponent as ArrowPageLeftIconSVG} from "../../assets/arrowPageLeft.svg"
import {ReactComponent as KeyCpnjIconSVG} from "../../assets/keyCpnj.svg"
import {ReactComponent as TrashIconSVG} from "../../assets/trash.svg"
import {ReactComponent as ShareIconSVG} from "../../assets/share.svg"
import {ReactComponent as arrowDownIconSVG} from '../../assets/arrowDown.svg'

import {ReactComponent as CloseIconSVG} from '../../assets/close.svg'

export const ArrowDownIcon = styled(arrowDownIconSVG)`

`

export const CloseIcon = styled(CloseIconSVG)`

`

export const ReceiptIcon = styled(ReceiptIconSVG)`
fill: var(--cian);
`

export const TrashIcon = styled(TrashIconSVG)`
fill: var(--red);
margin-left: 15px;
cursor: pointer;
`

export const ShareIcon = styled(ShareIconSVG)`
fill: var(--cian);
margin-right: 32px;
`

export const KeyCpnjIcon = styled(KeyCpnjIconSVG)`
fill: var(--white);
`

export const ExportIcon = styled(ExportIconSVG)`
fill: var(--white);
`

export const ArrowPageRightIcon = styled(ArrowPageRightIconSVG)`
fill: var(--gray);
`

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`
fill: var(--gray);
`


export const Modal = styled(ReactModal).attrs({
    style: {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: '3',
          backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
        content: {
          display: "flex",
          flexDirection: 'column',
          maxHeight: '392px',
          maxWidth: '666px',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          margin: 'auto',
          background: '#fff',
          // overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
        }
      }
})``

export const ModalWrapper = styled.div`

flex: 1;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: relative;

padding: 32px;
`

export const TitleModal = styled.span`
font-size: 24px;
font-family: var(--regular);
color: var(--cian);
margin-bottom: 36px;
`

export const DescriptionModal = styled.span`

margin-bottom: 73px;
text-align: center;
font-size: 16px;
font-family: var(--regular);
color: var(--modalTitle);

`

export const ModalButtonWrapper = styled.div`

margin-top: 54px;

`

export const CloseButtonModal = styled.button`

background: none;
border-style: none;
position: absolute;
top: 32px;
right: 32px;

`

export const Container = styled.div`

height: calc(100vh - 80px);
background-color: var(--background);

`

export const InviteModal = styled.div`

display: flex;
flex-direction: column;
align-items: center;

`
export const LineModal = styled.div`

width: 384px;
height: 2px;
background: var(--modalTitle);


`
export const LeftInvitesModal = styled.div`

margin-top: 17px;
font-size: 16px;
font-family: var(--regular);
color: var(--modalTitle);

> span {
    color: var(--cian);

}

`

export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;


`

export const Table = styled.div`

flex: 1;
padding: 32px;

`


export const TableWrapper = styled.div`

  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px){
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px){
      width: 100%;
  }


> table {

    width: 100%;
    border-radius: 8px;
    border: none;
    border-collapse: collapse;

    > tr {
        cursor: pointer;
        position: relative;

        &:first-child {

            &::after {
                content: '';
                height: 16px;
                width: 4px;
                background-color: var(--cian);
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
            }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
            text-align: start;
            font-family: var(--semiBold);
            font-size: 14px;
            color: var(--gray);
            padding: 16px;


            @media (min-width: 720px){
                font-size: 16px;
                padding: 21px 32px;
            }
        }

        > td {
            padding: 6px 16px;
            font-family: var(--regular);
            font-size: 10px;
            color: var(--gray-light-text);

                @media (min-width: 720px){
                    font-size: 14px;
                    padding: 12px 32px;

                }

            }
          }
        }


`


export const PaginationWrapper = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
width: 280px;
padding: 32px;
align-self: center;

> span {
    font-family: var(--regular);
    color: var(--gray-light-text);
    font-size: 16px;
}

.active {
    height: 32px;
    width: 32px;
    background-color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: 16px;
}

`

export const ButtonWrapper = styled.div`
margin-right: 54px;
`

export const KeyPixTable = styled.div`

display: flex;
align-items: center;

> svg {
    margin-right: 10px;
}

`

export const ActionPixTable = styled.div`

display: flex;
align-items: center;

`

import React, {
  InputHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import InputMask from "react-input-mask";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { FieldError } from "react-hook-form";

import { Container } from "./styles";
// import { ExclamationCircleIcon } from '@heroicons/react/solid';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  borderColor?: string;
  borderRadius?: string;
  height?: string;
  mask?: string;
  error?: FieldError;
  errortextstyle?: string;
  type?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    borderColor = "#ebebeb",
    borderRadius = "8px",
    height = "40px",
    mask,
    error = null,
    type = "text",
    ...rest
  },
  ref
) => {
  return (
    <>
      <Container
        height={height}
        borderColor={borderColor}
        borderRadius={borderRadius}
      >
        {mask ? (
          <>
            {mask === "CPF_CNPJ" ? (
              <CpfCnpj mask={mask} type="text" ref={ref} {...rest} />
            ) : (
              <InputMask
                maskChar=""
                mask={mask}
                inputRef={ref}
                type="text"
                {...rest}
              />
            )}
          </>
        ) : (
          <input type={type || "text"} ref={ref} {...rest} />
        )}
      </Container>
      {!!error && (
        <div className={`${rest.errortextstyle ? rest.errortextstyle : ""}`}>
          {/* <div className="relative inset-y-0 top-[-30px] right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> */}

          {!!error && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error.message}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export const TextInput = forwardRef(InputBase);

export default TextInput;

// const TextInput: React.FC<Props> = ({
//     borderColor = '#777986',
//     borderRadius = '8px',
//     height = '40px',
//     mask,
//     ...rest
// }) => {
//   return (

//     <Container height={height} borderColor={borderColor} borderRadius={borderRadius}>
//       {mask ? (
//         <>
//           {mask === 'CPF_CNPJ' ? (
//             <CpfCnpj mask={mask} type="text" {...rest} />
//           ) : (
//             <InputMask maskChar="" mask={mask} type="text" {...rest} />
//           )}
//         </>
//       ): (
//         <input type="text" {...rest} />
//       )}

//     </Container>

//   )
// }

// export default TextInput;

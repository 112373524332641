import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Internal } from "src/components/proofs/pdf/Internal";
import { InternalTax } from "src/components/proofs/pdf/InternalTax";
import { P2P } from "src/components/proofs/pdf/P2P";
import { PagbankPayment } from "src/components/proofs/pdf/PagbankPayment";
import { PagbankPaymentDiscount } from "src/components/proofs/pdf/PagbankPaymentDiscount";
import { Payment } from "src/components/proofs/pdf/Payment";
import { Pix } from "src/components/proofs/pdf/Pix";
import { PixKey } from "src/components/proofs/pdf/PixKey";
import { Slip } from "src/components/proofs/pdf/Slip";
import { Ted } from "src/components/proofs/pdf/Ted";
import { TransferWithPix } from "src/components/proofs/pdf/TransferWithPix";
import { api } from "src/services/api";
import dateToLine from "src/utils/dateToLine";
import { documentFormatted } from "src/utils/documentFormatted";
import { ExtractItem } from "src/utils/extractDescription";
import toLocaleAmount from "src/utils/toLocaleAmount";

const PDF = () => {
  const receiptString = localStorage.getItem("temp_receipt_to_print");

  const [tx, setTx] = useState<ExtractItem>();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTransaction(id);
    }
  }, []);

  const fetchTransaction = async (transactionId: string) => {
    try {
      const response = await api.get(`/transactions/extract/${transactionId}`);

      setTx(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  if (!receiptString && !id) {
    window.close();
    return <></>;
  }

  const data: any = receiptString ? JSON.parse(receiptString) : tx;

  if (!data) {
    window.close();
    return <></>;
  }

  if (!id) {
    window.print();
  }

  const txType = data.type.toLowerCase();

  const Proof =
    txType == "pix" ? (
      <PixKey data={data} closeModal={() => {}} />
    ) : txType == "transfer_with_pix" ? (
      <TransferWithPix data={data} closeModal={() => {}} />
    ) : txType.includes("pix") ? (
      <Pix data={data} closeModal={() => {}} />
    ) : txType.includes("p2p") ? (
      <P2P data={data} closeModal={() => {}} />
    ) : txType.includes("ted") ? (
      <Ted data={data} closeModal={() => {}} />
    ) : txType.includes("slip") ? (
      <Slip data={data} closeModal={() => {}} />
    ) : txType == "internal" || txType.includes("withdraw") ? (
      <Internal data={data} closeModal={() => {}} />
    ) : txType == "internal_tax" ? (
      <InternalTax data={data} closeModal={() => {}} />
    ) : txType == "pagbank_payment" ? (
      <PagbankPayment data={data} closeModal={() => {}} />
    ) : txType == "pagbank_payment_discount" ? (
      <PagbankPaymentDiscount data={data} closeModal={() => {}} />
    ) : txType == "payment" ? (
      <Payment data={data} closeModal={() => {}} />
    ) : (
      <div className="flex flex-col h-full w-full py-20 items-center justify-center">
        <span>Esse extrato ainda não esta pronto...</span>
        <span>Tente voltar aqui mais tarde</span>
      </div>
    );

  return (
    <div id="content" className="w-[720px] flex flex-col h-screen">
      {Proof}
      <div className="flex flex-1 flex-col-reverse w-[720px] justify-between p-10 pt-30">
        {/* <div className="flex w-full justify-between items-center">
          <div className="flex flex-col">
            <span className="text-zinc-500">SÃO PAULO</span>
            <span className="text-black ">Juscelino Kubitschek, 1545</span>
          </div>

          <div className="flex flex-col">
            <span className="text-zinc-500 text-right">Email</span>
            <span className="text-black ">suporte@pixwave.com.br</span>
          </div>
              </div>*/}
        <div className="flex flex-col items-center">
          <span className="text-zinc-500 text-right text-sm">ID Transação</span>
          <span className="text-black text-sm">{data.order_id}</span>
        </div>
      </div>
    </div>
  );
};

export const DefaultLabel: FC<{
  title: string;
  value: string;
  size?: "base" | "lg" | "md" | "xl";
}> = ({ title, value, size = "lg" }) => {
  return (
    <span className={`text-black font-semibold text-${size}`}>
      {title}
      {`: `}
      <span className="ml-2 text-zinc-500 font-semibold">{value}</span>
    </span>
  );
};

export default PDF;

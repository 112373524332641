import { ExclamationIcon } from "@heroicons/react/solid";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { useAuth } from "../../hooks/auth";
import { useNotification } from "../../hooks/notification";
import { api } from "../../services/api";
import { service, serviceError } from "../../services/ajio";

import {
  ActionText,
  ButtonsWrapper,
  Container,
  Form,
  FormItem,
  FormWrapper,
  WrapperHeader,
} from "./styles";

export const PixNewKey: React.FC = () => {
  const { account } = useAuth();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedKey, setSelectedKey] = useState("Tipo de chave");
  const [registered, setRegistered] = useState(false);
  const [mask, setMask] = useState<string | undefined>(undefined);

  const [verification, setVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const [key, setKey] = useState('');

  const { setOpenNotification } = useNotification();

  useEffect(() => {
    setKey('');
    if (selectedKey === 'CPF/CNPJ') {
      setMask('CPF_CNPJ');
    } else if (selectedKey === 'Celular') {
      setMask('(99) 99999-9999');
    } else {
      setMask(undefined);
    }
  }, [selectedKey]);

  function handleListKey() {
    navigation('/pix/mykeys');
  }


  const handleNextButton = async () => {
    if (!verification) {
      if (selectedKey === 'Tipo de chave') {
        setOpenNotification({
          type: 'error',
          title: 'Tipo inválido',
          notification: 'Selecione uma tipo de chave Pix'
        });

        return;
      }
      setLoading(true);
      setError('');

      let typeSelected;


      if (selectedKey === 'CPF/CNPJ') {
        typeSelected = 'sms';
      }
      else if (selectedKey === 'Celular') {
        typeSelected = 'pix.phone';
      }
      else if (selectedKey === 'E-mail') {
        typeSelected = 'pix.email';
      }
      else if (selectedKey === 'Chave aleatória') {
        typeSelected = 'sms';
      } 
      else {
        typeSelected = 'sms';
      }


      try {
      //  await service.post("/send-verification", {
       //   type: typeSelected,
       // });
  
        setLoading(false);
        setError('');
        setVerification(true);
      } catch (err) {
        setLoading(false);
        const error = serviceError(err);
        setError(error.friend);
      }

    } else {
      setLoading(true);
      let typeSelected;
      if (selectedKey === 'CPF/CNPJ') {
        typeSelected = 'DOCUMENT';
      }
      else if (selectedKey === 'Celular') {
        typeSelected = 'PHONE';
      }
      else if (selectedKey === 'E-mail') {
        typeSelected = 'EMAIL';
      }
      else if (selectedKey === 'Chave aleatória') {
        typeSelected = 'EVP';
      } else {
        typeSelected = 'EVP';
      }
{/* */}
      try {
        await service.post("/pix/register/key", {
          entryType: typeSelected,
         // verification_code: verificationCode,
        });

        setLoading(false);
        setError('');
        setRegistered(true);
      } catch (err) {
        setLoading(false);
        const error = serviceError(err);
        setError(error.friend);
      }
    }
  };

  const handleGoBack = () => {
    navigation(-1);
  };

  const handleCancelButton = () => {
    navigation("/pix");
  };


  const handleKeySelect = (option: string) => {
    setSelectedKey(option);
  };

  const TitleInput = useMemo(() => {
    switch(selectedKey){
      case "Celular":
      return "Digite o celular"
      case "CPF/CNPJ":
      return "Digite o CPF/CNPJ"
      case "E-mail":
      return "Digite o E-mail"
      case "Chave aleatória":
      return "Digite a chave aleatória"
      default:
      return "Selecione a chave pix"
    }
  }, [selectedKey])

  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Registrar nova chave"
        subtitle="Selecione um de chave para registrar"
      />
      </WrapperHeader>
      {!registered && (
      <Form>
        <FormWrapper>
          <FormItem>
          <ActionText>Tipo de chave Pix</ActionText>
          <SelectInput
            placeholderColor={'#B5B7BD'}
            borderColor={'#E3E3E3'}
            optionSelected={selectedKey}
            handleFunction={handleKeySelect}
            options={["CPF/CNPJ", "Celular", "E-mail", "Chave aleatória"]}
          />
          </FormItem>
       {/*}   <FormItem>
            {verification && (
              <>
                <ActionText>
                  Código de verificação
                </ActionText>
                <TextInput
                    borderColor="#E3E3E3" borderRadius="4px"
                    value={verificationCode}
                    onChange={input => setVerificationCode(input.target.value)}
                  />

                  <span className="text-sm mt-2 inline-block">Enviamos um código de verificação (sms ou e-mail).</span>
              </>
            )}
            </FormItem> */}
        </FormWrapper>
        {!!error && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
        )}
        <ButtonsWrapper>
        <Button
          color="#e3e3e3"
          title="Cancelar"
          handleFunction={handleCancelButton}
        />
        {/* <button type="button" className="bg-[#49D294] px-10 py-2 rounded-md text-white flex" onClick={handleNextButton}>
          <Loading isLoading={loading}/>
          Continuar
        </button> */}

       <Button type="button" isLoading={loading} handleFunction={handleNextButton} title="Registrar" />
      </ButtonsWrapper>
      </Form>
      )}

      {registered && (
        <Form>
          <FormWrapper>
          <FormItem>
          <ActionText>Chave pix em processo de registro</ActionText>
          <div className="text-sm mb-5">
            <span className="mb-2 block">Essa ação pode demorar alguns instantes.</span>
            Acompanhe o status da sua chave na lista de chaves
          </div>
          <Button type="button" isLoading={loading} handleFunction={handleListKey} title="Acompanhar minhas chaves" />
          </FormItem>
          </FormWrapper>
        </Form>
      )}
    </Container>
  );
};

import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useClient } from "../../hooks/client";
import { currencyFormat } from "../../utils/currencyFormat";
import { OptionSidebar } from "../optionSidebar";
// import { OptionSidebarButton } from "../optionSidebarButton";
import { Service } from "../service";
import {
  Container,
  WrapperHeader,
  InfoAccount,
  SideFix,
  SidebarIcon,
  BalanceInfoAccount,
  VisionIcon,
  // StricLogo,
  Arrowicon,
  BankMoneyIcon,
  HomeIcon,
  ReceiptIcon,
  TransferMoneyIcon,
  BillIcon,
  PixIcon,
  ChargeIcon,
  DepositIcon,
  OptionsWrapper,
  AccountDeclarationIcon,
  ShareIcon,
  Machine,
  // ShareIcon
} from "./styles";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import socket from "src/services/socket-io";
import { Howl } from "howler";
import { SocketContextProvider } from "src/hooks/SocketContext";

type props = {
  isActive: boolean;
  handleOpenReferenceModal: () => void;
};

export const Sidebar: React.FC<props> = ({
  isActive,
  handleOpenReferenceModal,
}) => {
  const route = useLocation();
  const { account, user } = useAuth();
  const { client } = useClient();
  const [showWalletUpgradeButton, setShowWalletUpgradeButton] = useState(false);

  const [bankServicesIsOpen, setBankServicesIsOpen] = useState(false);
  const [myAccountIsOpen, setMyAccountIsOpen] = useState(false);
  const [myWalletAccountIsOpen, setMyWalletAccountIsOpen] = useState(false);
  const [myMachineAccountIsOpen, setMyMachineAccountIsOpen] = useState(false);

  const [myAccountManageIsOpen, setMyAccountManageIsOpen] = useState(false);
  const [balance, setBalance] = useState(account.balance || 0);

  const [isBalanceVisible, setIsBalanceVisible] = useState(() => {
    const visible = localStorage.getItem("visible");

    if (visible && visible === "true") {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    setBalance(account.balance);
  }, [account.balance]);

  useEffect(() => {
    if (user?.role === "WALLET") {
      setShowWalletUpgradeButton(true);
    } else {
      setShowWalletUpgradeButton(false);
    }
  }, [user]);

  const handleBankServiceToggle = () => {
    setBankServicesIsOpen((state) => !state);
  };

  const handleMyAccountoggle = () => {
    setMyAccountIsOpen((state) => !state);
  };

  const handleMyWalletAccountoggle = () => {
    setMyWalletAccountIsOpen((state) => !state);
  };

  const handleMyMachineAccountoggle = () => {
    setMyMachineAccountIsOpen((state) => !state);
  };
  const handleMyAccounManagetoggle = () => {
    setMyAccountManageIsOpen((state) => !state);
  };
  const handleBalanceAccounttoggle = () => {
    localStorage.setItem("visible", (!isBalanceVisible).toString());
    setIsBalanceVisible((state) => !state);
  };

  return (
    <SocketContextProvider onChangeBalance={setBalance}>
      <Container isActive={isActive}>
        <WrapperHeader>
          <SidebarIcon />
          <Link to="/">
            <img
              src={client.darkLogo.image}
              alt={client.name}
              style={{
                width: client.whiteLogo.width,
                height: client.whiteLogo.height,
                marginLeft: "24px",
                cursor: "pointer", // Adicione este estilo para mostrar que a imagem é clicável
              }}
            />
          </Link>
          {/* <StricLogo/> */}
        </WrapperHeader>
        <InfoAccount>
          <p>Saldo Disponível</p>
          <BalanceInfoAccount>
            <p>{isBalanceVisible ? currencyFormat(balance) : "R$ ***"}</p>
            <button onClick={handleBalanceAccounttoggle}>
              <VisionIcon />
            </button>
          </BalanceInfoAccount>
        </InfoAccount>
        <SideFix>
          <OptionSidebar
            isSelected={route.pathname === "/"}
            linkTo={"/"}
            title={"Inicio"}
            Icon={HomeIcon}
          />

          <OptionSidebar
            isSelected={route.pathname === "/extract"}
            linkTo={"/extract"}
            title={"Extrato"}
            Icon={ReceiptIcon}
          />

          {/* <OptionSidebar
                isSelected={route.pathname === '/extract'}     linkTo={'/extract'}
                title={'Comprovantes'}
                Icon={BankMoneyIcon} /> */}
        </SideFix>
        <Service
          handleOption={handleBankServiceToggle}
          isOpen={bankServicesIsOpen}
          Icon={Arrowicon}
          title="Serviços Bancários"
        />
        <AnimatePresence>
          {bankServicesIsOpen && (
            <OptionsWrapper>
              {/* <OptionSidebar
                            isSelected={route.pathname.startsWith('/deposit')}
                            linkTo={'/deposit'}
                            title={'Depositar'}
                            Icon={DepositIcon} /> */}

              {/* <OptionSidebar
                            isSelected={route.pathname === '/charge'}
                            linkTo={'/charge'}
                            title={'Cobrar'}
                            Icon={ChargeIcon} /> */}

              <OptionSidebar
                isSelected={route.pathname.startsWith("/pix")}
                linkTo={"/pix"}
                title={"Pix"}
                Icon={PixIcon}
              />

              <OptionSidebar
                isSelected={route.pathname.startsWith("/paybills")}
                linkTo={"/paybills"}
                title={"Pagar Contas"}
                Icon={BillIcon}
              />

              {user?.role !== "GRAPHIC" && user?.role !== "WALLET" && (
                <OptionSidebar
                  isSelected={route.pathname.includes("/transfer/subscribe")}
                  // linkTo={'/transfer'}
                  linkTo={"/transfer/subscribe"}
                  title={"Transferir"}
                  Icon={TransferMoneyIcon}
                />
              )}

              {user?.role !== "GRAPHIC" && user?.role !== "WALLET" && (
                <OptionSidebar
                  isSelected={route.pathname.includes("/billet")}
                  // linkTo={'/transfer'}
                  linkTo={"/billet"}
                  title={"Boleto"}
                  Icon={TransferMoneyIcon}
                />
              )}

              {user?.role !== "GRAPHIC" && user?.role !== "WALLET" && (
                <>
                  <OptionSidebar
                    isSelected={route.pathname.includes("/transfer/list")}
                    // linkTo={'/transfer'}
                    linkTo={"/transfer/list"}
                    title={"Transferir Entr. Contas"}
                    Icon={BankMoneyIcon}
                  />
                </>
              )}
            </OptionsWrapper>
          )}
        </AnimatePresence>
        {["MEMBER", "ADMIN_BAG", "MASTER"].includes(user?.role) &&
        account.gestor_graphic ? (
          <>
            <Service
              handleOption={handleMyAccounManagetoggle}
              isOpen={myAccountManageIsOpen}
              Icon={Arrowicon}
              title="Gerenciar Contas"
            />
            <AnimatePresence>
              {myAccountManageIsOpen && (
                <OptionsWrapper>
                  <OptionSidebar
                    isSelected={route.pathname.includes("/gerenciar")}
                    linkTo={"/gerenciar"}
                    title={"Gerenciar"}
                    Icon={AccountDeclarationIcon}
                  />
                </OptionsWrapper>
              )}
            </AnimatePresence>
          </>
        ) : user?.role === "WALLET" || user?.role === "GRAPHIC" ? (
          <>
            <Service
              handleOption={handleMyWalletAccountoggle}
              isOpen={myWalletAccountIsOpen}
              Icon={Arrowicon}
              title="Minha Wallet"
            />

            <AnimatePresence>
              {myWalletAccountIsOpen && (
                <OptionsWrapper>
                  <OptionSidebar
                    isSelected={route.pathname.startsWith(
                      "/adds-founds-wallet"
                    )}
                    linkTo={"/adds-founds-wallet"}
                    title={"Adicionar Saldo"}
                    Icon={AccountDeclarationIcon}
                  />
                  {user?.role === "WALLET" && !user.status_master_user && (
                    <OptionSidebar
                      isSelected={route.pathname === "/type-create-account"}
                      linkTo={"/type-create-account"}
                      Icon={AccountDeclarationIcon}
                      title={"Wallet Upgrade"}
                    />
                  )}
                </OptionsWrapper>
              )}
            </AnimatePresence>
          </>
        ) : null}
        {/*///////////////////////////////////*/}
        {user?.role === "WALLET" ? (
          <>
            <Service
              handleOption={handleMyMachineAccountoggle}
              isOpen={myMachineAccountIsOpen}
              Icon={Arrowicon}
              title="Maquininhas"
            />

            <AnimatePresence>
              {myMachineAccountIsOpen && (
                <OptionsWrapper>
                  {user?.role === "WALLET" && (
                    <OptionSidebar
                      isSelected={route.pathname === "/extract-pos"}
                      linkTo={"/extract-pos"}
                      Icon={ReceiptIcon}
                      title={"Extrato (POS)"}
                    />
                  )}

                  <OptionSidebar
                    isSelected={route.pathname.startsWith("/my-terminals")}
                    linkTo={"/my-terminals"}
                    title={"Terminais (POS)"}
                    Icon={Machine}
                  />
                </OptionsWrapper>
              )}
            </AnimatePresence>
          </>
        ) : null}
        {/*///////////////////////////////////*/}
        <Service
          handleOption={handleMyAccountoggle}
          isOpen={myAccountIsOpen}
          Icon={Arrowicon}
          title="Minha Conta"
        />
        <AnimatePresence>
          {myAccountIsOpen && (
            <OptionsWrapper>
              {user?.role === "GRAPHIC" ? (
                <OptionSidebar
                  isSelected={
                    route.pathname === "/passwordKeyControlAccountChange"
                  }
                  linkTo={"/passwordKeyControlAccountChange"}
                  Icon={AccountDeclarationIcon}
                  title={"Senha de Acesso"}
                />
              ) : null}

              {user?.role === "GRAPHIC" ? (
                <OptionSidebar
                  isSelected={
                    route.pathname ===
                    "/configcontrolaccountkeypasswordeletronic"
                  }
                  linkTo={"/configcontrolaccountkeypasswordeletronic"}
                  Icon={AccountDeclarationIcon}
                  title={"Senha Eletrônica"}
                />
              ) : (
                <OptionSidebar
                  isSelected={route.pathname === "/configkeypasswordeletronic"}
                  linkTo={"/configkeypasswordeletronic"}
                  Icon={AccountDeclarationIcon}
                  title={"Senha Eletrônica"}
                />
              )}

              {user?.role === "GRAPHIC" ? (
                <OptionSidebar
                  isSelected={route.pathname === "/tax"}
                  linkTo={"/tax"}
                  title={"Tarifas C-A"}
                  Icon={ChargeIcon}
                />
              ) : user?.type === "NATURAL" ? (
                <OptionSidebar
                  isSelected={route.pathname === "/tax"}
                  linkTo={"/tax"}
                  title={"Tarifas PF"}
                  Icon={ChargeIcon}
                />
              ) : user?.type === "LEGAL" ? (
                <OptionSidebar
                  isSelected={route.pathname === "/tax"}
                  linkTo={"/tax"}
                  title={"Tarifas PJ"}
                  Icon={ChargeIcon}
                />
              ) : user?.role === "WALLET" ? (
                <OptionSidebar
                  isSelected={route.pathname === "/tax"}
                  linkTo={"/tax"}
                  title={"Tarifas Wallet"}
                  Icon={ChargeIcon}
                />
              ) : (
                ""
              )}

              {/* {(user?.role === "WALLET" || user?.role === "MASTER") && (
                <OptionSidebar
                  isSelected={route.pathname === "/myLimits"}
                  linkTo={"/myLimits"}
                  title={"Meus Limites"}
                  Icon={ChargeIcon}
                />
              )}
              <OptionSidebar
                isSelected={route.pathname.includes("support")}
                linkTo={"/support"}
                title={"Suporte"}
                Icon={ChargeIcon}
              /> */}
              {/*
                            <OptionSidebar
                            isSelected={route.pathname === '/1'}
                            linkTo={'/1'}
                            title={'Ajuda'}
                            Icon={BillIcon} /> 

                             <OptionSidebar
                            handleFuction={handleOpenReferenceModal}
                            title={'Convide amigos'}
                            Icon={ShareIcon} /> */}

              {/* {client.host === 'novo.nobebank.com.br' && (
                              <OptionSidebar
                                isSelected={route.pathname === '/signout'}
                                linkTo={'#'}
                                title={'Cobranças anteriores'}
                                onClick={() => {
                                  window.location.href = 'https://internet-banking-wb.vercel.app';
                                }}
                                Icon={TransferMoneyIcon} />
                            )} */}

              {/* <OptionSidebar
                            isSelected={route.pathname === '/type-create-account'}
                            linkTo={'/type-create-account'}
                            title={'Adicionar Conta'}
                            Icon={ChargeIcon} /> */}

              <OptionSidebar
                isSelected={route.pathname === "/signout"}
                linkTo={"/signout"}
                title={"Sair"}
                Icon={TransferMoneyIcon}
              />
            </OptionsWrapper>
          )}
        </AnimatePresence>
      </Container>
    </SocketContextProvider>
  );
};

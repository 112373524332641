import React, { useEffect, useState } from "react";

import {
  Container,
  ContentWrapper,
  GoBackButtonWrapper,
  Line,
  Requirement,
  Text,
  TextRequirement,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  ListWrapper,
  WrapperButton,
} from "./styles";

import { ReactComponent as ArrowLeft } from "../../assets/LParrowLeft.svg";

// import { ReactComponent as Logo } from "../../assets/logostric.svg";
import ButtonOpenAccount from "../../components/LPbutton";
import { useLocation, useNavigate } from "react-router-dom";
import { useClient } from "../../hooks/client";

const Requirements: React.FC = () => {
  const { client } = useClient();
  const navigation = useNavigate();
  const location = useLocation();

  const [type, setType] = useState<string | undefined>();
  const [franchiseId, setFranchiseId] = useState<string | undefined>();

  //const [type, setType] = useState('');
  const handleGoBack = () => {
    navigation("/signin");
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const type = queryParameters.get("type");
    const franchise_id = queryParameters.get("service_code");

    if (!location.search || location.search === null || location.search === "" || !type || !franchise_id) {
      navigation("/signin");
    }

    type && setType(type);
    franchise_id && setFranchiseId(franchise_id);
  }, [location.search, navigation]);

  useEffect(() => {
    window.localStorage.removeItem("@stricv2:status");
    window.localStorage.removeItem("@stricv2:id");
  }, []);

  return (
    <Container image={client.signin} bank={client.name}>
      <ContentWrapper>
        <WrapperLeft image={client.signin} bank={client.name}></WrapperLeft>
        <WrapperRight image={client.signin} bank={client.name}>
          {/*  <GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            Voltar ao Login
          </GoBackButtonWrapper>  */}
          <WrapperContent>
            {/* <Logo /> */}
            {client.name !== "PIXWAVE BANK" && (
              <img
                src={client.darkLogo.image}
                alt={client.name}
                style={{
                  width: client.darkLogo.maxWidth,
                  height: client.darkLogo.maxHeight,
                  marginBottom: "15px",
                }}
              />
            )}
            <Title>
              Pronto para ter uma Conta <strong> {client.name}</strong>?
            </Title>
            <Text>Antes de seguir confira os documentos que iremos solicitar nas próximas etapas</Text>
            {type === "?type=pf" && (
              <ListWrapper>
                <Requirement>
                  <Line />
                  <TextRequirement>CPF válido na Receita Federal</TextRequirement>
                </Requirement>
                <Requirement>
                  <Line />
                  <TextRequirement>FOTO do titular</TextRequirement>
                </Requirement>
                <Requirement>
                  <Line />
                  <TextRequirement>
                    Carteira de habilitação <br />
                    ou identidade (CNH ou RG)
                  </TextRequirement>
                </Requirement>
              </ListWrapper>
            )}

            {type === "?type=pj" && (
              <ListWrapper>
                <Requirement>
                  <Line />
                  <TextRequirement>CNPJ válido na Receita Federal</TextRequirement>
                </Requirement>
                <Requirement>
                  <Line />
                  <TextRequirement>FOTO do representante legal</TextRequirement>
                </Requirement>
                <Requirement>
                  <Line />
                  <TextRequirement>
                    Documentos dos representantes legais <br />
                  </TextRequirement>
                </Requirement>
              </ListWrapper>
            )}

            {type === "?type=wallet" && (
              <ListWrapper>
                <Requirement>
                  <Line />
                  <TextRequirement>WALLET</TextRequirement>
                </Requirement>
                <Requirement>
                  <Line />
                  <TextRequirement>FOTO do representante legal</TextRequirement>
                </Requirement>
                <Requirement>
                  <Line />
                  <TextRequirement>
                    Documentos dos representantes legais <br />
                  </TextRequirement>
                </Requirement>
              </ListWrapper>
            )}
            <WrapperButton>
              <ButtonOpenAccount
                linkTo={`create-account-wallet-form?type=${type}&service_code=${franchiseId}`}
                buttonText="Começar"
              />
            </WrapperButton>
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
};

export default Requirements;

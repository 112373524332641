import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Account } from "src/hooks/auth";
import { Transaction } from "src/pages/receipt";
import { api } from "src/services/api";
import {
  accountsToChartDataCount,
  transactionsToChartData,
  transactionsToChartDataCount,
} from "src/utils/chartUtils";
import toLocaleAmount from "src/utils/toLocaleAmount";

type ChartData = {
  value: number; //y
  date: string; //x
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export function HomeDash() {
  const token = localStorage.getItem("@backoffice:token");
  const [status, setStatus] = useState("done");

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);

  const checkinTxs = transactions.filter(
    (tx: Transaction) => tx.direction === "in"
  );

  const checkoutTxs = transactions.filter(
    (tx: Transaction) => tx.direction === "out"
  );

  const amountCheckin: ChartData[] = transactionsToChartData(checkinTxs);
  const amountCheckout: ChartData[] = transactionsToChartData(checkoutTxs);

  const accountChartDataCount = accountsToChartDataCount(accounts).map(
    (account) => account.value
  );

  const txCountChartData = transactionsToChartDataCount(transactions);
  const txCountPix = transactionsToChartDataCount(
    transactions.filter((tx) => tx.type.toLowerCase().includes("pix"))
  ).map((tx_) => tx_.value);

  console.log(transactions.map((tx) => tx.type));

  const getAccount = async () => {
    try {
      const response = await api.get(`/backoffice/accounts`, {
        headers: { Authorization: "Bearer " + token },
        params: {
          role: "ALL",
        },
      });
      const accounts = response.data.accounts.data;

      setAccounts(accounts);
    } catch (error) {
      console.log(error);
    }
  };

  const getTransfers = async () => {
    try {
      const response = await api.get(`/backoffice/bank-transfers`, {
        headers: { Authorization: "Bearer " + token },
        params: {
          status,
        },
      });

      const transactions = response.data.transactions.data;
      setTransactions(transactions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccount();
    getTransfers();
  }, []);

  return (
    <div className="flex flex-col w-screen h-full z-0 py-20 px-40">
      <div className="flex flex-col">
        <div className="grid grid-cols-2 grid-flow-col gap-8">
          <div className="">
            <h2>Transações</h2>
            <Chart
              options={{
                chart: {
                  type: "area",
                  zoom: { enabled: false },
                  toolbar: { show: false },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return value.toFixed(0); // Formata o valor para exibir apenas 2 casas decimais
                    },
                  },
                },
                grid: {
                  row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5,
                  },
                },
                xaxis: {
                  categories: months,
                },
              }}
              series={[
                {
                  name: "Transações",
                  data: txCountChartData.map((tx) => tx.value),
                },
              ]}
              type="area"
              height={280}
            />
          </div>
          <div className="">
            <h2>Contas (Progressão)</h2>
            <Chart
              options={{
                chart: {
                  type: "area",
                  zoom: { enabled: false },
                  toolbar: { show: false },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                grid: {
                  row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5,
                  },
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return value.toFixed(0); // Formata o valor para exibir apenas 2 casas decimais
                    },
                  },
                },
                xaxis: {
                  categories: months,
                },
              }}
              series={[{ name: "Contas criadas", data: accountChartDataCount }]}
              type="area"
              height={280}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 grid-flow-col gap-4">
          <div className="">
            <h2>Pix (Check-in e Check-out)</h2>
            <Chart
              options={{
                chart: {
                  type: "area",
                  zoom: { enabled: false },
                  toolbar: { show: false },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return `R$ ${toLocaleAmount(value)}`; // Formata o valor para exibir apenas 2 casas decimais
                    },
                  },
                },
                grid: {
                  row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5,
                  },
                },
                xaxis: {
                  categories: months,
                },
              }}
              series={[
                { name: "Entrada", data: amountCheckin.map((ci) => ci.value) },
                { name: "Saída", data: amountCheckout.map((co) => co.value) },
              ]}
              type="area"
              height={280}
            />
          </div>

          <div className="">
            <h2>Transações PIX</h2>
            <Chart
              options={{
                chart: {
                  type: "area",
                  zoom: { enabled: false },
                  toolbar: { show: false },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return value.toFixed(0); // Formata o valor para exibir apenas 2 casas decimais
                    },
                  },
                },
                grid: {
                  row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5,
                  },
                },
                xaxis: {
                  categories: months,
                },
              }}
              series={[{ name: "Transações PIX", data: txCountPix }]}
              type="area"
              height={280}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { FC, useEffect, useRef, useState } from "react";

import {
  ContentWrapper,
  Container,
  Content,
  Table,
  TableWrapper,
  Text,
} from "./styles";
import { api } from "src/services/api";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { useAuth } from "src/hooks/auth";
import "./styles.css";
import { DollarSign, Percent, Rss } from "lucide-react";
import { KeyReturn } from "phosphor-react";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import { useNavigate } from "react-router-dom";

type TaxConfiguration = {
  id: string;
  name: string;
  tax: number;
  taxDefault: number;
  taxType: "NUMBER" | "PERCENTAGE";
  taxDefaultType: "NUMBER" | "PERCENTAGE";
};

export const TaxBackoffice: React.FC = () => {
  const [sidebarMobileIsActive, setSidebarMobileIsActive] = useState(false);

  const token2 = window.localStorage.getItem("@backoffice:token");

  const [data, setData] = useState<TaxConfiguration[]>([]);

  const fetchTaxs = async () => {
    try {
      let url = `/backoffice/tax-configurations`;
      const { data } = await api.get(url, {
        headers: { Authorization: "Bearer " + token2 },
      });

      setData(data);
    } catch (error) {}
  };

  const handleSidebarMobileToggle = () => {
    setSidebarMobileIsActive((state) => !state);
  };

  useEffect(() => {
    fetchTaxs();
  }, []);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Configuração de tarifas</span>
          </Text>
          <Table>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <td>Tipo de tarifa</td>
                    <td className="text-center">Tarifa padrão</td>
                    <td className="text-center">Tarifa personalizada</td>
                    <td>Tarifa total</td>
                  </tr>
                </thead>
                <tbody>
                  {!data && <tr className="text-zinc-500">carregando</tr>}
                  {data.map((tax) => (
                    <TaxDetails key={tax.id} data={tax} />
                  ))}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

const TaxDetails: FC<{ data: TaxConfiguration }> = ({ data }) => {
  const [checkin, setCheckin] = useState<number>(data.taxDefault);
  const [checkout, setCheckout] = useState<number>(data.tax);

  const token = window.localStorage.getItem("@backoffice:token");

  const [checkinType, setCheckinType] = useState<"NUMBER" | "PERCENTAGE">(
    data.taxType
  );

  const [checkoutType, setCheckoutType] = useState<"NUMBER" | "PERCENTAGE">(
    data.taxDefaultType
  );

  const [editingCheckin, setEditingCheckin] = useState<boolean>(false);
  const [editingCheckout, setEditingCheckout] = useState<boolean>(false);

  const _checkin = toLocaleAmount(checkin);
  const _checkout = toLocaleAmount(checkout);

  const { user } = useAuthBackoffice();

  const role = user?.role || "ADMIN";

  const onChangeCheckinType = (event: any) => {
    event.stopPropagation();
    setCheckinType((old) => (old == "NUMBER" ? "PERCENTAGE" : "NUMBER"));
  };

  const onChangeCheckoutType = (event: any) => {
    event.stopPropagation();
    setCheckoutType((old) => (old == "NUMBER" ? "PERCENTAGE" : "NUMBER"));
  };

  const onChangeCheckin = (event: any) => {
    const newValue = parseFloat(event.target.value);
    setCheckin(newValue);
  };

  const onChangeCheckout = (event: any) => {
    const newValue = parseFloat(event.target.value);
    setCheckout(newValue);
  };

  const saveCheckin = () => {
    setEditingCheckin(false);
    setCheckin((newValue) => (Number.isNaN(newValue) ? 0 : newValue));
    updateTaxConfiguration();
  };

  const saveCheckout = () => {
    setEditingCheckout(false);
    setCheckout((newValue) => (Number.isNaN(newValue) ? 0 : newValue));
    updateTaxConfiguration();
  };

  const updateTaxConfiguration = async () => {
    try {
      let url = `/backoffice/tax-configurations`;
      const response = await api.put(
        url,
        {
          id: data.id,
          tax: Number.isNaN(checkout) ? 0 : checkout,
          taxType: checkoutType,
          taxDefault: Number.isNaN(checkin) ? 0 : checkin,
          taxDefaultType: checkinType,
        } as TaxConfiguration,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
    } catch (error) {
      console.log("erro ao atualizar taxa", error);
    }
  };

  const onKeyDown = (event: any) => {
    const pressedKey = event.key;

    if (pressedKey == "Enter") {
      saveCheckin();
      saveCheckout();
    }
  };

  const totalString = `${
    checkinType == "NUMBER" && checkoutType == "NUMBER"
      ? `R$ ${toLocaleAmount(checkin + checkout)}`
      : checkinType == "NUMBER" && checkoutType == "PERCENTAGE"
      ? `R$ ${_checkin} + ${_checkout}%`
      : checkinType == "PERCENTAGE" && checkoutType == "NUMBER"
      ? `${_checkin}% + R$ ${_checkout}`
      : ` ${toLocaleAmount(checkin + checkout)}%`
  }`;

  return (
    <tr>
      <td>{data.name}</td>
      {editingCheckin && role == "MASTER" ? (
        <td
          onBlur={saveCheckin}
          onKeyDown={(e) => {
            if (e.key == "Enter") saveCheckin();
          }}
          className="flex justify-between self-center items-center gap-1 text-center transition-all hover:bg-zinc-100 hover:border border-zinc-200 cursor-text"
        >
          <input
            onMouseDown={(e) => e.preventDefault()}
            onKeyDown={onKeyDown}
            autoFocus
            className="text-base max-w-[50px] text-zinc-700 bg-transparent h-full"
            type="number"
            value={checkin}
            onChange={onChangeCheckin}
          />
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="flex items-center gap-2"
          >
            <button
              onMouseDown={(e) => e.preventDefault()}
              onClick={onChangeCheckinType}
              className={`p-2 transition-all flex items-center justify-center rounded-lg ${
                checkinType == "NUMBER" ? "bg-green-500" : "bg-blue-500"
              }`}
            >
              {checkinType == "NUMBER" ? (
                <DollarSign size={18} color="#fff" />
              ) : (
                <Percent size={18} color="#fff" />
              )}
            </button>
            <div className="flex flex-col items-center">
              <KeyReturn size={22} color="#bbb" />
              <span className="text-xs text-zinc-500">save</span>
            </div>
          </div>
        </td>
      ) : (
        <td
          style={{
            cursor: role == "MASTER" ? "text" : "not-allowed",
          }}
          className="text-center transition-all hover:bg-zinc-100 hover:border border-zinc-200 cursor-text"
          onClick={() => {
            setEditingCheckin(true);
          }}
        >
          {checkinType == "NUMBER" ? `R$ ${_checkin}` : `${_checkin}%`}
        </td>
      )}
      {editingCheckout && role == "ADMIN" ? (
        <td
          onBlur={saveCheckout}
          onKeyDown={(e) => {
            if (e.key == "Enter") saveCheckout();
          }}
          className="flex justify-between self-center items-center gap-1 text-center transition-all cursor-text"
        >
          <input
            onMouseDown={(e) => e.preventDefault()}
            onKeyDown={onKeyDown}
            autoFocus
            className="text-base max-w-[50px] text-zinc-700 bg-transparent h-full"
            type="number"
            value={checkout}
            onChange={onChangeCheckout}
          />
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="flex items-center gap-2"
          >
            <button
              onMouseDown={(e) => e.preventDefault()}
              onClick={onChangeCheckoutType}
              className={`p-2 transition-all flex items-center justify-center rounded-lg ${
                checkoutType == "NUMBER" ? "bg-green-500" : "bg-blue-500"
              }`}
            >
              {checkoutType == "NUMBER" ? (
                <DollarSign size={18} color="#fff" />
              ) : (
                <Percent size={18} color="#fff" />
              )}
            </button>
            <div className="flex flex-col items-center">
              <KeyReturn size={22} color="#bbb" />
              <span className="text-xs text-zinc-500">save</span>
            </div>
          </div>
        </td>
      ) : (
        <td
          style={{
            cursor: role == "ADMIN" ? "text" : "not-allowed",
          }}
          onClick={() => {
            setEditingCheckout(true);
          }}
          className="text-center transition-all hover:bg-zinc-100 hover:border border-zinc-200 cursor-text"
        >
          {checkoutType == "NUMBER" ? `R$ ${_checkout}` : `${_checkout}%`}
        </td>
      )}

      <td>{totalString}</td>
    </tr>
  );
};

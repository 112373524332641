import React, { useState } from 'react';

interface PixTextAreaProps {
  pixString: string;
}

const PixTextArea: React.FC<PixTextAreaProps> = ({ pixString }) => {
  //const PixTextArea = () => {

  const [copied, setCopied] = useState(false);
  ////const pixString = "Exemplo de string Pix";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixString);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Defina o estado "copied" como falso após 3 segundos
  };

  return (
    <div>
      <textarea
        readOnly
        value={pixString}
        onClick={copyToClipboard}
        style={{
          width: '300px',
          height: '100px',
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          margin: '2px 0',
          //marginLeft: '50px',
        }}
      />
      <br />
      <button style={{
    cursor: 'pointer',
    background: 'var(--cian)',
    height: '40px',
    width: '100%',
    padding: '0 32px',
    borderStyle: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    display: 'flex',
    marginBottom: '16px',
    color: 'var(--white)',
    fontFamily: 'var(--semiBold)',
    fontSize: '14px',
  }} onClick={copyToClipboard}>{copied ? 'Copiado!' : 'Pix: Copia & Cola'}</button>
    </div>
  );
};

export default PixTextArea;

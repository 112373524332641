import { XCircleIcon } from "@heroicons/react/solid";
import {
  ChangeEvent,
  FC,
  ForwardRefRenderFunction,
  Fragment,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
  forwardRef,
  InputHTMLAttributes,
  Ref,
} from "react";

import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";

import { Link, useLocation, useParams } from "react-router-dom";
import { classNames } from "../Layout/base";
import { Dialog, Transition } from "@headlessui/react";

import { api } from "../../../services/api";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { useNotification } from "src/hooks/notification";
import { useAuth } from "src/hooks/auth";
import { useClient } from "src/hooks/client";
import { Plan } from "src/pages/createAccountWalletInitials/account_wallet_create";
import axios from "axios";
import { service, serviceError } from "src/services/ajio";
import { validateCPF, validateDate, validateDDD, validateEmail } from "src/utils/validations";
import { Loading } from "src/components/loading";
import toLocaleAmount from "src/utils/toLocaleAmount";
import Dropzone from "src/components/Dropzone";
import { DocumentIcon } from "@heroicons/react/outline";
import { convertToBase64 } from "src/utils/convertToBase64";
import { Alert } from "antd";

type IFormInfo = {
  pin: string;
  // "isPep": boolean;
  password: string;
  password2: string;
  name: string;
  document: string;
  email: string; // opcional
  phone: {
    ddd: string;
    number: string;
    type: string;
  };
  monthlyInvoicing: string;
  birthDate: string;
  educationLevel: string;
  maritalStatus: string;
  gender: string;
  address: {
    state: string; //only the UF code
    publicPlace: string;
    number: string;
    zipCode: string; //only numbers
    neighborhood: string;
    complement?: string;
    isConfirmed: boolean;
    type: string;
    cityId?: number;
    cityName?: string;
  };
  securityEletronic: string;
};

type IFormError = {
  isPep?: string;
  isForeign?: string; //fixed to false because we don't acept foreigners yet
  name?: string;
  document?: string;
  email?: string;
  birthDate?: string;
  motherName?: string;
  phone?: string;
  profession?: string;
  identityType?: string; //fixed to RG
  identityNumber?: string;
  identityIssuedAt?: string;
  monthlyIncome?: string;
  password?: string;
  password2?: string;
  emailValidationToken?: string;
  pin?: string;
  securityEletronic?: string;
  address?: {
    street?: string;
    number?: string;
    extra?: string;
    neighborhood?: string;
    city?: string;
    state?: string; //only the UF code
    postalCode?: string; //only numbers
  };
};

interface IndividualProps {
  status: string | null;
}

export function SignUpPfForBackoffice() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const { setOpenNotification } = useNotification();

  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [typeDoc, setTypeDoc] = useState("SELFIE");
  const [franchiseId, setFranchiseId] = useState<string | undefined>("52452452435-562546546-56456245645");

  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");

  const fetchPlans = async () => {
    try {
      const response = await api.get("/graphic/plans", {
        params: {
          role: "MEMBERPF",
        },
      });

      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const token = localStorage.getItem("@backoffice:token");

  useEffect(() => {
    const id = process.env.REACT_APP_FRANCHISE_ID;
    if (id) {
      setFranchiseId(id);
    }
    console.log("FRANCHISE_ID modificado: ", id);
  }, [franchiseId]);

  const clearErrorMessageAfterDelay = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const handleOneSubmit = async () => {
    setError({} as IFormError);

    // Name and Mother Name Validations
    if (!formInfo.name.includes(" ") || formInfo.name.length < 5) {
      setError({ name: "Preencha o nome completo." });
      return;
    }

    // CPF xxx.xxx.xxx-xx
    if (formInfo.document.length < 14) {
      setError({ document: "CPF Inválido." });
      return;
    }

    if (!validateCPF(formInfo.document.replace(/[/,.,-\s]/g, ""))) {
      setError({ document: "CPF Inválido." });
      return;
    }

    // Email
    if (formInfo.email.length < 6) {
      setError({ email: "Preencha o email." });
      return;
    }

    if (!validateEmail(formInfo.email)) {
      setError({ email: "Email Inválido." });
      return;
    }

    //if(!validateCellphone(formInfo.phone.number.replace(/[-,\s,(,)]/g, ""))){
    //   setError({phone: 'Celular Inválido.'});
    // }

    // BirthDate xxxx/xx/xx
    if (formInfo.birthDate.length < 10) {
      setError({ birthDate: "Data de Nascimento incompleta." });
      return;
    }
    if (!validateDate(formInfo.birthDate)) {
      setError({ birthDate: "Preencha a data de emissão completa." });
      return;
    }

    setLoading(true);

    try {
      setLoading(false);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      const errorAll = err as any;
      setLoading(false);
      if (error.code === "document.already" || error.code === "email.already") {
        setOpenNotification({
          title: "Alerta",
          notification:
            "Nós já nos conhecemos!\n\nIdentificamos que você já possui um vínculo com a gente.\n\nPara continuar, utilize sua senha e comece a desfrutar de todas as vantangens.",
          type: "warning",
        });
      } else {
        setOpenNotification({
          title: "Erro",
          notification: error.friend || errorAll?.response?.data?.message?.[0],
          type: "error",
        });
      }
    }
  };

  const handleTwoSubmitStepPF = async () => {
    setError({} as IFormError);

    if (formInfo.phone.ddd.length < 1) {
      setError({ phone: "DDD Inválido." });
      return;
    }

    if (!validateDDD(formInfo.phone.ddd.replace(/[-,\s,(,)]/g, ""))) {
      setError({ phone: "DDD Inválido." });
      return;
    }

    if (formInfo.phone.number.length < 9) {
      setError({ phone: "Celular Inválido." });
      clearErrorMessageAfterDelay(); // Limpa a mensagem de erro após 5 segundos
      return;
    }

    setLoading(true);
    try {
      setLoading(false);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }
  };

  async function handleCep() {
    setError({} as IFormError);

    if (formInfo?.address?.zipCode?.length < 9) {
      setError({
        ...error,
        address: {
          ...formInfo.address,
          postalCode: "Preencha o CEP completo.",
        },
      });
      return;
    }

    const rawPostalCode = formInfo.address.zipCode.replace(/-/g, "");
    if (rawPostalCode.length >= 8) {
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${rawPostalCode}/json/`);
        console.log(data);
        if (data) {
          setFormInfo({
            ...formInfo,
            address: {
              ...formInfo.address,
              publicPlace: data.logradouro,
              neighborhood: data.bairro,
              cityName: data.localidade,
              state: data.uf,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const mapGenderToTranslation = (gender: string): string => {
    const genderTranslationMap: { [key: string]: string } = {
      Masculino: "MALE",
      Feminino: "FEMALE",
      // Adicione mais mapeamentos conforme necessário
    };
    return genderTranslationMap[gender] || gender;
  };

  const mapMaritalStatusToTranslation = (status: string): string => {
    const maritalStatusTranslationMap: { [key: string]: string } = {
      "Solteiro(a)": "SINGLE",
      "Casado(a)": "MARRIED",
      "Separado(a) Legalmente": "LEGALLY_SEPARATED",
      "Divorciado(a)": "DIVORCED",
      "União Estável": "STABLE_UNION",
      "Desimpedido(a)": "DETACHED",
      "Viúvo(a)": "WIDOWER",
      Outros: "OTHERS",
      // Adicione mais mapeamentos conforme necessário
    };
    return maritalStatusTranslationMap[status] || status;
  };

  const mapEducationLevelToTranslation = (educationLevel: string): string => {
    const educationLevelTranslationMap: { [key: string]: string } = {
      Analfabeto: "ILLITERATE",
      "Ensino Fundamental - Anos Iniciais (1 a 4 anos)": "ELEMENTARY_1_TO_4_YEAR",
      "Ensino Fundamental - 5º ano": "ELEMENTARY_5_YEAR",
      "Ensino Fundamental - 6º a 9º ano": "ELEMENTARY_6_TO_4_YEAR",
      "Ensino Fundamental Completo": "COMPLETE_ELEMENTARY",
      "Ensino Médio Incompleto": "INCOMPLETE_HIGH_SCHOOL",
      "Ensino Médio Completo": "COMPLETE_HIGH_SCHOOL",
      "Ensino Superior Incompleto": "INCOMPLETE_HIGHER",
      "Ensino Superior Completo": "COMPLETE_HIGHER",
      "Pós-Graduação": "POSTGRADUATE",
      Mestrado: "MASTER_DEGREE",
      Doutorado: "DOCTORATE_DEGREE",
      // Adicione mais mapeamentos conforme necessário
    };
    const translatedEducationLevel = educationLevelTranslationMap[educationLevel] || educationLevel;

    // Adicione um console.log para imprimir o mapeamento
    console.log(
      `Nível de educação original: ${educationLevel}, Nível de educação traduzido: ${translatedEducationLevel}`
    );

    return translatedEducationLevel;
  };

  const mapAdressTypeToTranslation = (type: string): string => {
    const adressTypeTranslationMap: { [key: string]: string } = {
      Residencial: "RESIDECIAL",
      Comercial: "COMMERCIAL",
      // Adicione mais mapeamentos conforme necessário
    };
    return adressTypeTranslationMap[type] || type;
  };

  interface PlanMapping {
    [key: string]: string;
  }

  const [plansTranslationMap, setPlansTranslationMap] = useState<PlanMapping>({});

  useEffect(() => {
    const fetchPlansTranslations = async () => {
      try {
        const response = await axios.get("/chose-plans");
        const planMappings: PlanMapping = response.data;
        setPlansTranslationMap(planMappings);
      } catch (error) {
        console.error("Erro ao buscar mapeamento de planos:", error);
      }
    };

    fetchPlans();
    fetchPlansTranslations();
  }, []);

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const password2InputRef = useRef<HTMLInputElement>(null);
  const pinInputRef = useRef<HTMLInputElement>(null);
  const SecurityEletronicInputRef = useRef<HTMLInputElement>(null);

  const handleSignup = async () => {
    try {
      const raw = {
        franchise_id: franchiseId,
        document: formInfo.document.replace(/[/,.,-\s]/g, ""),
        name: formInfo.name,
        email: formInfo.email,
        planId: selectedPlanId,
        phone: {
          ddd: formInfo.phone.ddd.replace("(", "").replace(")", ""),
          number: formInfo.phone.number.replace(" ", "").replace("-", ""),
          type: "CELL",
        },
        monthlyInvoicing: Number(formInfo?.monthlyInvoicing?.replace(/[/,R$ .,-\s]/g, "")) / 100,
        birthDate: formInfo.birthDate?.split("/")?.reverse()?.join("-"),
        educationLevel: formInfo.educationLevel,
        maritalStatus: formInfo.maritalStatus,
        gender: formInfo.gender,
        address: {
          isConfirmed: true,
          publicPlace: formInfo.address.publicPlace,
          number: formInfo.address.number,
          complement: formInfo.address.complement || "",
          neighborhood: formInfo.address.neighborhood,
          cityName: formInfo.address.cityName,
          zipCode: formInfo?.address?.zipCode?.replace(/-/g, ""), //only numbers
          type: formInfo.address.type,
          state: formInfo.address.state,
        },
        password: formInfo.password,
        pin: formInfo.pin,
        securityEletronic: formInfo.securityEletronic,
      };

      const response = await service.post("/backoffice/accounts/individual", raw, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const createdUser = response.data;

      setCreatedUserId(createdUser.id);

      setStep("DOCUMENTS");

      setOpenNotification({
        notification: "Conta criada com sucesso",
        title: "Sucesso",
        type: "success",
      });
    } catch (error) {
      setOpenNotification({
        notification: "Houve um erro ao criar a conta...",
        title: "Erro",
        type: "error",
      });
      console.log(error);
    }
  };

  const [documents, setDocuments] = useState([
    {
      name: "Selfie",
      type: "SELFIE",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "CNH verso",
      type: "CNH_BACK",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "CNH frontal",
      type: "CNH_FRONT",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "RG fontal",
      type: "RG_FRONT",
      base64: "",
      isImage: false,
      fileName: "",
    },
    {
      name: "RG verso",
      type: "RG_BACK",
      base64: "",
      isImage: false,
      fileName: "",
    },
  ]);

  const [step, setStep] = useState<"ACCOUNT" | "DOCUMENTS">("ACCOUNT");

  const [createdUserId, setCreatedUserId] = useState("");

  const handleDocuments = async () => {
    setLoading(true);
    documents.map(async (document) => {
      try {
        if (document.base64) {
          const response = await service.post(
            "backoffice/accounts/document",
            {
              userId: createdUserId,
              documentBase64: document.base64,
              documentType: document.type,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
      } catch (error) {
        console.log("erro ao alterar documento", document, error);
      }
    });

    setTimeout(() => {
      setLoading(false);

      setOpenNotification({
        notification: `Documentos salvos`,
        title: `Sucesso`,
        type: "success",
      });
    }, 3000);
  };

  if (step == "ACCOUNT")
    return (
      <div>
        <div
          className={"w-ful"}
          onSubmit={(e) => {
            e.preventDefault();
            handleOneSubmit();
          }}
        >
          <section aria-labelledby="payment-details-heading">
            <>
              <form action="#" method="POST">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="bg-white py-6 px-4 sm:p-6">
                    <Skeleton isActive={loading} repeat={3} />
                    {!loading && (
                      <>
                        <div>
                          <h2
                            id="payment-details-heading"
                            className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                          >
                            Cadastro de conta PF:
                          </h2>

                          <p className="mt-1 text-sm text-gray-500">
                            {/* Update your billing information. Please note that updating your location could affect your tax
                        rates. */}
                          </p>
                        </div>

                        <div className="mt-6 grid grid-cols-5 gap-6">
                          <PropDetail
                            cols={1}
                            title="Nome"
                            value={formInfo.name}
                            placeholder="Nome"
                            required
                            error={error.name || ""}
                            onChanged={(e) => setFormInfo({ ...formInfo, name: e.target.value })}
                          />
                          <PropDetail
                            cols={1}
                            title="CPF"
                            value={formInfo.document}
                            placeholder="CPF"
                            mask="999.999.999-99"
                            required
                            error={error.document || ""}
                            onChanged={(e) =>
                              setFormInfo({
                                ...formInfo,
                                document: e.target.value,
                              })
                            }
                          />

                          <PropDetail
                            cols={1}
                            title="Data de nascimento"
                            mask="99/99/9999"
                            placeholder="Data de nascimento"
                            required
                            error={error.birthDate || ""}
                            onChanged={(e) =>
                              setFormInfo({
                                ...formInfo,
                                birthDate: e.target.value,
                              })
                            }
                          />

                          <PropDetail
                            cols={1}
                            title="Email"
                            value={formInfo.email}
                            placeholder="Email"
                            required
                            error={error.email || ""}
                            onChanged={(e) =>
                              setFormInfo({
                                ...formInfo,
                                email: e.target.value,
                              })
                            }
                          />

                          <FormInputCurrency
                            title="Renda mensal"
                            cols={1}
                            placeholder="Renda mensal"
                            required
                            value={parseFloat(formInfo.monthlyInvoicing)}
                            onChanged={(e) =>
                              setFormInfo({
                                ...formInfo,
                                monthlyInvoicing: e.target.value,
                              })
                            }
                            error={error.monthlyIncome || ""}
                          />

                          <div className="flex mb-[-15px] gap-2">
                            <div className="w-14">
                              <PropDetail
                                title="DDD"
                                placeholder="DDD"
                                required
                                mask="(99)"
                                error={error.phone || ""}
                                onChanged={(e) =>
                                  setFormInfo({
                                    ...formInfo,
                                    phone: {
                                      ...formInfo.phone,
                                      ddd: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                            <div className="flex-1">
                              <PropDetail
                                title="Telefone Celular"
                                placeholder="Celular"
                                required
                                mask="9 9999-9999"
                                error={error.phone || ""}
                                onChanged={(e) =>
                                  setFormInfo({
                                    ...formInfo,
                                    phone: {
                                      ...formInfo.phone,
                                      number: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          <PropDetailOptions
                            required
                            cols={1}
                            title={"Nível de educação"}
                            placeholder={"Nível de educação"}
                            label="Nível de educação"
                            options={[
                              "Analfabeto",
                              "Ensino Fundamental - Anos Iniciais (1 a 4 anos)",
                              "Ensino Fundamental - 5º ano",
                              "Ensino Fundamental - 6º a 9º ano",
                              "Ensino Fundamental Completo",
                              "Ensino Médio Incompleto",
                              "Ensino Médio Completo",
                              "Ensino Superior Incompleto",
                              "Ensino Superior Completo",
                              "Pós-Graduação",
                              "Mestrado",
                              "Doutorado",
                            ]}
                            onChanged={(e: any) =>
                              setFormInfo({
                                ...formInfo,
                                educationLevel: mapEducationLevelToTranslation(e.target.value),
                              })
                            }
                          />

                          <PropDetailOptions
                            required
                            title={"Gênero"}
                            placeholder={"Gênero"}
                            label="Gênero"
                            options={["Masculino", "Feminino"]}
                            onChanged={(e: any) => {
                              const translatedValue = mapGenderToTranslation(e.target.value);
                              setFormInfo({
                                ...formInfo,
                                gender: translatedValue,
                              });
                            }}
                          />
                          <PropDetailOptions
                            required
                            title={"Estado Civil"}
                            label="Estado Civil"
                            placeholder="Estado Civil"
                            options={[
                              "Solteiro(a)",
                              "Casado(a)",
                              "Separado(a) Legalmente",
                              "Divorciado(a)",
                              "União Estável",
                              "Desimpedido(a)",
                              "Viúvo(a)",
                              "Outros",
                            ]}
                            onChanged={(e: any) =>
                              setFormInfo({
                                ...formInfo,
                                maritalStatus: mapMaritalStatusToTranslation(e.target.value),
                              })
                            }
                          />

                          <PropDetailOptions
                            title="Plano"
                            placeholder="Plano"
                            label="Plano"
                            options={plans.map((p) => ({
                              id: p.id,
                              name: `${p.name} R$ ${toLocaleAmount(p.price)}`,
                            }))}
                            onChanged={(e: any) => setSelectedPlanId(e.target.value)}
                          />

                          <>
                            <h1 className="col-span-4">Endereço</h1>
                            <br />

                            <div className="flex mb-[-15px] gap-2">
                              <PropDetail
                                cols={1}
                                title="CEP"
                                placeholder="CEP"
                                mask="99999-999"
                                required
                                error={error.address?.postalCode || ""}
                                onChanged={(e) =>
                                  setFormInfo({
                                    ...formInfo,
                                    address: {
                                      ...formInfo.address,
                                      zipCode: e.target.value,
                                    },
                                  })
                                }
                              />
                              <div className="w-14 items-center h-[60px]">
                                <Button onClick={handleCep} title="Carregar">
                                  <Loading isLoading={loading} />
                                  CEP
                                </Button>
                              </div>
                            </div>

                            <PropDetail
                              cols={1}
                              title="Cidade"
                              placeholder="Cidade"
                              required
                              error={error.address?.city || ""}
                              value={formInfo?.address?.cityName}
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  address: {
                                    ...formInfo.address,
                                    cityName: e.target.value,
                                  },
                                })
                              }
                            />
                            <PropDetail
                              cols={1}
                              title="Bairro"
                              placeholder="Bairro"
                              value={formInfo?.address?.neighborhood}
                              error={error.address?.neighborhood || ""}
                              required
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  address: {
                                    ...formInfo.address,
                                    neighborhood: e.target.value,
                                  },
                                })
                              }
                            />
                            <PropDetail
                              cols={1}
                              title="Estado"
                              placeholder="Estado"
                              value={formInfo?.address?.state}
                              error={error.address?.state || ""}
                              required
                              maxLength={2}
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  address: {
                                    ...formInfo.address,
                                    state: e.target.value,
                                  },
                                })
                              }
                            />
                            <PropDetail
                              cols={1}
                              title="Número"
                              placeholder="Número"
                              error={error.address?.number || ""}
                              value={formInfo?.address?.number}
                              required
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  address: {
                                    ...formInfo.address,
                                    number: e.target.value,
                                  },
                                })
                              }
                            />
                            <PropDetail
                              cols={3}
                              title="Rua"
                              placeholder="Rua"
                              error={error.address?.street || ""}
                              value={formInfo?.address?.publicPlace}
                              maxLength={70}
                              required
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  address: {
                                    ...formInfo.address,
                                    publicPlace: e.target.value,
                                  },
                                })
                              }
                            />

                            <PropDetail
                              cols={1}
                              title="Complemento"
                              placeholder="Complemento"
                              value={formInfo?.address?.complement}
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  address: {
                                    ...formInfo.address,
                                    complement: e.target.value,
                                  },
                                })
                              }
                            />

                            <PropDetailOptions
                              required
                              cols={1}
                              title="Local"
                              placeholder="Local"
                              options={["Residencial", "Comercial"]}
                              onChanged={(e: any) => {
                                const typeAdressValue = mapAdressTypeToTranslation(e.target.value);
                                setFormInfo({
                                  ...formInfo,
                                  address: {
                                    ...formInfo.address,
                                    type: typeAdressValue,
                                  },
                                });
                              }}
                            />
                          </>

                          <>
                            <h1 className="col-span-4">Insira as credenciais</h1>
                            <br />
                            <FormInputPassword
                              required
                              cols={1}
                              title="Senha"
                              ref={passwordInputRef}
                              id="password-input"
                              borderColor="#ff0013"
                              placeholder="Senha"
                              type="password"
                              showPasswordIcon
                              error={error.password || ""}
                              value={formInfo.password}
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  password: e.target.value,
                                })
                              }
                            />
                            <FormInputPassword
                              required
                              cols={1}
                              title="Digite novamente a senha"
                              ref={password2InputRef}
                              id="password2-input"
                              // borderColor="#ff0013"
                              placeholder="Confirme a senha"
                              type="password"
                              showPasswordIcon
                              error={error.password2 || ""}
                              value={formInfo.password2}
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  password2: e.target.value,
                                })
                              }
                            />
                            <FormInputPassword
                              required
                              cols={1}
                              title="Pin"
                              ref={pinInputRef}
                              id="pin-input"
                              maxLength={4}
                              placeholder="4 Digitos"
                              type="password"
                              mask="9999"
                              error={error.pin || ""}
                              showPasswordIcon
                              value={formInfo.pin}
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  pin: e.target.value,
                                })
                              }
                            />

                            <FormInputPassword
                              cols={1}
                              title="Senha eletrônica"
                              placeholder="Senha eletrônica"
                              required
                              ref={SecurityEletronicInputRef}
                              id="securty-eletronic-input"
                              maxLength={8}
                              type="password"
                              mask="99999999"
                              error={error.securityEletronic || ""}
                              showPasswordIcon
                              value={formInfo.securityEletronic}
                              onChanged={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  securityEletronic: e.target.value,
                                })
                              }
                            />
                          </>
                          <Button cols={5} onClick={handleSignup}>
                            Cadastrar
                            <Loading isLoading={loading} />
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </>
          </section>
        </div>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              {/* <CopyToClipboard text={baseString}
                        onCopy={() => console.log('copied')}>
                        <button>Copiar base64</button>
                      </CopyToClipboard> */}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6 break-all">
                          <div className="absolute inset-0 px-4 sm:px-6">
                            <div className="h-full border-2 border-dashed border-gray-200" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    );

  return (
    <div className="flex w-full items-center justify-center">
      <div className="py-6 px-4 sm:p-6 bg-white rounded-xl shadow-sm w-full flex flex-wrap">
        <>
          <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <Alert
              message="Por favor, sinta-se à vontade para completar o seu cadastro anexando uma selfie sua e uma cópia frente e verso de um documento de identificação, como RG ou CNH. Estamos aqui para garantir sua segurança e privacidade."
              type="warning"
              style={{ marginBottom: 20 }}
            />
          </div>
          <div className="grid w-full grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
            {documents.map((doc, i) => (
              <DocumentDetail
                cols={1}
                key={i}
                onPickFile={async (f) => {
                  const fileBase64 = await convertToBase64(f);
                  const isImage = f.name.includes("png") || f.name.includes("jpeg") || f.name.includes("jpg");

                  setDocuments((documents) =>
                    documents.map((_doc) =>
                      _doc.type == doc.type
                        ? {
                            ...doc,
                            base64: fileBase64,
                            isImage,
                            fileName: f.name,
                          }
                        : _doc
                    )
                  );
                }}
                base64={doc.base64}
                name={doc.name}
                isImage={doc.isImage}
                fileName={doc.fileName}
              />
            ))}
          </div>
          <button className="flex w-full justify-end mt-2" onClick={handleDocuments}>
            <Button className="gap-2">
              Salvar documentos
              <Loading isLoading={loading} />
            </Button>
          </button>
        </>
      </div>
    </div>
  );
}

const DocumentDetail: FC<{
  name: string;
  base64: string;
  cols: number;
  isImage: boolean;
  fileName?: string;
  onPickFile: (file: File) => void;
}> = ({ base64, name, onPickFile, cols, isImage }) => {
  return (
    <div className={`flex flex-col gap-2 grid-cols-[${cols}]`}>
      {base64 ? (
        <div className="flex items-center justify-center w-full h-64 bg-zinc-100 rounded-lg overflow-hidden">
          {isImage ? (
            <img className="w-full h-64 bg-red-400 object-cover" src={`data:image/png;base64, ${base64}`} />
          ) : (
            <div className="flex flex-col items-center justify-center">
              <DocumentIcon width={42} color="#888" />
              <span className="text-zinc-400 text-sm">{name}</span>
            </div>
          )}
        </div>
      ) : (
        <Dropzone
          onDrop={(files) => {
            onPickFile(files[0]);
            console.log(files);
          }}
        />
      )}

      <div className="flex items-center justify-center w-full h-12 text-white rounded-lg bg-[#274683]">
        <span>{name}</span>
      </div>
    </div>
  );
};

interface PropDetailProps {
  title: string;
  value?: string | number | undefined;
  placeholder?: string;
  required?: boolean;
  error?: string;
  onChanged?: (e: ChangeEvent<HTMLInputElement>) => void;
  cols?: number;
  maxLength?: number;
  mask?: string; // Adicionando a propriedade mask aqui
  id?: string;
}

export const PropDetail: FC<PropDetailProps> = (
  { mask, title, value, placeholder, required, error, onChanged, cols = 4, maxLength }: PropDetailProps,
  ref: Ref<HTMLInputElement>
) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChanged) {
      onChanged(event);
    }
  };

  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {mask ? (
        <InputMask
          mask={mask}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
            error ? "border-red-500" : ""
          }`}
        />
      ) : (
        <input
          type="text"
          id={title}
          name={title}
          value={value}
          placeholder={placeholder}
          required={required}
          maxLength={maxLength}
          onChange={handleChange}
          className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
            error ? "border-red-500" : ""
          }`}
        />
      )}
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};
interface ButtonProps {
  title?: string;
  cols?: number;
  type?: "button" | "submit" | "reset";
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({
  title,
  cols = 4,
  type = "button",
  className = "",
  isLoading = false,
  onClick,
  children,
}) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700 mb-1">
        {title}
      </label>
      <button
        type={type}
        onClick={onClick}
        className={`mt-1 w-full inline-flex justify-center rounded-md bg-green-600 border border-green-300  px-4 py-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`}
        disabled={isLoading}
      >
        {isLoading && <span className="animate-spin mr-2">Loading...</span>}
        {children}
      </button>
    </div>
  );
};

///////////////

type CombinedOnChange =
  | {
      onChanged?: (e: any, number?: number, masked?: string) => void;
    }
  | {
      onChanged?: (e: ChangeEvent<HTMLSelectElement>) => void;
    };

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  cols?: number;
  label?: string;
  mask?: string;
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderInput?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  onChanged?: (e: any, number?: number, masked?: string) => void;
  showPasswordIcon?: boolean; // Adicione a propriedade showPasswordIcon aqui
  id?: string; // Adicione um campo "id" exclusivo para identificar o FormInput
  eyeIconColor?: string; // Adicione a propriedade eyeIconColor aqui
  value?: string | number;
  placeholder?: string;
  required?: boolean;
}

interface PropsSelect extends SelectHTMLAttributes<HTMLSelectElement> {
  title?: string;
  cols?: number;
  label?: string;
  placeholder?: string;
  options: string[] | { name: string; id: string }[];
  error?: string;
  nomargin?: boolean;
  borderColor?: string;
  borderRadius?: string;
  onChanged?: CombinedOnChange["onChanged"]; // Aqui você referencia o tipo combinado de onChanged
  value?: string | number;
  required?: boolean;
  mask?: string;
  onSelectOption?: (data: { name: string; id: string }) => void;
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    title,
    cols = 1,
    label,
    mask,
    error,
    nomargin = false,
    borderColor,
    borderInput,
    borderRadius,
    onChanged,
    showPasswordIcon = false,
    id,
    type = "text",
    ...rest
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>

      <input
        {...rest}
        onChange={(e) => onChanged && onChanged(e)}
        ref={ref as React.RefObject<HTMLInputElement>} // Use ref como RefObject<HTMLInputElement>
      />
      {error && (
        <div>
          <p className="text-sm text-red-600 " id="email-error">
            {error}
          </p>
        </div>
      )}
    </div>
  );
};

///////////// esse ok
const InputSelect = ({
  cols = 1,
  title,
  label,
  placeholder = "Selecione uma opção",
  options,
  error,
  nomargin = false,
  borderColor,
  borderRadius,
  onChanged,
  ...rest
}: PropsSelect) => {
  const containerStyle = {
    marginBottom: 27,
    borderRadius: "4px", // Define o raio dos cantos arredondados
  };

  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <select {...rest} onChange={onChanged}>
        <option value="">{placeholder}</option>
        {options.map((opt) => {
          if (typeof opt === "string") {
            return (
              <option key={opt} value={opt}>
                {opt}
              </option>
            );
          } else {
            return (
              <option key={opt.id} value={opt.id}>
                {opt.name}
              </option>
            );
          }
        })}
      </select>
      {!!error && (
        <div>
          {!!error && (
            <p className="text-sm text-red-600" id="email-error">
              {error}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export const FormInput = forwardRef(InputBase);

export const FormInputSelect = InputSelect;

/////////////////////

interface PropDetailOptions extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: string[] | { name: string; id: string }[];
  onChanged?: (e: ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  error?: string;
  title?: string;
  cols?: number;
}

const PropDetailOptions: React.FC<PropsSelect> = ({
  cols = 1,
  title,
  label,
  error,
  options,
  onChanged,
  placeholder,
  ...rest
}) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>

      <select
        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
          error ? "border-red-500" : ""
        }`}
        {...rest}
        onChange={onChanged}
      >
        <option value="">{placeholder}</option>
        {options.map((opt, index) =>
          typeof opt === "string" ? (
            <option key={index} value={opt}>
              {opt}
            </option>
          ) : (
            <option key={opt.id} value={opt.id}>
              {opt.name}
            </option>
          )
        )}
      </select>
    </div>
  );
};

const InputBaseCurrency: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    title,
    cols = 1,
    label,
    mask,
    error,
    nomargin = false,
    borderColor,
    borderInput,
    borderRadius,
    onChanged,
    showPasswordIcon = false,
    id,
    type = "text",
    ...rest
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>

      {mask !== "currency" ? (
        <div className="flex mb-[-15px] gap-2 items-center mt-1">
          <div className="col-span-1 border border-gray-300 rounded-md  p-1 h-[38px] w-[172px]">
            <IntlCurrencyInput currency="BRL" config={currencyConfig} onChange={onChanged} {...rest} />
          </div>
        </div>
      ) : (
        <input
          {...rest}
          onChange={(e) => onChanged && onChanged(e)}
          ref={ref as React.RefObject<HTMLInputElement>} // Use ref como RefObject<HTMLInputElement>
        />
      )}
      {error && (
        <div>
          <p className="text-sm text-red-600 " id="email-error">
            {error}
          </p>
        </div>
      )}
    </div>
  );
};

export const FormInputCurrency = forwardRef(InputBaseCurrency);

interface PropsPassword extends InputHTMLAttributes<HTMLInputElement> {
  cols?: number;
  label?: string;
  mask?: string;
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderInput?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  onChanged?: (e: any, number?: number, masked?: string) => void;
  showPasswordIcon?: boolean; // Adicione a propriedade showPasswordIcon aqui
  id?: string; // Adicione um campo "id" exclusivo para identificar o FormInput
  eyeIconColor?: string; // Adicione a propriedade eyeIconColor aqui
  value?: string | number;
  placeholder?: string;
  required?: boolean;
  title: string;
}

const InputBasePassword: ForwardRefRenderFunction<HTMLInputElement, PropsPassword> = (
  { title, value, placeholder, required, error, onChanged, cols = 4, maxLength }: PropDetailProps,
  ref: Ref<HTMLInputElement>
) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChanged) {
      onChanged(event);
    }
  };

  return (
    <div style={{ gridColumn: `span ${cols} / span ${cols}` }} className="sm:col-span-2">
      <label htmlFor={title} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <input
        type="text"
        id={title}
        name={title}
        value={value}
        placeholder={placeholder}
        required={required}
        maxLength={maxLength}
        onChange={handleChange}
        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export const FormInputPassword = forwardRef(InputBasePassword);

import React, { useState } from "react";
import {
  Container,
  Content,
  ContentWrapper,
  Text,
  Table,
  TableWrapper,
} from "../Tax/styles";
import DatePicker from "react-datepicker";
import { startOfMonth } from "date-fns";
import { PrintButtonIcon } from "src/pages/billet/styles";
import { PrinterIcon } from "lucide-react";

interface Transaction {
  id: number;
  created_at: Date;
  description: string;
  direction: string;
  type: string;
  amount: number;
}

export function ExtractsPagbank() {
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(startOfMonth(new Date()));

  // Dados fictícios
  const transactions: Transaction[] = [
    {
      id: 1,
      created_at: new Date(),
      description: "Retirada",
      direction: "out",
      type: "internal",
      amount: 100,
    },
    {
      id: 2,
      created_at: new Date(),
      description: "Depósito",
      direction: "in",
      type: "internal",
      amount: 200,
    },
    // Adicione mais dados fictícios conforme necessário
  ];

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Relatório das maquininhas</span>
          </Text>
          <Table>
            <TableWrapper>
              <table>
                <thead>
                  <tr className="text-left font-semibold rounded-t-lg">
                    <td
                      className="relative"
                      style={{ color: "#1DA7C6", padding: "20px" }}
                    >
                      <div className="flex items-center">
                        <span className="mr-2">Filtrar por:</span>
                        <select
                          className="block appearance-none  text-white border border-gray-300 rounded-md py-2 pl-3 pr-10 leading-tight focus:outline-none focus:border-indigo-500 mx-auto"
                          style={{ width: "fit-content", background: "#066FA6" }}
                        >
                          <option value="hoje">Hoje</option>
                          <option value="semana">Semana</option>
                          <option value="mes">Mês</option>
                          <option value="ano">Ano</option>
                        </select>
                      </div>
                    </td>

                    <td style={{ color: "#1DA7C6" }}>Tipo</td>
                    <td style={{ color: "#1DA7C6" }}>Transação</td>
                    <td style={{ color: "#1DA7C6" }}>Beneficiário</td>
                    <td style={{ color: "#1DA7C6" }}>Valor</td>
                    <td style={{ color: "#1DA7C6" }}>Imprimir</td>
                    <td colSpan={1}></td>
                  </tr>
                </thead>
                <tr
                  className="bg-gray-100 h-5 text-center font-semibold"
                  style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
                >
                  <td style={{ color: "#686868" }} colSpan={8}></td>
                </tr>
                <tbody>
                  {transactions.map((item) => (
                    <tr key={item.id}>
                      <td>{item.created_at.toISOString()}</td>
                      <td>
                        {item.description === "Retirada"
                          ? "Estornado"
                          : item.direction === "in"
                          ? "Crédito"
                          : "Débito"}
                      </td>
                      <td>{item.description}</td>
                      <td>
                        {item.direction === "out"
                          ? "Beneficiário"
                          : "Remetente"}
                      </td>
                      <td>
                        <span
                          style={{
                            color: item.direction === "in" ? "green" : "red",
                            marginLeft: "8px",
                          }}
                        >
                          {item.direction === "in" ? "+" : "-"}
                          {item.amount}
                        </span>
                      </td>
                      <td>
                        <PrintButtonIcon>
                          <PrinterIcon
                            onClick={() => {}}
                            color="var(--boxText)"
                            className="h-4 w-4 inline"
                          />
                        </PrintButtonIcon>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import Input from "../TextInput";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export interface SelectObject {
  id: number | string;
  name: string;
  hidden: string;
}

interface SelectProps {
  label: string;
  selected: SelectObject;
  options: SelectObject[];
  onChange: (select: SelectObject) => void;
  onSearch?: string;
}

export function InputSelect({
  label,
  selected,
  options,
  onChange,
  onSearch,
}: SelectProps) {
  const [search, setSearch] = useState("");
  const [searchOptions, setSearchOptions] = useState<typeof options>(options);

  useEffect(() => {
    if (search.length >= 2) {
      const newoptions = searchOptions.filter((op) =>
        op.hidden.includes(String(search).toLocaleLowerCase())
      );
      setSearchOptions(newoptions);
    } else {
      setSearchOptions(options);
    }
  }, [search]);
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-goldenrod focus:border-goldenrod sm:text-sm">
              <span className="block truncate">{selected.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {onSearch && (
                  <Input
                    name="b"
                    placeholder={onSearch}
                    value={search}
                    autoFocus
                    onChange={(input) => setSearch(input.target.value)}
                  />
                )}

                {searchOptions.map((option, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-goldenrod" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

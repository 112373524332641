import { DuplicateIcon } from "@heroicons/react/solid";
import React, { FormEvent, Fragment, useRef, useEffect, useState } from "react";
import { Skeleton } from "../../components/skeleton";
import { api } from "../../services/api";
import { dateFormat } from "../../utils/dateFormat";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import QRCode from "react-qr-code";
import {
  ActionPixTable,
  // KeyCpnjIcon,
  // KeyPixTable,
  // ShareIcon,
  TrashIcon,
} from "../extract/styles";
import {
  Container,
  Content,
  QrContainer,
  QrInput,
  Table,
  TableWrapper,
  LeftDiv,
  RightDiv,
} from "./styles";
import { useNotification } from "../../hooks/notification";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";
import { service } from "../../services/ajio";
import InputCustom, { InputInsertValueQrCodeStatic } from "./custom-input";
import axios from "axios"; // Importe o axios se ainda não estiver importado
import { useAuth } from "../../hooks/auth";
import PixTextArea from "./pix-text-area";

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export const QrCodeStatic = () => {
  const token = localStorage.getItem("@stricv2:token");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  //const { setOpenNotification } = useNotification();
  const [key, setKey] = useState<KeysProps>({} as KeysProps);
  const [keys, setKeys] = useState<KeysProps[]>([]);
  const [amount, setAmount] = useState<number>(0.0);
  const [qrImage, setQrImage] = useState<string>("");
  const [isEmvData, setEmvData] = useState<string>("");

  const [maskedValue, setMaskedValue] = useState("");
  const [value, setValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [keyOptions, setKeyOptions] = useState<string[]>([]);
  const { signIn, account, user } = useAuth();

  if (user) {
    const userId = user.id; // ou qualquer outra propriedade que contenha o userId

    // Use o userId conforme necessário aqui
    console.log("UserID:", userId);
  }

  // Definindo a função identificarTipoString
  function identificarTipoString(str: string): string {
    const regexPix = /^[a-fA-F0-9]{8}-(?:[a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$/;
    const regexCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$|^\d{14}$/;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regexPix.test(str)) {
      return "Chave Aleatória de Pix";
    } else if (regexCNPJ.test(str)) {
      return "CNPJ";
    } else if (regexEmail.test(str)) {
      return "E-mail";
    } else {
      return "Formato não reconhecido";
    }
  }

  // Chamar a função e exibir o tipo de string identificado
  const tipoStringIdentificado = identificarTipoString(inputValue);
  console.log(tipoStringIdentificado);

  const handleAmountChange = (newValue: number) => {
    if (!isNaN(newValue)) {
      setAmount(newValue);
    }
  };

  const handleDownloadQRCode = () => {
    downloadImage(qrImage, { id: linkValue });
  };

  async function getKeys() {
    service
      .get("/pix/keys")
      .then(({ data }) => {
        setKeys(data.keys);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getKeys();
  }, []);

  useEffect(() => {
    const options = keys.map((key) => key.key);
    setKeyOptions(options);
  }, [keys]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.value);
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  };

  const userId = user.id;
  console.log(userId, "eu sou o id:");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleGenerateQRCode = async () => {
    try {
      const payload: {
        userId?: string;
        amount?: number;
        key: string;
        description?: string;
        type?: string;
      } = {
        key: inputValue, // Chave é obrigatória
      };

      // Adicione os campos opcionais conforme necessário
      if (userId) payload.userId = userId;

      // Verifica se value é um número e não é zero
      if (!isNaN(value) && value !== 0) {
        payload.amount = value;
      }

      if (linkValue) payload.description = linkValue;
      payload.type = "PIX_STATIC"; // Este campo parece ser fixo, então pode ser adicionado diretamente

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pix/generate/static`,
        payload,
        config
      );
      console.log("respose data aqui", response.data); // Imprime a resposta do backend no console

      const imageData = response.data.qrCodeImageBase64; // Assumindo que a propriedade 'image' contém os dados da imagem em base64
      setQrImage(imageData); // Atualiza o estado da imagem QR Code
      console.log("imagedata aqui", imageData);
      const emvData = response.data.qrCodePayload; // Atribua o valor de response.data.evm a emvData se response.data estiver definido
      setEmvData(emvData);

      console.log(emvData); // Certifique-se de que emvData está recebendo o valor esperado

      /// const imageData2 = response.data.image; // Assumindo que a propriedade 'image' contém os dados da imagem em base64
      // const responseId = payload.description; // Substitua por uma variável apropriada do seu contexto, se disponível
      // downloadImage(imageData, { id: responseId });

      // Lógica adicional aqui, se necessário
    } catch (error) {
      console.error(
        "Ocorreu um erro ao enviar os dados para o backend:",
        error
      );
    }
  };

  const downloadImage = (base64Image: string, data: { id: string }) => {
    // Remova o prefixo 'data:image/png;base64,' da string Base64
    const base64Content = base64Image.replace(/^data:image\/png;base64,/, "");

    // Decodifique a string Base64
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `qrCode-${data.id}.png`;
    link.click();
  };

  function parseType(type: string): string {
    if (type === "evp") {
      return "Aleatória";
    }

    if (type === "email") {
      return "E-mail";
    }

    if (type === "nationalRegistration") {
      return "CPF/CNPJ";
    }

    if (type === "celular") {
      return "Celular";
    }

    return type;
  }

  function parseStatus(status: string): string {
    if (status === "WAITING") {
      return "Aguardando";
    }

    if (status === "CREATED") {
      return "Aguardando";
    }

    if (status === "PROCESSED") {
      return "Em processamento";
    }

    if (status === "REGISTERED") {
      return "Registrada";
    }

    if (status === "PRE-CANCELED") {
      return "Em cancelamento";
    }

    if (status === "CANCELED") {
      return "Cancelada";
    }

    return status;
  }

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const inputWidth = `${Math.max(100, inputValue.length * 8)}px`; // Adjust the multiplier as needed
  const width = inputValue === "" ? "300px" : inputWidth;

  return (
    <Container>
      <Content>
        <Table>
          <TableWrapper>
            {/* <div className=" flex justify-end">
            <div className="mb-5">
            <Button title="Cadastrar nova chave" handleFunction={() => {
                //navigate("/pix/mykeys/new")
              }} />
            </div>
            </div>*/}
            <LeftDiv>
              {user.role === "WALLET" || user.role === "GRAPHIC" ? null : (
                <select
                  placeholder="Selecionar  Chave"
                  onChange={handleSelectChange}
                  value={inputValue}
                  style={{
                    border: "1px solid #b6b6b6",
                    margin: "24px 0",
                    outline: "none",
                    padding: "8px",
                    borderRadius: "8px",
                    width: width,
                    height: "38px",
                    paddingBottom: "2px",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <option value="" disabled selected>
                    Selecionar Chave:
                  </option>
                  {keyOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}

              <InputInsertValueQrCodeStatic
                onChange={(event, value, masked) =>
                  handleChange(event, value, masked)
                }
              />

              <input
                onChange={handleLinkChange}
                type="text"
                value={linkValue}
                maxLength={25} // Definindo o limite máximo de caracteres
                placeholder="Descrição opcional"
                style={{
                  // opacity: isLocked ? 0.5 : 1,
                  //backgroundColor: 'white',
                  border: "1px solid #b6b6b6",
                  //  backgroundColor: isLocked ? 'lightgrey' : 'white',
                  // border: isLocked ? '2px solid black' : '2px solid black',
                  margin: "24px 0",
                  outline: "none",
                  padding: "8px",
                  borderRadius: "8px",
                  //  width: isLocked ? inputWidth :inputWidth,
                  width: width,
                  height: "38px",
                  // width: inputWidth,
                  paddingBottom: "2px",
                  textAlign: "center",
                  color: "black",
                }}
              />

              <div className=" flex justify-end" style={{ gap: "5px" }}>
                <div className="mb-5">
                  <Button
                    title="Gerar QrCode"
                    handleFunction={handleGenerateQRCode}
                  />
                </div>
                <div className="mb-5">
                  <Button
                    title="Baixar QrCode"
                    handleFunction={handleDownloadQRCode}
                  />
                </div>
              </div>
              <PixTextArea pixString={isEmvData} />
            </LeftDiv>

            <RightDiv>
              <QrContainer>
                {qrImage ? (
                  <img
                    style={{
                      border: "3px solid #00cbda",
                      borderRadius: "6px",
                      padding: "2px",
                    }}
                    src={`${qrImage}`}
                    alt="QR Code"
                  />
                ) : (
                  <div>Aguardando QR Code...</div>
                )}
              </QrContainer>
            </RightDiv>
          </TableWrapper>
        </Table>
      </Content>
    </Container>
  );
};

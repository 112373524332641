import { FC, useState } from "react";
import { FormInput, FormSelectInput, GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { validateCPF, validateDate } from "src/utils/validations";
import { serviceError } from "../../services/ajio";
import { useNotification } from "../../hooks/notification";

import "./styles.css";
import { api } from "src/services/api";

const FormSchema = z.object({
  email: z
    .string({
      required_error: "Insira um e-mail",
    })
    .email("O e-mail fornecido não é válido."),
  phone: z
    .string({
      required_error: "Insira seu número",
    })
    .min(10, {
      message: "O número de telefone deve ter pelo menos 10 caracteres.",
    })
    .max(15, {
      message: "O número de telefone não pode ter mais de 15 caracteres.",
    }),

  name: z.string({
    required_error: "Insira o seu Nome",
  }),
  document: z.string({
    required_error: "Insira seu CPF ou CNPJ.",
  }),
  birthDate: z.string({
    required_error: "Insira sua Data de nascimento.",
  }),
  gender: z.string({
    required_error: "Insira seu Gênero.",
  }),
  maritalStatus: z.string({
    required_error: "Insira seu Estado Cívil.",
  }),
  education: z.string({
    required_error: "Insira seu Nível De Educação",
  }),
  monthlyIncome: z.string({
    required_error: "Insira sua Renda mensal.",
  }),
});

type FormInputs = z.infer<typeof FormSchema>;

export const InsertAccountData: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const { setOpenNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setError } = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data: FormInputs) => {
    if (!data.name.includes(" ") || data.name.length < 5) {
      setError("name", { message: "Preencha o nome completo." });
      return;
    }

    if (!validateCPF(data.document.replace(/[/,.,-\s]/g, ""))) {
      setError("document", { message: "CPF Inválido." });
      return;
    }

    if (!validateDate(data.birthDate)) {
      setError("birthDate", { message: "Preencha a data de emissão completa." });
      return;
    }

    setLoading(true);

    try {
      function formateCpf(cpf: string) {
        return cpf.replace(/\D/g, "");
      }

      const cpf = formateCpf(data.document);
      console.log(cpf);
      const response = await api.get(`graphic/verify/email/${data.email}/${cpf}`);
      console.log(response);
      if (response.status === 200) {
        localStorage.setItem("@yntech:signup-data-01", JSON.stringify(data));
        setLoading(false);
        navigate("/create-account-wallet/choose-plan", { state: { accountType } });
      }
    } catch (err) {
      setLoading(false);
      setOpenNotification({
        title: "Alerta",
        notification: " E-mail ou Cpf já esta cadastrado.",
        type: "warning",
      });
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/choose-account-type", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="700" height="95vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="670"
            height="93vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[8vh] ml-20">
          <div className="w-full flex items-start gap-10">
            <p className="text-white font-medium text-lg mr-28 max-2xl:text-sm">
              Você está a poucos passos de uma <br />
              nova <strong>experiência financeira</strong>
            </p>

            <GradientButton title="1/5" tailwindStyles="bg-black text-white w-14" onClick={() => console.log("")} />
          </div>

          <p className="text-white text-base font-light mt-3 mb-4 max-2xl:text-xs">
            Todos os dados a seguir devem ser <br />
            obrigatoriamente do titular.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item: any, index: number) => {
              return (
                <div key={item.id}>
                  {item.isDropdown && (
                    <Controller
                      name={item.id as any}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormSelectInput
                          placeholder={item.placeholder}
                          options={item!.options!}
                          onChanged={(e) => field.onChange(e)}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  )}

                  {!item.isDropdown && item.breakPoint === false && (
                    <Controller
                      name={item.id as any}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormInput
                          onChangeText={(e) => field.onChange(e)}
                          height={55}
                          placeholder={item.placeholder}
                          required
                          {...field}
                          mask={item.mask}
                          errorMessage={fieldState.error?.message}
                        />
                      )}
                    />
                  )}

                  {item.breakPoint && (
                    <div className="flex items-center gap-5 justify-between">
                      <Controller
                        name={item.id as any}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormInput
                            autoFocus
                            onChangeText={(e) => field.onChange(e)}
                            style={{
                              width: 230,
                            }}
                            height={55}
                            placeholder={item.placeholder}
                            required
                            {...field}
                            mask={field?.value?.length > 0 ? item.mask : undefined}
                            errorMessage={fieldState.error?.message}
                          />
                        )}
                      />
                      <Controller
                        name={fields[index + 1].id as any}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormInput
                            onChangeText={(e) => field.onChange(e)}
                            height={55}
                            style={{
                              width: 230,
                            }}
                            placeholder={fields[index + 1].placeholder}
                            required
                            {...field}
                            mask={fields[index + 1].mask}
                            errorMessage={fieldState.error?.message}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              );
            })}

            <div className="flex w-full gap-5 items-center justify-between mt-7">
              <GradientButton
                isLoading={loading}
                title="Voltar"
                tailwindStyles="bg-[#171717] text-white w-[16rem] h-12"
                onClick={() => navigate("/create-account-wallet/choose-account-type")}
              />
              <GradientButton
                isLoading={loading}
                title="Avançar"
                tailwindStyles="bg-[#274683] text-white w-[16rem] h-12"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const fields = [
  {
    id: "name",
    placeholder: "Nome",
    breakPoint: false,
  },
  {
    id: "email",
    placeholder: "E-mail",
    breakPoint: false,
  },
  {
    id: "phone",
    placeholder: "Telefone",
    mask: "(99) 99999-9999",
    breakPoint: true,
  },
  {
    id: "document",
    placeholder: "CPF",
    mask: "999.999.999-99",
    breakPoint: undefined,
  },
  {
    id: "maritalStatus",
    placeholder: "Estado Civil",
    isDropdown: true,
    breakPoint: false,
    options: [
      "Solteiro(a)",
      "Casado(a)",
      "Separado(a) Legalmente",
      "Divorciado(a)",
      "União Estável",
      "Desimpedido(a)",
      "Viúvo(a)",
      "Outros",
    ],
  },
  {
    id: "education",
    placeholder: "Nível de educação",
    isDropdown: true,
    options: [
      "Analfabeto",
      "Ensino Fundamental - Anos Iniciais (1 a 4 anos)",
      "Ensino Fundamental - 5º ano",
      "Ensino Fundamental - 6º a 9º ano",
      "Ensino Fundamental Completo",
      "Ensino Médio Incompleto",
      "Ensino Médio Completo",
      "Ensino Superior Incompleto",
      "Ensino Superior Completo",
      "Pós-Graduação",
      "Mestrado",
      "Doutorado",
    ],
  },
  {
    id: "monthlyIncome",
    placeholder: "Renda Mensal",
    mask: "currency",
    breakPoint: true,
  },
  {
    id: "birthDate",
    placeholder: "Data de nascimento ",
    mask: "99/99/9999",
    breakPoint: undefined,
  },
  {
    id: "gender",
    placeholder: "Gênero",
    isDropdown: true,
    options: ["Masculino", "Feminino"],
    breakPoint: false,
  },
];

import styled from "styled-components"

import {ReactComponent as PixIconSVG} from '../../assets/pix.svg'
import {ReactComponent as PixMyKeysIconSVG} from '../../assets/pixmykeys.svg'
import {ReactComponent as PixHelpMeIconSVG} from '../../assets/pixhelpme.svg'
import {ReactComponent as QrCodeIconSVG} from '../../assets/QR-Code-Free-Icon-0sdf.svg'



export const PixIcon = styled(PixIconSVG)`
fill: var(--cian);
`

export const QrCodeIcon = styled(QrCodeIconSVG)`
fill: var(--cian);
height: 60px;
width: 60px;
`

export const PixMyKeysIcon = styled(PixMyKeysIconSVG)`
fill: var(--cian);
`
export const PixHelpMeIcon = styled(PixHelpMeIconSVG)`
fill: var(--cian);
`

export const Container = styled.div`

min-height: calc(100vh - 80px);
background-color: var(--background);


`

export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;


`

export const Table = styled.div`

flex: 1;
padding: 32px;

`


export const TableWrapper = styled.div`

  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px){
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px){
      width: 100%;
  }


> table {

    width: 100%;
    border-radius: 8px;
    border: none;
    border-collapse: collapse;

    > tr {
        position: relative;

        &:first-child {

            &::after {
                content: '';
                height: 16px;
                width: 4px;
                background-color: var(--cian);
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
            }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
            text-align: start;
            font-family: var(--semiBold);
            font-size: 14px;
            color: var(--gray);
            padding: 16px;


            @media (min-width: 720px){
                font-size: 16px;
                padding: 21px 32px;
            }
        }

        > td {
            padding: 6px 16px;
            font-family: var(--regular);
            font-size: 10px;
            color: var(--gray-light-text);

            &:last-child {
                color: var(--red);
            }

                @media (min-width: 720px){
                    font-size: 14px;
                    padding: 12px 32px;

                }

            }
          }
        }


`

export const OptionsWrapper = styled.div`

width: 100%;
padding: 32px;
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 32px;


@media (min-width: 900px){

    justify-content: start;

}

`

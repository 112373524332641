export function formatTransactionType(
  item: {
    type: string;
    direction: string;
    description: string;
    status: string;
    response: { eventType: string; message: string };
    user: { id: string };
  },
  user: { id: string }
) {
  if (item.status === "pix_error") {
    return "Pix cancelado";
  } else if (
    item.type === "pix" &&
    item.direction === "in" &&
    item.status === "done"
  ) {
    return "Pix recebido de:";
  } else if (
    item.type === "pix" &&
    item.status === "done" &&
    item.direction === "out"
  ) {
    return "Pix para: ";
  } else if (item.direction === "out" && item.status === "denied") {
    return "Transação Cancelada";
  } else if (item.type === "internal" && item.direction === "in") {
    return "Recebido via: ";
  } else if (item.type === "internal" && item.direction === "out") {
    return "Retirado via: ";
  } else if (
    item?.response?.message ===
    "Prezado cliente, a partir do horário vigente as transferências podem ser processadas até o próximo dia útil. Se preferir você pode agendá-la ou utilizar o PIX via APP!"
  ) {
    return "Valor estornado fora do horário autorizado.";
  } else if (
    (item.response?.eventType == "PIX_RECEIVED" ||
      item.type == "PIX_MANUAL" ||
      item?.type == "PIX_STATIC") &&
    item.user?.id === user?.id
  ) {
    return "Pix Recebido";
  } else if (item.type === "withdraw") {
    return item.description;
  } else if (item.type == "BANKSLIP") {
    return "Recebimento de Boleto";
  } else if (item.type == "INTERNAL_TAX") {
    return "Taxa interna";
  } else if (item.type == "TED") {
    return "Transferência TED";
  } else if (item.type == "P2P") {
    return "Transferência interna";
  } else if (item.type == "PAGBANK_PAYMENT") {
    return "Repasse";
  } else if (
    item.type == "PAGBANK_PAYMENT_DISCOUNT" &&
    item.direction == "out"
  ) {
    return "Desconto de repasse";
  } else if (item.type == "payment") {
    return "Pagamento de boleto";
  } else if (item?.type?.toLowerCase().includes("pix")) {
    return "Pix Recebido";
  } else if (item?.type == "PAGBANK_PAYMENT_DISCOUNT") {
    return "Desconto de repasse";
  } else if (item.type === "P2P_TAX") {
    return "Ajuste de Tarifa";
  } else {
    return "Wallet Recarregada";
  }
}

import { ExclamationIcon } from "@heroicons/react/solid";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { useNotification } from "../../hooks/notification";
import { service } from "../../services/ajio";

import {
  ActionText,
  ButtonsWrapper,
  Container,
  Form,
  FormItem,
  FormWrapper,
  WrapperHeader,
} from "./styles";



export const PixTransferCopiaECola: React.FC = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedKey, setSelectedKey] = useState("Tipo de chave");
  const [mask, setMask] = useState<string | undefined>(undefined);

  const [key, setKey] = useState('');

  const { setOpenNotification } = useNotification();

  useEffect(() => {
    setKey('');
    if (selectedKey === 'CPF/CNPJ') {
      setMask('CPF_CNPJ');
    } else if (selectedKey === 'Celular') {
      setMask('(99) 99999-9999');
    } else {
      setMask(undefined);
    }

  }, [selectedKey]);


  const handleNextButton = async () => {
    setLoading(true);
    setError('');



    try {
      const { data } = await service.post("pix/decode", {
        content: key
      });

      setLoading(false);
      navigation("/pix/copiaecola/confirm", {
        state: { ...data.pix }
      });
    } catch (err) {
      setLoading(false);
      setError('Não foi possível buscar informações do Pix nesse momento. Tente novamente');
    }
  };

  const handleGoBack = () => {
    navigation(-1);
  };

  const handleCancelButton = () => {
    navigation("/pix");
  };


  const TitleInput = "Informe o código copie e cola";

  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Nova transferência (Copia & Cola)"
      />
      </WrapperHeader>
      <Form>
        <FormWrapper>
          <FormItem>
          <ActionText>
          {TitleInput}
          </ActionText>
            <TextInput
              borderColor="#E3E3E3" borderRadius="4px"
              value={key}
              onChange={input => setKey(input.target.value)}
            />
          </FormItem>
        </FormWrapper>
        {!!error && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
        )}
        <ButtonsWrapper>
        <Button
          color="#e3e3e3"
          title="Cancelar"
          handleFunction={handleCancelButton}
        />
        {/* <button type="button" className="bg-[#49D294] px-10 py-2 rounded-md text-white flex" onClick={handleNextButton}>
          <Loading isLoading={loading}/>
          Continuar
        </button> */}

       <Button type="button" isLoading={loading} handleFunction={handleNextButton} title="Continuar" />
      </ButtonsWrapper>
      </Form>
    </Container>
  );
};

import React, { useState } from "react";

import { AboutUs, Container, ContentWrapper, Nav, Option, Options, OptionTitle } from "./styles";

import { ReactComponent as WhatsappIcon } from "../../assets/LPwhatsappicon.svg";
import { ReactComponent as MailIcon } from "../../assets/LPmailicon.svg";
import { ReactComponent as Facebook } from "../../assets/LPfacebookicon.svg";
import { ReactComponent as Instagram } from "../../assets/LPinstagramicon.svg";
import { ReactComponent as Linkedin } from "../../assets/LPlinkedinicon.svg";

const Footer: React.FC = () => {
  const [currentScreen, setCurrentScreen] = useState(window.location.pathname);

  return (
    <Container>
      <ContentWrapper>
        <Nav>
          <AboutUs>
            <strong>Sobre Nós</strong>
            <p>
              Nascemos para fazer diferente. Participamos da vida de milhões de pessoas, reunindo em uma mesma
              plataforma soluções que fazem a diferença no seu dia a dia.
            </p>
          </AboutUs>
          <Options>
            <OptionTitle>Site Map</OptionTitle>
            <Option href="home#Product">O produto</Option>
            <Option href="home/#Account">Conta Corrente</Option>
            <Option href="home/#Benefits">Beneficio</Option>
            <Option href="home/#FAQ">FAQ</Option>
          </Options>
          <Options>
            <OptionTitle>Fale Conosco</OptionTitle>
            <Option href="#">
              <WhatsappIcon />
              (12) 12345-1234
            </Option>
            <Option href="#">
              <MailIcon />
              sac@vilapaybank.com.br
            </Option>
            <Option className="underline" href="#">
              Uso de Cookies
            </Option>
            {currentScreen !== "/privacy" && (
              <Option className="underline" href="/privacy">
                Politíca de privacidade
              </Option>
            )}
          </Options>
          <Options>
            <OptionTitle>Siga-nos</OptionTitle>
            <Option className="underline" href="https://www.facebook.com/">
              <Facebook />
              Facebook
            </Option>
            <Option className="underline" href="https://www.instagram.com/">
              <Instagram />
              Instagram
            </Option>
            <Option className="underline" href="https://www.linkedin.com/">
              <Linkedin />
              Linkedin
            </Option>
          </Options>
        </Nav>
      </ContentWrapper>
    </Container>
  );
};

export default Footer;

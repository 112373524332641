import { X } from "lucide-react";
import React, { FC } from "react";

const Modal: FC<{ open: boolean; onClickClose: () => void }> = ({
  open,
  children,
  onClickClose,
}) => {
  return open ? (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClickClose();
      }}
      onMouseDown={(e) => e.stopPropagation()}
      className="flex fadeIn z-50 items-center justify-center fixed w-screen h-screen top-0 left-0 bg-black bg-opacity-75"
    >
      <div
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col bg-white rounded-lg shadow-xl p-6 relative"
      >
        <button
          onMouseDown={(e) => e.stopPropagation()}
          className="absolute right-2 top-2"
          onClick={onClickClose}
        >
          <X size={20} color="rgb(170, 170, 170)" />
        </button>
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Modal;

import styled, { css } from "styled-components";

// import striclaptopimage from "../../assets/striclaptopimage.png";
import SignInImage from "../../assets/SignUpBackgroundPNG.png";
import ButtonSignInColor from "../../../src/assets/button-color.svg";

interface CheckBoxProps {
  isActive: boolean;
}

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-image: url(${SignInImage});
  background-position: center;
  background-size: cover;
  justify-content: end;
  flex: 1;
`;

export const WrapperLeft = styled.div`
  background-color: var(--gray);

  @media (min-width: 840px) {
    display: block;
  }
`;

export const WrapperRight = styled.div`
  min-height: 100vh;
  flex: 1;
  max-width: 50%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(3px) saturate(200%);
  -webkit-backdrop-filter: blur(5px) saturate(200%);
  background-color: rgba(220, 220, 220, 0.7);
  border: 1px solid rgba(209, 213, 219, 0.3);
  flex-direction: column;
`;

export const GoBackButtonWrapper = styled.button`
  font-size: 14px;
  color: var(--white);
  text-decoration: underline;
  background-color: transparent;
  border-style: none;
  text-align: start;

  > svg {
    margin-right: 12px;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -50px;
`;

export const Title = styled.span`
  font-size: 30px;
  color: var(--gray);
  font-weight: var(--light);
  max-width: 384px;
  line-height: 35px;
  margin-bottom: 21px;

  > strong {
    font-weight: var(--bold);
  }
`;

export const Text = styled.span`
  font-size: 16px;
  color: var(--gray-1-lp);
  font-family: var(--regular);
  max-width: 410px;
  line-height: 28px;
  margin-bottom: 21px;
`;

export const TextRequirement = styled.strong`
  text-transform: uppercase;
  color: var(--gray);
  font-size: 16px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--white);
  position: relative;
  text-align: center;

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin: 0px 0px 14px 0px;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 12px 0px 0px;
  }
`;

export const Requirement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 14px 0px;
`;

export const WrapperDivButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const WrapperButton = styled.div`
  width: 100%;
  /* @media (min-width: 840px) {
    max-width: 176px;
  } */
`;

export const WrapperButtonBack = styled.div`
  width: 100%;
  padding: 0 0 0 0px;
  /* @media (min-width: 840px) {
    max-width: 176px;
  } */
`;

export const WrapperForm = styled.form`
  width: 70%;
  align-self: center;
`;

export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
`;

export const Label = styled.span`
  font-size: 14px;
  color: var(--gray);
  font-weight: var(--regular);
`;

export const WrapperOptions = styled.div`
  display: flex;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;

  > label {
    font-size: 18px;
    font-weight: var(--bold);
    color: var(--gray-1-lp);
    margin-left: 15px;
  }

  & + div {
    margin-left: 78px;
  }
`;

export const CheckBox = styled.div<CheckBoxProps>`
  height: 24px;
  width: 24px;
  border: 1px solid var(--cian);
  border-radius: 4px;
  background-color: ${(props) =>
    props.isActive ? "var(--cian)" : "transparent"};
`;

export const Button = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(${ButtonSignInColor}); */
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;

  font-family: var(--multi-bold);
  color: var(--white);
  font-size: 16px;
  font-weight: var(--extraBold);

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
  }
`;

export const ResendButton = styled.button`
  /* width: 100%; */
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #343434;
  text-decoration: none;

  padding: 0 15px;
  margin-top: 25px;

  margin-bottom: 15px;

  font-family: var(--multi-bold);
  color: white;
  font-size: 16px;
  font-weight: var(--extraBold);

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
  }
`;

interface ContainerProps {
  nomargin?: boolean;
}

export const ContainerNoMargin2 = styled.div<ContainerProps>`
  // Seus estilos aqui

  margin-bottom: ${(props) => (props.nomargin ? "0" : "27px")};
  // Adapte isso conforme necessário para suas necessidades
`;

export const ContainerNoMargin = styled.div<ContainerProps>`
  width: 100%;
  /* height: 66px; */
  border: 1px solid var(--cian);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  margin-bottom: ${(props) => (props.nomargin ? "0" : "27px")};
  > input {
    flex: 1;
    border-style: none;
    outline: none;
    background: none;
  }

  > select {
    flex: 1;
    background: none;
    font-size: 16px;
    padding: 3px 0px;
    outline: none;
    border-style: none;
  }

  & + div {
    /* margin-top: 27px; */

    ${(props) =>
      props.nomargin &&
      css`
        margin-top: 0;
      `}
  }
`;

//////////////////

interface WrapperInterface {
  image: string;
  bank: string;
}

export const ContainerCreateWallet = styled.div<WrapperInterface>`
  ${(props) =>
    props.bank === "PIXWAVE BANK"
      ? css<WrapperInterface>`
          background: url(${(props) => props.image}) no-repeat;
          background-size: cover;
        `
      : css`
          background-color: var(--gray);
        `}

  @media (max-width: 720px) {
    background-image: none;
    background-color: var(--gray);
    ${(props) =>
      props.bank === "PIXWAVE BANK"
        ? css<WrapperInterface>`
            background-color: black;
          `
        : css`
            background-color: var(--gray);
          `}
  }

  min-height: 100vh;
  max-width: 100vw;

  //overflow: scroll;
  display: flex;
`;

export const ContentWrapperCreateWallet = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  display: flex;
`;

export const WrapperLeftCreateWallet = styled.div<WrapperInterface>`
  flex: 1;
  min-height: 100vh;
  display: none;

  @media (min-width: 840px) {
    ${(props) =>
      props.bank === "PIXWAVE BANK"
        ? css<WrapperInterface>`
            background-image: none;
          `
        : css`
            background-image: url(${SignInImage});
          `}

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: unset;
  }
`;

export const WrapperRightCreateWallet = styled.div<WrapperInterface>`
  flex: 0.5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: "70px";

  ${(props) =>
    props.bank === "PIXWAVE BANK"
      ? css<WrapperInterface>`
          background-color: transparent;
        `
      : css`
          background-color: white;
        `}
`;

export const GoBackButtonWrapperCreateWallet = styled.button`
  font-size: 14px;
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border-style: none;
  text-align: start;

  > svg {
    margin-right: 12px;
  }
`;

export const WrapperContentCreateWallet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 488px;
  flex: 1;
  margin: 0 auto;
  //margin-right: 5px;
 // border: 2px solid pink;
  align-items: center;

  > svg {
    margin-bottom: 45px;
  }
`;

// export const Title = styled.span`
//   font-size: 42px;
//   color: var(--white);
//   font-weight: var(--light);
//   max-width: 384px;
//   line-height: 52px;
//   margin-bottom: 14px;

//   > strong {
//     font-weight: var(--bold);
//   }
// `;
export const TitleCreateWallet = styled.span`
  font-size: 42px;
  color: var(--white);
  font-weight: var(--light);
  max-width: 384px;
  line-height: 52px;
  margin-bottom: 14px;
  margin-left: 30px;
  padding: 5px;

  > strong {
    font-weight: var(--bold);
   // background-image: url(${ButtonSignInColor});
   background-color: #a26b37;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
export const TextCreateWallet = styled.span`
  font-size: 16px;
  color: var(--white);
  font-family: var(--regular);
  max-width: 384px;
  line-height: 28px;
  display: none;

  @media (min-width: 840px) {
    display: unset;
  }
`;

export const TextRequirementCreateWallet = styled.strong`
  text-transform: uppercase;
  color: var(--gray);
  font-size: 16px;
`;

export const ListWrapperCreateWallet = styled.div`
  margin-bottom: 63px;
  margin-top: 44px;
`;

export const SubtitleCreateWallet = styled.div`
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--white);
  position: relative;
  text-align: center;

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const LineCreateWallet = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin: 0px 12px 0px 0px;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const RequirementCreateWallet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 14px 0px;
`;

export const WrapperButtonCreateWallet = styled.div`
  width: 100%;
  @media (min-width: 840px) {
    max-width: 176px;
  }
`;

export const ButtonNotOpenAccount = styled.a`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cian);
  text-decoration: none;

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
  }
`;

import styled, { css } from "styled-components";

interface Props {
  nomargin?: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;
  height: 50px;
  background-color: #f6f6f6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  > input {
    flex: 1;
    border-style: none;
    outline: none;
    background: none;
  }

  > select {
    flex: 1;
    background: none;
    font-size: 16px;
    padding: 3px 0px;
    outline: none;
    border-style: none;
  }

  & + div {
    /* margin-top: 27px; */

    ${(props) =>
      props.nomargin &&
      css`
        margin-top: 0;
      `}
  }
`;

export const Label = styled.span`
  font-size: 14px;
  color: var(--gray);
  font-weight: var(--regular);
`;

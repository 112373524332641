import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { format } from "date-fns";
import logoImage from "../../../../src/assets/clients/vilapaybank/logo/white.png";
import { ExtractType, Account } from "..";
import { currencyFormat } from "../../../utils/currencyFormat";

export class GeneratePDF {
  public pdf = new jsPDF("p", "mm", "a4");
  public config = {
    marginLeft: 15,
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
  };
}

export class GerenciarContasHandlePDF extends GeneratePDF {
  constructor(
    private readonly transactions: ExtractType[],
    private readonly account: Account
  ) {
    super();
  }
  private header(marginTop: number) {
    this.pdf.addImage(logoImage, "PNG", 50, marginTop, 100, 20);
  }
  private build(item: ExtractType) {
    const amount = currencyFormat(item.amount);
    const negative = item.direction === "out" ? "-" : "";
    const formatAmount = `${negative}${amount}`;
    const created_at = format(new Date(item.created_at), "dd/MM/yyyy");

    return {
      ...item,
      created_at,
      amount: formatAmount,
    };
  }
  private userAccount(marginTop: number) {
    this.pdf.setFontSize(12);
    this.pdf.text("Nome", this.config.marginLeft, marginTop);
    this.pdf.text(this.account.name, this.config.marginLeft + 30, marginTop);

    marginTop = marginTop += 6;
    this.pdf.text("CPF/CNPJ:", this.config.marginLeft, marginTop);
    this.pdf.text(
      this.account.document,
      this.config.marginLeft + 30,
      marginTop
    );
  }
  private extract(marginTop: number) {
    autoTable(this.pdf, {
      html: "#table-extract",
      margin: { top: marginTop },
    });

    const transactions = [];
    for (let i = 0; i < this.transactions.length; i++) {
      const item = this.transactions[i];
      const build = this.build(item);
      var statusName = "";

      if (build.status === "done") {
        statusName = "Aprovado";
      } else if (build.status === "not_approved") {
        statusName = "Não Aprovado";
      }
      transactions.push([
        build.created_at,
        build.description,
        build.amount,
        statusName,
      ]);
    }
    autoTable(this.pdf, {
      head: [["Data", "Descrição", "Valor", "Status"]],
      body: transactions,
    });
  }
  public generate() {
    this.header(9);
    this.userAccount(34);
    this.extract(35);

    this.pdf.save("Extrato.pdf");
  }
}

import { api } from 'src/services/api';
import React, { useEffect, useState } from 'react';

export function LostTransactionsOneHour() {
  const [data, setData] = useState(null);
  const token = localStorage.getItem("@backoffice:token");

  useEffect(() => {
    api.get('log/transactions/one', {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response: { data: React.SetStateAction<null>; }) => {
        setData(response.data);
      })
      .catch((error: any) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [token]);

  return (
    <div>
      {data ? (
        <>
          <p>GET TRANSACTIONS HISTÓRICO</p>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

import { FC, useRef, useState } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircle, UploadSimple } from "phosphor-react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "src/services/firebase";
import { api } from "src/services/api";

export const SendCCMEI: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const navigate = useNavigate();

  const documentInputRef = useRef<any>(null);

  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<any>(null);

  const handleDocumentUpload = () => {
    documentInputRef?.current?.click();
  };

  const handleSelectDocument = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        alert("O arquivo deve ter no máximo 10MB.");
        return;
      }
      setDocument(file);
    }
  };

  const handleSavePhoto = async () => {
    if (!document) {
      return;
    }

    setLoading(true);
    const storageRef = ref(storage, `documents/${document.name}`);
    const documentUploadTask = uploadBytesResumable(storageRef, document);
    documentUploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        console.log(error);
      },
      async () => {
        const documentUri = await getDownloadURL(documentUploadTask.snapshot.ref);
        if (documentUri) {
          localStorage.setItem("@yntech:ccmei", documentUri);

          setLoading(false);
          navigate("/create-account-wallet/invoicing-alert", { state: { accountType } });
        }
        setLoading(false);
      }
    );
  };

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background max-2xl:gap-0">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/pj-alert", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 20 }} width="750" height="85vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="700"
            height="80vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[23vh] ml-14 max-2xl:top-[15vh]">
          <p className="text-white font-extralight text-3xl">Faça o upload do seu arquivo em PDF</p>

          <h3 className="text-[#274683] font-extralight text-sm mt-3">
            Atenção o arquivo deve ter no máximo o tamanho de 10mb.
          </h3>

          <h3 className={`text-white font-extralight text-base mt-3 mb-3`}>Selecione a foto em seu computador:</h3>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.odt"
            onChange={handleSelectDocument}
            ref={documentInputRef}
            style={{ display: "none" }}
          />

          <button
            onClick={handleDocumentUpload}
            className={`w-[35rem] h-48 rounded-2xl bg-white flex items-center justify-center mt-3 mb-3`}
          >
            {document !== null ? <CheckCircle color="#4BBD9C" size={50} /> : <UploadSimple color="#CFCFCF" size={32} />}
          </button>

          <div className="flex gap-4 mt-10">
            <GradientButton
              title="Voltar"
              tailwindStyles="bg-[#171717] text-white w-[17.7rem] h-12"
              onClick={() => navigate("/create-account-wallet/pj-alert", { state: { accountType } })}
            />
            <GradientButton
              isLoading={loading}
              title="Avançar"
              tailwindStyles="bg-[#274683] text-white w-[17.7rem] h-12"
              onClick={handleSavePhoto}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

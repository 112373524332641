import React, { Fragment, FormEvent, useState, useRef, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../../components/button";
import { InputInsertValue } from "../../components/inputValue";
import { StepHeader } from "../../components/stepHeader";
// import {PasswordInput} from "../../components/passwordInput";
// import { useModalPix } from "../../hooks/modalPix";

import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";

import { banks } from "../../utils/banks";

import {
  BoxInfoWrapper,
  // BoxPasswordWrapper,
  ButtonsWrapper,
  Container,
  DataWrapper,
  DateComponent,
  InfoWrapper,
  Key,
  // LabelInput,
  Name,
  WrapperBoxContent,
  WrapperHeader,
} from "./styles";
import { documentFormatted } from "../../utils/documentFormatted";
import { format } from "date-fns";
import brasilLocale from "date-fns/locale/pt-BR";
// import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import TextInput from "../../components/TextInput";
import { service, serviceError } from "../../services/ajio";
import usePerformP2PTransaction from "src/components/transferTax";
import KeyboardPressPasswordEletronic from "src/components/keyPressPasswordeletronic";
import { useNotification } from "src/hooks/notification";
import { api } from "src/services/api";

interface StateProps {
  endToEndId: string;
  key: string;
  selectedKey: string;
  beneficiary: {
    number: string;
    branch: string;
    type: string;
    participant: {
      name: string;
      ispb: string;
    };
    holder: {
      name: string;
      document: string;
      type: string;
    };
  };
}

export interface PixProps {
  id: string;
}

export const PixConfirm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("@stricv2:token");
  const { account, refresh, user } = useAuth();
  // const { openPixModal } = useModalPix();

  const formRef = useRef<HTMLFormElement | null>(null);

  const [open, setOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [value, setValue] = useState(0);
  const [maskedValue, setMaskedValue] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [pixData, setPixData] = useState<PixProps>({} as PixProps);
  const [isFavorite, setIsFavorite] = useState({ isFavorite: false });

  const [pinOpen, setPinOpen] = useState(false);
  const [pin, setPin] = useState("");
  const [hasPin, setHasPin] = useState(false);
  const [valueEletronic, setValueEletronic] = useState("");
  const { performP2PTransactions } = usePerformP2PTransaction();
  const [clickedValues, setClickedValues] = useState<string[]>([]);

  console.log(
    "oi sou eu clickedValues no state do inicio do codigo do pix confirm",
    clickedValues
  ); //aquitao problema so chega o array com umaposiçao amenos renderizada

  //console.log("oi sou eu a senha eletronica", account.security_eletronic)
  console.log("oi sou eu a senha eletrônica", account.security_eletronic);
  console.log("oi sou eu a senha pin", account.pin);
  console.log("Acc", location.state);

  const state = location.state as StateProps;

  useEffect(() => {
    handleCheckIfKeyPixIsUsed(state.key);
  }, []);

  if (state === null) {
    navigate("/pix");
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancelButton = () => {
    navigate("/pix");
  };

  const handleConfirmButton = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Verifica se 'graphic_balance' é um número
    const isGraphicBalanceNumber = typeof account?.graphic_balance === "number";
    console.log(isGraphicBalanceNumber);

    // // Atribui à variável 'result' o resultado da verificação
    // const resultBalance = isGraphicBalanceNumber
    //   ? account.balance - account.graphic_balance
    //   : account.balance;

    // // Agora 'result' contém o valor desejado com base na condição

    // // Exemplo de uso ou exibição
    // console.log(
    //   "Resultado do saldo disponivel do resultBalance:",
    //   resultBalance
    // );

    // const percentualMinimoAdicional = 0.25; // Altere para o percentual desejado (0,25%)
    // const valorMinimoAdicional =
    //   (percentualMinimoAdicional / 100) * resultBalance;

    // // Adiciona o percentual mínimo adicional ao saldo mínimo de 26 centavos
    // const saldoMinimoNecessario = 0.26 + valorMinimoAdicional;

    // // Exemplo de uso ou exibição
    // console.log("Saldo mínimo necessário:", saldoMinimoNecessario);
    // const taxa = 0.0025; // Taxa de 0,25%
    // let amount = value; // Valor da transação
    // console.log("ou eu sou o valor do value", amount);

    // // Calcula o valor da taxa
    // let valorTaxa = amount * taxa;

    // // Calcula o novo valor total após a aplicação da taxa
    // let novoValorTotal = amount + valorTaxa;
    // console.log("oi sou o novoValorTotal", novoValorTotal);

    // // Arredonda para cima
    // let novoValorTotalRedondo = Math.ceil(valorTaxa * 100) / 100; // Arredonda para 2 casas decimais
    // console.log("oi sou o novoValorTotalRedondo", novoValorTotalRedondo);
    // // Agora, `novoValorTotal` é o valor total após a aplicação da taxa e arredondamento para cima.
    // let valorFinal = novoValorTotalRedondo - value;
    // let novoAmount = value >= 99.99 ? novoValorTotalRedondo : 0.25;
    // console.log("oi tio olha aqui o valorFinal: ", valorFinal);

    // console.log("oi tio olha aqui o novoAmount: ", novoAmount);

    // let taxAddAmount = amount + novoValorTotalRedondo;
    // console.log("Resultado do saldo disponivel:", taxAddAmount);

    // // Verifica se o saldo disponível é menor que o saldo mínimo necessário
    // console.log("RESULTE BALANCE", resultBalance);
    // console.log("RESULTE BALANCE", saldoMinimoNecessario);

    try {
      const build = {
        endToEndId: state.endToEndId,
        amount: value,
        initiationType: "KEY",
        description: "",
        // pin: account.pin,
        // payer: {
        //   key: state.key,
        //   name: state.beneficiary.name,
        //   document: state.beneficiary.document,
        //   bank_account: {
        //     branch: state.bank_account.branch,
        //     bank_name: state.bank_account.bank_name,
        //   },
        // },
      };

      const { data } = await service.post("/pix", build);
      // Após o sucesso da transação PIX, chame a transação P2P
      // await performP2PTransactions({
      //   amount: novoAmount,
      //   endToEndId: state.endToEndId,
      // });

      setPixData(data);
      setLoading(false);
      setOpen(true);
      refresh();
    } catch (err) {
      setLoading(false);
      const error = serviceError(err);
      setError(error.message);
      setHasPin(false);
    }
  };

  function parseAgency() {
    // if (state.ReceiverBankBranch.length === 1) {
    //   return `000${state.ReceiverBankBranch}`;
    // }

    // if (state.ReceiverBankBranch.length === 2) {
    //   return `00${state.ReceiverBankBranch}`;
    // }

    // if (state.ReceiverBankBranch.length === 3) {
    //   return `00${state.ReceiverBankBranch}`;
    // }

    // return state.ReceiverBankBranch;

    return state.beneficiary.branch;
  }

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }

  const handleAddContact = async (state: StateProps) => {
    let url = `/pix/favorites`;
    let requestData = {
      // Renomeando para requestData
      contactName: state.beneficiary.holder.name,
      keyPix: documentFormatted(state.key),
      keyType: state.selectedKey,
    };
    try {
      const response = await api.post<any[]>(url, requestData, {
        headers: { Authorization: "Bearer " + token },
      });
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Erro ao adicionar contato:", error);
    }
  };

  const handleCheckIfKeyPixIsUsed = async (keyPix: string): Promise<void> => {
    try {
      const response = await api.get(`/pix/favorites/check/${keyPix}`, {
        headers: { Authorization: "Bearer " + token },
      });
      setIsFavorite(response.data.isFavorite);
      console.log(isFavorite);
    } catch (error) {
      console.error(
        "Erro ao verificar se o keyPix está sendo utilizado:",
        error
      );
      throw error;
    }
  };

  const handleKeyPress = (prevValue: string) => {
    // ...
    setClickedValues((prevValue: any) => [...prevValue]);
    // ...
  };

  const handlePasswordVerification = (clickedValues: string[]) => {
    const securityCode = account.security_eletronic;
    console.log("sou securityCode", securityCode);
    console.log(
      "sou clickedValues de dentro do handlePasswordVerification ",
      clickedValues
    );

    // Converte o array de valores para uma string
    const clickedValuesString = clickedValues.join("").trim();
    console.log(
      "sou clickedValuesString de dentro do handlePasswordVerification ",
      clickedValuesString
    );
    const handleConfirm = (passwords: string[]) => {
      // Lógica para lidar com a confirmação da senha
      setPinOpen(false);
      formRef.current?.requestSubmit();
    };
    // Verifique cada par de dígitos em clickedValuesString com os dígitos em securityCode
    const isPasswordCorrect = clickedValues.every((_, index) => {
      const start = index * 2;
      const end = start + 2;
      const clickedPair = clickedValuesString.substring(start, end);
      const securityPair = securityCode.substr(start, 2);

      console.log(
        `Comparando: clickedPair = ${clickedPair}, securityPair = ${securityPair}`
      );

      const isPairCorrect = clickedPair === securityPair;

      console.log(
        `Par ${index + 1}: ${isPairCorrect ? "Correto" : "Incorreto"}`
      );

      return isPairCorrect;
    });
    console.log(
      "sou isPasswordCorrect de dentro do handlePasswordVerification ",
      isPasswordCorrect
    );

    if (isPasswordCorrect) {
      // Senha correta, faça o que for necessário
      console.log("Senha correta!");
      console.log("sou boy 3");
    } else {
      // Senha incorreta, faça o que for necessário
      console.log("Senha incorreta!222222");
      console.log("sou boy 2");
    }
  };

  const updateValueEletronic = (newValue: any) => {
    setValueEletronic(newValue);
  };

  const handleUpdateClickedValues = (values: string[]) => {
    setClickedValues(values);
  };

  const { setOpenNotification } = useNotification();

  // Função para atualizar o estado com o valor recebido do componente filho

  console.log(pixData);

  return (
    <Container>
      <WrapperHeader>
        <StepHeader
          handleGoBackFunction={handleGoBack}
          title="Confirme e transfira"
        />
      </WrapperHeader>
      <BoxInfoWrapper>
        <WrapperBoxContent>
          <InfoWrapper>
            <Name>
              {state.beneficiary?.holder?.name?.toUpperCase()}
              <br />
              {documentFormatted(state.beneficiary?.holder?.document)}
            </Name>
            <DataWrapper>
              <span>Banco</span>
              <Key>{state.beneficiary.participant.name}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>Agência/Conta</span>
              <Key>
                {parseAgency()} {state.beneficiary.number}
              </Key>
            </DataWrapper>
            <DataWrapper>
              <span>Chave</span>
              <Key>{documentFormatted(state.key)}</Key>
            </DataWrapper>
          </InfoWrapper>
          <DateComponent>
            {format(new Date(), "dd LLL yyyy", {
              locale: brasilLocale,
            }).toUpperCase()}
          </DateComponent>
        </WrapperBoxContent>
        <InputInsertValue
          onChange={(event, value, masked) =>
            handleChange(event, value, masked)
          }
        />
      </BoxInfoWrapper>
      <form onSubmit={handleConfirmButton} ref={formRef}>
        {/* <BoxPasswordWrapper>
        <LabelInput>Senha</LabelInput>
        <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="********" />
      </BoxPasswordWrapper> */}

        {!!error && (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 w-[480px]">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">{error}</p>
              </div>
            </div>
          </div>
        )}
        <ButtonsWrapper>
          <Button
            handleFunction={handleCancelButton}
            color="#e3e3e3"
            title="Cancelar"
          />

          {/* <button type="submit" className="bg-[#49D294] w-96 px-10 py-2 rounded-md text-white flex justify-center">
          <Loading isLoading={loading}/>
          Continuar
        </button> */}
          {!hasPin ? (
            <Button
              type="button"
              isLoading={loading}
              title="Continuar para senha"
              handleFunction={() => setPinOpen(true)}
            />
          ) : (
            <Button type="submit" isLoading={loading} title="Transferir" />
          )}

          <Transition.Root show={pinOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative bg-darkgoldenrod rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                      <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-white"
                          >
                            Senha
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="mt-5 flex justify-center">
                              <div className="w-48 ">
                                <TextInput
                                  placeholder="Senha Eletrônica"
                                  maxLength={8}
                                  value={valueEletronic}
                                  style={{ color: "black" }} // Adicione esta linha
                                  type="password"
                                  onChange={(input) =>
                                    setValueEletronic(input.target.value)
                                  }
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <span>bolinha quadradinha</span>*/}
                      <div className="mt-5 sm:mt-6">
                        {/*  <span>bolinha redondinha</span> */}
                        <KeyboardPressPasswordEletronic
                          updateParentInput={updateValueEletronic}
                          updateClickedValues={setClickedValues}
                          onKeyPress={handleKeyPress}
                          onConfirm={handlePasswordVerification}
                        />

                        <Button
                          type="submit"
                          isLoading={loading}
                          title="Transferir"
                          handleFunction={() => {
                            const handlePasswordVerification = (
                              clickedValues: string[]
                            ) => {
                              // Verificar se clickedValues está vazio
                              if (clickedValues.length === 0) {
                                console.error(
                                  "Erro: Nenhum valor fornecido. Digite a senha eletrônica."
                                );
                                return;
                              }

                              const securityCode = account.security_eletronic;
                              console.log("Sou securityCode", securityCode);
                              console.log(
                                "em handleFunction no pix confirm Sou clickedValues pra referencia fui resultado dos clicks do usuario no front end: ",
                                clickedValues
                              );

                              // Verifique cada posição de clickedValuesArray com os dígitos em securityCode
                              let isPasswordCorrect = true;

                              clickedValues.some((position, index) => {
                                // Remove 'ou' e espaços e divide a posição em um array
                                const digitsArray: string[] = position
                                  .split(" ou ")
                                  .map((digit) => digit.trim());

                                console.log(
                                  `Posição ${
                                    index + 1
                                  } - Original: ${position}, digitsArray:`,
                                  digitsArray
                                );

                                // Verifica se temos exatamente dois dígitos na posição (exceto na última posição)
                                if (digitsArray.length !== 2) {
                                  if (index === 7) {
                                    // Se for a última posição, verifica se tem apenas um dígito
                                    if (digitsArray.length !== 2) {
                                      console.error(
                                        `Erro: posição ${
                                          index + 1
                                        } não possui um dígito válido.`
                                      );
                                      isPasswordCorrect = false;
                                      return true; // Sai do loop
                                    }
                                  } else {
                                    console.error(
                                      `Erro: posição ${
                                        index + 1
                                      } não possui dois dígitos válidos.`
                                    );
                                    isPasswordCorrect = false;
                                    return true; // Sai do loop
                                  }
                                }

                                // Continua com a lógica original de comparação
                                const digitOptionsString = digitsArray.join("");
                                const securityDigit = securityCode[index];
                                console.log(
                                  `Posição ${
                                    index + 1
                                  } - Comparando: digitOptions = ${digitOptionsString}, securityDigit = ${securityDigit}`
                                );

                                // Ajuste na lógica de comparação
                                const isDigitCorrect = digitsArray.some(
                                  (digit) => digit === securityDigit
                                );
                                console.log(
                                  `Posição ${index + 1}: ${
                                    isDigitCorrect ? "Correta" : "Incorreta"
                                  }`
                                );

                                if (!isDigitCorrect) {
                                  isPasswordCorrect = false;
                                  console.error(
                                    "Senha incorreta! Algum dígito está errado."
                                  );
                                  setOpenNotification({
                                    notification:
                                      "Verifique sua senha e tente novamente.",
                                    title: "Senha incorreta",
                                    type: "error",
                                  });
                                  return true; // Sai do loop
                                }

                                return false; // Continua para a próxima posição
                              });

                              if (isPasswordCorrect) {
                                // Senha correta, faça o que for necessário
                                console.log("Senha correta!");
                                console.log("Sou boy 3");
                                setHasPin(true);
                                setPinOpen(false);
                                formRef.current?.requestSubmit();
                              } else {
                                // Senha incorreta, faça o que for necessário
                                console.error(
                                  "Senha incorreta! Toda a senha está incorreta."
                                );
                                console.log("Sou boy 23");
                              }
                            };

                            handlePasswordVerification(clickedValues);
                          }}
                        />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </ButtonsWrapper>
      </form>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Transferência Pix realizada
                        <br />
                        {maskedValue}
                      </Dialog.Title>
                      <div className="mt-2 text-left">
                        <p className="text-sm text-gray-500">
                          <h2 className="mb-5 mt-5 text-lg">
                            Dados do favorecido
                          </h2>
                          {/* <div>
                          <h2>Nome</h2>
                            <h3>{account.name}</h3>
                         </div> */}

                          {/* <div className="mt-5"> */}
                          {/* <h2>Documento</h2>
                            <h3>{documentFormatted(account.document)}</h3>
                         </div> */}

                          {/* <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3></h3>
                            </div>

                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>
                                {account.account_number}-{account.account_digit}
                              </h3>
                            </div>
                          </div> */}

                          {/* <h2 className="mb-5 mt-5 text-lg">
                            Dados da conta debitada
                          </h2> */}

                          <div>
                            <h2>Nome</h2>
                            <h3>{state.beneficiary.holder.name}</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Documento</h2>
                            <h3>
                              {documentFormatted(
                                state.beneficiary.holder.document
                              )}
                            </h3>
                          </div>

                          <div className="mt-5">
                            <h2>Banco</h2>
                            <h3>{state.beneficiary.participant.name}</h3>
                          </div>

                          <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>{parseAgency()}</h3>
                            </div>

                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>{state.beneficiary.number}</h3>
                            </div>
                          </div>

                          <div className="mt-5">
                            <h2>ID da transação</h2>
                            <h3>{pixData.id}</h3>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => onCloseModal()}
                    >
                      Ir para o extrato
                    </button>
                    {isFavorite ? (
                      <button
                        type="button"
                        className="inline-flex mt-4 justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 mt-2 py-2 text-base font-medium text-gray-700 bg-gray-100 cursor-not-allowed opacity-75 focus:outline-none sm:text-sm"
                        disabled
                      >
                        Contato já favoritado
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 mt-2 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm ${
                          showSuccessMessage
                            ? "bg-green-600 hover:bg-green-700 transition-colors duration-300 ease-in-out"
                            : "bg-green-500 hover:bg-green-600 transition-colors duration-300 ease-in-out"
                        }`}
                        onClick={() => {
                          handleAddContact(state);
                          setShowSuccessMessage(true);
                        }}
                      >
                        {showSuccessMessage
                          ? "Salvo com sucesso"
                          : "Adicionar contato aos favoritos"}
                      </button>
                    )}

                    <button
                      type="button"
                      className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                      // onClick={() => onCloseModal()}
                    >
                      Comprovante disponível no extrato
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Container>
  );
};

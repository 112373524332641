import React, { FC, useEffect, useRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import icon from "./../../../assets/clients/pixwave/logo/icon.png";
import { Fragment, useState } from "react";
import {
  Combobox,
  Dialog,
  Disclosure,
  Menu,
  Transition,
} from "@headlessui/react";
import {
  ChevronRightIcon,
  SearchIcon,
  UsersIcon,
  CogIcon,
} from "@heroicons/react/solid";

import { BellIcon, Box, ChevronDown, Settings } from "lucide-react";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import Modal from "src/components/Modal";
import { ManualTax } from "./ManualTax";
import { AnimatePresence } from "framer-motion";
import { BellMessage } from "src/components/bellMessage";
import {
  BoxWrapper,
  ButtonBell,
  BellWrapper,
  BellNotifications,
  BoxNotifications,
  DashboardInfo,
} from "src/components/header/styles";
import { api } from "src/services/api";
import Carousel from "src/components/carouselModule";


type OptionItem = {
  onSelect: () => void;
  selected: boolean;
  title: string;
};
interface User {
  // Outras propriedades do usuário
  role: string; // Suponho que a role seja uma string
}
const userRole: User = {
  // Outras propriedades do usuário
  role: "OPERATOR", // Role do usuário é OPERATOR
};

type Person = {
  id: number;
  name: string;
  phone: string;
  email: string;
  role: string;
  url: string;
  profileUrl: string;
  imageUrl: string;
};

const people = [
  {
    // id: 1,
    // name: 'Leslie Alexander',
    // phone: '1-493-747-9031',
    // email: 'lesliealexander@example.com',
    // role: 'Co-Founder / CEO',
    // url: 'https://example.com',
    // profileUrl: '#',
    id: 1,
    name: "Rudival Souza",
    phone: "1-493-747-9031",
    email: "ajiopay@gmail.com",
    role: "Co-Founder / CEO",
    url: "https://example.com",
    profileUrl: "#",
    imageUrl:
      // 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      "https://th.bing.com/th/id/OIP.oPnVsOXyBBi3R2WnwL7p5gHaHa?w=197&h=197&c=7&r=0&o=5&pid=1.7",
  },
  {
    id: 2,
    name: "André França",
    phone: "1-493-747-9031",
    email: "lesliealexander@example.com",
    role: "Co-Founder / CEO",
    url: "https://example.com",
    profileUrl: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
] as Person[];

const recent = [people[0], people[1]];

const PIXWAVEbankLogo = require("../../../assets/clients/pixwave/logo/logo_dash.png");

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export const Layout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthBackoffice();
  const token = localStorage.getItem("@stricv2:token");

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState<Person>(people[0]);
  const [opened, setOpen] = useState(false);
  const [manualTaxModalIsOpen, setManualTaxModalIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [BellBoxMessageIsActive, setBoxMessageIsActive] = useState(false);

  const [notifications, setNotifications] = useState<any>();
  const [quantity, setQuantity] = useState(0);

  const fetchNotifications = async () => {
    try {
      let url = `/backoffice/notifications`;
      const { data } = await api.get(url, {
        headers: { Authorization: "Bearer " + token },
      });
      console.log("notifications", data);
      setNotifications(data);
      console.log(notifications);
    } catch (error) {}
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const [, setSubMenu] = useState(false);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const HandleBoxMessageToggle = async () => {
    if (quantity > 0) {
      try {
        let url = `/notifications/read`;
        const { data } = await api.get(url, {
          headers: { Authorization: "Bearer " + token },
        });
        console.log("notifications", data.notifications);
        setQuantity(data.quantity);
        setNotifications(data.notifications);
      } catch (error) {}
    }
    setBoxMessageIsActive((state) => !state);
  };

  const isDashboardIndex = window.location.href.endsWith("/backoffice");
  const href = window.location.href;


  useEffect(() => {
    if (location.pathname === "/backoffice") {
      setSubMenu(false);
    } else if (location.pathname === "/accounts") {
      setSubMenu(false);
    } else {
      setSubMenu(true);
    }
  }, [location.pathname]);


  const options: OptionItem[] = [
    {
      onSelect: () => {
        navigate("/backoffice/config/persons");
      },
      selected: window.location.href.includes("/backoffice/config/persons"),
      title: "Atribuição de maquininha",
    },
    {
      onSelect: () => {
        setShow(!show);
      },
      selected: false,
      title: "Cadastros",
    },
    {
      onSelect: () => {
        navigate("/backoffice/support");
      },
      selected: false,
      title: "Chat de suporte",
    },
    {
      onSelect: () => {
        navigate("/backoffice/get-transaction-delbank");
      },
      selected: false,
      title: "Get Transactions",
    },
    {
      onSelect: () => {
        navigate("/backoffice/config/operators/form");
      },
      selected: false,
      title: "Operadores",
    },
    {
      onSelect: () => {
        navigate("/backoffice/config/tax");
      },
      selected: window.location.href.includes("/backoffice/config/tax"),
      title: "Plano da maquininha",
    },
    ...(user?.role === "MASTER" ? [{
    onSelect: () => {
      navigate("/backoffice/get-count-transactions");
    },
    selected: false,
    title: "Quantidade de transações",
  }] : []),
    {
      onSelect: () => {
        navigate("/backoffice/request-limits-users");
      },
      selected: window.location.href.includes("/backoffice/request-limits-users"),
      title: "Requisições de limites",
    },
    ...(user?.role === "MASTER" ? [{
    onSelect: () => {
      setManualTaxModalIsOpen(true);
    },
    selected: false,
    title: "Taxa manual",
  }] : []),
    {
      onSelect: () => {
        navigate("/backoffice/logout");
      },
      selected: false,
      title: "Sair",
    }
  ];

  const updatedOptions: OptionItem[] = options
    .filter((item) => {
      if (user && user.role !== "MASTER") {
        return item.title !== "Get Transactions";
      } else {
        return item;
      }
    })
    .reduce((acc: OptionItem[], item: OptionItem) => {
      if (item.title === "Taxa manual" && show) {
        const newItems: OptionItem[] = [
          {
            onSelect: () => {
              navigate("/backoffice/signup-pf");
            },
            selected: href.endsWith("/backoffice/signup-pf"),
            title: "Conta PF",
          },
          {
            onSelect: () => {
              navigate("/backoffice/signup-pj");
            },
            selected: href.endsWith("/backoffice/signup-pj"),
            title: "Contas PJ",
          },
          {
            onSelect: () => {
              alert("Em construção");
            },
            selected: href.endsWith("/backoffice/signup-pj"),
            title: "Cadastro em massa",
          },
        ];
        acc.push(...newItems);
      }
      acc.push(item);
      return acc;
    }, []);

  const filteredPeople =
    query === ""
      ? []
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });




  return (
    <div className="max-h-screen overflow-y-auto pt-16">
      <div className="w-screen py-2 z-50 bg-white fixed top-0 left-0 sm:px-4 lg:divide-gray-200">
        <nav className="flex justify-center py-4 gap-10" aria-label="Global">
          <img src={PIXWAVEbankLogo} className="object-contain h-10" />
          <Button
            activeColor="#343F8A"
            onClick={() => {
              navigate("/backoffice");
            }}
            selected={href.endsWith("/backoffice")}
            title="Estatísticas"
          />

          <Button
            activeColor="#343F8A"
            onClick={() => {
              navigate("/backoffice/tax");
            }}
            selected={href.endsWith("/backoffice/tax")}
            title="Tarifas"
          />
          <Button
            activeColor="#343F8A"
            onClick={() => {
              navigate("/backoffice/plans");
            }}
            selected={href.endsWith("/backoffice/plans")}
            title="Planos"
          />
          {/* <Dropdown
            title="Pagbank"
            listWidth={150}
            closeOnSelect
            options={[
              {
                onSelect: () => {
                  navigate("/backoffice/transactions-pagbank");
                },
                selected: href.endsWith("/backoffice/transactions-pagbank"),
                title: "Transações POS",
              },
              // {
              //   onSelect: () => {
              //     navigate("/backoffice/extracts-pagbank");
              //   },
              //   selected: href.endsWith("/backoffice/extracts-pagbank"),
              //   title: "Extratos POS",
              // },
              {
                onSelect: () => {
                  navigate("/backoffice/forward-invoice-pagbank");
                },
                selected: href.endsWith("/backoffice/forward-invoice-pagbank"),
                title: "Repasse POS",
              },
            ]}
          /> */}
          <Dropdown
            title="Anti-fraudes"
            width={130}
            listWidth={180}
            options={[
              {
                onSelect: () => {},
                selected: true,
                title: "Transações suspeitas",
              },
            ]}
          />
          <Dropdown
            title="Contas"
            listWidth={150}
            closeOnSelect
            options={[
              {
                onSelect: () => {
                  navigate("/backoffice/accounts");
                },
                selected: href.endsWith("/backoffice/accounts"),
                title: "Conta Corrente",
              },
              {
                onSelect: () => {
                  navigate("/backoffice/accounts/wallet");
                },
                selected: href.endsWith("/backoffice/accounts/wallet"),
                title: "Contas Wallet",
              },
              {
                onSelect: () => {
                  navigate("/backoffice/accounts/ca");
                },
                selected: href.endsWith("/backoffice/accounts/ca"),
                title: "Contas C-Account",
              },
            ]}
          />

          <Dropdown
            title="Transações"
            closeOnSelect
            options={[
              {
                onSelect: () => {
                  navigate("/backoffice/financial");
                },
                selected: href.endsWith("/backoffice/financial"),
                title: "Financeiro",
              },
              {
                onSelect: () => {
                  navigate("/backoffice/control-account");
                },
                selected: href.endsWith("/backoffice/control-account"),
                title: "Transações C-A",
              },
              {
                onSelect: () => {
                  navigate("/backoffice/wallet/transactions");
                },
                selected: href.endsWith("/backoffice/wallet/transactions"),
                title: "Transações Wallet",
              },
            ]}
          />
          <BellWrapper>
            <ButtonBell onClick={HandleBoxMessageToggle}>
              <BellIcon />
            </ButtonBell>
            <BellNotifications>
              <p>{notifications?.totalPendingItems}</p>
            </BellNotifications>
            <AnimatePresence>
              {BellBoxMessageIsActive && (
                <BoxWrapper
                  initial={{ opacity: 0, y: "-10%" }}
                  animate={{ opacity: 1, y: "0%" }}
                  exit={{ opacity: 0, y: "-10%" }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="w-64 bg-white rounded-lg shadow-lg p-4">
                    <h3 className="text-lg font-semibold mb-4">Notificações</h3>
                    <ul>
                      <NotificationItem
                        title="Tickets"
                        count={notifications?.tickets}
                        onClick={() => navigate("/backoffice/support")}
                      />
                      <NotificationItem
                        title="Solicitações de aumento de limite"
                        count={notifications?.feeLimitsRequests}
                        onClick={() =>
                          navigate("/backoffice/request-limits-users")
                        }
                      />
                      <NotificationItem
                        title="Contas gráficas pendentes"
                        count={notifications?.graphicAccounts}
                        onClick={() => navigate("/backoffice/accounts/wallet")}
                      />
                      <NotificationItem
                        title="Contas correntes pendentes"
                        count={notifications?.users}
                        onClick={() => navigate("/backoffice/accounts")}
                      />
                    </ul>
                  </div>
                </BoxWrapper>
              )}
            </AnimatePresence>
          </BellWrapper>
          <Dropdown
            title={<Settings size={18} />}
            min
            listWidth={200}
            width={50}
            options={updatedOptions}
          />
        </nav>
      </div>

      {isDashboardIndex ? (
        <div className="pt-0">
          <Outlet />
        </div>
      ) : (
        <div className="mx-auto pb-10 lg:py-12 lg:px-8">
          <Outlet />
        </div>
      )}
      <Modal
        open={manualTaxModalIsOpen}
        onClickClose={() => {
          setManualTaxModalIsOpen(false);
        }}
      >
        <ManualTax />
      </Modal>
    </div>
  );
};

interface DropdownOption {
  title: string;
  selected: boolean;
  onSelect: () => void;
}

interface DropdownProps {
  options: DropdownOption[];
  closeOnSelect?: boolean;
  title: string | React.ReactChild;
  width?: number;
  listWidth?: number;
  min?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  title,
  closeOnSelect = false,
  width = 120,
  listWidth = 120,
  min = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (index: number) => {
    options[index].onSelect();
    if (closeOnSelect) {
      setIsOpen(false);
    }
  };

  return (
    <div
      onBlur={() => setIsOpen(false)}
      style={{ width }}
      className="relative inline-block text-left"
    >
      <div>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          style={{
            border: min ? "none" : "",
            boxShadow: min ? "none" : "",
          }}
          className="flex active:bg-zinc-50 duration-100 border border-zinc-50 items-center gap-1 justify-center w-full rounded-md shadow-inner h-10 bg-white text-sm font-medium text-gray-500 outline-none focus:outline-none"
        >
          <span
            className="transition-all duration-200 ease-in-out"
            style={{
              rotate: typeof title != "string" && isOpen ? "25deg" : "",
            }}
          >
            {title}
          </span>
          {!min && (
            <ChevronDown
              size={15}
              color="#445"
              strokeWidth={2.5}
              className="mt-[2px] duration-100"
              style={{
                rotate: isOpen ? "180deg" : "0deg",
              }}
            />
          )}
        </button>
      </div>
      <div
        style={{
          width: listWidth,
          opacity: isOpen ? 1 : 0,
          height: isOpen ? "auto" : 0,
        }}
        className="origin-top-right overflow-y-auto absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 hideScrollbar duration-100 transition-all"
      >
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          className="z-50"
        >
          {options.map((option, index) => (
            <button
              key={index}
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                handleSelect(index);
              }}
              className={`${
                option.selected ? "bg-gray-50 underline" : "bg-white"
              } block hover:bg-zinc-50 px-4 py-2 text-sm text-gray-700 w-full text-left`}
              role="menuitem"
            >
              {option.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const Button: FC<{
  title: string;
  onClick: () => void;
  activeColor: string;
  selected?: boolean;
}> = ({ activeColor, onClick, selected, title }) => {
  return (
    <button
      style={{
        backgroundColor: selected ? activeColor : "",
        color: selected ? "white" : "",
        borderRadius: selected ? 12 : "",
      }}
      onClick={onClick}
      className={`flex ${
        selected ? "shadow-md" : ""
      } active:bg-zinc-50 duration-100 border-zinc-50 px-6 items-center gap-1 justify-center rounded-md h-10 bg-white text-sm font-medium text-gray-500 outline-none focus:outline-none`}
    >
      {title}
    </button>
  );
};

const NotificationItem: FC<{
  title: string;
  count: number;
  onClick: () => void;
}> = ({ title, count, onClick }) => {
  return (
    <li className="flex justify-between items-center py-2">
      <button
        onClick={onClick}
        className="text-yellow-500 hover:underline focus:outline-none text-xs"
      >
        {title}
      </button>
      <span className="bg-yellow-500 text-white rounded-full px-2 py-1 text-xs">
        {count}
      </span>
    </li>
  );
};

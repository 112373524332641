import { useState } from "react";
import { service, serviceError } from "../../services/ajio";
import { useAuth } from "../../hooks/auth";

type PerformP2PProps = {
  amount: number;
  endToEndId: string;
};

// Função de hook para realizar a transação P2P
const usePerformP2PTransaction = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { account, refresh } = useAuth();

  const performP2PTransactions = async ({
    amount,
    endToEndId,
  }: PerformP2PProps) => {
    try {
      setLoading(true);
      const response = await service.post("/bank-transfers/p2p-tax", {
        payee_id: "a561e290-dc7e-45f9-b067-09d1faa6cf65", //  ---- refId
        amount: amount,
        endToEndId,
      });

      // Exibir informações ou lidar com o resultado da transação P2P conforme necessário
      console.log("Transação P2P realizada com sucesso", response.data);
    } catch (err) {
      setLoading(false);
      const error = serviceError(err);
      setError(error.friend);
      refresh();
    }
  };

  return {
    loading,
    error,
    performP2PTransactions,
  };
};

export default usePerformP2PTransaction;

import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Dropdown } from "src/components/Dropdown";
import Modal from "src/components/Modal";
import { Button } from "src/components/button";
import { Account, useAuth } from "src/hooks/auth";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import { api } from "src/services/api";
import { AccountProps } from "../Accounts";
import { useNotification } from "src/hooks/notification";
import toLocaleAmount from "src/utils/toLocaleAmount";

export const ManualTax = () => {
  const { user } = useAuthBackoffice();

  const token = localStorage.getItem("@backoffice:token");

  const [amount, setAmount] = useState("");
  const [selectedAccount, setSelectedAccount] = useState<any>();
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const floatAmount = parseFloat(amount);

  const { setOpenNotification } = useNotification();

  const handlePay = async () => {
    if (
      !selectedAccount ||
      !amount ||
      !floatAmount ||
      Number.isNaN(floatAmount)
    ) {
      return setOpenNotification({
        title: "Erro",
        notification: "Verifique os dados",
        type: "error",
      });
    }
    try {
      setLoading(true);

      const response = await api.post(
        "/bank-transfers/manual-tax",
        {
          payerId: selectedAccount.id,
          amount: floatAmount,
          description: "Tarifa manual",
        },
        { headers: { authorization: `Bearer ${token}` } }
      );

      setOpenNotification({
        title: "Sucesso",
        notification: `Transação de R$ ${toLocaleAmount(
          floatAmount
        )} feita com sucesso`,
        type: "success",
      });
      setLoading(false);
      setSelectedAccount(undefined);
      setAmount("");
    } catch (error) {
      setOpenNotification({
        title: "Erro",
        notification: "Verifique os dados e o saldo da conta",
        type: "error",
      });
      setLoading(false);
      setSelectedAccount(undefined);
      setAmount("");

      console.log(error);
    }
  };

  const loadAccount = async () => {
    try {
      const response = await api.get(
        `/backoffice/accounts?page=1&role=ALL&perPage=300`,
        // `/backoffice/accounts?page=${currentPage}&role=MEMBER&perPage=${perPage}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      setAccounts(response.data.accounts.data);

      // setBalance(bl);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadAccount();
  }, []);

  return (
    <div className="flex flex-col p-8 gap-4">
      <Dropdown
        options={accounts.map((acc) => ({
          onSelect: () => setSelectedAccount(acc),
          selected: selectedAccount?.id === acc.id,
          title: acc.user.name,
        }))}
        closeOnSelect
        title={
          selectedAccount ? selectedAccount.user.name : "Escolha um usuario"
        }
        width={300}
        listWidth={300}
      />
      <div className="flex gap-1 items-center h-12 border px-2 border-zinc-100 rounded-md">
        <span>R$ </span>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>

      <button
        className="bg-black text-white rounded-md h-12 w-full items-center justify-center"
        type="button"
        disabled={loading}
        onClick={handlePay}
      >
        {loading ? "Tranferindo..." : "Cobrar"}
      </button>
    </div>
  );
};

import React, { Fragment, FormEvent, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../../components/button";
import { InputInsertValue } from "../../components/inputValue";
import { StepHeader } from "../../components/stepHeader";
// import {PasswordInput} from "../../components/passwordInput";
// import { useModalPix } from "../../hooks/modalPix";

import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";

import {
  BoxInfoWrapper,
  // BoxPasswordWrapper,
  ButtonsWrapper,
  Container,
  DataWrapper,
  DateComponent,
  InfoWrapper,
  Key,
  // LabelInput,
  Name,
  WrapperBoxContent,
  WrapperHeader,
} from "./styles";
import { documentFormatted } from "../../utils/documentFormatted";
import { format } from "date-fns";
import brasilLocale from "date-fns/locale/pt-BR";
// import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import TextInput from "../../components/TextInput";
import { service, serviceError } from "../../services/ajio";
import { ActionText } from "../recharge/styles";
import SelectInput from "../../components/SelectInput";

interface StateProps {
  dealer_code: string;
  phone_number: string;
  id: string;
  amounts: [];
}

export interface PixProps {
  id: string;
}

export const RechargerConfirm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account, refresh } = useAuth();
  // const { openPixModal } = useModalPix();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [maskedValue, setMaskedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pinOpen, setPinOpen] = useState(false);
  const [pin, setPin] = useState("");
  const [hasPin, setHasPin] = useState(false);
  const [data, setData] = useState({ phone: "", operator: "" });
  const [selectedKey, setSelectedKey] = useState(0);
  const [operator, setOperator] = useState(0);
  const [valueEletronic, setValueEletronic] = useState("");
  const state = location.state as StateProps;

  const handleKeySelect = (option: number) => {
    setSelectedKey(option);
    setOperator(option);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancelButton = () => {
    navigate("/mobileRecharger");
  };

  const handleConfirmButton = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    console.log(operator);
    try {
      const { data } = await service.post(
        `phone-recharges/${state.id}/confirm`,
        {
          pin: account.pin,
          amount: selectedKey,
        }
      );
      setLoading(false);
      setOpen(true);
      refresh();
    } catch (err) {
      setLoading(false);
      const error = serviceError(err);

      setError(error.friend);
      setHasPin(false);
    }
  };

  function parseAgency() {
    return "state.bank_account.branch";
  }

  const handleChange = (
    event: FormEvent,
    value: number,
    maskedValue: string
  ) => {
    event.preventDefault();
    setValue(value);
    setMaskedValue(maskedValue);
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }

  return (
    <Container>
      <WrapperHeader>
        <StepHeader
          handleGoBackFunction={handleGoBack}
          title="Recarga de Celular"
        />
      </WrapperHeader>
      <BoxInfoWrapper>
        <WrapperBoxContent>
          <InfoWrapper>
            <DataWrapper>
              <span>Telefone</span>
              <Key>{state.phone_number}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>Operadora</span>
              <Key>{state.dealer_code}</Key>
            </DataWrapper>
          </InfoWrapper>
          <DateComponent>
            {format(new Date(), "dd LLL yyyy", {
              locale: brasilLocale,
            }).toUpperCase()}
          </DateComponent>
        </WrapperBoxContent>
        <ActionText>Valor</ActionText>
        <SelectInput
          placeholderColor={"#B5B7BD"}
          borderColor={"#E3E3E3"}
          optionSelected={selectedKey}
          handleFunction={handleKeySelect}
          options={state.amounts}
        />
        {/* <InputInsertValue
          onChange={(event, value, masked) =>
            handleChange(event, value, masked)
          }
        /> */}
      </BoxInfoWrapper>
      <form onSubmit={handleConfirmButton} ref={formRef}>
        {/* <BoxPasswordWrapper>
        <LabelInput>Senha</LabelInput>
        <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="********" />
      </BoxPasswordWrapper> */}

        {!!error && (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 w-[480px]">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">{error}</p>
              </div>
            </div>
          </div>
        )}
        <ButtonsWrapper>
          <Button
            handleFunction={handleCancelButton}
            color="#e3e3e3"
            title="Cancelar"
          />

          {/* <button type="submit" className="bg-[#49D294] w-96 px-10 py-2 rounded-md text-white flex justify-center">
          <Loading isLoading={loading}/>
          Continuar
        </button> */}
          {!hasPin ? (
            <Button
              type="button"
              isLoading={loading}
              title="Continuar para senha"
              handleFunction={() => setPinOpen(true)}
            />
          ) : (
            <Button type="submit" isLoading={loading} title="Transferir" />
          )}

          <Transition.Root show={pinOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                      <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Senha
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="mt-5 flex justify-center">
                              <div className="w-48 ">
                                <TextInput
                                  placeholder="Senha Eletrônica"
                                  maxLength={8}
                                  value={valueEletronic}
                                  type="password"
                                  onChange={(input) => setValueEletronic(input.target.value)}
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <Button
                          type="submit"
                          isLoading={loading}
                          title="Transferir"
                          handleFunction={() => {
                            if (valueEletronic === account.security_eletronic) {
                              setHasPin(true);
                              setPinOpen(false);
                              formRef.current?.requestSubmit();
                            } else {
                              // Lógica de erro quando a senha eletrônica está incorreta
                              //console.log("Senha eletrônica incorreta");
                            }
                          }}
                        />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </ButtonsWrapper>
      </form>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Transferência Pix realizada
                        <br />
                        teste
                      </Dialog.Title>
                      <div className="mt-2 text-left">
                        <p className="text-sm text-gray-500">
                          <h2 className="mb-5 mt-5 text-lg">
                            Dados do favorecido
                          </h2>

                          <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>1111</h3>
                            </div>

                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>teste-teste</h3>
                            </div>
                          </div>

                          <h2 className="mb-5 mt-5 text-lg">
                            Dados da conta debitada
                          </h2>

                          <div>
                            <h2>Nome</h2>
                            <h3>teste</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Documento</h2>
                            <h3>teste</h3>
                          </div>

                          <div className="mt-5">
                            <h2>Banco</h2>
                            <h3>teste</h3>
                          </div>

                          <div className="mt-5 flex">
                            <div>
                              <h2>Agência</h2>
                              <h3>{parseAgency()}</h3>
                            </div>

                            <div className="ml-28">
                              <h2>Conta</h2>
                              <h3>teste</h3>
                            </div>
                          </div>

                          <div className="mt-5">
                            <h2>ID da transação</h2>
                            <h3>teste</h3>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => onCloseModal()}
                    >
                      Ir para o extrato
                    </button>

                    <button
                      type="button"
                      className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                      // onClick={() => onCloseModal()}
                    >
                      Comprovante disponível no extrato
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Container>
  );
};

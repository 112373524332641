import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { ExclamationIcon } from "@heroicons/react/solid";
import ButtonSignInColor from "../../../src/assets/button-color.svg";
import {
  ButtonEyeVisibility,
  ButtonSignIn,
  Content,
  EyeDisabledIcon,
  EyeEnabledIcon,
  Input,
  InputPassword,
  WrapperContent,
  WrapperForm,
  Error,
} from "./styles";

export const SignInForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigation = useNavigate();
  const { signIn, account, user } = useAuth();

  const [document, setDocument] = useState("");
  const [password, setPassword] = useState("");
  const [errorTimeout, setErrorTimeout] = useState<NodeJS.Timeout | null>(null);
  // const [virtualKeyboardDocument, setVirtualKeyboardDocument] = useState("");

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((state) => !state);
  };

  useEffect(() => {
    if (account) {
      navigation("/");
    }

    if (user?.type === "GRAPHIC") {
      window.location.reload();
    }
  }, [account, user]);

  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();

    if (!document || !password) {
      return;
    }

    try {
      setLoading(true);
      setError("");
      console.log("Antes de signIn");
      await signIn({
        document: document.replace(/[/,.,-\s]/g, ""),
        password,
      });
      console.log("Depois de signIn");

      setLoading(false);
      setError("");
      navigation("/");
    } catch (err: any) {
      console.log(err);

      const errorMessage = err?.response?.data?.message || "Erro ao entrar no sistema, entre em contato com o suporte.";

      setLoading(false);
      setError(errorMessage);
      // setError("Usuário e/ou senha inválido.");
      const timeout = setTimeout(() => {
        setError("");
      }, 5000);

      setErrorTimeout(timeout);
    }
  };

  useEffect(() => {
    return () => {
      // Limpar o temporizador quando o componente for desmontado
      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }
    };
  }, [errorTimeout]);

  return (
    <>
      <WrapperContent>
        <Content>
          {/* Adicione o VirtualKeyboard e passe a referência do input */}

          <WrapperForm onSubmit={handleSignIn} className="hide-on-small-screens">
            <Input>
              <span>CPF/CNPJ</span>
              <CpfCnpj
                placeholder="Digite seu CPF/CNPJ"
                type="text"
                onChange={(input: any) => setDocument(input.target.value)}
                value={document}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  // Impedir a entrada de letras e números
                  const charCode = event.charCode;
                  if (
                    (charCode >= 48 && charCode <= 57) || // Números
                    (charCode >= 65 && charCode <= 90) || // Letras maiúsculas
                    (charCode >= 97 && charCode <= 122) // Letras minúsculas
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Input>

            <InputPassword>
              <div>
                <span>Senha</span>
                <input
                  onChange={(input) => setPassword(input.target.value)}
                  value={password}
                  placeholder="Digite sua senha"
                  type={isPasswordVisible ? "text" : "password"}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    // Impedir a entrada de letras e números
                    const charCode = event.charCode;
                    if (
                      (charCode >= 48 && charCode <= 57) || // Números
                      (charCode >= 65 && charCode <= 90) || // Letras maiúsculas
                      (charCode >= 97 && charCode <= 122) // Letras minúsculas
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>

              <ButtonEyeVisibility onClick={handlePasswordVisibility} type="button">
                {isPasswordVisible ? <EyeEnabledIcon /> : <EyeDisabledIcon />}
              </ButtonEyeVisibility>
            </InputPassword>

            <ButtonSignIn
              style={{
                //backgroundImage: `url(${ButtonSignInColor})`,
                //backgroundImage: `url(${ButtonSignInColor})`,
                backgroundColor: "#5f83fb",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <Loading isLoading={loading} />
              Entrar
            </ButtonSignIn>
          </WrapperForm>
          {!!error && (
            <Error>
              <div className="bg-red-50 border-l-4 border-red-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                </div>
              </div>
            </Error>
          )}
        </Content>
      </WrapperContent>
    </>
  );
};

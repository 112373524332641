import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import { endOfMonth, format, set, startOfMonth, subHours } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon, PrinterIcon } from "@heroicons/react/outline";
import { api } from "../../../services/api";
import { Skeleton } from "../../../components/backoffices/Skeleton";
import { currencyFormat } from "../../../utils/currencyFormat";
import ptBR from "date-fns/locale/pt-BR";
import { PrintButtonIcon } from "src/pages/billet/styles";
import { config } from "src/config";
import { useAuthBackoffice } from "src/hooks/authBackoffice";
import { Save, SearchIcon } from "lucide-react";
import handleExportPDF, { handleGeneratePDF } from "./pdf";
import { useNotification } from "src/hooks/notification";
import { ExportPDF_V2_DELBANK } from "../Export/pdf";
import { ExtractItem } from "../AccountsDetailsTax";

export interface Transaction {
  id: string;
  credit: boolean;
  amount: number;
  type: string;
  typeId: string;
  description: string;
  status: string;
  balanceAfter: number;
  date: string;
}

export interface Detail {
  id: string;
  credit: boolean;
  amount: number;
  description: string;
  rateValue: number;
  consignorName: string;
  consignorDocument: string;
  consignorBankNumber: string;
  consignorBankBranch: string;
  consignorBankAccount: string;
  consignorBankAccountDigit: string;
  beneficiaryName: string;
  beneficiaryDocument: string;
  beneficiaryBankNumber: string;
  beneficiaryBankBranch: string;
  beneficiaryBankAccount: string;
  beneficiaryBankAccountDigit: string;
  status: string;
  date: string;
}

export interface MetaProps {
  current_page: number;
  total_pages: number;
  total_items: number;
  total_items_page: number;
}
interface CreditCardMachine {
  id: string;
  serialNum: string;
  identificationCode: string;
  description: string;
  graphic_account_id: string;
  graphicAccount: {
    id: string;
    name: string;
    document: string;
    email: string;
    password_hash: string;
    balance: number;
    status: string;
    created_at: string;
    updated_at: string;
    user_id: string;
    account_id: string;
    number_identifier: string;
    access_token: string | null;
    pin: string;
    security_eletronic: string;
    status_pin_eletronic: boolean;
    blocked: boolean;
    counter: number;
    gender: string;
    status_master_user: boolean;
    id_master_user: string | null;
    birthDate: string;
    franchise_id: string;
    document_base64_front: string | null;
    document_base64_verse: string | null;
    document_base64_selfie: string | null;
    role: string;
    pin_wallet: string | null;
    parent_api_key: string;
    rg_cnh: string;
    planId: string;
    hash_reset_password: string | null;
    phone: {
      ddd: string;
      type: string;
      number: string;
    };
  };
}
export function GetTrasactionsMaster() {
  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());
  const [users, setUsers] = useState<any[]>([]);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<{
    name: string;
    id: string;
    userId: string;
  }>({ name: "", id: "", userId: "" });
  const [selectedOption, setSelectedOptions] = useState<{
    name: string;
  }>({
    name: "Chek-in/Chek-out",
  });
  const [showUserSelectionModal, setShowUserSelectionModal] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [count, setCount] = useState(0);
  const { user } = useAuthBackoffice();
  const [totalValue, setTotalValue] = useState<any>({});
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [show, setShow] = useState(false);
  const { setOpenNotification } = useNotification();
  const handleOpenDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const handleCloseDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const handleConfirmDownload = () => {
    if (selectedItem) {
      handleSaveTransaction(selectedItem);
    }
    setIsDownloadModalOpen(false);
  };

  const handleCancelDownload = () => {
    setIsDownloadModalOpen(false);
    setSelectedItem({});
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const repassModal = document.getElementById("repass-modal");
      const downloadModal = document.getElementById("download-modal");

      if (
        repassModal &&
        event.target instanceof Node &&
        !repassModal.contains(event.target) &&
        downloadModal &&
        event.target instanceof Node &&
        !downloadModal.contains(event.target)
      ) {
        setSelectedItem({});
        setIsDownloadModalOpen(false);
      }
    };
    if (isDownloadModalOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDownloadModalOpen]);

  const handleGetData = async () => {
    try {
      setLoading(true);
      setPage(1);
      let whereContition = {};
      if (selectedOption.name === "Chek-in") {
        whereContition = {
          isCredit: true,
        };
      } else if (selectedOption.name === "Chek-out") {
        whereContition = {
          isCredit: false,
        };
      } else {
        whereContition = {};
      }

      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      if (start) {
        start.setDate(start.getDate() - 1);
      }

      if (end) {
        end.setDate(end.getDate() - 1);
      }
      const response = await api.get("/transactions/delbank", {
        params: {
          page,
          limit,
          startDate: start,
          endDate: end,
          api_key: selectedUser.id,
          endToEndId: search,
          ...whereContition,
        },
      });

      const filteredTransactions = response.data.data.filter((transaction: any) => {
        const transactionDate = new Date(transaction.createdAt);

        const isWithinDateRange = transactionDate >= start! && transactionDate <= end!;

        return isWithinDateRange;
      });

      setItems(filteredTransactions);
      setCount(response.data.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedUser && selectedUser.id) {
      handleGetData();
    }
  }, [selectedUser, startDate, endDate, selectedOption, search]);

  const handleClickExportPDF = () => {
    handleExport("pdf");
  };

  async function handleExport(type: string) {
    setIsExporting(true);

    if (type === "pdf") {
      let whereContition = {};
      if (selectedOption.name === "Chek-in") {
        whereContition = {
          isCredit: true,
        };
      } else if (selectedOption.name === "Chek-out") {
        whereContition = {
          isCredit: false,
        };
      } else {
        whereContition = {};
      }

      const params = {
        title: "Financeiro",
        startDate: startDate,
        endDate: endDate,
        date: `${new Date().toLocaleDateString()}, ${new Date().toLocaleTimeString()}`,
        isWallet: false,
      };

      ExportPDF_V2_DELBANK(items, params, selectedUser.userId, true);
    }
    setIsExporting(false);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  async function handlePrevious() {
    if (page === 0) {
      return;
    }
    setPage((prev) => prev - 1);
  }

  async function handleNext() {
    setPage((prev) => prev + 1);
  }

  const dateFormat = (date: string | undefined): string => {
    const formattedDate = format(new Date(date || ""), "dd/MM/yyyy HH:mm", {
      locale: ptBR,
    });
    return formattedDate;
  };

  const token = localStorage.getItem("@backoffice:token");

  const fetchUsers = async () => {
    try {
      const { data } = await api.get<CreditCardMachine[]>("/backoffice/users/admin", {
        headers: { Authorization: "Bearer " + token },
      });
      setUsers(data);
    } catch (error) {
      console.error("Error fetching card machines:", error);
    }
  };

  const handleSaveTransaction = async (transactions: any) => {
    try {
      const response = await api.post<any>("/backoffice/users/save-transaction", transactions, {
        headers: { Authorization: "Bearer " + token },
      });
      console.log(response);

      setOpenNotification({
        type: "success",
        title: response.data.message,
        notification: "",
      });
    } catch (error) {
      console.error("Error fetching card machines:", error);
    }
  };

  const handleUserSelect = (user: any) => {
    setSelectedUser({
      userId: user.id,
      id: user.api_key || user.parent_api_key,
      name: user.name,
    });
    fetchTotalValue(user.id);
    setShowUserSelectionModal(false);
  };

  const fetchTotalValue = async (userId: string) => {
    try {
      const { data } = await api.get<CreditCardMachine[]>(`/backoffice/users/${userId}/total-value`, {
        headers: { Authorization: "Bearer " + token },
      });
      console.log(data);
      setTotalValue(data);
    } catch (error) {
      console.error("Error fetching card machines:", error);
    }
  };

  const handleOptionSelect = (option: any) => {
    setSelectedOptions({ name: option });
    setShowSelectionModal(false);
  };

  const perPage = 10;
  const startIdx = (page - 1) * perPage;
  const endIdx = startIdx + perPage;
  const paginatedItems = items.slice(startIdx, endIdx);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-xl font-semibold text-gray-900">Busca de transações via DelBank</h1>
      <div>
        <section>
          <>
            <div className="sm:flex-auto">
              <div>
                <Skeleton isActive={loading} repeat={3} />
                {!loading && (
                  <>
                    <div className="flex items-center gap-10">
                      <div>
                        <div className="flex flex-col w-full sm:w-auto">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Nome
                          </label>
                          <div className="relative">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={selectedUser.name}
                              onClick={() => setShowUserSelectionModal(true)}
                              className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                              placeholder="Nome"
                              readOnly
                            />
                            <button
                              onClick={() => setShowUserSelectionModal(true)}
                              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 4a2 2 0 100 4 2 2 0 000-4zM8 10a2 2 0 100-4 2 2 0 000 4zm8-2a2 2 0 11-4 0 2 2 0 014 0zm-4 4a2 2 0 100-4 2 2 0 000 4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {showUserSelectionModal && (
                              <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                  <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                  </div>
                                  <span
                                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true"
                                  >
                                    &#8203;
                                  </span>
                                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                      <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            Selecione um Usuário
                                          </h3>
                                          <div className="mt-2">
                                            {users.map((user) => (
                                              <div
                                                key={user.id}
                                                className="p-2 hover:bg-gray-100 cursor-pointer"
                                                onClick={() => handleUserSelect(user)}
                                              >
                                                {user.name}
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                      <button
                                        type="button"
                                        onClick={() => setShowUserSelectionModal(false)}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                      >
                                        Fechar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-col w-full sm:w-auto">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Entrada/Saida
                          </label>
                          <div className="relative">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={selectedOption.name}
                              onClick={() => setShowSelectionModal(true)}
                              className="mt-1 p-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                              placeholder="Nome"
                              readOnly
                            />
                            <button
                              onClick={() => setShowSelectionModal(true)}
                              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 4a2 2 0 100 4 2 2 0 000-4zM8 10a2 2 0 100-4 2 2 0 000 4zm8-2a2 2 0 11-4 0 2 2 0 014 0zm-4 4a2 2 0 100-4 2 2 0 000 4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            {showSelectionModal && (
                              <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                  <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                  </div>
                                  <span
                                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true"
                                  >
                                    &#8203;
                                  </span>
                                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                      <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            Selecione uma opção
                                          </h3>
                                          <div className="mt-2">
                                            <div
                                              className="p-2 hover:bg-gray-100 cursor-pointer"
                                              onClick={() => handleOptionSelect("Chek-in/Chek-out")}
                                            >
                                              Chek-in/Chek-out
                                            </div>
                                            <div
                                              className="p-2 hover:bg-gray-100 cursor-pointer"
                                              onClick={() => handleOptionSelect("Chek-in")}
                                            >
                                              Chek-in
                                            </div>
                                            <div
                                              className="p-2 hover:bg-gray-100 cursor-pointer"
                                              onClick={() => handleOptionSelect("Chek-out")}
                                            >
                                              Chek-out
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                      <button
                                        type="button"
                                        onClick={() => setShowSelectionModal(false)}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                      >
                                        Fechar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <span>Selecione um período para filtrar</span>
                        <div className="flex items-center">
                          <DatePicker
                            className="bg-gray-300 rounded px-2 py-1 mr-2"
                            selected={startDate}
                            onChange={(date: Date) => setStartDate(date)}
                            locale="pt"
                            dateFormat="dd/MM/yyyy"
                          />
                          <span className="mx-2">até</span>
                          <DatePicker
                            className="bg-gray-300 rounded px-2 py-1 mr-2"
                            selected={endDate}
                            onChange={(date: Date) => setEndDate(date)}
                            locale="pt"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                      <div>
                        <span>Buscar por id de transação</span>
                        <div className="flex items-center">
                          <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                              <SearchIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                              name="search"
                              id="search"
                              className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                              placeholder="Pesquisar"
                              type="search"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {totalValue.dellBankBalance && (
                        <div>
                          <span>Saldo na Dellbank</span>
                          <div className="flex items-center">
                            <div className="relative">
                              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"></div>
                              <p className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                                {currencyFormat(totalValue.dellBankBalance)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {totalValue.accountBalance && (
                        <div>
                          <span>Saldo na aplicação</span>
                          <div className="flex items-center">
                            <div className="relative">
                              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"></div>
                              <p className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                                {currencyFormat(totalValue.accountBalance)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto"></div>
                      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                          type="button"
                          onClick={handleClickExportPDF}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-3"
                        >
                          {isExporting ? "Exportando..." : "Exportar PDF"}
                        </button>
                      </div>
                    </div>

                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Data
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tipo</th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Transação
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Beneficiário / Pagador
                                  </th>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Valor</th>
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {!loading &&
                                  paginatedItems?.map((item: any) => {
                                    return (
                                      <tr key={item.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 text-sm">
                                          {dateFormat(item.createdAt)}
                                        </td>
                                        <td className="px-3 py-4 text-sm">
                                          {item.type.isCredit ? "Crédito" : "Débito"}
                                        </td>
                                        <td className="px-3 py-4 text-sm">{item.type.description}</td>
                                        <td className="px-1 py-4 text-sm">
                                          {item.type.isCredit
                                            ? `De: ${item.proof?.payer?.holder?.name || "Não informado"}`
                                            : `Para: ${item.proof?.beneficiary?.holder?.name || "Não informado"}`}
                                        </td>
                                        <td className="px-3 py-4 text-sm">
                                          <span
                                            style={{
                                              color: item.direction === "in" ? "var(--green)" : "var(--boxText)",
                                              marginLeft: item.direction === "in" ? "8px" : "0",
                                            }}
                                          >
                                            {currencyFormat(Number(item.amount))}
                                          </span>
                                          <span
                                            style={{
                                              marginLeft: "20px",
                                              display: item.direction === "in" ? "none" : "inline",
                                            }}
                                          ></span>
                                        </td>
                                        <td className="px-6 py-4 text-sm">
                                          <Save
                                            onClick={() => {
                                              setSelectedItem(item);
                                              handleOpenDownloadModal();
                                            }}
                                            color="var(--boxText)"
                                            size="24"
                                            className="inline"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                            <div className="flex items-center justify-center mt-5 mb-5">
                              <ChevronDoubleLeftIcon
                                onClick={handlePrevious}
                                className="mr-3 cursor-pointer w-5 h-5 text-gray-500"
                              />
                              <span>
                                Página {page} de {Math.ceil(items.length / limit)}
                              </span>
                              <ChevronDoubleRightIcon
                                onClick={handleNext}
                                className="ml-3 cursor-pointer w-5 h-5 text-gray-500"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {isDownloadModalOpen && (
              <div
                className="modal"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <div
                  className="modal-content"
                  id="download-modal"
                  style={{
                    backgroundColor: "#fefefe",
                    padding: "20px",
                    borderRadius: "10px",
                    maxWidth: "400px",
                  }}
                >
                  <span
                    className="close"
                    onClick={handleCloseDownloadModal}
                    style={{ float: "right", cursor: "pointer" }}
                  >
                    &times;
                  </span>
                  <p
                    className="text-center mb-4 mx-4"
                    style={{
                      color: "#5D5D5D",
                      height: "31.42px",
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      fontSize: "26.18px",
                    }}
                  >
                    Deseja salvar a transação?
                  </p>

                  <button
                    onClick={handleConfirmDownload}
                    style={{ background: "#274683", borderRadius: "20px" }}
                    className="w-full px-10 py-4 mb-4  text-white"
                  >
                    Sim
                  </button>

                  <button
                    onClick={handleCancelDownload}
                    style={{ borderRadius: "20px" }}
                    className="w-full px-10 py-4 bg-red-500 rounded text-white"
                  >
                    Não
                  </button>
                </div>
              </div>
            )}
            <div
              aria-live="assertive"
              className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
              <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                  show={show}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-sm font-medium text-gray-900">Configurações salvar</p>
                          <p className="mt-1 text-sm text-gray-500">As configurações de taxas foram alteradas.</p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <button
                            type="button"
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </>
        </section>
      </div>
    </div>
  );
}

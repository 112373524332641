import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components/loading";
import { useForm, Controller } from "react-hook-form";
import { useAuth } from "../../hooks/auth";

import {
  ButtonEyeVisibility,
  ButtonSignIn,
  Container,
  Content,
  EyeDisabledIcon,
  EyeEnabledIcon,
  ForgetPassword,
  InputPassword,
  Line,
  Reference,
  Separator,
  Title,
  WrapperBackground,
  WrapperContent,
  WrapperForm,
  WrapperLogo,
} from "./styles";
import { parseError } from "../../services/api";
import { useClient } from "../../hooks/client";
import { service } from "../../services/vf";
import { messageSuccess, messageError } from "src/components/Message";

interface IFormInput {
  password?: string;
  pin?: string;
}

export const NewPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
 const [isSecurityVisible, setIsSecurityVisible] = useState(false);

  const { email, hash } = useParams();
  const { client } = useClient();
  const navigation = useNavigate();
  const { account } = useAuth();

  const pinInputRef = useRef<HTMLInputElement>(null);

  const { handleSubmit, control, watch, setValue } = useForm();

  const watchFields = watch();

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((state) => !state);
  };

 const handleSecurityVisibility = () => {
    setIsSecurityVisible((state) => !state);
  };
  const isPasswordValid = (password: string) => {
    if (password.length < 8) {
      return false;
    }

    if (!/[a-z]/.test(password)) {
      return false;
    }

    if (!/[A-Z]/.test(password)) {
      return false;
    }

    if (!/\d/.test(password)) {
      return false;
    }

    return true;
  };

  const handleNewPassword = async (data: IFormInput) => {
    const replaceEmail = email?.replace("email=", "");
    const replaceHash = hash?.replace("hash=", "");

    if (!watchFields.password || !data.password) {
      messageError("Informe a nova senha");
      return;
    }

    if (!watchFields.pin || !data.pin) {
      messageError("Informe o pin");
      return;
    }

    if (watchFields.pin.length < 4) {
      messageError("O pin deve conter 4 dígitos");
      return;
    }

    if (!isPasswordValid(watchFields.password)) {
      messageError(
        "A senha deve conter no mínimo 8 caracteres, 1 letra maiúscula e 1 número"
      );
      return;
    }

    try {
      setLoading(true);

      const response = await service.patch("accounts/reset-password", {
        email: replaceEmail,
        password: watchFields.password,
        reset_code: replaceHash,
        pin: watchFields.pin,
      });

      setValue("password", "");
      setValue("pin", "");

      const successMessage = response.data.message;

      messageSuccess(successMessage || "Senha alterada com sucesso");

      navigation("/signin");
    } catch (err) {
      const error = parseError(err) || "Erro ao recuperar a senha.";
      console.log(error);

      messageError(error.message || "Erro ao recuperar a senha.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });

  return (
    <Container>
      <WrapperBackground />
      <WrapperContent>
        <Content>
          <WrapperLogo>
            <img
              src={client.whiteLogo.image}
              alt={client.name}
              style={{
                width: client.whiteLogo.maxWidth,
                height: client.whiteLogo.maxHeight,
              }}
            />
          </WrapperLogo>
          <Title>
            Bem-vindo ao internet <br />
            <b>banking da {client.name}.</b>
          </Title>
          <Separator />
          <>
            <Reference>
              Preencha o campo abaixo para atualizar sua senha
            </Reference>
            <WrapperForm onSubmit={handleSubmit(handleNewPassword)}>
              <InputPassword>
                <div>
        <span>Nova Senha</span>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <input
                        {...field}
                        ref={ref}
                        placeholder="Digite sua nova senha"
                        type={isPasswordVisible ? "text" : "password"}
                        autoComplete="off"
                      />
                    )}
                  />
                </div>

                <ButtonEyeVisibility
                  onClick={handlePasswordVisibility}
                  type="button"
                >
                  {isPasswordVisible ? <EyeEnabledIcon /> : <EyeDisabledIcon />}
                </ButtonEyeVisibility>
              </InputPassword>
<InputPassword>
                <div>
                  <span>SECURITY DIGIT (Camada extra de segurança)</span>
                  <Controller
                    name="pin"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <input
                        {...field}
                        ref={pinInputRef}
                        placeholder="Digite o pin"
                        type={isSecurityVisible ? "text" : "password"}
                        autoComplete="off"
                        maxLength={4}
                      />
                    )}
                  />
                </div>

                <ButtonEyeVisibility
                  onClick={handleSecurityVisibility}
                  type="button"
                >
                  {isSecurityVisible ? <EyeEnabledIcon /> : <EyeDisabledIcon />}
                </ButtonEyeVisibility>
              </InputPassword>
              <ButtonSignIn type="submit">
                <Loading isLoading={loading} />
                Enviar
              </ButtonSignIn>
            </WrapperForm>
          </>

          <ForgetPassword href="/signin">Voltar para o login</ForgetPassword>
          <Line />
        </Content>
      </WrapperContent>
    </Container>
  );
};

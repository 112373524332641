// Seu arquivo FormInputSelect.tsx
import React, {
    SelectHTMLAttributes,
    forwardRef,
    ForwardRefRenderFunction,
    ChangeEvent,
  } from "react";
import { ContainerNoMargin, Label } from "./create-control-account.styles";
  
  interface PropsSelect extends SelectHTMLAttributes<HTMLSelectElement> {
    label?: string;
    options: string[] | { name: string; id: string }[];
    onChanged?: (e: ChangeEvent<HTMLSelectElement>) => void;
    onSelectOption?: (data: { name: string; id: string }) => void;
    placeholder?: string;
    error?: string;
    nomargin?: boolean;
    borderColor?: string; // Adicione a propriedade borderColor aqui
    borderRadius?: string; // Define o raio dos cantos arredondados
    marginBottom?: string; //
  }
  
  const InputSelect: ForwardRefRenderFunction<HTMLSelectElement, PropsSelect> = (
    {
      label,
      error,
      options,
      nomargin = false,
      borderColor,
      borderRadius,
      marginBottom,
      onChanged,
      onSelectOption,
      id,
      ...rest
    },
    ref
  ) => {
    return (
      <div>
        <ContainerNoMargin>
          <Label>{label}</Label>
          <select ref={ref} {...rest} onChange={onChanged}>
            <option value="">Selecione uma opção</option>
            {options.map((opt) => {
              if (typeof opt === "string") {
                return (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                );
              }
              return (
                <option key={opt.id} value={opt.id}>
                  {opt.name}
                </option>
              );
            })}
          </select>
        </ContainerNoMargin>
      </div>
    );
  };
  
  export const FormInputSelect = forwardRef(InputSelect);
  
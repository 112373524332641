import React from "react";
import { Button } from "../button";
import {
  ButtonWrapper,
  CloseIcon,
  DownloadIcon,
  InfoModal,
  Modal,
  ModalContent,
  ModalHeader,
  Section,
  SectionAccountinfo,
  SectionCPNJ,
  SectionItem,
  SectionPayerName,
  SectionTitle,
} from "./styles";
import { ExtractItem } from "src/utils/extractDescription";
import dateToLine from "src/utils/dateToLine";
import toLocaleAmount from "src/utils/toLocaleAmount";
import { config } from "src/config";
import { documentFormatted } from "src/utils/documentFormatted";
import { useAuth } from "src/hooks/auth";
import { Pix } from "../proofs/Pix";
import { Ted } from "../proofs/Ted";
import { Slip } from "../proofs/Slip";
import { Internal } from "../proofs/Internal";
import { PixKey } from "../proofs/PixKey";
import { P2P } from "../proofs/P2P";
import { InternalTax } from "../proofs/InternalTax";
import { TransferWithPix } from "../proofs/TransferWithPix";
import { PagbankPayment } from "../proofs/PagbankPayment";
import { PagbankPaymentDiscount } from "../proofs/PagbankPaymentDiscount";
import { Payment } from "../proofs/Payment";

interface props {
  modalIsOpen: boolean;
  data?: ExtractItem;
  user?: any;
  afterOpenModal: () => void;
  closeModal: () => void;
}
const ModalConfirmOperation: React.FC<props> = ({
  afterOpenModal,
  user,
  closeModal,
  modalIsOpen,
  data,
}) => {
  if (!data) return <></>;

  function printPDF() {
    if (!data) return null;

    const receiptString = JSON.stringify(data);

    localStorage.setItem("temp_receipt_to_print", receiptString);

    window.open(`${config.FRONTEND_URL}/PDF`);
  }

  const txType = data.type.toLowerCase();

  const Proof =
    txType == "pix" ? (
      <PixKey data={data} closeModal={closeModal} />
    ) : txType == "transfer_with_pix" ? (
      <TransferWithPix data={data} closeModal={closeModal} />
    ) : txType.includes("pix") ? (
      <Pix data={data} closeModal={closeModal} />
    ) : txType.includes("p2p") ? (
      <P2P data={data} closeModal={closeModal} />
    ) : txType.includes("ted") ? (
      <Ted data={data} closeModal={closeModal} />
    ) : txType.includes("slip") ? (
      <Slip data={data} closeModal={closeModal} />
    ) : txType == "internal" || txType.includes("withdraw") ? (
      <Internal data={data} closeModal={closeModal} />
    ) : txType.includes("internal_tax") ? (
      <InternalTax data={data} closeModal={closeModal} />
    ) : txType == "pagbank_payment" ? (
      <PagbankPayment data={data} closeModal={closeModal} />
    ) : txType == "pagbank_payment_discount" ? (
      <PagbankPaymentDiscount data={data} closeModal={closeModal} />
    ) : txType == "payment" ? (
      <Payment data={data} closeModal={closeModal} />
    ) : (
      <div className="flex flex-col h-full w-full py-20 items-center justify-center">
        <span>Esse extrato ainda não esta pronto...</span>
        <span>Tente novamente mais tarde.</span>
      </div>
    );

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
    >
      {Proof}
    </Modal>
  );
};

export default ModalConfirmOperation;

import styled from 'styled-components';
import SignInImage from "../../assets/signinimage.png";
import {ReactComponent as StricLogoSVG} from '../../assets/stric_logo.svg'


import {ReactComponent as EyeEnabledIconSVG} from '../../assets/eyeEnabled.svg'
import {ReactComponent as EyeDisabledIconSVG} from '../../assets/eyeDisabled.svg'

export const EyeEnabledIcon = styled(EyeEnabledIconSVG)``
export const EyeDisabledIcon = styled(EyeDisabledIconSVG)``



export const StricLogo = styled(StricLogoSVG)`
height: 33px;
`

export const Container = styled.div`


background-color: var(--gray);
height: 100vh;
display: flex;

`





export const WrapperBackground = styled.div`

background: url(${SignInImage}) no-repeat;
background-size: cover;
height: 100%;
flex: 1;
display: none;

@media (min-width: 720px){
    display: block;

}

`

export const WrapperContent = styled.div`

flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;



`

export const Content = styled.div`

height: 100%;
max-width: 384px;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 10px;

`

export const Title = styled.h3`

margin-top: 32px;
font-size: 33px;
color: var(--white);
font-family: var(--regular);

> b {
    font-family: var(--semiBold);

}

`

export const Separator = styled.div`

margin-top: 25px;
width: 42px;
height: 4px;
background: var(--cian);

`

export const Reference = styled.span`

margin-top: 18px;
font-size: 16px;
color: var(--white);
font-family: var(--regular);

`

export const WrapperLogo = styled.div`

width: 100%;

`

export const WrapperForm = styled.div`

width: 100%;
display: flex;
flex-direction: column;
margin-top: 42px;

`


export const WhereIsMyToken = styled.a`

color: var(--white);
font-family: var(--semiBold);
font-size: 16px;
align-self: center;
margin-top: 22px;

`

export const Line = styled.div`

background-color: var(--lineBorder);
width: 100%;
height: 1px;
margin: 30px 0;

`


export const ButtonSignIn = styled.button`

margin-top: 24px;
background-color: var(--green);
height: 69px;
width: 100%;
color: var(--white);
font-family: var(--semiBold);
border-style: none;
border-radius: 4px;
font-size: 16px;
cursor: pointer;

`

export const WrapperToken = styled.div`

display: flex;
align-items: center;
width: 100%;

`

export const SeparatorNumbers = styled.div`

display: flex;
height: 68px;

& + div {
    margin-left: 8px;
}

`


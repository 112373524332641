import { useEffect, useState } from "react";
import {
  Container,
  Header,
  OrdersWrapper,
  Order,
  IconWrapper,
  InfoOrder,
  Balance,
  OrderDetails,
  BalanceDetails,
  Type,
  Name,
  WrapperMobile,
  Tooltip,
  DivMobile,
  DateUltimosLancamentos,
} from "./styles";

import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

import { normalizeName } from "../../utils/normelizaName";
import { currencyFormat } from "../../utils/currencyFormat";
import { format } from "date-fns";
import brasilLocale from "date-fns/locale/pt-BR";

import { Link } from "react-router-dom";
import { Skeleton } from "../skeleton";
import { service } from "../../services/ajio";
import {
  ExtractItem,
  extractDescriptionsUltimosLancamentos,
} from "../../utils/extractDescriptionsUltimosLancamentos";
import { dateFormatDaily } from "src/utils/dateFormat";
import { useAuth } from "src/hooks/auth";
import { formatTransactionsTypeLastUpdates } from "src/utils/formatTransactionsTypeLastUpdates";
import { separateTransactions } from "src/utils/separateTransactions";

const types = [
  "PIX_KEY",
  "PIX_MANUAL",
  "PIX_RECEIVED",
  "PIX_QR_CODE_STATIC",
  "PIX_QR_CODE_DYNAMIC",
  "P2P",
  'pix',
];

export const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ExtractItem[]>([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    service.get(`/transactions?page=1`).then(({ data }) => {
      const sortedFilteredItems = data.transactions.data
        .filter((data: any) => !(data.description === "P2P AJIOPAY"))
        .sort((a: any, b: any) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB.getTime() - dateA.getTime();
        });

      const { orderedTransactions } = separateTransactions(sortedFilteredItems)
      const slicedItems = orderedTransactions.slice(0, 6);

      setItems(slicedItems);
      setLoading(false);
    });
  }, []);

  function getType(type: string): string {
    if (type === "in") {
      return "Crédito";
    }

    if (type === "out") {
      return "Débito";
    }
    return type;
  }

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Container>
      <Header>
        <span>Últimos lançamentos</span>

        <Link to="/extract">
          <p>Ver extrato completo</p>
        </Link>
      </Header>
      <OrdersWrapper>
        {!loading && !items.length && (
          <div>
            <div className="text-center">
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                Nenhum lançamento para o mês de{" "}
                <strong>
                  {format(new Date(), "LLLL", { locale: brasilLocale })}
                </strong>
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Faça sua primeira transação
              </p>
              <div className="mt-6">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Transferir
                </button>
              </div>
            </div>
          </div>
        )}

        <Skeleton isActive={loading} repeat={4} />

        {!loading &&
          items.map((item) => {
            if (
              item.amount == 0 ||
              item.description === "TRANSACAO SUSPEITA (NAO CONSILIADA)"
            )
              return null;
            return (
              <Order key={item.id}>
                <OrderDetails>
                  <DateUltimosLancamentos>
                    {dateFormatDaily(item.created_at)}
                  </DateUltimosLancamentos>

                  <IconWrapper>
                    {item.direction === "in" ? (
                      <BsArrowUpShort color="var(--green)" size={21} />
                    ) : (
                      <BsArrowDownShort color="var(--red)" size={21} />
                    )}
                  </IconWrapper>
                  <InfoOrder>
                    <Type>{getType(item.direction)}</Type>

                    <Name className="truncate">
                      {formatTransactionsTypeLastUpdates(item as any, user)}
                    </Name>
                  </InfoOrder>
                </OrderDetails>

                <BalanceDetails>
                  <Balance credit={item.direction === "in"}>
                    {item.direction === "in" ? "+" : "-"}
                    {currencyFormat(Number(item.amount))}
                  </Balance>
                </BalanceDetails>
              </Order>
            );
          })}
      </OrdersWrapper>
    </Container>
  );
};

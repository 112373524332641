import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import InputInsertValue from "../../components/inputValue";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { cpf, cnpj } from 'cpf-cnpj-validator';


import {
  BoxInfoWrapper,
  BoxWarn,
  ButtonsWrapper,
  Container,
  // ExpiresDate,
  // InvoiceCode,
  // Label,
  LabelInput,
  // PaymentsDate,
  // TextAreaInput,
  // Title,
  // ValidityWrapper,
  WrapperHeader,
} from "./styles";
import { api, parseError } from "../../services/api";
import { ExclamationIcon } from "@heroicons/react/solid";
import { useNotification } from "../../hooks/notification";
import { service } from "../../services/vf";

type FormData = {
  name: string;
  cep: string;
  uf: string;
  date: string;
};

const schema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  cep: yup.string().required("CEP é obrigatório"),
  uf: yup.string().required("Estado é obrigatório"),
  date: yup.string().required("Vencimento é obrigatório"),
}).required();



export const DepositNewInvoice: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState('');
  const [value, setValue] = useState(0);
  const [maskedValue, setMaskedValue] = useState('');
  const [errorDocument, setErrorDocument] = useState('');
  const [error, setError] = useState('');

  const { setOpenNotification } =  useNotification();

  const { register, handleSubmit, formState, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema)
  });


  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate("/deposit");
  };

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleConfirm: SubmitHandler<FormData> = async (values, event) => {
    setErrorDocument('');
    setLoading(false);
    if (!document || document === null || document === '') {
      setErrorDocument("Documento é obrigatório");
      return;
    }

    if (!cpf.isValid(document) && !cnpj.isValid(document)) {
      setErrorDocument("Documento inválido");
      return;
    }

    if (value <= 0) {
      setOpenNotification({
        type: 'warning',
        title: 'Valor obrigatório',
        notification: "É preciso informar um valor"
      });

      return;
    }

    setLoading(true);
    setError("");

    try {
      const { data } = await service.post("/billets/generate", {
        amount: value,
        dueDate: values.date,
        customer: {
          document: document.replace(/[/,.,-\s]/g, ""),
          name: values.name,
          zipCode: values.cep.replace(/[-,\s]/g, ""),
          state: values.uf,
        }
      });
      setLoading(false);
      navigate("confirm", {
        state: {
          ...data, maskedValue,
          dueAt: values.date,
          customerName: values.name,
          customerDocument: document,
          url: "",
        },
      });

    } catch (err) {
      const error = parseError(err);
      // console.log(error);
      setError(error.friend);
      setLoading(false);
    }
  };

  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Gerar nova cobrança"
        subtitle="Informe os dados para emitir uma nova cobrança"
      />
      </WrapperHeader>
      <form onSubmit={handleSubmit(handleConfirm)}>
      <BoxInfoWrapper>
      <div className="mb-5">
          <LabelInput>Qual o nome completo de quem você vai emitir o boleto?</LabelInput>
          <TextInput
            borderColor="#E3E3E3"
            placeholder=""
            type="text"
            {...register("name")}
            error={errors.name}
        />
        </div>

        <div className="mb-5">
          <LabelInput>Qual o documento de quem você vai emitir o boleto?</LabelInput>
          <TextInput
            mask="CPF_CNPJ"
            borderColor="#E3E3E3"
            placeholder=""
            type="text"
            value={document}
            onChange={(e) => setDocument(e.target.value)}
            error={!!errorDocument ? { message: errorDocument, type: "required", ref: this }: undefined}
          />
        </div>
        <div className="mb-5 flex justify-between">
          <div className="w-full mr-5">
            <LabelInput>Informe o CEP</LabelInput>
            <TextInput
              mask="99999-999"
              borderColor="#E3E3E3"
              placeholder=""
              {...register("cep")}
              error={errors.cep}  />
          </div>
          <div className="w-1/2">
            <LabelInput>Informe o estado</LabelInput>
            <TextInput
              borderColor="#E3E3E3"
              placeholder=""
              type="text"
              maxLength={2}
              {...register("uf")}
              error={errors.uf}
            />
          </div>
        </div>

        <div className="mb-5">
          <LabelInput>Qual o vencimento dessa cobrança?</LabelInput>
          <TextInput
            borderColor="#E3E3E3"
            placeholder=""
            type="date"
            {...register("date")}
             error={errors.date} />
        </div>

        <div className="mb-5">
          <InputInsertValue onChange={(event, value, masked) => handleChange(event, value, masked)} />
        </div>

        <BoxWarn>
          <span>
            Para gerar um novo boleto, é necessário que o seu saldo em conta seja superior à tarifa de R$ 2,50.
          </span>
        </BoxWarn>

        {!!error && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
        )}
      </BoxInfoWrapper>
      <ButtonsWrapper>
        <Button handleFunction={handleCancel} color="#e3e3e3" title="Cancelar" />

        <Button type="submit" isLoading={loading} title="Continuar" />
      </ButtonsWrapper>
      </form>
    </Container>
  );
};

import { FC } from "react";
import { GradientButton, PhoneFrame } from "./components";
import { useLocation, useNavigate } from "react-router-dom";

import AddingSecureSVG from "../../assets/AddingSecureSVG.svg";

import "./styles.css";

export const AddingSecure: FC = () => {
  const location = useLocation();

  const { accountType } = location.state;

  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center items-center gap-20 background">
      <PhoneFrame onPress={() => navigate("/create-account-wallet/create-password", { state: { accountType } })} />

      <div>
        <svg style={{ position: "relative", top: 0 }} width="600" height="70vh">
          <defs>
            <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E2E2E" />
              <stop offset="25%" stopColor="#FFFFFF" />
              <stop offset="75%" stopColor="#274683" />
            </linearGradient>
          </defs>

          <rect
            x="10"
            y="10"
            width="570"
            height="65vh"
            rx="10"
            ry="10"
            fill="black"
            fillOpacity="0.4"
            stroke="url(#borderGradient)"
            strokeWidth="1"
          />
        </svg>

        <div className="absolute top-[20vh] ml-24">
          <img className="ml-32" src={AddingSecureSVG} alt="" />
          <p className="text-white text-2xl font-light text-center mb-8 mt-10 max-2xl:text-base max-2xl:mt-5 max-2xl:mb-2">
            Agora vamos adicionar <br />
            <strong className="font-bold text-white ">mais segurança</strong> a <br />
            sua conta.
          </p>

          <h3 className="text-white text-2xl font-light text-center mb-10 max-2xl:mb-5 max-2xl:text-lg">
            Tudo <strong className="font-bold text-white">certo</strong> por aqui!
          </h3>

          <GradientButton
            tailwindStyles="w-[25rem] h-12 bg-[#274683] text-white text-bold text-xl"
            title="Começar"
            onClick={() => navigate("/create-account-wallet/identification", { state: { accountType } })}
          />
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { ButtonForm } from './styles';

interface PixTextAreaProps {
    pixString: string;
}

const PixButtonCopyPaste: React.FC<PixTextAreaProps> = ({ pixString }) => {
    //const PixTextArea = () => {

    const [copied, setCopied] = useState(false);
    ////const pixString = "Exemplo de string Pix";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(pixString);
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); // Defina o estado "copied" como falso após 3 segundos
    };

    return (
            <ButtonForm
                type="button"
                style={{
                    border: "2px solid var(--cian)",
                }}
                onClick={copyToClipboard}>
                {copied ? 'Copiado!' : 'Copia & Cola'}
            </ButtonForm>

    
    );
};

export default PixButtonCopyPaste;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { useForm, Controller } from "react-hook-form";
import { useAuth } from "../../hooks/auth";

import {
  ButtonSignIn,
  Container,
  Content,
  ForgetPassword,
  Input,
  Line,
  Reference,
  Separator,
  Title,
  WrapperBackground,
  WrapperContent,
  WrapperForm,
  WrapperLogo,
} from "./styles";
import { api, parseError } from "../../services/api";
import { useClient } from "../../hooks/client";
import { service } from "../../services/vf";
import { messageSuccess, messageError } from "src/components/Message";

interface IFormInput {
  email?: string;
}

export const Forgot: React.FC = () => {
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const { account } = useAuth();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    // formState: { errors },
  } = useForm();

  const watchFields = watch();

  const handleResetPassword = async (data: IFormInput) => {
    if (!watchFields.email || !data.email) {
      messageError("Informe o seu e-mail");
      return;
    }

    const isValid = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i.test(
      watchFields.email
    );

    if (!isValid) {
      messageError("Informe um e-mail válido");
      return;
    }

    try {
      setLoading(true);

      await service.post("accounts/reset-password", {
        email: watchFields.email,
      });

      setValue("email", "");

      messageSuccess(
        "Foi enviado as instruções de recuperarção de senha para o seu e-mail."
      );
    } catch (err) {
      const error = parseError(err) || "Erro ao recuperar a senha.";
      console.log("ASDASDASD", error);

      messageError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleReset = async (event: FormEvent) => {
  //   event.preventDefault();

  //   if (!password) {
  //     return;
  //   }

  //   if (
  //     !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
  //       password
  //     )
  //   ) {
  //     setError(
  //       "A senha deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial."
  //     );
  //     return;
  //   }

  //   if (password !== passwordConfirm) {
  //     setError("As senhas não conferem.");
  //     return;
  //   }

  //   try {
  //     setLoading(true);
  //     setError("");
  //     await service.post("accounts/reset-password", {
  //       email: document,
  //       confirmationCode,
  //       password,
  //     });

  //     setLoading(false);
  //     setError("");
  //     setSuccess("Nova senha cadastrada com sucesso.");
  //     setOk(true);
  //   } catch (err) {
  //     const error = parseError(err);
  //     console.log(error);
  //     setLoading(false);
  //     setError(
  //       "Código de senha de redefinição de confirmação de usuário inválido"
  //     );
  //     // setError(error.friend);
  //   }
  // };

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });

  // useEffect(() => {
  //   if (!location.search || location.search === null || location.search === '') {
  //     setStep(1);
  //   } else {
  //     setStep(2);
  //     setToken(location.search.replace('?token=', ''));
  //   }
  // }, [location.search]);

  // useEffect(() => {
  //   console.log(step);
  // }, [step]);

  return (
    <Container>
      <WrapperBackground />
      <WrapperContent>
        <Content>
          <WrapperLogo>
            {/*} <img
              src={client.whiteLogo.image}
              alt={client.name}
              style={{
                width: client.whiteLogo.maxWidth,
                height: client.whiteLogo.maxHeight,
              }}
            />
            {/* <StricLogo /> */}
          </WrapperLogo>
          <Title>
            Bem-vindo ao internet <br />
            <b>banking da {client.name}.</b>
          </Title>
          <Separator />
          {/* {step === 1 ? (*/}
          <>
            <Reference>
              Preencha os campos abaixo para recuperar sua senha
            </Reference>
            <WrapperForm onSubmit={handleSubmit(handleResetPassword)}>
              <Input>
                <span>E-mail:</span>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <input
                      {...field}
                      ref={ref}
                      value={watchFields.email}
                      placeholder="Digite seu E-mail"
                      type="text"
                    />
                  )}
                />
              </Input>

              {/* {!!success && (
                <Error>
                  <div className="bg-green-50 border-l-4 border-green-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-gray-700">{success}</p>
                      </div>
                    </div>
                  </div>
                </Error>
              )} */}

              {/* {!!error && (
                <Error>
                  <div className="bg-red-50 border-l-4 border-red-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{error}</p>
                      </div>
                    </div>
                  </div>
                </Error>
              )} */}
              <ButtonSignIn type="submit">
                <Loading isLoading={loading} />
                Recuperar
              </ButtonSignIn>
            </WrapperForm>
          </>
          {/* ) : (
            <>
              <Reference>
                Preencha os campos abaixo para recuperar sua senha
              </Reference>

              <Reference>
                Configura o token que foi enviado no seu celular.
              </Reference>

              <WrapperForm onSubmit={handleReset}>
                <Input>
                  <span>Token (Enviado pelo celular)</span>
                  <input
                    placeholder="Token"
                    type="text"
                    onChange={(input: any) =>
                      setConfirmationCode(input.target.value)
                    }
                    value={confirmationCode}
                  />
                </Input>

                <Input>
                  <span>Informe sua nova senha</span>
                  <input
                    placeholder="Nova senha"
                    type="password"
                    onChange={(input: any) => setPassword(input.target.value)}
                    value={password}
                  />
                </Input>

                <Input>
                  <span>Informe sua nova senha</span>
                  <input
                    placeholder="Confirme sua nova senha"
                    type="password"
                    onChange={(input: any) =>
                      setPasswordConfirm(input.target.value)
                    }
                    value={passwordConfirm}
                  />
                </Input>

                {!!success && (
                  <Error>
                    <div className="bg-green-50 border-l-4 border-green-400 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckIcon
                            className="h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-gray-700">{success}</p>
                        </div>
                      </div>
                    </div>
                  </Error>
                )}

                {!!error && (
                  <Error>
                    <div className="bg-red-50 border-l-4 border-red-400 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{error}</p>
                        </div>
                      </div>
                    </div>
                  </Error>
                )}

                {!ok && (
                  <ButtonSignIn type="submit">
                    <Loading isLoading={loading} />
                    Continuar
                  </ButtonSignIn>
                )}
              </WrapperForm>
            </>
          )} */}
          <ForgetPassword href="/signin">Voltar para o login</ForgetPassword>
          <Line />
          {/* <NotHaveAnAccount to="/type-create-account">
            Não tem uma conta? Abrir conta {client.name}
          </NotHaveAnAccount> */}
        </Content>
      </WrapperContent>
    </Container>
  );
};

import React, { FC, useState } from "react";
import {
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Input } from "../signIn/styles";
import { Button } from "src/components/button";
import { service } from "src/services/ajio";
import { FormInput } from "src/components/LPFormButton2";
import { Eye, EyeClosed } from "phosphor-react";

export const RecoveryPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState<string>("");
  const [pin, setPin] = useState<string>("");

  const [error, setError] = useState<string>("");
  const [msg, setMsg] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const emailHash = searchParams.get("email");
  const hash = searchParams.get("hash");

  if (!emailHash || !hash) {
    setTimeout(() => {
      navigate("/signIn");
    }, 3000);
    return <></>;
  }

  const handleSendNewPassword = async () => {
    setError("");
    try {
      setIsLoading(true);
      const passwordRegex =
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()-_+=])[A-Za-z0-9!@#$%^&*()-_+=]{8,}$/;
      const passwordIsValid = passwordRegex.test(password);

      const specialCaraters = "!@#$%^&*()-_+={}[];:'\",<>.?/</>";

      const haveSpecial = !!password.split("").find((c) => {
        return specialCaraters.split("").find((sc) => sc == c);
      });

      if (!passwordIsValid || !haveSpecial) {
        setError(
          "A senha deve conter no minimo 8 caracteres sendo 1 caracter especial 1 número e uma letra maiúscula"
        );
        return;
      }

      if (pin.length < 4) {
        setError("Digite os 4 números do pin");
        return;
      }

      const response = await service.patch("/accounts/reset-password", {
        reset_code: hash,
        password,
        email: emailHash,
        pin: pin,
      });

      if (response.data) {
        setMsg("Sua senha foi alterada. Estamos te redirecionando...");
        setTimeout(() => {
          navigate("/signIn");
        }, 5000);
      }
    } catch (error: any) {
      console.log(error);
      setError("Pin incorreto");
    } finally {
      setIsLoading(false);

      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen p-8 gap-4">
      <div className="flex flex-col items-center w-[400px] gap-4">
        <span className="text-3xl font-bold">Digite sua nova senha </span>
        <div className="flex items-center border bg-white border-zinc-300 rounded-md p-2 w-full">
          <input
            className="flex flex-1 focus:outline-none"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Digite sua nova senha"
            type={isVisible ? "text" : "password"}
            value={password}
          />
          <button onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? (
              <Eye size={28} color="#333" />
            ) : (
              <EyeClosed size={28} color="#333" />
            )}
          </button>
        </div>
        <input
          className="border border-zinc-300 rounded-md p-2 w-full focus:outline-none"
          onChange={(e) => {
            const value = e.target.value;

            if (value.length == 5) return;

            setPin(e.target.value);
          }}
          placeholder="PIN"
          type="number"
          value={pin}
        />
        <button className="flex w-full" onClick={handleSendNewPassword}>
          <Button title="Enviar" isLoading={isLoading} />
        </button>
        <span className="text-red-400 text-sm font-semibold text-center mt-[-20px]">
          {error}
        </span>
        <span className="text-green-400 text-sm font-semibold text-center mt-[-20px]">
          {msg}
        </span>
      </div>
    </div>
  );
};

import React from "react";
import background from "../../assets/PrivacyPoliticsBackgroundPNG.png";
import vilabankWhiteLogo from "../../assets/VilapayLogoWhitePNG.png";
import { Container } from "./styles";
import { ArrowLeft, DeviceTabletSpeaker, Shield, Wallet } from "phosphor-react";
import { ExpansiveButton } from "./components/ExpansiveButton";
import Footer from "src/components/LPfooter";

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Container className="w-full h-screen bg-white">
        <div
          className="w-full h-[60vh] flex flex-col items-center justify-center bg-fixed bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${background})` }}
        >
          <img className="w-[20%] h-[20%] object-contain mt-56" src={vilabankWhiteLogo} alt="" />
          <h1 className="text-white text-6xl font-bold mb-5 mt-3">Nossa Política de Privacidade</h1>
          <h2 className="text-white text-4xl font-extralight mb-[5%]">
            A Política de Privacidade foi criada pra fortalecer nossa relação de confiança.
          </h2>
        </div>
        <div className="w-full flex flex-col text-center mt-10 pb-20">
          <h4 className="font-bold text-black text-5xl">Introdução</h4>
          <p className="font-light text-black text-2xl mt-10">
            O PIXWAVE existe para quebrar barreiras e eliminar burocracias. Com isso, nós queremos que você <br />
            passe menos tempo fazendo pagamentos e mais tempo aproveitando as coisas boas da vida. <br />
            Aqui, você também participará de uma rede social, para compartilhar suas experiências e <br />
            acompanhar as suas atividades e as de seus amigos. <br />
          </p>

          <p className="font-light text-black text-2xl mt-10">
            Nós também oferecemos uma série de serviços, conectando você a estabelecimentos, lojas e <br />
            profissionais liberais. Uma das nossas metas é fortalecer a sua confiança em nosso trabalho. Por <br />
            isso, queremos te contar sobre como usamos e protegemos seus dados pessoais quando você <br />
            utiliza os nossos aplicativos ou site do PIXWAVE
          </p>

          <p className="font-light text-black text-2xl mt-10">
            Ao utilizar nossos serviços, você aceita e concorda com todos os termos e condições deste aviso, <br />
            beleza? Vem com a gente!
          </p>
        </div>

        <div className="w-full bg-[#FFF3E5] flex flex-col justify-center items-center text-center">
          <h1 className="font-bold text-black text-5xl mt-20">Antes, uma pequena explicação</h1>
          <p className="font-light text-black text-2xl mt-5">
            Alguns termos são super importantes e tem relação com a Lei Geral de Proteção de Dados <br />
            Pessoais (LGPD). Então vamos explicar esses termos pra você.
          </p>

          <div className="w-full flex items-center justify-center gap-20 mt-20 pb-14">
            <div className="flex flex-col items-center justify-center">
              <Shield color="black" size={120} />
              <p className="font-light text-black text-xl mt-5 h-52">
                São todas as informações que <br />
                permitem a gente saber quem <br />
                você é. Como o seu nome, CPF, <br />
                selfie, geolocalização, dados do seu <br />
                cartão de crédito e outros dados.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <DeviceTabletSpeaker color="black" size={120} />
              <p className="font-light text-black text-xl mt-5 h-52">
                É a pessoa do nosso time que <br />
                cuida da sua privacidade aqui no <br />
                PIXWAVE. Ele também vai poder tirar <br />
                qualquer dúvida que você tenha <br />
                sobre a forma como tratamos os <br />
                seus dados.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <Wallet color="black" size={120} />
              <p className="font-light text-black text-xl mt-5 h-52">
                É o que fazemos com os seus <br />
                dados pessoais, por exemplo, como <br />
                vamos coletar, classificar, utilizar, <br />
                acessar, processar, armazenar, <br />
                eliminar, modificar, compartilhar, <br />
                transferir, etc.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-center px-32 mt-16 pb-52">
          <ExpansiveButton
            title="Política de Privacidade"
            text="Na PIXWAVE, estamos comprometidos em proteger a privacidade e os
              dados dos nossos clientes. Esta Política de Privacidade descreve
              como coletamos, usamos e protegemos as informações pessoais dos
              nossos clientes durante o uso dos nossos serviços. Ao utilizar os
              nossos serviços, você concorda com os termos descritos nesta."
          />
          <ExpansiveButton
            title="Informações Coletadas"
            text="Nós coletamos informações pessoais dos nossos clientes durante o
              processo de cadastro e uso dos nossos serviços. Isso pode incluir,
              mas não se limita a: Informações de identificação pessoal, como
              nome, endereço, data de nascimento e número de identidade;
              Informações de contato, como endereço de e-mail e número de
              telefone; Informações financeiras, como dados de conta bancária ou
              cartão de crédito; Informações de transações e atividades de
              conta."
          />
          <ExpansiveButton
            title="Uso das Informações"
            text="Das pessoais coletadas são usadas para os seguintes fins:
          Prestação de serviços solicitados pelos clientes; Verificação de
          identidade e prevenção à fraude; Comunicação com os clientes sobre
          os serviços oferecidos; Cumprimento de obrigações legais e
          regulatórias, incluindo prevenção à lavagem de dinheiro. Melhoria
          dos nossos serviços e desenvolvimento de novos recursos."
          />
          <ExpansiveButton
            title="Compartilhamento de Informações"
            text="Não compartilhamos informações pessoais dos nossos clientes com
          terceiros, exceto nas seguintes circunstâncias: Com o
          consentimento expresso dos clientes; Quando necessário para
          cumprir obrigações legais ou regulatórias; Para proteger os
          interesses legítimos da PIXWAVE, como prevenir fraudes ou
          violações de segurança."
          />
          <ExpansiveButton
            title="Segurança de Dados"
            text="Implementamos medidas técnicas e organizacionais para proteger as
          informações pessoais dos nossos clientes contra acesso não
          autorizado, alteração, divulgação ou destruição não autorizada."
          />
          <ExpansiveButton
            title="Direitos dos Titulares dos Dados"
            text="Os clientes têm o direito de acessar, corrigir, atualizar ou
          excluir suas informações pessoais a qualquer momento. Para exercer
          esses direitos ou para fazer perguntas sobre esta Política de
          Privacidade, entre em contato conosco através dos canais
          fornecidos abaixo."
          />
          <ExpansiveButton
            title="Alterações nesta Política de Privacidade"
            text="Reservamos o direito de atualizar esta Política de Privacidade
          periodicamente para refletir mudanças nos nossos processos ou em
          conformidade com a legislação aplicável. Recomendamos que os
          clientes revisem esta Política de Privacidade regularmente para
          ficarem informados sobre como protegermos suas informações
          pessoais."
          />
          <ExpansiveButton
            title="Contato"
            text="Se você tiver perguntas ou preocupações sobre esta Política de
          Privacidade ou sobre as práticas de privacidade da PIXWAVE,
          entre em contato conosco através dos seguintes meios:
<<<<<<< HEAD
          E-mail: suporte@pixwave.com.br
=======
          E-mail: suporte@pixwave.com.br
>>>>>>> 3bd29ff4c75f80ea6e59ef499e055be0c694fdb8
          Teremos prazer em ajudar no que for necessário."
          />
        </div>
        <div className="w-full bg-black h-32 flex items-center justify-start px-60">
          <div className="flex items-center gap-1">
            <ArrowLeft color="#5f83fb" size={24} />
            <a className="text-[#5f83fb] underline font-bold text-lg" href="/home">
              Voltar ao início
            </a>
          </div>
        </div>
        <Footer />
      </Container>
    </>
  );
};

import React from "react";
import { ArrowRightIcon, Container, IconWrapper } from "./styles";

interface IconTypeProps {
    width?: number;
    height?: number;
    color?: string;
  }  

type IconType = (props: IconTypeProps) => JSX.Element;

type props = {
    Icon?: IconType;
    title: string;
    linkTo?: string;  
    onClick?: () => void; // Adicionando o atributo onClick ao tipo props

}

export const CardActionOption: React.FC<props> = ({
    Icon,
    title,
    linkTo = "#"
}) => {

    return (
        <Container to={linkTo}>
            <IconWrapper>
            {
                Icon && <Icon/>
            }
            </IconWrapper>
            <div>
                <span>{title}</span>
                <ArrowRightIcon/>
            </div>
        </Container>
    )
}
import styled from "styled-components";
import ButtonSignInColor from "../../assets/button-color.svg";

export const Container = styled.a`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cian);
  text-decoration: none;
  //background-image: url(${ButtonSignInColor});
  background-repeat: no-repeat;
  background-size: cover;

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
  } 
`;

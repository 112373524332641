import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AprovacoesProvider } from "./pages/aprovacoes/AprovacoesContext";

// Adicione o cabeçalho X-XSS-Protection aqui
const metaTag = document.createElement("meta");
metaTag.httpEquiv = "X-XSS-Protection";
metaTag.content = "1; mode=block";
document.head.appendChild(metaTag);

ReactDOM.render(
  <React.StrictMode>
    <AprovacoesProvider>
      <App />
    </AprovacoesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

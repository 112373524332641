import React, {
  InputHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  useState,
} from "react";
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";

import { Container, Label } from "./styles";
import { EyeDisabledIcon, EyeEnabledIcon } from "../passwordInput/styles";
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  mask?: string;
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderInput?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  onChanged?: (e: any, number?: number, masked?: string) => void;
  showPasswordIcon?: boolean; // Adicione a propriedade showPasswordIcon aqui
  id?: string; // Adicione um campo "id" exclusivo para identificar o FormInput
  eyeIconColor?: string; // Adicione a propriedade eyeIconColor aqui
  height?: number;
  removeRelative?: boolean;
}

interface PropsSelect {
  label?: string;
  placeholder?: string;
  options: string[];
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  onChanged: (e: any, number?: number, masked?: string) => void;
}

interface PropsRadio {
  label: string;
  options: string[];
  value: string;
  error?: string;
  nomargin?: boolean;
  borderColor?: string; // Adicione a propriedade borderColor aqui
  borderRadius?: string; // Define o raio dos cantos arredondados
  onChanged?: (e: any, number?: number, masked?: string) => void;
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    label,
    mask,
    error,
    nomargin = false,
    borderColor,
    borderInput,
    borderRadius,
    onChanged,
    showPasswordIcon = false,
    id,
    type = "text",
    height,
    removeRelative = false,
    ...rest
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
    // Atualize o tipo do input com base no estado do botão de olho
  };
  const inputType = type === "text" ? "text" : isVisible ? "text" : "password";
  const inputMode = isVisible ? "text" : "none"; // Defina inputMode como "none" quando o campo estiver visível

  // const containerStyle: React.CSSProperties = {
  //   marginBottom: 20,
  //   width: "100%",
  //   border: `none`, // Defina a cor da borda com base na prop 'borderColor'
  //   borderRadius: "12px", // Define o raio dos cantos arredondados
  //   position: "relative", // Adicione esta propriedade para que o botão fique no lugar certo
  //   height: "6%",
  // };

  return (
    <div className={`${removeRelative ? 'mb-3' : 'relative'}`}>
      <Container style={{ width: "auto", flex: 1,  height: removeRelative ? '3rem' : undefined }} nomargin={nomargin}>
        <Label>{label}</Label>
        {mask ? (
          <>
            {mask !== "currency" ? (
              <InputMask
                maskChar=""
                mask={mask}
                type="text"
                onChange={onChanged}
                {...rest}
              />
            ) : (
              <IntlCurrencyInput
                currency="BRL"
                config={currencyConfig}
                onChange={onChanged}
                {...rest}
              />
            )}
          </>
        ) : (
          <input
            {...rest}
            type={inputType} // Use o tipo dinâmico aqui
            onChange={(e) => onChanged && onChanged(e)}
            ref={ref as React.RefObject<HTMLInputElement>} // Use ref como RefObject<HTMLInputElement>
          />
        )}
        {showPasswordIcon && (
          <button
            type="button"
            className="bg-white shadow-sm rounded-full p-1"
            onClick={toggleVisibility}
            style={{
              position: "absolute",
              top: "45%",
              right: "15px",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
              color: rest.eyeIconColor || "#009EE2", // Use a cor dos ícones de olho fornecida ou uma cor padrão
            }}
          >
            {isVisible ? <EyeDisabledIcon /> : <EyeEnabledIcon />}
          </button>
        )}
      </Container>
      {error && (
        <div>
          <p className="text-sm text-red-600 " id="email-error">
            {error}
          </p>
        </div>
      )}
    </div>
  );
};

const InputSelect = ({
  label,
  placeholder = "Selecione uma opção",
  options,
  error,
  nomargin = false,
  borderColor,
  borderRadius,
  onChanged,
  ...rest
}: PropsSelect) => {
  const containerStyle = {
    marginBottom: 27,
    borderRadius: "4px", // Define o raio dos cantos arredondados
  };

  return (
    <div className="mb-4">
      <Container nomargin={nomargin}>
        <Label>{label}</Label>
        <select {...rest} onChange={onChanged}>
          <option value="">{placeholder}</option>
          {options.map((opt: string) => {
            return (
              <option key={opt} value={opt}>
                {opt}
              </option>
            );
          })}
        </select>
      </Container>
      {!!error && (
        <div>
          {!!error && (
            <p className="text-sm text-red-600" id="email-error">
              {error}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const InputRadio = ({
  label,
  options,
  value,
  error,
  nomargin = true,
  borderColor,
  borderRadius,
  onChanged,
  ...rest
}: PropsRadio) => {
  const containerStyle = {
    marginBottom: 27,
    border: `2px solid ${borderColor || "none"}`, // Defina a cor da borda com base na prop 'borderColor'
    borderRadius: "4px", // Define o raio dos cantos arredondados
  };

  return (
    <div style={containerStyle}>
      <Container nomargin={nomargin}>
        <Label>{label}</Label>
        <div className="flex items-center justify-start">
          {options.map((opt) => {
            return (
              <Fragment key={opt}>
                <input
                  defaultChecked={false}
                  name={label}
                  type="radio"
                  value={opt}
                  className="mr-2 mb-1"
                  onChange={onChanged}
                />
                <label className="mr-6">{opt}</label>
              </Fragment>
            );
          })}
        </div>
      </Container>
      {!!error && (
        <div>
          {!!error && (
            <p className="text-sm text-red-600" id="email-error">
              {error}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export const FormInput = forwardRef(InputBase);

export const FormSelect = InputSelect;

export const FormRadio = InputRadio;

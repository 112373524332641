import { format, isValid } from "date-fns";
import { ptBR } from "date-fns/locale";
import { DateTime } from "luxon";

export function dateFormat(date: string): string {
  const tzFixed = ajustTimeZone(date);

  return tzFixed.toFormat("dd/MM/yyyy HH:mm");
  // return new Intl.DateTimeFormat("pt-BR").format(
  //   typeof date == "string" ? new Date(date) : date
  // );
}
export const dateFormatDaily = (date: string | undefined): string => {
  const tzFixed = ajustTimeZone(date ? date : "");

  return tzFixed.toFormat("dd/MM/yyyy HH:mm");
};

export const dateFormatUS = (date: Date): string => {
  const tzFixed = ajustTimeZone(date.toString());

  const formated = tzFixed.toISO();

  return formated ? formated : new Date().toISOString();
};

export const ajustTimeZone = (date: string) => {
  const tz = DateTime.now().zoneName;

  return DateTime.fromISO(date).setZone(tz);
};

export function formatDateString(dataString: string) {
  const parts = dataString.split(/[T:.Z-]/);
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];
  const hour = parts[3];
  const minutes = parts[4];

  return `${day}/${month}/${year} ${hour}:${minutes}`;
}
